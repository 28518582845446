import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['select']

  connect () {
    this.onChange()
  }

  onChange () {
    var reviewers = document.getElementsByClassName('review')
    var approvers = document.getElementsByClassName('approve')
    if ((reviewers.length > 0) && (approvers.length > 0)) {
      $('#submitButton').show()
    } else {
      $('#submitButton').hide()
    }
  }

  submit () {
    $(this.draftButtonTarget).hide()
    $(this.buttonsubmitTarget).hide()
    $(this.spinnerTarget).show()
  }
}
