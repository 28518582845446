import { Controller }  from 'stimulus'

export default class extends Controller {
  connect(){
    scroll = sessionStorage.scrollValue
    $('html, body').animate({
      scrollTop: scroll
    }, 50);
    sessionStorage.removeItem('scrollValue')
  }

  click(){
    sessionStorage.scrollValue = window.pageYOffset;
  }

  comeback(){
    sessionStorage.removeItem('scrollValue')
  }
}