import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['buttonsubmit', 'spinner']

  connect (e) {
    $('#eps_other').hide('slow')
    $('#eps_other *').prop('disabled', true)
    $('#population_other').hide('slow')
    $('#population_other *').prop('disabled', true)
    $(this.spinnerTarget).hide()
    if (typeof grecaptcha !== 'undefined') {
      grecaptcha.render( $('.g-recaptcha')[0], { sitekey : '6LerLukcAAAAAH0Ec7nRGONmj18cmIKFjPFjiLTv' })
    }
  }

  onSubmit (e) {
    $(this.buttonsubmitTarget).prop('className', 'btn btn-primary disabled')
    $(this.buttonsubmitTarget).hide()
    $(this.spinnerTarget).show()
  }

  onChange (e) {
    $(this.buttonsubmitTarget).prop('className', 'btn btn-primary')
  }

  eps_other(){
    var pqrsf_eps = document.getElementById('pqrsf_eps').value;
    if (pqrsf_eps == 'Otro'){
      $('#eps_other').show('slow')
      $('#eps_other *').prop('disabled', false)
    }
    else{
      $('#eps_other').hide('slow')
      $('#eps_other *').prop('disabled', true)
    }
  }
  poblation_other(){
    var plation = document.getElementById('pqrsf_population_group').value;
    if (plation == 'Otro'){
      $('#population_other').show('slow')
      $('#population_other *').prop('disabled', false)
    }
    else{
      $('#population_other').hide('slow')
      $('#population_other *').prop('disabled', true)
    }
  }
}
