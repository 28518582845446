import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import GlobalContext from "../../context/GlobalContext";
import Hour from "./Hour";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Week.css";

function getDays(selectedDate) {
  const prevDay = dayjs(selectedDate).add(-1, "day").format("YYYY-MM-DD");
  const postDay0 = dayjs(selectedDate).format("YYYY-MM-DD");
  const postDay1 = dayjs(selectedDate).add(1, "day").format("YYYY-MM-DD");
  const postDay2 = dayjs(selectedDate).add(2, "day").format("YYYY-MM-DD");
  const postDay3 = dayjs(selectedDate).add(3, "day").format("YYYY-MM-DD");
  const postDay4 = dayjs(selectedDate).add(4, "day").format("YYYY-MM-DD");
  const postDay5 = dayjs(selectedDate).add(5, "day").format("YYYY-MM-DD");
  const dates = [
    prevDay,
    postDay0,
    postDay1,
    postDay2,
    postDay3,
    postDay4,
    postDay5,
  ];
  return dates;
}

export default function Week({ events, flag, setFlag }) {
  const { selectedDate, setShowModal, setShowEditModal, flaged } =
    useContext(GlobalContext);
  const [matrix, setMatrix] = useState([]);
  const [titleMatrix, setTitleMatrix] = useState([]);
  const [durationMatrix, setDuratonMatrix] = useState([]);
  const [colorMatrix, setColorMatrix] = useState([]);
  const dates = getDays(selectedDate);
  const eventRange = [1, 2, 3, 4, 5, 6, 7];
  const numRows = 24; // Número de filas
  const numCols = 7; // Número de columnas
  const hours = [];
  const options = [
    { value: "completed", label: "Hecho", color: "#97E7B9" },
    { value: "in_progress", label: "En Proceso", color: "#FBB446" },
    { value: "incomplete", label: "No Realizado", color: "#E91E63" },
    {
      value: "without_status",
      label: "Sin seguimiento",
      color: "rgb(250, 250, 70)",
    },
  ];
  function getColorFromFinding(finding) {
    const option = options.find((option) => option.value === finding);
    return option ? option.color : null;
  }

  for (let hour = 7; hour <= 18; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      hours.push(
        `${hour.toString().padStart(2, "0")}:${minute
          .toString()
          .padStart(2, "0")}`
      );
    }
  }

  useEffect(() => {
    if (flag) {
      const newMatrix = Array.from({ length: numRows }, () =>
        Array(numCols).fill(false)
      );
      const newMatrixTitle = Array.from({ length: numRows }, () =>
        Array(numCols).fill(false)
      );
      const newMatrixDuration = Array.from({ length: numRows }, () =>
        Array.from({ length: numCols }, () => 0)
      );
      const newColorMatrix = Array.from({ length: numRows }, () =>
        Array(numCols).fill(false)
      );

      events.forEach((event) => {
        const startDate = dayjs(event.start_date).format("YYYY-MM-DD");
        const startTime = dayjs(event.start_date).format("HH:mm");
        const startTimeEvent = dayjs(event.start_date);
        const finalTime = dayjs(event.end_date);
        const title = event.title;
        const firstFollow = event.event_follows[event.event_follows.length - 1];
        const finding = firstFollow ? firstFollow.follow_status : null;

        dates.forEach((date, dateIndex) => {
          if (date === startDate) {
            const hourIndex = hours.findIndex((hour) => hour === startTime);
            const duration = calculateDuration(startTimeEvent, finalTime);
            if (hourIndex !== -1) {
              newMatrix[hourIndex][dateIndex] = true;
              newMatrixTitle[hourIndex][dateIndex] = title;
              newMatrixDuration[hourIndex][dateIndex] = duration;
              newColorMatrix[hourIndex][dateIndex] = getColorFromFinding(finding);
            }
          }
        });
      });
      setDuratonMatrix(newMatrixDuration);
      setMatrix(newMatrix);
      setTitleMatrix(newMatrixTitle);
      setColorMatrix(newColorMatrix);
      setFlag(false);
    }
  }, [flag, selectedDate, flaged]);

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function formatDate(date) {
    const dayName = dayjs(date).format("dddd");
    const dayNumber = dayjs(date).format("D");
    return `${capitalize(dayName)} ${dayNumber}`;
  }

  function calculateDuration(startTime, finalTime) {
    const initialTime = dayjs(startTime, "HH:mm");
    const finalTimeObj = dayjs(finalTime, "HH:mm");
    const durationInMinutes = finalTimeObj.diff(initialTime, "minute");
    const durationInHalfHours = Math.ceil(durationInMinutes / 30);
    return durationInHalfHours;
  }

  function handleEvent(eventOn) {
    if (eventOn !== true) {
      setShowModal(true);
    } else {
      setShowEditModal(true);
    }
  }

  return (
    <div className="d-flex flex-column container">
      <div className="row">
        <p className="col-1 h5 mt-1 text-center">Hora</p>
        {eventRange.map((i) => (
          <h4 className="col-custom h5 text-center" key={i}>
            {formatDate(dates[i - 1])}
          </h4>
        ))}
      </div>
      {hours.map((hour, hourIndex) => (
        <div className="row" key={hour}>
          <div className="col-1">
            <div className="d-flex align-items-center align-content-center justify-content-center">
              <p className="text-center">{hour}</p>
            </div>
          </div>
          {matrix &&
            matrix[hourIndex] &&
            eventRange.map((i) => (
              <div
                className="col-custom border-bottom border-right justify-content-center"
                onClick={() => handleEvent(matrix[hourIndex][i - 1])}
                key={i}
              >
                <Hour
                  title={titleMatrix[hourIndex][i - 1]}
                  eventOn={matrix[hourIndex][i - 1]}
                  duration={durationMatrix[hourIndex][i - 1]}
                  id={i}
                  idx={hourIndex}
                  color={colorMatrix[hourIndex][i - 1]}
                />
              </div>
            ))}
        </div>
      ))}
    </div>
  );
}
