import React from "react";

const TrMissingAspect = ({ impact, activity, activityIndex, actions }) => {
  function setRowspanImpact(impact) {
    return impact.activities.length * impact.impact_actions.length;
  }

  function setRowspanAction(impact) {
    return impact.impact_actions.length;
  }

  if (!actions || actions.length === 0) {
    return (
      <tr>
        {activityIndex === 0 && (
          <td
            className="align-middle text-center"
            rowSpan={setRowspanImpact(impact)}
          >
            {impact.all_procedures ? (
              <span>Todos los procesos</span>
            ) : (
              <>
                {impact.procedures.map((procedure) => (
                  <span className="d-block" key={procedure.id}>
                    {procedure.title}
                  </span>
                ))}
              </>
            )}
          </td>
        )}

        <td className="text-center align-middle">{activity.title}</td>

        <td className="text-center align-middle font-weight-bold">
          {activity.situation === 0 ? "x" : ""}
        </td>

        <td className="text-center align-middle font-weight-bold">
          {activity.situation === 1 ? "x" : ""}
        </td>

        <td className="text-center align-middle font-weight-bold">
          {activity.situation === 2 ? "x" : ""}
        </td>

        {/* aspect */}
        <td></td>
        <td></td>

        {/* evaluation */}
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>

        {/* action */}
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  } else {
    return (
      <>
        {actions.map((action, actionIndex) => {
          return (
            <tr key={`impact-${impact.id}-action-${action.id}`}>
              {activityIndex === 0 && actionIndex === 0 && (
                <td
                  className="align-middle text-center"
                  rowSpan={setRowspanImpact(impact)}
                >
                  {impact.all_procedures ? (
                    <span>Todos los procesos</span>
                  ) : (
                    <>
                      {impact.procedures.map((procedure) => (
                        <span className="d-block" key={procedure.id}>
                          {procedure.title}
                        </span>
                      ))}
                    </>
                  )}
                </td>
              )}
              {actionIndex === 0 && (
                <>
                  <td
                    rowSpan={setRowspanAction(impact)}
                    className="text-center align-middle"
                  >
                    {activity.title}
                  </td>

                  <td
                    rowSpan={setRowspanAction(impact)}
                    className="text-center align-middle font-weight-bold"
                  >
                    {activity.situation === 0 ? "x" : ""}
                  </td>
                  <td
                    rowSpan={setRowspanAction(impact)}
                    className="text-center align-middle font-weight-bold"
                  >
                    {activity.situation === 1 ? "x" : ""}
                  </td>
                  <td
                    rowSpan={setRowspanAction(impact)}
                    className="text-center align-middle font-weight-bold"
                  >
                    {activity.situation === 2 ? "x" : ""}
                  </td>
                  {/* aspect */}
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  {/* evaluation */}
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                </>
              )}
              {activityIndex === 0 && (
                <>
                  {/* action */}
                  <td className="text-center align-middle">
                    {action.action_title}
                  </td>
                  <td className="text-center align-middle">
                    {action.action_responsible}
                  </td>
                  <td className="text-center align-middle">
                    {action.action_start_date}
                  </td>
                  <td className="text-center align-middle">
                    {action.action_end_date}
                  </td>
                  <td className="text-center align-middle">
                    {action.action_evidence}
                  </td>
                </>
              )}
            </tr>
          );
        })}
      </>
    );
  }
};

export default TrMissingAspect;
