import React, { useState, useContext } from "react";
import { LabortoryContext } from "../LabortoryContext";
import { parse } from "dotenv";
import { FormDefinitionLaboratory } from "../FormDefinitionLaboratory";
import { DetermineLaboratoryParam } from "../DetermineLaboratoryParam";

function EditModal() {
  const { 
    datatypeLaboratory,
    updateTypeLaboratory,
    errorResponse,
    setOpenEditModal

  } = useContext(LabortoryContext);

  const [name, setName] = useState(datatypeLaboratory?.name || "");
  const [objective, setObjective] = useState(datatypeLaboratory?.objective || "");
  const [qualifiedpersonnelrequired, setQualifiedpersonnelrequired] = useState(datatypeLaboratory?.qualified_personnel_required || "");
  const [standard, setStandard] = useState(datatypeLaboratory?.standard || "");
  const [acceptanceCriteria, setAcceptanceCriteria] = useState(datatypeLaboratory?.acceptance_criteria || "");
  const [definitions, setDefinitions] = React.useState(datatypeLaboratory?.definition_laboratories || []);
  const [determinParams, setDetermineParams] = React.useState(datatypeLaboratory?.determine_laboratory_params ||[])
  const [method, setMethod] = React.useState(datatypeLaboratory?.method || '')

  const onSubmit = (e) => {
    e.preventDefault();
    const newLaboratoryTest ={
      name,
      objective,
      qualified_personnel_required: qualifiedpersonnelrequired,
      standard,
      acceptance_criteria: acceptanceCriteria,
      method, method,
      definition_laboratories_attributes: definitions.map((definition) => ({
        id: definition?.typeSendData == 'create' ? '' : definition.id,
        description: definition.description,
        _destroy: definition._destroy
      })),
      determine_laboratory_params_attributes: determinParams.map((determine) => ({
        id: determine?.typeSendData == 'create' ? '' : determine.id,
        name: determine.name,
        description: determine.description,
        _destroy: determine._destroy
      }))
    }
    updateTypeLaboratory(datatypeLaboratory.id, newLaboratoryTest)
  };

  const onCancel = () => {
    setOpenEditModal(false)
  };

  const addDefinition = () => {
    const newDefinition = {
      id: crypto.randomUUID(),
      description: '',
      typeSendData: 'create',
      _destroy: false
    };
    setDefinitions([...definitions, newDefinition]);
  };

  const addDetermin = () => {
    const newDetermin ={
      id: crypto.randomUUID(),
      name: '',
      description: '',
      typeSendData: 'create',
      _destroy: false
    }
    setDetermineParams([...determinParams, newDetermin])
  }

  const updateDefinitionDescription = (id, newDescription) => {
    setDefinitions(
      definitions.map((definition) => 
        definition.id === id ? { ...definition, description: newDescription } : definition
      )
    );
  };

  const updateDeterminName = (id, newName) => {
    setDetermineParams(
      determinParams.map((determin) =>
        determin.id === id ? {...determin, name: newName} : determin
      )
    )
  }

  const updateDeterminDescription = (id, newDescription) => {
    setDetermineParams(
      determinParams.map((determin) =>
        determin.id === id ? { ...determin, description: newDescription } : determin
      )
    )
  };
  
  const removeDefinition = (id) => {
    setDefinitions(
      definitions.map((definition) =>
        definition.id === id ? { ...definition, _destroy: true } : definition
      )
    );
  };

  const removeDetermin = (id) => {
    setDetermineParams(
      determinParams.map((determin) =>
        determin.id === id ? {...determin, _destroy: true } : determin
      )
    );
  };

  return (
    <div
      className="modal fade show"
      tabIndex="-1"
      style={{ paddingRight: '4px', display: 'block', backgroundColor: 'rgba(3, 3, 3, 0.5)' }}
      role="document"
    >
      <form onSubmit={onSubmit}>
        <div className="modal-dialog modal-dialog-scrollable" role="document" style={{ maxWidth: '700px' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Editar Tipo de Prueba de Laboratorio</h5>
            </div>
            <div className="modal-body">
              {errorResponse.length > 0 && (
                <div className="alert alert-danger" role="alert">
                  <ul>
                    {errorResponse.map((error, index) => (
                      <li key={index} style={{ color: 'black' }}>
                        {error}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-file-alt"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="Nombre"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-file-alt"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="Objetivo"
                    className="form-control"
                    value={objective}
                    onChange={(e) => setObjective(e.target.value)}
                  />
                </div>
              </div>
              <h6>Definiciones</h6>
              {definitions.map((definition) => (
                !(definition._destroy) && <FormDefinitionLaboratory
                  key={definition.id}
                  id={definition.id}
                  description={definition.description}
                  onNameChange={updateDefinitionDescription}
                  onRemove={removeDefinition}
                />
              ))}
              <div>
                <button 
                  type="button" 
                  className="btn btn-info btn-sm mb-3"
                  onClick={addDefinition}
                >
                  <i className="fas fa-plus mr-1">Agregar</i>
                </button>
              </div>
              <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-file-alt"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="Personal Cualificado Necesario"
                    className="form-control"
                    value={qualifiedpersonnelrequired}
                    onChange={(e) => setQualifiedpersonnelrequired(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-file-alt"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="Norma regional, nacional o internacional en la que se basa"
                    className="form-control"
                    value={standard}
                    onChange={(e) => setStandard(e.target.value)}
                  />
                </div>
              </div>
              <h6>Parámetros por determinar</h6>
              {determinParams.map((determine) => (
                !(determine._destroy) && <DetermineLaboratoryParam
                  key={determine.id}
                  id={determine.id}
                  name={determine.name}
                  description={determine.description}
                  onNameChange={updateDeterminName}
                  onDescriptionChange={updateDeterminDescription}
                  onRemove={removeDetermin}
                />
              ))}
              <div className="mt-2">
                <button 
                  type="button" 
                  className="btn btn-info btn-sm mb-3"
                  onClick={addDetermin}
                >
                  <i className="fas fa-plus mr-1">Agregar</i>
                </button>
              </div>
              <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-file-alt"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="Criterios de aceptación"
                    className="form-control"
                    value={acceptanceCriteria}
                    onChange={(e) => setAcceptanceCriteria(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-file-alt"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="Método Utilizado:"
                    className="form-control"
                    value={method}
                    onChange={(e) => setMethod(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-link" onClick={onCancel}>
                Cancelar
              </button>
              <button className="btn btn-primary mr-1" type="submit">
                Guardar Cambios
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export { EditModal };
