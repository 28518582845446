import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['fillTask', 'selectTask', 'averageTask']

  connect () {
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()
    this.hideAll()
    if ($(this.fillTaskTarget).prop('checked')) {
      $('#fillOutForm').show()
      $('#complianceLevel').show()
    } else if ($(this.selectTaskTarget).prop('checked')) {
      $('#selectForm').show()
      $('#complianceLevel').hide()
    } else if ($(this.averageTaskTarget).prop('checked')) {
      $('#averageForm').show()
      $('#fillOutForm').show()
      $('#complianceLevel').hide()
    }
  }

  hideAll (e) {
    if (e) e.preventDefault()
    $('#selectForm').hide()
    $('#fillOutForm').hide()
    $('#averageForm').hide()
  }
}
