import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['button', 'buttonone', 'buttontwo', 'buttonthree', 'buttonNA', 'buttonAction']

  connect () {
  }

  buttonOne() {
    this.onChange(100)
    $(this.buttononeTarget).prop('className', 'btn btn-outline-success btn-sm mr-1')
    $(this.buttonActionTarget).prop('className', 'btn btn-info disabled btn-sm')
  }

  buttonTwo() {
    this.onChange(50)
    $(this.buttontwoTarget).prop('className', 'btn btn-outline-warning btn-sm mr-1')
    $(this.buttonActionTarget).prop('className', 'btn btn-info btn-sm')
  }

  buttonThree() {
    this.onChange(0)
    $(this.buttonthreeTarget).prop('className', 'btn btn-outline-danger btn-sm mr-1')
    $(this.buttonActionTarget).prop('className', 'btn btn-info btn-sm')
  }

  buttonFour() {
    this.onChange('NA')
    $(this.buttonNATarget).prop('className', 'btn btn-outline-info btn-sm mr-1')
    $(this.buttonActionTarget).prop('className', 'btn btn-info disabled btn-sm')
  }

  onChange(value) {
    this.clearClass()
    const chapter = this.data.get('chapter')
    const url = this.data.get('url')
    const elementId = this.data.get('question')
    $.get(url+'?value='+value+'&question_id='+elementId).then((content) => {
      document.getElementById('spanchapter'+ chapter).innerHTML = content
    })
  }

  clearClass() {
    $(this.buttononeTarget).prop('className', 'btn btn-success btn-sm mr-1')
    $(this.buttontwoTarget).prop('className', 'btn btn-warning btn-sm mr-1')
    $(this.buttonthreeTarget).prop('className', 'btn btn-danger btn-sm mr-1')
    $(this.buttonNATarget).prop('className', 'btn btn-info btn-sm mr-1')            
  }
}
