import { Controller } from 'stimulus'
import Chart from 'chart.js'

export default class extends Controller {
  connect () {
    let { points } = this.element.dataset

    points = JSON.parse(points)

    const labels = points.map(({ chapter }) => chapter)
    const data = points.map(({ value }) => value)
    const colors = points.map(({ color }) => color)
    const total = points.map(({ total }) => total)
    this.ctx = this.element.getContext('2d')
  
    this.options = {
      type: 'horizontalBar',
      responsive: true,
      maintainAspectRatio: false,
      data: {
        
        labels,
        datasets: [
          {
            label: 'Porcentaje',
            pointBorderWidth: 5,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: true,
            backgroundColor: colors,
            borderWidth: 2,
            data
          },
        ]
      },
      options: {
        tooltips: {
          titleFontFamily: 'Source Sans Pro',
          titleFontSize: 16,
          bodyFontSize: 16,
          xPadding: 10,
          yPadding: 10,
          callbacks: {
            label: function (tooltipItems, data) {
                 return  'Puntos:' + tooltipItems.xLabel;
            }}
        },
        animation: {
          easing: 'easeInOutBack'
        },
        legend: {
          display: false,
          position: 'top'
        },
        scales: {
          yAxes: [{
            ticks: {
              fontColor: 'rgba(0,0,0,0.5)',
              fontStyle: 'bold',
              beginAtZero: true,
              maxTicksLimit: 10,
              max: 100,
              min: 0,
              padding: 20,
              thickness: 2
            },
            gridLines: {
              zeroLineColor: 'rgba(0,0,0,0.5)',
              drawTicks: true,
              display: true
            },
            scaleLabel: {
              display: true,
              //labelString: 'Resultados %'
            }
          }],
          xAxes: [{
            
            gridLines: {
              zeroLineColor: 'rgba(0,0,0,0.5)',
              drawTicks: true,
              display: true
              
            },
            ticks: {
              padding: 10,
              max: 5,
              min: 0,
              beginAtZero: true,
              fontColor: 'rgba(0,0,0,0.5)',
              fontStyle: 'bold'
            }
          }]
        }
      }
    }
    this.chart = new Chart(this.ctx, this.options)
  }
}
