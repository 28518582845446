import React, { useState, useEffect } from 'react'
import Chart from './Chart'
const CardList = ({text, value, color, modalControl, query, showchart, parent }) => {
  var button = ''
  if (query == 'none') { button = 'disabled' }
  return(<a className={button} type='button' onClick={()=>modalControl(true, parent, text, query)}>
          <li className='list-group-item list-group-item-action d-flex justify-content-between cursor-pointer'>
            <div>{text}</div>
            {!showchart && <h6 className='card-title text-center mt-4'>Sin Mediciones Activas</h6> }
            {showchart && <Chart
              progress={value}
              size={60}
              fontSize={'12px'}
              strokeWidth={4}
              circleTwoStroke={color}
            />}
          </li>
        </a>)
}

export default CardList