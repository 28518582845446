import React, { useContext, useState, useEffect } from "react";
import logo from "../assets/logo.png";
import Select from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";
import GlobalContext from "../context/GlobalContext";
import CreateEventButton from "./CreateEventButton";
import dayjs from "dayjs";

export default function CalendarHeader() {
  const {
    selectedDate,
    setSelectedDate,
    currentDay,
    selectedMonth,
    setSelectedMonth,
    typeTime,
    setTypeTime,
    selectedYear,
    setSelectedYear,
    setSelectedPeriodicity,
  } = useContext(GlobalContext);
  const [typeOptions, setTypeOptions] = useState([]);
  const [selectedType, setSelectedType] = useState(typeTime);
  const [optionsPeriodicities, setOptionsPeriodicities] = useState([]);
  const [periodicities, setPeriodicities] = useState([]);
  const [selectedPeriodicities, setSelectedPeriodicities] = useState();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Cambia el valor 768 según tus necesidades
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const options = [
      { value: 0, label: "Agenda" },
      { value: 1, label: "Dia" },
      { value: 2, label: "Semana" },
      { value: 3, label: "Mes" },
      { value: 4, label: "Año" },
      { value: 5, label: "Eventos periodicos" },
    ];
    setTypeOptions(options);
  }, []);

  useEffect(() => {
    fetch("/api/v2/periodicities/")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setPeriodicities(data);
      });
  }, []);

  useEffect(() => {
    const optionPeriodicities = periodicities.map((periodicities) => ({
      value: periodicities.id,
      label: periodicities.name,
    }));
    setOptionsPeriodicities(optionPeriodicities);
  }, [periodicities]);

  const handlePeriocity = (selectedOption) => {
    setSelectedPeriodicity(selectedOption.value);
    setSelectedPeriodicities(selectedOption);
  };

  const handleTypeChange = (selectedOption) => {
    setTypeTime(selectedOption.value);
    setSelectedType(selectedOption);
  };

  function handlePrevMonth() {
    if (typeTime === 3) {
      setSelectedMonth(selectedMonth - 1);
    } else if (typeTime === 4) {
      setSelectedYear(selectedYear - 1);
    } else {
      setSelectedDate(dayjs(selectedDate).add(-1, "day"));
    }
  }

  function handleNextMonth() {
    if (typeTime === 3) {
      setSelectedMonth(selectedMonth + 1);
    } else if (typeTime === 4) {
      setSelectedYear(selectedYear + 1);
    } else {
      setSelectedDate(dayjs(selectedDate).add(1, "day"));
    }
  }

  function handleReset() {
    setSelectedDate(currentDay);
  }

  function handleResetMonth() {
    setSelectedMonth(5);
  }

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function formatDate(date) {
    const dayName = dayjs(date).format("dddd");
    const dayNumber = dayjs(date).format("D");
    const monthName = dayjs(date).format("MMMM");
    return `${capitalize(dayName)} ${dayNumber} de ${capitalize(
      monthName
    )} del ${dayjs(date).format("YYYY")}`;
  }

  return (
    <div className="bg-dark w-100 d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <img
          src={logo}
          alt="calendar"
          className="mr-2"
          style={{ width: 40, height: 40 }}
        />
        <h4 className="text-white font-bold mb-0">ISWO Calendar</h4>
        <button
          onClick={handleReset}
          className="btn btn-outline-secondary border rounded py-1 px-3 me-2 m-3"
        >
          Hoy
        </button>
        <button
          className="btn btn-outline-secondary border rounded py-1 me-1 bg-transparent"
          onClick={handlePrevMonth}
        >
          <span>&lt;</span>
        </button>
        {typeTime === 3 ? (
          <button
            onClick={handleResetMonth}
            className="btn btn-outline-secondary border rounded py-1 px-3 me-2 m-3"
          >
            Mes actual
          </button>
        ) : (
          <></>
        )}
        {isMobile && <CreateEventButton />}
        {typeTime === 4 ? (
          <button
            onClick={handleResetMonth}
            className="btn btn-outline-secondary border rounded py-1 px-3 me-2 m-3"
          >
            Año actual
          </button>
        ) : (
          <></>
        )}
        <button
          className="btn btn-outline-secondary border rounded py-1 me-1 bg-transparent"
          onClick={handleNextMonth}
        >
          <span>&gt;</span>
        </button>
        <p className="text text-white font-bold m-1">
          {typeTime === 4 ? ` Año: ${selectedYear}` : formatDate(selectedDate)}
        </p>
      </div>
      <div className="d-flex align-items-center justify-content-center mr-5">
        {typeTime === 5 && (
          <Select
            className="my-select mr-2"
            options={optionsPeriodicities}
            name="[periodicities_id]"
            value={selectedPeriodicities}
            onChange={handlePeriocity}
            placeholder={
              selectedPeriodicities
                ? selectedPeriodicities.label
                : "Periodicidad"
            }
            formatOptionLabel={({ label }) => <span>{label}</span>}
          />
        )}
        <Select
          options={typeOptions}
          name="[type_id]"
          value={selectedType}
          onChange={handleTypeChange}
          placeholder={selectedType ? selectedType.label : "Tipo"}
          formatOptionLabel={({ label }) => <span>{label}</span>}
        />
      </div>
    </div>
  );
}
