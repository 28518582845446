import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  Form,
  Button,
  Row,
  Col,
  InputGroup,
  FormLabel,
  Dropdown,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCalendarDays,
  faArrowLeft,
  faFileLines,
  faPercent,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { FlashErrors } from "./Flash/FlashAlerts";
import {
  FLASH_TYPE_ERROR,
  FLASH_TYPE_SUCCESS,
  FlashContext,
  useFlash,
} from "./Context/FlashContext";
import Uploader from "./Uploader";
import errorFieldMapFollow from "../utils/errorsFollow.json";

const NewFollow = () => {
  const [formData, setFormData] = useState({
    action_id: actionId,
    employee_id: "",
    percentage: "",
    end_date: "",
    achievement_id: "",
    finding: "",
    evidence: "",
    file: "",
  });
  const [usersApi, setUsersApi] = useState([]);
  const [stateDate, setStateDate] = useState(true);
  const [achievements, setAchievements] = useState([]);
  const [filter, setFilter] = useState("");
  const navigate = useNavigate();
  const { setFlash } = useFlash();

  const { actionId, troubleId, investigationId } = useParams();

  const fetchAchievements = async () => {
    const url = "/api/v2/achievements";
    const response = await axios.get(url);
    const data = response.data;
    setAchievements(data);
  };

  useEffect(() => {
    fetchAchievements();
  }, []);
  useEffect(() => {
    fetchUsersApi();
  }, []);

  const fetchUsersApi = async () => {
    try {
      const response = await axios.get("/api/v2/users");
      if (response.status === 200) {
        const data = await response.data;
        setUsersApi(data);
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name === "percentage") {
      setStateDate(value !== "100");
    }
  };

  const handleFileUpload = (file) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      file: file,
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    const url = `/api/v2/troubles/${troubleId}/investigations/${investigationId}/actions/${actionId}/follows`;
    const payload = formData;
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    if (!payload.file) {
      delete payload.file;
    }

    axios
      .post(url, payload, {
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setFlash({
          type: FLASH_TYPE_SUCCESS,
          message: "Elemento creado",
        });

        navigate(
          `/troubles/${troubleId}/investigation/${investigationId}/action/${actionId}/follows`
        );
      })
      .catch((error) => {
        let responseError = error.response.data;
        let errorMessages = {};
        for (const field in responseError) {
          if (errorFieldMapFollow[field]) {
            errorMessages[field] = `${errorFieldMapFollow[field]}`;
          }
        }

        setFlash({
          type: FLASH_TYPE_ERROR,
          message: Object.entries(errorMessages)
            .map(([key, value]) => `${value}`)
            .join("\n"),
        });

        console.error("Error: " + error.errorMessages);
      });
  };
  const filteredUsers = usersApi.results?.filter((user) =>
    user.name.toLowerCase().includes(filter.toLowerCase())
  );
  return (
    <Form onSubmit={handleSubmit} className="px-3 pt-3">
      <Form.Group
        as={Col}
        controlId="workflowState"
        style={{ maxWidth: "55%" }}
      >
        <Row className=" justify-content-between ">
          <Col style={{ flexGrow: 0 }}>
            <Link
              to={`/troubles/${troubleId}/investigation/${investigationId}/action/${actionId}/follows`}
            >
              <Button variant="light">
                <FontAwesomeIcon icon={faArrowLeft} color="#438dff" />
              </Button>
            </Link>
          </Col>

          <Col md="auto">
            <h4>Nuevo seguimiento</h4>
          </Col>
        </Row>
      </Form.Group>
      <FlashErrors />

      <Form.Group controlId="employeeId">
        <Form.Label> Persona que informa</Form.Label>
        <InputGroup style={{ width: "100%" }}>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faUser} />
          </InputGroup.Text>
          <Dropdown style={{ width: "100%" }}>
            <Dropdown.Toggle
              id="dropdown-basic"
              style={{
                width: "100%",
                backgroundColor: "transparent",
                color: "black",
                borderColor: "black",
              }}
            >
              {formData.employee_id
                ? usersApi.results?.find(
                    (user) => user.id === formData.employee_id
                  )?.name
                : "Selecciona un Usuario"}
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ width: "100%" }}>
              <input
                type="text"
                placeholder="Filtrar usuarios"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                style={{ width: "100%" }}
              />
              {filteredUsers?.length > 0 ? (
                filteredUsers?.map((user) => (
                  <Dropdown.Item
                    key={user.id}
                    onClick={() =>
                      handleInputChange({
                        target: { name: "employee_id", value: user.id },
                      })
                    }
                  >
                    {user.name}
                  </Dropdown.Item>
                ))
              ) : (
                <div style={{ padding: "10px" }}>
                  <span>No hay resultados</span>
                </div>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </InputGroup>
      </Form.Group>

      <Row className="align-items-center">
        <Col>
          <Form.Group controlId="percentage">
            <Form.Label>Porcentaje</Form.Label>
            <InputGroup>
              <InputGroup.Text placeholder="Porcentaje(*)">
                <FontAwesomeIcon icon={faPercent} />
              </InputGroup.Text>
              <Form.Control
                type="number"
                name="percentage"
                value={formData.percentage}
                onChange={handleInputChange}
                max={100}
                min={0}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col style={{ display: "flex", justifyContent: "center" }}>
          <Form.Group controlId="endDate">
            <Form.Label>Fecha Final de Cierre</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faCalendarDays} />
              </InputGroup.Text>

              <DatePicker
                selected={formData.end_date}
                onChange={(date) =>
                  handleInputChange({
                    target: { name: "end_date", value: date },
                  })
                }
                disabled={stateDate}
                dateFormat="yyyy/MM/dd"
                showYearDropdown
                dropdownMode="select"
                locale={es}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={"responsibleId"}>
            <FormLabel></FormLabel>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faFileLines} />
              </InputGroup.Text>
              <Form.Control
                as="select"
                name="achievement_id"
                value={formData.achievement_id}
                onChange={handleInputChange}
                custom
              >
                <option value="">Selecciona un logro</option>
                {achievements.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}-{item.description}
                  </option>
                ))}
              </Form.Control>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group controlId="finding">
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faFileLines} />
              </InputGroup.Text>
              <Form.Control
                as="textarea"
                rows={2}
                name="finding"
                placeholder="Hallazgo(*)"
                value={formData.finding}
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="evidence">
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faFileLines} />
              </InputGroup.Text>
              <Form.Control
                as="textarea"
                rows={2}
                name="evidence"
                placeholder="Evidencia(*)"
                value={formData.evidence}
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Uploader onChange={handleFileUpload} />

      <Col md="auto" className=" d-flex  justify-content-end px-0 pt-3 ">
        <Link
          to={`/troubles/${troubleId}/investigation/${investigationId}/action/${actionId}/follows`}
        >
          <Button className="mx-1 border-0 " variant="outline-primary">
            Cancelar
          </Button>
        </Link>
        <Button
          className="btn btn-primary mx-1"
          type="submit"
          onClick={handleInputChange}
          variant="primary"
        >
          Guardar
        </Button>
      </Col>
    </Form>
  );
};

export default NewFollow;
