import React from "react";
import ViewerV2 from "./ViewerV2";
import * as Sentry from "@sentry/react";

if (process.env.SENTRY_FRONTEND_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_FRONTEND_DSN,
    tracesSampleRate: 1.0,
    integrations: [Sentry.browserTracingIntegration()],
  });
}

const App = (props) => {
  return <ViewerV2 {...props} />;
};

export default App;
