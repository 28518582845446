import React from "react";

const PeriodicityModal = () => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th className="align-middle text-center">
            PARÁMETROS DE CALIFICACIÓN CUALITATIVA
          </th>
          <th className="align-middle text-center"> CALIFICACIÓN </th>
          <th className="align-middle text-center"> GRADO DE SIGNIFICANCIA </th>
          <th className="align-middle text-center"> DESCRIPCIÓN </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="align-middle text-justify" rowSpan="3">
            PERIODICIDAD - (PR)
          </td>
          <td className="align-middle text-center">
            IRREGULAR O PERIÓDICO DISCONTINUO
          </td>
          <td className="align-middle text-center">1</td>
          <td className="align-middle text-justify">
            Las manifestaciones impacto no se puede predecir
          </td>
        </tr>
        <tr>
          <td className="align-middle text-center">PERIÓDICO</td>
          <td className="align-middle text-center">2</td>
          <td className="align-middle text-justify">
            La manifestación del efecto se presenta de manera cíclica
          </td>
        </tr>
        <tr>
          <td className="align-middle text-center">CONTINUO</td>
          <td className="align-middle text-center">4</td>
          <td className="align-middle text-justify">
            El impacto se presenta constantemente desde que se inicio la
            actividad
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PeriodicityModal;
