import React, { useState } from "react";
import { InputGroup } from "../../InputGroup";
import axios from "axios";

function Status({portal, color}){
  const [email, setEmail] = useState('')
  const [indentification, setIndentification] = useState('')
  const [response, setResponse] = useState([])
  const [notpresent, setNotPresent] = useState('')
  const onSubmit = (e) => {
    e.preventDefault()
    const data = {
      email: email,
      indentification: indentification
    }
    findTaboratories(data)
  }

  const findTaboratories = async (datatosearch) =>{
    try{
      const response = await axios.get(`api/v2/laboratories/see_laboratories_created?indentification=${indentification}&email=${email}`)
      if (response.status === 200){
        console.log(response.data)
        if(response.data.length > 0){
          setResponse(response.data)
        }else{
          setNotPresent('No se encontraron registros con los datos ingresados')
        }
      }
    } catch(error){
      console.log('errores')
    }
  }

  const type_state = (data) => {
    let name = '';
    switch(data) {
        case 'rejected':
            name = 'No aprobada';
            break;
        case 'awaiting_presentation_and_closure':
            name = 'Esperando envío de informe y cierre';
            break;
        case 'awaiting_approved':
            name = 'Esperando aprobación';
            break;
        case 'awaiting_test_approval':
            name = 'Esperando la ejecución de pruebas'
            break;
        case 'returned_aprobate_test':
            name = 'No aprobada'
            break;
        case 'closed':
            name = 'Cerrada'
            break;
        default:
            name = 'Estado desconocido';
    }
    return name;
  }
  return(
    <React.Fragment>
      <a
        href="/main_laboratories?portal_id=6011faf0-0604-40c6-a347-c2aead8e50ce&layout=pqrsf&color=true"
        className="btn btn-secondary btn-sm floating-top-left rounded-circle"
      >
        <i className="fas fa-arrow-left"/>
      </a>
      <div>
        <div className="card">
          <div className="p-4">
            <div className="card">
              <div className="card-header bg-white">
                {portal.short_name}
              </div>
              <div className="card-body p-4">
                <h1 className="font-weight-bold text-center">
                  {portal.short_name}
                </h1>
                <p>
                  Ingresa tu email y número de identificación para encontrar tus solicitudes de laboratorio
                </p>
                <form onSubmit={onSubmit}>
                  <InputGroup iconClass="fas fa-envelope" label="Email">
                    <input
                      type="text"
                      className="form-control"
                      name="Descripción Solicitud"
                      placeholder="Descripción Punto Toma de Muestra"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup iconClass="fas fa-id-card" label="Identificación">
                    <input
                      type="text"
                      className="form-control"
                      name="Descripción Solicitud"
                      placeholder="Descripción Punto Toma de Muestra"
                      onChange={(e) => setIndentification(e.target.value)}
                    />
                  </InputGroup>
                  <button
                    className="btn btn-primary btn-block"
                    type="submit"
                    style={{backgroundColor: color}}
                  >
                    Buscar
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="card">
            {response.length > 0 ? (
              <div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center align-middle">
                        Descripción Solicitud 
                      </th>
                      <th className="text-center align-middle">
                        Cantidad por Lotes
                      </th>
                      <th className="text-center align-middle">
                        Unidades por Lote
                      </th>
                      <th className="text-center align-middle">
                        Estado
                      </th>
                      <th className="text-center align-middle">
                        Pruebas seleccionadas
                      </th>
                      <th className="text-center align-middle">
                        Adjunto Respuesta
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {response.map((data, index) => (
                      <tr key={index}>
                        <td>
                          {data.description_application}
                        </td>
                        <td>
                          {data.number_lots}
                        </td>
                        <td>
                          {data.units_lot}
                        </td>
                        <td>
                          { type_state(data.state) }
                        </td>
                        <td>
                          {data.request_tests.map((request, index) => (
                            <p key={index}>{request.name}</p>
                          ))}
                        </td>
                        <td>
                          { data?.file_report && (
                            <Tooltip text={"Informe Final"}>
                              <a
                                href={data.file_report}
                                className={`btn btn-outline-info btn-sm mr-2`}
                                target="_blank"                 
                              >
                                <i className="far fa-file" />
                              </a>
                            </Tooltip>
                            )
                          }
                          { data?.file_report_edit && (
                            <Tooltip text={"Informe Editado"}>
                              <a
                                href={data.file_report_edit}
                                className={`btn btn-outline-info btn-sm mr-2`}
                                target="_blank"                 
                              >
                                <i className="far fa-file" />
                              </a>
                            </Tooltip>
                          )}
                          {data?.file_report && data?.file_report_edit ? '' : (
                            <p>
                              No se ha generado ningún informe.
                            </p>
                          ) }
                        </td>
                      </tr>
                    )
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="text-center pt-4">
                {notpresent}
              </p>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}


export default Status