import React, {useState} from 'react'

const DateInput = ({dateFilter}) => {
  const [dateinput, setDateInput] = useState('')
  const filter = () => {
    dateFilter(dateinput)
  }
  return (
    <div className='form-inline my-2'>
      <div className='form-group col-md-3 mb-0'>
        <input type='date' placeholder='aaaa-mm-dd' className='form-control mr-2' onChange={(e)=>setDateInput(e.target.value)}>
        </input>
        <button type='button' className='btn btn-primary' onClick={()=>filter()}>Filtrar</button>
      </div>
    </div>
  )
}

export default DateInput