import React, {useEffect, useState, useRef} from 'react'
import Chart from "./Chart"
import ProgressLine from "./ProgressLine"
const General = (props) => {  
  const { percentage, color, types } = props
  return (
    <>
      <h2 className='cart-title text-center'>General</h2>
      <div className='my-3'>
        <Chart
          progress={percentage}
          size={80}
          strokeWidth={6}
          circleTwoStroke={color}
        />
      </div>
      <ProgressLine percentage={percentage} color={color} text='Tipos de Procesos: ' count={types}/>
    </>
  )

}

export default General