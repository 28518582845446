import React, { useState } from 'react'

const WorkflowState = ({auditProgramReport}) => {

  return (
    <>
      {auditProgramReport.workflow_state === 'draft' ? (
        <span className='badge-warning mr-1 pl-2 pr-2 rounded'>
        Borrador
        </span>
        ):( null )
      }
      {auditProgramReport.workflow_state === 'reviewed' ? (
        <span className='badge-warning mr-1 pl-2 pr-2 rounded'>
        Revisado
        </span>
        ):( null )
      }
      {auditProgramReport.workflow_state === 'approved' ? (
        <span className='badge-warning mr-1 pl-2 pr-2 rounded'>
        Aprobado
        </span>
        ):( null )
      }
      {auditProgramReport.workflow_state === 'returned' ? (
        <span className='badge-warning mr-1 pl-2 pr-2 rounded'>
        Devuelto
        </span>
        ):( null )
      }
      {auditProgramReport.workflow_state === 'awaiting_review' ? (
        <span className='badge-warning mr-1 pl-2 pr-2 rounded'>
        Por revisar
        </span>
        ):( null )
      }
      {auditProgramReport.workflow_state === 'awaiting_approve' ? (
        <span className='badge-warning mr-1 pl-2 pr-2 rounded'>
        Por aprobar
        </span>
        ):( null )
      }
      {auditProgramReport.workflow_state === 'awaiting_update_version' ? (
        <span className='badge-warning mr-1 pl-2 pr-2 rounded'>
        Esperando Actualización
        </span>
        ):( null )
      }
    </>
  )
}

export default WorkflowState