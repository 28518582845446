import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['all']

  connect () {
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()
    const elementId = this.data.get('target')
    if ($(this.allTarget).prop('checked')) {
      $('#responseTextField').show('slow')
      $('#' + elementId).hide('slow')
    } else {
      $('#' + elementId).show('slow')
      $('#responseTextField').hide('slow')
    }
  }
}
