import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['select', 'status']

  connect () {
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()

    if ((this.selectTarget.value == 'approved') || (this.selectTarget.value == 'checked')) {
      $('#negativecheckObservations').hide()
      $(this.statusTarget).prop('value', 5)
    } else {
      $('#negativecheckObservations').show()
      $(this.statusTarget).prop('value', 3)
    }
  }
}
