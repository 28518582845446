import React from "react";

const MomentumModal = () => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th className="align-middle text-center">
            PARÁMETROS DE CALIFICACIÓN CUALITATIVA
          </th>
          <th className="align-middle text-center"> CALIFICACIÓN </th>
          <th className="align-middle text-center"> GRADO DE SIGNIFICANCIA </th>
          <th className="align-middle text-center"> DESCRIPCIÓN </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="align-middle text-justify" rowSpan="5">
            MOMENTO - (MO) Es el tiempo que trascurre entre el inicio de la
            Actividad y la aparición del impacto sobre el elemento del medio
            considerado
          </td>
          <td className="align-middle text-center">LARGO PLAZO</td>
          <td className="align-middle text-center">1</td>
          <td className="align-middle text-justify">
            Si el efecto tarda en manifestarse más de cinto años.
          </td>
        </tr>
        <tr>
          <td className="align-middle text-center">MEDIANO PLAZO</td>
          <td className="align-middle text-center">2</td>
          <td className="align-middle text-justify">
            Si se manifiesta entre uno a cinco años
          </td>
        </tr>
        <tr>
          <td className="align-middle text-center">CORTO PLAZO</td>
          <td className="align-middle text-center">4</td>
          <td className="align-middle text-justify">
            Si el impacto se presenta en menos de un año
          </td>
        </tr>
        <tr>
          <td className="align-middle text-center">INMEDIATO</td>
          <td className="align-middle text-center">8</td>
          <td className="align-middle text-justify">
            si el impacto ocurre una vez se inicie la actividad que lo genera
          </td>
        </tr>
        <tr>
          <td className="align-middle text-center">CRÍTICO</td>
          <td className="align-middle text-center">12</td>
          <td className="align-middle text-justify">
            Si ocurriese alguna circunstancia que hiciese crítico el momento del
            impacto, se le atribuye un valor de cuatro unidades por encima de
            las especificadas.
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default MomentumModal;
