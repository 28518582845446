import React, { useState, useEffect, useContext, useReducer } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  Form,
  Button,
  Row,
  Col,
  Stack,
  InputGroup,
  Container,
  Fade,
  Dropdown,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faBuilding,
  faCalendarDays,
  faFileInvoice,
  faArrowLeft,
  faFileLines,
  faPlus,
  faCoins,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parseISO } from "date-fns";
import es from "date-fns/locale/es";
import { FlashErrors } from "./Flash/FlashAlerts";
import {
  FLASH_TYPE_ERROR,
  FLASH_TYPE_NOTICE,
  useFlash,
} from "./Context/FlashContext";
import LoaderPagination from "./Skeleton/LoaderPagination";
import { AppContext } from "./Context/AppContext";
import errorFieldMapAction from "../utils/errorsAction.json";

const UpdateAction = () => {
  const [usersApi, setUsersApi] = useState([]);
  const [resourcesApi, setResourcesApi] = useState([]);
  const [resourcesAttributes, setResourcesAttributes] = useState([]);
  const [openResources, setOpenResources] = useState(true);
  const [open, setOpen] = useState(true);
  const [assignmentsAttributes, setAssignmentAttributes] = useState([]);
  const [formData, setFormData] = useState({
    activity: "",
    budget: "",
    initial_date: "",
    end_date: "",
    responsible_id: "",
    resources: [],
    assignments_attributes: [],
  });
  const navigate = useNavigate();
  const { troubleId, investigationId, id } = useParams();

  const { loading, setLoading } = useContext(AppContext);
  const { setFlash } = useFlash();

  useEffect(() => {
    fetchUsersApi();
  }, []);

  const fetchUsersApi = async () => {
    try {
      const response = await axios.get("/api/v2/users");
      if (response.status === 200) {
        const data = await response.data;
        setUsersApi(data);
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    fetchTroubleAction();
  }, []);

  useEffect(() => {
    fetchResources();
  }, []);

  const fetchResources = async () => {
    try {
      const response = await axios.get("/api/v2/resources_troubles");
      if (response.status === 200) {
        const data = await response.data;
        setResourcesApi(data);
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      setFlash({
        type: FLASH_TYPE_ERROR,
        message: "Ocurrio un error en el servidor",
      });
      console.error("Error", error);
    }
  };

  const fetchTroubleAction = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/api/v2/troubles/${troubleId}/investigations/${investigationId}/actions/${id}`
      );
      if (response.status === 200) {
        const data = await response.data;
        setFormData(data);
        setAssignmentAttributes(
          data.assignments.map((assignment) => ({
            id: assignment.id,
            action: assignment.action,
            user_id: assignment.user_id,
          }))
        );
        setResourcesAttributes(
          data.resources.map((resource) => ({ id: resource.id }))
        );
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      setFlash({
        type: FLASH_TYPE_ERROR,
        message: "Ocurrio un error en el servidor",
      });

      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const url = `/api/v2/troubles/${troubleId}/investigations/${investigationId}/actions/${id}`;
    const payload = formData;
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    payload.assignments_attributes = assignmentsAttributes;
    payload.resources = resourcesAttributes;

    axios
      .put(url, payload, {
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setFlash({
          type: FLASH_TYPE_NOTICE,
          message: "Elemento Actualizado",
        });

        navigate(
          `/troubles/${troubleId}/investigation/${investigationId}/actions`
        );
      })
      .catch((error) => {
        let responseError = error.response.data;
        let errorMessages = {};
        for (const field in responseError) {
          if (errorFieldMapAction[field]) {
            errorMessages[field] = `${errorFieldMapAction[field]}`;
          }
        }

        setFlash({
          type: FLASH_TYPE_ERROR,
          message: Object.entries(errorMessages)
            .map(([key, value]) => `${value}`)
            .join("\n"),
        });

        console.error("Error: ", error.errorMessages);
      });
  };

  const handleAddUserClick = (action) => () => {
    setAssignmentAttributes((old) => {
      return [...old, { user_id: "", action: action }];
    });

    setOpen(true);
  };

  const updateAssingmentAttribute = (index, user) => (event) => {
    setAssignmentAttributes((old) => {
      return [
        ...old.slice(0, index),
        {
          id: user.id,
          user_id: event.target.value,
          action: user.action,
        },
        ...old.slice(index + 1),
      ];
    });
  };

  const handleRemoveUserClick = (index, user) => {
    setAssignmentAttributes((old) => {
      return [
        ...old.slice(0, index),
        {
          id: user.id,
          user_id: user.user_id,
          action: user.action,
          _destroy: "true",
        },
        ...old.slice(index + 1),
      ];
    });
  };
  const handleAddUResourceClick = () => () => {
    setResourcesAttributes((old) => {
      return [...old, { id: "" }];
    });

    setOpenResources(true);
  };
  const updateResourceAttribute = (index, name) => (event) => {
    setResourcesAttributes((old) => {
      return [
        ...old.slice(0, index),
        { id: event.target.value, name: name },
        ...old.slice(index + 1),
      ];
    });
  };

  const handleRemoveResourceClick = (index, name, id) => {
    const indexElement = [...resourcesAttributes];
    indexElement.splice(index, 1), { id: id, name: name, _destroy: "true" };
    setResourcesAttributes(indexElement);
  };
  const [userFiltersReview, dispatchUserFiltersReview] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {}
  );
  const [userFiltersProduce, dispatchUserFiltersProduce] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {}
  );

  useEffect(() => {
    fetchUsersApi();
  }, []);

  return (
    <Form onSubmit={handleSubmit} className="px-3 pt-3">
      <Form.Group
        as={Col}
        controlId="workflowState"
        style={{ maxWidth: "55%" }}
      >
        <Row className=" justify-content-between ">
          <Col style={{ flexGrow: 0 }}>
            <Link
              to={`/troubles/${troubleId}/investigation/${investigationId}/actions`}
            >
              <Button variant="light">
                <FontAwesomeIcon icon={faArrowLeft} color="#438dff" />
              </Button>
            </Link>
          </Col>

          <Col md="auto">
            <h4>Editar acción preventiva/correctiva</h4>
          </Col>
        </Row>
      </Form.Group>
      <FlashErrors />

      {loading ? (
        <LoaderPagination />
      ) : (
        <>
          <Form.Group controlId="activity">
            <Form.Label>Actividad o procedimiento</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faFileLines} />
              </InputGroup.Text>
              <Form.Control
                as="textarea"
                rows={3}
                name="activity"
                value={formData.activity}
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="budget">
            <Form.Label>Presupuesto</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faCoins} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="budget"
                value={formData.budget}
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>

          <Row>
            <Col>
              <Form.Group controlId="initialDate">
                <Form.Label>Fecha inicio</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faCalendarDays} />
                  </InputGroup.Text>

                  <DatePicker
                    selected={
                      formData.initial_date
                        ? parseISO(formData.initial_date)
                        : null
                    }
                    onChange={(date) =>
                      handleInputChange({
                        target: {
                          name: "initial_date",
                          value: date.toISOString(),
                        },
                      })
                    }
                    dateFormat="yyyy/MM/dd"
                    showYearDropdown
                    dropdownMode="select"
                    locale={es}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="endDate">
                <Form.Label>Fecha fin</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faCalendarDays} />
                  </InputGroup.Text>
                  <DatePicker
                    selected={
                      formData.end_date ? parseISO(formData.end_date) : null
                    }
                    onChange={(date) =>
                      handleInputChange({
                        target: {
                          name: "end_date",
                          value: date.toISOString(),
                        },
                      })
                    }
                    dateFormat="yyyy/MM/dd"
                    showYearDropdown
                    dropdownMode="select"
                    locale={es}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Col
            className=" d-flex flex-column p-0 pb-4  "
            style={{ width: "28%" }}
          >
            <Form.Label>Recursos Necesarios</Form.Label>
            <div
              id="desplegables-container"
              style={{ display: "flex", flexDirection: "column" }}
            >
              {resourcesAttributes.reduce((acc, { id, name }, index) => {
                return [
                  ...acc,
                  <Fade in={openResources} key={index} timeout={200}>
                    <Form.Group controlId={index}>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUser} />
                        </InputGroup.Text>
                        <Form.Control
                          as="select"
                          name="resources"
                          value={id}
                          onChange={updateResourceAttribute(index, name)}
                        >
                          <option value="">Selecciona un Recurso</option>

                          {resourcesApi.map((resource, index) => (
                            <option
                              key={`${resource.id}-${index}`}
                              value={resource.id}
                            >
                              {resource.name}
                            </option>
                          ))}
                        </Form.Control>
                        <Button
                          variant="danger"
                          onClick={() =>
                            handleRemoveResourceClick(index, name, id)
                          }
                          style={{ marginLeft: "4px" }}
                        >
                          <FontAwesomeIcon icon={faClose} />
                        </Button>
                      </InputGroup>
                    </Form.Group>
                  </Fade>,
                ];
              }, [])}
            </div>

            <Button
              onClick={handleAddUResourceClick()}
              aria-controls="example-fade-text"
              aria-expanded={openResources}
              className="btn btn-info mx-1"
            >
              <FontAwesomeIcon icon={faPlus} className="  pr-2 " />
              Agregar Recurso
            </Button>
          </Col>
          <Row className=" pb-5 ">
            <Col className=" d-flex flex-column ">
              <Form.Label>Elabora</Form.Label>
              <div id="desplegables-container">
                {assignmentsAttributes.map((user, index) => {
                  if (user.action !== "produce") {
                    return null;
                  }

                  const filteredUsersList = usersApi.results
                    ?.filter((u) =>
                      u.name
                        .toLowerCase()
                        .includes(userFiltersProduce[index]?.toLowerCase())
                    )
                    .map((u, i) => (
                      <Dropdown.Item
                        key={`creator-select-${u.user_id}-${i}`}
                        onClick={() =>
                          updateAssingmentAttribute(
                            index,
                            user
                          )({
                            target: { value: u.user_id },
                          })
                        }
                      >
                        {u.name}
                      </Dropdown.Item>
                    ));

                  const displayList = userFiltersProduce[index] ? (
                    filteredUsersList.length > 0 ? (
                      filteredUsersList
                    ) : (
                      <div style={{ padding: "10px" }}>
                        <span>No hay resultados</span>
                      </div>
                    )
                  ) : (
                    usersApi.results?.map((u, i) => (
                      <Dropdown.Item
                        key={`creator-select-${u.user_id}-${i}`}
                        onClick={() =>
                          updateAssingmentAttribute(
                            index,
                            user
                          )({
                            target: { value: u.user_id },
                          })
                        }
                      >
                        {u.name}
                      </Dropdown.Item>
                    ))
                  );

                  return (
                    <Fade
                      in={open}
                      key={`creator-${user.id}-${index}`}
                      timeout={200}
                    >
                      <Form.Group controlId={`userId-${index}`}>
                        <Dropdown>
                          <Dropdown.Toggle
                            id={`dropdown-${user.id}-${index}`}
                            style={{
                              width: "90%",
                              backgroundColor: "transparent",
                              color: "black",
                              borderColor: "black",
                            }}
                          >
                            {user.user_id
                              ? usersApi.results?.find(
                                  (u) => u.user_id === user.user_id
                                )?.name
                              : "Selecciona un Usuario"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{ width: "90%" }}>
                            <input
                              type="text"
                              placeholder="Filtrar usuarios"
                              value={userFiltersProduce[index] || ""}
                              onChange={(e) =>
                                dispatchUserFiltersProduce({
                                  [index]: e.target.value,
                                })
                              }
                              style={{ width: "100%", marginBottom: "10px" }}
                            />
                            {displayList}
                          </Dropdown.Menu>
                          <Button
                            variant="danger"
                            onClick={() => handleRemoveUserClick(index)}
                            style={{ marginLeft: "2px" }}
                          >
                            <FontAwesomeIcon icon={faClose} />
                          </Button>
                        </Dropdown>
                      </Form.Group>
                    </Fade>
                  );
                })}
              </div>

              <Button
                onClick={handleAddUserClick("produce")}
                aria-controls="example-fade-text"
                aria-expanded={open}
              >
                <FontAwesomeIcon icon={faPlus} className="pr-2" />
                Agregar
              </Button>
            </Col>
            <Col className=" d-flex flex-column ">
              <Form.Label>Revisa</Form.Label>
              <div id="desplegables-container">
                {assignmentsAttributes.map((user, index) => {
                  if (user.action !== "review") {
                    return null;
                  }

                  const filteredUsersList = usersApi.results
                    ?.filter((u) =>
                      u.name
                        .toLowerCase()
                        .includes(userFiltersReview[index]?.toLowerCase())
                    )
                    .map((u, i) => (
                      <Dropdown.Item
                        key={`creator-select-${u.user_id}-${i}`}
                        onClick={() =>
                          updateAssingmentAttribute(
                            index,
                            user
                          )({
                            target: { value: u.user_id },
                          })
                        }
                      >
                        {u.name}
                      </Dropdown.Item>
                    ));

                  const displayList = userFiltersReview[index] ? (
                    filteredUsersList.length > 0 ? (
                      filteredUsersList
                    ) : (
                      <div style={{ padding: "10px" }}>
                        <span>No hay resultados</span>
                      </div>
                    )
                  ) : (
                    usersApi.results?.map((u, i) => (
                      <Dropdown.Item
                        key={`creator-select-${u.user_id}-${i}`}
                        onClick={() =>
                          updateAssingmentAttribute(
                            index,
                            user
                          )({
                            target: { value: u.user_id },
                          })
                        }
                      >
                        {u.name}
                      </Dropdown.Item>
                    ))
                  );
                  return (
                    <Fade
                      in={open}
                      key={`creator-${user.id}-${index}`}
                      timeout={200}
                    >
                      <Form.Group controlId={`userId-${index}`}>
                        <Dropdown>
                          <Dropdown.Toggle
                            id={`dropdown-${user.id}-${index}`}
                            style={{
                              width: "90%",
                              backgroundColor: "transparent",
                              color: "black",
                              borderColor: "black",
                            }}
                          >
                            {user.user_id
                              ? usersApi.results?.find(
                                  (u) => u.user_id === user.user_id
                                )?.name
                              : "Selecciona un Usuario"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{ width: "90%" }}>
                            <input
                              type="text"
                              placeholder="Filtrar usuarios"
                              value={userFiltersReview[index] || ""}
                              onChange={(e) =>
                                dispatchUserFiltersReview({
                                  [index]: e.target.value,
                                })
                              }
                              style={{ width: "100%", marginBottom: "10px" }}
                            />
                            {displayList}
                          </Dropdown.Menu>
                          <Button
                            variant="danger"
                            onClick={() => handleRemoveUserClick(index)}
                            style={{ marginLeft: "2px" }}
                          >
                            <FontAwesomeIcon icon={faClose} />
                          </Button>
                        </Dropdown>
                      </Form.Group>
                    </Fade>
                  );
                })}
              </div>
              <Button
                onClick={handleAddUserClick("review")}
                aria-controls="example-fade-text"
                aria-expanded={open}
              >
                <FontAwesomeIcon icon={faPlus} className="  pr-2 " />
                Agregar
              </Button>
            </Col>
          </Row>

          <Col md="auto" className=" d-flex  justify-content-end p-0 ">
            <Link
              to={`/troubles/${troubleId}/investigation/${investigationId}/actions`}
            >
              <Button className="mx-1 border-0 " variant="outline-primary">
                Cancelar
              </Button>
            </Link>
            <Button
              className="btn btn-primary mx-1"
              type="submit"
              variant="primary"
            >
              Actualizar
            </Button>
          </Col>
        </>
      )}
    </Form>
  );
};

export default UpdateAction;
