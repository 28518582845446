import { Controller }  from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  save()
  {
    //document.getElementById('workflowspinner').style.display = 'block';
    //const elem = document.getElementById('actsForm') // or $('#myform')[0] with jQuery
    //Rails.fire(elem, 'submit');
  }
}
