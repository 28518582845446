import React, { useState } from 'react';

const Findings = ({
  normsIso9001, 
  normsHealth, 
  normsIso14001, 
  normsIso45001, 
  normsSgsst,
  prompts,
  modalFindings,
  setModalFlag,
}) => {  
  const setModal = (e) => {
    setModalFlag(0);
    modalFindings(true, 'Editar Hallazgo', e);
  }
  return (
    <>
      <div className="card-body mb-2 p-1" data-controller="norm">
        <h5 className="card-title font-weight-bold">HALLAZGOS</h5>
        <div className="table_responsive">
          <table className="table table-bordered table-sm mb-0">
            <tbody>
              <tr style={{backgroundColor: '#00ADB5'}}>
                {prompts.user_writer && (
                  <th className="text-center" style={{width: '40px'}}>
                  </th>
                )}
                <th className="text-center">REQUISITO</th>
                <th className="text-center">HALLAZGO</th>
                <th className="text-center">PROCESO</th>
                <th className="text-center">DESCRIPCIÓN</th>
                <th className="text-center">EVIDENCIA</th>
              </tr>
              <tr>
                <th colSpan="7" style={{backgroundColor: '#65CFD4'}}>
                  ISO-9001            
                </th>
              </tr>
              {normsIso9001.map((norm)=>(
                <React.Fragment key={norm.id}>
                  <tr className='mergeable' >
                    {prompts.user_writer && (
                      <td className="text-center aligne-middle" style={{width: '40px'}}>
                        <span className={`btn btn-outline-success btn-sm`} onClick={()=>setModal(norm.id)}>
                          <i className='far fa-edit'></i>
                        </span>
                      </td>
                    )}
                    <td className='text-center'> {norm.chapter_number} </td>
                    <td className='text-center'> {norm.finding}</td>
                    <td className='text-center'> {norm.title}</td>
                    <td className='text-center'> {
                    norm.joint !== null ? norm.joint : norm.description
                    }</td>
                    <td className='text-center'>
                      {norm.filename !== null ? (
                        <a href={norm.fileurl} target='_blank'>
                        <i className='fas fa-file' data-bs-toggle='tooltip' data-bs-placement='left' title={norm.filename}></i>
                        </a>
                        ):( null )
                      }                  
                    </td>
                  </tr>
                </React.Fragment>
              ))}
              <tr>
                <th colSpan="7" style={{backgroundColor: '#65CFD4'}}>
                  Habilitación en la salud            
                </th>
              </tr>
              {normsHealth.map((norm)=>(
                <React.Fragment key={norm.id}>
                  <tr className='mergeable'>
                    {prompts.user_writer && (
                      <td className="text-center aligne-middle" style={{width: '40px'}}>
                        <span className={`btn btn-outline-success btn-sm ${prompts.edit_condition === true ? (''):('disabled') }`} onClick={()=>setModal(norm.id)}>
                          <i className='far fa-edit'></i>
                        </span>
                      </td>
                    )}
                  <td className='text-center'> {norm.chapter_number} </td>
                  <td className='text-center'> {norm.finding}</td>
                  <td className='text-center'> {norm.title}</td>
                  <td className='text-center'> {
                    norm.joint !== null ? norm.joint : norm.description
                  }</td>
                  <td className='text-center'> 
                    {norm.filename !== null ? (
                        <a href={norm.fileurl} target='_blank'>
                        <i className='fas fa-file' data-bs-toggle='tooltip' data-bs-placement='left' title={norm.filename}></i>
                        </a>
                        ):( null )
                    }
                  </td>
                  </tr>
                </React.Fragment>
              ))}
              <tr>
                <th colSpan="7" style={{backgroundColor: '#65CFD4'}}>
                  ISO-14001            
                </th>
              </tr>
              {normsIso14001.map((norm)=>(
                <React.Fragment key={norm.id}>
                  <tr className='mergeable'>
                    {prompts.user_writer && (
                      <td className="text-center aligne-middle" style={{width: '40px'}}>
                        <span className={`btn btn-outline-success btn-sm ${prompts.edit_condition === true ? (''):('disabled') }`} onClick={()=>setModal(norm.id)}>
                          <i className='far fa-edit'></i>
                        </span>
                      </td>
                    )}
                  <td className='text-center'> {norm.chapter_number} </td>
                  <td className='text-center'> {norm.finding}</td>
                  <td className='text-center'> {norm.title}</td>
                  <td className='text-center'> {
                    norm.joint !== null ? norm.joint : norm.description
                  }</td>
                  <td className='text-center'> 
                    {norm.filename !== null ? (
                      <a href={norm.fileurl} target='_blank'>
                      <i className='fas fa-file' data-bs-toggle='tooltip' data-bs-placement='left' title={norm.filename}></i>
                      </a>
                      ):( null )
                    }      
                  </td>
                  </tr>
                </React.Fragment>
              ))}
              <tr>
                <th colSpan="7" style={{backgroundColor: '#65CFD4'}}>
                  ISO-45001            
                </th>
              </tr>
              {normsIso45001.map((norm)=>(
                <React.Fragment key={norm.id}>
                  <tr className='mergeable'>
                    {prompts.user_writer && (
                      <td className="text-center aligne-middle" style={{width: '40px'}}>
                        <span className={`btn btn-outline-success btn-sm ${prompts.edit_condition === true ? (''):('disabled') }`} onClick={()=>setModal(norm.id)}>
                          <i className='far fa-edit'></i>
                        </span>
                      </td>
                    )}
                  <td className='text-center'> {norm.chapter_number} </td>
                  <td className='text-center'> {norm.finding}</td>
                  <td className='text-center'> {norm.title}</td>
                  <td className='text-center'> {
                    norm.joint !== null ? norm.joint : norm.description
                  }</td>
                  <td className='text-center'> 
                    {norm.filename !== null ? (
                      <a href={norm.fileurl} target='_blank'>
                      <i className='fas fa-file' data-bs-toggle='tooltip' data-bs-placement='left' title={norm.filename}></i>
                      </a>
                      ):( null )
                    }      
                  </td>
                  </tr>
                </React.Fragment>
              ))}
              <tr>
                <th colSpan="7" style={{backgroundColor: '#65CFD4'}}>
                  SG-SST            
                </th>
              </tr>
              {normsSgsst.map((norm)=>(
                <React.Fragment key={norm.id}>
                  <tr className='mergeable'>
                    {prompts.user_writer && (
                      <td className="text-center aligne-middle" style={{width: '40px'}}>
                        <span className={`btn btn-outline-success btn-sm ${prompts.edit_condition === true ? (''):('disabled') }`} onClick={()=>setModal(norm.id)}>
                          <i className='far fa-edit'></i>
                        </span>
                      </td>
                    )}
                  <td className='text-center'> {norm.chapter_number} </td>
                  <td className='text-center'> {norm.finding}</td>
                  <td className='text-center'> {norm.title}</td>
                  <td className='text-center'> {
                    norm.joint !== null ? norm.joint : norm.description
                  }</td>
                  <td className='text-center'> 
                    {norm.filename !== null ? (
                      <a href={norm.fileurl} target='_blank'>
                      <i className='fas fa-file' data-bs-toggle='tooltip' data-bs-placement='left' title={norm.filename}></i>
                      </a>
                      ):( null )
                    }      
                  </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default Findings