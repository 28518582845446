import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  static targets = ['trainer', 'employee']

  connect () {
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()
    const { value } = this.trainerTarget
    if (value == 'true') {
      $('#entityDiv').hide()
      $('#trainerDiv').show()
    } else {
      $('#trainerDiv').hide()
      $('#entityDiv').show()
    }
  }
}
