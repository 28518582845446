import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['fileField', 'button']

  onPurge (e) {
    e.preventDefault()
    this.fileFieldTarget.value = 'true'
    $(this.buttonTarget).hide()
    $('#link').hide()
  }
}
