import React from "react";

const ReversibilityModal = () => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th className="align-middle text-center">
            PARÁMETROS DE CALIFICACIÓN CUALITATIVA
          </th>
          <th className="align-middle text-center"> CALIFICACIÓN </th>
          <th className="align-middle text-center"> GRADO DE SIGNIFICANCIA </th>
          <th className="align-middle text-center"> DESCRIPCIÓN </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="align-middle text-justify" rowSpan="3">
            REVERSIBILIDAD - (RV)
          </td>
          <td className="align-middle text-center">CORTO PLAZO</td>
          <td className="align-middle text-center">1</td>
          <td className="align-middle text-justify">
            Si el elemento retorna a sus condiciones iniciales en menos de un
            año
          </td>
        </tr>
        <tr>
          <td className="align-middle text-center">MEDIO PLAZO</td>
          <td className="align-middle text-center">2</td>
          <td className="align-middle text-justify">
            Si se demora entre 1 y 10 años en recuperar sus condiciones
          </td>
        </tr>
        <tr>
          <td className="align-middle text-center">IRREVERSIBLE</td>
          <td className="align-middle text-center">4</td>
          <td className="align-middle text-justify">
            Si la recuperación se tarda mas de 10 años
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ReversibilityModal;
