import React from "react";

const TrMissingEvaluation = ({
  impact,
  activity,
  activityIndex,
  aspect,
  aspectIndex,
  actions,
}) => {
  function setRowspanImpact(impact) {
    return (
      impact.impact_actions.length *
      impact.activities.reduce(
        (acc, activity) => acc + activity.aspects.length,
        0
      )
    );
  }

  function setRowspanAspect(activity) {
    return impact.impact_actions.length * activity.aspects.length;
  }

  function setRowspanAction(impact) {
    return impact.impact_actions.length;
  }

  if (!actions || actions.length === 0) {
    return (
      <tr>
        {activityIndex === 0 && aspectIndex === 0 && (
          <td
            className="align-middle text-center"
            rowSpan={setRowspanImpact(impact)}
          >
            {impact.all_procedures ? (
              <span>Todos los procesos</span>
            ) : (
              <>
                {impact.procedures.map((procedure) => (
                  <span className="d-block" key={procedure.id}>
                    {procedure.title}
                  </span>
                ))}
              </>
            )}
          </td>
        )}

        {aspectIndex === 0 && (
          <td
            className="text-center align-middle"
            rowSpan={setRowspanAspect(activity)}
          >
            {activity.title}
          </td>
        )}

        {aspectIndex === 0 && (
          <td
            className="text-center align-middle font-weight-bold"
            rowSpan={setRowspanAspect(activity)}
          >
            {activity.situation === 0 ? "x" : ""}
          </td>
        )}

        {aspectIndex === 0 && (
          <td
            className="text-center align-middle font-weight-bold"
            rowSpan={setRowspanAspect(activity)}
          >
            {activity.situation === 1 ? "x" : ""}
          </td>
        )}

        {aspectIndex === 0 && (
          <td
            className="text-center align-middle font-weight-bold"
            rowSpan={setRowspanAspect(activity)}
          >
            {activity.situation === 2 ? "x" : ""}
          </td>
        )}

        <td className="text-center align-middle">{aspect.name}</td>

        <td className="text-center align-middle">{aspect.description}</td>

        {/* evaluation */}
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>

        {/* action */}
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  } else {
    return (
      <>
        {actions.map((action, actionIndex) => {
          return (
            <tr
              key={`impact-${impact.id}-aspect-${aspectIndex}-action-${actionIndex}`}
            >
              {activityIndex === 0 &&
                aspectIndex === 0 &&
                actionIndex === 0 && (
                  <td
                    className="align-middle text-center"
                    rowSpan={setRowspanImpact(impact)}
                  >
                    {impact.all_procedures ? (
                      <span>Todos los procesos</span>
                    ) : (
                      <>
                        {impact.procedures.map((procedure) => (
                          <span className="d-block" key={procedure.id}>
                            {procedure.title}
                          </span>
                        ))}
                      </>
                    )}
                  </td>
                )}

              {aspectIndex === 0 && actionIndex === 0 && (
                <td
                  className="text-center align-middle"
                  rowSpan={setRowspanAspect(activity)}
                >
                  {activity.title}
                </td>
              )}

              {aspectIndex === 0 && actionIndex === 0 && (
                <td
                  className="text-center align-middle font-weight-bold"
                  rowSpan={setRowspanAspect(activity)}
                >
                  {activity.situation === 0 ? "x" : ""}
                </td>
              )}

              {aspectIndex === 0 && actionIndex === 0 && (
                <td
                  className="text-center align-middle font-weight-bold"
                  rowSpan={setRowspanAspect(activity)}
                >
                  {activity.situation === 1 ? "x" : ""}
                </td>
              )}

              {aspectIndex === 0 && actionIndex === 0 && (
                <td
                  className="text-center align-middle font-weight-bold"
                  rowSpan={setRowspanAspect(activity)}
                >
                  {activity.situation === 2 ? "x" : ""}
                </td>
              )}

              {actionIndex === 0 && (
                <>
                  <td
                    rowSpan={setRowspanAction(impact)}
                    className="text-center align-middle"
                  >
                    {aspect.name}
                  </td>

                  <td
                    rowSpan={setRowspanAction(impact)}
                    className="text-center align-middle"
                  >
                    {aspect.description}
                  </td>

                  {/* evaluation */}
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                  <td rowSpan={setRowspanAction(impact)}></td>
                </>
              )}
              {activityIndex === 0 && aspectIndex === 0 && (
                <>
                  {/* action */}
                  <td className="text-center align-middle">
                    {action.action_title}
                  </td>
                  <td className="text-center align-middle">
                    {action.action_responsible}
                  </td>
                  <td className="text-center align-middle">
                    {action.action_start_date}
                  </td>
                  <td className="text-center align-middle">
                    {action.action_end_date}
                  </td>
                  <td className="text-center align-middle">
                    {action.action_evidence}
                  </td>
                </>
              )}
            </tr>
          );
        })}
      </>
    );
  }
};

export default TrMissingEvaluation;
