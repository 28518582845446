import { initial } from 'lodash'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['check']

  connect (e) {
    this.initial()
    this.TypeIncident()
  }

  TypeIncident (e) {
    if (e) e.preventDefault()
    if ($(this.checkTarget).prop('checked')) {
      $('#type_incident').show('slow')
    } else {      
      $('#type_incident').hide('slow')
    }
  }
  initial(){
    if ($(this.checkTarget).prop('checked')){
      $('#type_incident').show('slow')
    } else {      
      $('#type_incident').hide('slow')
    }
  }
}
