import React from 'react'

const TableHeaders = () => {
  const headers = ['ScoreCard', 'Periodo (AA/MM/DD)', 'Resultado', 'Meta', 'Cumplimiento', '', 'Promedio', 'Acumulado Año', '']
  const classNames = ['w-50p text-center align-middle', 'w-130px text-center align-middle', 'text-center align-middle',
                      'text-center align-middle', 'text-center align-middle', 'text-center align-middle',
                      'text-center align-middle', 'text-center align-middle', 'text-center align-middle']
  return (
    <tr>
      { headers.map((header, index)=> {
        return(
          <th key={index} className={classNames[index]}>{header}</th>
        )
      }) }
    </tr>
  )
}

export default TableHeaders