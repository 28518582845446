import React from "react";

export const Loader = ({ message }) => {
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translateY(-50%)",
      }}
    >
      <div className="d-flex">
        <div className="spinner-border text-primary mr-2" role="status" />
        <h4>{message}</h4>
      </div>
    </div>
  );
};

export default Loader;
