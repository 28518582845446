import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import GlobalContext from "../../context/GlobalContext";

const Day = ({ events, date, selectedDate }) => {
  const { setShowEditModal, setEventEditId } = useContext(GlobalContext);
  const formattedDate = dayjs(date).format("D");
  const options = [
    { value: "completed", label: "Hecho", color: "#97E7B9" },
    { value: "in_progress", label: "En Proceso", color: "#FBB446" },
    { value: "incomplete", label: "No Realizado", color: "#E91E63" },
    {
      value: "without_status",
      label: "Sin seguimiento",
      color: "rgb(250, 250, 70)",
    },
  ];
  const [eventColors, setEventColors] = useState([]);

  useEffect(() => {
    if (events) {
      const colors = events.map((event) => {
        const firstFollow = event.event_follows[event.event_follows.length - 1];
        const finding = firstFollow ? firstFollow.follow_status : null;
        return getColorFromFinding(finding);
      });
      setEventColors(colors);
    }
  }, [events]);

  function getColorFromFinding(finding) {
    const option = options.find((option) => option.value === finding);
    return option ? option.color : null;
  }

  function handleEventInDay(id) {
    setShowEditModal(true);
    setEventEditId(id)
  }

  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.slice(0, maxLength) + "";
    }
  }

  return (
    <div className="day-container">
      <div
        className={`day-number ${
          dayjs(selectedDate).isSame(date, "day") ? "selected" : ""
        }`}
      >
        {formattedDate}
      </div>
      <div className="align-content-center justify-content-center container">
        {events && (
          <ul
            className={`event-list ${events.length > 4 ? "two-columns" : ""}`}
            style={{ listStyleType: "none", padding: 0 }}
          >
            {events.map((event, index) => (
              <li
                key={event.id}
                onClick={() => handleEventInDay(event.id)}
                className="event-item"
                style={{ backgroundColor: eventColors[index] }}
              >
                {truncateText(event.title, 7)}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Day;
