import { Controller } from 'stimulus'
import IMask from 'imask'

export default class extends Controller {
  connect () {
    IMask(this.element, {
      mask: Number,
      scale: 4,
      signed: true,
      radix: '.',
      mapToRadix: [',']
    })
  }
}
