import { Controller }  from 'stimulus'

export default class extends Controller {
  static targets = ['input']
  onChange(e) {
    if (e) e.preventDefault();
    const url = this.data.get('url')
    const Id = this.data.get('id')
    $.get(url+'?element_id='+Id+'&value='+$(this.inputTarget).prop('checked'))
  }
}
