import { Controller }  from 'stimulus'

export default class extends Controller {

  connect() {
   // document.getElementById("pqrsf_country").style.display ='none'
    //document.getElementById("pqrsf_state").style.display ='none';
    var country = document.getElementById("pqrsf_country");
    var state = document.getElementById("stateU");
    $.ajax({
      type: "GET",
      url: "/pqrsfs/citystates",
      data: {country: country.value, state: state.value},
    })
    this.onChange() 
  }

  onChange(e) {
    var country = document.getElementById("pqrsf_country");
    var state = document.getElementById("stateU");
    
    country.addEventListener("change", function(){
      //document.getElementById("pqrsf_state").style.display ='';
      $.ajax({
        type: "GET",
        url: "/pqrsfs/states",
        data: {country: country.value},
        
      })
    })

    state.addEventListener("change", function(){
      //document.getElementById("pqrsf_state").style.display ='';
      $.ajax({
        type: "GET",
        url: "/pqrsfs/cities",
        data: {state: state.value, country: country.value},
        
      })
    })
    

  }

  
}
