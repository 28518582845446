import { Controller } from 'stimulus'
import measureinspectionChart from '../shared/measureinspectionChart'

export default class extends Controller {
  connect () {
    const elementId = this.data.get('element-id')
    const color = this.data.get('color')
    const value = this.data.get('value')

    measureinspectionChart(elementId, color, value)
  }
}
