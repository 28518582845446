import React, {useState} from 'react'

const SelectInput = ({dateFilter}) => {
  const [selectinput, SetSelectIput] = useState('')
  const filter = () => {
    dateFilter(selectinput)
  }
  return (
    <div className='form-inline my-2'>
      <div className='form-group col-md-3 mb-0'>
        <select className='form-control mr-2' name="select" onChange={(e)=>SetSelectIput(e.target.value)}>
          <option value="2023" onChange={(e)=>SetSelectIput(e.target.value)}>2023</option>
          <option value="2022" onChange={(e)=>SetSelectIput(e.target.value)}>2022</option>
          <option value="2021" onChange={(e)=>SetSelectIput(e.target.value)}>2021</option>
          <option value="2020" onChange={(e)=>SetSelectIput(e.target.value)}>2020</option>
          <option value="2019" onChange={(e)=>SetSelectIput(e.target.value)}>2019</option>
          <option value="2018" onChange={(e)=>SetSelectIput(e.target.value)}>2018</option>
        </select>
        <button type='button' className='btn btn-primary' onClick={()=>filter()}>Filtrar</button>
      </div>
    </div>
  )
}


export default SelectInput
