import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["divFrame"];

  connect() {
    if (
      document.getElementById("download_document") !== null &&
      document.getElementById("download_document") !== null
    ) {
      var data = document.getElementById("bandera");
      if (data.value == "true") {
        var miBoton = document.getElementById("download_document");
        var url = miBoton.getAttribute("href");
        window.location.href = url;
        data.value = "false";
      }
    }
  }
}
