export const GetProcedures = async () => {
  try {
    const url = '/api/v1/procedures'
    const response = await fetch(url)
    return response.json()
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
};

export const GetEnvironmentalAspects = async () => {
  try {
    const url = '/api/v1/environmental_aspects'
    const response = await fetch(url)
    return response.json()
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
};

export const GetRisks = async () => {
  try {
    const url = '/api/v1/risks'
    const response = await fetch(url)
    return response.json()
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
};

export const GetEnvResources = async () => {
  try {
    const url = '/api/v1/environmental_resources'
    const response = await fetch(url)
    return response.json()
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
};
