import React, { useState, useEffect } from 'react'
import CardChart from './CardChart'
const IndicatorTypeList = ({modalControl, date}) => {
  const [title, setTitle] = useState('Tipos de Indicador Promedio: ')
  const [data, setData] = useState([])
  //const { loading, error, data = [] } = useFetch('/api/v1/metrics/indicator_type?date='+date, {}, [])
  useEffect(() => {
    fetch('/api/v1/metrics/indicator_type?date='+date).then((response)=>{
      return response.json()
    }).then((info) => {
      setData(info)
    })
  }, [date])

  useEffect(()=>{
    var sum = 0
    for(var i=0; i < data.length; i++){
      sum += data[i].value
    }
    if (data.length) {
      setTitle(`Tipos de Indicador promedio: ${(sum / data.length).toFixed(2)}%`)
    }
  }, [date, data])
  return (
    <div className='col-lg-4'>
      <div className='card'>
        <div className='card-header bg-white'>
          <h5 className='card-title text-center'>{title}</h5>
        </div> 
        <ul className='list-group'>
          { data.map((info)=>{
            return ( <CardChart key={info.id} 
                                text={info.text} 
                                value={info.value} 
                                color={info.color} 
                                modalControl={modalControl} 
                                group={'indicator_type'} 
                                groupid={info.id}
                                date={date}/>)                                
          })}
        </ul>
      </div>
    </div>
  )
}

export default IndicatorTypeList