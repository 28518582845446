import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['draftButton', 'buttonsubmit']
  connect () {
    $('#createFields').show()
    $('#updateFields').hide()
    $('#deleteFields').hide()
    this.blockButton()
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()

    if ($('#document_request_request_type_create_document').prop('checked')) {
      $('#createFields').show('slow')
      $('#deleteFields').hide('slow')
      $('#updateFields').hide('slow')
    }
    else if ($('#document_request_request_type_update_document').prop('checked')){
      $('#createFields').hide('slow')
      $('#deleteFields').hide('slow')
      $('#updateFields').show('slow')
    }
    else if ($('#document_request_request_type_update_document').prop('checked')){
      $('#createFields').hide('slow')
      $('#updateFields').show('slow')
      $('#deleteFields').show('slow')
    }
  }

  blockButton() {
    const submitButtondraft = document.getElementById('draft')
    submitButtondraft.addEventListener('click', function() {
      const time = 5;

      setTimeout(function() {
        submitButton.disabled = true;
      }, time);
      // submitButton.disabled = true
    })

    const submitButton = document.getElementById('submitButton')
    submitButton.addEventListener('click', function() {
      const time = 5;
      setTimeout(function() {
        submitButton.disabled = true;
      }, time);
    })

    // submitButton.addEventListener('click', (event) => {
    //   if (event.hasTarget(this.buttonsubmitTarget)) {
    //   }
    // })

    // const submitButton = document.getElementById('submitButton')
    // submitButton.addEventListener('click', function() {
    //   // submitButton.disabled = true
    // })
    
  }
}
