import { Controller } from 'stimulus'
import 'bootstrap/js/dist/modal'

export default class extends Controller {
  connect () {
    if (document.getElementById('draft_button')) {
      document.getElementById('draft_button').className = 'btn btn-info mr-3'
    }
    if (document.getElementById('cancel_button')) {
      document.getElementById('cancel_button').className = 'btn btn-link'
    }
    if (document.getElementById('submit_button')) {
      document.getElementById('submit_button').className = 'btn btn-primary'
    }
  }

  onChange () {
    this.connect()
  }
}
