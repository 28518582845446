import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()
    const url = this.data.get('url')
    const id = this.data.get('id')
    const relation_portal_id = this.data.get('relation-portal')
    const elementId = this.data.get('target')
    $.get(url + '?portal_id=' + this.element.value + '&relation_portal_id=' + relation_portal_id).then((content) => {
      document.getElementById(elementId).innerHTML = content
    })
  }
}
