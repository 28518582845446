import { Controller } from 'stimulus'
import IMask from 'imask'

export default class extends Controller {
  connect (e) {
    const email = this.element.value;
    $(this.element).on({
      keydown: function(e) {
        if (e.which === 32)
          return false;
      },
      change: function() {
        this.value = this.value.replace(/\s/g, "");
      }
    })
  }
}
