import { Controller } from 'stimulus'
import indicatorLevelResolver from '../shared/indicatorLevelResolver'
import measureChart from '../shared/measureChart'

export default class extends Controller {
  static targets = ['goal', 'result', 'level', 'percentage', 'draftButton', 'submitButton', 'spinner', 'numerator', 'denumerator', 'no_measurement', 'measure_type']

  connect () {
    if (this.hasSpinnerTarget && $(this.spinnerTarget).length) {
      $(this.spinnerTarget).hide();
    } else {
      console.warn("El spinner target no está presente.");
    }
    this.update()
    if (document.getElementById('indicators_measure_numerator') !== null && document.getElementById('indicators_measure_denumerator') !== null){
      this.divition()
    }
    this.disabled()
    if (document.getElementById('indicators_measure_numerator') !== null && document.getElementById('indicators_measure_denumerator') !== null){
      this.resultTarget.disabled = true
    }
  }

  update () {
    const elementId = this.data.get('element-id')
    const semaphores = JSON.parse(this.data.get('semaphores'))

    const goal = Number.parseFloat(this.goalTarget.value)
    const result = Number.parseFloat(this.resultTarget.value)
    const trendDirection = this.data.get('trend-direction')
    let portal = ''

    if(document.getElementById('percentage_pabon') !== null){
      portal = document.getElementById('portal').value
    }
    // let testtow = (portal === 'true');

    let percentage
  
    if ((goal === 0) && (result === 0)) {
      percentage = 100;
    } else if (goal === 0) {
      percentage = ((100 - result) / 100.0) * 100;
    } else {
      const basePercentage = (100 * result) / goal;
      percentage = trendDirection === 'increase' ? basePercentage : 200 - basePercentage;
    }

    if (Number.isNaN(percentage)) {
      return
    }
    if (portal == 'true'){
      percentage = percentage.toFixed(5)
    }else{
      percentage = percentage.toFixed(2)
    }
    if (document.getElementById('percentage_pabon') !== null && document.getElementById('percentage_pabon') !== null){
      if (percentage > 100){
        percentage = 100
      }
      else if(percentage < 0){
        percentage = 0
      }
    }
    this.percentageTarget.value = percentage
    let semaphore;
    
    if (semaphores !== undefined && semaphores){
      semaphore = indicatorLevelResolver(semaphores, percentage)
    }

    if (semaphores !== undefined && semaphores && !semaphore) {
      if (this.hasDraftButtonTarget) {
        this.draftButtonTarget.disabled = true
      }
      this.submitButtonTarget.disabled = true
      this.levelTarget.innerHTML = 'Desconocido'
      measureChart(this.chartId, '#000', percentage, portal)
      alert('Resultado por fuera de rango aceptable, compruebe la configuración del semáforo')

      return
    }

    if (this.hasDraftButtonTarget) {
      this.draftButtonTarget.disabled = false
    }
    this.submitButtonTarget.disabled = false

    if (semaphores !== undefined && semaphores){
      const { data: { color } } = semaphore.achievement
      this.levelTarget.innerHTML = semaphore.achievement.name
      measureChart(elementId, color, percentage, portal)
    }
  }

  submit () {
    this.resultTarget.disabled = false
    $(this.submitButtonTarget).hide()
    $(this.spinnerTarget).show()
  }

  disabled (){
    if (document.getElementById('indicators_measure_no_measurement') !== null && document.getElementById('indicators_measure_no_measurement').checked){
      const elementId = this.data.get('element-id')
      this.submitButtonTarget !== null && this.submitButtonTarget !== undefined ? this.submitButtonTarget.disabled = false : null
      this.goalTarget !== null && this.goalTarget !== undefined ? this.goalTarget.disabled = true : null
      this.resultTarget !== null && this.resultTarget !== undefined ?  this.resultTarget.disabled = true : null
      document.getElementById('indicators_measure_denumerator') !== null ? this.denumeratorTarget.disabled = true : null
      document.getElementById('indicators_measure_numerator') !== null ? this.numeratorTarget.disabled = true : null
      this.levelTarget !== null && this.levelTarget !== undefined ? this.levelTarget.innerHTML = 'Desconocido' : null
      measureChart(elementId, 'black', '0')
    }else {
      if (document.getElementById('indicators_measure_numerator') !== null && document.getElementById('indicators_measure_denumerator') !== null){
        this.resultTarget.disabled = true
      }
      else{
        this.resultTarget.disabled = false
      }
      this.goalTarget.disabled = false
      document.getElementById('indicators_measure_numerator') !== null ? this.numeratorTarget.disabled = false : null
      document.getElementById('indicators_measure_denumerator') !== null ? this.denumeratorTarget.disabled = false : null
      this.submitButtonTarget.disabled = true
      this.update()
    }
    
  }


  divition () {
    const result = Number.parseFloat(this.resultTarget.value)
    const numerator = Number.parseFloat(this.numeratorTarget.value)
    const denumerator = Number.parseFloat(this.denumeratorTarget.value)
    const measure_type = document.getElementById('measure_type').value
    if (isNaN(numerator) && isNaN(denumerator) || isNaN(denumerator) || isNaN(numerator)) {
      this.resultTarget.value = result
      this.denumeratorTarget.placeholder = '' 
      $(indicators_measure_denumerator).css("background-color", "white")
      this.update()
    }
    else if (denumerator == 0){
      this.denumeratorTarget.placeholder = 'Mayor a 0'
      this.denumeratorTarget.value = '' 
      $(indicators_measure_denumerator).css("background-color", "pink")
      this.update()
    }
    else {
      const portal = document.getElementById('portal').value
      // Calculare resultado = numerador / denominador * 100
      if (measure_type == 'percentage'){
        var result_new = ((numerator / denumerator) * 100)
        if (portal == 'true'){
          result_new = result_new.toFixed(5)
        }else{
          result_new = result_new.toFixed(2)
        }
        this.resultTarget.value = result_new
        this.denumeratorTarget.placeholder = '' 
        $(indicators_measure_denumerator).css("background-color", "white")
        this.update()
      }
      else{
        var result_new = ((numerator / denumerator))
        if (portal == 'true'){
          result_new = result_new.toFixed(5)
        }else{
          result_new = result_new.toFixed(2)
        }
        this.resultTarget.value = result_new
        this.denumeratorTarget.placeholder = '' 
        $(indicators_measure_denumerator).css("background-color", "white")
        this.update()
      }
    }
  }

  descriptionChanged(event){
    if(event.target.value){
      document.getElementById("submitButton").disabled = false
    }
  }
}
