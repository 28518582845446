import { Controller }  from 'stimulus'

export default class extends Controller {

  connect() {
    var time = document.getElementById("time");
    time.style.display = ''
    this.onChange()
  }

  onChange(e) {
    var time_false = document.getElementById("survey_time_out_flag_false");
    var time = document.getElementById("time");
      if (time_false.checked == true){
        time.style.display = '';
      }
      else{
        time.style.display = 'none';
      }
  }

  
}
