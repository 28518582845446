export default function (semaphores, value) {
  value = Number(value)
  for (const semaphore of semaphores) {
    const { range_type: rangeType, starts, ends } = semaphore

    if (rangeType === 'between' && value >= Number(starts) && value <= Number(ends)) {
      return semaphore
    } else if (rangeType === 'equal_to' && value === Number(starts)) {
      return semaphore
    } else if (rangeType === 'greater_than' && value > Number(starts)) {
      return semaphore
    } else if (rangeType === 'greater_than_or_equal_to' && value >= Number(starts)) {
      return semaphore
    } else if (rangeType === 'less_than' && value < Number(starts)) {
      return semaphore
    } else if (rangeType === 'less_than_or_equal_to' && value <= Number(starts)) {
      return semaphore
    }
  }
}
