import React, {useState, useEffect} from "react"
import Spinner from './Pictures/Spinner'

const Assignments = (props) => {
  const [curriculum, setCurriculum] = useState('')
  const [name, setName] = useState('')

  useEffect(() => {
    fetch('/api/v1/curriculums/main?employee='+props.employee).then((response)=>{
      return response.json()
    }).then((data) => {
      setCurriculum(data[0])
      setName(data[0])
    })
  }, [setCurriculum])

  function removeToCollection(key){
    window.location.href = '/employees/remove_curriculum.'+key
  }

  return (
      <React.Fragment>
        <div className="ml-5">
          {!curriculum && <Spinner/>}
          {curriculum && <a onClick={() => {if(window.confirm('¿Está seguro de eliminar el documento?')){removeToCollection(curriculum.id)};}} className="btn btn-outline-warning btn-sm my-tooltip" target="_blank" disposition="attachment">
            <i className="far fa-trash-alt" data-bs-toggle='tooltip' data-bs-placement='top' title="prueba"></i>
            <span className="tooltiptext">Remover: {curriculum.name}</span>
          </a>
          }
        </div>
      </React.Fragment>
  )
}

export default Assignments
