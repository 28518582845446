import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['destroy', 'positive', 'negative', 'check']

  onDelete (e) {
    e.preventDefault()
    if (confirm("¿Estás seguro de eliminar este factor?")) {
      this.destroyTarget.value = '1'
      $(this.element).fadeOut()
    }
  }

  onChangep (e) {
    const { value } = this.positiveTarget
    if (value > 0)
    {
      $(this.negativeTarget).prop('value', '')
      $(this.positiveTarget).prop('value', value)
    }
  }
  onChangen (e) {
    const { value } = this.negativeTarget
    if (value > 0)
    {
      $(this.negativeTarget).prop('value', value)
      $(this.positiveTarget).prop('value', '')
    }
  }
  onChangeCheck (e) {
    var select = document.getElementById('multi_procedures');
    if($(this.checkTarget).is(':checked')) {
      select.disabled = true;
    } else {
      select.disabled = false;
    }
    
  }
}
