import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../context/GlobalContext";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./calendar.css";

export default function SmallCalendarLib() {
  const { selectedDate, setSelectedDate, selectedMonth } = useContext(GlobalContext);
  const [currentDay, setCurrentDay] = useState(selectedDate);

  useEffect(() => {
    setCurrentDay(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    const newSelectedDate = new Date(selectedDate.getFullYear(), selectedMonth, selectedDate.getDate());
    setSelectedDate(newSelectedDate);
  }, [selectedMonth]);

  function handleDateChange(value) {
    setCurrentDay(value);
    setSelectedDate(value);
  }

  return (
    <div className="justify-content-between align-items-center container">
      <div className="row justify-content-center">
        <Calendar
          onChange={handleDateChange}
          defaultView="month"
          value={currentDay instanceof Date ? currentDay : null}
        />
      </div>
    </div>
  );
}
