import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form']

  onChange (e) {
    e.preventDefault()
    this.formTarget.submit()
  }
}
