import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['objective', 'submitButton']

  connect () {
    const div1 = document.querySelector('.actions-button')
    if(div1 && div1.children[0] && div1.children[1]){
      const button1 = div1.children[0]
      const button2 = div1.children[1]
      button1.setAttribute('class', 'disabled btn btn-success')
      button2.setAttribute('class', 'disabled btn btn-primary mx-3')
    }
    this.block_button()
  }

  block_button(){
    const div1 = document.querySelector('.actions-button')
    if (div1){
      const button1 = div1.children[0]
      const button2 = div1.children[1]
      if (button1){
        button1.addEventListener('click', function() {
          const time = 25;
          setTimeout(function() {
            button1.disabled = true;
          }, time);
        })
      }
      if (button2){
        button2.addEventListener('click', function() {
          const time = 25;
          setTimeout(function() {
            button2.disabled = true;
          }, time);
        })
      }
    }
    const buttonReturn = document.getElementById('return')
    if (buttonReturn){
      buttonReturn.addEventListener('click', function() {
        const time = 25;
        setTimeout(function() {
          buttonReturn.disabled = true;
        }, time);
      })
    }
    const buttonReview = document.getElementById('review')
    if (buttonReview){
      buttonReview.addEventListener('click', function() {
        const time = 25;
        setTimeout(function() {
          buttonReview.disabled = true;
        }, time);
      })
    }
    const buttonApprove = document.getElementById('approve')
    if (buttonApprove){
      buttonApprove.addEventListener('click', function() {
        const time = 25;
        setTimeout(function() {
          buttonApprove.disabled = true;
        }, time);
      })
    }
    const buttonDraft = document.getElementById('draft')
    if (buttonDraft){
      buttonDraft.addEventListener('click', function() {
        const time = 25;
        setTimeout(function() {
          buttonDraft.disabled = true;
        }, time);
      })
    }
    const buttonSubmit = document.getElementById('submitButton')
    if (buttonSubmit){
      buttonSubmit.addEventListener('click', function() {
        const time = 25;
        setTimeout(function() {
          buttonSubmit.disabled = true;
        }, time);
      })
    }
    const submit_button = document.getElementById('submit_button')
    if (submit_button){
      submit_button.addEventListener('click', function() {
        const time = 25;
        setTimeout(function() {
          submit_button.disabled = true;
        }, time);
      })
    }
  }
}
