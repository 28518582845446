import React from 'react';

export default function EventDay({ number, title, startDate, endDate, periodicity, color }) {
  return (
    <div className="row border-bottom border-left">
      <div className="col-1 d-flex align-items-center justify-content-center">
        <p className="text-center mt-2">{number}</p>
      </div>
      <div
      className="col-5 h5 border-left d-flex align-items-center justify-content-center"
      style={{ backgroundColor: color }}>
        <p className="text-center mt-2">{title}</p>
      </div>
      <div className="col-2 border-left d-flex align-items-center justify-content-center">
        <p className="text-center mt-2">{startDate}</p>
      </div>
      <div className="col-2 border-left d-flex align-items-center justify-content-center">
        <p className="text-center mt-2">{endDate}</p>
      </div>
      <div className="col-2 border-left d-flex align-items-center justify-content-center">
        <p className="text-center mt-2">{periodicity}</p>
      </div>
    </div>
  );
}
