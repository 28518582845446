import React from "react";
function EmptyRecords(){
  return(
    <div className="mt-5 mb-5">
      <h1 className="text-center text-info display-4">
        <i className="fas fa-exclamation-triangle"/>
      </h1>
      <h1 className="text-center display-4">
        No se encontraron registros!
      </h1>
      <p className="h4 text-center">
        Puede usar el botón nuevo(a) para agregar registros aquí.
      </p>
    </div>
  )
}
export { EmptyRecords }