import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'draft', 'awaitingReview', 'reviewed', 'returned']

  onSubmit () {
    this.formTarget.submit()
  }

  draftButton () {
    $('.wf-check:checkbox').prop('checked', false)
    this.draftTarget.checked = true
    this.onSubmit()
  }

  awaitingReviewButton () {
    $('.wf-check:checkbox').prop('checked', false)
    this.awaitingReviewTarget.checked = true
    this.onSubmit()
  }

  reviewedButton () {
    $('.wf-check:checkbox').prop('checked', false)
    this.reviewedTarget.checked = true
    this.onSubmit()
  }

  returnedButton () {
    $('.wf-check:checkbox').prop('checked', false)
    this.returnedTarget.checked = true
    this.onSubmit()
  }
}
