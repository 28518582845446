import { Controller }  from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  static targets = ['power', 'interest', 'type_of_interested', 'recommended_action']


  connect() {
    this.onChange()
  }

  onChange(e) {
    var value_power = document.getElementById('power').value
    var value_interest = document.getElementById('interest').value
    if (value_power == 1 && value_interest == 1){
      $(this.type_of_interestedTarget).prop('value', 'Indeferente')
      $(this.recommended_actionTarget).prop('value', 'Monitorear con Esfuerzo Mínimo')
      document.getElementById('type_of_interested').style.backgroundColor = '#33BA38'
      document.getElementById('type_of_interested').style.color = '#4F4F4F'
      document.getElementById('recommended_action').style.backgroundColor = '#33BA38'
      document.getElementById('recommended_action').style.color = '#4F4F4F'
    }
    else if(value_power == 1 && value_interest == 2){
      $(this.type_of_interestedTarget).prop('value', 'Defensor')
      $(this.recommended_actionTarget).prop('value', 'Mantener Informado')
      document.getElementById('type_of_interested').style.backgroundColor = '#FAFA46'
      document.getElementById('type_of_interested').style.color = '#4F4F4F'
      document.getElementById('recommended_action').style.backgroundColor = '#FAFA46'
      document.getElementById('recommended_action').style.color = '#4F4F4F'
    }
    else if(value_power == 2 && value_interest == 1){
      $(this.type_of_interestedTarget).prop('value', 'Latente')
      $(this.recommended_actionTarget).prop('value', 'Mantener Satisfecho')
      document.getElementById('type_of_interested').style.backgroundColor = '#FFC100'
      document.getElementById('type_of_interested').style.color = '#4F4F4F'
      document.getElementById('recommended_action').style.backgroundColor = '#FFC100'
      document.getElementById('recommended_action').style.color = '#4F4F4F'
    }
    else if(value_power == 2 && value_interest == 2){
      $(this.type_of_interestedTarget).prop('value', 'Promotor')
      $(this.recommended_actionTarget).prop('value', 'Gestionar Activamente')
      document.getElementById('type_of_interested').style.backgroundColor = '#B42100'
      document.getElementById('type_of_interested').style.color = 'white'
      document.getElementById('recommended_action').style.backgroundColor = '#B42100'
      document.getElementById('recommended_action').style.color = 'white'
    }
  }
}
