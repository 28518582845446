import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  connect () {
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()
    var number = document.getElementsByClassName('element').length
    var defaul = 1 / number
    var sum_weight = 0
    var sum_score = 0
    for (var x = 0; x < number; x++) {
      if (document.getElementById('weight' + x).value) {} else {
        document.getElementById('weight' + x).setAttribute('value', defaul)}
      var weight = document.getElementById('weight' + x).value
      var classification = document.getElementById('classification' + x).value
      var result = (weight * classification).toFixed(3)
      var sum_weight = parseFloat(sum_weight) + parseFloat(weight)
      var sum_score = parseFloat(sum_score) + parseFloat(result)
      document.getElementById('score' + x).setAttribute('value', result)
      document.getElementById('weight_opportunities').setAttribute('value', sum_weight.toFixed(3))
      document.getElementById('score_opportunities').setAttribute('value', sum_score.toFixed(3))
    }
  }
}
