import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['select', 'checkbox']

  connect () {
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()

    if ($('#equipment_require_calibration').prop('checked')) { $('#calibrationField').show() } else { $('#calibrationField').hide() }
  }
}
