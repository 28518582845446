import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../context/GlobalContext";
import Week from "./week/Week";
import Day from "./day/Day";
import Month from "./month/Month";
import Year from "./year/Year";
import PeriodicsEvents from "./periodicEvents/PeriodicsEvents";
import PersonalEvents from "./personalEvents/PersonalEvents";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Time() {
  const { typeTime, selectedDate, selectedPeriodicity, flaged } =
    useContext(GlobalContext);
  const [events, setEvents] = useState([]);
  const [flag, setFlag] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [timeFlag, setTimeFlag] = useState(false);
  const [size, setSize] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setSize(isMobile ? "col" : "col-10");
  }, [isMobile]);

  const getType = (value) => {
    switch (value) {
      case 0:
        return "diary";
      case 1:
        return "day";
      case 2:
        return "week";
      case 3:
        return "month";
      case 4:
        return "year";
      case 5:
        return "periodicity";
      default:
        return "week";
    }
  };

  useEffect(() => {
    const type = getType(typeTime);
    const date = selectedDate;
    const parsedDate = new Date(date);
    const formattedDate = parsedDate.toISOString();
    const baseUrl = "/api/v2/events/";
    const params = new URLSearchParams();

    params.append(`filters[${type}]`, formattedDate);
    params.append(
      "filters[periodicity]",
      typeTime === 5 ? selectedPeriodicity : ""
    );
    const url = `${baseUrl}?${params}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setEvents(data);
        setFlag(true);
      });
  }, [flaged, selectedDate, typeTime, selectedPeriodicity, timeFlag]);

  useEffect(() => {
    setTimeout(() => {
      setTimeFlag((prevFlaged) => !prevFlaged);
    }, 6000);
  }, [events]);

  let timeView;

  switch (typeTime) {
    case 0:
      timeView = (
        <PersonalEvents events={events} flag={flag} setFlag={setFlag} />
      );
      break;
    case 1:
      timeView = <Day events={events} flag={flag} setFlag={setFlag} />;
      break;
    case 2:
      timeView = <Week events={events} flag={flag} setFlag={setFlag} />;
      break;
    case 3:
      timeView = <Month events={events} flag={flag} setFlag={setFlag} />;
      break;
    case 4:
      timeView = <Year events={events} flag={flag} setFlag={setFlag} />;
      break;
    case 5:
      timeView = (
        <PeriodicsEvents events={events} flag={flag} setFlag={setFlag} />
      );
      break;
    default:
      timeView = <Week events={events} flag={flag} setFlag={setFlag} />;
  }

  return <div className={size}>{timeView}</div>;
}
