import React, { useContext, useEffect, useState } from "react";
import { Link, BrowserRouter as Router, Route, Routes } from "react-router-dom";
import axios from "axios";
import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../components/Context/AppContext";
import { FLASH_TYPE_ERROR, useFlash } from "../components/Context/FlashContext";
import Table from "../components/Table";
import Header from "../components/Header";
import LoaderTable from "../components/Skeleton/LoaderTable";
import {
  FlashContext,
  useFlashState,
} from "../components/Context/FlashContext";
import { FlashAlerts } from "../components/Flash/FlashAlerts";
import NewTroubleType from "./NewTroubleType";
import UpdateTroubleType from "./UpdateTroubleType";

const TroubleTypes = () => {
  const [typesTroubles, setTypesTroubles] = useState([]);
  const { setLoading } = useContext(AppContext);
  const { setFlash } = useFlash();
  const fetchTroublesTypes = async () => {
    try {
      const url = "/api/v2/trouble_types";
      const response = await axios.get(url);
      const data = await response.data;
      setTypesTroubles(data);
    } catch (error) {
      setFlash({
        type: FLASH_TYPE_ERROR,
        message: "Ocurrio un error en el servidor",
      });

      console.error("Error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTroublesTypes();
  }, []);

  const deleteType = async (id) => {
    try {
      const url = `/api/v2/trouble_types/${id}`;
      const response = await axios.delete(url);
      if (response.status === 204) {
        setFlash({
          type: FLASH_TYPE_ERROR,
          message: "Elemento Eliminado",
        });
        fetchTroublesTypes();
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      setFlash({
        type: FLASH_TYPE_ERROR,
        message: "Ocurrio un error en el servidor",
      });

      console.error("Error", error);
    }
  };
  return (
    <>
      <Header
        title={"Tipos de problemas"}
        buttonNewElement={
          <Link
            to={"/troubles-admin/new"}
            className="position-absolute"
            style={{ right: "21px", top: "9px" }}
          >
            <Button>Nuevo</Button>
          </Link>
        }
      />
      <Table
        heads={
          <tr>
            <th className="text-center">#</th>
            <th></th>
            <th className="text-center">Name</th>
          </tr>
        }
        loaderSize={
          <>
            {Array.from({ length: 3 }).map((_, index) => (
              <tr key={index}>
                <td>
                  <LoaderTable />
                </td>
                <td>
                  <LoaderTable />
                </td>
                <td>
                  <LoaderTable />
                </td>
              </tr>
            ))}
          </>
        }
        data={
          <>
            {typesTroubles.map((item, index) => (
              <tr key={item.id}>
                <td style={{ textAlign: "center" }}>{index}</td>
                <td>
                  <Container className="d-flex">
                    <Link to={`/troubles-admin/update/${item.id}`}>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Editar</Tooltip>}
                      >
                        <Button variant="outline-success" className="m-2">
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </Button>
                      </OverlayTrigger>
                    </Link>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Eliminar</Tooltip>}
                    >
                      <Button
                        variant="outline-danger"
                        className="m-2"
                        onClick={() => deleteType(item.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </OverlayTrigger>
                  </Container>
                </td>
                <td style={{ textAlign: "center" }}>{item.name}</td>
              </tr>
            ))}
          </>
        }
      />
    </>
  );
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const flashState = useFlashState();

  const valuesState = {
    loading,
    setLoading,
  };

  return (
    <FlashContext.Provider value={flashState}>
      <AppContext.Provider value={valuesState}>
        <FlashAlerts />
        <Router>
          <Routes>
            <Route path="/troubles-admin" element={<TroubleTypes />} />
            <Route path="/troubles-admin/new" element={<NewTroubleType />} />
            <Route
              path="/troubles-admin/update/:id"
              element={<UpdateTroubleType />}
            />
          </Routes>
        </Router>
      </AppContext.Provider>
    </FlashContext.Provider>
  );
};

export default App;
