import React, {useEffect, useState} from 'react'
import LinkTitle from './LinkTitle'
import Chart from './Chart'
import ProgressLine from './ProgressLine'
const ProcedureTypeSmallCard = (props) => {
  const { title, percentage, color, procedures, type, modalControl, date, counts} = props

  const [cardclass, setClass] = useState('col-12')

  useEffect(() => {
    setClass(`col-12`)
  }, [counts])
  return (
    <React.Fragment>
      <div className={cardclass}>
        <div className='card'>
          <div className='card-body'>
            <LinkTitle title={title} type={type} modalControl={modalControl} date={date}/>
            <Chart
              progress={percentage}
              size={80}
              strokeWidth={6}
              circleTwoStroke={color}
            />
            <ProgressLine percentage={percentage} color={color} text='Procesos: ' count={procedures}/>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProcedureTypeSmallCard
