import { Controller }  from 'stimulus'

export default class extends Controller {
  static targets=['total', 'semaphore', 'score', 'observation']
  onChange () {
    let assessments = $('.proposal'+this.data.get('proposal'))
    const semaphores = JSON.parse(this.data.get('semaphores'))
    var sum = 0
    assessments.each(function(i, obj) {
      if (Number.isInteger(parseInt(obj.value))) {
        sum += parseFloat(obj.value) * parseFloat(obj.getAttribute('data-weigh'))
      }
    })
    $(this.totalTarget).html(sum.toFixed(2))
    $(this.scoreTarget).val(sum)
    $(this.observationTarget).html(this.semaphoreName(20*sum, semaphores))
    let color = this.semaphoreLevel(20*sum, semaphores)
    $(this.semaphoreTarget).html((20*sum).toFixed(1)+'%')
    $(this.semaphoreTarget).css("background-color", color)
    this.setTotal()
  }

  setTotal() {
    let scores = $('.proposal-score')
    var sum = 0
    const semaphores = JSON.parse(this.data.get('semaphores'))
    scores.each(function(i, obj) {
      let number = parseFloat(obj.innerHTML)
      if (Boolean(number)) {
        sum += number
      }
    })
    $('#totalScore').html(sum.toFixed(2))
    if(Boolean(scores.length)) {
      let totalValue = 20*(sum).toFixed(1) / scores.length
      $('#totalSemaphore').html(totalValue +'%')
      $('#totalSemaphore').css("background-color", this.semaphoreLevel(totalValue, semaphores))
    }
  }

  semaphoreLevel(percentage, semaphores) {
    var color
    for(var i=0;i<semaphores.length; i++) {
      var level = semaphores[i]
      if ((level.range_type == "between") && (parseInt(percentage) >= parseInt(level.starts)) && (parseInt(percentage) <= parseInt(level.ends))) {
        color =  level.color
        break
      }

      if ((level.range_type == "equal_to") && (parseInt(percentage) == parseInt(level.starts))) {
        color =  level.color
        break
      }

      if ((level.range_type == "greater_than") && (parseInt(percentage) > parseInt(level.starts))) {
        color =  level.color
        break
      }

      if ((level.range_type == "greater_than_or_equal_to") && (parseInt(percentage) >= parseInt(level.starts))) {
        color =  level.color
        break
      }

      if ((level.range_type == "less_than") && (parseInt(percentage) < parseInt(level.starts))) {
        color =  level.color
        break
      }

      if ((level.range_type == "less_than_or_equal_to") && (parseInt(percentage) <= parseInt(level.starts))) {
        color =  level.color
        break
      }
    }
    return color
  }

  semaphoreName(percentage, semaphores) {
    var color
    for(var i=0;i<semaphores.length; i++) {
      var level = semaphores[i]
      if ((level.range_type == "between") && (parseInt(percentage) >= parseInt(level.starts)) && (parseInt(percentage) <= parseInt(level.ends))) {
        color =  level.name
        break
      }

      if ((level.range_type == "equal_to") && (parseInt(percentage) == parseInt(level.starts))) {
        color =  level.name
        break
      }

      if ((level.range_type == "greater_than") && (parseInt(percentage) > parseInt(level.starts))) {
        color =  level.name
        break
      }

      if ((level.range_type == "greater_than_or_equal_to") && (parseInt(percentage) >= parseInt(level.starts))) {
        color =  level.name
        break
      }

      if ((level.range_type == "less_than") && (parseInt(percentage) < parseInt(level.starts))) {
        color =  level.name
        break
      }

      if ((level.range_type == "less_than_or_equal_to") && (parseInt(percentage) <= parseInt(level.starts))) {
        color =  level.name
        break
      }
    }
    return color
  }
}
