import React, { useState } from "react";
import GlobalContext from "./GlobalContext";

export default function ContextWrapper(props) {
  const [selectedDataIndex, setSelectedDataIndex] = useState(null);
  return (
    <GlobalContext.Provider value={{ selectedDataIndex, setSelectedDataIndex }}>
      {props.children}
    </GlobalContext.Provider>
  );
}
