import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content', 'finalGrade', 'automatedLevel', 'documentation', 'complexity', 'execution', 'reductionLevel'];

  connect() {
    this.uplLogged = JSON.parse(this.element.dataset.upluser);
    const parentDiv = this.element.querySelector('.parent-div');
    this.updateValues(parentDiv);
    const observer = new MutationObserver(this.handleContentChange.bind(this));
    observer.observe(this.contentTarget, { childList: true, subtree: true });
    this.addEventListeners();
  }

  addEventListeners() {
    const selectAutomated = Array.from(this.element.querySelectorAll('select.automated_level'));
    const selectDocumentation = Array.from(this.element.querySelectorAll('select.documentation'));
    const selectComplexity = Array.from(this.element.querySelectorAll('select.complexity'));
    const selectExecution = Array.from(this.element.querySelectorAll('select.execution'));
    const selectReduction = Array.from(this.element.querySelectorAll('select.reduction_level'));
    const inputFinalGrade = Array.from(this.element.querySelectorAll('input.final_grade'));
    const checkboxProb = Array.from(this.element.querySelectorAll('.probability-check'));
    const checkboxImpact = Array.from(this.element.querySelectorAll('.impact-check'));

    selectAutomated.forEach(select => select.addEventListener('change', this.handleChange.bind(this)));
    selectDocumentation.forEach(select => select.addEventListener('change', this.handleChange.bind(this)));
    selectComplexity.forEach(select => select.addEventListener('change', this.handleChange.bind(this)));
    selectExecution.forEach(select => select.addEventListener('change', this.handleChange.bind(this)));
    selectReduction.forEach(select => select.addEventListener('change', this.handleChange.bind(this)));
    inputFinalGrade.forEach((input) => {
      input.addEventListener('change', (event) => {
        this.dispatchInputEvent(event);
      });
    });
    checkboxProb.forEach((checkbox, index) => {
      checkbox.addEventListener('change', (event) => {
        this.dispatchCustomEvent(event, index);
      });
    });
    checkboxImpact.forEach((checkbox, index) => {
      checkbox.addEventListener('change', (event) => {
        this.dispatchCustomEvent(event, index);
      });
    });
  }

  dispatchInputEvent(event) {
    const customEvent = new CustomEvent('finalGradeChange', {
      bubbles: true,
      detail: {
        value: event,
      },
    });
    this.element.dispatchEvent(customEvent);
  }

  dispatchCustomEvent(event, index) {
    let eventType;
    let customEvent = '';
    if (event instanceof PointerEvent) {
      eventType = 'pointerEvent';
    } else if (event instanceof Event) {
      eventType = 'genericEvent';
    }
    if (eventType === 'genericEvent') {
      customEvent = new CustomEvent('checkboxChange', {
        bubbles: true,
        detail: {
          value: event.target.checked,
          name: event.target.name,
          index: index,
        },
      });
    } else {
      customEvent = new CustomEvent('checkboxChange', {
        bubbles: true,
        detail: {
          value: false,
          name: 'all',
          index: index
        },
      });
    }
    this.element.dispatchEvent(customEvent);
  }

  handleContentChange = (mutationsList) => {
    this.addEventListeners();
    if (!mutationsList[0].removedNodes.length > 0) {
      const parentDiv = mutationsList[0].addedNodes[2];
      this.updateValues(parentDiv);
    }
  }

  handleChange(event) {
    const selectedValue = event.target;
    const parentDiv = selectedValue.closest('.parent-div');
    this.updateValues(parentDiv);
  }

  updateValues = (parentDiv) => {
    const selects = parentDiv.querySelectorAll('select');
    const input = parentDiv.querySelector('input.final_grade');
    let sum = 0;
    selects.forEach((select) => {
      sum += parseInt(select.value, 10);
    });
    if (this.uplLogged) {
      sum = sum * 10/4;
    }
    input.value = sum;
    if (sum >= 86) {
      input.style.color = '#FFFFFF';
      input.style.backgroundColor = 'green';
    } else if (sum >= 71 && sum < 86) {
      input.style.backgroundColor = '#46E7FA';
    } else if (sum >= 56 && sum < 71) {
      input.style.backgroundColor = '#FAFA46';
      input.style.color = '#000000';
    } else if (sum >= 36 && sum < 56) {
      input.style.backgroundColor = '#FFC100';
    } else {
      input.style.backgroundColor = '#B42100';
      input.style.color = '#FFFFFF';
    }
    this.dispatchInputEvent(sum);
  }

  onDelete(e) {
    const deleteDivs = this.contentTarget.querySelectorAll('.delete-div');
    const link = e.currentTarget;
    const parentDiv = link.parentNode;
    let indexDiv = ''
    deleteDivs.forEach(function(deleteDiv, index) {
      if (deleteDiv === parentDiv) {
        indexDiv = index
      }
    });
    const hrElement = parentDiv.nextElementSibling;
    const nextDiv = hrElement.nextElementSibling;
    const nextHr = nextDiv.nextElementSibling;
    this.dispatchCustomEvent(e,indexDiv);
    $(parentDiv).fadeOut(() => {
      $(parentDiv).remove();
    });
    $(hrElement).fadeOut(() => {
      $(hrElement).remove();
    });
    $(nextDiv).fadeOut(() => {
      $(nextDiv).remove();
    });
    $(nextHr).fadeOut(() => {
      $(nextHr).remove();
    });
  }
}
