import { Controller } from 'stimulus'
import 'bootstrap/js/dist/modal'

export default class extends Controller {
  static targets = ['buttonsubmit', 'buttoncancel', 'buttondraft']

  submitted()
  {
    $(this.buttondraftTarget).prop('className', 'btn btn-info mr-3 disabled')
    $(this.buttoncancelTarget).prop('className', 'btn btn-link disabled')
    $(this.buttonsubmitTarget).prop('className', 'btn btn-primary disabled')
  }
}
