import React from "react";
import TrMissingAction from "./TrMissingAction";
import TrMissingEvaluation from "./TrMissingEvaluation";
import TrMissingAspect from "./TrMissingAspect";
import TrMissingActivity from "./TrMissingActivity";

const Tbody = ({ infoMatrix }) => {
  const renderRows = (data) => {
    const rows = [];
    data.environmental_impacts.forEach((impact, impactIndex) => {
      renderImpactRow(impact, impactIndex, rows);
    });
    return rows;
  };

  
  const renderImpactRow = (impact, impactIndex, rows = []) => {
    if (!impact.activities || impact.activities.length === 0) {
      rows.push(
        <TrMissingActivity
          impact={impact}
          actions={impact.impact_actions}
          key={impact.id}
        />
      );
    } else {
      impact.activities.forEach((activity, activityIndex) => {
        renderActivityRow(impact, impactIndex, activity, activityIndex, rows);
      });
    }

    return rows;
  };

  const renderActivityRow = (
    impact,
    impactIndex,
    activity,
    activityIndex,
    rows
  ) => {
    if (!activity.aspects || activity.aspects.length === 0) {
      rows.push(
        <TrMissingAspect
          key={`impact-${impactIndex}-activity-${activityIndex}`}
          impact={impact}
          activity={activity}
          activityIndex={activityIndex}
          actions={impact.impact_actions}
        />
      );
    } else {
      activity.aspects.forEach((aspect, aspectIndex) => {
        renderAspectRow(
          impact,
          impactIndex,
          activity,
          activityIndex,
          aspect,
          aspectIndex,
          rows
        );
      });
    }
  };

  const renderAspectRow = (
    impact,
    impactIndex,
    activity,
    activityIndex,
    aspect,
    aspectIndex,
    rows
  ) => {
    if (!aspect.evaluations || aspect.evaluations.length === 0) {
      rows.push(
        <TrMissingEvaluation
          key={`impact-${impactIndex}-activity-${activityIndex}-aspect-${aspectIndex}`}
          impact={impact}
          activity={activity}
          activityIndex={activityIndex}
          aspect={aspect}
          aspectIndex={aspectIndex}
          actions={impact.impact_actions}
        />
      );
    } else {
      aspect.evaluations.forEach((evaluation, evalIndex) => {
        renderEvaluationRow(
          impact,
          impactIndex,
          activity,
          activityIndex,
          aspect,
          aspectIndex,
          evaluation,
          evalIndex,
          rows
        );
      });
    }
  };

  const renderEvaluationRow = (
    impact,
    impactIndex,
    activity,
    activityIndex,
    aspect,
    aspectIndex,
    evaluation,
    evalIndex,
    rows
  ) => {
    rows.push(
      <TrMissingAction
        key={`impact-${impactIndex}-activity-${activityIndex}-aspect-${aspectIndex}-eval-${evalIndex}`}
        impact={impact}
        activity={activity}
        activityIndex={activityIndex}
        aspect={aspect}
        aspectIndex={aspectIndex}
        evaluation={evaluation}
        evalIndex={evalIndex}
        actions={impact.impact_actions}
      />
    );
  };

  return <tbody>{renderRows(infoMatrix)}</tbody>;
};

export default Tbody;
