import React, { useEffect, useState } from 'react'

const IndividualReports = ({
  procedureAudits, 
  opportunities, 
  prompts, 
  setModalFlag, 
  modalIndividualReports,
}) => {
  const setModal = (e) => {
    setModalFlag(1);
    modalIndividualReports(true, 'Editar Informe Individual', e);
  }
  const [abilities, setAbilities] = useState({});

  const getability = async (audit_program_id, procedure_id) => {
    try {
      const response = await fetch(
        '/api/v2/audit_reports/abilty_user_report_invididual?audit_program_id=' + audit_program_id + '&id=' + procedure_id
      );
      const data = await response.json();
      return data; // Asume que la respuesta es true o false
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchAbilities = async () => {
      const abilitiesMap = {};
      for (const procedure of procedureAudits) {
        const ability = await getability(procedure.audit_program_id, procedure.id);
        abilitiesMap[procedure.id] = ability;
      }
      setAbilities(abilitiesMap);
    };

    fetchAbilities();
  }, [procedureAudits]);
  return (
    <>
      <div className="card-body mb-2 px-1">
          <h5 className="card-title font-weight-bold">INFORMES INDIVIDUALES</h5>
          <div className="table_responsive">
            <table className="table table-borless table-sm mb-0">
              <tbody>
                <tr style={{backgroundColor: '#9FC5E8'}}>
                  {prompts.user_writer === true ? (
                    <td className="text-center" style={{width: '80px'}}>
                    </td>
                  ) : ( null )}
                  <th className="text-center">PROCESO</th>
                  <th className="text-center" style={{width: '20%'}}>FORTALEZAS</th>
                  <th className="text-center" style={{width: '20%'}}>CONFORMIDAD</th>
                  <th className="text-center" style={{width: '20%'}}>OPORTUNIDADES DE MEJORA</th>
                  <th className="text-center" style={{width: '20%'}}>NO CONFORMIDAD</th>
                </tr>
              {procedureAudits.map((procedure)=>(
                <tr key={procedure.id}>
                  {prompts.user_writer === true ? (
                    <td className="text-center">
                      <span className={`btn btn-outline-success btn-sm `} onClick={()=>setModal(procedure.audit_report_id)}>
                          <i className='far fa-edit'></i>
                      </span>
                    {opportunities.length >= 0 ? (
                      <>
                        {prompts.edit_condition === true || abilities[procedure.id] ? (
                          <a href={`/opportunities/new?audit=true&improvable_id=${procedure.audit_report_id}`} className='btn btn-outline-primary btn-sm' target='_blank'>
                            <i className='fas fa-arrow-circle-up'></i>
                          </a>
                        ) : ( null )}
                      </>
                    ):(
                      <a href={`/opportunities/${opportunities[0].id}`} className='btn btn-outline-primary btn-sm' target='_blank'>
                          <i className='fas fa-arrow-circle-up'></i>
                      </a>
                    )}
                    </td>
                  ) : ( null )}
                  <td >
                    {procedure.procedure}
                  </td>
                  <td className="text-justify">
                    <div className="whitespace">
                      {procedure.strengths}
                    </div>
                  </td>
                  <td className="text-justify">
                    <div className="whitespace">
                      {procedure.conformities}
                    </div>
                  </td>
                  <td className="text-justify">
                    <div className="whitespace">
                      {procedure.oportunities}
                    </div>
                  </td>
                  <td className="text-justify">
                    <div className="whitespace">
                      {procedure.nonconformities}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default IndividualReports