import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    if (this.data.get('url')){
      this.onChange()
    }
  }

  onChange (e) {
    if (e) e.preventDefault()
    const url = this.data.get('url')
    const id = this.data.get('id')
    const elementId = this.data.get('target')
    $.get(url + '?area_id=' + this.element.value + '&equipment_id=' + id).then((content) => {
      document.getElementById(elementId).innerHTML = content
    })
  }

  setInputValue(event) {
    const button = event.currentTarget;
    const equipmentId = button.getAttribute("name");
    const selectElement = document.getElementById('equipment_id');
    selectElement.value = equipmentId;
    // this.inputTarget.value = equipmentId;
  }
}
