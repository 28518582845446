import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  connect () {
    document.getElementById("other_impact").hidden = true
    document.getElementById("other_nature_change").hidden = true
  }

  onChange (e) {
    const isOtherSelected = document.getElementById("nature").value === 'otro';
    document.getElementById("other_nature_change").hidden = !isOtherSelected;

    const isOtherImpact = document.getElementById("impact").value === 'otro';
    document.getElementById("other_impact").hidden = !isOtherImpact;
  }
}
