import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['preventive', 'corrective', 'employee', 'calibration', 'area']

  connect () {
    this.showPreventive()
  }

  setAll () {
    $(this.preventiveTarget).prop('className', 'header_button')
    $(this.correctiveTarget).prop('className', 'header_button')
    $(this.calibrationTarget).prop('className', 'header_button')
    $(this.employeeTarget).prop('className', 'header_button')
    $(this.areaTarget).prop('className', 'header_button')
    $('#preventive').hide()
    $('#corrective').hide()
    $('#calibration').hide()
    $('#employee').hide()
    $('#area').hide()
  }

  showPreventive () {
    this.setAll()
    $(this.preventiveTarget).prop('className', 'header_button active_button')
    $('#preventive').show()
  }

  showCorrective () {
    this.setAll()
    $(this.correctiveTarget).prop('className', 'header_button active_button')
    $('#corrective').show()
  }

  showCalibration () {
    this.setAll()
    $(this.calibrationTarget).prop('className', 'header_button active_button')
    $('#calibration').show()
  }

  showEmployee () {
    this.setAll()
    $(this.employeeTarget).prop('className', 'header_button active_button')
    $('#employee').show()
  }

  showArea () {
    this.setAll()
    $(this.areaTarget).prop('className', 'header_button active_button')
    $('#area').show()
  }
}
