import React from "react";
import { TypeLaboratoriesProvider } from "./LabortoryContext";
import AppUI  from "./AppUI";

function TypeLaboratoryTests() {

  return(
    <TypeLaboratoriesProvider>
      <AppUI/>
    </TypeLaboratoriesProvider>
  )
}

export default TypeLaboratoryTests;