import { Controller }  from 'stimulus'

export default class extends Controller {
  static targets = ['servisType', 'productType']

  connect () {
    this.onChange()
  }

  onChange(e) {

    if (e) e.preventDefault()
    
    if ($(this.productTypeTarget).prop('checked')) {
      var nuevasOpciones = `
        <option value="Faltante de producto">Faltante de producto</option>
        <option value="Envases defectuosos">Envases defectuosos</option>
        <option value="Problemas de formulación">Problemas de formulación</option>
        <option value="Producto vencido">Producto vencido</option>
        <option value="Falla de etiquetas">Falla de etiquetas</option>
        <option value="Derrame">Derrame</option>
        <option value="Otros">Otros</option>
      `;
      $('#product').show('slow')
    } else if ($(this.servisTypeTarget).prop('checked')){
      var nuevasOpciones = `
        <option value="Clasificación">Clasificación</option>
        <option value="Atención del vendedor">Atención del vendedor</option>
        <option value="Atención del gestor de servicio">Atención del gestor de servicio</option>
        <option value="Seguimiento de solicitud">Seguimiento de solicitud</option>
        <option value="Atención del transportista">Atención del transportista</option>
        <option value="Atraso de entrega o tardía del producto">Atraso de entrega o tardía del producto</option>
        <option value="Errores en la entrega y/o facturación">Errores en la entrega y/o facturación</option>
        <option value="Atención de crédito y cobro">Atención de crédito y cobro</option>
        <option value="Otros">Otros</option>
      `;
      $('#product').hide('slow')
    }
    $('#clasification').html(nuevasOpciones);
  }

  
}
