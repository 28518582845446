import React, { useEffect, useState, useRef } from 'react';
const Chart = (props) => {
  const { size, progress, strokeWidth, circleOneStroke, circleTwoStroke, fontSize } = props;
  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const [offset, setOffset] = useState(0);
  const circleRef = useRef(null);
  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference;
    setOffset(progressOffset);
  }, [setOffset, circumference, progress, offset]);
  return (
    <svg className="svg my-0" width={size} height={size}>
      <circle
          className="svg-circle-bg"
          stroke='white'
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
      />
      <circle
          className="svg-circle"
          stroke={circleTwoStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          ref={circleRef}
          strokeDashoffset={offset}
      />  
      <text className="svg-circle-text" style={{fontSize: fontSize}} x={center} transform="translate(0,5)" y={center}>
        {progress}%
      </text>
    </svg>
  );
}

export default Chart