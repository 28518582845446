import React, { useState } from "react"

const CheckBox = ({id, checked, checkboxCallback}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    checkboxCallback(id, !isChecked)
  }

  return(
    <div className="custom-control custom-checkbox">
      <input
        className="custom-control-input ml-3"
        type="checkbox"
        checked={isChecked}
        id={`custom-checkbox-${id}`}
        onChange={handleCheckboxChange}
      />
      <label className="custom-control-label mr-3" htmlFor={`custom-checkbox-${id}`}></label>
    </div>
  )
}

export default CheckBox