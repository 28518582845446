import { Controller } from 'stimulus'
import { DirectUpload } from '@rails/activestorage'
import Dropzone from 'dropzone'

export default class extends Controller {
  uploadFile = (file, url, name) => {
    const upload = new DirectUpload(file, url, this)
    const root = this.element

    upload.create((error, blob) => {
      if (error) {
        console.error(error)
      } else {
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute('type', 'hidden')
        hiddenField.setAttribute('value', blob.signed_id)
        hiddenField.name = name
        $(root).closest('form').append(hiddenField)
      }
    })
  };

  connect () {
    const root = this.element
    const url = this.data.get('upload-url')
    const input = root.querySelector('input[type="file"]')
    const name = input.name

    this.myDropzone = new Dropzone(root.querySelector('.js-dropzone'), {
      url,
      autoDiscover: false,
      autoQueue: false,
      maxFiles: 1,
      uploadMultiple: false,
      previewTemplate: this.previewTemplate,
      accept: (file, done) => {
        this.uploadFile(file, url, name)
        done()
      },
      maxfilesexceeded: (file) => {
        this.myDropzone.removeAllFiles(true)
        this.myDropzone.addFile(file)
      }
    })
    
  }

  disconnect () {
    this.myDropzone = null
  }

  directUploadWillStoreFileWithXHR (request) {
    request.upload.addEventListener('progress', event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress (event) {
    const root = this.element

    root.querySelector('.progress .progress-bar').style.width = `${event.loaded * 100 / event.total}%`
  }

  get previewTemplate () {
    return `
    <div class=s"w-100">
      <div class="row">
        <div class="col-10">
          <span data-dz-name></span>
          <span data-dz-size></span>
        </div>
        <div class="col-2">
          <button data-dz-remove class="btn btn-link">
            <i class="fas fa-times" /></i>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-2">
          <div class="progress">
            <div class="progress-bar bg-success" style="width:0%;" />
          </div>
        </div>
      </div>
    </div>`
  }
}
