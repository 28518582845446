import React, { useState, useEffect } from "react";
import NatureModal from "./NatureModal";
import IntensityModal from "./IntensityModal";
import ExtensionModal from "./ExtensionModal";
import MomentumModal from "./MomentumModal";
import PersistencyModal from "./PersistencyModal";
import ReversibilityModal from "./ReversibilityModal";
import SinergyModal from "./SinergyModal";
import AccumulateModal from "./AccumulateModal";
import EffectModal from "./EffectModal";
import PeriodicityModal from "./PeriodicityModal";
import RecuperabilityModal from "./RecuperabilityModal";

const Modal = ({ modalFlag, setFunction }) => {
  return (
    <div
      className="modal fade show"
      tabIndex="-1"
      style={{
        paddingRight: "4px",
        display: "block",
        backgroundColor: "rgba(53, 53, 53, 0.5)",
      }}
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-scrollable"
        role="document"
        style={{ maxWidth: "70%" }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Evaluación de Impacto</h4>
            <button
              type="btn btn-link"
              className="close"
              onClick={() => setFunction(false)}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {modalFlag === 0 && <NatureModal />}
            {modalFlag === 1 && <IntensityModal />}
            {modalFlag === 2 && <ExtensionModal />}
            {modalFlag === 3 && <MomentumModal />}
            {modalFlag === 4 && <PersistencyModal />}
            {modalFlag === 5 && <ReversibilityModal />}
            {modalFlag === 6 && <SinergyModal />}
            {modalFlag === 7 && <AccumulateModal />}
            {modalFlag === 8 && <EffectModal />}
            {modalFlag === 9 && <PeriodicityModal />}
            {modalFlag === 10 && <RecuperabilityModal />}
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-secondary"
              onClick={() => setFunction(false)}
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
