import { Controller }  from 'stimulus'

export default class extends Controller {

  onChange() {
    if (! $('input:checkbox').not(":eq(0)").is(':checked')) {
      $('input:checkbox').prop('checked',true);
    } else {
      $('input:checkbox').prop('checked', false);
    }
  }

  arreglo(e)
  {
    var dic = {}
    if (! $('input:checkbox').not(":eq(0)").is(':checked')) {
      dic.this.element.name = this.element.id
    } else {
      nombre = "hol"
      dic.nombre = this.element.id
    }
  }

}
