import { Controller } from 'stimulus'
import {
  CATEGORY_INTERNAL,
  CATEGORY_EXTERNAL,
  CATEGORY_NORM,
  CATEGORY_REGISTRY,
  CATEGORY_INTERNAL_NORM,
  WITH_PARENT
} from '../shared/constants'

function showTarget (target, withParent) {
  if (withParent) {
    $(target).closest('.js-parent').show()
  } else {
    $(target).show()
  }
}

function hideTarget (target, withParent) {
  if (withParent) {
    $(target).closest('.js-parent').hide()
  } else {
    $(target).hide()
  }
}

export default class extends Controller {
  static targets = [
    'category',
    'code',
    'version',
    'documentType',
    'normType',
    'externalDocumentType',
    'externalFields',
    'procedure',
    'entity',
    'retentionTable',
    'appliesTo',
    'extraDetails',
    'extraDetailsTitle',
    'sequence',
    'subSequence',
    'seeAll'
  ]

  connect () {
    this.handlers = {
      [CATEGORY_INTERNAL]: this.handleInternalCategory,
      [CATEGORY_EXTERNAL]: this.handleExternalCategory,
      [CATEGORY_NORM]: this.handleNormCategory,
      [CATEGORY_REGISTRY]: this.handleRegistryCategory,
      [CATEGORY_INTERNAL_NORM]: this.handleInternalNormCategory
    }

    this.onChange()
    this.onChangeSubSequence()
    this.hideRead()
    this.ChangeAppliesTo()
    this.block_button()
  }

  onChange (e) {
    if (e) {
      e.preventDefault()
    }

    this.setProcedureCode()
    this.setCategory()
    this.setDocumentType()
    this.setDocumentVersion()
    this.cleanupDocumentCode()
    this.toggleDocumentCategory()
  }

  cleanupDocumentCode () {
    // Adds place holder and disables input
    if (this.category === CATEGORY_INTERNAL) {
      this.codeTarget.setAttribute('readonly', '')
      var id = document.getElementById('id_portal').value
      var id = id.toString()
      if(this.montagas(id)){
        $.get('/documents/next_consecutive_and_parent_id?procedure='+this.procedureTarget.value+'&document_type='+this.documentTypeTarget.value).then((response) => {
          let code_procedure = `${response.code_parent}${this.procedureCode}`
          $.get('/documents/order_codification?procedure='+code_procedure+'&document_type='+this.documentTypeCode+'&consecutive='+response.new_consecutive).then((response) => {
            this.codeTarget.placeholder = `${response.codification}`
          })
        })
      }
      else if(this.confival(id)){
        $.get('/documents/next_consecutive?procedure='+this.procedureTarget.value+'&document_type='+this.documentTypeTarget.value).then((response) => {
          let code_procedure = `CFV-${this.procedureCode}`
          $.get('/documents/order_codification?procedure='+code_procedure+'&document_type='+this.documentTypeCode+'&consecutive='+response.new_consecutive).then((response_tow) => {
            this.codeTarget.placeholder = `${response_tow.codification}`
          })
        })
      }
      else if(this.corposalud_upl(id)){
        $.get('/documents/next_consecutive?procedure='+this.procedureTarget.value+'&document_type='+this.documentTypeTarget.value).then((response) => {
          let new_number = this.add_zero(response.new_consecutive)
          var encodedProcedureCode = encodeURIComponent(this.procedureCode);
          $.get('/documents/order_codification?procedure='+encodedProcedureCode+'&document_type='+this.documentTypeCode+'&consecutive='+new_number).then((response) => {
            this.codeTarget.placeholder = `${response.codification}`
          })
        })
      }
      else if(this.calderon(id)){
        $.get('/documents/next_consecutive?procedure='+this.procedureTarget.value+'&document_type='+this.documentTypeTarget.value).then((response) => {
          let new_number = this.add_zero(response.new_consecutive)
          $.get('/documents/order_codification?procedure='+this.procedureCode+'&document_type='+this.documentTypeCode+'&consecutive='+new_number).then((response) => {
            this.codeTarget.placeholder = `${response.codification}`
          })
        })
      }
      else{
        $.get('/documents/next_consecutive?procedure='+this.procedureTarget.value+'&document_type='+this.documentTypeTarget.value).then((response) => {
          $.get('/documents/order_codification?procedure='+this.procedureCode+'&document_type='+this.documentTypeCode+'&consecutive='+response.new_consecutive).then((response) => {
            this.codeTarget.placeholder = `${response.codification}`
          })
        })
      }
      showTarget(this.codeTarget, WITH_PARENT)
    // Disables and hide input
    } else if ([CATEGORY_EXTERNAL, CATEGORY_NORM, CATEGORY_INTERNAL_NORM].includes(this.category)) {
      this.codeTarget.setAttribute('readonly', '')
      this.codeTarget.value = ''
      hideTarget(this.codeTarget, WITH_PARENT)
    // Enables for manual input
    } else if ([CATEGORY_REGISTRY].includes(this.category)) {
      this.codeTarget.removeAttribute('readonly')
      this.codeTarget.value = ''
      showTarget(this.codeTarget, WITH_PARENT)
    }
  }

  toggleDocumentCategory () {
    this.handlers[this.category]()
  }

  handleInternalCategory = () => {
    hideTarget(this.externalFieldsTarget)
    hideTarget(this.externalDocumentTypeTarget, WITH_PARENT)
    hideTarget(this.extraDetailsTarget)
    hideTarget(this.normTypeTarget, WITH_PARENT)

    showTarget(this.documentTypeTarget, WITH_PARENT)
    showTarget(this.versionTarget, WITH_PARENT)
  }

  handleExternalCategory = () => {
    const title = $(this.extraDetailsTarget).data(`title-${this.category}`)
    $(this.extraDetailsTitleTarget).text(title)

    hideTarget(this.documentTypeTarget, WITH_PARENT)
    hideTarget(this.retentionTableTarget)
    hideTarget(this.normTypeTarget, WITH_PARENT)
    // hideTarget(this.articleTarget, WITH_PARENT)
    hideTarget(this.entityTarget, WITH_PARENT)
    hideTarget(this.versionTarget, WITH_PARENT)

    showTarget(this.externalDocumentTypeTarget, WITH_PARENT)
    showTarget(this.externalFieldsTarget)
    showTarget(this.extraDetailsTarget)
  }

  handleNormCategory = () => {
    const title = $(this.extraDetailsTarget).data(`title-${this.category}`)
    $(this.extraDetailsTitleTarget).text(title)

    hideTarget(this.documentTypeTarget, WITH_PARENT)
    hideTarget(this.retentionTableTarget)
    hideTarget(this.externalFieldsTarget)
    hideTarget(this.externalDocumentTypeTarget, WITH_PARENT)
    hideTarget(this.versionTarget, WITH_PARENT)

    showTarget(this.extraDetailsTarget)
    showTarget(this.normTypeTarget, WITH_PARENT)
    // showTarget(this.articleTarget, WITH_PARENT)
    showTarget(this.entityTarget, WITH_PARENT)
  }

  handleInternalNormCategory = () => {
    hideTarget(this.documentTypeTarget, WITH_PARENT)
    hideTarget(this.externalFieldsTarget)
    hideTarget(this.externalDocumentTypeTarget, WITH_PARENT)
    hideTarget(this.versionTarget, WITH_PARENT)
    hideTarget(this.entityTarget, WITH_PARENT)
    showTarget(this.extraDetailsTarget)
    showTarget(this.retentionTableTarget)
    showTarget(this.normTypeTarget, WITH_PARENT)
    showTarget(this.extraDetailsTarget)
  }

  handleRegistryCategory = () => {
    hideTarget(this.documentTypeTarget, WITH_PARENT)
    hideTarget(this.externalFieldsTarget)
    hideTarget(this.externalDocumentTypeTarget, WITH_PARENT)
    hideTarget(this.extraDetailsTarget)
    hideTarget(this.normTypeTarget, WITH_PARENT)
    showTarget(this.versionTarget, WITH_PARENT)
  }

  onChangeSubSequence (e) {
    if (e) {
      e.preventDefault()
    }

    this.sequenceTarget.value = $(this.subSequenceTarget).find(':selected').data('parentId')
  }

  setProcedureCode () {
    this.procedureCode = $(this.procedureTarget).find(':selected').data('code')
  }

  setCategory () {
    this.category = $("input[name='document[category]']:checked").val()
  }

  setDocumentType () {
    this.documentTypeCode = $(this.documentTypeTarget).find(':selected').data('code')
  }

  setDocumentVersion () {
    this.version = this.versionTarget.value
  }

  hideRead () {
    if ($('#document_see_all').prop('checked')) { $('#read_field').hide() } else { $('#read_field').show() }
  }

  ChangeAppliesTo () {
    if ($('#document_all_procedures').prop('checked')) { hideTarget(this.appliesToTarget) } else { showTarget(this.appliesToTarget) }
  }

  montagas(id) {
    return id == '096ea912-70d8-4d11-8f72-c99fc48a2c0d' || id == 'af6485da-eb75-49ea-a18a-474d9d9cf011'
  }
  confival(id){
    return id == '4c90cd56-b0ab-4c16-ac36-0c8a620e6c64'
  }
  corposalud_upl(id){
    return id == '8ed00dec-578e-44c6-ad83-c5eb06a5ec3d' || id == '21aed10a-dd78-4b86-9780-0e12b7fdc20c'
  }
  calderon(id) {
    const ids = ['6e4bb540-cc98-4207-9b3b-52a6510086ce', 'fe5afa1f-580d-4fdb-a78e-3fe4b317bfba', '4c4c16b6-9a67-42cf-9f8f-11d78b860316', '7d49ecb8-d5d9-452e-8eca-0e051b403f31', 'c8652c6a-9484-42d9-83ad-2fffc2430c5e'];
    return ids.includes(id);
  }

  add_zero(numero) {
    if (numero >= 1 && numero <= 9) {
        return "00" + numero.toString();
    } else if (numero >= 10 && numero <= 99) {
        return "0" + numero.toString();
    } else {
        return numero.toString(); // No hace falta agregar ceros si es mayor de 99
    }
  }
  codification(data){

  }

  block_button(){
    const buttonReturn = document.getElementById('return')
    if (buttonReturn){
      buttonReturn.addEventListener('click', function() {
        const time = 15;
        setTimeout(function() {
          buttonReturn.disabled = true;
        }, time);
      })
    }
    const buttonReview = document.getElementById('review')
    if (buttonReview){
      buttonReview.addEventListener('click', function() {
        const time = 15;
        setTimeout(function() {
          buttonReview.disabled = true;
        }, time);
      })
    }
    const buttonApprove = document.getElementById('approve')
    if (buttonApprove){
      buttonApprove.addEventListener('click', function() {
        const time = 15;
        setTimeout(function() {
          buttonApprove.disabled = true;
        }, time);
      })
    }
    const buttonDraft = document.getElementById('draft')
    if (buttonDraft){
      buttonDraft.addEventListener('click', function() {
        const time = 15;
        setTimeout(function() {
          buttonDraft.disabled = true;
        }, time);
      })
    }
    const buttonSubmit = document.getElementById('submit')
    if (buttonSubmit){
      buttonSubmit.addEventListener('click', function() {
        const time = 15;
        setTimeout(function() {
          buttonSubmit.disabled = true;
        }, time);
      })
    }
    const buttonSave_gd = document.getElementById('save_gd')
    if (buttonSave_gd){
      buttonSave_gd.addEventListener('click', function() {
        const time = 15;
        setTimeout(function() {
          buttonSave_gd.disabled = true;
        }, time);
      })
    }
  }
}
