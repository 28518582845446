import React, { useState, useEffect } from "react";

const Header = ({ matrixId, handleSubmit }) => {
  return (
    <>
      <div
        className="card border-0"
        data-sidebar-section="js-environmental-management"
        data-sidebar-menu="js-environmental-impacts-menu"
      >
        <div className="card-header d-flex justify-content-between position-sticky sticky-top bg-white">
          <div className="card-title mb-0 d-flex align-items-center">
            <a
              className="btn btn-link"
              href={`/environmental_matrices/${matrixId}/environmental_impacts`}
            >
              <i className="fas fa-arrow-left"></i>
            </a>
            <h5 className="mb-0">Impacto Ambiental</h5>
          </div>
          <div className="card-options d-flex">
            <button
              className="btn btn-primary mx-1"
              type="submit"
              onClick={(e) => handleSubmit(e)}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
