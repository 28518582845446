import React, { useState } from "react";
import { LaboratoriesContext } from "../LaboratoriesContext";
import { InputGroup } from "../../InputGroup";

function Approved(){
  const {
    setOpenModalApproved,
    dataLaboratory,
    updateLaboratory
  } = React.useContext(LaboratoriesContext)
  const [descriptionNoApproval, setDescriptionNoApproval] = useState('')
  const [approval, setApproval] = useState(true)
  const [disabledSubmit, setDisabledSubmit] = useState(false)
  const onUpdate = (e) => {
    e.preventDefault();
    setDisabledSubmit(true);
    const dataupdateLaboratory = {
      approval: approval,
      description_no_approval: approval === true ? '' : descriptionNoApproval,
      state: approval === true ? 'sampling_and_assignment' : 'rejected'
    }
    updateLaboratory(dataLaboratory.id, dataupdateLaboratory)
  }
  
  const onCancel = () => {
    setOpenModalApproved(false);
  }

  return(
    <div className="modal fade show" tabIndex="-1" style={{ paddingRight: '4px', display: 'block', backgroundColor: 'rgba(3, 3, 3, 0.5)' }} role="document">
      <form onSubmit={onUpdate}>
        <div className="modal-dialog modal-dialog-scrollable" role="document" style={{ maxWidth: '700px' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Aprobación de la prueba de laboratorio</h5>
            </div>
            <div className="modal-body">
              <div className="form-check form-check-inline ml-1 mt-1">
                ¿Desea aprobar la solicitud de laboratorio?
                <div className="ml-3 mt-2">
                  <input
                    type="radio"
                    name="approval" // Mismo name para ambos botones
                    value={true}
                    checked={approval === true}
                    onChange={() => setApproval(true)} // Cambiar a true
                  />
                  <label>Si</label>
                </div>
                <div className="ml-3 mt-2">
                  <input
                    type="radio"
                    name="approval" // Mismo name para ambos botones
                    value={false}
                    checked={approval === false}
                    onChange={() => setApproval(false)} // Cambiar a false
                  />
                  <label>No</label>
                </div>
              </div>
              { approval === false &&
                <InputGroup iconClass="far fa-clipboard" label="Motivo">
                  <textarea
                    value={descriptionNoApproval}
                    placeholder="Escribe el motivo de la no aprobación"
                    onChange={(e) => setDescriptionNoApproval(e.target.value)}
                    className="form-control"
                  />
                </InputGroup>
              }
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-link'
                onClick={onCancel}
              >
                Cancelar
              </button>
              <button
                className='btn btn-primary mr-1'
                type="submit"
                disabled={disabledSubmit}
              >
                Envíar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export { Approved }