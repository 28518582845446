import React from 'react'

const IndicatorSummary = ({indicator}) => {

  // const handleDuplicate = async (event) => {
  //   event.preventDefault();
  //   try {
  //     fetch(`/indicators/duplicate?indicator=${indicator.id}`, {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' }
  //     }).then((response) => { return response.json() })
  //     .then(window.location.href = '/indicators/list')
  //   } catch (error) {
  //     console.error('Error al duplicar:', error);
  //   }
  // }

  return (
    <div className='card-group'>
      <div className='card border-light border-1 mb-0'>
        <div className='card-body px-1 py-2'>
          <p className='mb-0'><strong>Objetivo</strong></p>
          <div className='card-text text-muted mb-2'>
            <p>{indicator.objective}</p>
          </div>
          <a className='btn btn-link' href={`/indicators/duplicate?indicator=${indicator.id}`}>Duplicar</a>
        </div>
      </div>

      <div className='card border-light border-1 mb-0'>
        <div className='card-body px-1 py-2'>
          <p className='mb-0'><strong>Fórmula</strong></p>
          <div className='card-text text-muted mb-2'>
            <p>{indicator.formula}</p>
          </div>
          <strong className='card-text text-muted mb-2'>{indicator.formula_type}</strong>
        </div>
      </div>

      <div className='card border-light border-1 mb-0'>
        <div className='card-body px-1 py-2'>
          <p className='mb-0'><strong>Periocidad</strong></p>
          <div className='card-text text-muted mb-2'>
            <p>{indicator.periodicity_name}</p>
          </div>
          <p className='mb-0'><strong>Tendencia Esperada</strong></p>
          <div className='card-text text-muted mb-2'>
            <p>{indicator.trend_direction}</p>
          </div>
          <p className='mb-0'><strong>Tipo de Medida</strong></p>
          <div className='card-text text-muted mb-2'>
            <p>{indicator.measure_type}</p>
          </div>
        </div>
      </div>

      <div className='card border-light border-1 mb-0'>
        <div className='card-body px-1 py-2'>
          <p className='mb-0'><strong>Responsables Indicador</strong></p>
          <div className='card-text text-muted mb-2'>
            <p>{indicator.responsible}</p>
          </div>
          <p className='mb-0'><strong>Responsables Medición</strong></p>
          <div className='card-text text-muted mb-2'>
            <p>{indicator.metrics_responsible}</p>
          </div>
        </div>
      </div>

      <div className='card border-light border-1 mb-0'>
        <div className='card-body px-1 py-2'>
          <p className='mb-0'><strong>Fuente de Información</strong></p>
          <div className='card-text text-muted mb-2'>
            <p>{indicator.source}</p>
          </div>
        </div>
      </div>

      <div className='card border-light border-1 mb-0'>
        <div className='card-body px-1 py-2'>
          <p className='mb-0'><strong>Instrucciones</strong></p>
          <div className='card-text text-muted mb-2'>
            <p>{indicator.instructions}</p>
          </div>
        </div>
      </div>

      <div className='card border-light border-1 mb-0'>
        <div className='card-body px-1 py-2'>
          <p className='mb-0'><strong>Atributo a mejorar</strong></p>
          <div className='card-text text-muted mb-2'>
            <p>{indicator.improve_attribute}</p>
          </div>
        </div>
        {indicator.measure?.ytd &&
          <div className='card-body px-1 py-2'>
            <p className='mb-0'><strong>YTD</strong></p>
            <div className='card-text text-muted mb-2'>
              <p>{indicator.measure?.ytd}</p>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
export default IndicatorSummary