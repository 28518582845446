import Rails from "@rails/ujs";
import "@ungap/with-resolvers";
import Turbolinks from "turbolinks";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import "bootstrap";
import "styles/application";

Rails.start();
Turbolinks.start();

const application = Application.start();
const context = require.context("../controllers", true, /\.js$/);
Notification.requestPermission(function (permission) {});
require("channels");
application.load(definitionsFromContext(context));
require.context("../images", true);
const componentRequireContext = require.context("../components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
