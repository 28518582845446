import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import {
  Form,
  Button,
  Row,
  Col,
  InputGroup,
  ButtonGroup,
  Navbar,
  Badge,
  Dropdown,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faBuilding,
  faCalendarDays,
  faFileInvoice,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parseISO } from "date-fns";
import es from "date-fns/locale/es";
import {
  FLASH_TYPE_ERROR,
  FLASH_TYPE_NOTICE,
  useFlash,
} from "./Context/FlashContext";
import Header from "./Header";
import LoaderPagination from "./Skeleton/LoaderPagination";
import Uploader from "./Uploader";
import { AppContext } from "./Context/AppContext";
import { FlashErrors } from "./Flash/FlashAlerts";
import errorFieldMap from "../utils/errorsTrouble.json";

const UpdateTroubles = () => {
  const [branchApi, setBranchApi] = useState([]);
  const [bossApi, setBossAbossApi] = useState([]);
  const [usersApi, setUsersApi] = useState([]);
  const [filter, setFilter] = useState("");
  const [filterBosses, setFilterBosses] = useState("");
  const [formData, setFormData] = useState({
    trouble_type: "",
    date_report: "",
    date_event: "",
    description: "",
    description_actions: "",
    report: "false",
    branch_office_id: "",
    inmediate_boss_id: "",
    employee_id: "",
    workflow_state: "",
    file: "",
    filter: "",
  });
  const { loading, setLoading } = useContext(AppContext);

  const { setFlash } = useFlash();

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    fetchBranchOffices();
  }, []);

  const fetchBranchOffices = async () => {
    try {
      const response = await axios.get("/api/v2/branch_offices");
      if (response.status === 200) {
        const data = await response.data;
        setBranchApi(data);
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBossApi();
  }, []);

  const fetchBossApi = async () => {
    try {
      const response = await axios.get("/api/v2/inmediaty_boss");
      if (response.status === 200) {
        const data = await response.data;
        setBossAbossApi(data);
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUsersApi();
  }, []);

  const fetchUsersApi = async () => {
    try {
      const response = await axios.get("/api/v2/users");
      if (response.status === 200) {
        const data = await response.data;
        setUsersApi(data);
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    fetchTroubleData();
  }, []);

  const fetchTroubleData = async () => {
    setLoading(true);

    try {
      const response = await axios.get(`/api/v2/troubles/${params.id}`);
      if (response.status === 200) {
        const data = await response.data;
        setFormData(data);
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      setFlash({
        type: FLASH_TYPE_ERROR,
        message: "Ocurrio un error en el servidor",
      });

      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileUpload = async (file) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      file: file,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const url = `/api/v2/troubles/update/${params.id}`;
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    try {
      const response = await axios.put(url, formData, {
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        setFlash({
          message: "Elemento Actualizado",
          type: FLASH_TYPE_NOTICE,
        });
        navigate("/troubles");
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      let responseError = error.response.data;

      let errorMessages = {};
      for (const field in responseError) {
        if (errorFieldMap[field]) {
          errorMessages[field] = `${errorFieldMap[field]}`;
        }
      }

      setFlash({
        type: FLASH_TYPE_ERROR,
        message: Object.entries(errorMessages)
          .map(([key, value]) => `${value}`)
          .join("\n"),
      });
    }
  };

  const fileSummary = formData.file_summary;
  const filteredUsers = usersApi.results
    ? usersApi.results.filter((user) =>
        user.name.toLowerCase().includes(filter.toLowerCase())
      )
    : [];

  const filteredBosses = bossApi.filter((boss) =>
    boss.name.toLowerCase().includes(filterBosses.toLowerCase())
  );

  return (
    <>
      <Header />
      <Navbar className="bg-light border border-secondary">
        <Link to={`/troubles/${params.troubleType}`}>
          <Button variant="light">
            <FontAwesomeIcon icon={faArrowLeft} color="#438dff" />
          </Button>
        </Link>
        <Navbar.Brand className=" d-flex " style={{ gap: "5px" }}>
          <span>Editar Reporte</span>
        </Navbar.Brand>
      </Navbar>
      {loading ? (
        <LoaderPagination />
      ) : (
        <Form onSubmit={handleSubmit} className="px-5 pt-2">
          <FlashErrors />
          <Form.Group controlId="troubleType">
            <Form.Label>Tipo de reporte:</Form.Label>

            <Row className="row px-2">
              <div className="px-2">
                <Form.Check
                  type="radio"
                  label={"Accidente"}
                  name="trouble_type"
                  value={"Accidente"}
                  checked={formData.trouble_type === "Accidente"}
                  onChange={handleInputChange}
                />
              </div>
              <div className="px-2">
                <Form.Check
                  type="radio"
                  label={"Emergencia"}
                  name="trouble_type"
                  value={"Emergencia"}
                  checked={formData.trouble_type === "Emergencia"}
                  onChange={handleInputChange}
                />
              </div>
              <div className="px-2">
                <Form.Check
                  type="radio"
                  label={"Incidente"}
                  name="trouble_type"
                  value={"Incidente"}
                  checked={formData.trouble_type === "Incidente"}
                  onChange={handleInputChange}
                />
              </div>
            </Row>
          </Form.Group>

          <Form.Group controlId="userId">
            <Form.Label>Persona que informa</Form.Label>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faUser} />
              </InputGroup.Text>
              <Dropdown style={{ width: "100%" }}>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  style={{
                    width: "100%",
                    backgroundColor: "transparent",
                    color: "black",
                    borderColor: "black",
                  }}
                >
                  {formData.employee_id
                    ? usersApi.results?.find(
                        (user) => user.id === formData.employee_id
                      )?.name
                    : "Selecciona un Usuario"}
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ width: "100%" }}>
                  <input
                    type="text"
                    placeholder="Filtrar usuarios"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    style={{ width: "100%" }}
                  />
                  {filteredUsers?.length > 0 ? (
                    filteredUsers?.map((user) => (
                      <Dropdown.Item
                        key={user.id}
                        onClick={() =>
                          handleInputChange({
                            target: { name: "employee_id", value: user.id },
                          })
                        }
                      >
                        {user.name}
                      </Dropdown.Item>
                    ))
                  ) : (
                    <div style={{ padding: "10px" }}>
                      <span>No hay resultados</span>
                    </div>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </InputGroup>
          </Form.Group>

          <Row className="px-3 d-flex justify-content-between">
            <Form.Group controlId="dateReport">
              <Form.Label>Fecha del reporte</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faCalendarDays} />
                </InputGroup.Text>

                <DatePicker
                  selected={
                    formData.date_report ? parseISO(formData.date_report) : null
                  }
                  onChange={(date) =>
                    handleInputChange({
                      target: {
                        name: "date_report",
                        value: date.toISOString(),
                      },
                    })
                  }
                  dateFormat="yyyy/MM/dd"
                  showYearDropdown
                  dropdownMode="select"
                  locale={es}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="dateEvent">
              <Form.Label>Fecha del evento</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faCalendarDays} />
                </InputGroup.Text>

                <DatePicker
                  selected={
                    formData.date_event ? parseISO(formData.date_event) : null
                  }
                  onChange={(date) =>
                    handleInputChange({
                      target: {
                        name: "date_event",
                        value: date.toISOString(),
                      },
                    })
                  }
                  dateFormat="yyyy/MM/dd"
                  showYearDropdown
                  dropdownMode="select"
                  locale={es}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="branchOfficeId">
              <Form.Label>Sede</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faBuilding} />
                </InputGroup.Text>
                <Form.Control
                  as="select"
                  name="branch_office_id"
                  value={formData.branch_office_id}
                  onChange={handleInputChange}
                >
                  <option value="">Selecciona una sede</option>
                  {branchApi?.map((office) => (
                    <option key={office.id} value={office.id}>
                      {office.name}
                    </option>
                  ))}
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Row>

          <Form.Group controlId="descriptionAction">
            <Form.Label>Descripción de lo ocurrido</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faFileInvoice} />
              </InputGroup.Text>
              <Form.Control
                as="textarea"
                rows={3}
                name="description_actions"
                value={formData.description_actions}
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>

          <Form.Group controlId="description">
            <Form.Label>
              Describa las acciones tomadas para atender el evento
            </Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faFileInvoice} />
              </InputGroup.Text>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>

          <Row className="justify-content-between align-items-center">
            <Col>
              <Form.Group controlId="report" className="d-flex ">
                <Form.Label>Reporto al jefe inmediato</Form.Label>
                <div className="px-2">
                  <Form.Check
                    type="radio"
                    label={"Si"}
                    name="report"
                    value={"true"}
                    checked={formData.report === "true"}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="px-2">
                  <Form.Check
                    type="radio"
                    label={"No"}
                    name="report"
                    value={"false"}
                    checked={formData.report === "false"}
                    onChange={handleInputChange}
                  />
                </div>
              </Form.Group>
            </Col>
            <Col>
              {formData.report === "true" && (
                <Form.Group
                  controlId="inmediateBossId"
                  className="d-flex align-items-center"
                >
                  <Form.Label style={{ width: "130px" }}>
                    Jefe inmediato
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUser} />
                    </InputGroup.Text>
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        style={{
                          backgroundColor: "transparent",
                          color: "black",
                          borderColor: "black",
                        }}
                      >
                        {bossApi &&
                        bossApi.length > 0 &&
                        formData.inmediate_boss_id &&
                        bossApi.find(
                          (boss) => boss.id === formData.inmediate_boss_id
                        )?.name
                          ? bossApi.find(
                              (boss) => boss.id === formData.inmediate_boss_id
                            ).name
                          : "Seleccione un jefe"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <input
                          type="text"
                          placeholder="Filtrar jefes"
                          value={filterBosses}
                          onChange={(e) => setFilterBosses(e.target.value)}
                          style={{ width: "100%" }}
                        />
                        {filteredBosses?.length > 0 ? (
                          filteredBosses.map((boss) => (
                            <Dropdown.Item
                              key={boss.id}
                              onClick={() =>
                                handleInputChange({
                                  target: {
                                    name: "inmediate_boss_id",
                                    value: boss.id,
                                  },
                                })
                              }
                            >
                              {boss.name}
                            </Dropdown.Item>
                          ))
                        ) : (
                          <div style={{ padding: "10px" }}>
                            <span>No hay resultados</span>
                          </div>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </InputGroup>
                </Form.Group>
              )}
            </Col>
          </Row>

          {fileSummary &&
          /\.(jpg|png|jpeg|gif|svg)$/i.test(fileSummary.name) ? (
            <Uploader
              onChange={handleFileUpload}
              value={formData.file_summary}
              id={params.id}
              url={fileSummary?.url}
            />
          ) : (
            <Uploader
              onChange={handleFileUpload}
              value={formData.file_summary}
              id={params.id}
              url={`/api/v2/troubles/controlled_copy.${params.id}`}
            />
          )}

          <ButtonGroup className="d-flex pt-5">
            <Link to="/troubles">
              <Button className="btn btn-primary mx-1" variant="primary">
                Cancelar
              </Button>
            </Link>

            <Button
              className="btn btn-primary mx-1"
              type="submit"
              variant="primary"
            >
              Actualizar
            </Button>
          </ButtonGroup>
        </Form>
      )}
    </>
  );
};

export default UpdateTroubles;
