import React from "react";

const PersistencyModal = () => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th className="align-middle text-center">
            PARÁMETROS DE CALIFICACIÓN CUALITATIVA
          </th>
          <th className="align-middle text-center"> CALIFICACIÓN </th>
          <th className="align-middle text-center"> GRADO DE SIGNIFICANCIA </th>
          <th className="align-middle text-center"> DESCRIPCIÓN </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="align-middle text-justify" rowSpan="3">
            PERSISTENCIA - (PE)
          </td>
          <td className="align-middle text-center">FUGAZ</td>
          <td className="align-middle text-center">1</td>
          <td className="align-middle text-justify">si dura menos de un año</td>
        </tr>
        <tr>
          <td className="align-middle text-center">TEMPORAL</td>
          <td className="align-middle text-center">2</td>
          <td className="align-middle text-justify">
            si dura entre 1 a 10 años
          </td>
        </tr>
        <tr>
          <td className="align-middle text-center">PERMANENTE</td>
          <td className="align-middle text-center">4</td>
          <td className="align-middle text-justify">
            si tiene una duración superior a 10 años
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PersistencyModal;
