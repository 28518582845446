import React from 'react'

const ProgressLine = (props) => {
  const {count, percentage, color, text} = props
  return (
    <div>
      <div className='text-right font-weight-semibold pr-3'>
        {text} {count}
      </div>
      <div className='progress mt-2' style={{height: '3px'}}>
        <div style={{width: `${percentage}%`, background: `${color}`}}>
        </div>
      </div>
    </div>
  )
}

export default ProgressLine