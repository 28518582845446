import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import GlobalContext from "../../context/GlobalContext";
import Day from "./Day"
import './Month.css'
import "bootstrap/dist/css/bootstrap.min.css";

export function getMonth(month = dayjs().month()) {
  month = Math.floor(month);
  const year = dayjs().year();
  const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
  let currentMonthCount = 0 - firstDayOfTheMonth;
  const daysMatrix = new Array(5).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      const currentDate = dayjs(new Date(year, month, currentMonthCount));
      currentMonthCount++;
      return currentDate.format("YYYY-MM-DD");
    });
  });
  return daysMatrix;
}

export default function Month({ events, flag, setFlag }) {
  const {
    selectedDate,
    setShowModal,
    setShowEditModal,
    selectedMonth,
    setSelectedMonth
  } = useContext(GlobalContext);
  const [currentMonth, setCurrentMonth] = useState(getMonth());
  const [matrix, setMatrix] = useState([]);

  useEffect(() => {
    setCurrentMonth(getMonth(selectedMonth));

    if (flag) {
      const eventsByDay = {};

      events.forEach((event, index) => {
        const startDate = dayjs(event.start_date);
        const endDate = dayjs(event.end_date);
        const startDay = startDate.format("YYYY-MM-DD");
        const endDay = endDate.format("YYYY-MM-DD");
        const month = startDate.month();


        if (month === selectedMonth || (month === selectedMonth - 1 && startDate.date() > 7)) {
          for (let currentDay = startDay; currentDay <= endDay; currentDay = dayjs(currentDay).add(1, 'day').format("YYYY-MM-DD")) {
            if (!eventsByDay[currentDay]) {
              eventsByDay[currentDay] = [];
            }
            eventsByDay[currentDay].push(event);
          }
        }
      });
      setMatrix(eventsByDay);
      setFlag(false);
    }
  }, [selectedMonth, flag, events]);

  function handleEvent(date) {
    const eventsOnDate = matrix[date];
    if (!eventsOnDate || eventsOnDate.length === 0) {
      setShowModal(true);
    }
  }

  return (
    <div className="row ">
      <div className="container-fluid wd-100 calendar-container">
        <div
        className="row aling-items-center">
          <div className="col-custom-day border">
            <p>Sabado</p>
          </div>
          <div className="col-custom-day border">
            <p>Domingo</p>
          </div>
          <div className="col-custom-day border">
            <p>Lunes</p>
          </div>
          <div className="col-custom-day border">
            <p>Martes</p>
          </div>
          <div className="col-custom-day border">
            <p>Miercoles</p>
          </div>
          <div className="col-custom-day border">
            <p>Jueves</p>
          </div>
          <div className="col-custom-day border">
            <p>Viernes</p>
          </div>
        </div>
        {currentMonth.map((row, rowIndex) => (
          <div key={rowIndex} className="row">
            {row.map((date, colIndex) => (
              <div
                key={colIndex}
                className="col-custom-month justify-content-center border cursor-pointer"
                onClick={() => handleEvent(date)}>
                 <Day events={matrix[date]} date={date} selectedDate={selectedDate}/>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
