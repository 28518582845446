import { Controller } from 'stimulus'
import Chart from 'chart.js'

export default class extends Controller {
  connect () {
    let { metrics, datas, semaphores } = this.element.dataset
    metrics = JSON.parse(metrics)
    datas = JSON.parse(datas)
    const labels = metrics
    const data = datas
    this.ctx = this.element.getContext('2d')

    this.options = {
      type: 'bar',
      responsive: true,
      maintainAspectRatio: false,
      data: {

        labels,
        datasets: [
          {
            label: 'Total',
            pointBorderWidth: 100,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: true,
            backgroundColor: '#009688',
            borderWidth: 2,
            data
          },
        ]

      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              fontColor: 'rgba(0,0,0,0.5)',
              fontStyle: 'bold',
              beginAtZero: true,
              maxTicksLimit: 10,

              max: Math.max.apply(null, data)+1,
              min: 0,
              padding: 20,
              thickness: 1,
              stepSize: 1
            },
            gridLines: {
              show: true
            },
          }],
          xAxes: [{
            gridLineWidth: 0
          }],
        }
      }

    }
    this.chart = new Chart(this.ctx, this.options)
  }
}
