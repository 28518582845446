import React from 'react'
import Chart from "./Chart"
const General = (props) => {
  const { percentage, color } = props
  return (
    <>
      <h2 className='cart-title text-center'>General</h2>
      <div className='my-3'>
        <Chart
          progress={percentage}
          size={80}
          strokeWidth={6}
          circleTwoStroke={color}
        />
      </div>
    </>
  )

}

export default General