import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()
    const url = this.data.get('url')
    const elementId = this.data.get('target')
    const auxId = this.data.get('aux')
    $.get(url + '?element_id=' + this.element.value + '&aux_id=' + auxId).then((content) => {
      document.getElementById(elementId).innerHTML = content
    })
  }
}
