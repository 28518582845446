import React, { useContext, useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./year.css";
import GlobalContext from "../../context/GlobalContext";

export default function Year({ events, flag, setFlag }) {
  const { selectedYear, setSelectedYear, selectedDate } = useContext(GlobalContext);
  const [eventDates, setEventDates] = useState([]);

  useEffect(() => {
    setEventDates(events.map(event => new Date(event.date).toLocaleDateString()));
  }, [events]);

  const handleCalendarChange = (date) => {
    setSelectedYear(date.getFullYear());
  };

  const markEventDays = (date) => {
    return events.some(event => {
      const eventDate = new Date(event.date).toLocaleDateString();
      return eventDate === date.toLocaleDateString();
    });
  };

  return (
    <div className="year-calendar">
      {Array.from({ length: 12 }, (_, monthIndex) => {
        const monthDate = new Date(selectedYear, monthIndex, 1);
        const isSelectedMonth = selectedDate instanceof Date && selectedDate.getMonth() === monthIndex;

        return (
          <div className="calendar-cell" key={monthIndex}>
            <Calendar
              value={isSelectedMonth ? selectedDate : null}
              onChange={handleCalendarChange}
              defaultView="month"
              activeStartDate={monthDate}
              tileClassName={({ date }) => markEventDays(date) ? 'event-marker' : null}
            />
          </div>
        );
      })}
    </div>
  );
}
