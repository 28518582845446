import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['select']

  connect () {
    var category = this.data.get('name')
    var present = this.data.get('user')
    var elements = document.getElementsByClassName(category)
    if (elements.length > 0) {
      for (var x = 0; x < elements.length; x++) {
        if (elements[x].name !== this.selectTarget.name) {
          for (var i = 0; i < this.selectTarget.length; i++) {
            if (this.selectTarget.options[i].value === elements[x].value) {
              if (present === 'nil' || elements[x].value !== present) {
                this.selectTarget.remove(i)
              }
            }
          }
        }
      }
    }

    this.onChange()
  }

  onChange () {
    var arroptions = []
    var index = 0
    $(this.selectTarget.options[this.selectTarget.selectedIndex]).attr('selected', 'selected')
    for (var i = 0; i < this.selectTarget.length; i++) {
      if (this.selectTarget.selectedIndex != i) { $(this.selectTarget.options[i]).attr('selected', false) }
      if (this.selectTarget.options[i].value != this.selectTarget.value) {
        arroptions[index] = this.selectTarget.options[i]
        index = index + 1
      }
    }

    var category = this.data.get('name')
    var elements = document.getElementsByClassName(category)
    var flag

    if (elements.length > 0) {
      for (var x = 0; x < elements.length; x++) {
        if (elements[x].value != this.selectTarget.value) {
          for (var i = 0; i < elements[x].length; i++) {
            if (elements[x].options[i].value === this.selectTarget.value) {
              elements[x].remove(i)
            }
          }
        }
      }
    }

    if (elements.length > 1) {
      for (var x = 0; x < elements.length; x++) {
        for (var y = 0; y < arroptions.length; y++) {
          flag = 'false'
          for (var w = 0; w < elements[x].options.length; w++) {
            if (arroptions[y].value == elements[x].options[w].value) { flag = 'true' }
          }
          if (flag == 'false') {
            var opt1 = document.createElement('option')
            opt1.value = arroptions[y].value
            opt1.text = arroptions[y].text
            elements[x].add(opt1, null)
          }
        }
      }
    }
  }

  onDelete () {
    var selected = $(this.selectTarget).children('option:selected').val()
    var text = $(this.selectTarget).children('option:selected').text()
    var category = this.data.get('name')
    this.selectTarget.classList.remove(category)
    var elements = document.getElementsByClassName(category)

    if (elements.length > 0) {
      for (var x = 0; x < elements.length; x++) {
        var opt1 = document.createElement('option')
        opt1.value = selected
        opt1.text = text
        elements[x].add(opt1, null)
      }
    }
  }
}
