import { Controller } from 'stimulus'
import Chart from 'chart.js'

export default class extends Controller {
  connect () {
    let { points } = this.element.dataset

    points = JSON.parse(points)

    const labels = points.map(({ chapter }) => chapter)
    const data = points.map(({ value }) => value)
    const colors = points.map(({ color }) => color)

    this.ctx = this.element.getContext('2d')
    this.options = {
      type: 'radar',
      data: {
        labels,
        datasets: [
          {
            label: 'Dominio',
            pointBorderWidth: 5,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: true,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            pointBackgroundColor: colors,
            pointBorderColor: colors,
            borderColor: colors,
            borderWidth: 2,
            data
          },
        ]
      },
      options: {
        animation: {
          easing: 'easeInOutBack'
        }
      }
    }
    this.chart = new Chart(this.ctx, this.options)
  }
}
