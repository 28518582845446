import React from 'react'

const WorkflowButton = ({workflowPrompts}) => {
  return (
    <>
      {workflowPrompts.return_condition && (
        <label className='btn btn-danger mx-4 cursor-pointer' htmlFor='returnAssignments' tabIndex='0' >Devolver</label>
      )}
      {workflowPrompts.submit_condition && (
        <label className='btn btn-primary' htmlFor='sendAssignments' tabIndex='0' >Enviar</label>
      )}
      {workflowPrompts.reviewed_condition && (
        <label className='btn btn-success cursor-pointer' htmlFor="reviewAssignments" tabIndex='0'> Revisado</label>
      )}
      {workflowPrompts.approve_condition && (
        <label className='btn btn-success cursor-pointer mx-4' htmlFor="approveAssignments" tabIndex='0'>Aprobar</label>
      )}
    </>
  )
}

export default WorkflowButton