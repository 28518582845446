import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  
  connect () {
  }

  onChangeform1 (e) {
    var select = document.querySelector('input[name="botontype"]:checked').value;
    var container = document.getElementById("renderform");
    var filePath = 'ordinary_wastes/form1?type=' + select;
    fetch(filePath)
        .then(response => response.text())
        .then(htmlContent => {
              container.innerHTML = htmlContent;
        })
        .catch(error => {
            console.error('Error al cargar el archivo:', error);
        });
   }

  onChangeform2 (e) {
    var container = document.getElementById("renderform");
    var filePath2 = 'ordinary_wastes/form2';

    fetch(filePath2)
      .then(response => response.text())
      .then(htmlContent => {
            container.innerHTML = htmlContent;
      })
      .catch(error => {
          console.error('Error al cargar el archivo:', error);
      });
  }
}
