import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['image', 'crosshair', 'mipgModal', 'menuModal', 'select', 'modalTitle']

  connect () {
    if (this.data.get('mode') !== 'edit') {
      return
    }

    const { x, y } = this.imageTarget.getBoundingClientRect()
    this.posX = x
    this.posY = y

    window.requestAnimationFrame(() => {
      $(this.crosshairTarget).css({
        '-webkit-transform': `translateX(${this.posX - 24}px) translateY(${this.posY - 24} px)`,
        transform: `translateX(${this.posX - 24}px) translateY(${this.posY - 24}px)`
      })
    })
  }

  onClick (e) {
    e.preventDefault()

    if (this.data.get('mode') === 'edit') {
      const { x, y } = this.imageTarget.getBoundingClientRect()
      this.posX = e.pageX - x
      this.posY = e.pageY - y
      $(this.mipgModalTarget).modal('show')
    } else {
      const { title, code } = e.target.dataset
      $('#ulist').empty()
      $(this.menuModalTarget).modal('show')
      $(this.modalTitleTarget).html(title)
      switch (code) {
        case '1':
          var button = '<a href="/employees" class="btn btn-link" target="_blank">' + title
          $('#ulist').append(button)
          break
        case '2':
          button = '<a href="/portals/strategic_planning" class="btn btn-link" target="_blank">' + title
          $('#ulist').append(button)
          break
        case '3':
          button = '<a href="/portals/strategic_planning" class="btn btn-link" target="_blank">' + title
          var buttontwo = '<a href="/documents" class="btn btn-link" target="_blank"> Documentos'
          var buttonthree = '<a href="/pqrsfs" class="btn btn-link" target="_blank"> PQRSF'
          $('#ulist').append(button)
          $('#ulist').append(buttontwo)
          $('#ulist').append(buttonthree)
          break
        case '4':
          button = '<a href="/new_metrics" class="btn btn-link" target="_blank"> Cuadro de Mando'
          $('#ulist').append(button)
          break
        case '5':
          button = '<a href="/documents" class="btn btn-link" target="_blank"> Documentos'
          $('#ulist').append(button)
          break
        case '6':
          button = '<a href="/documents" class="btn btn-link" target="_blank"> Documentos'
          $('#ulist').append(button)
          break
        case '7':
          button = '<a href="/audit_programs" class="btn btn-link" target="_blank"> Auditoría'
          buttontwo = '<a href="/new_metrics" class="btn btn-link" target="_blank"> Cuadro de Mando'
          $('#ulist').append(button)
          $('#ulist').append(buttontwo)
          break
      }
    }
  }

  onSave () {
    $(this.mipgModalTarget).modal('hide')
    $.post(`/mipgs/update_coords`, {
      select: this.selectTarget.value,
      x: this.posX,
      y: this.posY,
      mode: 'edit'
    })
  }

  onMove (e) {
    if (this.data.get('mode') !== 'edit') {
      return
    }

    const { x, y } = this.imageTarget.getBoundingClientRect()

    this.posX = e.pageX - x
    this.posY = e.pageY - y

    window.requestAnimationFrame(() => {
      $(this.crosshairTarget).css({
        '-webkit-transform': `translateX(${this.posX - 24}px) translateY(${this.posY - 24} px)`,
        transform: `translateX(${this.posX - 24}px) translateY(${this.posY - 24}px)`
      })
    })
  }
}
