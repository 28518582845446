import { Controller }  from 'stimulus'

export default class extends Controller {
  static targets = ['normable',
  'normableId',
  'requirementiso',
  'requirementHealth',
  'requirement14',
  'requirement45',
  'requirementsg',
  'Iso',
  'Iso10012',
  'HealthAssessment',
  'Iso14001Assessment',
  'Iso45001Assessment',
  'filefield',
  'button',
  'SgsstQuestion']
  connect() {
    this.filefieldTarget.style.display = 'none';
    this.onChange()
  }

  onChange(e) {
    this.IsoTarget.style.display = 'none';
    this.Iso10012Target.style.display = 'none';
    this.HealthAssessmentTarget.style.display = 'none';
    this.Iso14001AssessmentTarget.style.display = 'none';
    this.Iso45001AssessmentTarget.style.display = 'none';
    this.SgsstQuestionTarget.style.display = 'none';
    const value = this.normableTarget.value
    switch (value) {
      case 'ISO9001':
        this.IsoTarget.style.display = 'block';
        break;
      case 'Iso10012':
        this.Iso10012Target.style.display = 'block';
        break;
      case 'HealthAssessment':
        this.HealthAssessmentTarget.style.display = 'block';
        break;
      case 'Iso14001Assessment':
        this.Iso14001AssessmentTarget.style.display = 'block';
        break;
      case 'Iso45001Assessment':
        this.Iso45001AssessmentTarget.style.display = 'block';
        break;
      case 'SgsstQuestion':
        this.SgsstQuestionTarget.style.display = 'block';
        break;
    }
  }

  onRequirementone(){
    $(this.normableIdTarget).prop('value', this.requirementisoTarget.value)
  }
  onRequirementtwo(){
    $(this.normableIdTarget).prop('value', this.requirementHealthTarget.value)
  }
  onRequirementthree(){
    $(this.normableIdTarget).prop('value', this.requirement14Target.value)
  }
  onRequirementfour(){
    $(this.normableIdTarget).prop('value', this.requirement45Target.value)
  }
  onRequirementfive(){
    $(this.normableIdTarget).prop('value', this.requirementsgTarget.value)
  }
  onRequirementsix(){
    $(this.normableIdTarget).prop('value', this.requirementsgTarget.value)
  }

  seeFile(){
    if (this.filefieldTarget.style.display == 'block')
    {this.filefieldTarget.style.display = 'none';}
    else {this.filefieldTarget.style.display = 'block';}
  }

  normModal(e)
  {
    if (e) e.preventDefault();
    const url =  this.buttonTarget.getAttribute('data-url')
    const normable = this.normableTarget.value

    switch (normable) {
      case 'ISO9001':
        document.getElementById('infoModalLabel').innerHTML = 'ISO 9001'
        break;
      case 'Iso10012':
        document.getElementById('infoModalLabel').innerHTML = 'NTC-ISO 10012'
        break;
      case 'HealthAssessment':
        document.getElementById('infoModalLabel').innerHTML = 'Habilitación en Salud'
        break;
      case 'Iso14001Assessment':
        document.getElementById('infoModalLabel').innerHTML = 'ISO 14001'
        break;
      case 'Iso45001Assessment':
        document.getElementById('infoModalLabel').innerHTML = 'ISO 45001'
        break;
      case 'SgsstQuestion':
        document.getElementById('infoModalLabel').innerHTML = 'SGSST'
        break;
    }

    $('#infoModal').modal('show');
    const elementId = this.buttonTarget.getAttribute('data-remote-norm-target')
    $.get(url+'?norm_id='+this.normableIdTarget.value+'&norm_type='+normable).then((content) => {
      document.getElementById(elementId).innerHTML = content
    })
    $('#infoModal').on('click', function(event) {
      event.stopPropagation();
    })
  }

}
