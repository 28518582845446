import React, { useContext, useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Button,
  Container,
  OverlayTrigger,
  Tooltip,
  Badge,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import {
  faArrowLeft,
  faTrash,
  faPenToSquare,
  faFile,
  faArrowUpWideShort,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Header from "./Header";
import Table from "./Table";
import LoaderTable from "./Skeleton/LoaderTable";
import { AppContext } from "./Context/AppContext";
import { FLASH_TYPE_ERROR, useFlash } from "./Context/FlashContext";
import formatDateTimeAll from "../utils/FormatDateTimeAll";
const Investigation = () => {
  const [investigations, setInvestigations] = useState([]);
  const [infoPageInvestigation, setInfoPageInvestigation] = useState({});
  const { setLoading } = useContext(AppContext);
  const { setFlash } = useFlash();
  const { troubleId } = useParams();
  const [searchParams] = useSearchParams();

  const fetchInvestigations = async (page, troubleId) => {
    setLoading(true);
    try {
      const url = `/api/v2/troubles/${troubleId}/investigations`;
      const searchParams = new URLSearchParams(location.search);
      if (page) {
        searchParams.set("page", page || 1);
      }
      const response = await axios.get(`${url}?${searchParams.toString()}`);
      const data = await response.data;
      setInvestigations(data.investigations);
      setInfoPageInvestigation(data.total_pages);
    } catch (error) {
      setFlash({
        type: FLASH_TYPE_ERROR,
        message: "Ocurrio un error en el servidor",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvestigations(searchParams.get("page") || 1, troubleId);
  }, [searchParams]);

  const deleteInvestigation = async (id) => {
    try {
      const url = `/api/v2/troubles/${troubleId}/investigations/${id}`;
      const response = await axios.delete(url);
      if (response.status === 204) {
        setFlash({
          type: FLASH_TYPE_ERROR,
          message: "Elemento Eliminado",
        });
        fetchInvestigations(searchParams.get("page") || 1, troubleId);
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      setFlash({
        type: FLASH_TYPE_ERROR,
        message: "Ocurrio un error en el servidor",
      });

      console.error("Error", error);
    }
  };

  const getTypeValue = investigations.map((item) => item.troubles.trouble_type);
  const onlyValue = getTypeValue[0];

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const value = params.get("troubleType");

  return (
    <>
      <Header
        title={
          "Investigación de accidentes, incidentes o emergencias ambientales"
        }
        iconBack={
          <Link to={`/troubles/${onlyValue || value}`} className="pr-3">
            <Button variant="bg-dark">
              <FontAwesomeIcon icon={faArrowLeft} color="#438dff" />
            </Button>
          </Link>
        }
        buttonNewElement={
          <Link
            to={`/troubles/${troubleId}/investigations/new`}
            className="position-absolute"
            style={{ right: "21px", top: "9px" }}
          >
            <Button>Nuevo</Button>
          </Link>
        }
      />
      <Table
        numPages={infoPageInvestigation}
        items={investigations.length}
        heads={
          <tr>
            <th></th>
            <th className="text-center">Encargado</th>
            <th className="text-center">Descripción</th>
            <th className="text-center">Fecha</th>
          </tr>
        }
        loaderSize={
          <>
            {Array.from({ length: 3 }).map((_, index) => (
              <tr key={index}>
                <td style={{ minWidth: "341px" }}>
                  <LoaderTable />
                </td>
                <td style={{ minWidth: "260px" }}>
                  <LoaderTable />
                </td>
                <td style={{ minWidth: "234px" }}>
                  <LoaderTable />
                </td>
                <td
                  style={{
                    minWidth: "260px",
                  }}
                >
                  <LoaderTable />
                </td>
              </tr>
            ))}
          </>
        }
        data={
          <>
            {investigations.map((item) => (
              <tr key={item.id}>
                <td style={{ width: "225px" }}>
                  <Container className="d-flex">
                    <Link
                      to={`/troubles/${troubleId}/investigations/${item.id}`}
                    >
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Editar</Tooltip>}
                      >
                        <Button
                          variant="outline-success"
                          className="m-2"
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </Button>
                      </OverlayTrigger>
                    </Link>
                    <Link
                      to={`/troubles/${troubleId}/investigation/${item.id}/actions`}
                    >
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Plan de acción</Tooltip>}
                      >
                        <Button
                          variant="outline-dark"
                          className="m-2"
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faArrowUpWideShort} />
                        </Button>
                      </OverlayTrigger>
                    </Link>

                    {item.file_summary &&
                    /\.(jpg|png|jpeg|gif|svg)$/i.test(
                      item.file_summary.name
                    ) ? (
                      <Link to={item.file_summary.url} target="_blank">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Archivos adjuntos</Tooltip>}
                        >
                          <Button
                            variant="outline-primary"
                            className="m-2"
                            disabled={item.file_summary ? false : true}
                            size="sm"
                          >
                            <FontAwesomeIcon icon={faFile} />
                          </Button>
                        </OverlayTrigger>
                      </Link>
                    ) : (
                      <Link
                        to={`/api/v2/troubles/${troubleId}/investigations/controlled_copy_investigations.${item.id}`}
                        target="_blank"
                      >
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Archivos adjuntos</Tooltip>}
                        >
                          <Button
                            variant="outline-primary"
                            className="m-2"
                            disabled={item.file_summary ? false : true}
                            size="sm"
                          >
                            <FontAwesomeIcon icon={faFile} />
                          </Button>
                        </OverlayTrigger>
                      </Link>
                    )}

                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Eliminar</Tooltip>}
                    >
                      <Button
                        variant="outline-danger"
                        className="m-2"
                        onClick={() => deleteInvestigation(item.id)}
                        size="sm"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </OverlayTrigger>
                  </Container>
                </td>
                <td>
                  {item.assignments.map((assignment) =>
                    assignment.action === "produce" && assignment.user ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            <span className=" d-flex  flex-column ">
                              {assignment.user.first_name}{" "}
                              {assignment.user.last_name}
                            </span>
                          </Tooltip>
                        }
                        key={assignment.id}
                      >
                        <div className="d-flex justify-content-center mb-2 ">
                          <FontAwesomeIcon icon={faUser} color="#007BFF" />
                        </div>
                      </OverlayTrigger>
                    ) : null
                  )}
                </td>
                <td
                  style={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item.location_description && item.location_description}
                </td>
                <td style={{ textAlign: "center" }}>
                  {formatDateTimeAll(item.created_at)}
                </td>
              </tr>
            ))}
          </>
        }
      />
    </>
  );
};

export default Investigation;
