import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

const ExcelReact = ({ id }) => {
  const url = `/api/v2/troubles/export/${id}.xlsx`;

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>Descargar Excel</Tooltip>}
      >
        <Button
          variant="outline-success"
          className="m-2"
          href={url}
          target="_blank"
          size="sm"
        >
          <FontAwesomeIcon icon={faFileExcel} />
        </Button>
      </OverlayTrigger>
    </>
  );
};

export default ExcelReact;
