import { Controller } from 'stimulus'
import Chart from 'chart.js'

export default class extends Controller {
  connect () {
    var ctx = document.getElementById("myPieChart").getContext('2d');
    var ctxtwo = document.getElementById("myPieChart2").getContext('2d');
    var awaitingReview = parseInt(ctx.canvas.dataset.awaitingReview, 10);
    var awaitingApprove = parseInt(ctx.canvas.dataset.awaitingApprove, 10);
    var approved = parseInt(ctx.canvas.dataset.approved, 10);
    var nill = parseInt(ctx.canvas.dataset.nill, 10);
    var draft = parseInt(ctx.canvas.dataset.draft, 10);
    var returned = parseInt(ctx.canvas.dataset.returned, 10);
    var totalInspections = parseInt(ctx.canvas.dataset.total, 10);

    var awaitingReviewPercentage = ((awaitingReview / totalInspections) * 100).toFixed(2);
    var awaitingApprovePercentage = ((awaitingApprove / totalInspections) * 100).toFixed(2);
    var approvedPercentage = ((approved / totalInspections) * 100).toFixed(2);
    var nillPorcentage = ((nill / totalInspections) * 100).toFixed(2);
    var draftPercentage = ((draft / totalInspections) * 100).toFixed(2);
    var returnedPercentage = ((returned / totalInspections) * 100).toFixed(2);


    var process = parseInt(ctxtwo.canvas.dataset.process, 10);
    var effective = parseInt(ctxtwo.canvas.dataset.effective, 10);
    var ineffective = parseInt(ctxtwo.canvas.dataset.ineffective, 10);
    var totalActions = parseInt(ctxtwo.canvas.dataset.totalActions, 10);

    var processPercentage = ((process / totalActions) * 100).toFixed(2);
    var effectivePercentage = ((effective / totalActions) * 100).toFixed(2);
    var ineffectivePercentage = ((ineffective / totalActions) * 100).toFixed(2);

    var myPieChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: [
          `Esperando revisión (${awaitingReviewPercentage}%)`, 
          `Esperando aprobación (${awaitingApprovePercentage}%)`, 
          `Aprobadas (${approvedPercentage}%)`,
          `Sin evaluacion (${nillPorcentage}%)`,
          `Borrador (${draftPercentage}%)`,
          `Retornadas (${returnedPercentage}%)`,
        ],
        datasets: [{
          data: [awaitingReview, awaitingApprove, approved, nill, draft, returned],
          backgroundColor: ['#28A745', '#FFC107', '#007BFF', '#A6A6A6', '#FF9907', '#FB0001' ],
          hoverBackgroundColor: ['#006b17', '#d8a200', '#0653a5', '#0653a5', '#B67F07', '#820001'],
          hoverBorderColor: "rgba(234, 236, 244, 1)",
        }],
      },
      options: {
        maintainAspectRatio: false,
        tooltips: {
          backgroundColor: "rgb(255,255,255)",
          bodyFontColor: "#858796",
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          caretPadding: 10,
        },
        legend: {
          display: true,
          onClick: null // Disable legend click events
        },
        animation: {
          duration: 0 // Disable animation
        },
        cutoutPercentage: 80,
      },
    });

    var myPieChart2 = new Chart(ctxtwo,{
      type: 'doughnut',
      data:{
        labels:[
          `En proceso (${processPercentage}%)`,
          `Eficaz (${effectivePercentage}%)`,
          `Ineficaz (${ineffectivePercentage}%)`,
        ],
        datasets:[{
          data:[process, effective, ineffective],
          backgroundColor:['#FFC107','#17D95D','#D21B59'],
          hoverBackgroundColor:['#FF7D08','#58A816','#91003D'],
          hoverBorderColor: "rgba(234, 236, 244, 1)",
        }],
      },
      options: {
        maintainAspectRatio: false,
        tooltips: {
          backgroundColor: "rgb(255,255,255)",
          bodyFontColor: "#858796",
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          caretPadding: 10,
        },
        legend: {
          display: true,
          onClick: null // Disable legend click events
        },
        animation: {
          duration: 0 // Disable animation
        },
        cutoutPercentage: 80,
      },
    })
  }
}