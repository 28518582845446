import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['button','buttonone', 'buttontwo', 'buttonthree', 'buttonfour',
    'span', 'chapter', 'subchapter', 'article', 'paragraph_truncate', 'paragraph_complete', 'less_button', 'more_button']

  connect () {
    var chapter = document.getElementsByClassName("chapter");
    
    for (var x = 0; x < chapter.length; x++) {
      document.getElementById("spanchapter"+chapter[x].id).textContent = chapter[x].value
      var subchapters = document.getElementsByClassName("subchapter"+chapter[x].id);
      for (var y = 0; y < subchapters.length; y++) {
        document.getElementById("span"+subchapters[y].id).textContent = subchapters[y].value
        var articles = document.getElementsByClassName("article"+subchapters[y].id.slice(18));
        for (var i = 0; i < articles.length; i++) {
          document.getElementById("span"+articles[i].id).textContent = articles[i].value
        }
      }
    }
    this.checkValues()
  }

  checkValues(){
    var selected = this.buttonTarget.value
    if (selected == 1) {$(this.buttononeTarget).prop('className', 'btn btn-outline-danger btn-sm mr-1')}
    else if (selected == 2) {$(this.buttontwoTarget).prop('className', 'btn btn-outline-warning btn-sm mr-1')}
    else if (selected == 3) {$(this.buttonthreeTarget).prop('className', 'btn btn-outline-info btn-sm mr-1')}
  }

  save()
  {
    // const elem = document.getElementById('assessmentform') // or $('#myform')[0] with jQuery
    // Rails.fire(elem, 'submit');
  }

  resetClass()
  {
    $(this.buttononeTarget).prop('className', 'btn btn-danger btn-sm mr-1')
    $(this.buttontwoTarget).prop('className', 'btn btn-warning btn-sm mr-1')
    $(this.buttonthreeTarget).prop('className', 'btn btn-info btn-sm mr-1')
  }

  onAssigne1 (e) {
    if (e) e.preventDefault()
    this.resetClass()
    $(this.buttononeTarget).prop('className', 'btn btn-outline-danger btn-sm mr-1')
    this.buttonTarget.value = '0'
    this.onChange(this.buttonTarget.id)
    this.save()
  }
  onAssigne2 (e) {
    if (e) e.preventDefault()
    this.resetClass()
    $(this.buttontwoTarget).prop('className', 'btn btn-outline-warning btn-sm mr-1')
    this.buttonTarget.value = '0.5'
    this.onChange(this.buttonTarget.id)
    this.save()
  }
  onAssigne3 (e) {
    if (e) e.preventDefault()
    this.resetClass()
    $(this.buttonthreeTarget).prop('className', 'btn btn-outline-info btn-sm mr-1')
    this.buttonTarget.value = '1'
    this.onChange(this.buttonTarget.id)
    this.save()
  }
  onAssigne5 (e) {
    if (e) e.preventDefault()
    this.resetClass()
    this.buttonTarget.value = '-1'
    this.onChange(this.buttonTarget.id)
    this.save()
  }

  seeMore (){
    $(this.more_buttonTarget).prop('textContent', this.paragraph_completeTarget.innerText)
    $(this.more_buttonTarget).attr('data-action', 'click->assessment-decress#seeLess')
  }

  seeLess (){
    $(this.more_buttonTarget).prop('textContent', this.paragraph_completeTarget.innerText.slice(0, 77) + '...')
    $(this.more_buttonTarget).attr('data-action', 'click->assessment-decress#seeMore')
  }

  onChange (id) {
    var number = document.getElementsByClassName(id);
    var sum = 0;
    var nils = 0;
    for (var x = 0; x < number.length; x++) {
      var value = number[x].value
      if (value > -1)
      { sum = Number(sum) + (Number(value) * (Number(number[x].dataset.percentage)))  }
      else
      { sum = Number(sum) + ((Number(number[x].dataset.percentage))) }
    }
    var prom = Number(sum)
    document.getElementById("article"+id).textContent = prom.toFixed(2);
    document.getElementById("spanarticle"+id).textContent = prom.toFixed(2);
    document.getElementById("article"+id).value = prom.toFixed(2);
    this.Articles(document.getElementById("article"+id).className.split(" ")[1])
  }

  Articles (subchapter) {
    var sub = document.getElementsByClassName("subchapterquestion"+subchapter.slice(7))[0];
    var articles = document.getElementsByClassName("article"+subchapter.slice(7));
    var sum = 0;
    for (var y = 0; y < articles.length; y++) {
      var value = articles[y].value
      if (value > -1)
      { 
        sum = Number(sum) + (Number(value)) }
      else
      { 
        sum = Number(sum) + (Number(articles[y].dataset.percentage)) }
    }
    var prom = Number(sum)
    if (isNaN(prom))
    { prom = 0 }
    sub.value = prom.toFixed(2);
    document.getElementById("spansubchapterquestion"+subchapter.slice(7)).textContent = prom.toFixed(2);

    this.Chapter()
  }

  Chapter () {
    var chapter = document.getElementsByClassName("chapter");
    var total = 0;
    for (var x = 0; x < chapter.length; x++) {
      var subchapters = document.getElementsByClassName("subchapter"+chapter[x].id);
      var sum = 0;
      var nils = 0;
      for (var y = 0; y < subchapters.length; y++) {
        var value = subchapters[y].value
        if (value > -1)
        {
          sum = Number(sum) + (Number(value))
        }
        else
        { nils = sum = Number(sum) + (Number(subchapters[y].dataset.percentage)) }
      }
      total = Number(total) + Number(sum)
      var prom = Number(sum)
      if (isNaN(prom))
      { prom = 0 }
      document.getElementById(chapter[x].id).value = prom.toFixed(2);
      document.getElementById("spanchapter"+chapter[x].id).textContent = prom.toFixed(2);
    }
    document.getElementById("total_label").innerText = 'Calificación Final: ' + total;
    document.getElementById("total_field").value = total;
  }
}
