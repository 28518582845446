import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  static targets = ['percentage', 'optioninprogress', 'optioncompleted', 'filefield']

  connect () {
    this.selectName = this.optioninprogressTarget.name
    this.onChange()
    this.colorSelect()
  }

  onChange (e) {
    if (e) e.preventDefault()
    const { value } = this.percentageTarget
    if (Number(value) < 100) {
      this.optioninprogressTarget.style.display = 'block'
      this.optioninprogressTarget.style.color = 'white'
      this.optioncompletedTarget.style.display = 'none'

      this.optioninprogressTarget.name = this.selectName
      this.optioncompletedTarget.name = ''
    } else {
      this.optioncompletedTarget.selectedIndex = 2
      this.optioninprogressTarget.style.display = 'none'
      this.optioncompletedTarget.style.color = 'white'
      this.optioncompletedTarget.style.display = 'block'
      this.optioninprogressTarget.name = ''
      this.optioncompletedTarget.name = this.selectName
    }
    this.colorSelect()
  }

  colorSelect (e) {
    const { value } = this.percentageTarget
    if (Number(value) < 100) {
      var select = this.optioninprogressTarget.value
      if (select == 'En proceso') {
        $(this.optioninprogressTarget).css('background-color', '#F1B80A')
      } else if (select == 'No realizado') {
        $(this.optioninprogressTarget).css('background-color', '#E91E63')
      }
    } else {
      var selectTwo = this.optioncompletedTarget.value
      if (selectTwo == 'En proceso') {
        $(this.optioncompletedTarget).css('background-color', '#F1B80A')
      } else if (selectTwo == 'No realizado') {
        $(this.optioncompletedTarget).css('background-color', '#E91E63')
      } else if (selectTwo == 'Realizado') {
        $(this.optioncompletedTarget).css('background-color', '#009688')
      }
    }
  }
}
