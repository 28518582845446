import React, { useState, useEffect } from "react";
import AssignmentPanel from "./AuditReportsPage/AssignmentPanel";
import Findings from "./AuditReportsPage/Findings";
import FirstTable from "./AuditReportsPage/FirstTable";
import GeneralReport from "./AuditReportsPage/GeneralReport";
import IndividualReports from "./AuditReportsPage/IndividualReports";
import NavBar from "./AuditReportsPage/NavBar";
import Modal from "./AuditReportsPage/Modal";

const AuditReports = (props) => {
  const [auditProgram, setAuditProgram] = useState({});
  const [auditProgramReport, setAuditProgramReport] = useState({});
  const [procedureAudits, setProcedureAudits] = useState([]);
  const [procedureAuditsId, setProcedureAuditsId] = useState([]);
  const [vectorProcedureAuditId, setVectorProcedureAuditId] = useState([]);
  const [checklists, setChecklists] = useState([]);
  const [checklistsId, setChecklistsId] = useState([]);
  const [vectorChecklist, setVectorChecklist] = useState([]);
  const [auditNorms, setAuditNorms] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [normsIso9001, setNormsIso9001] = useState([]);
  const [normsHealth, setNormsHealth] = useState([]);
  const [normsIso14001, setnormsIso14001] = useState([]);
  const [normsIso45001, setNormsIso45001] = useState([]);
  const [normsSgsst, setnormsSgsst] = useState([]);
  const [prompts, setPrompts] = useState([]);
  const [workflowPrompts, setWorkflowPrompts] = useState([]);
  const [modalFlag, setModalFlag] = useState(0);
  const [modal, ShowModal] = useState(false);
  const [modaltitle, setModalTitle] = useState("");
  const [auditNormId, setAuditNormId] = useState("");
  const [auditReportId, setAuditReportId] = useState("");
  const [auditProgramReportId, setAuditProgramReportId] = useState("");
  const [uplbarranquilla, setUplBarranquilla] = useState([])

  const modalFindings = (modal, modaltitle, auditNormId) => {
    ShowModal(modal);
    setAuditNormId(auditNormId);
    setModalTitle(modaltitle);
  };

  const modalIndividualReports = (modal, modaltitle, auditReportId) => {
    ShowModal(modal);
    setAuditReportId(auditReportId);
    setModalTitle(modaltitle);
  };

  const modalGeneralReport = (modal, modaltitle, auditProgramReportId) => {
    ShowModal(modal);
    setAuditProgramReportId(auditProgramReportId);
    setModalTitle(modaltitle);
  };

  useEffect(() => {
    fetch("/api/v2/audit_programs/" + props.audit_program_id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setAuditProgram(data);
      }),
      fetch(
        "/api/v2/audit_programs/" +
          props.audit_program_id +
          "/audit_program_reports/" +
          props.audit_general_report_id
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setAuditProgramReport(data);
        }),
      fetch(
        "/api/v2/procedure_audits/?audit_program_id=" + props.audit_program_id
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setProcedureAudits(data);
        }),
      fetch("/api/v2/checklists/?audit_program_id=" + props.audit_program_id)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setChecklists(data);
        }),
      fetch("/api/v2/employees")
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setEmployees(data);
        });
      fetch(`/api/v1/portals/portal_upl_barranquilla`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setUplBarranquilla(data)
        });
  }, []);

  useEffect(() => {
    procedureAudits.map(
      (item) => (
        setProcedureAuditsId([...procedureAuditsId, item.id]),
        procedureAuditsId.push(item.id),
        setVectorProcedureAuditId(procedureAuditsId.join("&improvable_ids[]="))
      )
    );
  }, [procedureAudits]);

  useEffect(() => {
    fetch("/api/v2/opportunities?improvable_ids[]=" + vectorProcedureAuditId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setOpportunities(data);
      });
  }, [procedureAuditsId]);

  useEffect(() => {
    checklists.map(
      (item) => (
        setChecklistsId([...checklistsId, item.id]),
        checklistsId.push(item.id),
        setVectorChecklist(checklistsId.join("&checklist_ids[]="))
      )
    );
  }, [checklists]);

  useEffect(() => {
    fetch("/api/v2/audit_norms?checklist_ids[]=" + vectorChecklist)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setAuditNorms(data);
      });
  }, [checklistsId]);

  useEffect(() => {
    const values9001 = auditNorms.filter(
      (value) => value.normable_type == "ISO9001"
    );
    const sortedValues9001 = values9001
      .slice()
      .sort(
        (a, b) =>
          a.chapter_number.replace(/\./g, "") -
          b.chapter_number.replace(/\./g, "")
      );
    if (normsIso9001.length <= 0) {
      setNormsIso9001([...sortedValues9001]);
    }
    const valuesHealth = auditNorms.filter(
      (value) => value.normable_type == "HealthAssessment"
    );
    const sortedValuesHealth = valuesHealth
      .slice()
      .sort(
        (a, b) =>
          a.chapter_number.replace(/\./g, "") -
          b.chapter_number.replace(/\./g, "")
      );
    if (normsHealth.length <= 0) {
      setNormsHealth([...sortedValuesHealth]);
    }
    const values14001 = auditNorms.filter(
      (value) => value.normable_type == "Iso14001Assessment"
    );
    const sortedValues14001 = values14001
      .slice()
      .sort(
        (a, b) =>
          a.chapter_number.replace(/\./g, "") -
          b.chapter_number.replace(/\./g, "")
      );
    if (normsHealth.length <= 0) {
      setnormsIso14001([...sortedValues14001]);
    }
    const values45001 = auditNorms.filter(
      (value) => value.normable_type == "Iso45001Assessment"
    );
    const sortedValues45001 = values45001
      .slice()
      .sort(
        (a, b) =>
          a.chapter_number.replace(/\./g, "") -
          b.chapter_number.replace(/\./g, "")
      );
    if (normsHealth.length <= 0) {
      setNormsIso45001([...sortedValues45001]);
    }
    const valuesSGSST = auditNorms.filter(
      (value) => value.normable_type == "SgsstQuestion"
    );
    const sortedValuesSGSST = valuesSGSST
      .slice()
      .sort(
        (a, b) =>
          a.chapter_number.replace(/\./g, "") -
          b.chapter_number.replace(/\./g, "")
      );
    if (normsHealth.length <= 0) {
      setnormsSgsst([...sortedValuesSGSST]);
    }
  }, [auditNorms]);

  useEffect(() => {
    if (auditProgramReport.id !== undefined) {
      fetch(
        "/api/v2/abilities/user_abilities?audit_program_report_id=" +
          auditProgramReport.id
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setPrompts(data);
        }),
        fetch(
          "/api/v2/workflow/workflow_state?audit_program_report_id=" +
            auditProgramReport.id
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            setWorkflowPrompts(data);
          });
    }
  }, [auditProgramReport]);

  return (
    <>
      <NavBar
        auditProgramReport={auditProgramReport}
        props={props}
        prompts={prompts}
        auditNorms={auditNorms}
        workflowPrompts={workflowPrompts}
      />
      <div className="card-body p-1">
        <FirstTable auditProgram={auditProgram} props={props} />
        { uplbarranquilla && (
          < Findings
            normsIso9001={normsIso9001}
            normsHealth={normsHealth}
            normsIso14001={normsIso14001}
            normsIso45001={normsIso45001}
            normsSgsst={normsSgsst}
            prompts={prompts}
            modalFindings={modalFindings}
            setModalFlag={setModalFlag}
          />
        )}
        <IndividualReports
          procedureAudits={procedureAudits}
          opportunities={opportunities}
          prompts={prompts}
          modalIndividualReports={modalIndividualReports}
          setModalFlag={setModalFlag}
        />
        <GeneralReport
          auditProgramReport={auditProgramReport}
          prompts={prompts}
          setModalFlag={setModalFlag}
          modalGeneralReport={modalGeneralReport}
        />
        {prompts.assign_pannels_condition && (
          <AssignmentPanel
            auditProgramReport={auditProgramReport}
            props={props}
            employees={employees}
            prompts={prompts}
          />
        )}
        {modal && (
          <Modal
            modalFindings={modalFindings}
            modalIndividualReports={modalIndividualReports}
            modalGeneralReport={modalGeneralReport}
            ShowModal={ShowModal}
            modaltitle={modaltitle}
            modalFlag={modalFlag}
            auditNormId={auditNormId}
            auditReportId={auditReportId}
            props={props}
            procedureAudits={procedureAudits}
            auditProgramReport={auditProgramReport}
            auditNorms={auditNorms}
          />
        )}
      </div>
    </>
  );
};

export default AuditReports;
