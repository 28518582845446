import React, { useState, useEffect } from 'react';

const CountAssignmentsTask = ({task, type}) => {
  const [count, setCount] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCount = async () => {
      try {
        const response =await fetch(`/api/v2/action_summaries/count_assignments_task?task=${task}&type=${type}`,{
          method: 'GET',
          headers: {
            'Content-Type': 'aplication/json'
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const text = await response.text();

        const data = text ? JSON.parse(text) : {};
        setCount(data.count_assignment || 0);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchCount();
  }, [task, type]);

  return (
    <div>
      {error ? (
        <p>Error: {error}</p>
      ) : (
        <span id="task_filter_review" className="notification-badge notification-circle badge badge-pill badge-warning notification">
          {count !== null ? count : 'Loading...'}
        </span>
      )}
    </div>
  );
};

export default CountAssignmentsTask