import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['button','buttonone', 'buttontwo', 'buttonthree', 'buttonfour','buttonfive',
    'span', 'chapter', 'subchapter', 'paragraph_truncate', 'paragraph_complete', 'less_button', 'more_button']

  connect () {
    var chapter = document.getElementsByClassName("chapter");
    for (var x = 0; x < chapter.length; x++) {
      document.getElementById("spanchapter"+chapter[x].id).textContent = chapter[x].value
      var subchapters = document.getElementsByClassName("subchapter"+chapter[x].id);
      for (var y = 0; y < subchapters.length; y++) {
        document.getElementById("span"+subchapters[y].id).textContent = subchapters[y].value
      }
    }
    this.checkValues()
  }

  checkValues(){
    var selected = this.buttonTarget.value
    if (selected == 1) {$(this.buttononeTarget).prop('className', 'btn btn-outline-danger btn-sm mr-1 assigne')}
    else if (selected == 2) {$(this.buttontwoTarget).prop('className', 'btn btn-outline-warning btn-sm mr-1 assigne')}
    else if (selected == 3) {$(this.buttonthreeTarget).prop('className', 'btn btn-outline-info btn-sm mr-1 assigne')}
    else if (selected == 4) {$(this.buttonfourTarget).prop('className', 'btn btn-outline-success btn-sm mr-1 assigne')}
    else if (selected == 5) {$(this.buttonfiveTarget).prop('className', 'btn btn-outline-success btn-sm mr-1 assigne')}
    else {document.getElementById('finished').disabled = true}
    // this.Finished()
  }

  save()
  {
    // const elem = document.getElementById('assessmentform') // or $('#myform')[0] with jQuery
    // Rails.fire(elem, 'submit');
  }

  resetClass()
  {
    $(this.buttononeTarget).prop('className', 'btn btn-danger btn-sm mr-1')
    $(this.buttontwoTarget).prop('className', 'btn btn-warning btn-sm mr-1')
    $(this.buttonthreeTarget).prop('className', 'btn btn-info btn-sm mr-1')
    $(this.buttonfourTarget).prop('className', 'btn btn-success btn-sm mr-1')
    $(this.buttonfiveTarget).prop('className', 'btn btn-success btn-sm mr-1')
  }

  onAssigne1 (e) {
    if (e) e.preventDefault()
    this.resetClass()
    $(this.buttononeTarget).prop('className', 'btn btn-outline-danger btn-sm mr-1 assigne')
    this.buttonTarget.value = '1'
    this.onChange(this.buttonTarget.id)
    this.Finished()
  }
  onAssigne2 (e) {
    if (e) e.preventDefault()
    this.resetClass()
    $(this.buttontwoTarget).prop('className', 'btn btn-outline-warning btn-sm mr-1 assigne')
    this.buttonTarget.value = '2'
    this.onChange(this.buttonTarget.id)
    this.Finished()
  }
  onAssigne3 (e) {
    if (e) e.preventDefault()
    this.resetClass()
    $(this.buttonthreeTarget).prop('className', 'btn btn-outline-info btn-sm mr-1 assigne')
    this.buttonTarget.value = '3'
    this.onChange(this.buttonTarget.id)
    this.Finished()
  }
  onAssigne4 (e) {
    if (e) e.preventDefault()
    this.resetClass()
    $(this.buttonfourTarget).prop('className', 'btn btn-outline-success btn-sm mr-1 assigne')
    this.buttonTarget.value = '4'
    this.onChange(this.buttonTarget.id)
    this.Finished()
  }
  onAssigne5 (e) {
    if (e) e.preventDefault()
    this.resetClass()
    this.buttonTarget.value = '0'
    this.onChange(this.buttonTarget.id)
    this.Finished()
  }
  onAssigne6 (e) {
    if (e) e.preventDefault()
    this.resetClass()
    $(this.buttonfiveTarget).prop('className', 'btn btn-outline-primary btn-sm mr-1 assigne')
    this.buttonTarget.value = 5
    this.onChange(this.buttonTarget.id)
    this.Finished()
  }

  seeMore (){
    $(this.more_buttonTarget).prop('textContent', this.paragraph_completeTarget.innerText)
    $(this.more_buttonTarget).attr('data-action', 'click->assessment#seeLess')
  }

  seeLess (){
    $(this.more_buttonTarget).prop('textContent', this.paragraph_completeTarget.innerText.slice(0, 77) + '...')
    $(this.more_buttonTarget).attr('data-action', 'click->assessment#seeMore')
  }

  onChange (id) {
    var number = document.getElementsByClassName(id);
    var sum = 0;
    var nils = 0;
    for (var x = 0; x < number.length; x++) {
      var value = number[x].value
      if (value > 0)
      { sum = Number(sum) + Number(value) }
      else
      { nils = Number(nils) + 1 }
    }
    var prom = Number(sum) / (Number(number.length) - nils)
    document.getElementById("spansubchapter"+id).textContent = prom.toFixed(2);
    document.getElementById("subchapter"+id).value = prom.toFixed(2);
    this.Chapter()
  }

  Chapter () {
    var chapter = document.getElementsByClassName("chapter");
    for (var x = 0; x < chapter.length; x++) {
      var subchapters = document.getElementsByClassName("subchapter"+chapter[x].id);
      var sum = 0;
      var nils = 0;
      for (var y = 0; y < subchapters.length; y++) {
        var value = subchapters[y].value
        if (value > 0)
        { sum = Number(sum) + Number(value) }
        else
        { nils = Number(nils) + 1 }
      }
      var prom = Number(sum) / (Number(subchapters.length) - nils)
      if (isNaN(prom))
      { prom = 0 }
      document.getElementById(chapter[x].id).value = prom.toFixed(2);
      document.getElementById("spanchapter"+chapter[x].id).textContent = prom.toFixed(2);
    }
  }

  Finished()
  {
    var elementosOutline = document.querySelectorAll('.assigne').length;
    var allelementosOutline = document.querySelectorAll('.nrow').length;
  
    if (elementosOutline == allelementosOutline)
    {
      document.getElementById('finished').disabled = false;
    }else{
      document.getElementById('finished').disabled = true;
    }
  }
}
