import { Controller }  from 'stimulus'

export default class extends Controller {

  connect () {
    var rows = document.getElementsByClassName("mergeable")
    for (var i=0; i<rows.length; i++)
    {
      if(rows[i].style.display != 'none') {
        var normable_parent = rows[i].childNodes[0].childNodes[0].textContent
        for (var j=0; j<rows.length; j++)
        {
          if(normable_parent == rows[j].childNodes[0].childNodes[0].textContent && i !=j) {
            if (rows[i].childNodes[0].childNodes[1].textContent == 'false' ){
              var description = rows[j].childNodes[4].childNodes[0];
              var finding = rows[j].childNodes[2].childNodes[0]
              if (description) {rows[i].childNodes[4].appendChild(description);}
              if (finding) { rows[i].childNodes[2].appendChild(finding);}
            }
            var procedure = rows[j].childNodes[3].childNodes[0]
            var evidence = rows[j].childNodes[5].childNodes[0]
            if (procedure) { rows[i].childNodes[3].appendChild(procedure);}
            if (evidence) { rows[i].childNodes[5].appendChild(evidence);}
            rows[j].style.display = 'none';
          }
        }
      }
    }
  }
}
