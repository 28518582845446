import { Controller }  from 'stimulus'



export default class extends Controller {

  connect() {

    
    var level_1 = document.getElementById("pqrsf_satisfaction_level_1");
    var level_2 = document.getElementById("pqrsf_satisfaction_level_2");
    var level_3 = document.getElementById("pqrsf_satisfaction_level_3");
    var level_4 = document.getElementById("pqrsf_satisfaction_level_4");
    var level_5 = document.getElementById("pqrsf_satisfaction_level_5");
    level_1.style.display = 'none'
    level_2.style.display = 'none'
    level_3.style.display = 'none'
    level_4.style.display = 'none'
    level_5.style.display = 'none'
    
    
    this.onChange();
  }

  

  onChange(e) {
    
    var level_1 = document.getElementById("pqrsf_satisfaction_level_1");
    var level_2 = document.getElementById("pqrsf_satisfaction_level_2");
    var level_3 = document.getElementById("pqrsf_satisfaction_level_3");
    var level_4 = document.getElementById("pqrsf_satisfaction_level_4");
    var level_5 = document.getElementById("pqrsf_satisfaction_level_5");
    var card_smile_beam = document.getElementById("smile-beam");
    var card_smile = document.getElementById("smile");
    var card_meh = document.getElementById("meh");
    var card_frown = document.getElementById("frown"); 
    var card_angry = document.getElementById("angry");
    level_1.addEventListener("change", function(){
     card_smile_beam.style.background ="#FFFFFF";
     card_smile.style.background ="#FFFFFF";
      card_meh.style.background ="#FFFFFF";
      card_frown.style.background ="#FFFFFF";
      card_angry.style.background = "#E2A494";
    });

    level_2.addEventListener("change", function(){
      
      card_smile_beam.style.background ="#FFFFFF";
      card_smile.style.background ="#FFFFFF";
       card_meh.style.background ="#FFFFFF";
       card_frown.style.background ="#FEF69B";
       card_angry.style.background = "#FFFFFF";
     });
    
     level_3.addEventListener("change", function(){
      
      card_smile_beam.style.background ="#FFFFFF";
      card_smile.style.background ="#FFFFFF";
       card_meh.style.background ="#ADE294";
       card_frown.style.background ="#FFFFFF";
       card_angry.style.background = "#FFFFFF";
     });

     level_4.addEventListener("change", function(){
      
      card_smile_beam.style.background ="#FFFFFF";
      card_smile.style.background ="#94ACE2";
       card_meh.style.background ="#FFFFFF";
       card_frown.style.background ="#FFFFFF";
       card_angry.style.background = "#FFFFFF";
     });

     level_5.addEventListener("change", function(){
      
      card_smile_beam.style.background ="#B8DFF9";
      card_smile.style.background ="#FFFFFF";
       card_meh.style.background ="#FFFFFF";
       card_frown.style.background ="#FFFFFF";
       card_angry.style.background = "#FFFFFF";
     });

  }

  
}
