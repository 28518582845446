import React from "react";

const EffectModal = () => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th className="align-middle text-center">
            PARÁMETROS DE CALIFICACIÓN CUALITATIVA
          </th>
          <th className="align-middle text-center"> CALIFICACIÓN </th>
          <th className="align-middle text-center"> GRADO DE SIGNIFICANCIA </th>
          <th className="align-middle text-center"> DESCRIPCIÓN </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="align-middle text-justify" rowSpan="2">
            EFECTO - (EF)
          </td>
          <td className="align-middle text-justify">INDIRECTO (Secundario)</td>
          <td className="align-middle text-justify">1</td>
          <td className="align-middle text-justify">
            La manifestación no es consecuencia directa de la acción
          </td>
        </tr>
        <tr>
          <td className="align-middle text-justify">DIRECTO</td>
          <td className="align-middle text-justify">4</td>
          <td className="align-middle text-justify">
            El impacto es causado por la actividad
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default EffectModal;
