import React, { useEffect, useState } from "react";
import { GetEnvResources } from "../Form/Requests";
import ResourceInput from "./ResourceInput";

const Resources = ({
  envImpactAux,
  envImpact,
  setEnvImpactAux,
  indexActivity,
  indexImpact,
  indexAspect,
  impactsAux,
  setImpactsAux,
  impact,
}) => {
  const [environmentalResources, setEnvironmentalResources] = useState([]);
  const [counterResources, setCounterResources] = useState(0);
  const [resourcesAux, setResourcesAux] = useState([]);
  const [resources, setResources] = useState([]);

  useEffect(() => {
    if (
      !!envImpact.env_impact_activities_attributes[indexActivity]
        ?.env_impact_aspects_attributes[indexAspect]
        ?.env_impact_evaluations_attributes[indexImpact]
    ) {
      const formattedResources = envImpact.env_impact_activities_attributes[
        indexActivity
      ].env_impact_aspects_attributes[
        indexAspect
      ].env_impact_evaluations_attributes[
        indexImpact
      ].resources_attributes.reduce((acc, resource, index) => {
        acc[index] = {
          id: resource?.id,
          indexResource: index,
          environmental_resource_id: resource.environmental_resource_id,
          _destroy: false,
        };
        return acc;
      }, []);
      setResourcesAux(formattedResources);
      setCounterResources(
        envImpact.env_impact_activities_attributes[indexActivity]
          .env_impact_aspects_attributes[indexAspect]
          .env_impact_evaluations_attributes[indexImpact].resources_attributes
          .length - 1
      );
    }
  }, [envImpact]);

  useEffect(() => {
    GetEnvResources().then((data) => {
      setEnvironmentalResources(data);
    });
  }, []);

  const addInput = () => {
    const localCounter = counterResources + 1;
    const newResource = {
      id: null,
      indexResource: localCounter,
      environmental_resource_id: environmentalResources[0].id,
      _destroy: false,
    };
    setCounterResources(counterResources + 1);
    setResourcesAux([...resourcesAux, newResource]);
  };

  const deleteInput = (e) => {
    const item = resourcesAux.find((item) => item.indexResource === e);
    if (!!item.id) {
      const indexToUpdate = resourcesAux.findIndex(
        (item) => item.indexResource === e
      );
      const newObj = { ...resourcesAux[indexToUpdate], _destroy: true };
      setResourcesAux((prevState) => {
        const newState = [...prevState];
        newState[indexToUpdate] = newObj;
        return newState;
      });
    } else {
      const updatedResources = resourcesAux.filter(
        (proc) => proc.indexResource !== e
      );
      const reindexedImpacts = updatedResources.map((proc, index) => ({
        ...proc,
        indexResource: index,
      }));
      setCounterResources(counterResources - 1);
      setResourcesAux(reindexedImpacts);
    }
  };

  const handleSelect = (id, e) => {
    const { value } = e.target;
    setResourcesAux((prevResourcesAux) =>
      prevResourcesAux.map((item) =>
        item.indexResource === id
          ? { ...item, environmental_resource_id: value }
          : item
      )
    );
  };

  useEffect(() => {
    const formattedResources = resourcesAux.reduce((acc, resource, index) => {
      acc[index] = {
        id: resource?.id || null,
        environmental_resource_id: resource.environmental_resource_id,
        _destroy: resource._destroy,
      };
      return acc;
    }, {});
    setResources(formattedResources);
  }, [resourcesAux]);

  useEffect(() => {
    const updatedImpact = { ...envImpactAux };
    const updatedImpacts = [...impactsAux];
    if (
      updatedImpact?.env_impact_activities_attributes?.[indexActivity] &&
      updatedImpact.env_impact_activities_attributes[indexActivity]?.env_impact_aspects_attributes?.[indexAspect] &&
      updatedImpact.env_impact_activities_attributes[indexActivity].env_impact_aspects_attributes[indexAspect]?.env_impact_evaluations_attributes?.[indexImpact]
    ) {
      updatedImpact.env_impact_activities_attributes[indexActivity].env_impact_aspects_attributes[indexAspect].env_impact_evaluations_attributes[indexImpact].resources_attributes = resources;
      updatedImpacts[indexImpact].resources_attributes = resources;
      setEnvImpactAux(updatedImpact);
      setImpactsAux(updatedImpacts);
    }
    }, [resources]);

  return (
    <div className="card-body">
      <div className="form-row">
        <div className="col-md-12">
          <div className="form-group mb-3">
            {resourcesAux.map((input) => (
              <ResourceInput
                key={input.indexResource}
                resources={environmentalResources}
                deleteInput={deleteInput}
                idInput={input.indexResource}
                idResource={input.environmental_resource_id}
                handleSelect={handleSelect}
                resource={input}
              />
            ))}
            <button
              type="button"
              className="btn btn-info btn-sm mb-2"
              onClick={addInput}
            >
              <i className="fas fa-plus mr-1"></i>
              Recurso afectado
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
