import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["destroy", "position"]

  onMoveUp (e){
    e.preventDefault()
    const rowId = parseInt(this.positionTarget.value)
    const previousRowId = rowId - 1

    if (previousRowId >= 1 )  {
      const $currentRow = document.querySelector(`[value="${rowId}"]`).closest("input")
      const $previousRow = document.querySelector(`[value="${previousRowId}"]`).closest("input")
      const currentValueCurrentRow = parseInt($currentRow.value)
      const newValueCurrentRow = currentValueCurrentRow - 1
      const currentValuePreviousRow = parseInt($previousRow.value)
      const newValuePreviousRow = currentValuePreviousRow + 1
      $currentRow.setAttribute('value',newValueCurrentRow)
      $previousRow.setAttribute('value',newValuePreviousRow)
      const div = e.currentTarget.closest('div')
      const td = div.parentNode
      const tr = td.parentNode
      const tbody = tr.parentNode
      const table = tbody.parentNode
      const maindiv = table.parentNode
      const parentdiv = maindiv.parentNode
      const alldivs = parentdiv.querySelectorAll('div .form-group.mb-0')
      const row = $(this.element)
      row.insertBefore(row.prev())
      alldivs.forEach((div) => {
        const div2 = div.children[1].children[0].children[0].children[0].querySelectorAll('[data-controller="tooltip"]')
        div2[2].setAttribute('class', 'disabled')
      })
    } else {
      alert('No se puede subir la pregunta')
    }
  }

  onMoveDown (e){
    e.preventDefault();
    const rowId = parseInt(this.positionTarget.value)
    const nextRowId = rowId + 1

    try {
      const $currentRow = document.querySelector(`[value="${rowId}"]`).closest("input")
      const $nextRow = document.querySelector(`[value="${nextRowId}"]`).closest("input")
      const currentValueCurrentRow = parseInt($currentRow.value)
      const newValueCurrentRow = currentValueCurrentRow + 1
      const currentValueNextRow = parseInt($nextRow.value)
      const newValueNextRow = currentValueNextRow - 1
      $currentRow.setAttribute('value',newValueCurrentRow)
      $nextRow.setAttribute('value',newValueNextRow)
      const div = e.currentTarget.closest('div')
      const td = div.parentNode
      const tr = td.parentNode
      const tbody = tr.parentNode
      const table = tbody.parentNode
      const maindiv = table.parentNode
      const parentdiv = maindiv.parentNode
      const alldivs = parentdiv.querySelectorAll('div .form-group.mb-0')
      const row = $(this.element)
      row.insertAfter(row.next())
      alldivs.forEach((div) => {
        const div2 = div.children[1].children[0].children[0].children[0].querySelectorAll('[data-controller="tooltip"]')
        div2[2].setAttribute('class', 'disabled')
      })
    } catch(e) {
      alert('No se puede bajar la pregunta')
    }
  }

  onDelete (e) {
    e.preventDefault()
    const div = e.currentTarget.closest('div')
    const td = div.parentNode
    const tr = td.parentNode
    const tbody = tr.parentNode
    const table = tbody.parentNode
    const maindiv = table.parentNode
    const parentdiv = maindiv.parentNode
    const alldivs = parentdiv.querySelectorAll('div .form-group.mb-0')
    $(this.element).fadeOut() 
    this.destroyTarget.value = '1'
    alldivs.forEach((div) => {
      const div2 = div.children[1].children[0].children[0].children[0].querySelectorAll('[data-controller="tooltip"]')
      div2[0].setAttribute('class', 'disabled')
      div2[1].setAttribute('class', 'disabled')
    })
  }
}
