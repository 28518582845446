import React, { useState, useEffect } from "react"
import Modal from './BscObjectives/Modal'
import List from "./BscObjectives/List"
import DateInput from './MetricsPage/DateInput'
import General from './BscObjectives/General'

const BscMetrics = (props) => {
  const [colorRanges, setColorRanges] = useState([])
  const [operatives, setOperatives] = useState([])
  const [strategics, setStrategics] = useState([])
  const [general, setGeneral] = useState(0)
  const [perspectives, setPerspectives] = useState([])
  const [showStrategics, setShowStrategics] = useState(false)
  const [showPerspectives, setShowPerspectives] = useState(false)
  const [date, setDate] = useState('')
  const [modal, showModal] = useState(false)
  const [modalData, setModalData] = useState([])
  const [modalParentId, setModalParentId] = useState(null)
  const [modalTitle, setModalTitle] = useState(null)
  const [modalParentType, setModalParentType] = useState(null)

  useEffect(() => {
    fetch('/api/v1/bsc_metrics/color_ranges').then((response)=>{
      return response.json()
    }).then((data) => {
      setColorRanges(data)
    })
  }, [])

  useEffect(() => {
    fetch(`/api/v1/bsc_metrics/operative_objectives?bsc_id=${props.bsc_id}&date=${date}`).then((response)=>{
      return response.json()
    }).then((data) => {
      setOperatives(data)
    })
  }, [colorRanges, date])

  useEffect(() => {
    fetch(`/api/v1/bsc_metrics/strategic_objectives?bsc_id=${props.bsc_id}`).then((response)=>{
      return response.json()
    }).then((data) => {
      setStrategics(data)
    })
  }, [colorRanges])

  useEffect(() => {
    fetch(`/api/v1/bsc_metrics/bsc_perspectives?bsc_id=${props.bsc_id}`).then((response)=>{
      return response.json()
    }).then((data) => {
      setPerspectives(data)
    })
  }, [colorRanges])

  // Set the values of the strategics
  useEffect(()=> {
    if (strategics.length > 0 && operatives.length > 0 && perspectives.length > 0) {
      var generalValue = 0
      strategics.forEach(strategic => {
        let filter_operatives = operatives.filter(operative => operative.parent_id === strategic.id)
        let sumValue = filter_operatives.reduce((acumulator, element) => {
          return acumulator + element.value;
        }, 0);
        let value = sumValue / filter_operatives.length
        strategic.value = isNaN(value) ? 0 : Math.round(value)
        generalValue += strategic.value
        strategic.color = ColorRangeEvaluator(value)
        strategic.weighted = isNaN(value) ? 0 : (strategic.percentage * value) / 100
      })
      setGeneral((generalValue / strategics.length).toFixed(2))
      setShowStrategics(true)

      perspectives.forEach(perspective => {
        let filter_strategics = strategics.filter(strategic => strategic.parent_id === perspective.id)
        let sumValue = filter_strategics.reduce((acumulator, element) => {
          return acumulator + element.value;
        }, 0);
        let value = sumValue / filter_strategics.length
        perspective.value = isNaN(value) ? 0 : Math.round(value)
        perspective.color = ColorRangeEvaluator(value)
        perspective.weighted = isNaN(value) ? 0 : (perspective.percentage * value) / 100
      })
      setShowPerspectives(true)
    }

  }, [operatives, strategics, perspectives])

  const ColorRangeEvaluator = (resource) => {
    let color = '#FFFFFF'

    for (let i = 0; i < colorRanges.length; i++) {
      const colorRange = colorRanges[i];

      if (colorRange.range_type === 'between' && resource >= colorRange.starts && resource <= colorRange.ends) {
        color = colorRange.color;
      }
      if (colorRange.range_type === 'equal_to' && resource === colorRange.starts) {
        color = colorRange.color;
      }
      if (colorRange.range_type === 'greater_than' && resource > colorRange.starts) {
        color = colorRange.color;
      }
      if (colorRange.range_type === 'greater_than_or_equal_to' && resource >= colorRange.starts) {
        color = colorRange.color;
      }
      if (colorRange.range_type === 'less_than' && resource < colorRange.starts) {
        color = colorRange.color;
      }
      if (colorRange.range_type === 'less_than_or_equal_to' && resource <= colorRange.starts) {
        color = colorRange.color;
      }
    }
    return color;
  }

  const modalCallback = (modal, dataType, dataParent, modaltitle) => {
    let newData = []
    showModal(modal)
    setModalParentId(dataParent)
    setModalTitle(modaltitle)
    if (dataType === 'perspective') {
      setModalParentType('strategic')
      newData = strategics.filter(strategic => strategic.parent_id === dataParent)
    } else if (dataType === 'strategic') {
      setModalParentType('operative')
      newData = operatives.filter(operative => operative.parent_id === dataParent)
    } else if (dataType === 'operative') {
      let checkOperative = operatives.filter(operative => operative.id === dataParent)[0]
      if (checkOperative !== undefined && checkOperative?.group_strategic_id !== null) {
        fetch(`/api/v1/bsc_metrics/unscoped_operative_objectives?strategic_id=${checkOperative.group_strategic_id}`).then((response)=>{
          return response.json()
        }).then((data) => {
          setModalData(data)
          setModalParentType('operative')
        })
      } else {
        fetch(`/api/v1/bsc_metrics/indicators?operative_objective_id=${dataParent}&bsc_id=${props.bsc_id}`).then((response)=>{
          return response.json()
        }).then((data) => {
          setModalData(data)
          setModalParentType('indicator')
        })
      }

    }
    setModalData(newData)
  }

  const dateCallBack = (date) =>{
    setDate(date)
  }

  return (
    <>
      <div className='card-body'>
        <DateInput dateFilter={dateCallBack}/>
        <div style={{border: '1px solid', borderColor: '#dadada', borderRadius: '5px'}}>
          <General percentage={general} color={ColorRangeEvaluator(general)}/>
        </div>
        <div className='row mt-4'>
          {showPerspectives && <List cardTitle={'Perspectivas BSC'}
                                     data={perspectives}
                                     parentType={'perspective'}
                                     modalControl={modalCallback}/>}
          {showStrategics && <List cardTitle={'Objetivos Estratégicos'}
                                   data={strategics}
                                   parentType={'strategic'}
                                   modalControl={modalCallback}/>}
          {operatives.length && <List cardTitle={'Objetivos Operativos'}
                                      data={operatives}
                                      parentType={'operative'}
                                      modalControl={modalCallback}/>}
          {modal && <Modal modalControl={modalCallback}
                           modalTitle={modalTitle}
                           parentType={modalParentType}
                           parentId={modalParentId}
                           data={modalData}/>}
        </div>
      </div>
    </>
  );
}
export default BscMetrics