import React from 'react'

const GeneralReport = ({auditProgramReport, prompts, setModalFlag, modalGeneralReport}) => {
  const setModal = (e) => {
    setModalFlag(2);
    modalGeneralReport(true, 'Editar Informe General', e);
  }
  return (
    <>
      <div className="card card-body mb-2 mx-1 mt-3">
        <div className="d-flex justify-content-between">
          <h5 className="card-title font-weight-bold mb-1">INFORME GENERAL</h5>
          {prompts.user_writer === true ? (
            <span className={`btn btn-outline-success btn-sm ${prompts.edit_condition === true ? (''):('disabled') }`} onClick={()=>setModal(auditProgramReport.id)}>
            <i className='far fa-edit'></i>
          </span>
          ) : ( null )}
        </div>
        <h6 className="font-weight-semibold">
          Fortalezas
        </h6>
        <span className="mb-4" style={{whiteSpace: 'pre-line', textAlign: 'justify', display: 'block'}}>
          {auditProgramReport.strengths} 
        </span>
        <div className="dropdown-divider bg-light">
        </div>
        <h6 className="font-weight-semibold">Conformidad</h6>
        <span className="mb-4" style={{whiteSpace: 'pre-line', textAlign: 'justify', display: 'block'}}>
          {auditProgramReport.conformities}
        </span>
        <div className="dropdown-divider bg-light">
        </div>
        <h6 className="font-weight-semibold">
          Oportunidades de mejora
        </h6>
        <span className="mb-4" style={{whiteSpace: 'pre-line', textAlign: 'justify', display: 'block'}}>
          {auditProgramReport.opportunities}
        </span>
        <div className="dropdown-divider bg-light">
        </div>
        <h6 className="font-weight-semibold">No Conformidad</h6>
        <span className="mb-4" style={{whiteSpace: 'pre-line', textAlign: 'justify', display: 'block'}}>
          {auditProgramReport.nonconformities}
        </span>
        <div className="dropdown-divider bg-light">
        </div>
        <h6 className="font-weight-semibold">
          Análisis Histórico
        </h6>
        <span className="mb-4" style={{whiteSpace: 'pre-line', textAlign: 'justify', display: 'block'}}>
          {auditProgramReport.historical_analysis}
        </span>
        <div className="dropdown-divider bg-light">
        </div>
        <h6 className="font-weight-semibold">
          Conclusiones
        </h6>
        <span className="mb-4" style={{whiteSpace: 'pre-line', textAlign: 'justify', display: 'block'}}>
          {auditProgramReport.conclusions}
        </span>
        <div className="dropdown-divider bg-light">
        </div>
        <h6 className="font-weight-semibold">
          Recomendaciones
        </h6>
        <span className="mb-4" style={{whiteSpace: 'pre-line', textAlign: 'justify', display: 'block'}}>
          {auditProgramReport.recommendations}
        </span>
      </div>
    </>
  )
}

export default GeneralReport