import React, { useState } from "react";
import Tooltip from "../../../Tooltip";

const TableRow = ({ environmentalImpact, canSee, canEdit }) => {
  const [collapsed, setCollapsed] = useState(false);

  const activityNames = (activities) => {
    let text = activities.map((object) => object.title).join(", ");
    return text.slice(0, 120);
  };

  const procedureNames = (procedures) => {
    let text = procedures.map((object) => object.title).join(", ");
    return text.slice(0, 120);
  };

  return (
    <div className="container-fluid ml-0 mr-0 mb-0">
      <div
        className="row border-top border-bottom cursor-pointer"
        onClick={() => setCollapsed(!collapsed)}
      >
        <div className="col-2 px-1 py-2">
          <div className="d-flex justify-content-between align-middle">
            <div className="d-flex">
              <Tooltip text={"Plan de acción"}>
                {canSee && (
                  <a
                    href={`/environmental_matrices/${environmentalImpact.environmental_matrix_id}/environmental_impacts/${environmentalImpact.id}/environmental_impact_actions`}
                    className="btn btn-primary btn-sm mr-1"
                  >
                    Plan de acción
                  </a>
                )}
              </Tooltip>
              <Tooltip text={"Editar"}>
                {canEdit && (
                  <a
                    href={`/environmental_matrices/${environmentalImpact.environmental_matrix_id}/environmental_impacts/${environmentalImpact.id}/edit`}
                    className="btn btn-outline-success btn-sm mr-1"
                  >
                    <i className="far fa-edit"></i>
                  </a>
                )}
              </Tooltip>
              <Tooltip text={"Borrar"}>
                {canEdit && (
                  <a
                    href={`/environmental_matrices/${environmentalImpact.environmental_matrix_id}/environmental_impacts/${environmentalImpact.id}`}
                    className="btn btn-sm btn-outline-danger mr-1"
                    data-method="delete"
                  >
                    <i className="fas fa-trash"></i>
                  </a>
                )}
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="col-5 text-center py-2 px-0 d-none d-lg-block">
          {activityNames(environmentalImpact.activities)}
        </div>
        <div className="col-5 text-center py-2 px-0 d-xs-none d-md-block">
          {environmentalImpact.all_procedures ? (
            <span>Todos los procesos</span>
          ) : (
            <span>{procedureNames(environmentalImpact.procedures)}</span>
          )}
        </div>
      </div>
      {collapsed && (
        <div className="container-fluid ml-0 mr-0 mb-2 d-none d-md-block d-lg-block d-xl-block">
          <div className="row border-bottom">
            <div className="col-5 px-0 d-md-none d-lg-block">
              <h6 className="font-weight-bold mb-0 py-2 text-start">Resumen</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <span className="font-weight-bold mb-2 py-2 text-start">
                Aspectos
              </span>
            </div>
            <div className="col-3">
              <span className="font-weight-bold mb-0 py-2 text-start">
                Impactos
              </span>
            </div>
            <div className="col-3">
              <span className="font-weight-bold mb-0 py-2 text-start">
                Valor de significancia
              </span>
            </div>
            <div className="col-3">
              <span className="font-weight-bold mb-0 py-2 text-start">
                Nivel de significancia
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <span className=" mb-0 py-2 text-start">
                {environmentalImpact.aspects.map((aspect) => (
                  <React.Fragment key={aspect.id}>
                    <span>{aspect.name}</span>
                    <br></br>
                  </React.Fragment>
                ))}
              </span>
            </div>
            <div className="col-3">
              <span className="mb-0 py-2 text-start">
                {environmentalImpact.evaluations.map((evaluation) => (
                  <React.Fragment key={evaluation.id}>
                    <span>{evaluation.generated_impact}</span>
                    <br></br>
                  </React.Fragment>
                ))}
              </span>
            </div>
            <div className="col-3">
              <span className="mb-0 py-2 text-start">
                {environmentalImpact.evaluations.map((evaluation) => (
                  <React.Fragment key={evaluation.id}>
                    <span>{evaluation.significance_value}</span>
                    <br></br>
                  </React.Fragment>
                ))}
              </span>
            </div>
            <div className="col-3">
              <span className="mb-0 py-2 text-start">
                {environmentalImpact.evaluations.map((evaluation) => (
                  <React.Fragment key={evaluation.id}>
                    <span>{evaluation.significance_level}</span>
                    <br></br>
                  </React.Fragment>
                ))}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableRow;
