import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['true', 'false', 'nul']

  connect () {
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()
    if ($(this.trueTarget).prop('checked')) 
    {
      $("#text_description").show('slow');
    } else {
      $("#text_description").hide('slow');
    }
  }
}
