import { createContext, useContext, useState } from "react";


export const FLASH_TYPE_ERROR = "error"
export const FLASH_TYPE_NOTICE = "notice"
export const FLASH_TYPE_SUCCESS = "success"

export const FlashContext = createContext({
  message: "",
  type: "",
  ts: 0,
  setFlash:  () => {},
  clearFlash:  () => {}
});


export const useFlashState = () => {

  const [flashState, setFlashState] = useState({
    message: "",
    type: "",
    ts: 0,
    setFlash: ({ message: newMessage, type: newType }) => {
      setFlashState((old) => {
        return {
          ...old,
          ts: new Date().getTime(),
          message: newMessage,
          type: newType,
        };
      });
    },
    clearFlash: () => {
      setFlashState((old) => {
        return {
          ...old,
          ts: new Date().getTime(),
          message: "",
          type: "",
        };
      });
    },
  });

  return flashState;
}

export const useFlash = () => {
  return useContext(FlashContext)
}
