import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox"]

  connect () {
    this.toggle()
  }


  toggle(event) {
    const value = document.getElementById("evaluation").checked
    if (value){
      document.getElementById("calification").hidden = false
      document.getElementById("save_evaluation").hidden = false
    }else{
      document.getElementById("calification").hidden = true
      document.getElementById("save_evaluation").hidden = true
    }
  }
}