import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['starts', 'ends', 'rangeType', 'label']

  connect () {
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()

    if ($(this.rangeTypeTarget).find(':selected').val() === 'between') {
      $(this.endsTarget).fadeIn()
      $(this.labelTarget).fadeIn()
    } else {
      $(this.endsTarget).fadeOut()
      $(this.labelTarget).fadeOut()
    }
  }
}
