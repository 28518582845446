import { Controller } from 'stimulus'
import Chart from 'chart.js'

export default class extends Controller {
  connect () {
    if (!!this.element.dataset.points2) {
      let { points2 } = this.element.dataset
      this.points2 = JSON.parse(points2)
    }
    let { points } = this.element.dataset

    points = JSON.parse(points)
    const yAxisName = this.data.get('yaxis')
    const yAxisName2 = this.data.get('yaxis2')
    const labels = points.map(({ date }) => date)
    const data = points.map(({ value }) => value)
    const colors = points.map(({ color }) => color)
    if (!!this.points2) {
      this.data2 = this.points2.map(({ value }) => value)
    }
    this.ctx = this.element.getContext('2d')
    if (!!this.points2) {
      this.options = {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: yAxisName,
              borderColor: '#3368FF',
              pointBorderWidth: 5,
              pointHoverRadius: 10,
              pointHoverBorderWidth: 1,
              pointRadius: 3,
              fill: false,
              backgroundColor: colors,
              borderWidth: 2,
              data,
            },
            {
              label: yAxisName2,
              borderColor: '#FF3352',
              pointBorderWidth: 5,
              pointHoverRadius: 10,
              pointHoverBorderWidth: 1,
              pointRadius: 3,
              fill: false,
              backgroundColor: colors,
              borderWidth: 2,
              data: this.data2,
            },
          ]
        },
        options: {
          tooltips: {
            titleFontFamily: 'Source Sans Pro',
            titleFontSize: 16,
            bodyFontSize: 16,
            xPadding: 10,
            yPadding: 10
          },
          animation: {
            easing: 'easeInOutBack'
          },
          legend: {
            display: true,
            position: 'top'
          },
          scales: {
            yAxes: [{
              ticks: {
                fontColor: 'rgba(0,0,0,0.5)',
                fontStyle: 'bold',
                beginAtZero: true,
                maxTicksLimit: 5,
                padding: 20
              },
              gridLines: {
                drawTicks: false,
                display: false
              }
            }],
            xAxes: [{
              gridLines: {
                zeroLineColor: 'transparent'
              },
              ticks: {
                padding: 20,
                fontColor: 'rgba(0,0,0,0.5)',
                fontStyle: 'bold',
                callback: function(label) {
                  if (/\s/.test(label)) {
                    return label.split(" ");
                  }else{
                    return label;
                  }
                }


              }
            }]
          }
        }
      }
    } else {
      this.options = {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: yAxisName,
              borderColor: '#3368FF',
              pointBorderWidth: 5,
              pointHoverRadius: 10,
              pointHoverBorderWidth: 1,
              pointRadius: 3,
              fill: false,
              backgroundColor: colors,
              borderWidth: 2,
              data,
            },
          ]
        },
        options: {
          tooltips: {
            titleFontFamily: 'Source Sans Pro',
            titleFontSize: 16,
            bodyFontSize: 16,
            xPadding: 10,
            yPadding: 10
          },
          animation: {
            easing: 'easeInOutBack'
          },
          legend: {
            display: true,
            position: 'top'
          },
          scales: {
            yAxes: [{
              ticks: {
                fontColor: 'rgba(0,0,0,0.5)',
                fontStyle: 'bold',
                beginAtZero: true,
                maxTicksLimit: 5,
                padding: 20
              },
              gridLines: {
                drawTicks: false,
                display: false
              }
            }],
            xAxes: [{
              gridLines: {
                zeroLineColor: 'transparent'
              },
              ticks: {
                padding: 20,
                fontColor: 'rgba(0,0,0,0.5)',
                fontStyle: 'bold',
                callback: function(label) {
                  if (/\s/.test(label)) {
                    return label.split(" ");
                  }else{
                    return label;
                  }
                }


              }
            }]
          }
        }
      }
    }
    this.chart = new Chart(this.ctx, this.options)
  }
}
