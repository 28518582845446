import { Controller } from 'stimulus'

export default class extends Controller {
  onCall (e) {
    if (e) e.preventDefault()
    const url = this.data.get('url')
    const elementId = this.data.get('target')
    document.getElementById(elementId).innerHTML = this.data.get('loading')
    const auxVarOne = this.data.get('auxone')
    const auxVarTwo = this.data.get('auxtwo')
    $.get(url + '?aux_one=' + auxVarOne + '&aux_two=' + auxVarTwo).then((content) => {
      document.getElementById(elementId).innerHTML = content
    })
  }
}
