import React, { useState, useContext, useEffect, useMemo } from "react";
import GlobalContext from "../context/GlobalContext";
import Select from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";

export default function ModalFollow({
  temporalInformationFollow,
  setTemporalInformationFollow,
}) {
  const { eventEditId, currentUserId } = useContext(GlobalContext);
  const [eventData, setEventData] = useState(null);
  const [users, setUsers] = useState([]);
  const [optionsUsers, setOptionsUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [followOptions, setFollowOptions] = useState([]);
  const [selectedFollowOption, setSelectedFollowOption] = useState();
  const [selectedUsersRemember, setSelectedUserRemember] = useState();
  const [userProps, setUserProps] = useState(currentUserId);
  const [userInformation, setUserInformation] = useState({});

  useEffect(() => {
    fetch(`/api/v2/events/${eventEditId}`)
      .then((response) => response.json())
      .then((data) => {
        setEventData(data);
      })
      .catch((error) => {
        console.error("Error en la petición de eventos:", error);
      });
  }, [eventEditId]);

  useEffect(() => {
    Promise.all([fetch("/api/v2/users/").then((response) => response.json())])
      .then(([usersData]) => {
        setUsers(usersData.results);
      })
      .catch((error) => {
        console.error(
          "Error en la petición de usuarios, periodicidades o procedimientos:",
          error
        );
      });
  }, [eventData]);

  useEffect(() => {
    let isMounted = true;

    fetch(`/api/v2/users/${userProps}`)
      .then((response) => response.json())
      .then((data) => {
        if (isMounted) {
          setUserInformation(data.user);
        }
      })
      .catch((error) => {
        console.error("Error en la petición de info de usuario:", error);
      });

    return () => {
      isMounted = false;
    };
  }, [eventData, selectedUsersRemember]);

  useEffect(() => {
    const optionUsers = users.map((user) => ({
      value: user.id,
      label: user.name,
    }));
    setOptionsUsers(optionUsers);
    if (eventData && eventData.employee_id !== undefined) {
      const selected = optionsUsers.find(
        (option) => option.value === eventData.employee_id
      );
      setSelectedUser(selected);
    }
  }, [users]);

  useEffect(() => {
    const options = [
      { value: "completed", label: "Hecho", color: "#97E7B9" },
      { value: "in_progress", label: "En Proceso", color: "#FBB446" },
      { value: "incomplete", label: "No Realizado", color: "#E91E63" },
      { value: "without_status", label: "Sin seguimiento", color: "rgb(250, 250, 70)" },
    ];
    setFollowOptions(options);
  }, [eventData]);

  const handleTypeChange = (selectedOption) => {
    setTemporalInformationFollow({
      ...temporalInformationFollow,
      follow: selectedOption.value,
    });
    setSelectedFollowOption(selectedOption);
  };
  const handleFindingCheckboxChange = (e) => {
    setTemporalInformationFollow({
      ...temporalInformationFollow,
      findingOption: e.target.checked,
    });
  };
  const handleDescriptionCheckboxChange = (e) => {
    setTemporalInformationFollow({
      ...temporalInformationFollow,
      descriptionOption: e.target.checked,
    });
  };
  const handleArchivesCheckboxChange = (e) => {
    setTemporalInformationFollow({
      ...temporalInformationFollow,
      fileOption: e.target.checked,
    });
  };

  const handleArchivesFileChange = (e) => {
    setTemporalInformationFollow({
      ...temporalInformationFollow,
      file: e.target.files[0],
    });
  };
  const handleCurrentUserCheckboxChange = (e) => {
    setTemporalInformationFollow({
      ...temporalInformationFollow,
      currentUserCheckOption: e.target.checked,
    });
  };
  const handleRememberUsersChange = (selectedOption) => {
    setTemporalInformationFollow({
      ...temporalInformationFollow,
      employeeResponseId: selectedOption.value,
    });
    setSelectedUserRemember(selectedOption);
    setUserProps(selectedOption.value)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="container-fluid">
      <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <label
                    className="form-check-label"
                    htmlFor="floatingInputGrid"
                    style={{ color: "#696969" }}
                  >
                    Seguimiento :
                  </label>
                  <Select
                    options={followOptions}
                    name="[type_id]"
                    value={
                      selectedFollowOption ? selectedFollowOption.value : ""
                    }
                    onChange={handleTypeChange}
                    placeholder={
                      selectedFollowOption && selectedFollowOption.label
                        ? selectedFollowOption.label
                        : "Selecciona un seguimiento"
                    }
                    formatOptionLabel={({ label }) => <span>{label}</span>}
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.data.color,
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={temporalInformationFollow.findingOption}
                      onChange={handleFindingCheckboxChange}
                      id="placeCheckbox"
                    />
                    <label className="form-check-label" htmlFor="placeCheckbox">
                      ¿Hallazgo?
                    </label>
                  </div>
                  <div className="col">
                    <input
                      placeholder="Ingrese Hallazgo"
                      type="text"
                      className="form-control"
                      id="title"
                      value={temporalInformationFollow.finding}
                      onChange={(e) =>
                        setTemporalInformationFollow({
                          ...temporalInformationFollow,
                          finding: e.target.value,
                        })
                      }
                      disabled={!temporalInformationFollow.findingOption}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={temporalInformationFollow.descriptionOption}
                      onChange={handleDescriptionCheckboxChange}
                      id="placeCheckbox"
                    />
                    <label className="form-check-label" htmlFor="placeCheckbox">
                      ¿Descripción de Evidencia?
                    </label>
                  </div>
                  <div className="col">
                    <input
                      placeholder="Ingrese Descripción"
                      type="text"
                      className="form-control"
                      id="title"
                      value={temporalInformationFollow.descriptionFollow}
                      onChange={(e) =>
                        setTemporalInformationFollow({
                          ...temporalInformationFollow,
                          descriptionFollow: e.target.value,
                        })
                      }
                      disabled={!temporalInformationFollow.descriptionOption}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={temporalInformationFollow.fileOption}
                      onChange={handleArchivesCheckboxChange}
                      id="archivesCheckbox"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="archivesCheckbox"
                    >
                      ¿Archivo adjunto?
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control-file"
                      id="attachmentFile"
                      onChange={handleArchivesFileChange}
                      disabled={!temporalInformationFollow.fileOption}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={temporalInformationFollow.currentUserCheckOption}
                      onChange={handleCurrentUserCheckboxChange}
                      id="rememberCheckbox"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="rememberCheckbox"
                    >
                      ¿Desea cambiar el responsable?
                    </label>
                  </div>
                  <div className="col">
                    <Select
                      options={optionsUsers}
                      name="[users_id]"
                      value={selectedUsersRemember}
                      onChange={handleRememberUsersChange}
                      placeholder="Selecciona un usuario"
                      formatOptionLabel={({ label }) => <span>{label}</span>}
                      isDisabled={
                        !temporalInformationFollow.currentUserCheckOption
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <h4>Responsable</h4>
              <div className="row align-items-center">
                <div className="col">
                  <img src={userInformation.profile_picture} />
                </div>
                <div className="col">
                  <h6>Nombre: {userInformation.full_name}</h6>
                  <h6>Posicion: {userInformation.position}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
