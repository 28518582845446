import { Controller } from 'stimulus'
import 'bootstrap/js/dist/modal'

export default class extends Controller {
  static targets= ['input']
  onChange () {
    const url = this.data.get('url')
    const maintenanceId = this.data.get('id')
    $.get(url+'?element_id='+maintenanceId+'&value='+$(this.inputTarget).prop('checked')).then((content) => {
      $('.maintenance'+maintenanceId).prop('checked', $(this.inputTarget).prop('checked'))
    })
  }
}
