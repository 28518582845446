import React, { useState, useEffect, useContext, useReducer } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Form,
  Button,
  Row,
  Col,
  InputGroup,
  ButtonGroup,
  Navbar,
  Fade,
  Badge,
  Dropdown,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faArrowLeft,
  faFileLines,
  faPlus,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import {
  FLASH_TYPE_ERROR,
  FLASH_TYPE_NOTICE,
  useFlash,
} from "./Context/FlashContext";
import { AppContext } from "./Context/AppContext";
import LoaderPagination from "./Skeleton/LoaderPagination";
import Uploader from "./Uploader";
import Header from "./Header";
import { FlashErrors } from "./Flash/FlashAlerts";
import errorFieldMapInvestigaction from "../utils/errorsInvestigation.json";

const UpdateInvestigations = () => {
  const [open, setOpen] = useState(true);
  const [usersApi, setUsersApi] = useState([]);
  const [troubleType, setTroubleType] = useState([]);
  const [assignmentsAttributes, setAssignmentAttributes] = useState([]);
  const [formData, setFormData] = useState({
    trouble_type_id: "",
    location_description: "",
    area: "",
    cause: "",
    control_event: "true",
    media_type: "",
    description_type: "",
    environmental_report: "false",
    description_environmental: "",
    correction: "",
    corrective_action: "false",
    approve_id: "",
    checkout_id: "",
    elaborates_id: "",
    workflow_state: "",
    file: "",
    assignments_attributes: [],
  });
  const { troubleId, id } = useParams();
  const navigate = useNavigate();

  const { loading, setLoading } = useContext(AppContext);

  const { setFlash } = useFlash();

  useEffect(() => {
    fetchUsersApi();
  }, []);

  const fetchUsersApi = async () => {
    try {
      const response = await axios.get("/api/v2/users");
      if (response.status === 200) {
        const data = await response.data;
        setUsersApi(data);
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  useEffect(() => {
    fetchTroubleTypeApi();
  }, []);

  const fetchTroubleTypeApi = async () => {
    try {
      const response = await axios.get("/api/v2/trouble_types");
      if (response.status === 200) {
        const data = await response.data;
        setTroubleType(data);
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value, id: buttonId } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      buttonId,
    }));
  };

  useEffect(() => {
    fetchTroubleInvestigation();
  }, []);

  const fetchTroubleInvestigation = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/api/v2/troubles/${troubleId}/investigations/${id}`
      );
      if (response.status === 200) {
        const data = await response.data;
        setFormData(data);
        setAssignmentAttributes(
          data.assignments.map((assignment) => ({
            id: assignment.id,
            action: assignment.action,
            user_id: assignment.user_id,
          }))
        );
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      setFlash({
        type: FLASH_TYPE_ERROR,
        message: "Ocurrio un error en el servidor",
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = (file) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      file: file,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const url = `/api/v2/troubles/${troubleId}/investigations/${id}`;
    const payload = formData;
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    if (!payload.file) {
      delete payload.file;
    }

    payload.assignments_attributes = assignmentsAttributes;

    try {
      const response = await axios.put(url, payload, {
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        setFlash({
          type: FLASH_TYPE_NOTICE,
          message: "Elemento Actualizado",
        });
        navigate(`/troubles/${troubleId}/investigations`);
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      let responseError = error.response.data;

      let errorMessages = {};
      for (const field in responseError) {
        if (errorFieldMapInvestigaction[field]) {
          errorMessages[field] = `${errorFieldMapInvestigaction[field]}`;
        }
      }

      setFlash({
        type: FLASH_TYPE_ERROR,
        message: Object.entries(errorMessages)
          .map(([key, value]) => `${value}`)
          .join("\n"),
      });
    }
  };

  const handleAddUserClick = (action) => () => {
    setAssignmentAttributes((old) => {
      return [...old, { user_id: "", action: action }];
    });

    setOpen(true);
  };

  const updateAssingmentAttribute = (index, user) => (event) => {
    setAssignmentAttributes((old) => {
      return [
        ...old.slice(0, index),
        {
          id: user.id,
          user_id: event.target.value,
          action: user.action,
        },
        ...old.slice(index + 1),
      ];
    });
  };

  const handleRemoveUserClick = (index, user) => {
    setAssignmentAttributes((old) => {
      return [
        ...old.slice(0, index),
        {
          id: user.id,
          user_id: user.user_id,
          action: user.action,
          _destroy: "true",
        },
        ...old.slice(index + 1),
      ];
    });
  };
  const fileSummary = formData.file_summary;
  const [userFiltersReview, dispatchUserFiltersReview] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {}
  );
  const [userFiltersProduce, dispatchUserFiltersProduce] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {}
  );

  useEffect(() => {
    fetchUsersApi();
  }, []);

  return (
    <>
      <Header />
      <Navbar className="bg-light border border-secondary">
        <Link to={`/troubles/${troubleId}/investigations`}>
          <Button variant="light">
            <FontAwesomeIcon icon={faArrowLeft} color="#438dff" />
          </Button>
        </Link>
        <Navbar.Brand className=" d-flex " style={{ gap: "5px" }}>
          <span>Editar investigación</span>
        </Navbar.Brand>
      </Navbar>

      {loading ? (
        <LoaderPagination />
      ) : (
        <Form onSubmit={handleSubmit} className="px-3 pt-3">
          <FlashErrors />

          <Form.Group controlId="troubleType">
            <Form.Label>Tipo de evento</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faFileLines} />
              </InputGroup.Text>
              <Form.Control
                as="select"
                name="trouble_type_id"
                value={formData.trouble_type_id}
                onChange={handleInputChange}
              >
                <option value="">Selecciona un evento</option>
                {troubleType.map((troubleTp) => (
                  <option key={troubleTp.id} value={troubleTp.id}>
                    {troubleTp.name}
                  </option>
                ))}
              </Form.Control>
            </InputGroup>
          </Form.Group>

          <Form.Label>Descripción de evento</Form.Label>
          <Col className=" pl-4 ">
            <Form.Group
              controlId="locationDescription"
              className="d-flex align-items-center"
              style={{ gap: "10px" }}
            >
              <Form.Label>Ubicación</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faFileLines} />
                </InputGroup.Text>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="location_description"
                  value={formData.location_description}
                  onChange={handleInputChange}
                  placeholder="FUENTE DE DATOS(*)"
                />
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="area" className="d-flex align-items-center">
              <Form.Label>
                Determinación de áreas afectadas (terrenos, recursos naturales,
                instalaciones)
              </Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faFileLines} />
                </InputGroup.Text>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="area"
                  value={formData.area}
                  onChange={handleInputChange}
                  placeholder="FUENTE DE DATOS(*)"
                />
              </InputGroup>
            </Form.Group>
            <Form.Group
              controlId="cause"
              className="d-flex align-items-center "
            >
              <Form.Label style={{ width: "15%" }}>
                Causa probable del evento
              </Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faFileLines} />
                </InputGroup.Text>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="cause"
                  value={formData.cause}
                  onChange={handleInputChange}
                  placeholder="FUENTE DE DATOS(*)"
                />
              </InputGroup>
            </Form.Group>
            <Form.Group
              controlId="controlEvent"
              className="d-flex align-items-start "
            >
              <Form.Label style={{ width: "35%" }}>
                Se logró controlar el evento con los medios disponibles
              </Form.Label>
              <InputGroup>
                <div className="px-2">
                  <Form.Check
                    type="radio"
                    label={"Si"}
                    name="control_event"
                    value={"true"}
                    checked={formData.control_event === "true"}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="px-2">
                  <Form.Check
                    type="radio"
                    label={"No"}
                    name="control_event"
                    value={"false"}
                    checked={formData.control_event === "false"}
                    onChange={handleInputChange}
                  />
                </div>
              </InputGroup>
            </Form.Group>
            <Form.Group
              controlId="mediaType"
              className="d-flex align-items-center "
            >
              <Form.Label style={{ width: "35%" }}>
                ¿Con que tipo de medios se puede atender el evento?
              </Form.Label>
              <InputGroup className=" d-flex  align-items-center ">
                <div className="px-2">
                  <Form.Check
                    type="radio"
                    label={"Internos"}
                    name="media_type"
                    value={"Internos"}
                    checked={formData.media_type === "Internos"}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="px-2">
                  <Form.Check
                    type="radio"
                    label={"Externos"}
                    name="media_type"
                    value={"Externos"}
                    checked={formData.media_type === "Externos"}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="px-2 w-100">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description_type"
                    value={formData.description_type}
                    onChange={handleInputChange}
                    placeholder="¿Cuáles?_________"
                  />
                </div>
              </InputGroup>
            </Form.Group>
            <Form.Group
              controlId="environmentalReport"
              className="d-flex align-items-start "
            >
              <Form.Label style={{ width: "35%" }}>
                Requiere reporte a la autoridad ambiental
              </Form.Label>
              <InputGroup className=" d-flex  align-items-center ">
                <div className="px-2">
                  <Form.Check
                    type="radio"
                    label={"Si"}
                    name="environmental_report"
                    value={"true"}
                    checked={formData.environmental_report === "true"}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="px-2">
                  <Form.Check
                    type="radio"
                    label={"No"}
                    name="environmental_report"
                    value={"false"}
                    checked={formData.environmental_report === "false"}
                    onChange={handleInputChange}
                  />
                </div>
                {formData.environmental_report === "true" && (
                  <div className="px-2 w-100">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="description_environmental"
                      value={formData.description_environmental}
                      onChange={handleInputChange}
                      placeholder="¿Cuáles?_________"
                    />
                  </div>
                )}
              </InputGroup>
            </Form.Group>
            <Form.Group
              controlId="correction"
              className="d-flex align-items-center "
            >
              <Form.Label style={{ width: "15%" }}>
                Corrección que se realizó
              </Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faFileLines} />
                </InputGroup.Text>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="correction"
                  value={formData.correction}
                  onChange={handleInputChange}
                  placeholder="FUENTE DE DATOS(*)"
                />
              </InputGroup>
            </Form.Group>
            <Form.Group
              controlId="correctiveAction"
              className="d-flex align-items-end "
            >
              <Form.Label style={{ width: "35%" }}>
                ¿Se requiere acción correctiva?
              </Form.Label>
              <InputGroup className=" d-flex  align-items-center ">
                <div className="px-2">
                  <Form.Check
                    type="radio"
                    label={"Si"}
                    name="corrective_action"
                    value={"true"}
                    checked={formData.corrective_action === "true"}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="px-2">
                  <Form.Check
                    type="radio"
                    label={"No"}
                    name="corrective_action"
                    value={"false"}
                    checked={formData.corrective_action === "false"}
                    onChange={handleInputChange}
                  />
                </div>
              </InputGroup>
            </Form.Group>
            <Row className=" pb-5 ">
              <Col className=" d-flex flex-column ">
                <Form.Label>Elabora</Form.Label>
                <div id="desplegables-container">
                  {assignmentsAttributes.map((user, index) => {
                    if (user.action !== "produce") {
                      return null;
                    }

                    const filteredUsersList = usersApi.results
                      ?.filter((u) =>
                        u.name
                          .toLowerCase()
                          .includes(userFiltersProduce[index]?.toLowerCase())
                      )
                      .map((u, i) => (
                        <Dropdown.Item
                          key={`creator-select-${u.user_id}-${i}`}
                          onClick={() =>
                            updateAssingmentAttribute(
                              index,
                              user
                            )({
                              target: { value: u.user_id },
                            })
                          }
                        >
                          {u.name}
                        </Dropdown.Item>
                      ));

                    const displayList = userFiltersProduce[index] ? (
                      filteredUsersList.length > 0 ? (
                        filteredUsersList
                      ) : (
                        <div style={{ padding: "10px" }}>
                          <span>No hay resultados</span>
                        </div>
                      )
                    ) : (
                      usersApi.results?.map((u, i) => (
                        <Dropdown.Item
                          key={`creator-select-${u.user_id}-${i}`}
                          onClick={() =>
                            updateAssingmentAttribute(
                              index,
                              user
                            )({
                              target: { value: u.user_id },
                            })
                          }
                        >
                          {u.name}
                        </Dropdown.Item>
                      ))
                    );

                    return (
                      <Fade
                        in={open}
                        key={`creator-${user.id}-${index}`}
                        timeout={200}
                      >
                        <Form.Group controlId={`userId-${index}`}>
                          <Dropdown>
                            <Dropdown.Toggle
                              id={`dropdown-${user.id}-${index}`}
                              style={{
                                width: "90%",
                                backgroundColor: "transparent",
                                color: "black",
                                borderColor: "black",
                              }}
                            >
                              {user.user_id
                                ? usersApi.results?.find(
                                    (u) => u.user_id === user.user_id
                                  )?.name
                                : "Selecciona un Usuario"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ width: "90%" }}>
                              <input
                                type="text"
                                placeholder="Filtrar usuarios"
                                value={userFiltersProduce[index] || ""}
                                onChange={(e) =>
                                  dispatchUserFiltersProduce({
                                    [index]: e.target.value,
                                  })
                                }
                                style={{ width: "100%", marginBottom: "10px" }}
                              />
                              {displayList}
                            </Dropdown.Menu>
                            <Button
                              variant="danger"
                              onClick={() => handleRemoveUserClick(index)}
                              style={{ marginLeft: "2px" }}
                            >
                              <FontAwesomeIcon icon={faClose} />
                            </Button>
                          </Dropdown>
                        </Form.Group>
                      </Fade>
                    );
                  })}
                </div>

                <Button
                  onClick={handleAddUserClick("produce")}
                  aria-controls="example-fade-text"
                  aria-expanded={open}
                >
                  <FontAwesomeIcon icon={faPlus} className="pr-2" />
                  Agregar
                </Button>
              </Col>
              <Col className=" d-flex flex-column ">
                <Form.Label>Revisa</Form.Label>
                <div id="desplegables-container">
                  {assignmentsAttributes.map((user, index) => {
                    if (user.action !== "review") {
                      return null;
                    }

                    const filteredUsersList = usersApi.results
                      ?.filter((u) =>
                        u.name
                          .toLowerCase()
                          .includes(userFiltersReview[index]?.toLowerCase())
                      )
                      .map((u, i) => (
                        <Dropdown.Item
                          key={`creator-select-${u.user_id}-${i}`}
                          onClick={() =>
                            updateAssingmentAttribute(
                              index,
                              user
                            )({
                              target: { value: u.user_id },
                            })
                          }
                        >
                          {u.name}
                        </Dropdown.Item>
                      ));

                    const displayList = userFiltersReview[index] ? (
                      filteredUsersList.length > 0 ? (
                        filteredUsersList
                      ) : (
                        <div style={{ padding: "10px" }}>
                          <span>No hay resultados</span>
                        </div>
                      )
                    ) : (
                      usersApi.results?.map((u, i) => (
                        <Dropdown.Item
                          key={`creator-select-${u.user_id}-${i}`}
                          onClick={() =>
                            updateAssingmentAttribute(
                              index,
                              user
                            )({
                              target: { value: u.user_id },
                            })
                          }
                        >
                          {u.name}
                        </Dropdown.Item>
                      ))
                    );
                    return (
                      <Fade
                        in={open}
                        key={`creator-${user.id}-${index}`}
                        timeout={200}
                      >
                        <Form.Group controlId={`userId-${index}`}>
                          <Dropdown>
                            <Dropdown.Toggle
                              id={`dropdown-${user.id}-${index}`}
                              style={{
                                width: "90%",
                                backgroundColor: "transparent",
                                color: "black",
                                borderColor: "black",
                              }}
                            >
                              {user.user_id
                                ? usersApi.results?.find(
                                    (u) => u.user_id === user.user_id
                                  )?.name
                                : "Selecciona un Usuario"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ width: "90%" }}>
                              <input
                                type="text"
                                placeholder="Filtrar usuarios"
                                value={userFiltersReview[index] || ""}
                                onChange={(e) =>
                                  dispatchUserFiltersReview({
                                    [index]: e.target.value,
                                  })
                                }
                                style={{ width: "100%", marginBottom: "10px" }}
                              />
                              {displayList}
                            </Dropdown.Menu>
                            <Button
                              variant="danger"
                              onClick={() => handleRemoveUserClick(index)}
                              style={{ marginLeft: "2px" }}
                            >
                              <FontAwesomeIcon icon={faClose} />
                            </Button>
                          </Dropdown>
                        </Form.Group>
                      </Fade>
                    );
                  })}
                </div>
                <Button
                  onClick={handleAddUserClick("review")}
                  aria-controls="example-fade-text"
                  aria-expanded={open}
                >
                  <FontAwesomeIcon icon={faPlus} className="  pr-2 " />
                  Agregar
                </Button>
              </Col>{" "}
            </Row>
          </Col>
          {fileSummary &&
          /\.(jpg|png|jpeg|gif|svg)$/i.test(fileSummary.name) ? (
            <Uploader
              onChange={handleFileUpload}
              value={formData.file_summary}
              id={id}
              url={fileSummary?.url}
            />
          ) : (
            <Uploader
              onChange={handleFileUpload}
              value={formData.file_summary}
              id={id}
              url={`/api/v2/troubles/${troubleId}/investigations/controlled_copy_investigations.${id}`}
            />
          )}

          <ButtonGroup className="d-flex pt-5">
            <Link to={`/troubles/${troubleId}/investigations`}>
              <Button className="btn btn-primary mx-1" variant="primary">
                Cancelar
              </Button>
            </Link>

            <Button
              className="btn btn-primary mx-1"
              type="submit"
              variant="primary"
            >
              Actualizar
            </Button>
          </ButtonGroup>
        </Form>
      )}
    </>
  );
};

export default UpdateInvestigations;
