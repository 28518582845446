import React from "react";
import { AdminLaboratoriesContext } from "../AdminLaboratoriesContext";
import { AssignmentUsers } from "../AssignmentUsers";
import { InputGroup } from "../../../InputGroup";

function Modal(){
  const{
    setOpenModal,
    createAdminLaboratory,
    errorResponse,
    adminLaboratory,
    dataEditAdmin,
    updateAdminLaboratory
  } = React.useContext(AdminLaboratoriesContext)

  if (dataEditAdmin?.assignments){
    dataEditAdmin.assignments.map(assignment=>{
      return{
        ...assignment,
        value: assignment.user_name.value,
        label: assignment.user_name.label
      }
    })
  }
  const [timeAnswer, setTimeAnswer] = React.useState(dataEditAdmin?.answer_time || '')
  const [adminUser, setAdminUser] = React.useState(dataEditAdmin?.assignments?.map(assignment => {
    return{
      ...assignment,
      value: assignment.user_name.value,
      label: assignment.user_name.label,
      typeSendData: 'edit'
    }
  }) || []);
  const [color, setColor] = React.useState(dataEditAdmin?.color || '')
  const [disabledSubmit, setDisabledSubmit] = React.useState(false)
  const onCancel = () => {
    setOpenModal(false);
  }
  const onSubmit = (e) =>{
    e.preventDefault();
    setDisabledSubmit(true);
    const newDataAdminLaboratories ={ 
      admin_laboratory: {
        answer_time: timeAnswer,
        color: color,
        assignments_attributes: adminUser.map((user) =>({
          id: '',
          action: user.action,
          user_id: user.user_id,
          assignable_type: user.assignable_type,
          _destroy: false
        }))

      }
    }
    setTimeout(() => {
      setDisabledSubmit(false);
    }, 4000);
    createAdminLaboratory(newDataAdminLaboratories)
  }

  const onUpdate = (e) => {
    e.preventDefault();
    setDisabledSubmit(true);
    const updateDataAdminLaboratories ={ 
      admin_laboratory: {
        answer_time: timeAnswer,
        color: color,
        assignments_attributes: adminUser.map((user) =>({
          id: user.typeSendData == 'create' ? '' : user.id,
          action: user.action,
          user_id: user.user_id,
          assignable_type: user.assignable_type,
          _destroy: user._destroy
        }))

      }
    }
    setTimeout(() => {
      setDisabledSubmit(false);
    }, 4000);
    updateAdminLaboratory(dataEditAdmin.id, updateDataAdminLaboratories)
  }
  return(
    <div className="modal fade show" tabIndex="-1" style={{ paddingRight: '4px', display: 'block', backgroundColor: 'rgba(3, 3, 3, 0.5)' }} role="document">
      <form onSubmit={adminLaboratory.length > 0 ? onUpdate : onSubmit }>
        <div className="modal-dialog modal-dialog-scrollable" role="document" style={{ maxWidth: '700px' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Editar administración de laboratorio</h5>
            </div>
            <div className="modal-body">
              {errorResponse.length > 0 && (
                <div className="alert alert-danger" role="alert">
                  <ul>
                    {errorResponse.map((error, index) => (
                      <li key={index} style={{ color: 'black' }}>
                        {error}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-file-alt"></i>
                    </span>
                  </div>
                  <input
                    type="number"
                    placeholder="Tiempo de respuesta"
                    className="form-control"
                    value={timeAnswer}
                    onChange={(e) => setTimeAnswer(e.target.value)}
                  />
                </div>
              </div>
              <InputGroup iconClass="fas fa-user" label="Color en formulario de solicitud y pantalla principal">
                <input
                  type="color"
                  className="form-control"
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                />
              </InputGroup>
              <AssignmentUsers
                key={adminUser}
                adminUser={adminUser}
                setAdminUser={setAdminUser}
                removeAssigmentAdmin
              />
            </div>
            
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-link'
                onClick={onCancel}
              >
                Cancelar
              </button>
              <button
                className='btn btn-primary mr-1'
                type="submit"
                disabled={disabledSubmit}
              >
                Crear
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
export {Modal}