import React, { useState, useContext, useEffect, useMemo } from "react";
import GlobalContext from "../context/GlobalContext";
import DatePicker from "react-datepicker";
import Select from "react-select";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";

export default function ModalFormEdit({
  temporalInformation,
  setTemporalInformation,
}) {
  const { eventEditId } = useContext(GlobalContext);
  const [eventData, setEventData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [typeOptions, setTypeOptions] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [selectedDateEnd, setSelectedDateEnd] = useState(null);
  const [periodicities, setPeriodicities] = useState([]);
  const [optionsPeriodicities, setOptionsPeriodicities] = useState([]);
  const [selectedPeriodicities, setSelectedPeriodicities] = useState();
  const [selectedDateRemember, setSelectedDateRemember] = useState(null);
  const [procedures, setProcedures] = useState([]);
  const [optionsProcedures, setOptionsProcedures] = useState([]);
  const [selectedProcedures, setSelectedProcedures] = useState();
  const [optionsManagementSystem, setOptionsManagementSystem] = useState([]);
  const [selectedManagementSystem, setSelectedManagementSystem] = useState();
  const [users, setUsers] = useState([]);
  const [optionsUsers, setOptionsUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedUsersRemember, setSelectedUserRemember] = useState([]);
  const [organizer, setOrganizer] = useState();

  useEffect(() => {
    fetch(`/api/v2/events/${eventEditId}`)
      .then((response) => response.json())
      .then((data) => {
        setEventData(data);
      })
      .catch((error) => {
        console.error("Error en la petición de eventos:", error);
      });
  }, []);

  useEffect(() => {
    Promise.all([
      fetch("/api/v2/users/").then((response) => response.json()),
      fetch("/api/v2/periodicities/").then((response) => response.json()),
      fetch("/api/v2/procedures/").then((response) => response.json()),
    ])
      .then(([usersData, periodicitiesData, proceduresData]) => {
        setUsers(usersData.results);
        setPeriodicities(periodicitiesData);
        setProcedures(proceduresData);
      })
      .catch((error) => {
        console.error(
          "Error en la petición de usuarios, periodicidades o procedimientos:",
          error
        );
      });
  }, [eventData]);

  useEffect(() => {
    const optionPeriodicities = periodicities.map((periodicities) => ({
      value: periodicities.id,
      label: periodicities.name,
    }));
    setOptionsPeriodicities(optionPeriodicities);
    // Actualizar el valor seleccionado en base a eventData.type
    if (eventData && eventData.event_type !== undefined) {
      const selected = optionPeriodicities.find(
        (option) => option.value === eventData.periodicity_id
      );
      setSelectedPeriodicities(selected);
    }
  }, [periodicities]);

  useEffect(() => {
    const optionUsers = users.map((user) => ({
      value: user.id,
      label: user.name,
    }));
    setOptionsUsers(optionUsers);
    if (eventData && eventData.employee_id !== undefined) {
      const selected = optionsUsers.find(
        (option) => option.value === eventData.employee_id
      );
      setSelectedUser(selected);
    }
  }, [users]);

  useEffect(() => {
    const optionProcedure = procedures.map((procedures) => ({
      value: procedures.id,
      label: procedures.title,
    }));
    setOptionsProcedures(optionProcedure);
    if (eventData && eventData.procedure_id !== undefined) {
      const selected = optionsProcedures.find(
        (option) => option.value === eventData.procedure_id
      );
      setSelectedProcedures(selected);
    }
  }, [procedures]);

  useEffect(() => {
    const options = [
      { value: true, label: "Evento" },
      { value: false, label: "Tarea" },
    ];
    setTypeOptions(options);
    // Actualizar el valor seleccionado en base a eventData.type
    if (eventData && eventData.event_type !== undefined) {
      const selected = options.find(
        (option) => option.value === eventData.event_type
      );
      setSelectedType(selected);
    }
  }, [eventData]);

  useEffect(() => {
    if (eventData && eventData.start_date) {
      const formattedDate = dayjs(eventData.start_date).toDate();
      setSelectedDate(formattedDate);
    }
    if (eventData && eventData.end_date) {
      const formattedDateEnd = dayjs(eventData.end_date).toDate();
      setSelectedDateEnd(formattedDateEnd);
    }
    if (eventData && eventData.reminder_date) {
      const formattedDate = dayjs(eventData.reminder_date).toDate();
      setSelectedDateRemember(formattedDate);
    }
    if (eventData && eventData.place) {
      setTemporalInformation((prevInformation) => ({
        ...prevInformation,
        placeOption: true,
        place: eventData.place,
      }));
    }
    if (eventData && eventData.link) {
      setTemporalInformation((prevInformation) => ({
        ...prevInformation,
        linkOption: true,
        link: eventData.link,
      }));
    }
    if (eventData && eventData.description) {
      setTemporalInformation((prevInformation) => ({
        ...prevInformation,
        descriptionOption: true,
        description: eventData.description,
      }));
    }
    // if (eventData.rememberUsers.length > 0) {
    //   setTemporalInformation((prevInformation) => ({
    //     ...prevInformation,
    //     remember: true,
    //   }));
    // }
  }, [eventData]);

  useEffect(() => {
    const options = [
      { value: "quality", label: "Calidad", color: "#007BFF" },
      { value: "sst", label: "SST", color: "#FFEB3B" },
      { value: "environmental", label: "Ambiental", color: "#C8E6C9" },
      { value: "no_one", label: "Ninguno", color: "#E0E0E0" },
    ];
    setOptionsManagementSystem(options);
    if (eventData && eventData.management_system !== undefined) {
      const selected = options.find(
        (option) => option.value === eventData.management_system
      );
      setSelectedManagementSystem(selected);
    }
  }, [eventData]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleTypeChange = (selectedOption) => {
    setTemporalInformation({
      ...temporalInformation,
      type: selectedOption.value,
    });
    setSelectedType(selectedOption);
  };

  const handleDateChange = (value) => {
    setTemporalInformation({
      ...temporalInformation,
      day: dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
    });
    setSelectedDate(value);
  };

  const handleOutsideClick = () => {
    setSelectedDate(selectedDate);
  };

  const handleDateEndChange = (value) => {
    setTemporalInformation({
      ...temporalInformation,
      dayEnd: dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
    });
    setSelectedDateEnd(value);
  };

  const handleEndOutsideClick = () => {
    setSelectedDateEnd(selectedDateEnd);
  };

  const handleDateRememberChange = (value) => {
    setTemporalInformation({
      ...temporalInformation,
      rememberDate: dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
    });
    setSelectedDateRemember(value);
  };

  const handleRememberOutsideClick = () => {
    setSelectedDateRemember(selectedDateEnd);
  };

  const handlePeriocity = (selectedOption) => {
    setTemporalInformation({
      ...temporalInformation,
      periocityTipe: selectedOption.value,
    });
    setSelectedPeriodicities(selectedOption);
  };

  const handlePlaceCheckboxChange = (e) => {
    setTemporalInformation({
      ...temporalInformation,
      placeOption: e.target.checked,
    });
  };

  const handleLinkCheckboxChange = (e) => {
    setTemporalInformation({
      ...temporalInformation,
      linkOption: e.target.checked,
    });
  };

  const handleArchivesCheckboxChange = (e) => {
    setTemporalInformation({
      ...temporalInformation,
      archivesOption: e.target.checked,
    });
  };

  const handleArchivesFileChange = (e) => {
    setTemporalInformation({
      ...temporalInformation,
      archives: e.target.files[0],
    });
  };

  const handleDescriptionCheckboxChange = (e) => {
    setTemporalInformation({
      ...temporalInformation,
      descriptionOption: e.target.checked,
    });
  };

  const handleDescriptionChange = (e) => {
    setTemporalInformation({
      ...temporalInformation,
      description: e.target.value,
    });
  };

  const handleRememberCheckboxChange = (e) => {
    setTemporalInformation({
      ...temporalInformation,
      remember: e.target.checked,
    });
  };

  const handleRememberUsersChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedUserRemember((prevSelectedUsers) => [
        ...prevSelectedUsers,
        selectedOption,
      ]);
      setTemporalInformation((prevTemporalInformation) => ({
        ...prevTemporalInformation,
        rememberUsers: [
          ...prevTemporalInformation.rememberUsers,
          selectedOption.value,
        ],
      }));
    } else {
      setSelectedUserRemember((prevSelectedUsers) =>
        prevSelectedUsers.filter((user) => user.value !== selectedOption.value)
      );
      setTemporalInformation((prevTemporalInformation) => ({
        ...prevTemporalInformation,
        rememberUsers: prevTemporalInformation.rememberUsers.filter(
          (user) => user !== selectedOption.value
        ),
      }));
    }
  };

  const handleOrganizerChange = (selectedOption) => {
    setTemporalInformation({
      ...temporalInformation,
      organizer: selectedOption.value,
    });
    setSelectedUser(selectedOption);
  };

  const handleProcedures = (selectedOption) => {
    setTemporalInformation({
      ...temporalInformation,
      procedures: selectedOption.value,
    });
    setSelectedProcedures(selectedOption);
  };

  const handleManagementSystem = (selectedOption) => {
    setTemporalInformation({
      ...temporalInformation,
      associatedManagementSystem: selectedOption.value,
    });
    setSelectedManagementSystem(selectedOption);
  };

  return (
    <div className="container-fluid">
      <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <label
                    className="form-check-label"
                    htmlFor="floatingInputGrid"
                    style={{ color: "#696969" }}
                  >
                    Titulo :
                  </label>
                  <input
                    placeholder="Titulo"
                    type="text"
                    className="form-control"
                    id="title"
                    value={eventData ? eventData.title : ""}
                    onChange={(e) =>
                      setTemporalInformation({
                        ...temporalInformation,
                        title: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <label
                    className="form-check-label"
                    htmlFor="floatingInputGrid"
                    style={{ color: "#696969" }}
                  >
                    Tipo :
                  </label>
                  <Select
                    options={typeOptions}
                    name="[type_id]"
                    value={selectedType ? selectedType.value : ""}
                    onChange={handleTypeChange}
                    placeholder={
                      selectedType && selectedType.label
                        ? selectedType.label
                        : "Selecciona un tipo"
                    }
                    formatOptionLabel={({ label }) => <span>{label}</span>}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <label
                    className="form-check-label"
                    htmlFor="floatingInputGrid"
                    style={{ color: "#696969" }}
                  >
                    Hora inicio:
                  </label>
                  <DatePicker
                    selected={selectedDate}
                    withPortal
                    onChange={handleDateChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    onClickOutside={handleOutsideClick}
                    dateFormat="dd/MM/yyyy HH:mm"
                    className="form-control custom-datepicker"
                    placeholderText="Fecha y hora de inicio"
                    style={{ color: "#000" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <label
                    className="form-check-label"
                    htmlFor="floatingInputGrid"
                    style={{ color: "#696969" }}
                  >
                    Hora Final:
                  </label>
                  <DatePicker
                    selected={selectedDateEnd}
                    onChange={handleDateEndChange}
                    withPortal
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    onClickOutside={handleEndOutsideClick}
                    dateFormat="dd/MM/yyyy HH:mm"
                    className="form-control custom-datepicker"
                    placeholderText="Fecha y hora Final"
                    style={{ color: "#000" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="form-floating">
                <label
                  className="form-check-label align-items-center"
                  htmlFor="floatingInputGrid"
                  style={{ color: "#696969" }}
                >
                  Periodicidad :
                </label>
                <Select
                  options={optionsPeriodicities}
                  name="[periodicities_id]"
                  value={selectedPeriodicities}
                  onChange={handlePeriocity}
                  placeholder={
                    selectedPeriodicities
                      ? selectedPeriodicities.label
                      : "periodicidad"
                  }
                  formatOptionLabel={({ label }) => <span>{label}</span>}
                />
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={temporalInformation.placeOption}
                      onChange={handlePlaceCheckboxChange}
                      id="placeCheckbox"
                    />
                    <label className="form-check-label" htmlFor="placeCheckbox">
                      ¿Lugar?
                    </label>
                  </div>
                  <div className="col">
                    <input
                      placeholder="Ingrese lugar"
                      type="text"
                      className="form-control"
                      id="title"
                      value={temporalInformation.place}
                      onChange={(e) =>
                        setTemporalInformation({
                          ...temporalInformation,
                          place: e.target.value,
                        })
                      }
                      disabled={!temporalInformation.placeOption}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={temporalInformation.linkOption}
                      onChange={handleLinkCheckboxChange}
                      id="linkCheckbox"
                    />
                    <label className="form-check-label" htmlFor="placeCheckbox">
                      ¿Link?
                    </label>
                  </div>
                  <div className="col">
                    <input
                      placeholder="Ingrese el link"
                      type="text"
                      className="form-control"
                      id="link"
                      value={temporalInformation.link}
                      onChange={(e) =>
                        setTemporalInformation({
                          ...temporalInformation,
                          link: e.target.value,
                        })
                      }
                      disabled={!temporalInformation.linkOption}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={temporalInformation.descriptionOption}
                      onChange={handleDescriptionCheckboxChange}
                      id="descriptionCheckbox"
                    />
                    <label className="form-check-label" htmlFor="placeCheckbox">
                      ¿Descripcion?
                    </label>
                  </div>
                  <div className="col">
                    <input
                      placeholder="Ingrese la descripcion"
                      type="text"
                      className="form-control"
                      id="description"
                      value={temporalInformation.description}
                      onChange={handleDescriptionChange}
                      disabled={!temporalInformation.descriptionOption}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={temporalInformation.remember}
                      onChange={handleRememberCheckboxChange}
                      id="rememberCheckbox"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="rememberCheckbox"
                    >
                      ¿recordatorio?
                    </label>
                  </div>
                  <div className="col">
                    <label
                      className="form-check-label"
                      htmlFor="rememberCheckbox"
                    >
                      Agrega los invitados
                    </label>
                    {selectedUsersRemember &&
                      selectedUsersRemember.map((user) => (
                        <label className="col" key={user.value}>
                          {user.label}
                        </label>
                      ))}
                    <Select
                      options={optionsUsers}
                      name="[users_id]"
                      value={selectedUsersRemember}
                      onChange={handleRememberUsersChange}
                      placeholder="Selecciona un usuario"
                      formatOptionLabel={({ label }) => <span>{label}</span>}
                      isDisabled={!temporalInformation.remember}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <label
                    className="form-check-label"
                    htmlFor="floatingInputGrid"
                    style={{ color: "#696969" }}
                  >
                    Hora Del recordatorio:
                  </label>
                  <DatePicker
                    selected={selectedDateRemember}
                    onChange={handleDateRememberChange}
                    showTimeSelect
                    withPortal
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    onClickOutside={handleRememberOutsideClick}
                    className="form-control custom-datepicker"
                    dateFormat="dd/MM/yyyy HH:mm"
                    placeholderText="Fecha y hora del recordatorio"
                    style={{ color: "#000" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <label
                    className="form-check-label"
                    htmlFor="floatingInputGrid"
                    style={{ color: "#696969" }}
                  >
                    Proceso asociado :
                  </label>
                  <Select
                    options={optionsProcedures}
                    name="[optionsProcedures_id]"
                    value={selectedProcedures}
                    onChange={handleProcedures}
                    placeholder={
                      selectedProcedures
                        ? selectedProcedures.label
                        : "Selecciona el sistema"
                    }
                    formatOptionLabel={({ label }) => <span>{label}</span>}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <label
                    className="form-check-label"
                    htmlFor="floatingInputGrid"
                    style={{ color: "#696969" }}
                  >
                    Sistema de Gestión Asociado :
                  </label>
                  <Select
                    options={optionsManagementSystem}
                    name="[value]"
                    value={selectedManagementSystem}
                    onChange={handleManagementSystem}
                    placeholder={
                      selectedManagementSystem
                        ? selectedManagementSystem.label
                        : "Selecciona un sistema"
                    }
                    formatOptionLabel={({ label }) => <span>{label}</span>}
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.data.color,
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <label
                    className="form-check-label"
                    htmlFor="rememberCheckbox"
                  >
                    ¿Organizador?
                  </label>
                  <Select
                    options={optionsUsers}
                    name="[users_id]"
                    value={selectedUser}
                    onChange={handleOrganizerChange}
                    placeholder={
                      selectedUser
                        ? selectedUser.label
                        : "Selecciona un usuario"
                    }
                    formatOptionLabel={({ label }) => <span>{label}</span>}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
