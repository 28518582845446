import React, { useState, useEffect } from 'react'

const NonconformingSummary = (props) => {
  const [procedures, setProcedures] = useState([]);
  const [nonConformings, setNonConformings] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [NonconformingId, setNonconformingId] = useState('');
  const [ResponsibleId, setResponsibleId] = useState('');
  const [WorkflowId, setWorkflowId] = useState('');
  const [endDate, setEndDate] = useState('');
  const [counters, setCounters] = useState([]);
  const [abilities, setAbilities] = useState([]);
  const [abilitiesNonRepeat, setAbilitiesNonRepeat] = useState([]);

  useEffect(() => {
    if (props.start_date === null) {
      setStartDate('');
    } else {
      setStartDate(props.start_date)
    }
    if (props.end_date === null) {
      setEndDate('');
    } else {
      setEndDate(props.end_date)
    }
    if (props.nonconforming_type_id === null) {
      setNonconformingId('');
    } else {
      setNonconformingId(props.nonconforming_type_id)
    }
    if (props.employe_resp_id === null) {
      setResponsibleId('');
    } else {
      setResponsibleId(props.employe_resp_id)
    }
    if (props.workflow === null) {
      setWorkflowId('');
    } else {
      setWorkflowId(props.workflow)
    }

    var ProcedureId = props.procedure_id === null ? '' : props.procedure_id;
    fetch("/api/v2/procedures?procedure_id="+ProcedureId).then((response)=>{
      return response.json()
    }).then((data) => {
      setProcedures(data);
    })
  }, []);

  useEffect(() => {
    procedures.map((procedure)=>{
      fetch("/api/v2/nonconformings/set_dates?procedure_id="+procedure.id+"&start_date="+startDate+"&end_date="+endDate+"&nonconforming_type_id="+NonconformingId+"&employe_resp_id="+ResponsibleId+"&workflow="+WorkflowId).then((response)=>{
        return response.json()
      }).then((data) => {
        setNonConformings([...nonConformings, data].flat());
        nonConformings.push(data);
      }),
      fetch("/api/v2/nonconformings/set_counters?procedure_id="+procedure.id+"&start_date="+startDate+"&end_date="+endDate+"&nonconforming_type_id="+NonconformingId+"&employe_resp_id="+ResponsibleId+"&workflow="+WorkflowId).then((response)=>{
        return response.json()
      }).then((data) => {
        setCounters([...counters, data].flat());
        counters.push(data);
      })
    })
  }, [procedures]);

  useEffect(() => {
    nonConformings.map((nonconforming)=>{
      fetch("/api/v2/nonconformings/abilities?employe_resp_id="+nonconforming.employe_resp_id+"&id="+nonconforming.id).then((response)=>{
        return response.json()
      }).then((data) => {
        setAbilities([...abilities, data]);
        abilities.push(data);
      })
    })
  }, [nonConformings]);

  useEffect(() => {
    let hash = {};
    setAbilitiesNonRepeat(abilities.filter(o => hash[o.nonconforming_id] ? false : hash[o.nonconforming_id] = true))
  }, [abilities]);
  
  return (
    <div className='my-1'>
      {procedures.map((procedure,index)=>(
          <React.Fragment key={procedure.id}>
            <div className='border border-light'>
              <a className='collapsed btn btn-link btn-lg' data-toggle="collapse" href={`#collapse${index}`}>
                <div className='d-flex justify-content-starts py-md-3 py-lg-1 py-4'>
                  <div className='h6 mb-0 font-weight-semibold' style={{color: '#757575'}}>
                    {procedure.title}
                  </div>
                  <div className='h5 my-0'>
                    {counters.map((counter)=>{
                      if (counter.procedure_id === procedure.id) {
                        return (
                          <span className='badge font-weight-normal {}' style={{color: 'gray'}} key={counter.procedure_id}>[{counter.counter}]</span>
                        )}
                    })}
                  </div>
                </div>
              </a>
            </div>
            <div className='card-group collapse' id={`collapse${index}`}>
              <div className='table-responsive'>
                <table className='table table-bordered table-hover table-sm table-striped'>
                  <thead>
                    <tr>
                      <th className='align-middle text-center' scope="col">
                        Acciones
                      </th>
                      <th className='align-middle text-center' scope='col' style={{width: '100px'}}>
                        Fecha Reporte
                      </th>
                      <th className='align-middle text-center' scope='col' style={{width: '200px'}}>
                        Hallazgo
                      </th>
                      <th className='align-middle text-center' scope='col' style={{width: '200px'}}>
                        Correción Inmediata
                      </th>
                      <th className='align-middle text-center' scope='col' style={{width: '80px'}}>
                        Reportó
                      </th>
                      <th className='align-middle text-center' scope='col' style={{width: '200px'}}>
                        Correción Ejecutada
                      </th>
                      <th className='align-middle text-center' scope='col' style={{width: '90px'}}>
                        Responsable
                      </th>
                      <th className='align-middle text-center' scope='col' style={{width: '150px'}}>
                        Hallazgo Seguimiento
                      </th>
                      <th className='align-middle text-center' scope='col'>
                        Eficacia
                      </th>
                      <th className='align-middle text-center' scope='col'>
                        Oportunidad
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {nonConformings.map((nonconforming, index) => {
                      if (nonconforming.procedure_id === procedure.id) {
                        return (
                          <React.Fragment key={nonconforming.id}>
                            <tr>
                              <td className='align-middle'>
                                <div className='d-flex justify-content-between'>
                                  <div data-controller='tooltip' title='Ver reporte Completo Salida No Conforme' data-original-title>
                                    <a action='show' className='btn btn-outline-primary btn-sm' href={`/nonconformings/${nonconforming.id}`}>
                                      <i className='fas fa-eye'></i>
                                    </a>
                                  </div>
                                  <div data-controller='tooltip' title='Editar Salida No Conforme'>
                                    <a className='btn btn-outline-success btn-sm' data-controller='modal-opener' data-modal-opener-url={`/nonconformings/${nonconforming.id}/edit?edit=true`} data-modal-opener-loading="<div class='d-flex justify-content-center' id='loadingIndicator'>
                                    <div class='spinner-grow text-primary' role='status'>
                                    <span class='sr-only'>Cargando...</span>
                                    </div>
                                    </div>
                                    " data-action='modal-opener#open' data-modal-opener-modal-id='nonconformingModalS' data-modal-opener-modal-title='Editar Salida No Conforme' href="">
                                      <i className='far fa-edit'></i>
                                    </a>
                                  </div>
                                  <div data-controller='tooltip' title='Eliminar Salida No Conforme'>
                                    <a className="btn btn-outline-danger btn-sm " data-confirm="Está seguro de eliminar esta Salida No Conforme?" rel="nofollow" data-method="delete" href={`/nonconformings/${nonconforming.id}`}>
                                      <i className='far fa-trash-alt'></i>
                                    </a>
                                  </div>
                                </div>
                                <div className='text-center p-md-1'>
                                  {abilitiesNonRepeat.map((abilitie)=>{
                                    if (abilitie.nonconforming_id === nonconforming.id) {
                                      return(
                                        <React.Fragment key={abilitie.nonconforming_id}>
                                          {nonconforming.status_nonconforming === 1 && (
                                            <a className={`btn btn-info btn-sm ${abilitie.can_classify}`} data-controller='modal-opener' data-modal-opener-url={`/nonconformings/${nonconforming.id}/edit?edit=false`} data-modal-opener-loading="<div class='d-flex justify-content-center' id='loadingIndicator'>
                                            <div class='spinner-grow text-primary' role='status'>
                                            <span class='sr-only'>Cargando...</span>
                                            </div>
                                            </div>
                                            " data-action='modal-opener#open' data-modal-opener-modal-id='nonconformingModalS' data-modal-opener-modal-title='Clasificación Salida No Conforme' href="javasript:void(0)">
                                              Clasificar
                                            </a>
                                          )}
                                          {nonconforming.status_nonconforming === 2 && (
                                            <a className={`btn btn-info btn-sm ${abilitie.can_control}`} data-controller='modal-opener' data-modal-opener-url={`/nonconformings/${nonconforming.id}/edit?edit=false`} data-modal-opener-loading="<div class='d-flex justify-content-center' id='loadingIndicator'>
                                            <div class='spinner-grow text-primary' role='status'>
                                            <span class='sr-only'>Cargando...</span>
                                            </div>
                                            </div>
                                            " data-action='modal-opener#open' data-modal-opener-modal-id='nonconformingModalS' data-modal-opener-modal-title='Control Salida No Conforme' href="javasript:void(0)">
                                              Control
                                            </a>
                                          )}
                                          {nonconforming.status_nonconforming > 2 && (
                                            <a className={`btn btn-info btn-sm ${abilitie.can_follow}`} href={`/nonconformings/${nonconforming.id}/nonconforming_follows`} title='Seguimientos'>
                                              Seguimiento
                                            </a>
                                          )} 
                                        </React.Fragment>
                                      )}
                                  })}   
                                </div>
                              </td>
                              <td className='align-middle text-center'>
                                <small>
                                  {nonconforming.created_at}
                                </small>
                                <small className='d-block'>
                                  {nonconforming.time}
                                </small>
                              </td>
                              <td className='align-middle text-justify'>
                                <a aria-controls="collapseExample" aria-expanded="false" data-toggle="collapse" href={`#collapseH${index}`} role="button">
                                  <div className='h7 mb-0 font-weight-semibold' style={{color: '#757575'}}>
                                    {nonconforming.find}
                                  </div>
                                </a>
                              </td>
                              {nonconforming.report_correction_condition === false ? (
                                <td className='align-middle text-justify'>
                                  <span>No se reporto</span>
                                </td>
                               ) : (
                                <td className='align-middle text-justify'>
                                  <a aria-controls="collapseExample" aria-expanded="false" data-toggle="collapse" href={`#collapseCR${index}`} role="button">
                                  <div className='h7 mb-0 font-weight-semibold' style={{color: '#757575'}}>
                                    {nonconforming.report_correction}
                                  </div>
                                  </a>
                                </td>
                               )}
                              <td className='align-middle text-center'>
                                {nonconforming.employee_write_id === null ? (
                                <div data-controller='tooltip' title={nonconforming.external_position} >
                                  <h6>
                                    {nonconforming.external_name}
                                  </h6>
                                </div>
                                ) : (
                                  <div data-controller='tooltip' title={nonconforming.write_employee} >
                                    <div className="avatar bg-light text-dark border border-light rounded-circle avatar-small avatar-inline">
                                      {nonconforming.write_employee_picture.length > 6 ? (
                                        <img className="img-fluid rounded-circle" src={`${nonconforming.write_employee_picture}`}>
                                        </img>
                                      ):(
                                        <div className='initials'>
                                          {nonconforming.write_employee_picture}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}                     
                              </td>
                              {nonconforming.real_correction === null ? (
                                <td className='align-middle text-justify'>
                                  Aún no se realiza
                                </td>
                              ):(
                                <td className='align-middle text-justify'>
                                  <a aria-controls="collapseExample" aria-expanded="false" data-toggle="collapse" href={`#collapseCE${index}`} role="button">
                                    <div className='h7 mb-0 font-weight-semibold' style={{color: '#757575', maxWidth: '300px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
                                      {nonconforming.real_correction}
                                    </div>
                                  </a>
                                </td>
                              )}
                              <td className='align-middle text-center'>
                                {nonconforming.employe_resp_id === null ? (
                                  <div data-controller="tooltip" data-title="No asignado">
                                    <i className='fas fa-user-times'></i>
                                  </div>
                                ):(
                                  <div data-controller="tooltip" data-title={`${nonconforming.responsible_employee}`}>
                                    <div className='avatar bg-light text-dark border border-light rounded-circle avatar-small avatar-inline'>
                                      {nonconforming.resp_employee_picture.length > 6 ? (
                                        <img className="img-fluid rounded-circle" src={`${nonconforming.resp_employee_picture}`}>
                                        </img>
                                      ):(
                                        <div className='initials'>
                                        {nonconforming.resp_employee_picture}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </td>
                              {nonconforming.find_follow === "" ? (
                                <td className='align-middle text-justify'>
                                  Aun no se realiza
                                </td>
                              ):(
                                <td className='align-middle text-justify'>
                                  <a aria-controls="collapseExample" aria-expanded="false" data-toggle="collapse" href={`#collapseHS${index}`} role="button">
                                    <div className='h7 mb-0 font-weight-semibold' style={{color: '#757575'}}>
                                      {nonconforming.find_follow}
                                    </div>
                                  </a>
                                </td>
                              )}
                              <td className='align-middle text-center' style={{background: `${nonconforming.achievement_color}`, border: '2px solid white', color: 'black', fontSize: '15px'}}>
                                <small className='d-block'>{nonconforming.achievement_name}</small>
                              </td>
                              {nonconforming.delay_time_condition === false ? (
                                <td className='align-middle text-center'>
                                  Por Verificar
                                </td>
                              ):(
                                <td className='align-middle text-center' style={{background: `${nonconforming.justification}`, border: '2px solid white', color: 'black', fontSize: '15px'}}>
                                  <small className='d-block'>{nonconforming.delay_time}</small>
                                </td>
                              )}
                            </tr>
                            <tr>
                              <th colSpan={'10'}>
                                <div className='collapse' id={`collapseH${index}`}>
                                  <div className='form-row'>
                                    <div className='col-md-12'>
                                      <div className='card card border-info mb-3'>
                                        <div className='card-header'>
                                          <h4 className='card-title mb-0 mt-1 font-weight-bold'>
                                            Hallazgo
                                          </h4>
                                        </div>
                                        <div className='card-text mb-2 text-dark font-weight-normal p-md-2'>
                                          {nonconforming.find}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='collapse' id={`collapseCR${index}`}>
                                  <div className='form-row'>
                                    <div className='col-md-12'>
                                      <div className='card card border-info mb-3'>
                                        <div className='card-header'>
                                          <h4 className='card-title mb-0 mt-1 font-weight-bold'>
                                            Corrección Inmediata
                                          </h4>
                                        </div>
                                        <div className='card-text mb-2 text-dark font-weight-normal p-md-2'>
                                          {nonconforming.report_correction}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='collapse' id={`collapseCE${index}`}>
                                  <div className='form-row'>
                                    <div className='col-md-12'>
                                      <div className='card card border-info mb-3'>
                                        <div className='card-header'>
                                          <h4 className='card-title mb-0 mt-1 font-weight-bold'>
                                            Corrección Ejecutada
                                          </h4>
                                        </div>
                                        <div className='card-text mb-2 text-dark font-weight-normal p-md-2'>
                                          {nonconforming.real_correction}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='collapse' id={`collapseHS${index}`}>
                                  <div className='form-row'>
                                    <div className='col-md-12'>
                                      <div className='card card border-info mb-3'>
                                        <div className='card-header'>
                                          <h4 className='card-title mb-0 mt-1 font-weight-bold'>
                                            Hallazgo Seguimiento
                                          </h4>
                                        </div>
                                        <div className='card-text mb-2 text-dark font-weight-normal p-md-2'>
                                          {nonconforming.find_follow}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </th>
                            </tr>
                          </React.Fragment>
                        )
                      }})
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </React.Fragment>
        ))}
    </div>
  )
}

export default NonconformingSummary