import { Controller }  from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  //static targets = ['survey_theme']
  connect() {
    var options = document.getElementById("options")
    var range = document.getElementById("range")
    var required = document.getElementById("required")
    options.style.display ='none'
    range.style.display ='none'
    required.style.display ='none'
    this.checkValues()
    this.onChange()
  }

  checkValues(e){
    var survey = document.getElementById("survey_question_question_type");
    var options = document.getElementById("options")
    var range = document.getElementById("range")
    var required = document.getElementById("required")
    var image = document.getElementById("image")
    var title = document.getElementById("title")

    if(survey.value == 'A_a'){
      title.style.display =''
      range.style.display ='none'
      options.style.display ='none'
      required.style.display ='none'
    }

    if(survey.value == 'A_b'){
      options.style.display ='none'
      range.style.display ='none'
      title.style.display ='none'
      required.style.display ='none'
    }

    if(survey.value == 'A'){
      options.style.display ='none'
      range.style.display ='none'
      required.style.display =''
    }
    
    if(survey.value == 'B'){
      options.style.display ='none'
      range.style.display ='none'
      required.style.display =''
    }
    if(survey.value == 'C'){
      options.style.display ='none'
      range.style.display ='none'
      required.style.display =''
    }
    if(survey.value == 'D'){
      options.style.display ='none'
      range.style.display ='none'
      required.style.display =''
    }
    if(survey.value == 'E'){
      options.style.display =''
      range.style.display ='none'
      required.style.display =''
    }
    if(survey.value == 'F'){
      options.style.display =''
      range.style.display ='none'
      required.style.display =''
    }
    if(survey.value == 'G'){
      options.style.display ='none'
      range.style.display =''
      required.style.display =''
    }
    if(survey.value == 'H'){
      options.style.display ='none'
      range.style.display ='none'
      required.style.display =''
    }
    if(survey.value == 'I'){
      options.style.display ='none'
      range.style.display ='none'
      required.style.display =''
    }
    if(survey.value == 'J'){
      options.style.display ='none'
      range.style.display ='none'
      required.style.display =''
    }
    if(survey.value == 'K'){
      options.style.display ='none'
      range.style.display ='none'
      required.style.display =''
    }
    if(survey.value == 'L'){
      options.style.display ='none'
      range.style.display ='none'
      required.style.display =''
    }
    if(survey.value == 'M'){
      options.style.display ='none'
      range.style.display ='none'
      required.style.display =''
    }
  }

  onChange(e) {
    var survey = document.getElementById("survey_question_question_type");
    var options = document.getElementById("options")
    var range = document.getElementById("range")
    var label_title = document.getElementById("label_title")
    var required = document.getElementById("required")
    survey.addEventListener("change", function(){

      if(survey.value == 'A_a'){
        title.style.display =''
        range.style.display ='none'
        options.style.display ='none'
        label_title.textContent = "Título" 
        required.style.display ='none'
      }
  
      if(survey.value == 'A_b'){
        options.style.display ='none'
        range.style.display ='none'
        title.style.display ='none'
        label_title.textContent = "Título Pregunta"
        required.style.display ='none'
      }

      if(survey.value == 'A'){
        options.style.display ='none'
        range.style.display ='none'
        title.style.display =''
        label_title.textContent = "Título Pregunta" 
        required.style.display =''
      }
      
      if(survey.value == 'B'){
        options.style.display ='none'
        range.style.display ='none'
        title.style.display =''
        required.style.display =''
        label_title.textContent = "Título Pregunta" 
      }
      if(survey.value == 'C'){
        options.style.display ='none'
        range.style.display ='none'
        title.style.display =''
        required.style.display =''
        label_title.textContent = "Título Pregunta" 
      }
      if(survey.value == 'D'){
        options.style.display ='none'
        range.style.display ='none'
        title.style.display =''
        required.style.display =''
        label_title.textContent = "Título Pregunta" 
      }
      if(survey.value == 'E'){
        options.style.display =''
        range.style.display ='none'
        title.style.display =''
        required.style.display =''
        label_title.textContent = "Título Pregunta" 
      }
      if(survey.value == 'F'){
        options.style.display =''
        range.style.display ='none'
        title.style.display =''
        required.style.display =''
        label_title.textContent = "Título Pregunta" 
      }
      if(survey.value == 'G'){
        options.style.display ='none'
        range.style.display =''
        title.style.display =''
        required.style.display =''
        label_title.textContent = "Título Pregunta" 
      }
      if(survey.value == 'H'){
        options.style.display ='none'
        range.style.display ='none'
        title.style.display =''
        required.style.display =''
        label_title.textContent = "Título Pregunta" 
      }
      if(survey.value == 'I'){
        options.style.display ='none'
        range.style.display ='none'
        title.style.display =''
        required.style.display =''
        label_title.textContent = "Título Pregunta" 
      }
      if(survey.value == 'J'){
        options.style.display ='none'
        range.style.display ='none'
        title.style.display =''
        required.style.display =''
        label_title.textContent = "Título Pregunta" 
      }
      if(survey.value == 'K'){
        options.style.display ='none'
        range.style.display ='none'
        title.style.display =''
        required.style.display =''
        label_title.textContent = "Título Pregunta" 
      }
      if(survey.value == 'L'){
        options.style.display ='none'
        range.style.display ='none'
        title.style.display =''
        required.style.display =''
        label_title.textContent = "Título Pregunta" 
      }
      if(survey.value == 'M'){
        options.style.display ='none'
        range.style.display ='none'
        title.style.display =''
        required.style.display =''
        label_title.textContent = "Título Pregunta" 
      }
    })
  }
  
}
