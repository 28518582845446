import React from "react";

function Success ({portal, laboratory}){
  return(
    <div className="card border-secondary shadow px-5 py-2 ml-4 mr-4 mt-4">
      <div className="card-header text-center bg-white">
        <a className="btn btn-secondary btn-sm floating-top-left rounded-circle" href="#">
          <i className="fas.fa-arrow-left"/>
        </a>
        <h3 className="text-wrap font-weight-bold text-dark">
          {portal.short_name}
        </h3>
      </div>
      <div className="card border-info shadow-sm mb-4 rounded">
        <div className="card-header bg-dark">
          <h4 className="font-weight-bold text-white text-center">
            Solcitud de Laboratorio Exitosa
          </h4>
        </div>
        <div className="card-body">
          <h4 className="text-dark text-center">
          Estimado(a), su solicitud fue recibida exitosamente, pronto nos comunicaremos con usted!!
          </h4>
          <p>
          Puede consultar el estado de su solicitud con su email e identificación, o también con el siguiente identificador único:
          </p>
          <h4 className="font-weight-bold ml-3">
            {laboratory}
          </h4>
          <a className="btn btn-link" target="_blank" href="#"> Consultar estado de Laboratorio </a>
        </div>
      </div>
    </div>
  )
}

export default Success