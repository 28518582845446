import React from 'react'
import { GlobalProvider } from './Calendar/context/GlobalContext'
import CalendarView from './Calendar/CalendarView'

export default function Calendarapp(props) {
  return (
    <GlobalProvider>
      <CalendarView id={props.currentUser} csrf={props.csrfToken} />
    </GlobalProvider>
  )
}

