import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  static targets = ['probability', 'impact', 'opportunityLevel', 'opportunityEvaluation', 'submitButton', 'draftButton', 'spinner', 'timeLimit']

  connect () {
    $(this.spinnerTarget).hide()
    this.onChange()
  }
  
  onChange (e) {
    if (e) e.preventDefault()
      var probability = this.probabilityTarget.value
    var impact = this.impactTarget.value
    var value = probability * impact
    const receivedString = this.element.dataset.user
    const keyValuePairs = receivedString.replace(/\s/g, "").split(",");
    const values = {};
    keyValuePairs.forEach((pair) => {
      const [key, value] = pair.split(":");
      values[key] = value.trim();
    });
    this.uplbarranquilla = JSON.parse(values.uplbarranquilla_log);
    $(this.opportunityLevelTarget).prop('value', value)
    if (this.uplbarranquilla){
      this.conditions_upl_barranquilla (value)
    }
    else{
      this.conditions_companies (value)
    }
  }

  submit () {
    $(this.draftButtonTarget).hide()
    $(this.submitButtonTarget).hide()
    $(this.spinnerTarget).show()
  }

  conditions_upl_barranquilla(value){
    if ((Number(value) > 0) && (Number(value) < 11)) {
      $(this.opportunityEvaluationTarget).prop('value', 'Bajo')
      $(this.timeLimitTarget).prop('value', 'Largo Plazo')
      $(this.timeLimitTarget).attr('style', 'background-color: #DA0540; color: white')
      $(this.opportunityEvaluationTarget).attr('style', 'background-color: #DA0540; color: white')
      $(this.opportunityLevelTarget).attr('style', 'background-color: #DA0540; color: white')
    } else if ((Number(value) < 36) && (Number(value) > 10)) {
      $(this.opportunityEvaluationTarget).prop('value', 'Medio')
      $(this.timeLimitTarget).prop('value', 'Mediano Plazo')
      $(this.timeLimitTarget).attr('style', 'background-color: #FFA307; color: white')
      $(this.opportunityEvaluationTarget).attr('style', 'background-color: #FFA307; color: white')
      $(this.opportunityLevelTarget).attr('style', 'background-color: #FFA307; color: white')
    } else if ((Number(value) < 70) && (Number(value) > 35)) {
      $(this.opportunityEvaluationTarget).prop('value', 'Alto')
      $(this.timeLimitTarget).prop('value', 'Corto Plazo')
      $(this.timeLimitTarget).attr('style', 'background-color: #009688; color: white')
      $(this.opportunityEvaluationTarget).attr('style', 'background-color: #009688; color: white')
      $(this.opportunityLevelTarget).attr('style', 'background-color: #009688; color: white')
    } else if ((Number(value) > 69)) {
      $(this.opportunityEvaluationTarget).prop('value', 'Muy Alto')
      $(this.timeLimitTarget).prop('value', 'Corto Plazo')
      $(this.timeLimitTarget).attr('style', 'background-color: #8CD339; color: white')
      $(this.opportunityEvaluationTarget).attr('style', 'background-color: #8CD339; color: white')
      $(this.opportunityLevelTarget).attr('style', 'background-color: #8CD339; color: white')
    }
  }
  conditions_companies(value){
    if ((Number(value) > 0) && (Number(value) <= 5)) {
      $(this.opportunityEvaluationTarget).prop('value', 'Bajo')
      $(this.timeLimitTarget).prop('value', 'Largo Plazo')
      $(this.timeLimitTarget).attr('style', 'background-color: #DA0540; color: white')
      $(this.opportunityEvaluationTarget).attr('style', 'background-color: #DA0540; color: white')
      $(this.opportunityLevelTarget).attr('style', 'background-color: #DA0540; color: white')
    } else if ((Number(value) >= 6) && (Number(value) <= 11)) {
      $(this.opportunityEvaluationTarget).prop('value', 'Medio')
      $(this.timeLimitTarget).prop('value', 'Mediano Plazo')
      $(this.timeLimitTarget).attr('style', 'background-color: #FFA307; color: white')
      $(this.opportunityEvaluationTarget).attr('style', 'background-color: #FFA307; color: white')
      $(this.opportunityLevelTarget).attr('style', 'background-color: #FFA307; color: white')
    } else if ((Number(value) >= 12) && (Number(value) <= 19)) {
      $(this.opportunityEvaluationTarget).prop('value', 'Alto')
      $(this.timeLimitTarget).prop('value', 'Corto Plazo')
      $(this.timeLimitTarget).attr('style', 'background-color: #009688; color: white')
      $(this.opportunityEvaluationTarget).attr('style', 'background-color: #009688; color: white')
      $(this.opportunityLevelTarget).attr('style', 'background-color: #009688; color: white')
    } else if ((Number(value) >= 20) && (Number(value) <= 25)) {
      $(this.opportunityEvaluationTarget).prop('value', 'Muy Alto')
      $(this.timeLimitTarget).prop('value', 'Corto Plazo')
      $(this.timeLimitTarget).attr('style', 'background-color: #8CD339; color: white')
      $(this.opportunityEvaluationTarget).attr('style', 'background-color: #8CD339; color: white')
      $(this.opportunityLevelTarget).attr('style', 'background-color: #8CD339; color: white')
    }
  }
}
