import { Controller }  from 'stimulus'

export default class extends Controller {

  connect() {
    var accident = document.getElementById("accident");
    var description = document.getElementById("description");
    accident.style.display = ''
    description.style.display = ''
    this.onChange()
  }

  onChange(e) {
    var accident_false = document.getElementById("accident_accident_type_false");
    var accident = document.getElementById("accident");
      if (accident_false.checked == true){
        accident.style.display = 'none';
      }
      else{
        accident.style.display = '';
      }
  }

  
}
