import React, { useEffect, useState } from 'react'

const GeneralReportModalBody = ({
  ShowModal,
  props,
  auditProgramReport,
}) => {
  const [dataGeneralReport, setDataGeneralReport] = useState({});

  const handleChange = (e) => {
    const {value, name} = e.target;
    setDataGeneralReport({...dataGeneralReport, [name]: value});
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    fetch(`/api/v2/audit_programs/${props.audit_program_id}/audit_program_reports/${auditProgramReport.id}`,{
      method: 'PATCH',
      body: JSON.stringify(dataGeneralReport),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then((response)=> { response.json() })
      .then(() => { window.location.reload(); })
      // .catch(error => console.error(error))
  }
  return (
    <>
      <form action="" onSubmit={handleSubmit}>
        <div className='form-row mb-1'>
          <div className="col-md-12 js-parent mb-3">
            <label htmlFor="audit_program_report_strengths">FORTALEZAS</label>
            <textarea className='form-control' autoFocus='autofocus' name="strengths" id="audit_program_report_strengths" rows="4" onChange={handleChange} defaultValue={auditProgramReport.strengths}></textarea>
          </div>
          <div className="col-md-12 js-parent mb-3">
            <label htmlFor="audit_program_report_conformities">CONFORMIDAD</label>
            <textarea className='form-control' autoFocus='autofocus' name="conformities" id="audit_program_report_conformities" rows="4" onChange={handleChange} defaultValue={auditProgramReport.conformities}></textarea>
          </div>
          <div className="col-md-12 js-parent mb-3">
            <label htmlFor="audit_program_report_opportunities">OPORTUNIDADES DE MEJORA</label>
            <textarea className='form-control' autoFocus='autofocus' name="opportunities" id="audit_program_report_opportunities" rows="4" onChange={handleChange} defaultValue={auditProgramReport.opportunities}></textarea>
          </div>
          <div className="col-md-12 js-parent mb-3">
            <label htmlFor="audit_program_report_nonconformities">NO CONFORMIDAD</label>
            <textarea className='form-control' autoFocus='autofocus' name="nonconformities" id="audit_program_report_nonconformities" rows="4" onChange={handleChange} defaultValue={auditProgramReport.nonconformities}></textarea>
          </div>
          <div className="col-md-12 js-parent mb-3">
            <label htmlFor="audit_program_report_historical_analysis">ANÁLISIS HISTÓRICO</label>
            <textarea className='form-control' autoFocus='autofocus' name="historical_analysis" id="audit_program_report_historical_analysis" rows="4" onChange={handleChange} defaultValue={auditProgramReport.historical_analysis}></textarea>
          </div>
          <div className="col-md-12 js-parent mb-3">
            <label htmlFor="audit_program_report_conclusions">CONCLUSIONES</label>
            <textarea className='form-control' autoFocus='autofocus' name="conclusions" id="audit_program_report_conclusions" rows="4" onChange={handleChange} defaultValue={auditProgramReport.conclusions}></textarea>
          </div>
          <div className="col-md-12 js-parent mb-3">
            <label htmlFor="audit_program_report_recommendations">RECOMENDACIONES</label>
            <textarea className='form-control' autoFocus='autofocus' name="recommendations" id="audit_program_report_recommendations" rows="4" onChange={handleChange} defaultValue={auditProgramReport.recommendations}></textarea>
          </div>
          <button className='btn btn-link' type='button' id='cancel_button' onClick={()=>ShowModal(false)}> Cancelar </button>
          <button className='btn btn-primary ml-auto' type='submit' id='submit_button' name='commit' value='ready'> Guardar </button>
        </div>
      </form>
    </>
  )
}

export default GeneralReportModalBody