import React, {useEffect, useState} from 'react'

const FirstTable = ({auditProgram, props}) => {

  return (
    <>
      <div className="table_responsive">
        <table className="table table-bordered table-sm mb-0">
          <tbody>
            <tr>
              <th className="align-top" style={{width: '15%'}}> OBJETIVO AUDITORÍA</th>
              <td colSpan="3"> {auditProgram.objective}
              </td>
            </tr>
            <tr>
              <th className="align-top" > ALCANCE</th>
              <td colSpan="3"> {auditProgram.scope}
              </td>
            </tr>
            <tr>
              <th className="align-top" > CRITERIOS</th>
              <td colSpan="3"> {auditProgram.criteria}
              </td>
            </tr>
            <tr>
              <th className="align-top" style={{width: '15%'}}> RIESGOS</th> 
              <td style={{width: '35%'}}>{auditProgram.risk}</td> 
              <th className='align-top' > CONTROLES</th>
              <td style={{width: '35%'}}>{auditProgram.control}</td> 
            </tr>
            <tr>
              <th className="align-middle" > METODOLOGÍA / TÉCNICA DE MUESTREO:</th>
              <td colSpan="3" > {auditProgram.metodology}
              </td>
            </tr>
            <tr>
              <th className="align-middle" > RESPONSABLE</th>
              <td colSpan="3" > {props.name}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default FirstTable