import React from "react";
import { AdminLaboratoriesContext } from "./AdminLaboratoriesContext";
import { LoadingSpinner } from "../../LoadingSpinner";
import { EmptyRecords } from "../../EmptyRecords";
import { Modal } from "./Modal"
import { Table } from "./Table";

function AppUI(){
  const{
    loading,
    error,
    adminLaboratory,
    openModal,
    setOpenModal,
    setModalEdit,
    findAdmin
  } = React.useContext(AdminLaboratoriesContext)
  return(
    <div className="card border-0">
      <div className="card-header d-flex justify-content-between bg-dark">
        <h5 className="card-title text-white">
          Administración de Laboratorio
        </h5>
        <div className="card-options">
          {adminLaboratory.length > 0 &&
            <button 
              className="btn btn-primary"
              onClick={
                () => {
                  findAdmin(adminLaboratory[0].id)
                }
              }
            >
              Editar
            </button>
          }
          {adminLaboratory.length <= 0 &&
            <button 
              className="btn btn-primary"
              onClick={
                () => {
                  setOpenModal(state => !state)
                }
              }
            >
              Nuevo
            </button>
          }
        </div>
      </div>
      <div className="card-body p-4">
        {loading && <LoadingSpinner/>}
        {error && <p>Se generó un error :o</p>}
        {!loading && adminLaboratory.length <= 0 && <EmptyRecords/> }
        {!loading && adminLaboratory.length > 0 &&  <Table/>}
      </div>
      {openModal && (
        <Modal/>
      )
      }
    </div>
  )
}

export default AppUI 