import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['image', 'crosshair', 'proceduresModal', 'menuModal',
    'modalTitle', 'procedure', 'documentsLink', 'indicatorsLink', 'opportunitiesLink', 'risksLink',
    'characterizationLink', 'checkbox']

  handleCheckboxChange(event) {
    const isChecked = event.target.checked;
    if (isChecked) {
      const id = document.getElementById('id_macroproceso').value
      const url = '/procedures/sub_procedures'
      $.get(url+'?procedure='+ id).then((content) => {
        document.getElementById('equipmentsDiv').innerHTML = content
      })
    }
    else{
      const url = '/procedures/procedures'
      var id_macropoceso = document.getElementById('id_macroproceso').value
      $.get(url+'?parent_id='+id_macropoceso).then((content) => {
        document.getElementById('equipmentsDiv').innerHTML = content
      })
    }
  }

  dataprocedures(event) {
    const isChecked = event.target.checked;
    if (isChecked) {
      const id = document.getElementById('id_procedure').value
      const url = '/procedures/sub_procedures'
      const data = 'procedure'
      $.get(url+'?procedure='+ id+'&data='+data).then((content) => {
        document.getElementById('dataprocedureDiv').innerHTML = content
      })
    }
    else{
      const url = '/procedures/procedures_tow'
      const id = document.getElementById('id_procedure').value
      $.get(url+'?parent_id='+id).then((content) => {
        document.getElementById('dataprocedureDiv').innerHTML = content
      })
    }
  }

  datasubprocedures(event) {
    const isChecked = event.target.checked;
    if (isChecked) {
      const id = document.getElementById('sub_procedure_id').value
      const url = '/procedures/subprocedures'
      $.get(url+'?procedure='+ id).then((content) => {
        document.getElementById('datasuprocedureDiv').innerHTML = content
      })
    }
  }
  
  connect () {
    if (this.data.get('mode') !== 'edit') {
      return
    }

    const { x, y } = this.imageTarget.getBoundingClientRect()
    this.posX = x
    this.posY = y

    window.requestAnimationFrame(() => {
      $(this.crosshairTarget).css({
        '-webkit-transform': `translateX(${this.posX - 24}px) translateY(${this.posY - 24} px)`,
        transform: `translateX(${this.posX - 24}px) translateY(${this.posY - 24}px)`
      })
    })
  }

  onClick (e) {
    e.preventDefault()

    if (this.data.get('mode') === 'edit') {
      const { x, y } = this.imageTarget.getBoundingClientRect()
      this.posX = e.pageX - x
      this.posY = e.pageY - y

      $(this.proceduresModalTarget).modal('show')
    } else {
      var checkboxElement = document.getElementById("cheked_macroproceso");
      if (checkboxElement) {
        checkboxElement.checked = true; // Esto marcará el checkbox
        console.log("Checkbox marcado:", checkboxElement.checked); // Confirmación en consola
      }
      const { title, id, characterization } = e.target.dataset
      $(this.menuModalTarget).modal('show')
      $(this.modalTitleTarget).html(title)
      const id_macroproceso = document.getElementById("id_macroproceso");
      if (id_macroproceso){
        checkboxElement.setAttribute("checked", "checked");
        id_macroproceso.value = id
        const url = '/procedures/sub_procedures'
        $.get(url+'?procedure='+ id).then((content) => {
          document.getElementById('equipmentsDiv').innerHTML = content
        })
      }
      if (characterization) {
        $(this.characterizationLinkTarget).attr('class', '')
        $(this.characterizationLinkTarget).attr('href', characterization)
      } else {
        $(this.characterizationLinkTarget).attr('class', 'disabled text-muted')
      }
      $(this.documentsLinkTarget).attr('href', `/documents?filters[procedure_id]=${id}`)
      $(this.indicatorsLinkTarget).attr('href', `/indicators/list?procedure_id=${id}`)
      $(this.opportunitiesLinkTarget).attr('href', `/opportunities?filters[procedure]=${id}`)
      $(this.risksLinkTarget).attr('href', `/risks?filters[procedure]=${id}`)
    }
  }

  onSave () {
    $(this.proceduresModalTarget).modal('hide')
    $.post(`/procedures/${this.procedureTarget.value}/update-coords`, {
      x: this.posX,
      y: this.posY,
      mode: 'edit'
    })
  }

  onMove (e) {
    if (this.data.get('mode') !== 'edit') {
      return
    }

    const { x, y } = this.imageTarget.getBoundingClientRect()

    this.posX = e.pageX - x
    this.posY = e.pageY - y

    window.requestAnimationFrame(() => {
      $(this.crosshairTarget).css({
        '-webkit-transform': `translateX(${this.posX - 24}px) translateY(${this.posY - 24} px)`,
        transform: `translateX(${this.posX - 24}px) translateY(${this.posY - 24}px)`
      })
    })
  }
}
