import React from "react";
import { AdminUsers } from "../AdminUsers";

function AssignmentUsers({adminUser, setAdminUser, type}){
  const [employees, setEmployees] = React.useState([]);

  React.useEffect(() => {
    fetch("/api/v2/employees").then((response)=>{
      return response.json()
    }).then((data) => {
      setEmployees(data)
    })
  }, [])

  const addAssignment = () => {
    const newAssignment = {
      id: crypto.randomUUID(),
      assignable_type: 'Laboratory',
      action: type === 'responsible' ? 'responsible' : 'read',
      user_id: '',
      _destroy: false,
      value: employees[0].value,
      label: employees[0].label,
      typeSendData: 'create'
    }
    setAdminUser([...adminUser, newAssignment])
  };
  
  const removeAssigmentAdmin = (id) => {
    setAdminUser(
      adminUser.map((user) =>
        user.id === id ? { ...user, _destroy: true } : user
      )
    );
  };

  const SelectUserAssignment = (id) => (e) => {
    const newAdminUser = adminUser.map(user =>{
      if(user.id === id){
        return{
          ...user,
          user_id: e.value,
          value: e.value,
          label: e.label
        }
      }
      return user
    })
    setAdminUser(newAdminUser)
  }
  return(
    <React.Fragment>
      {adminUser.map((user) => (
        !(user._destroy) && <AdminUsers
          key={user?.assignment_id || user.id}
          id={user.id}
          value={user.value}
          label={user.label}
          employees={employees}
          SelectUserAssignment={SelectUserAssignment}
          removeAssigmentAdmin={removeAssigmentAdmin}
        />
      ))}
      <div className="mt-2">
        <button 
          type="button" 
          className="btn btn-info btn-sm mb-3"
          onClick={addAssignment}
        >
          <i className="fas fa-plus mr-1"> Agregar</i>
        </button>
      </div>
    </React.Fragment>
  )
}
export { AssignmentUsers }