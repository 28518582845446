import React, { useContext } from 'react';
import Modal from './Modal';
import plusImg from '../assets/plus.svg';
import GlobalContext from '../context/GlobalContext';


export default function CreateEventButton() {
  const { showModal, setShowModal } = useContext(GlobalContext);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="row">
      <button
        className="btn btn-outline-secondary mx-5 rounded-pill d-flex align-items-center shadow-sm hover-shadow-lg"
        onClick={handleOpenModal}
      >
        <img src={plusImg} alt="create_event" style={{ width: '30px', height: '30px' }} />
        <span className="pl-2">Crear</span>
      </button>
    </div>
  );
}
