import { Controller }  from 'stimulus'


export default class extends Controller {

  static targets = ['check']

  connect() {
    this.onmultiCheck()
    
  }
  
  onmultiCheck ()
  {
    var check = document.getElementsByClassName("Mycheckbox");
    var k = 0
    for (var x = 0; x < check.length; x++) {
      if (check[x].checked){
        k = k+1
      }
    }
    var boton = document.getElementById("boton");
    var texto = document.getElementById("texto");
    if (k >= 1){
    boton.style.display = ''
    texto.style.display = 'none'
    }
    if (k == 0){
      boton.style.display = 'none'
      texto.style.display = ''
    }
  }

}
