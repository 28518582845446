import React, { useState, useEffect } from 'react'
import FindingsModalBody from './FindingsModalBody'
import IndividualReportsModalBody from './IndividualReportsModalBody'
import GeneralReportModalBody from './GeneralReportModalBody'

const Modal = ({
  modaltitle,
  modalFlag, 
  auditNormId, 
  auditReportId,
  ShowModal,
  props,
  procedureAudits,
  auditProgramReport,
  auditNorms,
}) => {
  return (
    <div className="modal fade show" tabIndex="-1" style={{paddingRight: '4px', display: 'block', backgroundColor: 'rgba(53, 53, 53, 0.5)'}} role="dialog">
      <div className="modal-dialog modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{modaltitle}</h5>
            <button type="btn btn-link" className="close" onClick={()=> ShowModal(false)} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            { modalFlag === 0 && 
            <FindingsModalBody 
            auditNormId={auditNormId} 
            ShowModal={ShowModal}
            auditNorms={auditNorms}
            /> }
            { modalFlag === 1 && 
            <IndividualReportsModalBody 
            auditReportId={auditReportId}
            ShowModal={ShowModal}
            procedureAudits={procedureAudits} /> }
            { modalFlag === 2 && 
            <GeneralReportModalBody
            ShowModal={ShowModal}
            props={props}
            auditProgramReport={auditProgramReport}/>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal