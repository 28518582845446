import { Controller } from 'stimulus'
import Chart from 'chart.js'

export default class extends Controller {
  connect () {
    let { metrics, years, summationflag, averageflag, goalflag, complianceflag } = this.element.dataset

    metrics = JSON.parse(metrics)
    years = JSON.parse(years)
    const labels = metrics.map(({ date }) => date)
    const percentages = metrics.map(({ percentage }) => Number(Number(percentage).toFixed(2)))
    const results = metrics.map(({ result }) => Number(Number(result).toFixed(2)))
    const goals = metrics.map(({ goal }) => Number(Number(goal).toFixed(2)))
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    var yData = []
    for (var m = 1; m <= 12; m++) {
      var thisMonth = 0
      var countMeasures = 0
      for (var i = 1; i <= labels.length; i++) {
        var datayear = new Date(labels[i - 1]).getFullYear()
        if (datayear === years[0]) {
          var datamonth = new Date(labels[i - 1]).getMonth() + 1 // Función getMonth() meses indexados desde 0
          if (datamonth === m) {
            thisMonth += percentages[i - 1]
            countMeasures += 1
          }
        }
      }
      if (countMeasures > 0) { yData[m - 1] = (thisMonth / countMeasures).toFixed(2) } else {
        countMeasures = 0
        for (var j = 1; j <= labels.length; j++) {
          datayear = new Date(labels[j - 1]).getFullYear()
          if (datayear === years[0]) {
            datamonth = new Date(labels[j - 1]).getMonth() + 1
            if ((datamonth > m) && (percentages[j - 1] > 0) && (yData[m - 2] > 0)) {
              var pendiente = (parseFloat(yData[m - 2]) - percentages[j - 1]) / (m - 1 - datamonth)
              yData[m - 1] = parseFloat(yData[m - 2]) + pendiente
            }
          }
        }
      }
    }
    var suma = 0
    var promedio = 0
    var positives = 0
    yData.forEach (function(num){
      var number = num
      if (number > 100) { number = 100 }
      else if (number < 0) { number = 0 }
      suma += parseInt(number)
      if (number > 0) { positives += 1 }
    })
    if ( positives > 0 ) { promedio = suma / positives }
    var myColor = this.getRandomColor()
    this.ctx = this.element.getContext('2d')
    this.options = {
      type: 'line',
      data: {
        labels: months,
        datasets: [
          {
            label: years[0],
            pointBorderWidth: 5,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: false,
            borderWidth: 2,
            data: yData,
            pointBackgroundColor: myColor,
            pointBorderColor: myColor,
            borderColor: myColor
          }
        ]
      }
    }
    this.chart = new Chart(this.ctx, this.options)

    for (var y = 1; y < years.length; y++) {
      var newData = []
      var newColor = this.getRandomColor()
      for (m = 1; m <= 12; m++) {
        thisMonth = 0
        countMeasures = 0
        for (i = 1; i <= labels.length; i++) {
          datayear = new Date(labels[i - 1]).getFullYear()
          if (datayear === years[y]) {
            datamonth = new Date(labels[i - 1]).getMonth() + 1 // Función getMonth() meses indexados desde 0
            if (datamonth === m) {
              thisMonth += percentages[i - 1]
              countMeasures += 1
            }
          }
        }
        if ((countMeasures > 0) && (!newData[m - 1])) { newData[m - 1] = (thisMonth / countMeasures).toFixed(2) } else {
          countMeasures = 0
          for (j = 1; j <= labels.length; j++) {
            datayear = new Date(labels[j - 1]).getFullYear()
            if (datayear === years[y]) {
              datamonth = new Date(labels[j - 1]).getMonth() + 1
              if ((datamonth > m) && (percentages[j - 1] > 0) && (newData[m - 2] > 0) && (!newData[m - 1])) {
                pendiente = (parseFloat(newData[m - 2]) - percentages[j - 1]) / (m - 1 - datamonth)
                newData[m - 1] = parseFloat(newData[m - 2]) + pendiente
              }
            }
          }
        }
      }
      suma = 0
      promedio = 0
      positives = 0
      newData.forEach (function(num){
        var number = num
        if (number > 100) { number = 100 }
        else if (number < 0) { number = 0 }
        suma += parseInt(number)
        if (number > 0) { positives += 1 }
      })
      if ( positives > 0 ) { promedio = suma / positives }

      this.chart.data.datasets.push({
        label: years[y],
        pointBorderWidth: 5,
        pointHoverRadius: 10,
        pointHoverBorderWidth: 1,
        pointRadius: 3,
        fill: false,
        borderWidth: 2,
        pointBackgroundColor: newColor,
        pointBorderColor: newColor,
        borderColor: newColor,
        data: newData
      })
      this.chart.update()
    }

    years.forEach(function(year){
      let yearSum = 0
      let countYear = 0
      let labelsYear
      let goalsum = 0
      let percentagesum = 0
      let countgoal = 0
      let countsum = 0
      let countpercentage = 0
      for (var i = 1; i <= labels.length; i++) {
        labelsYear = new Date(labels[i - 1]).getFullYear()
        if (labelsYear === year) {
          yearSum += results[i-1]
          countYear += 1
          countsum += 1
        }
      }
      for (var i = 1; i <= labels.length; i++){
        labelsYear = new Date(labels[i - 1]).getFullYear()
        if (labelsYear === year) {
          goalsum  += goals[i-1]
          countYear += 1
          countgoal += 1
        }
      }
      for (var i = 1; i <= labels.length; i++){
        labelsYear = new Date(labels[i - 1]).getFullYear()
        if (labelsYear === year) {
          percentagesum  += percentages[i-1]
          countpercentage += 1
        }
      }
      var table = document.getElementById("tableSummaryYear")
      if (table) {
        var body = table.getElementsByTagName('tbody')[0]
        var row = body.insertRow()
        var cellyear = row.insertCell(0)
        cellyear.innerHTML = year
        cellyear.classList.add('text-center')

        if (summationflag == 'true'){
          var cellsum = row.insertCell()
          cellsum.innerHTML = yearSum.toFixed(2);
          cellsum.classList.add('text-center')
        } // if (summationflag == 'true')
        if (averageflag == 'true'){
          var cellaverage = row.insertCell()
          cellaverage.innerHTML = (yearSum / countsum).toFixed(2);
          cellaverage.classList.add('text-center')
        } // else if (averageflag == 'true')
        if (goalflag == 'true'){
          var cellgoal = row.insertCell()
          cellgoal.innerHTML = goalsum;
          cellgoal.classList.add('text-center')
        }
        if (averageflag == 'true'){
          var cellgoal = row.insertCell()
          cellgoal.innerHTML = (goalsum / countgoal).toFixed(2);
          cellgoal.classList.add('text-center')
        }
        if (complianceflag == 'true'){
          var cellcompliance = row.insertCell()
          cellcompliance.innerHTML = (percentagesum / countpercentage).toFixed(2);
          cellcompliance.classList.add('text-center')
        }
      } // if(table)
    })
  }

  getRandomColor () {
    var letters = '0123456789ABCDEF'
    var color = '#'
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }
}
