import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  static targets = ['probability', 'diamond']

  connect () {
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()
    let probability = this.probabilityTarget.value
    if (probability === 'possible') {
      this.diamondTarget.style.backgroundColor = '#008000'
    } else if (probability === 'likely' ) {
      this.diamondTarget.style.backgroundColor = '#FFFF00'
    } else {
      this.diamondTarget.style.backgroundColor = '#FF0000'
    }

  }
}
