import { useEffect } from "react";

export const useProtected = (isProtected, searchRef) => {
  useEffect(() => {
    if (isProtected) {
      function contextMenuHandler(e) {
        return e.preventDefault();
      }

      function keyboardHandler(event) {
        const getPlatform = () => {
          if (navigator.userAgentData) {
            return navigator.userAgentData.platform;
          } else {
            const platform = navigator.userAgent.toLowerCase();
            if (platform.includes("mac")) return "mac";
            if (platform.includes("win")) return "windows";
            return "unknown";
          }
        };

        const isMac = getPlatform() === "mac";
        const isWindows = getPlatform() === "windows";

        // Disable common keys
        const disabledKeys = [
          "F12", // Developer Tools
          "I", // Ctrl + Shift + I for Dev Tools
          "U", // Ctrl + U for View Source
          "C", // Ctrl + C for Copy
          "X", // Ctrl + X for Cut
          "V", // Ctrl + V for Paste
          "A", // Ctrl + A for Select All
          "P", // Ctrl + P for Print
          "S", // Ctrl + S for Save
          "J", // Ctrl + J for Console
          "H", // Ctrl + H for History
          "K", // Ctrl + K for Search
        ];

        if (event.key === "f" && (event.ctrlKey || event.metaKey)) {
          searchRef.current.focus();
          return event.preventDefault();
        }

        if (
          (disabledKeys.includes(event.key) &&
            (event.ctrlKey || event.metaKey)) ||
          (isMac &&
            event.metaKey &&
            event.shiftKey &&
            (event.key === "3" || event.key === "4")) ||
          (isWindows && event.key === "PrintScreen") ||
          (event.key === "s" && event.ctrlKey && event.shiftKey)
        ) {
          return event.preventDefault();
        }
      }

      document.addEventListener("contextmenu", contextMenuHandler);
      document.addEventListener("keydown", keyboardHandler);

      return () => {
        document.removeEventListener("contextmenu", contextMenuHandler);
        document.removeEventListener("keydown", keyboardHandler);
      };
    }
  }, [isProtected]);
};
