import { Controller } from 'stimulus'
import Chart from 'chart.js'

export default class extends Controller {
  connect () {
    let { points } = this.element.dataset

    points = JSON.parse(points)

    const labels = points.map(({ stage }) => stage)
    const data = points.map(({ value }) => value)
    const planning = [40]
    const execution = [20]
    const check = [20]
    const act = [20]
    this.ctx = this.element.getContext('2d')
    this.options = {
      type: 'bar',
      data: {
        datasets: [{
            label: 'Avance P',
            pointBorderWidth: 5,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: false,
            backgroundColor: 'rgba(50, 127, 168)',
            borderColor: '#000000',
            borderWidth: 2,
            stack: 'Stack 1',
            data: data.slice(0,1)
          },
          {
            label: 'Avance H',
            pointBorderWidth: 5,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: false,
            backgroundColor: 'rgba(255, 157, 59)',
            borderColor: '#000000',
            borderWidth: 2,
            stack: 'Stack 1',
            data: data.slice(1,2)
          },
          {
            label: 'Avance V',
            pointBorderWidth: 5,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: false,
            backgroundColor: 'rgba(173, 170, 166)',
            borderColor: '#000000',
            borderWidth: 2,
            stack: 'Stack 1',
            data: data.slice(2,3)
          },
          {
            label: 'Avance A',
            pointBorderWidth: 5,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: false,
            backgroundColor: 'rgba(255, 215, 36)',
            borderColor: '#000000',
            borderWidth: 2,
            stack: 'Stack 1',
            data: data.slice(3,4)
          },
          {
            label: 'Planear',
            pointBorderWidth: 5,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: false,
            backgroundColor: 'rgba(50, 127, 168)',
            borderColor: '#000000',
            borderWidth: 2,
            stack: 'Stack 0',
            data: planning
          },
          {
            label: 'Hacer',
            pointBorderWidth: 5,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: false,
            backgroundColor: 'rgba(255, 157, 59)',
            borderColor: '#000000',
            borderWidth: 2,
            stack: 'Stack 0',
            data: execution
          },
          {
            label: 'Verificar',
            pointBorderWidth: 5,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: false,
            backgroundColor: 'rgba(173, 170, 166)',
            borderColor: '#000000',
            borderWidth: 2,
            stack: 'Stack 0',
            data: check
          },
          {
            label: 'Actuar',
            pointBorderWidth: 5,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: false,
            backgroundColor: 'rgba(255, 215, 36)',
            borderColor: '#000000',
            borderWidth: 2,
            stack: 'Stack 0',
            data: act
          },
        ]
      },
      options: {
        animation: {
          easing: 'easeInOutBack'
        }
      },
      interaction: {
        intersect: false,
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true
        }
      }
    }
    this.chart = new Chart(this.ctx, this.options)
  }
}
