import React from "react";
import { ServiceAdminLaboratories } from "./ServiceAdminLaboratories";
import axios from "axios";

const AdminLaboratoriesContext = React.createContext()
function AdminLaboratoriesProvider({children}){
  const{
    loading,
    error,
    adminLaboratory
  } = ServiceAdminLaboratories()
  const [openModal, setOpenModal] = React.useState(false)
  const [errorResponse, setErrorResponse] = React.useState([])
  const [modalEdit, setModalEdit] = React.useState(false)
  const [dataEditAdmin, setDataEditAdmin] = React.useState([])

  const createAdminLaboratory = async (newDataAdminLaboratories) => {
    try{
      const response = await axios.post("/api/v2/admin_laboratories", newDataAdminLaboratories)
      console.log("Prueba de laboratorio creada:", response.data);
      window.location.href = "/admin_laboratories";
      setOpenModal(false)
      setErrorResponse([]);
    }catch(error){
      console.error("Error al crear la prueba de laboratorio:", error.response.data.errors);      
      setErrorResponse(error.response.data.errors);
    }
  }

  const findAdmin = async (idAdmin) => {
    try{
      const respone = await axios.get(`/api/v2/admin_laboratories/${idAdmin}/edit`)
      setOpenModal(true)
      setDataEditAdmin(respone.data)
    }catch(error){
      console.log('error que tal')
      setOpenModal(false)
    }
  }

  const updateAdminLaboratory = async (id, updateDataAdminLaboratories) => {
    try{
      const response = await axios.patch(`/api/v2/admin_laboratories/${id}`, updateDataAdminLaboratories)
      console.log("Administrador de laboratorio editada con éxito:", response.data);
      window.location.href = "/admin_laboratories";
      setOpenModal(false)
      setErrorResponse([])
    } catch (error){
      setErrorResponse(error.response.data.errors)
    }
  }
  return(
    <AdminLaboratoriesContext.Provider
      value={{
        loading,
        error,
        adminLaboratory,
        openModal,
        setOpenModal,
        errorResponse,
        createAdminLaboratory,
        modalEdit,
        setModalEdit,
        dataEditAdmin,
        findAdmin,
        updateAdminLaboratory
      }}
    >
      {children}
    </AdminLaboratoriesContext.Provider>
  )
}



export { AdminLaboratoriesContext, AdminLaboratoriesProvider }