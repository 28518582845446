import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['classification']

  connect() {
    this.onChange()
  }

  onChange() {
    const requiredProcedure = this.data.get('required-procedure')
    if (this.hasClassificationTarget) {
      if ($(this.classificationTarget).prop('checked')){
        $('#perspectiveField').hide('slow')
        if (requiredProcedure == 'false') {
          $('#procedureField').show('slow')
        }
      } else {
        $('#perspectiveField').show('slow')
        if (requiredProcedure == 'false') {
          $('#procedureField').hide('slow')
        }
      }
    }
  }
}