import React, {useState, useEffect} from "react"
import Spinner from './Pictures/Spinner'

const Assignments = (props) => {
  const [curriculum, setCurriculum] = useState('')

  useEffect(() => {
    fetch('/api/v1/curriculums/main?employee='+props.employee).then((response)=>{
      return response.json()
    }).then((data) => {
      setCurriculum(data[0])
    })
  }, [setCurriculum])

  function removeToCollection(key){
    window.location.href = '/employees/remove_curriculum.'+key
  }

  return (
      <React.Fragment>
        <div>
          {!curriculum && <Spinner/>}
          {curriculum && <a href={curriculum.url} className="btn btn-outline-primary btn-sm my-tooltip" target="_blank" disposition="attachment">
            <i className="far fa-file" data-bs-toggle='tooltip' data-bs-placement='top' title="prueba"></i>
            <span className="tooltiptext">{curriculum.name}</span>
          </a>
          }
        </div>
      </React.Fragment>
  )
}

export default Assignments
