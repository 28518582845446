import React, { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { Table, Pagination } from "react-bootstrap";
import { AppContext } from "./Context/AppContext";

const Main = ({
  controllCardButtons,
  heads,
  loaderSize,
  data,
  businessAnalytics,
  businessAnalyticsPlans,
  numPages,
  items,
}) => {
  const { loading } = useContext(AppContext);
  const [_, setSearchParams] = useSearchParams();

  return (
    <>
      <div className="table-responsive justify-content-center align-items-center">
        {controllCardButtons}
        <>
          <Table striped bordered hover>
            <thead>{heads}</thead>

            {loading ? <tbody>{loaderSize}</tbody> : <tbody>{data}</tbody>}
          </Table>

          {businessAnalytics || businessAnalyticsPlans ? null : (
            <Pagination style={{ justifyContent: "center" }}>
              {[...Array(numPages).keys()].map((i) => (
                <Pagination.Item
                  key={i}
                  onClick={() => setSearchParams(0 || { page: i + 1 })}
                >
                  {i + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          )}
        </>
      </div>
      {businessAnalytics}
      {businessAnalyticsPlans}
    </>
  );
};

export default Main;
