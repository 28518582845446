import React, {useState} from 'react'

const SelectInput = ({dateFilter, initialdateFilter, finaldateFilter}) => {
  const [selectinput, SetSelectIput] = useState('')
  const [intialdate, SetInitalDate] = useState('')
  const [finaldate, SetFinalDate] = useState('')

  const getCurrentYear = () => {
    const currentDate = new Date();
    return currentDate.getFullYear();
  };
  const filter = () => {
    initialdateFilter(intialdate)
    finaldateFilter(finaldate)
    dateFilter(selectinput)
  }
  function download_year() {
    if (isNaN(selectinput))
    {
      let year = new Date().toDateString()
      fetch('/api/v2/metrics/download_year?year='+year).then((response) => {
        reload()
        return response.json();
      })
    }
    else {
      fetch('/api/v2/metrics/download_year?year='+selectinput+'&initialdate='+intialdate+'&finaldate='+finaldate).then((response) => {
        reload()
        return response.json()
      })
    }
  }
  const minDate = selectinput ? `${selectinput}-01-01` : `${getCurrentYear()}-01-01`;
  const maxDate = selectinput ? `${selectinput}-12-31` : `${getCurrentYear()}-12-31`;
  function reload() {
    window.location.reload()
  }
  return (
    <div className='form-inline my-2'>
      <div className='form-group col-md-8 mb-0'>
        <select className='form-control mr-2' name="select" onChange={(e)=>SetSelectIput(e.target.value)}>
          <option value="2023" onChange={(e)=>SetSelectIput(e.target.value)}>2023</option>
          <option value="2022" onChange={(e)=>SetSelectIput(e.target.value)}>2022</option>
          <option value="2021" onChange={(e)=>SetSelectIput(e.target.value)}>2021</option>
          <option value="2020" onChange={(e)=>SetSelectIput(e.target.value)}>2020</option>
          <option value="2019" onChange={(e)=>SetSelectIput(e.target.value)}>2019</option>
          <option value="2018" onChange={(e)=>SetSelectIput(e.target.value)}>2018</option>
          <option value="2017" onChange={(e)=>SetSelectIput(e.target.value)}>2017</option>
          <option value="2016" onChange={(e)=>SetSelectIput(e.target.value)}>2016</option>
          <option value="2015" onChange={(e)=>SetSelectIput(e.target.value)}>2015</option>
        </select>
        <button type='button' className='btn btn-primary' onClick={()=>filter()}>Filtrar</button>
        <button type='button' style={{ marginLeft: '5px' }} className='btn btn-success' onClick={()=>download_year()}>Descargar indicadores anual</button>
      </div>
      <div className='form-group col-md-8 mb-0 mt-2'>
        <input type='date' placeholder='aaaa-mm-dd' className='form-control mr-2' onChange={(e)=>SetInitalDate(e.target.value)}>
        </input>
        <input type='date' placeholder='aaaa-mm-dd' className='form-control mr-2' onChange={(e)=>SetFinalDate(e.target.value)}>
        </input>
      </div>
    </div>
  )
}


export default SelectInput
