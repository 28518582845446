import { select } from 'd3'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['idselected']

  charge () {
    var charge = $(this.idselectedTarget).prop('textContent');
    document.getElementById("tabla").innerHTML = charge
  }
}
