import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'
import { select } from 'd3';

export default class extends Controller {
  static targets = ["select", 'selectParent'];

  connect() {
    if (this.hasSelectTarget) {
      this.onChange();
      this.verPatert();
    }
  }

  onChangeArea (e) {
    if (e) e.preventDefault()
    const select = document.getElementById('dese_is').value;
    const url = "/danger_matrices/get_office?branch_office_id="+select // Ruta configurada en las rutas de Rails

    fetch(url)
      .then(response => response.json())
      .then(data => this.areaSelect(data))
      .catch(error => console.error("Error fetching data:", error));
  }

  areaSelect(data) {
    const select = document.getElementById('selectArea');

    // Clear existing options
    select.innerHTML = "";

    // Add new options based on fetched data
    data.forEach(item => {
      const option = document.createElement("option");
      option.value = item.id;
      option.text = item.name;
      select.appendChild(option);
    });
  }

  onChange (e) {
    const selectedOption = this.selectTarget.options[this.selectTarget.selectedIndex];
    const customAttribute = selectedOption.getAttribute("data-custom-attribute");

    var miInput = document.getElementById('description_classification');

    // Cambia el valor del elemento de entrada
    miInput.value = customAttribute;
  }

  verPatert() {
    const selectedOption = this.selectTarget.options[this.selectTarget.selectedIndex];
    var parent = selectedOption.getAttribute("data-parent");
    const customAttribute = (parent == null) ? 'all' : parent;

    var select = document.getElementById('risk_classification_parent')


    // Busca la opción con el nuevo valor y selecciona el elemento
    var opcion = Array.from(select.options).find(option => option.value === customAttribute);
    select.value = opcion ? customAttribute : ''; 
    var eventoChange = new Event('change');
    select.dispatchEvent(eventoChange);
  }

  onChangeOption(e) {
    if (e) e.preventDefault()
    const select = document.getElementById('risk_classification_parent').value;
    const url = "/risk_classifications/filter_danger?id="+select // Ruta configurada en las rutas de Rails

    fetch(url)
      .then(response => response.json())
      .then(data => this.populateSelect(data))
      .catch(error => console.error("Error fetching data:", error));
  }

  populateSelect(data) {
    const select = document.getElementById('risk_classification');

    // Clear existing options
    select.innerHTML = "";

    // Add new options based on fetched data
    var classification = (data && data.length > 0 && data[0].data) ? data[0].data.description : '';
    document.getElementById('description_classification').value = classification;
    data.forEach(item => {
      const option = document.createElement("option");
      option.value = item.id;
      option.text = item.name;
      option.dataset.customAttribute = item.data.description;
      select.appendChild(option);
    });
  }
}
