import React from "react";
import { AdminLaboratoriesProvider } from "./AdminLaboratoriesContext";
import AppUI from "./AppUI";

function AdminLaboratories(){
  return(
    <AdminLaboratoriesProvider>
      <AppUI/>
    </AdminLaboratoriesProvider>
  )
}

export default AdminLaboratories