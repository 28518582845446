import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  static targets = ['field', 'destroy']

  connect () {
    this.fieldTarget.value = this.fieldTarget.parentNode.parentNode.id
  }

  onDelete (e) {
    e.preventDefault()
    this.destroyTarget.value = '1'
    $(this.element).fadeOut()
  }
}
