import React from "react";
import { LabortoryContext } from "../LabortoryContext";
import { format } from 'date-fns';
import { formatDistanceToNow } from 'date-fns';
import { es, enUS } from 'date-fns/locale';
import { Buttons } from "../Buttons";

function Table() {
  const{
    typeLaboratory
  } = React.useContext(LabortoryContext)
  return(
    <table className="table table-bordered table-hover table-sm table-striped">
      <thead>
        <tr>
          <th className="text-center" scope="col" style={{width: '30px'}}> # </th>
          <th className="text-center" scope="col" style={{width: '105px'}}/>
          <th className="text-center" scope="col" > Nombre </th>
          <th className="text-center" scope="col" > Objetivo </th>
          <th className="text-center" scope="col" > Estandar </th>
          <th className="text-center" scope="col" style={{width: '150px'}}>Fecha Creación</th>
          <th className="text-center" scope="col" style={{width: '150px'}}>Última Actualización</th>
        </tr>
      </thead>
      <tbody>
        {
          typeLaboratory.map((laboratory, index) => {
            return(
              <React.Fragment key={laboratory.id}>
                <tr>
                  <td className="align-middle text-center"> {index + 1} </td>
                  <td className="align-middle">
                    <Buttons
                      service_type={laboratory}
                    />
                  </td>
                  <td className="align-middle">{laboratory.name}</td>
                  <td className="align-middle">{laboratory.objective}</td>
                  <td className="align-middle">{laboratory.standard}</td>
                  <td className="align-middle text-center">
                    <small>{format(new Date(laboratory.created_at), 'dd-MM-yy hh:mm a')} </small>
                    <small className="d-block">{formatDistanceToNow(new Date(laboratory.created_at), { locale: es, addSuffix: true })}</small>
                  </td>
                  <td className="align-middle text-center">
                    <small>{format(new Date(laboratory.updated_at), 'dd-MM-yy hh:mm a')} </small>
                    <small className="d-block">{formatDistanceToNow(new Date(laboratory.updated_at), { locale: es, addSuffix: true })}</small>
                  </td>
                </tr>
              </React.Fragment>
            )
          }) 
        }
      </tbody>
    </table>
  )
}

export { Table }