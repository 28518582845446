import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['text', 'field']

  connect() {
    let text = this.data.get('text')
    var replacePattern1 = /(((https?:\/\/)|(www\.))[^\s]+)/g
    var replacedText = text.replace(replacePattern1, function (url) {
      var hyperlink = url;
      var host = new URL(url).hostname;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + host + '</a>'
    })
    $(this.fieldTarget).html(replacedText)
  }
}
