import React from "react";

const NatureModal = () => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th className="align-middle text-center">
            PARÁMETROS DE CALIFICACIÓN CUALITATIVA
          </th>
          <th className="align-middle text-center"> CALIFICACIÓN </th>
          <th className="align-middle text-center"> GRADO DE SIGNIFICANCIA </th>
          <th className="align-middle text-center"> DESCRIPCIÓN </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="align-middle text-justify" rowSpan="2">
            NATURALEZA - SIGNO Define el sentido del cambio ambiental producido
            por una determinada acción
          </td>
          <td className="align-middle text-justify">(+)</td>
          <td className="align-middle text-justify">Positivo</td>
          <td className="align-middle text-justify"> Mejora el ambiente actual o futuro</td>
        </tr>
        <tr>
          <td className="align-middle text-justify">(-)</td>
          <td className="align-middle text-justify">Negativo</td>
          <td className="align-middle text-justify"> Degrada el ambiente actual o futuro</td>
        </tr>
      </tbody>
    </table>
  );
};

export default NatureModal;
