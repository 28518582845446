import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  static targets = ['buttonone', 'buttontwo', 'buttonthree', 'buttonfour', 'buttonNan']

  buttonOne() {
    this.onChange(1)
    $(this.buttononeTarget).prop('className', 'btn btn-outline-danger btn-sm mr-1')
  }

  buttonTwo() {
    this.onChange(2)
    $(this.buttontwoTarget).prop('className', 'btn btn-outline-warning btn-sm mr-1')
  }

  buttonThree() {
    this.onChange(3)
    $(this.buttonthreeTarget).prop('className', 'btn btn-outline-info btn-sm mr-1')
  }

  buttonFour() {
    this.onChange(4)
    $(this.buttonfourTarget).prop('className', 'btn btn-outline-success btn-sm mr-1')
  }

  buttonNa() {
    this.onChange('NA')
    $(this.buttonNanTarget).prop('className', 'btn btn-outline-secondary btn-sm mr-1')
  }

  onChange(value) {
    this.clearClass()
    const assessment = this.data.get('assessment')
    const url = this.data.get('url')
    const evaluation = this.data.get('evaluation')
    $.post(url+'?value='+value+'&question_id='+evaluation+'&assessment_id='+assessment).then((content) => {
      document.getElementById(`spanevaluation${content[0]}`).innerHTML = this.setContent(content[1])
      document.getElementById(`spanevaluation${content[2]}`).innerHTML = this.setContent(content[3])
      document.getElementById(`spanevaluation${content[4]}`).innerHTML = this.setContent(content[3])
    })
  }

  setContent(value) {
    if (value) { return parseFloat(value).toFixed(2) }
    else { return '' }
  }

  clearClass() {
    $(this.buttononeTarget).prop('className', 'btn btn-danger btn-sm mr-1')
    $(this.buttontwoTarget).prop('className', 'btn btn-warning btn-sm mr-1')
    $(this.buttonthreeTarget).prop('className', 'btn btn-info btn-sm mr-1')
    $(this.buttonfourTarget).prop('className', 'btn btn-success btn-sm mr-1')
    $(this.buttonNanTarget).prop('className', 'btn btn-secondary btn-sm mr-1')
  }
}
