import React from "react";

function DetermineLaboratoryParam({ id, name, description, onNameChange, onDescriptionChange, onRemove }) {
  return (
    <div className="form-group mb-3 mt-1">
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">
            <i className="fas fa-file-alt"></i>
          </span>
        </div>
        <input
          type="text"
          placeholder="Nombre"
          className="form-control"
          value={name}
          onChange={(e) => onNameChange(id, e.target.value)} // Notificar el cambio al componente padre
        />
        <div className="input-group-prepend ml-3">
          <span className="input-group-text">
            <i className="fas fa-file-alt"></i>
          </span>
        </div>
        <input
          type="text"
          placeholder="Descripción"
          className="form-control"
          value={description}
          onChange={(e) => onDescriptionChange(id, e.target.value)} // Notificar el cambio al componente padre
        />
        {/* Botón para eliminar el componente */}
        <div className="input-group-append">
          <div className="input-group-text bg-white border-0">
            <a 
              type="button" 
              className="text-dark"
              onClick={() => onRemove(id)} // Llamar a la función de eliminación
            >
              <i className="fas fa-times"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export { DetermineLaboratoryParam };
