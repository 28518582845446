import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['notTicket']

  connect () {
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()
    this.hideAll()
    if ($(this.notTicketTarget).prop('checked')) {
      $('#serviceTime').show('slow')
    }
  }

  hideAll (e) {
    if (e) e.preventDefault()
    $('#serviceTime').hide('slow')
  }
}
