import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js";

function ChartComponent() {
  const [state, setState] = useState(0);
  const [stateTime, setStateTime] = useState(0);
  const [eventsCount, setEventsCount] = useState([]);
  const chartRef = useRef(null);

  const handleClick = (value) => {
    setState(value);
  };

  const handleClickTime = (value) => {
    setStateTime(value);
  };

  const getType = (value) => {
    switch (value) {
      case 0:
        return true;
      case 1:
        return false;
    }
  };

  const getTypeTime = (value) => {
    switch (value) {
      case 0:
        return "year";
      case 1:
        return "month";
      case 2:
        return "week";
    }
  };

  useEffect(() => {
    const type = getType(state);
    const date = new Date();
    const parsedDate = new Date(date);
    const formattedDate = parsedDate.toISOString();
    const baseUrl = "/api/v2/events/business_analytics";
    const params = new URLSearchParams();
    const time = getTypeTime(stateTime);

    params.append(`filters[${time}]`, formattedDate);
    params.append("filters[event_type]", type);
    const url = `${baseUrl}?${params}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setEventsCount(data);
      });
  }, [state, stateTime]);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    // Configuramos los datos y opciones del gráfico
    const data = {
      labels: ["Hechas", "En Proceso", "No Cumplidas", "Sin seguimiento"],
      datasets: [
        {
          label: "Tareas",
          data: [
            eventsCount.completed_events,
            eventsCount.in_progress_events,
            eventsCount.incompleted_events,
            eventsCount.unfollow_events,
          ],
          backgroundColor: [
            "#088A4B", // Color para Tareas Hechas
            "#FF8000", // Color para Tareas en Proceso
            "#FE2E2E", // Color para Tareas No Cumplidas
            "#E0F8F7", // Color para Tareas sin seguimiento
          ],
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    };

    if (state === 1) {
      // Datos y opciones para la gráfica de Eventos
      data.datasets = [
        {
          label: "Eventos",
          data: [
            eventsCount.completed_events,
            eventsCount.in_progress_events,
            eventsCount.incompleted_events,
            eventsCount.unfollow_events,
          ],
          backgroundColor: [
            "#088A4B", // Color para Tareas Hechas
            "#FF8000", // Color para Tareas en Proceso
            "#FE2E2E", // Color para Tareas No Cumplidas
            "#E0F8F7", // Color para Tareas sin seguimiento
          ],
          borderColor: "rgba(255, 99, 132, 1)",
          borderWidth: 1,
        },
      ];
    }

    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    // Creamos el gráfico
    const chart = new Chart(ctx, {
      type: "bar",
      data: data,
      options: options,
    });

    // Limpieza al desmontar el componente
    return () => {
      chart.destroy();
    };
  }, [state, eventsCount]);

  return (
    <div>
      <div className="bg-dark w-100 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <h4 className="text-white font-bold mb-0 ml-5">Business Analytics</h4>
        </div>
      </div>
      <div>
        <button
          type="button"
          className={`btn btn-outline-primary ml-2 mb-2 mt-2 ${
            state === 0 ? "active" : ""
          }`}
          onClick={() => handleClick(0)}
        >
          Tareas
        </button>
        <button
          type="button"
          className={`btn btn-outline-primary ml-2 mb-2 mt-2 ${
            state === 1 ? "active" : ""
          }`}
          onClick={() => handleClick(1)}
        >
          Eventos
        </button>
      </div>
      <div>
        <button
          type="button"
          className={`btn btn-outline-primary ml-2 ${
            stateTime === 0 ? "active" : ""
          }`}
          onClick={() => handleClickTime(0)}
        >
          Año
        </button>
        <button
          type="button"
          className={`btn btn-outline-primary ml-2 ${
            stateTime === 1 ? "active" : ""
          }`}
          onClick={() => handleClickTime(1)}
        >
          Mes
        </button>
        <button
          type="button"
          className={`btn btn-outline-primary ml-2 ${
            stateTime === 2 ? "active" : ""
          }`}
          onClick={() => handleClickTime(2)}
        >
          Semana
        </button>
      </div>
      <canvas ref={chartRef} />
    </div>
  );
}

export default ChartComponent;
