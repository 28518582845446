import React, { useState, useEffect } from 'react'

const TypeSelect = ({typeChange}) => {
  const [product, setType] = useState(false)

  const buttonToggle = (boolval) => {
    setType(boolval)
  }

  useEffect(() => {
    if (product) { typeChange('product') }
    else { typeChange('result') }
  }, [product])

  return (<>
    <div className="btn-group">
      <button className={product ? 'btn btn-outline-scondary' : 'btn btn-secondary active'} type='button' onClick={()=> buttonToggle(false)}>Resultado</button>
      <button className={product ? 'btn btn-secondary active' : 'btn btn-outline-scondary'} type='button' onClick={()=> buttonToggle(true)}>Producto</button>
    </div>
  </>)
}

export default TypeSelect