import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Form, Button, Row, Col, InputGroup, Fade } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faFileLines } from "@fortawesome/free-solid-svg-icons";

import {
  FLASH_TYPE_ERROR,
  FLASH_TYPE_NOTICE,
  FLASH_TYPE_SUCCESS,
  useFlash,
} from "../components/Context/FlashContext";
import { FlashErrors } from "../components/Flash/FlashAlerts";
import errorTroubleType from "../utils/errorsTroubleAdmin.json";

const NewTroubleType = () => {
  const [formData, setFormData] = useState({
    name: "",
  });

  const { setFlash } = useFlash();
  const navigate = useNavigate();

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const url = "/api/v2/trouble_types/";
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    axios
      .post(url, formData, {
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        let flashMessage = {};

        flashMessage = {
          type: FLASH_TYPE_SUCCESS,
          message: "Elemento creado",
        };

        setFlash(flashMessage);

        navigate(`/troubles-admin`);
      })
      .catch((error) => {
        let responseError = error.response.data;

        let errorMessages = {};
        for (const field in responseError) {
          if (errorTroubleType[field]) {
            errorMessages[field] = `${errorTroubleType[field]}`;
          }
        }

        setFlash({
          type: FLASH_TYPE_ERROR,
          message: Object.entries(errorMessages)
            .map(([key, value]) => `${value}`)
            .join("\n"),
        });
      });
  };

  return (
    <Form onSubmit={handleSubmit} className="px-3 pt-3">
      <Form.Group as={Col} controlId="workflowState">
        <Row className="justify-content-between">
          <Col style={{ flexGrow: 0 }}>
            <Link to={"/troubles-admin"}>
              <Button variant="light">
                <FontAwesomeIcon icon={faArrowLeft} color="#438dff" />
              </Button>
            </Link>
          </Col>

          <Col md="auto">
            <h4>Nuevo tipo de trouble</h4>
          </Col>
          <Col md="auto">
            <Link to={"/troubles-admin"}>
              <Button className="btn btn-info mx-1">Cancelar</Button>
            </Link>

            <Button
              className="btn btn-primary mx-1"
              type="submit"
              onClick={handleInputChange}
              variant="primary"
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Form.Group>
      <FlashErrors />

      <Form.Group
        controlId="area"
        className="d-flex align-items-center"
        style={{ gap: "20px" }}
      >
        <Form.Label>Name</Form.Label>
        <InputGroup>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faFileLines} />
          </InputGroup.Text>
          <Form.Control
            as="textarea"
            rows={3}
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="ESCRIBE UN NUEVO TIPO DE PROBLEMA"
          />
        </InputGroup>
      </Form.Group>
    </Form>
  );
};

export default NewTroubleType;
