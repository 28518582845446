import React, {useState, useEffect} from 'react'
import ListData from './ListData'

const List = (props) => {
  const { type, modalControl, date } = props
  const showobjectives = type == 'objectives'
  const cardTitle = type == 'objectives' ? 'Objetivo del Sistema de Gestión' : 'Perspectivas BSC'
  const [titleAverage, setTitleAverage] = useState('Promedio')
  const [titleWeighted, setTitleWeighted] = useState('Ponderado')
  const [titleperspectiveAverage, setTitlePerspectivesAverage] = useState('Promedio')
  const [titleperspectiveWeighted, setTitlePerspectivesWeighted] = useState('Ponderado')
  const [objectives, setObjectives] = useState([])
  const [objectivesweight, setObjectivesWeight] = useState([])
  const [perspectives, setPerspective] = useState([])
  const [perspectivesweight, setPerspectivesWeight] = useState([])
  const [showAverage, setAverage] = useState(true)

  useEffect(() => {
    fetch('/api/v1/metrics/objectives?value=value&date='+date).then((response)=>{
      return response.json()
    }).then((data) =>
    {
      setObjectives(data)
    })
  }, [setObjectives, date])

  useEffect(()=>{
    let sum = 0
    for (var i=0; i<objectives.length; i++){ sum += objectives[i].value }
    if (objectives.length) { setTitleAverage(`Promedio: ${(sum / objectives.length).toFixed(2)} %`)}

  },[objectives, date])

  useEffect(()=>{
    let sum = 0
    for (var i=0; i<objectivesweight.length; i++){ sum += objectivesweight[i].value }
    if (objectivesweight.length) { setTitleWeighted(`Ponderado: ${(sum).toFixed(2)} %`)}

  },[objectivesweight, date])

  useEffect(()=>{
    let sum = 0
    for (var i=0; i<perspectives.length; i++){ sum += perspectives[i].value }
    if (perspectives.length) { setTitlePerspectivesAverage(`Promedio: ${(sum / perspectives.length).toFixed(2)} %`)}

  },[perspectives, date])

  useEffect(()=>{
    let sum = 0
    for (var i=0; i<perspectivesweight.length; i++){ sum += perspectivesweight[i].value }
    if (perspectivesweight.length) { setTitlePerspectivesWeighted(`Ponderado: ${(sum).toFixed(2)} %`)}

  },[perspectivesweight, date])

  useEffect(() => {
    fetch('/api/v1/metrics/objectives?value=weighted&date='+date).then((response)=>{
      return response.json()
    }).then((data) =>
    {
      setObjectivesWeight(data)
    })
  }, [setObjectivesWeight, date])

  useEffect(() => {
    fetch('/api/v1/metrics/perspectives?value=value&date='+date).then((response)=>{
      return response.json()
    }).then((data) => setPerspective(data))
  }, [setPerspective, date])

  useEffect(() => {
    fetch('/api/v1/metrics/perspectives?value=weighted&date='+date).then((response)=>{
      return response.json()
    }).then((data) =>
    {
      setPerspectivesWeight(data)
    })
  }, [setPerspectivesWeight, date])

  return (
    <div className='col-lg-4'>
      <div className='card'>
        <div className='card-header bg-white'>
          <h5 className='card-title text-center'>{cardTitle}</h5>
        </div>
        <nav>
          <div className='nav nav-justified nav-tabs' role='tablist'>
            <a className='h6 nav-item nav-link active' onClick={()=>setAverage(true)} href="#" data-toggle="tab" role="tab">{showobjectives ? titleAverage : titleperspectiveAverage}</a>
            <a className='h6 nav-item nav-link' onClick={()=>setAverage(false)} href="#" data-toggle="tab" role="tab">{showobjectives ? titleWeighted : titleperspectiveWeighted}</a>
          </div>
          <div className='w-100 tab-content'>
            <div className='tab-pane fade show active'>
              <ul className='list-group'>
                {showobjectives && <ListData data={showAverage ? objectives : objectivesweight } modalControl={modalControl} group={'quality_objective'} date={date}/>}
                {!showobjectives && <ListData data={showAverage ? perspectives : perspectivesweight } modalControl={modalControl} group={'perspective'} date={date}/>}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default List