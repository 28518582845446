import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    $('#groupDepartmentFields').show()
    $('#groupCommitteeFields').hide()
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()

    if ($('#groups_attendee_group_type_department').prop('checked')) {
      $('#groupDepartmentFields').show('slow')
      $('#groupCommitteeFields').hide('slow')
    }
    else {
      $('#groupDepartmentFields').hide('slow')
      $('#groupCommitteeFields').show('slow')
    }
  }
}
