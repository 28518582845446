import React, { useState, useEffect } from 'react'
import Chart from './Chart'

const CardChart = ({ name, id, date, indicatortype, modalControl, components, lineValue }) => {
  const [values, setValue] = useState([])
  const [query, setQuery] = useState('')
  const [showchart, setChart] = useState(false)
  useEffect(() => {
    fetch('api/v1/mipg_metrics/line_value?id='+id+'&date='+date+'&type_of='+indicatortype).then((response)=>{
      return response.json()
    }).then((data)=>{
      setValue(data)
      if (data.available) { setChart(true) } else { setChart(false) }
      lineValue(data.available, data.percentage)
    })
  }, [date, indicatortype])

  useEffect(() => {
    if (components) {
      setQuery('components')
    } else {
      setQuery('programs')
    }
  }, [])
  return(
    <div className='col-lg-3 my-2'>
      <a className='w-100' type='button' onClick={()=>modalControl(true, id, name, query)}>
        <div className='card vh-200px'>
          <div className='card-header bg-white'>
            <h5 className='card-title text-center'>{name}</h5>
          </div>
          <div className='card-body bg-white'>
            {showchart && <Chart
              progress={values.percentage}
              size={80}
              strokeWidth={6}
              circleTwoStroke={values.color}
              />}
            {!showchart && <h5 className='card-title text-center mt-4'>Sin Mediciones Activas</h5> }
          </div>
        </div>
      </a>
    </div>
  )
}

export default CardChart