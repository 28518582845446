import React, { useEffect, useState } from 'react'

const IndividualReportsModalBody = ({auditReportId, ShowModal, procedureAudits}) => {
  const [initialValues, setInitialValues] = useState({});
  const [dataIndividualReports, setDataIndividualReports] = useState({
    id: auditReportId,
  });

  useEffect(() => {
    const initialValue = procedureAudits.filter((procedure) => procedure.audit_report_id === auditReportId);
    setInitialValues({
      strengths: initialValue[0].strengths,
      conformities: initialValue[0].conformities,
      oportunities: initialValue[0].oportunities,
      nonconformities: initialValue[0].nonconformities,
    })
  }, [])
  
  const handleChange = (e) => {
    const {value, name} = e.target;
    setDataIndividualReports({...dataIndividualReports, [name]: value});
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    let data = {individual_report: {...dataIndividualReports}}
    fetch(`/api/v2/audit_reports/${auditReportId}`,{
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then((response)=>{ return response.json() })
      .then(() => { window.location.reload(); })
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='form-row mb-1'>
          <div className="col-md-12 js-parent mb-3">
            <label htmlFor="times">FORTALEZAS</label>
            <textarea className='form-control' name="strengths" id="times" rows="4" onChange={handleChange} defaultValue={initialValues.strengths}></textarea>
          </div>
          <div className="col-md-12 js-parent mb-3">
            <label htmlFor="times">CONFORMIDAD</label>
            <textarea className='form-control' name="conformities" id="times" rows="4" onChange={handleChange} defaultValue={initialValues.conformities}></textarea>
          </div>
          <div className="col-md-12 js-parent mb-3">
            <label htmlFor="times">OPORTUNIDADES</label>
            <textarea className='form-control' name="opportunities" id="times" rows="4" onChange={handleChange} defaultValue={initialValues.oportunities}></textarea>
          </div>
          <div className="col-md-12 js-parent mb-3">
            <label htmlFor="times">NO CONFORMIDAD</label>
            <textarea className='form-control' name="nonconformities" id="times" rows="4" onChange={handleChange} defaultValue={initialValues.nonconformities}></textarea>
          </div>
          <button className='btn btn-link' type='button' id='cancel_button' onClick={()=>ShowModal(false)}> Cancelar </button>
          <button className='btn btn-primary ml-auto' type='submit' id='submit_button' name='commit' value='ready'> Guardar </button>
        </div>
      </form>
    </>
  )
}

export default IndividualReportsModalBody