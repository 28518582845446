import React, { useEffect, useState } from 'react'
import InputGroup from '../forms/InputGroup'
import Header from '../Basics/Header'
import CheckBox from '../forms/CheckBox'

const CreateObjectiveForm = (props) => {
  const [objectiveId, setObjectiveId] = useState(props.operative_objective.id)
  const [name, setObjective] = useState(props.operative_objective.name)
  const [percentage, setPercentage] = useState(props.operative_objective.percentage)
  const [strategic_objective_id, setStrategicObjective] = useState(props.operative_objective.strategic_objective_id)
  const [bsc_idicators, setBscIdicators] = useState([])
  const [selected_indicators, setSelectedIndicators] = useState([])
  const [indicators, setIndicators] = useState([])
 
  const [shownIndicators, setShownIndicators] = useState([])
  const [searchText, setSearchText] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault()
    const url = objectiveId === null ? '/api/v1/operative_objectives' : `/api/v1/operative_objectives/${objectiveId}`
    const method = objectiveId === null ? 'POST' : 'PATCH'
    const datos = { name, percentage, strategic_objective_id, selected_indicators }

    fetch(url, {
      method: method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(datos)
    }).then((response) => { return response.json() })
      .then(window.location.href = `/bsc_perspectives/${props.perspective_id}/strategic_objectives/${strategic_objective_id}/operative_objectives`)
      .catch(error => console.error(error))
  }

  const fillIndicators = (indicatorId, isSelected) => {
    if (isSelected) {
      setSelectedIndicators([...selected_indicators, indicatorId])
    } else {
      const newArr = selected_indicators.slice()
      const deleteIndex = newArr.indexOf(indicatorId)

      if (deleteIndex > -1) {
        newArr.splice(deleteIndex, 1)
        setSelectedIndicators(newArr)
      }
    }
  }

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    if (e.target.value.length > 0) {
      var filteredIndicators = indicators.filter(function(indicator) {
        return indicator.name.toLowerCase().includes(e.target.value.toLowerCase());
      });
      setShownIndicators(filteredIndicators)
    } else {
      setShownIndicators(indicators)
    }
  }

  useEffect(()=> {
    fetch(`/api/v1/operative_objectives/${objectiveId}/bcs_indicators`)
      .then((response) => { return response.json() })
      .then((data) => {
        setBscIdicators(data)
      })
      .catch(error => console.error(error))
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch indicators data
        const indicatorsResponse = await fetch(`/api/v1/operative_objectives/${objectiveId}/indicators`);
        const indicatorsData = await indicatorsResponse.json();
        setIndicators(indicatorsData);
        setShownIndicators(indicatorsData); // Assuming you need this elsewhere

        // Fetch bsc_indicators data (assuming a separate endpoint)
        const bscIndicatorsResponse = await fetch(`/api/v1/operative_objectives/${objectiveId}/bcs_indicators`);
        const bscIndicatorsData = await bscIndicatorsResponse.json();
        setBscIdicators(bscIndicatorsData);
        let selected = indicatorsData.filter(indicator => bscIndicatorsData.includes(indicator.id))
        setSelectedIndicators(selected.map(indicator => indicator.id))
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  // useEffect(()=> {
  //   fetch(`/api/v1/operative_objectives/${objectiveId}/indicators`)
  //     .then((response) => { return response.json() })
  //     .then((data) => {
  //       setIndicators(data)
  //       setShownIndicators(data)
        
  //       let selected = data.filter(indicator => indicator.operative_objective_id !== null && indicator.operative_objective_id === props.operative_objective.id)
  //       setSelectedIndicators(selected.map(indicator => indicator.id))
  //     })
  //     .catch(error => console.error(error))
  // }, [])

  return (
    <>
      <form onSubmit={handleSubmit}>
      <Header backUrl={`/bsc_perspectives/${props.perspective_id}/strategic_objectives/${strategic_objective_id}/operative_objectives`}/>
        <div className='card-body'>
          <div className='card shadow mb-3'>
            <div className='card-body'>
              <div className='form-row mb-2'>
                <div className='col-12'>
                  <InputGroup
                    placeholder={'Objetivo Operativo'}
                    value={name}
                    label={'Objetivo Operativo'}
                    icon={'fas fa-file-alt'}
                    setter={setObjective}
                    customProps={{ type: "text", autoFocus: true }}
                  />
                </div>
              </div>
              <div className='form-row'>
                <div className='col-12'>
                  <InputGroup
                    placeholder={'Porcentaje'}
                    value={percentage}
                    label={'Porcentaje'}
                    icon={'fas fa-percentage'}
                    setter={setPercentage}
                    customProps={{ type: "number" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='card shadow mb-3'>
            <div className='card-header bg-white'>
              <div className='form-row'>
                <div className='col-6'>
                  <h6 className='card-title'>Seleccionar Indicadores</h6>
                </div>
                <div className='col-6'>
                  <div className='form-group'>
                    <div className="input-group">
                      <div className='input-group-prepend'>
                        <div className="input-group-text">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </div>
                      </div>
                      <input className='form-control' type='text' placeholder='Buscar' onChange={handleSearchChange} value={searchText}></input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-body'>
              <table className='table table-striped'>
                <thead>
                  <tr>
                    <th></th>
                    <th>Nombre Indicador</th>
                    <th>Proceso</th>
                    <th>Medición</th>
                  </tr>
                </thead>
                <tbody>
                  {shownIndicators.length === 0 && (
                    <tr>
                      <td colSpan="6" style={{ textAlign: 'center' }}>
                        {shownIndicators.length === 0 && <h4>Cargando</h4>}
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="spinner-grow text-dark" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          <div className="spinner-grow text-dark" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          <div className="spinner-grow text-dark" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          <div className="spinner-grow text-dark" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          <div className="spinner-grow text-dark" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          <div className="spinner-grow text-dark" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                  { (shownIndicators.length > 0) && shownIndicators.map((indicator) => {
                    return (
                      <React.Fragment key={indicator.id}>
                        <tr key={indicator.id}>
                          <td>
                            <CheckBox checked={objectiveId !== null && (indicator.operative_objective_id == objectiveId || bsc_idicators.includes(indicator.id))}
                                      id={indicator.id}
                                      checkboxCallback={fillIndicators}/>
                          </td>
                          <td>{indicator.name}</td>
                          <td>{indicator.procedure}</td>
                          <td>{indicator.measure}</td>
                        </tr>
                      </React.Fragment>)
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default CreateObjectiveForm