import { Controller } from 'stimulus'
import Chart from 'chart.js'

export default class extends Controller {
  connect () {
    let { points } = this.element.dataset

    points = JSON.parse(points)

    const labels = points.map(({ chapter }) => chapter)
    const data = points.map(({ value }) => value)
    const colors = points.map(({ color }) => color)
    const total = data.reduce((a, b) => a + b, 0)
    var maxi = Math.max(...data) + Math.ceil(Math.max(...data) / 10)
    if (maxi == 0) { maxi = 1 }
    this.ctx = this.element.getContext('2d')
    this.options = {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: 'Cantidad',
            pointBorderWidth: 5,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: true,
            backgroundColor: colors,
            borderWidth: 2,
            data
          }
        ]
      },
      options: {
        tooltips: {
          titleFontFamily: 'Source Sans Pro',
          titleFontSize: 16,
          bodyFontSize: 16,
          xPadding: 10,
          yPadding: 10
        },
        animation: {
          easing: 'easeInOutBack',
          duration: 0,
          onComplete: function () {
              var chartInstance = this.chart,
                  ctx = chartInstance.ctx;
              ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
              ctx.textAlign = 'center';
              ctx.textBaseline = 'bottom';

              this.data.datasets.forEach(function (dataset, i) {
                  var meta = chartInstance.controller.getDatasetMeta(i);
                  meta.data.forEach(function (bar, index) {
                    var percentage = (100*dataset.data[index] / total).toFixed(2) + '%'
                    var data = dataset.data[index] + ' (' + percentage + ')'
                    ctx.fillText(data, bar._model.x, bar._model.y - 5);
                  });
              });
          }
        },
        legend: {
          display: true,
          position: 'top'
        },
        scales: {
          yAxes: [{
            ticks: {
              fontColor: 'rgba(0,0,0,0.5)',
              fontStyle: 'bold',
              beginAtZero: true,
              maxTicksLimit: maxi,
              max: maxi,
              padding: 20
            },
            gridLines: {
              drawTicks: true,
              display: true
            }
          }],
          xAxes: [{
            gridLines: {
              zeroLineColor: 'transparent'
            },
            ticks: {
              padding: 20,
              fontColor: 'rgba(0,0,0,0.5)',
              fontStyle: 'bold',
              callback: function(label) {
                if (/\s/.test(label)) {
                  return label.split(" ");
                } else{
                  return label;
                }
              }
            }
          }]
        }
      }
    }
    this.chart = new Chart(this.ctx, this.options)
  }
}
