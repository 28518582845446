import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

function CollapseTypeLaboratory({
  index,
  dataLaboratory,
  testExecution,
  updateNumerUnit,
  udpateAcceptanceCriteria,
  udpateUncertainty,
  udpateResult,
  updateDeclarationConformity
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-3">
      {/* Botón para desplegar/colapsar */}
      <button 
        className="btn btn-link text-left w-100" 
        onClick={toggleCollapse}
        aria-expanded={isOpen}
        type="button"
      >
        <h6 className="mb-0 font-weight-semibold text-dark">
          {index+1}: {dataLaboratory.name}
        </h6>
      </button>

      {/* Contenido colapsable */}
      <div className={`collapse ${isOpen ? 'show' : ''}`}>
        {/* Descripción */}
        <div className="p-3 bg-light border rounded mb-3">
          <p><strong>Nombre:</strong> {dataLaboratory.type_laboratory_test.name}</p>
          <p><strong>Objetivo:</strong> {dataLaboratory.type_laboratory_test.objective}</p>
          <p><strong>Definiciones:</strong></p>
          <ul>
            {dataLaboratory.type_laboratory_test.definition_laboratories.map((definition, index) => (
              <li key={index}><strong>{index+1}- Descripción: </strong>{definition.description}</li>
            ))}
          </ul>
          <p><strong>Personal Cualificado Necesario:</strong> {dataLaboratory.type_laboratory_test.qualified_personnel_required}</p>
          <p><strong>Norma regional, nacional o internacional en la que se basa:</strong> {dataLaboratory.type_laboratory_test.standard}</p>
          <p><strong>Parámetros por determinar :</strong></p>
          <ul>
            {dataLaboratory.type_laboratory_test.determine_laboratory_params.map((definition, index) => (
              <li key={index}> <strong>{index+1}- Nombre: </strong>{definition.name}  <strong>Descripción: </strong>{definition.description}</li>
            ))}
          </ul>
          <p><strong>Criterios de aceptación:</strong> {dataLaboratory.type_laboratory_test.acceptance_criteria}</p>
          <p><strong>Método:</strong> {dataLaboratory.type_laboratory_test.method}</p>
        </div>

        {/* Resultados */}
        <h5>Resultados</h5>
        <table className="table table-bordered">
          <thead className="bg-danger text-white">
            <tr>
              <th>No. Unidades</th>
              <th>Parámetros de Prueba</th>
              <th>Criterio de Aceptación</th>
              <th>Incertidumbre</th>
              <th>Declaración de Conformidad</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <textarea
                  className="form-control"
                  value={testExecution.number_unit}
                  onChange={(e) => updateNumerUnit(e, dataLaboratory.type_laboratory_test_id)}
                />
              </td>
              <td>
                <textarea
                  className="form-control"
                  value={testExecution.acceptance_criteria}
                  onChange={(e) => udpateAcceptanceCriteria(e, dataLaboratory.type_laboratory_test_id)}
                />
              </td>
              <td>
                <textarea
                  className="form-control"
                  value={testExecution.uncertainty}
                  onChange={(e) => udpateUncertainty(e, dataLaboratory.type_laboratory_test_id)}
                />
              </td>
              <td>
                <textarea
                  className="form-control"
                  value={testExecution.result}
                  onChange={(e) => udpateResult(e, dataLaboratory.type_laboratory_test_id)}
                />
              </td>
              <td>
                <div className="form-check form-check-inline ml-1 mt-1">
                  <div className="ml-3 mt-2">
                    <input
                      type="radio"
                      name={`EnvironmentalConditions${dataLaboratory.id}`} // Mismo name para ambos botones
                      value={true}
                      checked={testExecution.declaration_conformity === true}
                      onChange={() => updateDeclarationConformity(dataLaboratory.type_laboratory_test_id, true)} // Cambiar a true
                    />
                    <label>Aprobado</label>
                  </div>
                  <div className="ml-3 mt-2">
                    <input
                      type="radio"
                      name={`EnvironmentalConditions${dataLaboratory.id}`} // Mismo name para ambos botones
                      value={false}
                      checked={testExecution.declaration_conformity === false}
                      onChange={() => updateDeclarationConformity(dataLaboratory.type_laboratory_test_id, false)} // Cambiar a false
                    />
                    <label>No aprobado</label>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export { CollapseTypeLaboratory };
