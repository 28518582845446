import React, { useEffect, useState } from 'react'

const FindingsModalBody = ({
  auditNormId,
  ShowModal,
  auditNorms,
}) => {
  const [initialValues, setInitialValues] = useState({});
  useEffect(() => {
    const initialValue = auditNorms.filter((norm) => norm.id === auditNormId);
    setInitialValues({
      find_type_id: initialValue[0].find_type_id,
      joint: initialValue[0].joint !== null ? initialValue[0].joint : initialValue[0].description,
    })
  }, [])
  
  const [dataFindings, setDataFindings] = useState({
    find_type_id: 'dc461c26-b8a5-4baa-ac49-05f7da01936a',
    joint: '',
  });

  const handleChange = (e) => {
    setDataFindings({...dataFindings, joint: e.target.value });
  }

  const handleSelect = (e) => {
    setDataFindings({...dataFindings, find_type_id: e.target.value });
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    fetch(`/api/v2/audit_norms/${auditNormId}`,{
      method: 'PATCH',
      body: JSON.stringify(dataFindings),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then((response)=> { response.json() })
      .then(() => { window.location.reload(); })
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className='form-row mb-1'>
        <div className='col-md-12 js-parent mb-3'>
          <label htmlFor='select'>HALLAZGO</label>
          <select className='form-control' value={initialValues.find_type_id} id="select" onChange={handleSelect}>
            <option value="dc461c26-b8a5-4baa-ac49-05f7da01936a" defaultValue={ initialValues.find_type_id === 'dc461c26-b8a5-4baa-ac49-05f7da01936a'}>No conforme</option>
            <option value="7114f175-5391-43f1-b40a-1b7fb6679e72" defaultValue={ initialValues.find_type_id === '7114f175-5391-43f1-b40a-1b7fb6679e72'}>Observación</option>
            <option value="fc6f7d60-f90b-4e5c-905e-14bd04fc7ac2" defaultValue={ initialValues.find_type_id === 'fc6f7d60-f90b-4e5c-905e-14bd04fc7ac2'}>Conforme</option>
          </select>
        </div>
        <div className='col-md-12 js-parent mb-3'>
          <label htmlFor='description'>DESCRIPCIÓN</label>
          <textarea className='form-control' name="audit_norm[joint]" id="description" rows="3" onChange={handleChange} defaultValue={initialValues.joint}></textarea>
        </div>
        <button className='btn btn-link' type='button' id='cancel_button' onClick={()=>ShowModal(false)}> Cancelar </button>
        <button className='btn btn-primary ml-auto' type='submit' id='submit_button' name='commit' value='ready'> Guardar </button>
      </div>
    </form>
  )
}

export default FindingsModalBody