import { Controller }  from 'stimulus'

export default class extends Controller {
  static targets = ['checkAll', 'select', 'users']
  connect() {
    this.addOptions()
    if (this.hasUsersTarget) {
      this.showAll()
    }
  }

  addOptions() {
    const employees = JSON.parse(this.data.get('employees'))
    var departments = document.getElementsByClassName('TrainingDepartment')
    var arrDepartments = []
    var ids = []
    if (departments.length > 0) {
      for (var x = 0; x < departments.length; x++) {
        arrDepartments.push(departments[x].value)
      } // for(var x = 0; x < departments.length; x++)
    }
    for (var e = 0; e < employees.length; e++) {
      if (arrDepartments.includes(employees[e].department)) {
        ids.push(employees[e].id)
      }
    }
    var selectFields = document.getElementsByClassName('TrainingEmployee')
    if (selectFields.length > 0) {
      var news = ids.slice(0)
      for (var x = 0; x < selectFields.length; x++) {
        for (var y = selectFields[x].length-1; y >=0 ; y--){
          if (!ids.includes(selectFields[x].options[y].value)){
            selectFields[x].remove(y) //quita elementos de los select
          } else {
            var index = news.indexOf(selectFields[x].options[y].value)
            if (index != -1) { news.splice(index,1) }
          }
        }
      } // (var x = 0; x < selectFields.length; x++)
      // Agregar opciones cuando se agrega depencencia o comité
      for (var t=0; t < employees.length; t++){
        if (news.includes(employees[t].id)) {
          for (var s = 0; s < selectFields.length; s++ ) {
            var opt1 = document.createElement('option')
            opt1.value = employees[t].id
            opt1.text = employees[t].full_name
            selectFields[s].add(opt1, null)
          }
        }
      }
    }
  }

  showAll() {
    const employees = JSON.parse(this.data.get('employees'))
    $(this.usersTarget).empty()
    if ($(this.checkAllTarget).prop('checked')) {
      var departmentId =  $(this.selectTarget).val()

      for (var e = 0; e < employees.length; e++) {
        if (employees[e].department == departmentId) {
          this.usersTarget.innerHTML += employees[e].full_name + '. '
          //$(this.usersTarget).append(`<li class="list-group-item">${employees[e].full_name}</li>`)
        }
      }
    }
  }
}
