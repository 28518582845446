import React, { createContext, useState } from "react";
import "dayjs/locale/es";
import dayjs from "dayjs";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [currentUserId, setCurrentUserId] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [currentDay, setCurrentDay] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [eventEditId, setEventEditId] = useState(null);
  const [followId, setFollowId] = useState(null);
  const [token, setToken] = useState(null);
  const [flaged, setFlaged] = useState(false);
  const [selectedPeriodicity, setSelectedPeriodicity] = useState("");
  const [typeTime, setTypeTime] = useState(0);
  const [editEvent, setEditEvent] = useState({
    event: {
      title: "",
      event_type: "",
      start_date: "",
      end_date: "",
      periodicity_id: "",
      place: "",
      link: "",
      description: "",
      archives: null,
      reminder_date: "",
      reminder_users: "",
      procedure_id: "",
      management_id: "",
      employee_id: "",
    },
  });
  const [eventInformation, setEventInformation] = useState({
    event: {
      title: "",
      event_type: "",
      start_date: "",
      end_date: "",
      periodicity_id: "",
      place: "",
      link: "",
      description: "",
      archives: null,
      reminder_date: "",
      reminder_users: "",
      procedure_id: "",
      management_id: "",
      employee_id: "",
    },
  });

  // Actualizar el mes y año seleccionados en base a la fecha actual
  useState(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    setSelectedMonth(currentMonth);
    setSelectedYear(currentYear);
  }, []);

  // Configurar el idioma de Day.js
  useState(() => {
    dayjs.locale("es");
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        selectedDate,
        setSelectedDate,
        currentDay,
        setCurrentDay,
        showModal,
        setShowModal,
        eventInformation,
        setEventInformation,
        showEditModal,
        setShowEditModal,
        editEvent,
        setEditEvent,
        flaged,
        setFlaged,
        typeTime,
        setTypeTime,
        selectedMonth,
        setSelectedMonth,
        selectedYear,
        setSelectedYear,
        eventEditId,
        setEventEditId,
        currentUserId,
        setCurrentUserId,
        token,
        setToken,
        followId,
        setFollowId,
        selectedPeriodicity,
        setSelectedPeriodicity,
        showDeleteModal,
        setShowDeleteModal
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
