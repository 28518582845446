import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  static targets = ['probability', 'percentage', 'impact', 'detectability', 'npr', 'risk', 'action', 'achievement1', 'achievement2', 'date']

  connect () {
    const receivedString = this.element.dataset.user
    const keyValuePairs = receivedString.replace(/\s/g, "").split(",");
    const values = {};
    keyValuePairs.forEach((pair) => {
      const [key, value] = pair.split(":");
      values[key] = value.trim();
    });
    this.montagasLogged = JSON.parse(values.montagas_log);
    this.uplbarranquilla = JSON.parse(values.uplbarranquilla_log);
    this.selectName = this.achievement1Target.name
    if (this.uplLogged) {
      this.impactTarget.disabled = true;
      this.addEventListeners();
    } else {
      this.onChange();
    }
  }

  addEventListeners() {
    this.element.addEventListener('impactupl:change', this.onImpactuplChange.bind(this));
  }

  onImpactuplChange(event) {
    const value = event.detail.value;
    this.impactTarget.value = value;
    this.onChange();
  }

  onChange (e) {
    if (e) e.preventDefault()
    const { value } = this.percentageTarget
    var probability = document.getElementById("probability").value
    var impact = document.getElementById("impact").value
    var valuetwo = probability * impact
    $(this.nprTarget).prop('value', valuetwo)
    document.getElementById("npr").setAttribute("value", valuetwo)

    $(this.riskTarget).prop('value', valuetwo)
    if (this.uplbarranquilla){
      if ((Number(valuetwo) > 0) && (Number(valuetwo) < 11 )) {
        $(this.riskTarget).prop('value', 'Bajo')
        if (this.montagasLogged) {
          $(this.actionTarget).prop('value', 'Asumir o Aceptar')
        }else
        {
          $(this.actionTarget).prop('value', 'Aceptar')
        }
        

        document.getElementById("npr").style.backgroundColor='#46E7FA';
        document.getElementById("risk_follow_risk_level").style.backgroundColor='#46E7FA';
        document.getElementById("risk_follow_recommended_action").style.backgroundColor='#46E7FA';
        document.getElementById("npr").style.color='#4F4F4F';
        document.getElementById("risk_follow_risk_level").style.color='#4F4F4F';
        document.getElementById("risk_follow_recommended_action").style.color='#4F4F4F';

      } else if ((Number(valuetwo) < 36) && (Number(valuetwo) > 10)) {
        $(this.riskTarget).prop('value', 'Moderado')
        $(this.actionTarget).prop('value', 'Reducir')

        document.getElementById("npr").style.backgroundColor='#FAFA46';
        document.getElementById("risk_follow_risk_level").style.backgroundColor='#FAFA46';
        document.getElementById("risk_follow_recommended_action").style.backgroundColor='#FAFA46';
        document.getElementById("npr").style.color='#4F4F4F';
        document.getElementById("risk_follow_risk_level").style.color='#4F4F4F';
        document.getElementById("risk_follow_recommended_action").style.color='#4F4F4F';

      } else if ((Number(valuetwo) < 70) && (Number(valuetwo) > 35)) {
        $(this.riskTarget).prop('value', 'Importante')
        if (this.montagasLogged) {
          $(this.actionTarget).prop('value', 'Transferir o Mitigar')
        }else{
          $(this.actionTarget).prop('value', 'Compartir o Mitigar')
        }
        document.getElementById("npr").style.backgroundColor='#FFC100';
        document.getElementById("risk_follow_risk_level").style.backgroundColor='#FFC100';
        document.getElementById("risk_follow_recommended_action").style.backgroundColor='#FFC100';
        document.getElementById("npr").style.color='#4F4F4F';
        document.getElementById("risk_follow_risk_level").style.color='#4F4F4F';
        document.getElementById("risk_follow_recommended_action").style.color='#4F4F4F';

      } else if ((Number(valuetwo) > 69)) {
        $(this.riskTarget).prop('value', 'Inaceptable')
        $(this.actionTarget).prop('value', 'Mitigar o Eliminar')

        document.getElementById("npr").style.backgroundColor='#B42100';
        document.getElementById("risk_follow_risk_level").style.backgroundColor='#B42100';
        document.getElementById("risk_follow_recommended_action").style.backgroundColor='#B42100';
        document.getElementById("npr").style.color='white';
        document.getElementById("risk_follow_risk_level").style.color='white';
        document.getElementById("risk_follow_recommended_action").style.color='white';
      }
    }else{
      if ((Number(valuetwo) > 0) && (Number(valuetwo) < 4 )) {
        $(this.riskTarget).prop('value', 'Bajo')
        if (this.montagasLogged) {
          $(this.actionTarget).prop('value', 'Asumir o Aceptar')
        }else
        {
          $(this.actionTarget).prop('value', 'Aceptar')
        }
        

        document.getElementById("npr").style.backgroundColor='#46E7FA';
        document.getElementById("risk_follow_risk_level").style.backgroundColor='#46E7FA';
        document.getElementById("risk_follow_recommended_action").style.backgroundColor='#46E7FA';
        document.getElementById("npr").style.color='#4F4F4F';
        document.getElementById("risk_follow_risk_level").style.color='#4F4F4F';
        document.getElementById("risk_follow_recommended_action").style.color='#4F4F4F';

      } else if ((Number(valuetwo) < 7) && (Number(valuetwo) > 3)) {
        $(this.riskTarget).prop('value', 'Moderado')
        $(this.actionTarget).prop('value', 'Reducir')

        document.getElementById("npr").style.backgroundColor='#FAFA46';
        document.getElementById("risk_follow_risk_level").style.backgroundColor='#FAFA46';
        document.getElementById("risk_follow_recommended_action").style.backgroundColor='#FAFA46';
        document.getElementById("npr").style.color='#4F4F4F';
        document.getElementById("risk_follow_risk_level").style.color='#4F4F4F';
        document.getElementById("risk_follow_recommended_action").style.color='#4F4F4F';

      } else if ((Number(valuetwo) < 13) && (Number(valuetwo) > 6)) {
        $(this.riskTarget).prop('value', 'Importante')
        if (this.montagasLogged) {
          $(this.actionTarget).prop('value', 'Transferir o Mitigar')
        }else{
          $(this.actionTarget).prop('value', 'Compartir o Mitigar')
        }
        document.getElementById("npr").style.backgroundColor='#FFC100';
        document.getElementById("risk_follow_risk_level").style.backgroundColor='#FFC100';
        document.getElementById("risk_follow_recommended_action").style.backgroundColor='#FFC100';
        document.getElementById("npr").style.color='#4F4F4F';
        document.getElementById("risk_follow_risk_level").style.color='#4F4F4F';
        document.getElementById("risk_follow_recommended_action").style.color='#4F4F4F';

      } else if ((Number(valuetwo) < 26) && (Number(valuetwo) > 12)) {
        $(this.riskTarget).prop('value', 'Inaceptable')
        $(this.actionTarget).prop('value', 'Mitigar o Eliminar')

        document.getElementById("npr").style.backgroundColor='#B42100';
        document.getElementById("risk_follow_risk_level").style.backgroundColor='#B42100';
        document.getElementById("risk_follow_recommended_action").style.backgroundColor='#B42100';
        document.getElementById("npr").style.color='white';
        document.getElementById("risk_follow_risk_level").style.color='white';
        document.getElementById("risk_follow_recommended_action").style.color='white';
      }
    }
    if (Number(value) < 100) {
      $(this.dateTarget).prop('disabled', true)
      $(this.achievement1Target).hide()
      this.achievement1Target.name = ''
      $(this.achievement2Target).show()
      this.achievement2Target.name = this.selectName
    } else {
      $(this.dateTarget).prop('disabled', false)
      $(this.dateTarget).val(null).trigger('change')
      $(this.achievement1Target).show()
      this.achievement1Target.name = this.selectName
      $(this.achievement2Target).hide()
      this.achievement2Target.name = ''
    }
  }
  showbuttons () {
    document.getElementById('cancel_button').className = 'btn btn-link';
    document.getElementById('submit_button').className = 'btn btn-primary';
    this.onChange()
  }
}
