import React, { useState, useEffect } from "react";
import NatureModal from "../Form/NatureModal";
import IntensityModal from "../Form/IntensityModal";
import ExtensionModal from "../Form/ExtensionModal";
import MomentumModal from "../Form/MomentumModal";
import PersistencyModal from "../Form/PersistencyModal";
import ReversibilityModal from "../Form/ReversibilityModal";
import SinergyModal from "../Form/SinergyModal";
import AccumulateModal from "../Form/AccumulateModal";
import EffectModal from "../Form/EffectModal";
import PeriodicityModal from "../Form/PeriodicityModal";
import RecuperabilityModal from "../Form/RecuperabilityModal";

const Modal = ({ modalFlag, setFunction }) => {
  return (
    <div
      className="modal fade show"
      tabIndex="-1"
      style={{
        paddingRight: "4px",
        display: "block",
        backgroundColor: "rgba(53, 53, 53, 0.5)",
      }}
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-scrollable"
        role="document"
        style={{ maxWidth: "70%" }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Evaluación de Impacto</h4>
            <button
              type="btn btn-link"
              className="close"
              onClick={() => setFunction(false)}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {modalFlag === 0 && <NatureModal />}
            {modalFlag === 1 && <IntensityModal />}
            {modalFlag === 2 && <ExtensionModal />}
            {modalFlag === 3 && <MomentumModal />}
            {modalFlag === 4 && <PersistencyModal />}
            {modalFlag === 5 && <ReversibilityModal />}
            {modalFlag === 6 && <SinergyModal />}
            {modalFlag === 7 && <AccumulateModal />}
            {modalFlag === 8 && <EffectModal />}
            {modalFlag === 9 && <PeriodicityModal />}
            {modalFlag === 10 && <RecuperabilityModal />}
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-secondary"
              onClick={() => setFunction(false)}
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
