import { Controller } from 'stimulus'
import Chart from 'chart.js'

export default class extends Controller {
  connect () {
    let { points } = this.element.dataset

    points = JSON.parse(points)

    const labels = points.map(({ chapter }) => chapter)
    const data = points.map(({ value }) => value)
    const colors = points.map(({ color }) => color)
    var maxi = Math.max(...data)
    if (maxi == 0) { maxi = 1 }
    this.ctx = this.element.getContext('2d')
    this.options = {
      type: 'pie',
      data: {
        labels,
        datasets: [
          {
            label: 'Cantidad',
            pointBorderWidth: 5,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: true,
            backgroundColor: colors,
            borderWidth: 2,
            data
          }
        ]
      },
      options: {
        tooltips: {
          titleFontFamily: 'Source Sans Pro',
          titleFontSize: 16,
          bodyFontSize: 16,
          xPadding: 10,
          yPadding: 10
        },
        animation: {
          easing: 'easeOutQuart',
          duration: 500,
          onComplete: function () {
            const ctx = this.chart.ctx;
            ctx.font = '16px Source Sans Pro';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
        
            this.chart.data.datasets.forEach((dataset) => {
              const meta = this.chart.getDatasetMeta(0);
              meta.data.forEach((point, index) => {
                const model = point._model;
                const total = dataset.data.reduce((acc, val) => acc + val, 0);
                const percent = `${Math.round((dataset.data[index] / total) * 100)}%`;
        
                // Coordenadas para colocar la etiqueta
                const midRadius = model.innerRadius + (model.outerRadius - model.innerRadius) / 2;
                const startAngle = model.startAngle;
                const endAngle = model.endAngle;
                const midAngle = startAngle + (endAngle - startAngle) / 2;
        
                const x = model.x + midRadius * Math.cos(midAngle);
                const y = model.y + midRadius * Math.sin(midAngle);
        
                if (dataset.data[index] !== 0) {
                  // Fondo de la etiqueta
                  const padding = 5;
                  const textWidth = ctx.measureText(dataset.data[index]).width;
                  const percentWidth = ctx.measureText(percent).width;
                  const rectWidth = Math.max(textWidth, percentWidth) + padding * 2;
                  const rectHeight = 35;
                  const borderRadius = 10; // Radio de las esquinas redondeadas

                  // Posición del rectángulo
                  const rectX = x - rectWidth / 2;
                  const rectY = y - rectHeight / 2;

                  // Dibuja el fondo con esquinas redondeadas
                  ctx.fillStyle = 'rgba(0, 0, 0, 0.4)'; // Fondo semitransparente
                  ctx.beginPath();
                  ctx.moveTo(rectX + borderRadius, rectY);
                  ctx.lineTo(rectX + rectWidth - borderRadius, rectY);
                  ctx.quadraticCurveTo(rectX + rectWidth, rectY, rectX + rectWidth, rectY + borderRadius);
                  ctx.lineTo(rectX + rectWidth, rectY + rectHeight - borderRadius);
                  ctx.quadraticCurveTo(rectX + rectWidth, rectY + rectHeight, rectX + rectWidth - borderRadius, rectY + rectHeight);
                  ctx.lineTo(rectX + borderRadius, rectY + rectHeight);
                  ctx.quadraticCurveTo(rectX, rectY + rectHeight, rectX, rectY + rectHeight - borderRadius);
                  ctx.lineTo(rectX, rectY + borderRadius);
                  ctx.quadraticCurveTo(rectX, rectY, rectX + borderRadius, rectY);
                  ctx.closePath();
                  ctx.fill();

                  // Texto de la etiqueta
                  ctx.fillStyle = '#fff';
                  ctx.fillText(dataset.data[index], x, y - 8);
                  ctx.fillText(percent, x, y + 8);
                }
              });
            });
          },
        },
        legend: {
          display: true,
          position: 'top'
        }
      }
    }
    this.chart = new Chart(this.ctx, this.options)
  } //
} //

