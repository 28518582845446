import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  static targets = ['dateField']

  connect () {
    if (this.dateFieldTarget.value) {
      var date = new Date(this.dateFieldTarget.value)
      this.dateFieldTarget.value = this.shortDate(date)
    }
  }

  shortDate (initialDate) {
    return (initialDate.toLocaleDateString('zh-Hans-CN', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }))
  }
}
