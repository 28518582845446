import { Controller } from 'stimulus'

export default class extends Controller {
  onClick (e) {
    e.preventDefault()

    const fields = this.data.get('fields')
    const obj = $(fields)

    var id = new Date().getTime()

    obj.find('input, select, textarea').each(function () {
      $(this).attr('name', function () {
        return $(this)
          .attr('name')
          .replace('new_record', id)
      })
      $(this).attr('id', id)
    })

    obj.insertBefore(this.element)
  }
}
