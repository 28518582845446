import React from "react";
import { Bar } from "react-chartjs-2";

export default function GraphicsSecondary({ managedValue, toManageValue }) {
  const values = [0, managedValue, toManageValue];
  const plans = ["", "Planes Gestionados", "Planes Por Gestionar"];

  const myData = {
    labels: plans,
    datasets: [
      {
        label: "Cantidad",
        data: values,
        tension: 0.5,
        fill: true,
        borderColor: "rgb(0, 150, 136)",
        backgroundColor: "rgba(0, 150, 136, 0.5)",
        pointRadius: 5,
        pointBorderColor: "rgba(0, 150, 136)",
        pointBackgroundColor: "rgba(0, 150, 136)",
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        ticks: { color: "rgb(255, 99, 132)" },
      },
    },
  };

  return <Bar data={myData} options={options} />;
}
