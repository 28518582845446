import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
function Header({
  title,
  buttonBusiness,
  iconBack,
  buttonNewElement,
  children,
}) {
  return (
    <>
      <Navbar variant="dark" className="bg-dark position-relative " expand="lg">
        <Container fluid>
          {iconBack}
          <Navbar.Brand>{title}</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav">
            <Nav className="me-auto">{children}</Nav>
            <Nav className="px-2">{buttonBusiness}</Nav>
            <Nav className="me-auto"> {buttonNewElement}</Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
