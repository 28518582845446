import React from "react";
import axios from "axios";

function serviceTypeLaboratory(){
  const [typeLaboratory, setTypeLaboratory] = React.useState([])
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const getData = async () => {
      try {
        const url = `/api/v2/type_laboratory_tests`;
        const response = await axios.get(`${url}`);
        if (response.status === 200) {
          const data = await response.data;

          setTypeLaboratory(data)
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    getData();
    return () => {
      setTests([]);
      setError(null);
    };
  }, [])

  return {
    loading,
    error,
    typeLaboratory
  }
}

export { serviceTypeLaboratory }