import React, { useEffect, useState } from "react";
import Aspect from "./Aspect";
import { GetEnvironmentalAspects } from "./Requests";

const Activity = ({
  indexActivity,
  deleteActivity,
  activitiesAux,
  setActivitiesAux,
  activities,
  setActivities,
  envImpact,
  setEnvImpact,
  activity,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const [aspects, setAspects] = useState([]);
  const [envAspectsAux, setEnvAspectsAux] = useState([]);
  const [envAspects, setEnvAspects] = useState([]);
  const [envAspectsCounter, setEnvAspectsCounter] = useState(0);

  const handleChange = (e) => {
    const { value } = e.target;
    const updatedActivities = [...activitiesAux];
    updatedActivities[indexActivity] = {
      ...updatedActivities[indexActivity],
      activityInput: value,
    };
    setActivitiesAux(updatedActivities);
  };

  const handleRadioChange = (value) => {
    const updatedActivities = [...activitiesAux];
    updatedActivities[indexActivity] = {
      ...updatedActivities[indexActivity],
      situationInput: value,
    };
    setActivitiesAux(updatedActivities);
  };

  useEffect(() => {
    const formattedActivities = activitiesAux.reduce((acc, activity, index) => {
      acc[index] = {
        activity: activity.activityInput,
        situation: activity.situationInput,
        env_impact_aspects_attributes: activity.aspects,
        _destroy: false,
      };
      return acc;
    }, {});
    setActivities(formattedActivities);
  }, [activitiesAux]);

  useEffect(() => {
    const updatedImpact = { ...envImpact };
    updatedImpact.environmental_impact.env_impact_activities_attributes =
      activities;
    setEnvImpact(updatedImpact);
  }, [activities]);

  useEffect(() => {
    GetEnvironmentalAspects().then((data) => {
      setAspects(data);
    });
  }, []);

  useEffect(() => {
    if (aspects.length > 0) {
      const newAspect = {
      indexEnvAspect: 0,
      environmentalSelect: aspects[0].id,
      descriptionInput: '',
      }
      setEnvAspectsAux([newAspect]);
    }
  }, [aspects]);

  const addAspect = () => {
    const localCounter = envAspectsCounter + 1;
    const newAspect = {
      indexEnvAspect: localCounter,
      environmentalSelect: aspects[0].id,
      descriptionInput: "",
      evaluations: {},
    };
    setEnvAspectsCounter(envAspectsCounter + 1);
    setEnvAspectsAux([...envAspectsAux, newAspect]);
  };

  const deleteAspect = (e) => {
    const updatedAspects = envAspectsAux.filter(
      (aspect) => aspect.indexEnvAspect !== e
    );
    const reindexedAspects = updatedAspects.map((aspect, index) => ({
      ...aspect,
      indexEnvAspect: index,
    }));
    setEnvAspectsCounter(envAspectsCounter - 1)
    setEnvAspectsAux(reindexedAspects);
  };

  if (collapsed) {
    return (
      <>
        <div className="form-row ">
          <div className="col-md-12">
            <div className="d-flex form-group mb-3">
              <div
                className="d-flex justify-content-between pr-2 input-group-text col-md-11 cursor-pointer"
                onClick={() => setCollapsed(false)}
              >
                <div className="">
                  <span>Actividad #{indexActivity + 1}</span>
                </div>
                <div>
                  <i className="fas fa-chevron-down"></i>
                </div>
              </div>
              <div className="col-md-1 d-flex justify-content-center">
                <a
                  className="btn btn-outline-danger"
                  onClick={() => deleteActivity(indexActivity)}
                >
                  <i className="fas fa-times text-danger"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div>
        <div className="form-row ">
          <div className="col-md-12">
            <div className="form-group mb-3">
              <div className="d-flex col-md-12 mb-3 p-0">
                <div
                  className="d-flex justify-content-between pr-2 input-group-text col-md-11 cursor-pointer"
                  onClick={() => setCollapsed(true)}
                >
                  <div className="">
                    <span>Actividad #{indexActivity + 1}</span>
                  </div>
                  <div>
                    <i className="fas fa-chevron-up"></i>
                  </div>
                </div>
                <div className="col-md-1 d-flex justify-content-center">
                  <a
                    className="btn btn-outline-danger"
                    onClick={() => deleteActivity(indexActivity)}
                  >
                    <i className="fas fa-times text-danger"></i>
                  </a>
                </div>
              </div>
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <i className="fas fa-file-alt form-icon-small"></i>
                  </div>
                </div>
                <textarea
                  className="form-control"
                  onChange={(e) => handleChange(e)}
                  value={activity?.activityInput}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>Situación: </label>
            </div>
            <div className="form-group form-check-inline flex-wrap">
              <div className="d-flex justify-content-center mr-4 ">
                <input
                  className="mr-2"
                  type="radio"
                  checked={activity?.situationInput === 0}
                  onChange={() => handleRadioChange(0)}
                />
                <label className="mb-0 mr-2">Normal</label>
              </div>
              <div className="d-flex justify-content-center mr-4 ">
                <input
                  className="mr-2"
                  type="radio"
                  checked={activity?.situationInput === 1}
                  onChange={() => handleRadioChange(1)}
                />
                <label className="mb-0 mr-2">Anormal</label>
              </div>
              <div className="d-flex justify-content-center mr-4 ">
                <input
                  className="mr-2"
                  type="radio"
                  checked={activity?.situationInput === 2}
                  onChange={() => handleRadioChange(2)}
                />
                <label className="mb-0 mr-2">Emergencia</label>
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow mb-3">
          {envAspectsAux.map((aspect) => (
            <Aspect
              key={aspect.indexEnvAspect}
              indexAspect={aspect.indexEnvAspect}
              aspect={aspect}
              deleteAspect={deleteAspect}
              aspects={aspects}
              indexActivity={indexActivity}
              envImpact={envImpact}
              setEnvImpact={setEnvImpact}
              envAspectsAux={envAspectsAux}
              setEnvAspectsAux={setEnvAspectsAux}
              envAspects={envAspects}
              setEnvAspects={setEnvAspects}
              activitiesAux={activitiesAux}
              setActivitiesAux={setActivitiesAux}
            />
          ))}
          <div className="card-body p-3">
            <button
              type="button"
              className="btn btn-info btn-sm mb-2"
              onClick={addAspect}
            >
              <i className="fas fa-plus mr-1"></i>
              Agregar
            </button>
            <span className="ml-2"> Agregar Aspecto</span>
          </div>
        </div>
      </div>
    );
  }
};

export default Activity;
