import React from 'react';

const Hour = ({ title, eventOn, duration, color }) => {
  const hourStyle = {
    backgroundColor: eventOn ? `${color}` : 'transparent',
    color: eventOn ? 'white' : 'black',
    border: eventOn ? '1px solid ' : '',
    borderRadius: eventOn ? '3px' : '',
    padding: eventOn ? '3px' : '',
    marginBottom: eventOn ? '4px' : '',
    marginLeft:"5%",
    marginTop: eventOn ? '4px' : '',
    cursor: eventOn ? 'pointer' : '',
    zIndex: eventOn ? 15 : 0,
    position: 'absolute',
    width: '90%',
    height: eventOn ? `${duration * 34}px` : '0',
  };

  const titleContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <div className="align-content-center justify-content-center" style={{ position: 'relative' }}>
      <div className="align-content-center justify-content-center" style={hourStyle}>
        {eventOn && (
          <div className="event-title text-center" style={titleContainerStyle}>
            <p className="h6 text-dark">{title}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Hour;
