import { useCallback, useState, useMemo } from "react";

const removeDiacritics = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const useTextRenderer = () => {
  const [searchPageIndex, setSearchPageIndex] = useState(new Set());
  const [searchText, setSearchText] = useState("");
  const [nextPageIndex, setNextPageIndex] = useState(-1);
  const pagesArray = useMemo(
    () => Array.from(searchPageIndex).sort((a, b) => a - b),
    [searchPageIndex]
  );

  const highlightPattern = (text, pattern, pageIndex) => {
    if (!pattern) {
      return text;
    }

    const normalizedText = removeDiacritics(text);
    const normalizedPattern = removeDiacritics(pattern);

    const regex = new RegExp(`${normalizedPattern}`, "gi");
    if (!regex.test(normalizedText)) {
      return text;
    }

    setSearchPageIndex((curr) => new Set([...Array.from(curr.add(pageIndex))]));

    return text.replace(
      new RegExp(`(${normalizedPattern})`, "gi"),
      (value) =>
        `<span style="background: rgba(255, 0, 0, 0.5)">${value}</span>`
    );
  };

  const textRenderer = useCallback(
    ({ str, pageIndex }) => highlightPattern(str, searchText, pageIndex),
    [searchText]
  );

  const handlePrevTerm = useCallback(() => {
    if (pagesArray.length == 0) {
      return;
    }

    if (nextPageIndex - 1 >= 0) {
      setNextPageIndex((currState) => currState - 1);
    } else {
      setNextPageIndex(pagesArray.length - 1);
    }
  }, [nextPageIndex, pagesArray]);

  const handleNextTerm = useCallback(() => {
    if (pagesArray.length == 0) {
      return;
    }

    if (nextPageIndex + 1 < pagesArray.length) {
      setNextPageIndex((currState) => currState + 1);
    } else {
      setNextPageIndex(0);
    }
  }, [nextPageIndex, pagesArray]);

  return {
    searchText,
    setSearchText,
    searchPageIndex,
    setSearchPageIndex,
    textRenderer,
    handlePrevTerm,
    handleNextTerm,
    nextPageIndex,
    setNextPageIndex,
    pagesArray,
  };
};
