import React from "react";

function Main(props){
  return(
    <div className="card px-2 py-2 vh-100">
      <div className="card-header text-center bg-white">
        <h3 className="text-center font-weight-bold text-dark ml-3">
          {props.portal.short_name}
        </h3>
        <h4 className="text-wrap font-weight-bold text-dark">
          Ingreso de laboratorio
        </h4>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 col-md-6">
            {/* <a className="btn btn-block btn-lg btn-primary shadow mb-2" href="https://www.sigiswo.com/main_laboratories_new?portal=true&layout=pqrsf&type=new">
              <h4 className="text-white">Crear solicitud de laboratorio</h4>
            </a>
             */}
            <a 
              className="btn btn-block btn-lg shadow mb-2 btn-primary"
              href={`/main_laboratories_new?portal_id=${props.portal.id}&layout=pqrsf&type=new&color=true`}
              style={{backgroundColor: props.color}}
            >
              <h4 className="text-white">Crear solicitud de laboratorio</h4>
            </a>
          </div>
          <div className="col-12 col-md-6">
            <a
              href={`/status_laboratories?portal_id=${props.portal.id}&layout=pqrsf&type=new&color=true`}
              className="btn btn-block btn-lg btn-primary shadow mb-2"
              style={{backgroundColor: props.color}}
            >
              <h4 className="text-white">Consultar solicitud de laboratorio</h4>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main