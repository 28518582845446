import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['employeesNumber', 'yearUnit', 'result', 'totalResult']

  connect () {
    this.onChange()
  }

  onChange (e) {
    var units = this.yearUnitTarget.value
    var number = this.employeesNumberTarget.value
    var result = number * units
    this.totalResultTarget.value = result
    this.resultTarget.innerHTML = result
  }
}
