import React from "react";
import ContentLoader from "react-content-loader";

const LoaderTable = (props) => (
  <ContentLoader
    height={40}
    width={120}
    viewBox="0 0 400 200"
    backgroundColor="#d9d9d9"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="114" y="60" rx="6" ry="6" width="500" height="30" />
  </ContentLoader>
);

export default LoaderTable;
