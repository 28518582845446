import { Controller } from 'stimulus'
import indicatorLevelResolver from '../shared/indicatorLevelResolver'
import measureChart from '../shared/measureChart'

export default class extends Controller {
  static targets = ['starts', 'ends', 'checkbox', 'inputs', 'rangeType', 'goal', 'result', 'level']

  connect () {
    this.chartId = 'gauge-chart-indicator-level'
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()

    const goal = Number.parseFloat(this.goalTarget.value)
    const result = Number.parseFloat(this.resultTarget.value)
    const trendDirection = this.data.get('trend-direction')

    this.checkboxTargets.forEach((el, i) => {
      if (el.checked) {
        $(this.inputsTargets[i]).fadeIn()
      } else {
        $(this.inputsTargets[i]).fadeOut()
      }
    })

    this.rangeTypeTargets.forEach((rangetTypeTarget, index) => {
      if ($(rangetTypeTarget).find(':selected').val() === 'between') {
        $(this.endsTargets[index]).fadeIn()
      } else {
        $(this.endsTargets[index]).fadeOut()
      }
    })

    const duplicates = this.findDuplicates()

    this.allInputs.forEach((el) => {
      $(el).tooltip('dispose')
    })

    this.allInputs.forEach((el) => {
      const { value } = el

      if (duplicates.includes(Number(value))) {
        $(el).tooltip({ title: 'Duplicado', trigger: 'manual' })
        setTimeout(() => {
          $(el).tooltip('show')
        }, 100)
      }
    })

    let percentage

    if (trendDirection === 'increase') {
      percentage = (result / goal) * 100
    } else if (goal === 0) {
      percentage = ((100 - result) / 100.0) * 100
    } else {
      // percentage = 100 - result / goal
      // percentage = Math.round((100.0 * (100.0 - result)) / 100.0 - goal)
      // percentage = 100 - ((result - goal) / goal) * 100
      // Formula Anterior percentage = 100 - ((goal - result) / 100.0) * -100
      percentage = 100 - (((result/goal) * 100.0) - 100)
    }

    const semaphores = this.checkboxTargets.reduce((acc, el, index) => {
      if (el.checked) {
        acc.push({
          range_type: this.rangeTypeTargets[index].value,
          starts: Number(this.startsTargets[index].value),
          ends: Number(this.endsTargets[index].value),
          achievement: {
            data: {
              color: el.dataset.color
            },
            name: el.dataset.achievement
          }
        })
      }
      return acc
    }, [])

    if (e) {
      $(e.target).next('input').focus()
    }

    const semaphore = indicatorLevelResolver(semaphores, percentage)

    if (!semaphore) {
      this.levelTarget.innerHTML = 'Desconocido'
      measureChart(this.chartId, '#000', percentage)
      return
    }

    const { name, data: { color } } = semaphore.achievement
    this.levelTarget.innerHTML = name

    measureChart(this.chartId, color, percentage)
  }

  findDuplicates () {
    const allValues = this.allInputs.reduce((acc, { value }) => value ? [...acc, Number(value)] : acc, [])
    return allValues.filter((value, i, collection) => collection.includes(value, i + 1)).sort()
  }

  get allInputs () {
    return [...this.startsTargets, ...this.endsTargets]
  }
}
