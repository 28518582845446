import React, { useState, useEffect, useRef, useCallback } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/cjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faMagnifyingGlass,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";
import { useDocument } from "./useDocument";
import { useTextRenderer } from "./useTextRenderer";

import "react-pdf/dist/cjs/Page/AnnotationLayer.css";
import "react-pdf/dist/cjs/Page/TextLayer.css";

import "./styles.css";
import { useProtected } from "./useProtected";

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@4.4.168/legacy/build/pdf.worker.min.mjs`;

const ViewerV2 = ({ id, protected: isProtected }) => {
  const canvasRefs = useRef([]);
  const containerRef = useRef();
  const searchRef = useRef();

  const [pageNumber, setPageNumber] = useState(1);
  const [url] = useDocument(id);
  const [numPages, setNumPages] = useState(null);
  const {
    searchText,
    setSearchText,
    setSearchPageIndex,
    textRenderer,
    handleNextTerm,
    handlePrevTerm,
    nextPageIndex,
    setNextPageIndex,
    pagesArray,
  } = useTextRenderer();

  const [zoom, setZoom] = useState(700);

  useProtected(isProtected, searchRef);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const handleScroll = () => {
      const scrollTop = containerRef.current.scrollTop;
      const pageHeights = canvasRefs.current.map((ref, index) => {
        if (ref) {
          return ref.parentElement.offsetTop + ref.height / 3;
        }

        return 0;
      });

      const page = pageHeights.findIndex((height, index) => {
        return height > scrollTop;
      });

      setPageNumber(page + 1);
    };

    containerRef.current.addEventListener("scroll", handleScroll);

    return () => {
      containerRef.current.removeEventListener("scroll", handleScroll);
    };
  }, [pageNumber]);

  useEffect(() => {
    if (nextPageIndex === -1) {
      return;
    }

    const pageNumber = pagesArray[nextPageIndex];

    containerRef.current.scrollTo({
      top: canvasRefs.current[pageNumber].parentElement.offsetTop,
      behavior: "smooth",
    });
  }, [nextPageIndex]);

  const onDocumentLoadSuccess = useCallback(
    ({ numPages, protected: isProtected }) => {
      setNumPages(numPages);
      canvasRefs.current = Array(numPages)
        .fill()
        .map((_, i) => canvasRefs.current[i] || React.createRef());
    },
    []
  );

  if (!url) {
    <div className="pdf-viewer-container">
      <Loader message="Cargando..." />
    </div>;
  }

  return (
    <div
      className="pdf-viewer-container"
      style={{ userSelect: isProtected ? "none" : "initial" }}
    >
      <>
        <Document
          file={url}
          loading={<Loader message="Cargando..." />}
          noData={<Loader message="Cargando..." />}
          onLoadSuccess={onDocumentLoadSuccess}
          inputRef={containerRef}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              canvasRef={(ref) => (canvasRefs.current[index] = ref)}
              key={`page_${index + 1}`}
              pageIndex={index}
              className="mt-1 mb-1"
              width={zoom}
              customTextRenderer={textRenderer}
            />
          ))}
        </Document>

        {numPages && (
          <div className="navigation-bar">
            <form
              className="form-inline"
              onSubmit={(event) => {
                event.preventDefault();
                const newText = searchRef.current.value;

                if (!newText) {
                  return;
                }

                if (newText === searchText) {
                  handleNextTerm();
                } else {
                  setSearchPageIndex(new Set());
                  setNextPageIndex(-1);
                  setSearchText(newText);
                }
              }}
            >
              <input
                type="search"
                ref={searchRef}
                className="form-control "
                placeholder="Buscar..."
                onChange={(event) => {
                  event.preventDefault();
                  const newText = event.target.value;

                  if (searchText != "" && newText === "") {
                    setSearchPageIndex(new Set());
                    setNextPageIndex(-1);
                    setSearchText(newText);
                  }
                }}
              />
            </form>

            <button
              className="btn btn-link viewer-zoom-control"
              disabled={pagesArray.length == 0}
              onClick={handlePrevTerm}
            >
              <FontAwesomeIcon icon={faAngleLeft} size="sm" />
            </button>
            <span style={{ color: "white", width: "30px", userSelect: "none" }}>
              {searchText
                ? `${nextPageIndex + 1} / ${pagesArray.length}`
                : "0/0"}
            </span>
            <button
              className="btn btn-link viewer-zoom-control"
              disabled={pagesArray.length == 0}
              onClick={handleNextTerm}
            >
              <FontAwesomeIcon icon={faAngleRight} size="sm" />
            </button>
            {`|`}
            <span style={{ color: "white", width: "50px", userSelect: "none" }}>
              Pag {`${pageNumber} / ${numPages}`}
            </span>
            <button
              className="btn btn-link viewer-zoom-control"
              onClick={() => setZoom(zoom - 50)}
            >
              <FontAwesomeIcon icon={faMinus} />
            </button>

            <FontAwesomeIcon icon={faMagnifyingGlass} size="sm" />

            <button
              className="btn btn-link viewer-zoom-control"
              onClick={() => setZoom(zoom + 50)}
            >
              <FontAwesomeIcon icon={faPlus} size="sm" />
            </button>
          </div>
        )}
      </>
    </div>
  );
};

export default ViewerV2;
