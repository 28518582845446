import React, {useState} from 'react'
import WorkflowButton from './WorkflowButton';
import WorkflowState from './WorkflowState'

const NavBar = ({auditProgramReport, props, prompts, auditNorms, workflowPrompts}) => {
  const [title, setTitle] = useState('Informe General ');
  return (
    <div className='card-header d-flex justify-content-between bg-dark position-sticky sticky-top'>
      <div className='card-title mb-0 d-flex align-items-center ml-xs-4'>
        <a href={`/audit_programs/${props.audit_program_id}`}>
          <button className='btn btn-link' type='button' >
            <i className='ml-1 fas fa-arrow-left no-pointer'></i>
          </button>
        </a>
        <h5 className='mb-0 text-white mr-4'>
          {title}
        </h5>
        <WorkflowState auditProgramReport={auditProgramReport}/>
      </div>
      <div className='card-title mb-0 d-flex align-items-center ml-xs-4'>
        {prompts.workflow_condition && (
          <WorkflowButton workflowPrompts={workflowPrompts}/>
        )}
        {prompts.pdf_condition && (
          <div className='ml-4'>
            <a href={`/audit_programs/${props.audit_program_id}/audit_program_reports/download_pdf?audit_general=`+auditProgramReport.id+ `&resource=`+props.audit_program_id+`&norm=`+auditNorms+`&format=pdf`} className='btn btn-danger mr-1'>
              <i className='far fa-file-pdf' data-bs-toggle='tooltip' data-bs-placement='top' title='Descargar Informe General'></i>
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default NavBar