import { Controller }  from 'stimulus'



export default class extends Controller {

  connect() {
    var slider = document.getElementById("slider1");
    var label = document.getElementById("label_slider");
    slider.style.background = "#28A745"; 
    this.check();
    this.onChange();
  }

  check(e){
    var slider = document.getElementById("slider1");
    var label = document.getElementById("label_slider");
    label.textContent = "Valor Seleccionado:" + slider.value
    
  }

  onChange(e) {
    var slider = document.getElementById("slider1");
    var label = document.getElementById("label_slider");
    
    slider.addEventListener("change", function(){
      label.textContent = "Valor Seleccionado:" + slider.value
    });
  }

  
}
