import React from "react";
import { AdminLaboratoriesContext } from "../AdminLaboratoriesContext";
import Assignments from "../../../Assignments";

function Table() {
  const { adminLaboratory } = React.useContext(AdminLaboratoriesContext);

  return (
    <div>
      {adminLaboratory && adminLaboratory.length > 0 ? (
        adminLaboratory.map((admin, index) => (
          <React.Fragment key={index}>
            <table className="table table-bordered table-hover table-sm table-striped">
              <thead>
                <tr>
                  <th
                    className="text-center align-middle bg-info"
                    scope="col"
                    style={{ width: "180px" }}
                  >
                    <h6 className="text-white font-weight-bold"> Administrador</h6>
                  </th>
                  <td className="align-middle text-center" style={{ width: "280px" }}>
                    {admin.assignments.map((assignment, index) => (
                      <React.Fragment key={index}>
                        {assignment.user_name.label}
                        <br />
                      </React.Fragment>
                    ))}
                  </td>
                  <td className="align-middle text-center" style={{ width: "180px" }}>
                    <Assignments
                      assignable={admin.id}
                      action={'produce'}
                    />
                  </td>
                  <th
                    className="text-center align-middle bg-info"
                    scope="col"
                    style={{ width: "180px" }}
                  >
                    <h6 className="text-white font-weight-bold">
                      Plazo de Respuesta
                    </h6>
                  </th>
                  <td className="align-middle text-center" style={{ width: "280px" }}>
                    {admin.answer_time} días
                  </td>
                </tr>
              </thead>
            </table>
            <table className="table table-bordered table-hover table-sm table-striped">
              <thead>
                <tr>
                  <th className="text-center align-middle bg-info">
                    <h6 className="text-white font-weight-bold">Url Laboratorio</h6>
                  </th>
                  <td className="align-middle text-justify font-weight-bold">
                    {`https://www.sigiswo.com/main_laboratories?portal_id=${admin.portal_id}&layout=pqrsf&color=true`}
                  </td>
                </tr>
              </thead>
            </table>
            <table className="table table-bordered table-hover table-sm table-striped">
              <thead>
                <tr>
                  <th 
                    className="text-center align-middle bg-info"
                    style={{width: "70%"}}
                  >
                    <h6 className="text-white font-weight-bold">Color elegido para el formulario de solicitud y pantalla principal:</h6>
                  </th>
                  <td
                    className="align-middle text-justify font-weight-bold"
                    style={{backgroundColor: admin.color}}
                  >
                    Color seleccionado
                  </td>
                </tr>
              </thead>
            </table>
          </React.Fragment>
        ))
      ) : (
        <p>No hay datos disponibles.</p>
      )}
    </div>
  );
}

export { Table };
