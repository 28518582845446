import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  static targets = ['levelName', 'parent', 'position', 'employee', 'check']

  connect () {
    this.onCheck()
  }

  onChangeLevelName (e) {
    e.preventDefault()
    const { value } = this.levelNameTarget

    if (value === 'macroprocedure') {
      $(this.parentTarget).prop('disabled', true)
      $(this.parentTarget).val(null).trigger('change')
    } else {
      $(this.parentTarget).prop('disabled', false)
    }
  }

  onChangePosition (e) {
    e.preventDefault()
    const { value } = this.positionTarget

    if (value === '') {
      $(this.employeeTarget).prop('disabled', true)
      $(this.employeeTarget).val(null).trigger('change')
    } else {
      $(this.employeeTarget).prop('disabled', false)
    }
  }

  onCheck() {
    if (this.hasCheckTarget)
    {
      if ($(this.checkTarget).prop('checked')){
        $('#documentField').show('slow')
      }
      else {
        $('#documentField').hide('slow')
      }
    }
  }
}
