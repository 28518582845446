import { Controller } from 'stimulus'
import Pickr from '@simonwep/pickr'

export default class extends Controller {
  static targets = ['button', 'input']

  connect () {
    if (!this.hasButtonTarget) {
      return
    }

    this.pickr = Pickr.create({
      el: this.buttonTarget,
      theme: 'monolith',
      default: this.inputTarget.value || '#009688',
      adjustableNumbers: false,
      lockOpacity: true,
      swatches: [
        'rgba(244, 67, 54, 1)',
        'rgba(233, 30, 99, 1)',
        'rgba(156, 39, 176, 1)',
        'rgba(103, 58, 183, 1)',
        'rgba(63, 81, 181, 1)',
        'rgba(33, 150, 243, 1)',
        'rgba(3, 169, 244, 1)',
        'rgba(0, 188, 212, 1)',
        'rgba(0, 150, 136, 1)',
        'rgba(76, 175, 80, 1)',
        'rgba(139, 195, 74, 1)',
        'rgba(205, 220, 57, 1)',
        'rgba(255, 235, 59, 1)',
        'rgba(255, 193, 7, 1)'
      ],
      components: {
        preview: true,
        opacity: false,
        hue: true,
        interaction: {
          hex: false,
          rgba: false,
          input: false,
          clear: false,
          save: false
        }
      }
    })

    this.pickr.on('change', (color) => {
      const newColor = color.toHEXA().toString()
      this.inputTarget.value = newColor
      this.pickr.applyColor(true)
    })
  }

  onChange (e) {
    const { value } = e.currentTarget
    e.preventDefault()
    this.pickr.setColor(value)
  }
}
