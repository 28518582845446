import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['serial', 'asset', 'dropdown', 'selectserial', 'selectfixed', 'equipmentid']
  connect () {
    this.onChange();
  }

  onChange (e) {
    if (e) { e.preventDefault() }
    if (this.hasDropdownTarget && this.hasSerialTarget) {
      if ($(this.dropdownTarget).val()){
        var filePath = 'corrective_maintenances/get_equipment?equipment_id='+$(this.dropdownTarget).val();
        fetch(filePath)
            .then(response => response.json())
            .then(data => {
              this.equipmenSelect(data)
            })
            .catch(error => {
              console.error('Error al cargar el archivo:', error);
            });

      }


    }
    var selectElement = document.getElementById('maintenance_plan_assignments_attributes_0_user_id');
    if (selectElement) {
      var filePath = 'maintenance_plans/admin_area?equipment_id='+this.dropdownTarget.value;
      fetch(filePath)
          .then(response => response.text())
          .then(data => {
            selectElement.value = data;
          })
          .catch(error => {
            console.error('Error al cargar el archivo:', error);
          });
    }
  }

  equipmenSelect(data) {
    const serials = data.serials;
    const assets = data.assets;

    // // Clear existing options
    this.selectserialTarget.innerHTML = "";
    this.selectfixedTarget.innerHTML = "";
    this.equipmentidTarget.value = $(this.dropdownTarget).val();
    var id_equiment = $(this.equipmentidTarget).val();
    // Add new options based on fetched data
    serials.forEach(item => {
      const option = document.createElement("option");
      option.value = item[1];
      option.text = item[0];

      option.setAttribute("data-name", item[2].data.name);
      option.setAttribute("data-serial", item[2].data.serial);
      option.setAttribute("data-asset", item[2].data.asset);
      this.selectserialTarget.appendChild(option);
    });
    this.selectserialTarget.value = id_equiment;

    assets.forEach(item => {
      const option = document.createElement("option");
      option.value = item[1];
      option.text = item[0];

      option.setAttribute("data-name", item[2].data.name);
      option.setAttribute("data-serial", item[2].data.serial);
      option.setAttribute("data-asset", item[2].data.asset);
      this.selectfixedTarget.appendChild(option);
    });
    this.selectfixedTarget.value = id_equiment;
    this.changelabels();
  }

  onChangeSerial (e) {
    var value = $(this.selectserialTarget).val();
    this.equipmentidTarget.value = value;
    this.selectfixedTarget.value = value;
    this.changelabels();
  }

  onChangeFixed (e) {
    var value = $(this.selectfixedTarget).val();
    this.equipmentidTarget.value = value;
    this.selectserialTarget.value = value;
    this.changelabels();
  }

  changelabels (e)
  {
    const selectedOptionserial = this.selectserialTarget.options[this.selectserialTarget.selectedIndex];
    const selectedTextserial = selectedOptionserial.text; // Obtiene el texto de la opción seleccionada
    const selectedOptionfixed = this.selectfixedTarget.options[this.selectfixedTarget.selectedIndex];
    const selectedTextfixed = selectedOptionfixed.text; // Obtiene el texto de la opción seleccionada

    $(this.serialTarget).text( selectedTextserial )
    $(this.assetTarget).text( selectedTextfixed )
  }

  triggerSelectChange(e){
    // Encuentra el select usando el atributo name
    const areaSelected = document.querySelector('select[name="corrective_maintenance[area_id]"]');
    
    // Verifica si el select fue encontrado antes de intentar despachar el evento
    if (areaSelected) {
      // Despacha el evento 'change' en el select
      areaSelected.dispatchEvent(new Event('change', { bubbles: true }));
    }
  }
}
