import React from "react";

function CollapseTableUser({laboratory}){
  return (
    <div className="card border-info mb-3">
      <div className="card-header">
        <h5 className="card-title mb-0 mt-1 font-weight-bold">
          INFORMACIÓN DEL SOLICITANTE
        </h5>
      </div>
      <div className="card-text mb-2 text-dark font-weight-normal p-md-2">
        <div className="row">
          <div className="col md-2">
            <div className="card border-success ml-2 shadow-sm">
              <div className="card-header text-center p-md-1 align-middle">
                <h6 className="font-weight-bold">
                  Nombre o Razón Social
                </h6>
              </div>
              <div className="card-body text-center">
                {laboratory.name_company_name}
              </div>
            </div>
          </div>
          <div className="col md-2">
            <div className="card border-success ml-2 shadow-sm">
              <div className="card-header text-center p-md-1 align-middle">
                <h6 className="font-weight-bold">
                  Persona de Contacto
                </h6>
              </div>
              <div className="card-body text-center">
                {laboratory.contact_person}
              </div>
            </div>
          </div>
          <div className="col md-2">
            <div className="card border-success ml-2 shadow-sm">
              <div className="card-header text-center p-md-1 align-middle">
                <h6 className="font-weight-bold">
                  Cédula o RUC
                </h6>
              </div>
              <div className="card-body text-center">
                {laboratory.indentification}
              </div>
            </div>
          </div>
          <div className="col md-2">
            <div className="card border-success ml-2 shadow-sm">
              <div className="card-header text-center p-md-1 align-middle">
                <h6 className="font-weight-bold">
                  Teléfono de Contacto
                </h6>
              </div>
              <div className="card-body text-center">
                {laboratory.phone}
              </div>
            </div>
          </div>
          <div className="col md-2">
            <div className="card border-success ml-2 shadow-sm">
              <div className="card-header text-center p-md-1 align-middle">
                <h6 className="font-weight-bold">
                  Correo Electrónico 
                </h6>
              </div>
              <div className="card-body text-center">
                {laboratory.email}
              </div>
            </div>
          </div>
          <div className="col md-2">
            <div className="card border-success ml-2 shadow-sm">
              <div className="card-header text-center p-md-1 align-middle">
                <h6 className="font-weight-bold">
                  Fecha de solicitud
                </h6>
              </div>
              <div className="card-body text-center">
                {laboratory.date}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { CollapseTableUser }