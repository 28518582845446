import React from "react";
import { New } from "./New";

function Form({ portal, type, color }) {
  switch (type) {
    case 'new':
      return <New
        portal={portal}
        color={color}
      />
  }
}

export default Form 