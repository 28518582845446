import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  static targets = ['probability', 'impact', 'risk', 'control', 'action', 'residual', 'residual_control',
    'residual_action', 'spinner', 'draftButton', 'buttonsubmit', 'secondImpact', 'residualImpact', 'residualProbability']
  montagasLogged = null;

  connect () {
    this.element.addEventListener('checkboxChange', this.handleCheckboxChange);
    this.element.addEventListener('finalGradeChange', this.handleFinalGradeChange);
    $(this.spinnerTarget).hide()
    const receivedString = this.element.dataset.user
    const keyValuePairs = receivedString.replace(/\s/g, "").split(",");
    const values = {};
    keyValuePairs.forEach((pair) => {
      const [key, value] = pair.split(":");
      values[key] = value.trim();
    });
    this.montagasLogged = JSON.parse(values.montagas_log);
    this.uplLogged = JSON.parse(values.upl_log);
    this.pabon = JSON.parse(values.pabon_log);
    this.uplbarranquilla = JSON.parse(values.uplbarranquilla_log);
    if (this.montagasLogged || this.uplLogged)
    {
      this.residualImpactTarget.style.backgroundColor = '#f2f2f2';
      this.residualImpactTarget.style.cursor = 'not-allowed';
      this.residualProbabilityTarget.style.backgroundColor = '#f2f2f2';
      this.residualProbabilityTarget.style.cursor = 'not-allowed';
    }
    this.arrayProbValues = [];
    this.arrayImpactValues = [];
    if (this.montagasLogged) {
      this.onChange();
    } else if (this.uplLogged) {
      this.impactTarget.style.backgroundColor = '#f2f2f2';
      this.impactTarget.style.cursor = 'not-allowed';
      this.addEventListeners();
    } else {
      this.onChange()
    }
  }

  addEventListeners() {
    this.element.addEventListener('impactupl:change', this.onImpactuplChange.bind(this));
  }

  onImpactuplChange(event) {
    const value = event.detail.value;
    this.impactTarget.value = value;
    this.onChange();
  }

  handleCheckboxChange = (event) => {
    if (event.detail.value === false) {
      if (event.detail.name === 'risk[risk_controls_attributes][0][directed_to_impact]') {
        this.arrayImpactValues[event.detail.index] = 0
      }
      if (event.detail.name === 'risk[risk_controls_attributes][0][directed_to_probability]') {
        this.arrayProbValues[event.detail.index] = 0
      }
      if (event.detail.name === 'all') {
        this.arrayProbValues[event.detail.index] = 0
        this.arrayImpactValues[event.detail.index] = 0
      }
    }
    if (event.detail.name !== 'all') {
      this.handleFinalGradeChange();
    } else {
      this.handleDelete();
    }
  }

  handleFinalGradeChange = () => {
    const inputFinalGrade = Array.from(this.element.querySelectorAll('input.final_grade'));
    inputFinalGrade.forEach((input, index) => {
      const parentDiv = input.closest('div.parent-div');
      const probabilityCheckbox = parentDiv.querySelector('.probability-check');
      const impactCheckbox = parentDiv.querySelector('.impact-check');
      if (probabilityCheckbox.checked && impactCheckbox.checked) {
        this.arrayImpactValues[index] = parseInt(input.value)
        this.arrayProbValues[index] = parseInt(input.value)
      } else if (probabilityCheckbox.checked) {
        this.arrayProbValues[index] = parseInt(input.value)
      } else if (impactCheckbox.checked){
        this.arrayImpactValues[index] = parseInt(input.value)
      }
    });
    this.sum = (this.arrayImpactValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0)) / this.arrayImpactValues.length || 0;
    this.sum2 = (this.arrayProbValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0)) / this.arrayProbValues.length || 0;
    this.onChange();
  }

  handleDelete = () => {
    this.sum = (this.arrayImpactValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0)) / this.arrayImpactValues.length || 0;
    this.sum2 = (this.arrayProbValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0)) / this.arrayProbValues.length || 0;
    this.onChange();
  }

  onChange (e) {
    if (e) e.preventDefault()
    if (this.montagasLogged || this.uplLogged  || this.pabon ){
      var probability = document.getElementById('probability').value
      var impact = document.getElementById('impact').value
      var value = probability * impact
      var calculatedImpact = impact;
      var calculatedProb = probability;
      var roundedImpact = document.getElementById('residual_probability').value
      var roundedProb = document.getElementById('residual_impact').value
      document.getElementById('risk_risk_level').setAttribute('value', value)
      if (this.sum != undefined && this.sum != null && this.sum >= 0) {
        calculatedImpact = impact * this.sum / 100
        roundedImpact = Math.round(calculatedImpact);
        const tempValue = impact - roundedImpact
        if (tempValue < 1) {
          this.residualImpactTarget.value = 1
        } else {
          this.residualImpactTarget.value = tempValue
        }
      }
      if (this.sum2 != undefined && this.sum2 != null && this.sum2 >= 0) {
        calculatedProb = probability * this.sum2 / 100
        roundedProb = Math.round(calculatedProb);
        const tempValue = probability - roundedProb
        if (tempValue < 1) {
          this.residualProbabilityTarget.value = 1;
        } else {
          this.residualProbabilityTarget.value = tempValue;
        }
      }
      var value_two = this.residualImpactTarget.value * this.residualProbabilityTarget.value;
    } else {
      var probability = document.getElementById('probability').value
      var impact = document.getElementById('impact').value
      var value = probability * impact
      document.getElementById('risk_risk_level').setAttribute('value', value)
      var probability_two = document.getElementById('residual_probability').value
      var impact_two = document.getElementById('residual_impact').value
      var value_two = probability_two * impact_two
    }

    $(this.riskTarget).prop('value', value)
    $(this.residualTarget).prop('value', value_two)

    if (this.uplbarranquilla){
      if ((Number(value) > 0) && (Number(value) < 11)) {
        $(this.controlTarget).prop('value', 'Bajo')
        if (this.montagasLogged) {
          $(this.actionTarget).prop('value', 'Asumir o Aceptar')
        }else{
          $(this.actionTarget).prop('value', 'Aceptar')
        }
        
        document.getElementById('risk_risk_level').style.backgroundColor = '#46E7FA'
        document.getElementById('risk_one').style.backgroundColor = '#46E7FA'
        document.getElementById('action_one').style.backgroundColor = '#46E7FA'
        document.getElementById('risk_risk_level').style.color = '#4F4F4F'
        document.getElementById('risk_one').style.color = '#4F4F4F'
        document.getElementById('action_one').style.color = '#4F4F4F'
      } else if ((Number(value) < 36) && (Number(value) > 10)) {
        $(this.controlTarget).prop('value', 'Moderado')
        $(this.actionTarget).prop('value', 'Reducir')
        document.getElementById('risk_risk_level').style.backgroundColor = '#FAFA46'
        document.getElementById('risk_one').style.backgroundColor = '#FAFA46'
        document.getElementById('action_one').style.backgroundColor = '#FAFA46'
        document.getElementById('risk_risk_level').style.color = '#4F4F4F'
        document.getElementById('risk_one').style.color = '#4F4F4F'
        document.getElementById('action_one').style.color = '#4F4F4F'
      } else if ((Number(value) < 70) && (Number(value) > 35)) {
        $(this.controlTarget).prop('value', 'Importante')
        if (this.montagasLogged) {
          $(this.actionTarget).prop('value', 'Transferir o Mitigar')
        }else{
          $(this.actionTarget).prop('value', 'Compartir o Mitigar')
        }
        
        document.getElementById('risk_risk_level').style.backgroundColor = '#FFC100'
        document.getElementById('risk_one').style.backgroundColor = '#FFC100'
        document.getElementById('action_one').style.backgroundColor = '#FFC100'
        document.getElementById('risk_risk_level').style.color = '#4F4F4F'
        document.getElementById('risk_one').style.color = '#4F4F4F'
        document.getElementById('action_one').style.color = '#4F4F4F'
      } else if ((Number(value) > 69)) {
        $(this.controlTarget).prop('value', 'Inaceptable')
        $(this.actionTarget).prop('value', 'Mitigar o Eliminar')
        document.getElementById('risk_risk_level').style.backgroundColor = '#B42100'
        document.getElementById('risk_one').style.backgroundColor = '#B42100'
        document.getElementById('action_one').style.backgroundColor = '#B42100'
        document.getElementById('risk_risk_level').style.color = 'white'
        document.getElementById('risk_one').style.color = 'white'
        document.getElementById('action_one').style.color = 'white'
      }
      if ((Number(value_two) > 0) && (Number(value_two) < 11)) {
        if (this.montagasLogged) {
          $(this.residual_actionTarget).prop('value', 'Asumir o Aceptar')
        }else{
          $(this.residual_actionTarget).prop('value', 'Aceptar')
        }
        
        $(this.residual_controlTarget).prop('value', 'Bajo')
        document.getElementById('risk_residual_risk_level').style.backgroundColor = '#46E7FA'
        document.getElementById('risk_residual_risk_level_two').style.backgroundColor = '#46E7FA'
        document.getElementById('risk_recomemended_action_two').style.backgroundColor = '#46E7FA'
        document.getElementById('risk_residual_risk_level').style.color = '#4F4F4F'
        document.getElementById('risk_residual_risk_level_two').style.color = '#4F4F4F'
        document.getElementById('risk_recomemended_action_two').style.color = '#4F4F4F'
      } else if ((Number(value_two) < 36) && (Number(value_two) > 10)) {
        $(this.residual_actionTarget).prop('value', 'Reducir')
        $(this.residual_controlTarget).prop('value', 'Moderado')
        document.getElementById('risk_residual_risk_level').style.backgroundColor = '#FAFA46'
        document.getElementById('risk_residual_risk_level_two').style.backgroundColor = '#FAFA46'
        document.getElementById('risk_recomemended_action_two').style.backgroundColor = '#FAFA46'
        document.getElementById('risk_residual_risk_level').style.color = '#4F4F4F'
        document.getElementById('risk_residual_risk_level_two').style.color = '#4F4F4F'
        document.getElementById('risk_recomemended_action_two').style.color = '#4F4F4F'
      } else if ((Number(value_two) < 70) && (Number(value_two) > 35)) {
        if (this.montagasLogged) {
          $(this.actionTarget).prop('value', 'Transferir o Mitigar')
        }else{
          $(this.actionTarget).prop('value', 'Compartir o Mitigar')
        }
        $(this.residual_controlTarget).prop('value', 'Importante')
        document.getElementById('risk_residual_risk_level').style.backgroundColor = '#FFC100'
        document.getElementById('risk_residual_risk_level_two').style.backgroundColor = '#FFC100'
        document.getElementById('risk_recomemended_action_two').style.backgroundColor = '#FFC100'
        document.getElementById('risk_residual_risk_level').style.color = '#4F4F4F'
        document.getElementById('risk_residual_risk_level_two').style.color = '#4F4F4F'
        document.getElementById('risk_recomemended_action_two').style.color = '#4F4F4F'
      } else if ((Number(value_two) > 69)) {
        $(this.residual_actionTarget).prop('value', 'Mitigar o Eliminar')
        $(this.residual_controlTarget).prop('value', 'Inaceptable')
        document.getElementById('risk_residual_risk_level').style.backgroundColor = '#B42100'
        document.getElementById('risk_residual_risk_level_two').style.backgroundColor = '#B42100'
        document.getElementById('risk_recomemended_action_two').style.backgroundColor = '#B42100'
        document.getElementById('risk_residual_risk_level').style.color = 'white'
        document.getElementById('risk_residual_risk_level_two').style.color = 'white'
        document.getElementById('risk_recomemended_action_two').style.color = 'white'
      }
    }
    else{
      if ((Number(value) > 0) && (Number(value) < 4)) {
        $(this.controlTarget).prop('value', 'Bajo')
        if (this.montagasLogged) {
          $(this.actionTarget).prop('value', 'Asumir o Aceptar')
        }else{
          $(this.actionTarget).prop('value', 'Aceptar')
        }
        
        document.getElementById('risk_risk_level').style.backgroundColor = '#46E7FA'
        document.getElementById('risk_one').style.backgroundColor = '#46E7FA'
        document.getElementById('action_one').style.backgroundColor = '#46E7FA'
        document.getElementById('risk_risk_level').style.color = '#4F4F4F'
        document.getElementById('risk_one').style.color = '#4F4F4F'
        document.getElementById('action_one').style.color = '#4F4F4F'
      } else if ((Number(value) < 7) && (Number(value) > 3)) {
        $(this.controlTarget).prop('value', 'Moderado')
        $(this.actionTarget).prop('value', 'Reducir')
        document.getElementById('risk_risk_level').style.backgroundColor = '#FAFA46'
        document.getElementById('risk_one').style.backgroundColor = '#FAFA46'
        document.getElementById('action_one').style.backgroundColor = '#FAFA46'
        document.getElementById('risk_risk_level').style.color = '#4F4F4F'
        document.getElementById('risk_one').style.color = '#4F4F4F'
        document.getElementById('action_one').style.color = '#4F4F4F'
      } else if ((Number(value) < 13) && (Number(value) > 6)) {
        $(this.controlTarget).prop('value', 'Importante')
        if (this.montagasLogged) {
          $(this.actionTarget).prop('value', 'Transferir o Mitigar')
        }else{
          $(this.actionTarget).prop('value', 'Compartir o Mitigar')
        }
        
        document.getElementById('risk_risk_level').style.backgroundColor = '#FFC100'
        document.getElementById('risk_one').style.backgroundColor = '#FFC100'
        document.getElementById('action_one').style.backgroundColor = '#FFC100'
        document.getElementById('risk_risk_level').style.color = '#4F4F4F'
        document.getElementById('risk_one').style.color = '#4F4F4F'
        document.getElementById('action_one').style.color = '#4F4F4F'
      } else if ((Number(value) < 26) && (Number(value) > 12)) {
        $(this.controlTarget).prop('value', 'Inaceptable')
        $(this.actionTarget).prop('value', 'Mitigar o Eliminar')
        document.getElementById('risk_risk_level').style.backgroundColor = '#B42100'
        document.getElementById('risk_one').style.backgroundColor = '#B42100'
        document.getElementById('action_one').style.backgroundColor = '#B42100'
        document.getElementById('risk_risk_level').style.color = 'white'
        document.getElementById('risk_one').style.color = 'white'
        document.getElementById('action_one').style.color = 'white'
      }
      if ((Number(value_two) > 0) && (Number(value_two) < 4)) {
        if (this.montagasLogged) {
          $(this.residual_actionTarget).prop('value', 'Asumir o Aceptar')
        }else{
          $(this.residual_actionTarget).prop('value', 'Aceptar')
        }
        
        $(this.residual_controlTarget).prop('value', 'Bajo')
        document.getElementById('risk_residual_risk_level').style.backgroundColor = '#46E7FA'
        document.getElementById('risk_residual_risk_level_two').style.backgroundColor = '#46E7FA'
        document.getElementById('risk_recomemended_action_two').style.backgroundColor = '#46E7FA'
        document.getElementById('risk_residual_risk_level').style.color = '#4F4F4F'
        document.getElementById('risk_residual_risk_level_two').style.color = '#4F4F4F'
        document.getElementById('risk_recomemended_action_two').style.color = '#4F4F4F'
      } else if ((Number(value_two) < 7) && (Number(value_two) > 3)) {
        $(this.residual_actionTarget).prop('value', 'Reducir')
        $(this.residual_controlTarget).prop('value', 'Moderado')
        document.getElementById('risk_residual_risk_level').style.backgroundColor = '#FAFA46'
        document.getElementById('risk_residual_risk_level_two').style.backgroundColor = '#FAFA46'
        document.getElementById('risk_recomemended_action_two').style.backgroundColor = '#FAFA46'
        document.getElementById('risk_residual_risk_level').style.color = '#4F4F4F'
        document.getElementById('risk_residual_risk_level_two').style.color = '#4F4F4F'
        document.getElementById('risk_recomemended_action_two').style.color = '#4F4F4F'
      } else if ((Number(value_two) < 13) && (Number(value_two) > 6)) {
        if (this.montagasLogged) {
          $(this.actionTarget).prop('value', 'Transferir o Mitigar')
        }else{
          $(this.actionTarget).prop('value', 'Compartir o Mitigar')
        }
        $(this.residual_controlTarget).prop('value', 'Importante')
        document.getElementById('risk_residual_risk_level').style.backgroundColor = '#FFC100'
        document.getElementById('risk_residual_risk_level_two').style.backgroundColor = '#FFC100'
        document.getElementById('risk_recomemended_action_two').style.backgroundColor = '#FFC100'
        document.getElementById('risk_residual_risk_level').style.color = '#4F4F4F'
        document.getElementById('risk_residual_risk_level_two').style.color = '#4F4F4F'
        document.getElementById('risk_recomemended_action_two').style.color = '#4F4F4F'
      } else if ((Number(value_two) < 26) && (Number(value_two) > 12)) {
        $(this.residual_actionTarget).prop('value', 'Mitigar o Eliminar')
        $(this.residual_controlTarget).prop('value', 'Inaceptable')
        document.getElementById('risk_residual_risk_level').style.backgroundColor = '#B42100'
        document.getElementById('risk_residual_risk_level_two').style.backgroundColor = '#B42100'
        document.getElementById('risk_recomemended_action_two').style.backgroundColor = '#B42100'
        document.getElementById('risk_residual_risk_level').style.color = 'white'
        document.getElementById('risk_residual_risk_level_two').style.color = 'white'
        document.getElementById('risk_recomemended_action_two').style.color = 'white'
      }
    }
  }

  submit () {
    $(this.draftButtonTarget).hide()
    $(this.buttonsubmitTarget).hide()
    $(this.spinnerTarget).show()
  }
}
