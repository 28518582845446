import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['program', 'type']

  connect() {
    this.onChange()
  }

  onChange() {
    if ($(this.programTarget).prop('value'))
    {
      $(this.typeTarget).show()
    } else { $(this.typeTarget).hide() }
  }

}