import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  static targets = ['intensity', 'extension', 'moment', 'persistence', 'reversibility', 'significanceLevel',
                    'synergy', 'accumulation', 'effect', 'periodicity', 'recoverability', 'significance']

  connect () {
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()
    var value = 0
    var significanceLevel = '';
    this.intensityTargets.forEach((_el, i) => {
      if ($(this.intensityTargets[i]).is(':checked')) {
        value = value + 3 * parseInt($(this.intensityTargets[i]).val())
      }
    })

    this.extensionTargets.forEach((_el, i) => {
      if ($(this.extensionTargets[i]).is(':checked')) {
        value = value + 2 * parseInt($(this.extensionTargets[i]).val())
      }
    })

    this.momentTargets.forEach((_el, i) => {
      if ($(this.momentTargets[i]).is(':checked')) {
        value = value + parseInt($(this.momentTargets[i]).val())
      }
    })

    this.persistenceTargets.forEach((_el, i) => {
      if ($(this.persistenceTargets[i]).is(':checked')) {
        value = value + parseInt($(this.persistenceTargets[i]).val())
      }
    })

    this.reversibilityTargets.forEach((_el, i) => {
      if ($(this.reversibilityTargets[i]).is(':checked')) {
        value = value + parseInt($(this.reversibilityTargets[i]).val())
      }
    })

    this.synergyTargets.forEach((_el, i) => {
      if ($(this.synergyTargets[i]).is(':checked')) {
        value = value + parseInt($(this.synergyTargets[i]).val())
      }
    })

    this.accumulationTargets.forEach((_el, i) => {
      if ($(this.accumulationTargets[i]).is(':checked')) {
        value = value + parseInt($(this.accumulationTargets[i]).val())
      }
    })

    this.effectTargets.forEach((_el, i) => {
      if ($(this.effectTargets[i]).is(':checked')) {
        value = value + parseInt($(this.effectTargets[i]).val())
      }
    })

    this.periodicityTargets.forEach((_el, i) => {
      if ($(this.periodicityTargets[i]).is(':checked')) {
        value = value + parseInt($(this.periodicityTargets[i]).val())
      }
    })

    this.recoverabilityTargets.forEach((_el, i) => {
      if ($(this.recoverabilityTargets[i]).is(':checked')) {
        value = value + parseInt($(this.recoverabilityTargets[i]).val())
      }
    })

    if (value <= 25) {
      significanceLevel = 'Compatible';
    } else if (value <= 50) {
      significanceLevel = 'Moderado';
    } else if (value <= 75) {
      significanceLevel = 'Severo';
    } else if (value <= 100) {
      significanceLevel = 'Crítico';
    }

    $(this.significanceLevelTarget).val(significanceLevel)
    $(this.significanceTarget).val(value)
  }

}
