import React from "react";
import CreateEventButton from "./CreateEventButton";
import SmallCalendar from "./SmallCalendar";

export default function Sidebar() {
  return (
    <aside className="col-2 red p-2 w-64 ">
      <div className="row align-items-center justify-content-center">
        <CreateEventButton />
      </div>
      <div className="row align-items-center justify-content-center">
        <SmallCalendar />
      </div>

    </aside>
  );
}
