import React, { useState, useEffect } from 'react'
import Indicators from './Indicators'
import IndicatorSummary from './Indicator'
import { GetIndicators, GetEstrategy, GetPortal } from './Requests'

const Procedures = (props) => {
  const [procedures, setProcedures] = useState(props.list)
  const [estrategy, setEstrategy] = useState('')
  const [openestrategy, setOpenstrategy] = useState(false)
  const [portal, setPortal] = useState(false)
  const openProcedure = props.openIndicator
  const procedureId = props.procedureId
  
  const showIndicators = async (procedureId) => {
    const updatedProcedures = procedures.map(async (procedure) => {
      if (procedure.id === procedureId) {
        if (procedure.indicators_requested) {
          return { ...procedure, show: !procedure.show }
        } else {
          const indicators = await GetIndicators(procedureId)
          return { ...procedure, show: !procedure.show, indicators: indicators, indicators_requested: true }
        }
      }
      return procedure
    });
    const updatedProceduresWithData = await Promise.all(updatedProcedures);
    setProcedures(updatedProceduresWithData)
  }

  useEffect(() => {
    GetEstrategy()
    .then((data) => {
      setEstrategy(data);
    })
    GetPortal()
    .then((data) => {
      setPortal(data);
    })
    if (openProcedure) {
      const updateProcedure = async () => {
        const updatedProcedures = await Promise.all(
          procedures.map(async (procedure) => {
            if (procedure.id === procedureId) {
              const indicators = await GetIndicators(procedureId);
              return { ...procedure, show: !procedure.show, indicators, indicators_requested: true };
            }
            return procedure;
          })
        );
        setProcedures(updatedProcedures);
      };
    
      updateProcedure();
    }
  }, []);
  return(
    <React.Fragment>
      {procedures.map((procedure, index)=> {
        return (
          <React.Fragment key={procedure.id}>
            <div className={`border border-light py-md-3 py-lg-1 py-4 ${index%2==0 ? 'bg-light' : 'bg-white'}`}>
            {props.portal.montagas && (
              <button className={`collapsed1 btn btn-link btn-lg btn-block ${(props.portal.montagas && procedure.active_module === true) || props.permissions.admin_sg ? '' : 'disabled'} `} onClick={() => showIndicators(procedure.id)}>
                <div className='text-left no-pointer text-muted'>{`${procedure.title} (${procedure.count})`}</div>
              </button>
            )}
            {!props.portal.montagas && (
              <button className='collapsed1 btn btn-link btn-lg btn-block' onClick={() => showIndicators(procedure.id)}>
                <div className='text-left no-pointer text-muted'>{`${procedure.title} (${procedure.count})`}</div>
              </button>
            )}
            </div>
            {
              procedure.show && <Indicators indicatorsList={procedure.indicators}
                                            permissions = {props.permissions}
                                            user = {props.user}
                                            portal = {props.portal}/>
            }
          </React.Fragment>
        )
      })}
      {portal && (
        <React.Fragment>
          <div className={`border border-light py-md-3 py-lg-1 py-4 'bg-light'`}>
            <button className='collapsed1 btn btn-link btn-lg btn-block' onClick={() => setOpenstrategy(!openestrategy)}>
              <div className='text-left no-pointer text-muted'>{`ESTRATÉGICOS (${estrategy.length})`}</div>
            </button>
          </div>
          {
            openestrategy && <Indicators indicatorsList={estrategy}
                                          permissions = {props.permissions}
                                          user = {props.user}
                                          portal = {props.portal}/>
          }
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default Procedures
