import axios from "axios";
import React from "react";
import { LabortoryContext } from "../LabortoryContext";
import { event } from "jquery";
function Buttons({service_type}) {
  const {
    findTypeLaboratory
  } = React.useContext(LabortoryContext)
  const deleteType = async (event) =>{
    event.preventDefault()
    const confirmed = window.confirm('¿Está seguro de eliminar el tipo de prueba de laboratorio?')
    if (confirmed){
      try{
        const response = await axios.delete(`/api/v2/type_laboratory_tests/${service_type.id}`);
        console.log("Laboratorio Eliminado", response.data)
        window.location.href = "/type_laboratory_tests";
      }catch(error){
        console.log("Error al eliminar el tipo de prueba de laboratorio")
      }
    }
  }
  const editData = async (event) =>{
    event.preventDefault()
    findTypeLaboratory(service_type.id)
  }
  return(
    <React.Fragment>
      <a
        className={`btn btn-outline-success btn-sm mr-1`}
        href="#"
        onClick={editData}
      >
        <i className='far fa-edit'></i>
      </a>
      <a
        className={`btn btn-sm btn-outline-danger mr-1 hover-blank`}
        href="#"
        onClick={deleteType}
      >
        <i className='far fa-trash-alt text-danger'></i>
      </a>
    </React.Fragment>

  )
}
export {Buttons}