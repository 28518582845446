import { initial } from 'lodash'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['check', 'person', 'anonymous', 'ultimate', 'portal']

  connect (e) {
    this.initial()
  }

  DenunPabon (e){
    if (document.getElementById('pqrsf_type_request_d').checked)
    {      
      document.getElementById('pqrsf_anonymous_complaint').checked = true
      document.getElementById('pqrsf_identifier_type_true').checked = true      
      this.DenunPabonChange()
    }
    // else{
    //   document.getElementById('pqrsf_anonymous_complaint').checked = false
    //   document.getElementById('pqrsf_identifier_type_true').checked = true
    //   this.DenunPabonChange()
    // }    
  }

  onChange (e) {
    if (e) e.preventDefault()
    if ($(this.checkTarget).prop('checked')) {
      $('#identifierType').hide('slow')
      $('#remitter').hide('slow')
    } else {
      $('#identifierType').show('slow')
      $('#remitter').show('slow')
    }
  }

  OnChangePabon (e) {
    if (e) e.preventDefault()
    if ($(this.checkTarget).prop('checked')) {
      $('#identifierType').hide('slow')
      $('#remmitter_pabon').hide('slow')
      $('#remitter_anonymus').show('slow')
      $('#remitterlegal').hide('slow')
      $('#remmitter_citys').hide('slow')
      $('#remmitter_info').hide('slow')
      $('#remmitter_pabon *').prop('disabled',true);
      $('#remitterlegal *').prop('disabled',true);
      $('#remitter_anonymus *').prop('disabled',false);
    } else {      
      $('#identifierType').show('slow')
      $('#remitter_anonymus').hide('slow')
      $('#remmitter_pabon').show('slow')
      $('#remmitter_citys').show('slow')
      $('#remmitter_info').show('slow')
      $('#remmitter_pabon *').prop('disabled',false);
      $('#remitterlegal *').prop('disabled',false);
      // $('#pqrsf_country').prop('disabled',true);
      // $('#stateU').prop('disabled',true);
      // $('#cityU').prop('disabled',true);
    }
  }
  DenunPabonChange (){
    if (document.getElementById('pqrsf_anonymous_complaint').checked){
      $('#identifierType').hide('slow')
      $('#remmitter_pabon').hide('slow')
      $('#remitter_anonymus').show('slow')
      $('#remitterlegal').hide('slow')
      $('#remmitter_citys').hide('slow')
      $('#remmitter_info').hide('slow')
      $('#remmitter_pabon *').prop('disabled',true);
      $('#remitterlegal *').prop('disabled',true);
      $('#remitter_anonymus *').prop('disabled',false);
    } else {
      $('#identifierType').show('slow')
      $('#remitter_anonymus').hide('slow')
      $('#remmitter_pabon').show('slow')
      $('#remmitter_citys').show('slow')
      $('#remmitter_info').show('slow')
      $('#remmitter_pabon *').prop('disabled',false);
      $('#remitterlegal *').prop('disabled',false);
    }
  }
  onChangeLegal (e){    
    if (e) e.preventDefault()
    if ($(this.anonymousTarget).prop('checked')) {
      $('#remitterlegal *').prop('disabled',false);
      $('#remitter_anonymus').hide('slow')
      $('#remitterlegal').show('slow')
      $('#remmitter_pabon').hide('slow')
      $('#remmitter_pabon *').prop('disabled',true);
    }
  }
  
  onChangePerson (e){
    if (e) e.preventDefault()
    if ($(this.personTarget).prop('checked')) {
      $('#remmitter_pabon *').prop('disabled',false);
      $('#remitter_anonymus').hide('slow')
      $('#remitterlegal').hide('slow')
      $('#remmitter_pabon').show('slow')
      $('#remitterlegal *').prop('disabled',true);
    }
  }

  eps_corposalud (){
    const pqrsf_identifier_type_true = document.getElementById('pqrsf_identifier_type_true').checked
    const pqrsf_identifier_type_false = document.getElementById('pqrsf_identifier_type_false').checked
    if (pqrsf_identifier_type_true){
      $('#eps').show('slow')
      $('#eps *').prop('disabled',false)
    }
    else if (pqrsf_identifier_type_false){
      $('#eps').hide('slow')
      $('#eps *').prop('disabled',true)
    }
  }

  initial (){
    const identifier_type = document.getElementById('identifier_type').value
    const anonymous_complaint = document.getElementById('anonymous_complaint').value
    const portal = document.getElementById('portal').value
    if (portal == 'bfeb0939-38bc-419b-bd66-332617925696' && (identifier_type == 'true' && anonymous_complaint == 'false')){
      $('#remitter_anonymus').hide('slow')
      $('#remitterlegal').hide('slow')
      $('#remitter_pabon').show('slow')
      $('#identifierType').show('slow')
      $('#remmitter_citys').show('slow')
      $('#remmitter_info').show('slow')
      $('#remitter_anonymus *').prop('disabled',true);
      $('#remitterlegal *').prop('disabled',true);
    }
    else if (portal == 'bfeb0939-38bc-419b-bd66-332617925696' && (identifier_type == 'false' && anonymous_complaint == 'false')){
      $('#remmitter_pabon').hide('slow')
      $('#remitter_anonymus').hide('slow')
      $('#identifierType').show('slow')
      $('#remitterlegal').show('slow')
    }
    else if (portal == 'bfeb0939-38bc-419b-bd66-332617925696' && (anonymous_complaint == 'true' && (identifier_type == 'true' || identifier_type == 'false'))){
      $('#remmitter_pabon').hide('slow')
      $('#identifierType').hide('slow')
      $('#remitterlegal').hide('slow')
      $('#remmitter_citys').hide('slow')
      $('#remmitter_info').hide('slow') 
      $('#remitter_anonymus').show('slow')
    }
    else if (portal == 'bfeb0939-38bc-419b-bd66-332617925696' && anonymous_complaint == 'true'){
      $('#remmitter_pabon').hide('slow')
      $('#identifierType').hide('slow')
      $('#remitter_anonymus').show('slow')
      $('#remitterlegal').hide('slow')
    }
    else if (portal != 'bfeb0939-38bc-419b-bd66-332617925696' && (anonymous_complaint == 'true' && (identifier_type == 'true' || identifier_type == 'false'))){
      $('#remitter').hide('slow')
      $('#identifierType').hide('slow')
    }
    else if (portal != 'bfeb0939-38bc-419b-bd66-332617925696' && anonymous_complaint == 'true'){
      $('#remitter').hide('slow')
      $('#identifierType').hide('slow')
    }
    else {
      $('#remitter').show('slow')      
      $('#remitter_anonymus').hide('slow')
      $('#remitterlegal').hide('slow')
      $('#identifierType').show('slow')
    }
  }
}
