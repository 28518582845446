import React, {useState, useEffect} from "react"
import AssignmentPanel from './AssignmentPanel'

const AssignmentModules = (props) => {
const [act, setAct] = useState({});
const [employees, setEmployees] = useState([]);
const [prompts, setPrompts] = useState([]);
  useEffect(() => {
    fetch("/api/v1/acts/main?act="+props.id).then((response)=>{
        return response.json()
    }).then((data) => {
        setAct(data)
    }),

    fetch("/api/v2/employees").then((response)=>{
        return response.json()
    }).then((data) => {
        setEmployees(data)
    })
  }, [])

  useEffect(() => {
    if (act.id !== undefined) {
      fetch("/api/v1/abilities/user_abilities?act="+act.id).then((response)=>{
      return response.json()
      }).then((data) => {
      setPrompts(data)
      })
    }
  }, [act])

  return (
    <>
      {prompts.assign_pannels_condition && (
      <AssignmentPanel 
        act={act} 
        props={props} 
        employees={employees}
        prompts={prompts}
        assignable_type={props.assignable_type}
      />
      )}
    </>
  )
  }
        
export default AssignmentModules
 