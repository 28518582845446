import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['acces']

  connect () {
    this.onChange()
  }

  onChange (e) {
    var value = document.getElementById('role').value;

    if (value === 'admin') {
      document.getElementById("check_permiso").hidden = false;
    } else {
      document.getElementById("check_permiso").hidden = true;
    }
  }
}
