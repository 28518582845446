import React from "react";
import { serviceTypeLaboratory } from "./serviceTypeLaboratory";
import axios from "axios";

const LabortoryContext = React.createContext()

function TypeLaboratoriesProvider({children}){
  const {
    loading,
    error,
    typeLaboratory
  } = serviceTypeLaboratory()
  const [openModal, setOpenModal] = React.useState(false)
  const [errorResponse, setErrorResponse] = React.useState([])
  const [datatypeLaboratory, setDataTypeLaboratory] = React.useState([])
  const [openEditModal, setOpenEditModal] = React.useState(false)

  const createServiceType = async (newLaboratoryTest) => { 
    try {
      const response = await axios.post("/api/v2/type_laboratory_tests", newLaboratoryTest);
      console.log("Prueba de laboratorio creada:", response.data);
      window.location.href = "/type_laboratory_tests";
      setOpenModal(false)
      setErrorResponse([]);
    } catch (error) {
      console.error("Error al crear la prueba de laboratorio:", error.response.data.errors);      
      setErrorResponse(error.response.data.errors);
    }
  };
  
  const findTypeLaboratory = async (idLaboratoryTest) => {
    try{
      const response = await  axios.get(`/api/v2/type_laboratory_tests/${idLaboratoryTest}/edit`)
      setOpenEditModal(true)
      setDataTypeLaboratory(response.data)
    }catch (error){
      setOpenEditModal(false)
    }
  }
  const updateTypeLaboratory = async (id, newLaboratoryTest) => {
    try{
      const response = await axios.patch(`/api/v2/type_laboratory_tests/${id}`, newLaboratoryTest)
      console.log("Prueba de laboratorio editada con éxito:", response.data);
      window.location.href = "/type_laboratory_tests";
      setOpenEditModal(false)
      setErrorResponse([])
    } catch (error){
      setErrorResponse(error.response.data.errors)
    }
  }

  return(
    <LabortoryContext.Provider
      value={{
        loading,
        error,
        typeLaboratory,
        openModal,
        setOpenModal,
        createServiceType,
        errorResponse,
        findTypeLaboratory,
        openEditModal,
        setOpenEditModal,
        datatypeLaboratory,
        updateTypeLaboratory

      }}
    >
      {children}
    </LabortoryContext.Provider>
  )
}

export {LabortoryContext, TypeLaboratoriesProvider }