import React, { useState, useEffect } from "react";
import { GetEnvironmentalImpacts } from "./Requests";
import Header from "./Table/Header";
import TableRow from "./Table/TableRow";

const Table = ({ environmental_matrix_id, can_see, can_edit }) => {
  const [environmentalImpacts, setEnvironmentalImpacts] = useState([]);
  const [canSee, setCanSee] = useState(can_see);
  const [canEdit, setCanEdit] = useState(can_edit);

  useEffect(() => {
    GetEnvironmentalImpacts(environmental_matrix_id).then((data) => {
      setEnvironmentalImpacts(data.impacts);
    });
  }, []);

  return (
    <React.Fragment>
      <Header/>
      {environmentalImpacts.map((environmentalImpact, index) => {
        return (
          <React.Fragment key={index}>
            <TableRow
              environmentalImpact={environmentalImpact}
              canSee={canSee}
              canEdit={canEdit}
            />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default Table;
