import tinycolor from 'tinycolor2'
import * as d3 from 'd3'
import { loadLiquidFillGauge, liquidFillGaugeDefaultSettings } from '../shared/liquidGauge'

export default function (elementId, color = '#000000', value = 0, portal = 'false') {
  const config = liquidFillGaugeDefaultSettings()
  if (document.getElementById('portal_ability') !== null){
    const portal_ability = document.getElementById('portal_ability').value
    if (portal_ability == 'true'){
      portal = portal_ability
    }
  }
  var size = 0
  if(portal == 'true'){
    size = 0.6
  }else{
    size = 0.8
  }
  config.circleThickness = 0.1
  config.circleFillGap = 0.1
  config.textVertPosition = 0.5
  config.textSize = size
  config.waveCount = 2
  config.waveAnimateTime = 5000
  config.circleColor = color
  config.textColor = color
  config.waveTextColor = tinycolor(color).isDark ? '#FFF' : '#000'
  config.waveColor = tinycolor(color).lighten().toHexString()

  d3.select(`#${elementId}`).selectAll('*').remove()

  loadLiquidFillGauge(elementId, value || 0, config, portal)
}
