import { Controller } from 'stimulus'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.es'

export default class extends Controller {
  connect () {
    const color = this.data.get('color')
    const color_ok = this.data.get('ok')
    const language = this.data.get('language')
    const editable = this.data.get('editable') || true

    if (editable === false || editable === 'false') {
      return
    }

    const options = {
      format: 'yyyy-mm-dd',
      todayHighlight: true,
      clear: true,
      language
    }

    $(this.element).datepicker(options)

    var check = document.getElementById("boton");
    var texto = document.getElementById("texto");
    var date= document.getElementById('date')
    
    if (date.value !=''){
      check.style.display = ''
      texto.style.display = 'none'
    }
    else{
      check.style.display = 'none'
      texto.style.display = ''
    }
    
    $(this.element).focus(function() {
        if (date.value !=''){
          check.style.display = ''
          texto.style.display = 'none'
        }
        else{
          check.style.display = 'none'
          texto.style.display = ''
        }
    });
    
    $(this.element).keyup(function() {
      if (date.value !=''){
        check.style.display = ''
        texto.style.display = 'none'
      }
      else{
        check.style.display = 'none'
        texto.style.display = ''
      }
    });
  }
 
}
