import React from 'react'

const Header = () => {
  return(
    <div className="my-1">
      <div className="container-fluid ml-0 mr-0 mb-2 d-none d-md-block d-lg-block d-xl-block">
        <div className="row border-top">
          <div className="col-2"></div>
          <div className="col-5 px-0 d-md-none d-lg-block">
            <h6 className="font-weight-bold mb-0 py-2 text-center">Actividades</h6>
          </div>
          <div className="col-5 px-0 d-xs-none d-md-block">
            <h6 className="font-weight-bold mb-0 py-2 text-center">Procesos</h6>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header