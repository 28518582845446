import React from "react";

const ResourceInput = ({
  resources,
  deleteInput,
  idInput,
  handleSelect,
  idResource,
}) => {
  return (
    <>
      <div className="form-row mb-3">
        <div className="col-md-11">
          <div className="form-group m-0">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i className="fas fa-file-alt form-icon-small"></i>
                </div>
              </div>
              <select
                className="form-control custom-select"
                onChange={(e) => handleSelect(idInput, e)}
                value={idResource}
              >
                {resources.map((resource) => (
                  <option key={resource.id} value={resource.id}>
                    {resource.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="col-md-1 d-flex justify-content-center">
          <a
            className="btn btn-outline-danger"
            onClick={() => deleteInput(idInput)}
          >
            <i className="fas fa-times text-danger"></i>
          </a>
        </div>
      </div>
    </>
  );
};

export default ResourceInput;
