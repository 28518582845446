import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['eval', 'result']

  connect () {
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()
    var { value } = $('#qualification').value
    if (Number(value) > $(this.evalTarget).value) {
      $(this.evalTarget).attr(value, 'Mayor')
    }
  }
}
