import { Controller }  from 'stimulus'

export default class extends Controller {
  static targets = ['checkAll', 'select', 'users']
  connect() {
    this.addOptions()
    if (this.hasUsersTarget) {
      this.showAll()
    }
  }

  addOptions() {
    const employees = JSON.parse(this.data.get('employees'))
    const committees = JSON.parse(this.data.get('committees'))
    const positions = JSON.parse(this.data.get('positions'))
    var departments = document.getElementsByClassName('AssignmentDepartment')
    var committeeFields = document.getElementsByClassName('AssignmentCommittee')
    var positionFields = document.getElementsByClassName('AssignmentPosition')
    var arrDepartments = []
    var arrCommittees = []
    var arrPositions = []
    var arrPositionsSelect = []
    var arrPositionsIds = []
    var ids = []
    if (departments.length > 0) {
      for (var x = 0; x < departments.length; x++) {
        arrDepartments.push(departments[x].value)
      } // for(var x = 0; x < departments.length; x++)
    } // if (departments.length > 0)
    if (committeeFields.length > 0) {
      for (var x = 0; x < committeeFields.length; x++) {
        arrCommittees.push(committeeFields[x].value)
      } // for(var x = 0; x < departments.length; x++)
      for (var c = 0; c < committees.length; c++){
        if (arrCommittees.includes(committees[c].id)){
          for (var d = 0; d < committees[c].positions[0].length; d++) {
            arrPositions.push(committees[c].positions[0][d].id)
          }
        }
      }
    } // if (departments.length > 0)
    if (positionFields.length > 0){
      for (var x = 0; x < positionFields.length; x++){
        arrPositionsSelect.push(positionFields[x].value)
      }
      if (positions.length > 0){
        for (var p = 0; p < positions.length; p++){
          if (arrPositionsSelect.includes(positions[p].id)){
            arrPositionsIds.push(positions[p].id)
          }
        }
      }
    }
    for (var e = 0; e < employees.length; e++) {
      if ((arrDepartments.includes(employees[e].department)) || (arrPositions.includes(employees[e].position)) || (arrPositionsIds.includes(employees[e].position))) {
        ids.push(employees[e].id)
      }
    }
    var selectFields = document.getElementsByClassName('read')
    if (selectFields.length > 0) {
      var news = ids.slice(0)
      if (arrDepartments.length > 0 || arrPositions.length > 0 || arrPositionsIds.length > 0){
        for (var x = 0; x < selectFields.length; x++) {
          for (var y = selectFields[x].length-1; y >=0 ; y--){
            if (!ids.includes(selectFields[x].options[y].value)){
              selectFields[x].remove(y) //quita elementos de los select
            } else {
              var index = news.indexOf(selectFields[x].options[y].value)
              if (index != -1) { news.splice(index,1) }
            }
          }
        } // (var x = 0; x < selectFields.length; x++)
      }
      // Agregar opciones cuando se agrega depencencia o comité
      for (var t=0; t < employees.length; t++){
        if (news.includes(employees[t].id)) {
          for (var s = 0; s < selectFields.length; s++ ) {
            var opt1 = document.createElement('option')
            opt1.value = employees[t].id
            opt1.text = employees[t].full_name
            selectFields[s].add(opt1, null)
          }
        }
      }
    } // if (selectFields.length > 0) {
  }

  showAll() {
    const filterType = this.data.get('type')
    const employees = JSON.parse(this.data.get('employees'))
    $(this.usersTarget).empty()
    if ($(this.checkAllTarget).prop('checked')) {
      if (filterType == 'Department') {
        var departmentId =  $(this.selectTarget).val()

        for (var e = 0; e < employees.length; e++) {
          if (employees[e].department == departmentId) {
            $(this.usersTarget).append(`<li class="list-group-item">${employees[e].full_name}</li>`)
          }
        }
      } else if (filterType == 'Committee') {
        var committeeId =  $(this.selectTarget).val()
        const committees = JSON.parse(this.data.get('committees'))
        var ids = []
        for (var c = 0; c < committees.length; c++) {
          if ((committeeId == committees[c].id) && (committees[c].positions[0].length > 0)) {
            for(var p = 0; p < committees[c].positions[0].length; p++){
              ids.push(committees[c].positions[0][p].id)
            }
            for (var e = 0; e < employees.length; e++) {
              if (ids.includes(employees[e].position)) {
                $(this.usersTarget).append(`<li class="list-group-item">${employees[e].full_name}</li>`)
              }
            }
          }
        }
      } else if (filterType == 'Position') {
        var positionId =  $(this.selectTarget).val()
        const positions = JSON.parse(this.data.get('positions'))
        var ids = []
        for (var c = 0; c < positions.length; c++) {
          if (positionId == positions[c].id) {
            ids.push(positions[c].id)
            for (var e = 0; e < employees.length; e++) {
              if (ids.includes(employees[e].position)) {
                $(this.usersTarget).append(`<li class="list-group-item">${employees[e].full_name}</li>`)
              }
            }
          }
        }
      }
    }
  }

  onDelete(){
    const employees = JSON.parse(this.data.get('employees'))
    const committees = JSON.parse(this.data.get('committees'))
    const positions = JSON.parse(this.data.get('positions'))
    var departments = document.getElementsByClassName('AssignmentDepartment')
    var committeeFields = document.getElementsByClassName('AssignmentCommittee')
    var positionFields = document.getElementsByClassName('AssignmentPosition')
    var arrDepartments = []
    var arrCommittees = []
    var arrPositions = []
    var arrPositionsSelect = []
    var arrPositionsIds = []
    var ids = []
    var selected = $(this.selectTarget).children('option:selected').val()
    
    if (departments.length > 0) {
      for (var x = 0; x < departments.length; x++) {
        arrDepartments.push(departments[x].value)
      } // for(var x = 0; x < departments.length; x++)
    } // if (departments.length > 0)
    if (committeeFields.length > 0) {
      for (var x = 0; x < committeeFields.length; x++) {
        arrCommittees.push(committeeFields[x].value)
      } // for(var x = 0; x < departments.length; x++)
      for (var c = 0; c < committees.length; c++){
        if (arrCommittees.includes(committees[c].id)){
          for (var d = 0; d < committees[c].positions[0].length; d++) {
            arrPositions.push(committees[c].positions[0][d].id)
          }
        }
      }
    } // if (departments.length > 0)
    if (positionFields.length > 0){
      for (var x = 0; x < positionFields.length; x++){
        arrPositionsSelect.push(positionFields[x].value)
      }
      if (positions.length > 0){
        for (var p = 0; p < positions.length; p++){
          if (arrPositionsSelect.includes(positions[p].id)){
            arrPositionsIds.push(positions[p].id)
          }
        }
      }
    }

    var indexDepartment = arrDepartments.indexOf(selected)
    var indexPosition = arrPositionsIds.indexOf(selected)
    var indexCommitte = arrCommittees.indexOf(selected)


    if (indexDepartment !== -1) {
      arrDepartments.splice(indexDepartment, 1)
      for (var x = 0; x < departments.length; x++) {
        if (departments[x].value === selected) {
          departments[x].remove();
        }
      }
    }

    if(indexPosition !== 1){
      arrPositionsIds.splice(indexPosition, 1)
      for (var x = 0; x < positionFields.length; x++) {
        if (positionFields[x].value === selected) {
          positionFields[x].remove();
        }
      }
    }

    if(indexCommitte !== 1){
      arrCommittees.splice(indexCommitte, 1)
      for (var x = 0; x < committeeFields.length; x++) {
        if (committeeFields[x].value === selected) {
          committeeFields[x].remove();
        }
      }
    }



    for (var e = 0; e < employees.length; e++) {
      if(arrDepartments.length > 0 || arrPositions.length > 0 || arrPositionsIds.length > 0){
        if ((arrDepartments.includes(employees[e].department)) || (arrPositions.includes(employees[e].position)) || (arrPositionsIds.includes(employees[e].position))) {
          ids.push(employees[e].id)
        }
      }
    }

    var selectFields = document.getElementsByClassName('read')
    if (selectFields.length > 0) {
      var news = ids.slice(0)
      if (arrDepartments.length > 0 || arrPositions.length > 0 || arrPositionsIds.length > 0){
        for (var x = 0; x < selectFields.length; x++) {
          for (var y = selectFields[x].length-1; y >=0 ; y--){
            if (!ids.includes(selectFields[x].options[y].value)){
              selectFields[x].remove(y) //quita elementos de los select
            } else {
              var index = news.indexOf(selectFields[x].options[y].value)
              if (index != -1) { news.splice(index,1) }
            }
          }
        } // (var x = 0; x < selectFields.length; x++)
      } else {
        for (var x = 0; x < selectFields.length; x++) {
          for (var y = selectFields[x].length-1; y >=0 ; y--){
            if (!ids.includes(selectFields[x].options[y].value)){
              selectFields[x].remove(y) //quita elementos de los select
            } else {
              var index = news.indexOf(selectFields[x].options[y].value)
              if (index != -1) { news.splice(index,1) }
            }
          }
        }
        for (var t=0; t < employees.length; t++){
          for (var s = 0; s < selectFields.length; s++ ) {
            var opt1 = document.createElement('option')
            opt1.value = employees[t].id
            opt1.text = employees[t].full_name
            selectFields[s].add(opt1, null)
          }
        }
      }

    }
  }
}
