import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['selector']

  connect () {
    $('#selectAttendee').css('color', 'white')
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()
    var value = this.selectorTarget.value
    if (value == 'attendee') {
      $('#selectAttendee').css('background-color', '#00CA00')
    } else {
      $('#selectAttendee').css('background-color', '#FF7171')
    }
  }
}
