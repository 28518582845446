import { Controller } from 'stimulus'

export default class extends Controller {

  connect () {   
    this.verify()
  }

  verify(e) {
    const grupoefect = document.querySelectorAll('.grupoefect');
    const analysis = document.querySelectorAll('.grupoAnalysis');
    const grupoBrainstorming = document.querySelectorAll('.grupoBrainstorming');
    
    const expresionAnalisis = /\b(5|cinco|Cinco)\b/i;
    const expresionbrain = /\b(idea|ideas|Ideas)\b/i;
    
    const selectedText = $("#select_cause_id option:selected").text().toLowerCase();
  
    // Mostrar todos los elementos de grupoefect inicialmente
    grupoefect.forEach(el => el.style.display = '');
  
    // Ocultar o mostrar análisis según coincidencia con expresionAnalisis
    const showAnalysis = expresionAnalisis.test(selectedText);
    analysis.forEach(el => el.style.display = showAnalysis ? '' : 'none');
    
    // Ocultar grupoefect si analysis está visible
    if (showAnalysis) grupoefect.forEach(el => el.style.display = 'none');
  
    // Ocultar o mostrar grupoBrainstorming según coincidencia con expresionbrain
    const showBrainstorming = expresionbrain.test(selectedText);
    grupoBrainstorming.forEach(el => el.style.display = showBrainstorming ? '' : 'none');
    
    // Ocultar grupoefect si grupoBrainstorming está visible
    if (showBrainstorming) grupoefect.forEach(el => el.style.display = 'none');
  }
}
