import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['buttonone', 'buttontwo', 'buttonthree', 'buttonfour', 'buttonfive']
  resetClass()
  {
    $(this.buttononeTarget).prop('className', 'btn btn-danger btn-sm mr-1')
    $(this.buttontwoTarget).prop('className', 'btn btn-warning btn-sm mr-1')
    $(this.buttonthreeTarget).prop('className', 'btn btn-info btn-sm mr-1')
    $(this.buttonfourTarget).prop('className', 'btn btn-success btn-sm mr-1')
    $(this.buttonfiveTarget).prop('className', 'btn btn-primary btn-sm mr-1')
  }

  firstAction (e) {
    if (e) e.preventDefault()
    this.resetClass()
    $(this.buttononeTarget).prop('className', 'btn btn-outline-danger btn-sm mr-1')
    this.updateAssessment(1)
  }

  secondAction (e) {
    if (e) e.preventDefault()
    this.resetClass()
    $(this.buttontwoTarget).prop('className', 'btn btn-outline-warning btn-sm mr-1')
    this.updateAssessment(2)
  }

  thirdAction (e) {
    if (e) e.preventDefault()
    this.resetClass()
    $(this.buttonthreeTarget).prop('className', 'btn btn-outline-info btn-sm mr-1')
    this.updateAssessment(3)
  }

  fourthAction (e) {
    if (e) e.preventDefault()
    this.resetClass()
    $(this.buttonfourTarget).prop('className', 'btn btn-outline-success btn-sm mr-1')
    this.updateAssessment(4)
  }

  fifthAction (e) {
    if (e) e.preventDefault()
    this.resetClass()
    $(this.buttonfiveTarget).prop('className', 'btn btn-outline-primary btn-sm mr-1')
    this.updateAssessment('')
  }

  updateAssessment (value) {
    const chapter = this.data.get('chapter')
    const subchapter = this.data.get('subchapter')
    const health = this.data.get('id')
    const url = '/multi_healths/update_value'
    $.get(url+'?health_id='+health+'&value='+value).then((content) => {
      $('#' + chapter).html(content.chapter)
      $('#' + subchapter).html(content.subchapter)
    })
  }
}
