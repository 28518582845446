import React, { useRef, useState } from 'react'

const PerspectiveRows = ({ perspectives, strategics }) => {
  const elementRefs = useRef([]);
  const [showDropdown, setShowDropdown] = useState(false)
  const [filteredStrategics, setFilteredStrategics] = useState([])

  const buttonCall = (index, perspectiveId) => {
    //e.target.classList.remove()
    let iTag = elementRefs.current[index]
    if (iTag.classList.value == 'fas fa-caret-right') {
      iTag.className = 'fas fa-caret-down'
    } else {
      iTag.className = 'fas fa-caret-right'
    }

    let filteredData = strategics.filter(strategic => strategic.perspective_id == perspectiveId)
    setFilteredStrategics(filteredData)
    showDropdown(!showDropdown)
  }

  let filas = perspectives.map((perspective, index) => {
    return (
      <>
        <tr key={perspective.id}>
          <td>
            <button className='btn btn-link mr-3' onClick={() => buttonCall(index, perspective.id)}>
              <i ref={(el) => (elementRefs.current[index] = el)} className="fas fa-caret-right"></i>
            </button>
            <i className="fas fa-sitemap text-warning mr-3"></i>
            <span>{perspective.name}</span>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        { showDropdown && <StrategicRows/> }
      </>
      )
  })
  return filas
}

export default PerspectiveRows