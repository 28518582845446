import { Controller } from 'stimulus'
import measureChart from '../shared/measureChart'

export default class extends Controller {
  connect () {
    const elementId = this.data.get('element-id')
    const color = this.data.get('color')
    const value = this.data.get('value')
    measureChart(elementId, color, value)
  }
}
