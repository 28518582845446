import React, { useEffect, useState } from "react";
import Impact from "./Impact";
import { GetRisks } from "./Requests";

const Aspect = ({
  aspect,
  indexAspect,
  deleteAspect,
  aspects,
  indexActivity,
  envImpact,
  setEnvImpact,
  envAspectsAux,
  setEnvAspectsAux,
  envAspects,
  setEnvAspects,
  activitiesAux,
  setActivitiesAux,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const [impactsAux, setImpactsAux] = useState([]);
  const [impacts, setImpacts] = useState([]);
  const [impactsCounter, setImpactsCounter] = useState(0);
  const [risks, setRisks] = useState([]);

  const handleChange = (e) => {
    const { value } = e.target;
    const updatedAspects = [...envAspectsAux];
    updatedAspects[indexAspect] = {
      ...updatedAspects[indexAspect],
      descriptionInput: value,
    };
    setEnvAspectsAux(updatedAspects);
  };

  const handleSelect = (idAspect, e) => {
    const { value } = e.target;
    setEnvAspectsAux((prevEnvAspectsAux) =>
      prevEnvAspectsAux.map((item) =>
        item.indexEnvAspect === idAspect
          ? { ...item, environmentalSelect: value }
          : item
      )
    );
  };

  useEffect(() => {
    const formattedAspects = envAspectsAux.reduce((acc, aspect, index) => {
      acc[index] = {
        environmental_aspect_id: aspect.environmentalSelect,
        environmental_aspect_description: aspect.descriptionInput,
        env_impact_evaluations_attributes: aspect.evaluations,
        _destroy: false,
      };
      return acc;
    }, {});
    setEnvAspects(formattedAspects);
  }, [envAspectsAux]);

  useEffect(() => {
    const updatedImpact = { ...envImpact };
    const updatedActivitiesAux = [ ...activitiesAux ]
    updatedImpact.environmental_impact.env_impact_activities_attributes[
      indexActivity
    ].env_impact_aspects_attributes = envAspects;
    updatedActivitiesAux[indexActivity].aspects = envAspects
    setActivitiesAux(updatedActivitiesAux)
    setEnvImpact(updatedImpact);
  }, [envAspects]);

  useEffect(() => {
    GetRisks().then((data) => {
      setRisks(data);
    });
  }, []);

  useEffect(() => {
    if (risks.length > 0) {
      const newImpact = {
        indexImpact: 0,
        description: "",
        control: "",
        character: "",
        intensity: null,
        impact_extension: null,
        moment: null,
        persistence: null,
        reversibility: null,
        synergy: null,
        accumulation: null,
        effect: null,
        periodicity: null,
        recoverability: null,
        risk_id: risks[0].id,
        risk_description: "",
        associated_opportunity_description: "",
        observations: "",
        significant_impact: "",
        _destroy: false,
        resources_attributes: {},
      };
      setImpactsAux([newImpact]);
    }
  }, [risks]);

  const addImpact = () => {
    const localCounter = impactsCounter + 1;
    const newImpact = {
      indexImpact: localCounter,
      description: "",
      control: "",
      character: "",
      intensity: null,
      impact_extension: null,
      moment: null,
      persistence: null,
      reversibility: null,
      synergy: null,
      accumulation: null,
      effect: null,
      periodicity: null,
      recoverability: null,
      risk_id: risks[0].id,
      risk_description: "",
      associated_opportunity_description: "",
      observations: "",
      significant_impact: "",
      _destroy: false,
      resources_attributes: {},
    };
    setImpactsCounter(impactsCounter + 1);
    setImpactsAux([...impactsAux, newImpact]);
  };

  const deleteImpact = (e) => {
    const updatedImpacts = impactsAux.filter(
      (impact) => impact.indexImpact !== e
    );
    const reindexedImpacts = updatedImpacts.map((impact, index) => ({
      ...impact,
      indexImpact: index,
    }));
    setImpactsCounter(impactsCounter - 1);
    setImpactsAux(reindexedImpacts);
  };

  if (collapsed) {
    return (
      <>
        <div className="form-row px-3 mt-3">
          <div className="col-md-12">
            <div className="d-flex form-group mb-0">
              <div
                className="d-flex justify-content-between pr-2 input-group-text col-md-11 cursor-pointer"
                onClick={() => setCollapsed(false)}
              >
                <div className="">
                  <span>Aspecto ambiental asociado #{indexAspect + 1}</span>
                </div>
                <div>
                  <i className="fas fa-chevron-down"></i>
                </div>
              </div>
              <div className="col-md-1 d-flex justify-content-center">
                <a
                  className="btn btn-outline-danger"
                  onClick={() => deleteAspect(indexAspect)}
                >
                  <i className="fas fa-times text-danger"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="form-row px-3 mt-3">
        <div className="col-md-12">
          <div className="d-flex col-md-12 mb-3 p-0">
            <div
              className="d-flex justify-content-between pr-2 input-group-text col-md-11 cursor-pointer "
              onClick={() => setCollapsed(true)}
            >
              <div className="">
                <span>Aspecto ambiental asociado #{indexAspect + 1}</span>
              </div>
              <div>
                <i className="fas fa-chevron-up"></i>
              </div>
            </div>
            <div className="col-md-1 d-flex justify-content-center">
              <a
                className="btn btn-outline-danger"
                onClick={() => deleteAspect(indexAspect)}
              >
                <i className="fas fa-times text-danger"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="card-body py-0 px-2">
          <div className="form-row">
            <div className="col-md-12">
              <div className="form-group mb-3">
                <span>Aspecto ambiental</span>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="fas fa-file-alt form-icon-small"></i>
                    </div>
                  </div>
                  <select
                    className="form-control custom-select"
                    onChange={(e) => handleSelect(indexAspect, e)}
                    value={aspect.id}
                  >
                    {aspects.map((aspect) => (
                      <option key={aspect.id} value={aspect.id}>
                        {aspect.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group mb-3">
                <span>Descripción del aspecto ambiental</span>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="fas fa-file-alt form-icon-small"></i>
                    </div>
                  </div>
                  <textarea
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                    value={aspect?.descriptionInput}
                  />
                </div>
              </div>
            </div>
          </div>
          {impactsAux.map((impact) => (
            <Impact
              key={impact.indexImpact}
              indexImpact={impact.indexImpact}
              deleteImpact={deleteImpact}
              impactsAux={impactsAux}
              setImpactsAux={setImpactsAux}
              impact={impact}
              risks={risks}
              impacts={impacts}
              setImpacts={setImpacts}
              envImpact={envImpact}
              setEnvImpact={setEnvImpact}
              indexActivity={indexActivity}
              indexAspect={indexAspect}
              envAspectsAux={envAspectsAux}
              setEnvAspectsAux={setEnvAspectsAux}
            />
          ))}
          <div className="card-body p-3">
            <button
              type="button"
              className="btn btn-info btn-sm mb-2"
              onClick={addImpact}
            >
              <i className="fas fa-plus mr-1"></i>
              Agregar
            </button>
            <span className="ml-2"> Agregar Impacto</span>
          </div>
        </div>
      </div>
    );
  }
};

export default Aspect;
