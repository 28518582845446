import { Controller } from 'stimulus'
import 'bootstrap/js/dist/modal'

export default class extends Controller {
  connect () {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const section = urlParams.get('section')
    if (section == 'act_attendees') {
      var att = document.getElementById('actAttendees')
      att.scrollIntoView({ behavior: 'smooth' })
    } else if (section == 'attendees') {
      var oth = document.getElementById('otherAttendees')
      oth.scrollIntoView({ behavior: 'smooth' })
    } else if (section == 'activities') {
      var act = document.getElementById('activities')
      act.scrollIntoView({ behavior: 'smooth' })
    } else if (section == 'conclusions') {
      var con = document.getElementById('conclusions')
      con.scrollIntoView({ behavior: 'smooth' })
    } else if (section == 'commitments') {
      var com = document.getElementById('commitments')
      com.scrollIntoView({ behavior: 'smooth' })
    } else if (section == 'commitmentspend') {
      var com = document.getElementById('commitmentspend')
      com.scrollIntoView({ behavior: 'smooth' })
    }
    if (document.getElementById('act_committee_process_and_committee') !== null || document.getElementById('act_committee_process') !== null)
    {
      this.committee_act()
    }
  }

  committee_act (){
    const act_committee_true = document.getElementById('act_committee_process_and_committee').checked
    const act_committee_false = document.getElementById('act_committee_process').checked
    if (act_committee_true){
      $('#procedure_commite').show('slow')
      $('#procedure').hide('slow')
      $('#procedure *').prop('disabled',true);
    }
    else if (act_committee_false){
      $('#procedure').show('slow')
      $('#procedure_commite').hide('slow')
      $('#procedure_commite *').prop('disabled',true)
    }
  }

  procedurecommite(){
    $('#procedure_commite').show('slow')
    $('#procedure_commite *').prop('disabled',false)
    $('#procedure').hide('slow')
    $('#procedure *').prop('disabled',true);
  }
  procedure(){
    $('#procedure').show('slow')
    $('#procedure *').prop('disabled',false)
    $('#procedure_commite').hide('slow')
    $('#procedure_commite *').prop('disabled',true);
  }
}
