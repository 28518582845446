import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  static targets = ['percentage', 'date', 'achievement1', 'achievement2']

  connect () {
    this.selectName = this.achievement1Target.name
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()
    const { value } = this.percentageTarget
    if (Number(value) < 100) {
      $(this.dateTarget).prop('disabled', true)
      $(this.achievement1Target).hide()
      this.achievement1Target.name = ''
      $(this.achievement2Target).show()
      this.achievement2Target.name = this.selectName
    } else {
      $(this.dateTarget).prop('disabled', false)
      $(this.dateTarget).val(null).trigger('change')
      $(this.achievement1Target).show()
      this.achievement1Target.name = this.selectName
      $(this.achievement2Target).hide()
      this.achievement2Target.name = ''
    }
  }
}
