import React from "react";

const ExtensionModal = () => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th className="align-middle text-center">
            PARÁMETROS DE CALIFICACIÓN CUALITATIVA
          </th>
          <th className="align-middle text-center"> CALIFICACIÓN </th>
          <th className="align-middle text-center"> GRADO DE SIGNIFICANCIA </th>
          <th className="align-middle text-center"> DESCRIPCIÓN </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="align-middle text-justify" rowSpan="5">
            EXTENSION - (EX)
          </td>
          <td className="align-middle text-center">PUNTUAL</td>
          <td className="align-middle text-center">1</td>
          <td className="align-middle text-justify">
            Si el impacto es muy localizado
          </td>
        </tr>
        <tr>
          <td className="align-middle text-center">LOCAL</td>
          <td className="align-middle text-center">2</td>
          <td className="align-middle text-justify">
            El impacto se presenta en menos del 50% del área donde se construirá
            la planta y el interceptor
          </td>
        </tr>
        <tr>
          <td className="align-middle text-center">EXTENSO</td>
          <td className="align-middle text-center">4</td>
          <td className="align-middle text-justify">
            El impacto se presenta en el mas del 50% del área donde se
            construirá la planta y el interceptor
          </td>
        </tr>
        <tr>
          <td className="align-middle text-center">TOTAL</td>
          <td className="align-middle text-center">8</td>
          <td className="align-middle text-justify">
            El impacto no admite una ubicación precisa dentro del entorno del
            programa de saneamiento. Tiene una influencia generalizada en toda
            el área de estudio
          </td>
        </tr>
        <tr>
          <td className="align-middle text-center">CRÍTICO</td>
          <td className="align-middle text-center">12</td>
          <td className="align-middle text-justify">
            En caso de que el impacto se produzca en un sitio critico, se le
            sumara cuatro a la calificación del parámetro
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ExtensionModal;
