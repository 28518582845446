import { Controller } from 'stimulus'
import copy from 'copy-to-clipboard'

export default class extends Controller {
  static targets = ['source']

  onCopy (e) {
    e.preventDefault()
    const url = this.data.get('url')
    copy(url)
  }

  ulrcopy (e){
    e.preventDefault();
    const link = e.currentTarget.getAttribute('data-url');
    navigator.clipboard.writeText(link).then(() => {
      alert("URL de Evaluación a Auditores fue copiada al portapapeles, puede compartirla a los usuarios encargados de la evaluacón");
    }).catch(err => {
      console.error("Error al copiar al portapapeles: ", err);
    });
  }
}
