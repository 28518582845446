import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['procedure']

  onDelete (e) {
    e.preventDefault()
    this.procedureTarget.value = '1'
    $(this.element).fadeOut()
  }
}
