import React, { useState } from "react";
import { LaboratoriesContext } from "../LaboratoriesContext";
import { CollapseTypeLaboratory } from "../CollapseTypeLaboratory";
import { InputGroup } from "../../InputGroup";
import Months from "react-calendar/dist/cjs/YearView/Months";


function TestExecution(){
  const {
    updateLaboratory,
    setOpenModalTestExecution,
    dataLaboratory
  } = React.useContext(LaboratoriesContext)
  const [descriptionResults, setDescriptionResults] = useState(dataLaboratory?.description_results || '')
  const [applications, setApplications] = useState(dataLaboratory?.request_tests || []);
  const [testExecutions, setTestExecutions] = useState(
    dataLaboratory?.test_executions.length > 0 ? dataLaboratory?.test_executions : dataLaboratory?.request_tests.map(request => ({
      id: '',
      type_laboratory_test_id: request.type_laboratory_test_id,
      laboratory_id: request.laboratory_id,
      number_unit: '',
      acceptance_criteria: '',
      uncertainty: '',
      result: '',
      declaration_conformity: true,
      _destroy: false
    }))
  );
  const [abilitySend, setAbilitySend] = useState(
    testExecutions.length > 0 ?
    testExecutions.every(test =>
      test.type_laboratory_test_id !== '' &&
      test.laboratory_id !== '' &&
      test.number_unit !== '' &&
      test.acceptance_criteria !== '' &&
      test.uncertainty !== '' &&
      test.result !== '' &&
      test.declaration_conformity !== ''
    ) : false)
  const [temperature, setTemperature] = useState(dataLaboratory?.temperature || '')
  const [atmosphericPressure, setAtmosphericPressure] = useState(dataLaboratory?.atmospheric_pressure || '')
  const [relativeHumidity, setRelativeHumidity] = useState(dataLaboratory?.relative_humidity || '')


  const updateNumerUnit = (e, id) => {
    const data = e.target.value
    setTestExecutions(
      testExecutions.map((execution) =>
        execution.type_laboratory_test_id === id 
          ? { ...execution, number_unit: data } 
          : execution
      )
    );
    setAbilitySend(DataComplete)
  }

  const udpateAcceptanceCriteria = (e, id) =>{
    const data = e.target.value
    setTestExecutions(
      testExecutions.map((execution) =>
        execution.type_laboratory_test_id === id 
          ? { ...execution, acceptance_criteria: data } 
          : execution
      )
    );
    setAbilitySend(DataComplete)
  }

  const udpateUncertainty = (e, id) =>{
    const data = e.target.value
    setTestExecutions(
      testExecutions.map((execution) =>
        execution.type_laboratory_test_id === id 
          ? { ...execution, uncertainty: data } 
          : execution
      )
    );
    setAbilitySend(DataComplete)
  }

  const udpateResult = (e, id) =>{
    const data = e.target.value
    setTestExecutions(
      testExecutions.map((execution) =>
        execution.type_laboratory_test_id === id 
          ? { ...execution, result: data } 
          : execution
      )
    );
    setAbilitySend(DataComplete)
  }

  const updateDeclarationConformity = (id, value) => {
    setTestExecutions(
      testExecutions.map((execution) =>
        execution.type_laboratory_test_id === id 
          ? { ...execution, declaration_conformity: value } 
          : execution
      )
    );
    setAbilitySend(DataComplete)
  };

  const onUpdate = (e) => {
    e.preventDefault()
    const updateData = {
      description_results: descriptionResults,
      temperature: temperature,
      atmospheric_pressure: atmosphericPressure,
      relative_humidity: relativeHumidity,
      date_analysis: new Date(),
      type_save: 'saveChangesTest',
      test_executions_attributes: testExecutions.map((execution) =>({
        id: execution?.id ? execution.id : '',
        type_laboratory_test_id: execution.type_laboratory_test_id,
        laboratory_id: execution.laboratory_id,
        number_unit: execution.number_unit,
        acceptance_criteria: execution.acceptance_criteria,
        uncertainty: execution.uncertainty,
        result: execution.result,
        declaration_conformity: execution.declaration_conformity
      })),
    }
    updateLaboratory(dataLaboratory.id, updateData)
  }

  const onUpdateState = (e) => {
    e.preventDefault()
    const updateData = {

      description_results: descriptionResults,
      temperature: temperature,
      atmospheric_pressure: atmosphericPressure,
      relative_humidity: relativeHumidity,
      date_analysis: new Date(),
      state: "awaiting_test_approval",
      test_executions_attributes: testExecutions,
    }

    updateLaboratory(dataLaboratory.id, updateData)
  }

  const onCancel = () => {
    setOpenModalTestExecution(false);
  }

  const DataComplete = () => {
    return testExecutions.every(test =>
      test.type_laboratory_test_id !== '' &&
      test.laboratory_id !== '' &&
      test.number_unit !== '' &&
      test.acceptance_criteria !== '' &&
      test.uncertainty !== '' &&
      test.result !== '' &&
      test.declaration_conformity !== ''
    );
  };

  return(
    <div className="modal fade show" tabIndex="-1" style={{ paddingRight: '4px', display: 'block', backgroundColor: 'rgba(3, 3, 3, 0.5)' }} role="document">
      <form onSubmit={onUpdate}>
        <div className="modal-dialog modal-dialog-scrollable" role="document" style={{ maxWidth: '700px' }}>
          <div className="modal-content">
            {/* Header del modal */}
            <div className="modal-header">
              <h5 className="modal-title font-weight-bold">EJECUCIÓN DE PRUEBAS</h5>
              <button type="button" className="close" aria-label="Close" onClick={onCancel}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {/* Cuerpo del modal */}
            <div className="modal-body">
              <h6 className="mt-3 font-weight-bold">Pruebas seleccionadas:</h6>
              
              {/* Contenedor de las pruebas seleccionadas */}
              <div className="container-fluid">
                {applications.length > 0 ? (
                  applications.map((application, index) => {
                    // Encuentra el testExecution correspondiente al application actual
                    const matchingTest = testExecutions.find(
                      test => test.type_laboratory_test_id === application.type_laboratory_test_id
                    );

                    return matchingTest ? (
                      <div className="card border" key={application.id}> 
                        <CollapseTypeLaboratory
                          key={application.id}
                          index={index}
                          dataLaboratory={application}
                          testExecution={matchingTest}
                          updateDeclarationConformity={updateDeclarationConformity}
                          updateNumerUnit={updateNumerUnit}
                          udpateAcceptanceCriteria={udpateAcceptanceCriteria}
                          udpateUncertainty={udpateUncertainty}
                          udpateResult={udpateResult}
                        />
                      </div>
                    ) : null;
                  })
                ) : (
                  <p>No hay pruebas seleccionadas.</p>
                )}
              </div>
              <table className="table table-bordered mt-4">
                <thead className="bg-danger text-white">
                  <tr>
                    <th colSpan={3}>
                      Condiciones Ambientales
                    </th>                    
                  </tr>
                  <tr>
                    <th>Temperatura °C</th>
                    <th>Presión Atmosférica</th>
                    <th>Humedad Relativa %</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <textarea
                        className="form-control"
                        value={temperature}
                        disabled={!abilitySend}
                        onChange={(e) => setTemperature(e.target.value)}
                      />
                    </td>
                    <td>
                      <textarea
                        className="form-control"
                        value={atmosphericPressure}
                        disabled={!abilitySend}
                        onChange={(e) => setAtmosphericPressure(e.target.value)}
                      />
                    </td>
                    <td>
                      <textarea
                        className="form-control"
                        value={relativeHumidity}
                        disabled={!abilitySend}
                        onChange={(e) => setRelativeHumidity(e.target.value)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <InputGroup iconClass="far fa-file-alt" label="Opiniones e Interpretaciones">
                <textarea
                  placeholder=" Descripción de opiniones e interpretaciones"
                  className="form-control"
                  value={descriptionResults}
                  disabled={!abilitySend}
                  onChange={(e) => setDescriptionResults(e.target.value)}
                />
              </InputGroup>
            </div>
            {/* Footer del modal */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onCancel}
              >
                Cancelar
              </button>
              <button
                className='btn btn-secondary'
                type="submit"
              >
                Guardar
              </button>
              <button
                className="btn btn-primary"
                type="button"
                disabled={!(descriptionResults !== '')}
                onClick={onUpdateState}
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export { TestExecution }