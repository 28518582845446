import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    this.onChange()
  }

  onChange () {
    if ($('#action_review_all_procedures').prop('checked')) { $('#select_field').hide() } else { $('#select_field').show() }
  }
}
