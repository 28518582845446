import { Controller }  from 'stimulus'

export default class extends Controller {

  connect() {
    var activity = document.getElementById("activity");
    var product = document.getElementById("product");
    activity.style.display = ''
    product.style.display = 'none'
    this.onChange()
  }

  onChange(e) {
    var ratified_true = document.getElementById("task_type_activity_true");
    var ratified_false = document.getElementById("task_type_activity_false");
    var activity = document.getElementById("activity");
    var product = document.getElementById("product");
    
    ratified_false.addEventListener("change", function(){
      if (ratified_false.value == 'false'){
        activity.style.display = 'none'
        product.style.display = ''
      }
    })

    ratified_true.addEventListener("change", function(){
      if (ratified_true.value == 'true'){
        activity.style.display = ''
        product.style.display = 'none'
      }
    })
    

  }

  
}
