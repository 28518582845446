import React, { useEffect, useState } from 'react'
import Select from 'react-select'

const AssignmentPanel = ({ act, employees, props }) => {
  const [produceUsers, setProduceUsers] = useState([]);
  const [counterProduce, setCounterProduce] = useState(0);
  const [reviewUsers, setReviewUsers] = useState([]);
  const [counterReview, setCounterReview] = useState(0);
  const [approveUsers, setApproveUsers] = useState([]);
  const [counterApprove, setCounterApprove] = useState(0);
  const [readUsers, setReadUsers] = useState([]);
  const [counterRead, setCounterRead] = useState(0);
  const [seeAllFlat, setSeeAllFlat] = useState(act.see_all);
  
  const options = employees.map(employee => {
    const [label, value] = employee;
    return { label, value };
  })

  const defaultOption = {
    label: act.name,
    value: act.employee_id
  }
  
  useEffect(() => {
    if (act.assignments.length > 1 ) {
      const newProduceUsers = act.assignments.filter((user) => user.action === 'produce');
      newProduceUsers.shift();
      setProduceUsers(newProduceUsers);
      const newReviewUsers = act.assignments.filter((user) => user.action === 'review');
      setReviewUsers(newReviewUsers);
      const newApproveUsers = act.assignments.filter((user) => user.action === 'approve');
      setApproveUsers(newApproveUsers);
      const newReadUsers = act.assignments.filter((user) => user.action === 'read');
      setReadUsers(newReadUsers);
    }
  }, []);

  const handleClickProduce = () => {
    setCounterProduce( counterProduce + 1 );
    const newUser = {
      user_id: defaultOption.value,
      id: counterProduce,
    }
    setProduceUsers([...produceUsers, newUser]);
  }

  const handleClickReview = () => {
    setCounterReview( counterReview + 1 );
    const newUser = {
      user_id: defaultOption.value,
      id: counterReview,
    }
    setReviewUsers([...reviewUsers, newUser]);
  }
  
  const handleClickApprove = () => {
    setCounterApprove( counterApprove + 1 );
    const newUser = {
      user_id: defaultOption.value,
      id: counterApprove,
    }
    setApproveUsers([...approveUsers, newUser]);
  }

  const handleClickRead = () => {
    setCounterRead( counterRead + 1 );
    const newUser = {
      user_id: defaultOption.value,
      id: counterRead,
    }
    setReadUsers([...readUsers, newUser]);
  }

  const handleSelectProduce = (id) => (e) => {
    const newUser = produceUsers.map(user => {
      if (user.id === id) {
        return {
          ...user,
          user_id: e.value
        }
      }
      return user
    })
    setProduceUsers(newUser);
  };
  
  const handleSelectReview = (id) => (e) => {
    const newUser = reviewUsers.map(user => {
      if (user.id === id) {
        return {
          ...user,
          user_id: e.value
        }
      }
      return user
    })
    setReviewUsers(newUser);
  };

  const handleSelectApprove = (id) => (e) => {
    const newUser = approveUsers.map(user => {
      if (user.id === id) {
        return {
          ...user,
          user_id: e.value
        }
      }
      return user
    })
    setApproveUsers(newUser);
  };

  const handleSelectRead = (id) => (e) => {
    const newUser = readUsers.map(user => {
      if (user.id === id) {
        return {
          ...user,
          user_id: e.value
        }
      }
      return user
    })
    setReadUsers(newUser);
  };

  function fadeOut(element, duration) {
    let interval = 50; // Intervalo en milisegundos
    let steps = duration / interval;
    let currentStep = 0;
    let initialOpacity = parseFloat(window.getComputedStyle(element).opacity);
  
    let fadeOutInterval = setInterval(function() {
      currentStep++;
      let opacity = initialOpacity - (currentStep / steps);
      element.style.opacity = opacity;
  
      if (currentStep >= steps) {
        clearInterval(fadeOutInterval);
        element.style.display = 'none'; // Opcional, ocultar el elemento después del desvanecimiento
      }
    }, interval);
  }

  const handleRemoveProduce = (index) => (e)=> {
    const parentDiv = e.target.parentNode;
    const parentDiv2 = parentDiv.parentNode;
    const parentDiv3 = parentDiv2.parentNode;
    const parentDiv4 = parentDiv3.parentNode;
    let inputTarget = parentDiv3.querySelector('[class="act_destroy"]');
    fadeOut(parentDiv4,250);
    const position = index + 1;
    parentDiv3.removeChild(inputTarget);
    const input = document.createElement("input");
    input.value = 1;
    input.name = `act[assignments_attributes][${position}][_destroy]`;
    input.hidden = true;
    parentDiv3.appendChild(input);
  };

  const handleRemoveReview = (index) => (e) => {
    const parentDiv = e.target.parentNode;
    const parentDiv2 = parentDiv.parentNode;
    const parentDiv3 = parentDiv2.parentNode;
    const parentDiv4 = parentDiv3.parentNode;
    let inputTarget = parentDiv3.querySelector('[class="act_destroy"]');
    fadeOut(parentDiv4,250);
    const position = index + 200;
    parentDiv3.removeChild(inputTarget);
    const input = document.createElement("input");
    input.value = 1;
    input.name = `act[assignments_attributes][${position}][_destroy]`;
    input.hidden = true;
    parentDiv3.appendChild(input);
  };

  const handleRemoveApprove = (index) => (e) => {
    const parentDiv = e.target.parentNode;
    const parentDiv2 = parentDiv.parentNode;
    const parentDiv3 = parentDiv2.parentNode;
    const parentDiv4 = parentDiv3.parentNode;
    let inputTarget = parentDiv3.querySelector('[class="act_destroy"]');
    fadeOut(parentDiv4,250);
    const position = index + 400;
    parentDiv3.removeChild(inputTarget);
    const input = document.createElement("input");
    input.value = 1;
    input.name = `act[assignments_attributes][${position}][_destroy]`;
    input.hidden = true;
    parentDiv3.appendChild(input);
  };

  const handleRemoveRead = (index) => (e) => {
    const parentDiv = e.target.parentNode;
    const parentDiv2 = parentDiv.parentNode;
    const parentDiv3 = parentDiv2.parentNode;
    const parentDiv4 = parentDiv3.parentNode;
    let inputTarget = parentDiv3.querySelector('[class="act_destroy"]');
    fadeOut(parentDiv4,250);
    const position = index + 600;
    parentDiv3.removeChild(inputTarget);
    const input = document.createElement("input");
    input.value = 1;
    input.name = `act[assignments_attributes][${position}][_destroy]`;
    input.hidden = true;
    parentDiv3.appendChild(input);
  };

  const handleCheckSeeAll = () => {
    setSeeAllFlat(!seeAllFlat);
  }

  return (
    <div className='m-0'>
      <div className='card card-body p-0'>
          <div className="form-row m-0">
            <div className='col-md-3 p-0'>
              <div className='card-header bg-white'>
                <h6>Elabora</h6>
              </div>
              <div className='card-body'>
                <div className='input-group mb-3'>
                  <div className='input-group-prepend'>
                    <div className='input-group-text bg-white border-0'>
                      <span className='fas fa-user'></span>
                    </div>
                  </div>
                  <Select defaultValue={defaultOption} isDisabled={true} />                 
                </div>
                {produceUsers.length > 0 && produceUsers.map((user, index) => (
                    <div key={!!user.assignment_id ? `${user.assignment_id}` : user.id}>
                      <div className='input-group mb-3'>  
                        <div className='input-group-prepend'>
                          <div className='input-group-text bg-white border-0'>
                            <span className='fas fa-user'></span>
                          </div>
                          </div>
                          <Select 
                            options={options}  
                            defaultValue={!!user.user_name ? user.user_name : defaultOption}
                            name={`act[assignments_attributes][${index + 1}][user_id]` }
                            onChange={handleSelectProduce(user.id)}
                            value={user.user_name}
                          />
                          <input hidden name={`act[assignments_attributes][${index + 1}][action]`} value="produce" readOnly/>
                          <input hidden name={`act[assignments_attributes][${index + 1}][id]`} defaultValue={user.assignment_id === undefined ? '': `${user.assignment_id}`} />
                          <input hidden name={`act[assignments_attributes][${index + 1}][assignable_id]`} value={`${act.assignments[0].assignable_id}`} readOnly/>
                          <input hidden name={`act[assignments_attributes][${index + 1}][assignable_type]`} value="Act" readOnly/>
                          <input hidden name={`act[assignments_attributes][${index + 1}][_destroy]`} className='act_destroy' defaultValue={false} />
                          <div className='input-group-append'>
                          <div className='input-group-text bg-white border-0'>
                            <span className='fas fa-times cursor-pointer' onClick={handleRemoveProduce(index)}></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                <button className='btn btn-info btn-sm' onClick={handleClickProduce} type='button'>
                  <span className='fas fa-plus ml-1 mr-1'>
                    Agregar
                  </span>
                </button>
              </div>
            </div>
            <div className='col-md-3 p-0'>
              <div className='card-header bg-white'>
                <h6>Revisa</h6>
              </div>
              <div className='card-body'>   
            {reviewUsers.length > 0 && reviewUsers.map((user, index) => (
              <div key={!!user.assignment_id ? `${user.assignment_id}` : user.id}>
                <div className='input-group mb-3'>  
                  <div className='input-group-prepend'>
                    <div className='input-group-text bg-white border-0'>
                      <span className='fas fa-user'></span>
                    </div>
                    </div>
                    <Select 
                      options={options}  
                      name={`act[assignments_attributes][${index + 200}][user_id]` }
                      defaultValue={!!user.user_name ? user.user_name : defaultOption } 
                      onChange={handleSelectReview(user.id)}
                      value={user.user_name}
                    />
                    <input hidden name={`act[assignments_attributes][${index + 200}][action]`} value="review" readOnly/>
                    <input hidden name={`act[assignments_attributes][${index + 200}][id]`} defaultValue={user.assignment_id === undefined ? '': `${user.assignment_id}`} />
                    <input hidden name={`act[assignments_attributes][${index + 200}][assignable_id]`} value={`${act.assignments[0].assignable_id}`} readOnly/>
                    <input hidden name={`act[assignments_attributes][${index + 200}][assignable_type]`} value="Act" readOnly/>
                    <input hidden name={`act[assignments_attributes][${index + 200}][_destroy]`} className='act_destroy' value={false} readOnly/>
                    <div className='input-group-append'>
                    <div className='input-group-text bg-white border-0'>
                      <span className='fas fa-times cursor-pointer' onClick={handleRemoveReview(index)}></span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <button className='btn btn-info btn-sm' onClick={handleClickReview} type='button'>
                <span className='fas fa-plus mr-2' >
                  Agregar
                </span>
            </button>
            </div>
            </div>
            <div className='col-md-3 p-0'>
              <div className='card-header bg-white'>
                <h6>
                  Aprueba
                </h6>
              </div>
              <div className='card-body'>
            {approveUsers.length > 0 && approveUsers.map((user, index) => (
              <div key={!!user.assignment_id ? `${user.assignment_id}` : user.id}>
                <div className='input-group mb-3'>
                  <div className='input-group-prepend'>
                    <div className='input-group-text bg-white border-0'>
                      <span className='fas fa-user'></span>
                    </div>
                  </div>
                  <Select 
                    options={options}  
                    defaultValue={!!user.user_name ? user.user_name : defaultOption } 
                    name={`act[assignments_attributes][${index + 400}][user_id]` }
                    onChange={handleSelectApprove(user.id)}
                    value={user.user_name}
                  />
                  <input hidden name={`act[assignments_attributes][${index + 400}][action]`} value="approve" readOnly/>
                  <input hidden name={`act[assignments_attributes][${index + 400}][id]`} defaultValue={user.assignment_id === undefined ? '': `${user.assignment_id}`} />
                  <input hidden name={`act[assignments_attributes][${index + 400}][assignable_id]`} value={`${act.assignments[0].assignable_id}`} readOnly/>
                  <input hidden name={`act[assignments_attributes][${index + 400}][assignable_type]`} value="Act" readOnly/>
                  <input hidden name={`act[assignments_attributes][${index + 400}][_destroy]`} className='act_destroy' value={false} readOnly/>
                  <div className='input-group-append'>
                  <div className='input-group-text bg-white border-0'>
                    <span className='fas fa-times cursor-pointer' onClick={handleRemoveApprove(index)}></span>
                  </div>
                  </div>
                </div>
              </div>
            ))}
              <button className='btn btn-info btn-sm' onClick={handleClickApprove} type='button'>
                <span className='fas fa-plus mr-2'>
                  Agregar
                </span>
              </button>
              </div>
            </div>
            <div className='col-md-3 p-0'>
              <div className='card-header bg-white'>
                <h6>
                  Leer
                </h6>
              </div>
              <div className='card-body'>
                <div className='form-row mb-2'>
                  <div className='d-flex align-items-center'>
                    <input 
                      type='checkbox'
                      checked={ seeAllFlat }
                      onChange={ handleCheckSeeAll }
                      name= 'act[see_all]'
                      value= {seeAllFlat}
                    />
                    {!seeAllFlat && <input hidden name='act[see_all]' value={seeAllFlat} readOnly/> }
                    <label className='ml-2 mr-2 mb-0'>Todos</label>
                  </div>
                </div>
                {(readUsers.length >= 0 && !seeAllFlat) && (
                  <>
                    {readUsers.map((user, index)=>(
                    <div key={!!user.assignment_id ? `${user.assignment_id}` : user.id}>
                      <div className="input-group mb-3 ">
                        <div className='input-group-prepend'>
                          <div className='input-group-text bg-white border-0'>
                            <span className='fas fa-user'></span>
                          </div>
                        </div>
                        <Select 
                          options={options}  
                          defaultValue={!!user.user_name ? user.user_name : defaultOption }
                          name={`act[assignments_attributes][${index + 600}][user_id]` }
                          onChange={handleSelectRead(user.id)}
                          value={user.user_name}
                        />
                        <input hidden name={`act[assignments_attributes][${index + 600}][id]`} defaultValue={user.assignment_id === undefined ? '': `${user.assignment_id}`} />
                        <input hidden name={`act[assignments_attributes][${index + 600}][action]`} value="read" readOnly/>
                        <input hidden name={`act[assignments_attributes][${index + 600}][assignable_id]`} value={`${act.assignments[0].assignable_id}`} readOnly/>
                        <input hidden name={`act[assignments_attributes][${index + 600}][assignable_type]`} value="Act" readOnly/>
                        <input hidden name={`act[assignments_attributes][${index + 600}][_destroy]`} className='act_destroy' value={false} readOnly/>
                        <div className='input-group-append'>
                        <div className='input-group-text bg-white border-0'>
                          <span className='fas fa-times cursor-pointer' onClick={handleRemoveRead(index)}></span>
                        </div>
                        </div>
                      </div> 
                    </div>
                    ))}
                    <button className='btn btn-info btn-sm' onClick={handleClickRead} type='button'>
                      <span className='fas fa-plus ml-1 mr-1'>
                        Agregar
                      </span>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default AssignmentPanel