import React, { useContext, useState, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import {
  Button,
  OverlayTrigger,
  Tooltip,
  ProgressBar,
  Container,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faTrash,
  faPenToSquare,
  faFile,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Header from "./Header";
import Table from "./Table";
import { FLASH_TYPE_ERROR, useFlash } from "./Context/FlashContext";
import { AppContext } from "./Context/AppContext";
import LoaderTable from "./Skeleton/LoaderTable";
import formatDateTimeShort from "../utils/FormatDateTimeShort";

const Follow = () => {
  const [follows, setFollows] = useState([]);
  const [infoPageFollow, setInfoPageFollow] = useState([]);
  const { setLoading } = useContext(AppContext);
  const { setFlash } = useFlash();
  const [searchParams] = useSearchParams();
  const { actionId, troubleId, investigationId } = useParams();
  const deleteFollow = async (id) => {
    try {
      const url = `/api/v2/troubles/${troubleId}/investigations/${investigationId}/actions/${actionId}/follows/${id}`;
      const response = await axios.delete(url);
      if (response.status === 204) {
        setFlash({
          type: FLASH_TYPE_ERROR,
          message: "Elemento Eliminado",
        });
        fetchFollows(
          searchParams.get("page") || 1,
          troubleId,
          investigationId,
          actionId
        );
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      setFlash({
        type: FLASH_TYPE_ERROR,
        message: "Ocurrio un error en el servidor",
      });
      console.error("Error", error);
    }
  };

  const fetchFollows = async (page, troubleId, investigationId, actionId) => {
    setLoading(true);

    try {
      const url = `/api/v2/troubles/${troubleId}/investigations/${investigationId}/actions/${actionId}/follows`;
      const searchParams = new URLSearchParams();
      if (page) {
        searchParams.set("page", page || 1);
      }
      const response = await axios.get(`${url}?${searchParams.toString()}`);
      const data = await response.data;
      setFollows(data.follows);
      setInfoPageFollow(data.total_pages);
    } catch (error) {
      setFlash({
        type: FLASH_TYPE_ERROR,
        message: "Ocurrio un error en el servidor",
      });
      console.error("Error al cargar las actions", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFollows(
      searchParams.get("page") || 1,
      troubleId,
      investigationId,
      actionId
    );
  }, [searchParams]);

  const getProgressBar = (percentage) => {
    let variant;
    if (percentage > 59) {
      variant = "success";
    } else if (percentage > 39) {
      variant = "warning";
    } else {
      variant = "danger";
    }

    return (
      <ProgressBar
        now={percentage}
        label={`${percentage}%`}
        variant={variant}
        style={{
          height: "25px",
          borderRadius: "20px",
          background: "#000000",
          width: "283px",
        }}
        max={100}
        min={0}
      />
    );
  };
  return (
    <>
      <Header
        title={"Seguimientos"}
        iconBack={
          <Link
            to={`/troubles/${troubleId}/investigation/${investigationId}/actions`}
            className="pr-3"
          >
            <Button variant="bg-dark">
              <FontAwesomeIcon icon={faArrowLeft} color="#438dff" />
            </Button>
          </Link>
        }
        buttonNewElement={
          <Link
            to={`/troubles/${troubleId}/investigation/${investigationId}/action/${actionId}/follows/new`}
            className="position-absolute"
            style={{ right: "21px", top: "9px" }}
          >
            <Button>Nuevo</Button>
          </Link>
        }
      />

      <Table
        numPages={infoPageFollow}
        items={follows.length}
        heads={
          <tr>
            <th className="text-center">Acciones</th>
            <th className="text-center">Hallazgo</th>
            <th className="text-center">Porcentaje</th>
            <th className="text-center">Cierre Real</th>
            <th className="text-center">Responsable</th>
            <th className="text-center">Fecha de Creación</th>
          </tr>
        }
        loaderSize={
          <>
            {Array.from({ length: 3 }).map((_, index) => (
              <tr key={index}>
                <td style={{ minWidth: "327px" }}>
                  <LoaderTable />
                </td>
                <td style={{ minWidth: "430px" }}>
                  <LoaderTable />
                </td>
                <td style={{ minWidth: "300px" }}>
                  <LoaderTable />
                </td>
                <td
                  style={{
                    minWidth: "493px",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <LoaderTable />
                  </div>
                </td>
                <td style={{ maxWidth: "158px" }}>
                  <LoaderTable />
                </td>
                <td style={{ maxWidth: "209px" }}>
                  <LoaderTable />
                </td>
              </tr>
            ))}
          </>
        }
        data={
          <>
            {follows.map((item) => (
              <tr key={item.id}>
                <td style={{ width: "200px" }}>
                  <Container className="d-flex">
                    <Link
                      to={`/troubles/${troubleId}/investigation/${investigationId}/action/${actionId}/follows/update/${item.id}`}
                    >
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Editar</Tooltip>}
                      >
                        <Button
                          variant="outline-success"
                          className="m-2"
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </Button>
                      </OverlayTrigger>
                    </Link>

                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Eliminar</Tooltip>}
                    >
                      <Button
                        variant="outline-danger"
                        className="m-2"
                        onClick={() => deleteFollow(item.id)}
                        size="sm"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </OverlayTrigger>

                    {item.file_summary &&
                    /\.(jpg|png|jpeg|gif|svg)$/i.test(
                      item.file_summary.name
                    ) ? (
                      <Link to={item.file_summary.url} target="_blank">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Anexos</Tooltip>}
                        >
                          <Button
                            variant="outline-primary"
                            className="m-2"
                            disabled={item.file_summary ? false : true}
                            size="sm"
                          >
                            <FontAwesomeIcon icon={faFile} />
                          </Button>
                        </OverlayTrigger>
                      </Link>
                    ) : (
                      <Link
                        to={`/api/v2/troubles/${troubleId}/investigations/${investigationId}/actions/${actionId}/follows/controlled_copy_follows.${item.id}`}
                        target="_blank"
                      >
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Anexos</Tooltip>}
                        >
                          <Button
                            variant="outline-primary"
                            className="m-2"
                            disabled={item.file_summary ? false : true}
                            size="sm"
                          >
                            <FontAwesomeIcon icon={faFile} />
                          </Button>
                        </OverlayTrigger>
                      </Link>
                    )}
                  </Container>
                </td>
                <td
                  style={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item.finding}
                </td>
                <td
                  style={{
                    width: "300px",
                  }}
                >
                  {getProgressBar(item.percentage)}
                </td>
                {item.percentage === 100 ? (
                  <td style={{ textAlign: "center" }}>{item.end_date} </td>
                ) : (
                  <td style={{ textAlign: "center" }}>
                    Se mostrará cuando el porcentaje sea igual a 100%
                  </td>
                )}

                <td>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        <span>
                          {item.employee.user && item.employee.user.first_name}
                        </span>{" "}
                        <span>
                          {item.employee.user && item.employee.user.last_name}
                        </span>
                      </Tooltip>
                    }
                  >
                    <div className="d-flex justify-content-center">
                      <FontAwesomeIcon icon={faUser} color="#007BFF" />
                    </div>
                  </OverlayTrigger>
                </td>
                <td style={{ textAlign: "center" }}>
                  {formatDateTimeShort(item.created_at)}
                </td>
              </tr>
            ))}
          </>
        }
      />
    </>
  );
};

export default Follow;
