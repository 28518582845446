import React, { useState } from 'react'
import Buttons from './Buttons'
import IndicatorSummary from './IndicatorSummary';

const Indicator = ({indicator, permissions, user, portal}) => {
  const percentage = parseFloat(indicator.measure?.percentage);
  const semaphores = indicator.semaphores;
  const [semaphore, setSemaphore] = useState(findSemaphore(semaphores, percentage))
  const [showSummary, setShowSummary] = useState(false)

  function findSemaphore (semaphores, resource) {
    let matched = {}

    for (let i = 0; i < semaphores?.length; i++) {
      const semaphore = semaphores[i];

      if (semaphore.range_type === 'between' && resource >= semaphore.starts && resource <= semaphore.ends) {
        matched = semaphore;
      }
      if (semaphore.range_type === 'equal_to' && resource === semaphore.starts) {
        matched = semaphore;
      }
      if (semaphore.range_type === 'greater_than' && resource > semaphore.starts) {
        matched = semaphore;
      }
      if (semaphore.range_type === 'greater_than_or_equal_to' && resource >= semaphore.starts) {
        matched = semaphore;
      }
      if (semaphore.range_type === 'less_than' && resource < semaphore.starts) {
        matched = semaphore;
      }
      if (semaphore.range_type === 'less_than_or_equal_to' && resource <= semaphore.starts) {
        matched = semaphore;
      }
    }
    return matched;
  }

  return(
    <React.Fragment>
      <div className='border border-light py-md-3 py-lg-1 py-4 w-100'>
        <div className='d-flex flex-wrap align-items-start bg-white'>
          <Buttons permissions={permissions} user={user} indicator={indicator} portal={portal}/>
          <div className='flex-grow-1'>
            <button className='btn btn-link btn-lg btn-block' onClick={() => setShowSummary(!showSummary)}>
              <div className='d-flex flex-wrap align-items-center'>
                <div className='text-muted text-left h6 mb-0 mr-1 font-weight-semibold'>
                  {indicator.name}
                </div>
                {indicator.state !== 'obsolete' && indicator.measure?.id !== undefined &&
                  <React.Fragment>
                    <span className='badge font-weight-normal mr-1'
                          style={{ color: semaphore.text_color, background : semaphore.background_color }}>
                      {`Cumplimiento: ${indicator.measure?.percentage}`}
                    </span>
                    <span className='badge font-weight-normal mr-1'
                          style={{ color: semaphore.text_color, background : semaphore.background_color }}>
                      {`Meta: ${indicator.measure?.goal}`}
                    </span>
                    <span className='badge font-weight-normal mr-1'
                          style={{ color: semaphore.text_color, background : semaphore.background_color }}>
                      {`Medición: ${indicator.measure?.result}`}
                    </span>
                    <span className='badge font-weight-normal mr-1'
                          style={{ color: semaphore.text_color, background : semaphore.background_color }}>
                      {indicator.measure?.measure_date}
                    </span>
                    <span className='badge font-weight-normal mr-1'
                          style={{ color: semaphore.text_color, background : semaphore.background_color }}>
                      {indicator.measure?.distance_of_time}
                    </span>
                    { indicator.measure?.ytd &&
                      <span className='badge font-weight-normal mr-1'
                            style={{ color: semaphore.text_color, background : semaphore.background_color }}>
                        {`YTD: ${indicator.measure?.ytd}`}
                      </span>
                    }
                  </React.Fragment>
                }
                {
                  indicator.state === 'obsolete' &&
                    <span className='badge badge-danger mr-1'>
                      Obsoleto
                    </span>
                }
              </div>
            </button>
          </div>
        </div>
        { indicator.finished === false && indicator.state === null &&
          <div className='mx-1 text-right'>{indicator.next_measure_reminder_message}</div>
        }
      </div>
      {showSummary && <IndicatorSummary indicator={indicator}/>}
    </React.Fragment>
  )
}

export default Indicator