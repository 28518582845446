import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  connect () {
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()
    var number = document.getElementsByClassName('element').length
    var number_negatives = document.getElementsByClassName('negatives').length
    var defaul = 1 / (number + number_negatives)
    var sum_weight = 0
    var sum_score = 0
    var sum_weight_negatives = 0
    var sum_score_negatives = 0
    for (var x = 0; x < number; x++) {
      if (document.getElementById('weight' + x).value) {} else {
        document.getElementById('weight' + x).setAttribute('value', defaul.toFixed(3))
      }
      var weight = document.getElementById('weight' + x).value
      var classification = document.getElementById('classification' + x).value
      var result = (weight * classification).toFixed(3)
      var sum_weight = parseFloat(sum_weight) + parseFloat(weight)
      var sum_score = parseFloat(sum_score) + parseFloat(result)
      document.getElementById('score' + x).setAttribute('value', result)
      document.getElementById('weight_opportunities').setAttribute('value', sum_weight.toFixed(3))
      document.getElementById('score_opportunities').setAttribute('value', sum_score.toFixed(3))
    }
    for (var y = 0; y < number_negatives; y++) {
      if (document.getElementById('weight_negative' + y).value) {} else {
        document.getElementById('weight_negative' + y).setAttribute('value', defaul.toFixed(3))
      }
      var weightn = document.getElementById('weight_negative' + y).value
      var classification_negative = document.getElementById('classification_negative' + y).value
      var result_negatives = (weightn * classification_negative).toFixed(3)
      var sum_weight_negatives = parseFloat(sum_weight_negatives) + parseFloat(weightn)
      var sum_score_negatives = parseFloat(sum_score_negatives) + parseFloat(result_negatives)
      document.getElementById('score_negative' + y).setAttribute('value', result_negatives)
      document.getElementById('weight_menace').setAttribute('value', sum_weight_negatives.toFixed(3))
      document.getElementById('score_menace').setAttribute('value', sum_score_negatives.toFixed(3))
    }
    document.getElementById('total_weight').setAttribute('value', (parseFloat(sum_weight) + parseFloat(sum_weight_negatives)).toFixed(2))
    var total_score = (parseFloat(sum_score) + parseFloat(sum_score_negatives)).toFixed(2)
    document.getElementById('total_score_mefe').setAttribute('value', total_score)
    if ((parseFloat(sum_weight) + parseFloat(sum_weight_negatives)).toFixed(2) != 1.0) {
      document.getElementById('total_weight').style.backgroundColor = '#FF3838'
      document.getElementById('total_weight').style.color = 'white'
    } else {
      document.getElementById('total_weight').style.backgroundColor = '#00D807'
      document.getElementById('total_weight').style.color = '#4F4F4F'
    }
    if (total_score > 3) {
      document.getElementById('mefe_analysis').style.backgroundColor = '#009688'
      document.getElementById('mefe_analysis').setAttribute('value', 'Evaluación Satisfactoria: Predominan las Oportunidades')
      document.getElementById('mefe_analysis').style.color = 'white'
    } else if ((total_score >= 2.5) && (total_score <= 3)) {
      document.getElementById('mefe_analysis').style.backgroundColor = '#FFC107'
      document.getElementById('mefe_analysis').setAttribute('value', 'Evaluación Aceptable: Predominan las Oportunidades')
      document.getElementById('mefe_analysis').style.color = 'white'
    } else {
      document.getElementById('mefe_analysis').style.backgroundColor = '#E91E63'
      document.getElementById('mefe_analysis').setAttribute('value', 'Evaluación No Satisfactoria: Predominan las Amenazas')
      document.getElementById('mefe_analysis').style.color = 'white'
    }
  }

  reCalculate (e) {
    if (e) e.preventDefault()
    var number = document.getElementsByClassName('element').length
    var number_negatives = document.getElementsByClassName('negatives').length
    var defaul = 1 / (number + number_negatives)
    for (var x = 0; x < number; x++) {
      document.getElementById('weight' + x).setAttribute('value', defaul.toFixed(3))
    }
    for (var y = 0; y < number_negatives; y++) {
      document.getElementById('weight_negative' + y).setAttribute('value', defaul.toFixed(3))
    }
    this.onChange()
  }
}
