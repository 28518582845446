import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    if (!window.location.hash) {
      window.location = window.location + '#loaded'
      window.location.reload()
    }
    var time = $('#text').html()
    if (time == 'Tiempo Agotado') {} else {
      let timerId = setTimeout(function tick () {
        var time = $('#text').html()
        var seconds = new Date('1970-01-01T' + time + 'Z').getTime() / 1000
        var s = seconds - 1
        var hours = ((s - s % 3600) / 3600) % 60
        var minutes = ((s - s % 60) / 60) % 60
        seconds = s % 60
        if (s > 0) {
          $('#text').text(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`)
        } else {
          $('#text').text('Tiempo Agotado')
        }
        clearTimeout(timerId)
        timerId = setTimeout(tick, 1000) // (*)
      }, 1000)
    }
  }
}
