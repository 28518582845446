import React from "react"

const Header = ({ backUrl }) => {
  return (
    <>
      <div className="card border-0" data-sidebar-section="js-environmental-management" data-sidebar-menu="js-environmental-impacts-menu">
        <div className="card-header d-flex justify-content-between position-sticky sticky-top bg-dark">
          <div className="card-title mb-0 d-flex align-items-center">
            <a className="btn btn-link" href={backUrl}>
              <i className="fas fa-arrow-left"></i>
            </a>
            <h5 className="mb-0 text-white">Objetivo Operativo</h5>
          </div>
          <div className="card-options d-flex">
            <button className="btn btn-primary mx-1" type='submit'> Guardar</button>
          </div>
        </div>

      </div>
    </>
  )
}

export default Header;
