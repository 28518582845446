import React from "react";

const formatDateTimeShort = (dateTimeString) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const formattedDate = new Date(dateTimeString).toLocaleDateString(
    undefined,
    options
  );
  return formattedDate;
};
export default formatDateTimeShort;
