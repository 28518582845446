import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Container,
  OverlayTrigger,
  ProgressBar,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faTrash,
  faPenToSquare,
  faChevronRight,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Header from "./Header";
import Table from "./Table";
import { AppContext } from "./Context/AppContext";
import LoaderTable from "./Skeleton/LoaderTable";
import { FLASH_TYPE_ERROR, useFlash } from "./Context/FlashContext";

const Action = () => {
  const [actions, setActions] = useState([]);
  const [infoPageAction, setInfoPageAction] = useState([]);
  const [searchParams] = useSearchParams();

  const { setLoading } = useContext(AppContext);
  const { setFlash } = useFlash();

  const { troubleId, investigationId } = useParams();

  const deleteAction = async (id) => {
    try {
      const url = `/api/v2/troubles/${troubleId}/investigations/${investigationId}/actions/${id}`;
      const response = await axios.delete(url);
      if (response.status === 204) {
        setFlash({
          type: FLASH_TYPE_ERROR,
          message: "Elemento Eliminado",
        });

        fetchActions(searchParams.get("page") || 1, troubleId, investigationId);
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      setFlash({
        type: FLASH_TYPE_ERROR,
        message: "Ocurrio un error en el servidor",
      });
    }
  };

  const fetchActions = async (page, troubleId, investigationId) => {
    setLoading(true);

    try {
      const url = `/api/v2/troubles/${troubleId}/investigations/${investigationId}/actions`;
      const searchParams = new URLSearchParams();
      if (page) {
        searchParams.set("page", page || 1);
      }
      const response = await axios.get(`${url}?${searchParams.toString()}`);
      const data = await response.data;
      setActions(data.actions);
      setInfoPageAction(data.total_pages);
    } catch (error) {
      setFlash({
        type: FLASH_TYPE_ERROR,
        message: "Ocurrio un error en el servidor",
      });

      console.error("Error al cargar las actions", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchActions(searchParams.get("page") || 1, troubleId, investigationId);
  }, [searchParams]);

  const CustomProgressBar = ({ now, label }) => {
    const porcentajeString = now;
    const porcentajeNumero = parseInt(porcentajeString, 10);
    const progressBarStyle = {
      height: "25px",
      backgroundColor: "black",
      borderRadius: "20px",
    };

    const customProgressBarStyle = {
      width: `${porcentajeNumero}%`,
      backgroundColor: "#60CBF7",
      height: "100%",
      borderRadius: "20px",
    };

    return (
      <div
        style={{
          textAlign: "center",
          padding: "3px",
          position: "relative",
          color: "white",
        }}
      >
        <div style={progressBarStyle}>
          <div style={customProgressBarStyle}></div>
        </div>
        <p
          className="progress-label"
          style={{
            fontSize: "0.9em",
            position: "absolute",
            margin: "0",
            left: "0",
            right: "0",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <strong>{label}</strong>
        </p>
      </div>
    );
  };

  return (
    <>
      <Header
        title={"Plan de acción"}
        iconBack={
          <Link to={`/troubles/${troubleId}/investigations`} className="pr-3">
            <Button variant="bg-dark">
              <FontAwesomeIcon icon={faArrowLeft} color="#438dff" />
            </Button>
          </Link>
        }
        buttonNewElement={
          <Link
            to={`/troubles/${troubleId}/investigation/${investigationId}/actions/new`}
            className="position-absolute"
            style={{ right: "21px", top: "9px" }}
          >
            <Button>Nuevo</Button>
          </Link>
        }
      />

      <Table
        numPages={infoPageAction}
        items={actions.length}
        heads={
          <tr>
            <th></th>
            <th className="text-center">Titulo</th>
            <th className="text-center">Presupuesto</th>
            <th className="text-center">Timeline</th>
            <th className="text-center">Responsable</th>
            <th className="text-center">Estado</th>
            <th className="text-center">Oportunidad</th>
          </tr>
        }
        loaderSize={
          <>
            {Array.from({ length: 3 }).map((_, index) => (
              <tr key={index}>
                <td style={{ minWidth: "332px" }}>
                  <LoaderTable />
                </td>
                <td style={{ minWidth: "280px" }}>
                  <LoaderTable />
                </td>
                <td style={{ maxWidth: "160px" }}>
                  <LoaderTable />
                </td>
                <td
                  style={{
                    minWidth: "514px",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <LoaderTable />
                  </div>
                </td>
                <td style={{ maxWidth: "162px" }}>
                  <LoaderTable />
                </td>
                <td style={{ maxWidth: "144px" }}>
                  <LoaderTable />
                </td>
                <td style={{ minWidth: "323px" }}>
                  <LoaderTable />
                </td>
              </tr>
            ))}
          </>
        }
        data={
          <>
            {actions.map((item) => (
              <tr key={item.id}>
                <td style={{ width: "200px" }}>
                  <Container className="d-flex">
                    <Link
                      to={`/troubles/${troubleId}/investigation/${investigationId}/action/${item.id}/follows`}
                    >
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Seguimiento de esta acción</Tooltip>}
                      >
                        <Button
                          variant="outline-primary"
                          className="m-2"
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faChevronRight} />
                        </Button>
                      </OverlayTrigger>
                    </Link>
                    <Link
                      to={`/troubles/${troubleId}/investigation/${investigationId}/actions/update/${item.id}`}
                    >
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Editar</Tooltip>}
                      >
                        <Button
                          variant="outline-success"
                          className="m-2"
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </Button>
                      </OverlayTrigger>
                    </Link>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Eliminar</Tooltip>}
                    >
                      <Button
                        variant="outline-danger"
                        className="m-2"
                        onClick={() => deleteAction(item.id)}
                        size="sm"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </OverlayTrigger>
                  </Container>
                </td>
                <td
                  style={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item.activity}
                </td>
                <td>{item.budget}</td>
                <td style={{ width: "334px" }}>
                  <CustomProgressBar
                    now={item.timeline}
                    label={`${item.initial_date}-${item.end_date}`}
                  />
                </td>
                <td>
                  {item.assignments.map((assignment) =>
                    assignment.action === "produce" && assignment.user ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            <span className=" d-flex  flex-column ">
                              {assignment.user.first_name}{" "}
                              {assignment.user.last_name}
                            </span>
                          </Tooltip>
                        }
                        key={assignment.id}
                      >
                        <div className="d-flex justify-content-center mb-2 ">
                          <FontAwesomeIcon icon={faUser} color="#007BFF" />
                        </div>
                      </OverlayTrigger>
                    ) : null
                  )}
                </td>
                {item.achievement ? (
                  <td
                    style={{
                      background: `${item.achievement.color}`,
                      textAlign: "center",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    {item.achievement.name}
                  </td>
                ) : (
                  <td
                    style={{
                      background: "#FFC107",
                      textAlign: "center",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    En Proceso
                  </td>
                )}
                <td
                  style={{
                    background: `${item.opportunity_action[1]}`,
                    textAlign: "center",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  {item.opportunity_action[0]}
                </td>
              </tr>
            ))}
          </>
        }
      />
    </>
  );
};

export default Action;
