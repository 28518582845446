import consumer from "./consumer"
document.addEventListener("DOMContentLoaded", function(e) {
  let sidebar = document.getElementById('show-sidebar')
  if (sidebar) {
    const user = sidebar.getAttribute('data-notification-user')
    consumer.subscriptions.create({ channel: "NotificationChannel", room: user }, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        if (Notification.permission == 'granted') {
          var title = 'Nueva notificación de ISWO'
          var body = data
          var options = { body: body }
          var notification = new Notification(title, options)
          notification.onclick = function() {
            window.location.href = '/pqrsfs';
          };
        }
      }
    })
  }
})