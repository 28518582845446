import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['check', 'workflow']

  connect () {
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()
    if ($(this.checkTarget).prop('checked')) {
      this.workflowTarget.value = 'awaiting_report'
      $('#pending').show('slow')
    } else {
      this.workflowTarget.value = 'awaiting_approve'
      $('#pending').hide('slow')
    }
  }
}
