import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['cell', 'input']
  cellSubmit () {
    const url =  this.data.get('url')
    const id = this.data.get('id')
    const date = this.data.get('date')
    const indicatorId = this.data.get('indicatorId')
    const value = $(this.inputTarget).val()
    $.get(url+'?value='+value+'&goal_id='+id+'&indicator_id='+indicatorId+'&date='+date).then((content) => {
      $(this.cellTarget).attr('data-measure-goal-url','/indicators/'+content.indicator_id+'/goal_measures/update_goal')
      $(this.cellTarget).attr('data-measure-goal-id', content.id)
      $(this.inputTarget).val(content.goal)
    })
  }
}
