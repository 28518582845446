import React from "react";
import { Table } from "./Table"
import { LaboratoriesContext } from "./LaboratoriesContext";
import { Approved } from "./Form/Approved";
import { EmptyRecords } from "../EmptyRecords";
import { LoadingSpinner } from "../LoadingSpinner";
import { Sampling } from "./Form/Sampling";
import { TestExecution } from "./Form/TestExecution";
import { ApprovalOfTest } from "./Form/ApprovalOfTest";
import { SendAndClose } from "./Form/SendAndClose";
import { EditReport } from "./Form/EditReport";

function AppUI(){
  const {
    openModalApproved,
    openModalSampling,
    openModalTestExecution,
    openModalApprovalOfTest,
    openModalSendAndClose,
    openModalEditReport,
    loading,
    error,
    laboratory,
    filterData
  } = React.useContext(LaboratoriesContext)
  const filterLaboratories = (type) =>{
    filterData(type)
  }
  return(
    <div className="card">
      <div className="card-header d-flex justify-content-between bg-dark text-white mb-0">
        <h5 className="card-title font-weight-bold ml-xs-4">
          Laboratorio
        </h5>
        <div className="d-flex justify-content-end py-md-12 py-lg-0 py-5">
          <div className="d-flex justify-content-between font-weight-bold">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-selected={true}
                  onClick={() => filterLaboratories('progress')}
                >
                  En proceso
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-selected={false}
                  onClick={() => filterLaboratories('closed')}
                >
                  Cerrados
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="card-body p-0">
        {loading && <LoadingSpinner/>}
        {error && <p>Se generó un error :o</p>}
        {!loading && laboratory.length <= 0 && <EmptyRecords/> }
        {!loading && laboratory.length > 0 &&  <Table/>}
      </div>
      {openModalApproved && <Approved/> }
      {openModalSampling && <Sampling/> }
      {openModalTestExecution && <TestExecution/> }
      {openModalApprovalOfTest && <ApprovalOfTest/>}
      {openModalSendAndClose && <SendAndClose/>}
      {openModalEditReport &&  <EditReport/>}
    </div>
  )
}

export default AppUI;