import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import axios from "axios";
import Home from "../components/Home";
import ShowTrouble from "../components/ShowTrouble";
import UpdateTroubles from "../components/UpdateTroubles";
import {
  FlashContext,
  useFlashState,
} from "../components/Context/FlashContext";
import { AppContext } from "../components/Context/AppContext";
import NewTrouble from "../components/NewTrouble";
import Investigation from "../components/Investigation";
import NewInvestigation from "../components/NewInvestigation";
import UpdateInvestigations from "../components/UpdateInvestigations";
import Action from "../components/Action";
import NewAction from "../components/NewAction";
import UpdateAction from "../components/UpdateAction";
import Follow from "../components/Follow";
import NewFollow from "../components/NewFollow";
import UpdateFollow from "../components/UpdateFollow";
import BusinessAnalytics from "../components/BusinessAnalytics";
import { FlashAlerts } from "../components/Flash/FlashAlerts";
import "../components/Css/index.css";

function Troubles() {
  const [loading, setLoading] = useState(true);
  const flashState = useFlashState();

  const valuesState = {
    loading,
    setLoading,
  };

  return (
    <FlashContext.Provider value={flashState}>
      <AppContext.Provider value={valuesState}>
        <FlashAlerts />
        <Router>
          <Routes>
            <Route
              path="/troubles/"
              element={<Navigate to={"/troubles/accident"} />}
            />

            <Route path="/troubles/:troubleType" element={<Home />} />

            <Route
              path="/troubles/:troubleType/show/:id"
              element={<ShowTrouble />}
            />
            <Route
              path="/troubles/:troubleType/update/:id"
              element={<UpdateTroubles />}
            />
            <Route path="/troubles/:troubleType/new" element={<NewTrouble />} />
            <Route
              path="/troubles/:troubleId/investigations"
              element={<Investigation />}
            />
            <Route
              path="/troubles/:troubleId/investigations/new"
              element={<NewInvestigation />}
            />
            <Route
              path="/troubles/:troubleId/investigations/:id"
              element={<UpdateInvestigations />}
            />
            <Route
              path="/troubles/:troubleId/investigation/:investigationId/actions"
              element={<Action />}
            />
            <Route
              path="/troubles/:troubleId/investigation/:investigationId/actions/new"
              element={<NewAction />}
            />
            <Route
              path="/troubles/:troubleId/investigation/:investigationId/actions/update/:id"
              element={<UpdateAction />}
            />
            <Route
              path="/troubles/:troubleId/investigation/:investigationId/action/:actionId/follows"
              element={<Follow />}
            />
            <Route
              path="/troubles/:troubleId/investigation/:investigationId/action/:actionId/follows/new"
              element={<NewFollow />}
            />
            <Route
              path="/troubles/:troubleId/investigation/:investigationId/action/:actionId/follows/update/:id"
              element={<UpdateFollow />}
            />
            <Route
              path="/troubles/business_analytics"
              element={<BusinessAnalytics />}
            />
          </Routes>
        </Router>
      </AppContext.Provider>
    </FlashContext.Provider>
  );
}

export default Troubles;
