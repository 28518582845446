import React, { useEffect, useState } from "react";
import { GetEnvResources } from "./Requests";
import ResourceInput from "./ResourceInput";

const Resources = ({
  envImpact,
  setEnvImpact,
  indexActivity,
  indexImpact,
  indexAspect,
  impactsAux,
  setImpactsAux
}) => {
  const [environmentalResources, setEnvironmentalResources] = useState([]);
  const [counterResources, setCounterResources] = useState(0);
  const [resourcesAux, setResourcesAux] = useState([]);
  const [resources, setResources] = useState([]);

  useEffect(() => {
    GetEnvResources().then((data) => {
      setEnvironmentalResources(data);
    });
  }, []);

  useEffect(() => {
    if (environmentalResources.length > 0) {
      const newResource = {
        indexResource: counterResources,
        resource_id: environmentalResources[0].id,
      };
      setResourcesAux([newResource]);
    }
  }, [environmentalResources]);

  const addInput = () => {
    const localCounter = counterResources + 1;
    const newResource = {
      indexResource: localCounter,
      resource_id: environmentalResources[0].id,
      resources: {},
    };
    setCounterResources(counterResources + 1);
    setResourcesAux([...resourcesAux, newResource]);
  };

  const deleteInput = (e) => {
    const updatedResources = resourcesAux.filter(
      (resource) => resource.indexResource !== e
    );
    const reindexedResources = updatedResources.map((resource, index) => ({
      ...resource,
      indexResource: index,
    }));
    setResourcesAux(reindexedResources);
  };

  const handleSelect = (id, e) => {
    const { value } = e.target;
    setResourcesAux((prevResourcesAux) =>
      prevResourcesAux.map((item) =>
        item.indexResource === id ? { ...item, resource_id: value } : item
      )
    );
  };

  useEffect(() => {
    const formattedResources = resourcesAux.reduce((acc, resource, index) => {
      acc[index] = {
        id: null,
        environmental_resource_id: resource.resource_id,
        _destroy: false,
      };
      return acc;
    }, {});
    setResources(formattedResources);
  }, [resourcesAux]);

  useEffect(() => {
    const updatedImpact = { ...envImpact };
    const updatedImpacts = [ ...impactsAux ]
    updatedImpact.environmental_impact.env_impact_activities_attributes[
      indexActivity
    ].env_impact_aspects_attributes[
      indexAspect
    ].env_impact_evaluations_attributes[
      indexImpact
    ].resources_attributes = resources;
    updatedImpacts[indexImpact].resources = resources
    setImpactsAux(updatedImpacts)
    setEnvImpact(updatedImpact);
  }, [resources]);

  return (
    <div className="card-body">
      <div className="form-row">
        <div className="col-md-12">
          <div className="form-group mb-3">
            {resourcesAux.map((input) => (
              <ResourceInput
                key={input.indexResource}
                resources={environmentalResources}
                deleteInput={deleteInput}
                idInput={input.indexResource}
                idResource={input.resource_id}
                handleSelect={handleSelect}
              />
            ))}
            <button
              type="button"
              className="btn btn-info btn-sm mb-2"
              onClick={addInput}
            >
              <i className="fas fa-plus mr-1"></i>
              Recurso afectado
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
