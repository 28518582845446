import { Controller } from 'stimulus'
import Chart from 'chart.js'

export default class extends Controller {
  connect () {
    let { points } = this.element.dataset

    points = JSON.parse(points)

    const labels = points.map(({ chapter }) => chapter)
    const data = points.map(({ value }) => value)
    const colors = points.map(({ color }) => color)
    
    this.ctx = this.element.getContext('2d')
  
    this.options = {
      type: 'line',
      data: data,
      responsive: true,
      maintainAspectRatio: false,
      data: {
        
        labels,
        datasets: [
          {
            backgroundColor: '#DEF2FF',
            pointBorderColor: '#000E96',
            pointBackgroundColor: '#009688',
            borderColor: '#009688',
            label: "Respuesta",
            
            pointStyle: 'rectRounded',
            data: data,
            
          },
        ]
      },
      
    }
    this.chart = new Chart(this.ctx, this.options)
  }
}
