import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['exams', 'employees', 'actions']

  connect () {
    this.showExams()
  }

  setAll () {
    $(this.examsTarget).prop('className', 'header_button')
    $(this.employeesTarget).prop('className', 'header_button')
    $(this.actionsTarget).prop('className', 'header_button')
    $('#exams').hide()
    $('#employees').hide()
    $('#actions').hide()
  }

  showExams () {
    this.setAll()
    $(this.examsTarget).prop('className', 'header_button active_button')
    $('#exams').show()
  }

  showEmployees () {
    this.setAll()
    $(this.employeesTarget).prop('className', 'header_button active_button')
    $('#employees').show()
  }

  showActions () {
    this.setAll()
    $(this.actionsTarget).prop('className', 'header_button active_button')
    $('#actions').show()
  }
}