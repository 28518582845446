import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.fetchNotificationCounts();
    this.element.addEventListener('click', () => {
      this.fetchNotificationCounts();
    });
  }

  fetchNotificationCounts() {
    fetch('/action_summaries/count_total_actions')
      .then(response => response.json())
      .then(data => {
        this.updateNotificationCount(data.total_count);
      })
      .catch(error => console.error('Error fetching counts:', error));
  }

  updateNotificationCount(totalCount) {
    const notificationElement = document.getElementById('notification-count');
    if (notificationElement) {
      notificationElement.textContent = totalCount.toString();
    }
  }
}