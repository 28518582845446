import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['department', 'storageLocation', 'position', 'destroy']

  connect () {
    this.changeDepartment()
    $(this.storageLocationTarget).val(this.data.get('selected'))
  }

  onChangeDepartment (e) {
    e.preventDefault()
    this.changeDepartment()
  }

  onChangeStorageLocation (e) {
    e.preventDefault()
    this.changeStorageLocation()
  }

  onClickDelete (e) {
    e.preventDefault()
    this.destroyTarget.value = '1'
    $(this.element).fadeOut()
  }

  changeStorageLocation () {
    const { position } = $(this.storageLocationTarget).find(':selected').data()
    this.positionTarget.value = position
  }

  changeDepartment () {
    const { storageLocations } = $(this.departmentTarget).find(':selected').data()
    const [first] = storageLocations

    this.positionTarget.value = first.position.name
    this.storageLocationTarget.innerHTML = null

    storageLocations.forEach(sl => {
      const option = document.createElement('option')
      option.setAttribute('value', sl.id)
      option.setAttribute('data-position', sl.position.name)
      option.innerHTML = sl.name
      $(this.storageLocationTarget).append(option)
    })
  }
}
