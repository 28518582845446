import { Controller }  from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  connect() {
    this.onChange()
  }

  onChange(e) {
    var texto = document.getElementById("read_field");
    var check = document.getElementById("concerned_part_see_all");
    if (check.checked == true) { texto.style.display = 'none' } 
    else { texto.style.display = ''}
  }
}
