import React, { useCallback, useEffect, useRef, useState } from "react";
import { DirectUpload } from "@rails/activestorage";
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";

function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

function Preview({ name, size, date, progress, onRemove, value }) {
  return (
    <div style={{ cursor: "pointer", pointerEvents: "none" }}>
      <div className="w-100">
        <div className="row">
          <div className="col-10">
            {name} <strong>{humanFileSize(size, true)}</strong> {date}
          </div>
          {onRemove && (
            <div
              className="col-2"
              style={{ cursor: "pointer", pointerEvents: "all" }}
            >
              <button
                className="btn btn-link"
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove();
                }}
              >
                <i className="fas fa-times" />
              </button>
            </div>
          )}
        </div>
        {progress && (
          <div className="row">
            <div className="col-12 mt-2">
              <div className="progress">
                <div
                  className="progress-bar bg-success"
                  style={{ width: `${progress}%` }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default function Uploader({ onChange, value, id, url }) {
  const [progress, setProgress] = useState(0);
  const [tempFile, setTempFile] = useState();

  const directUploadWillStoreFileWithXHR = useCallback((request) => {
    request.upload.addEventListener("progress", (event) => {
      setProgress((event.loaded * 100) / event.total);
    });
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const [file] = acceptedFiles;
      setProgress(0);
      setTempFile(file);
      uploadFile(file, "/as/direct_uploads");
    },
    maxFiles: 1,
  });

  const uploadFile = useCallback((file, url) => {
    const upload = new DirectUpload(file, url, {
      directUploadWillStoreFileWithXHR,
    });

    upload.create((error, blob) => {
      if (error) {
        console.error(error);
        return;
      }
      setTempFile(file);
      onChange(blob.signed_id);
    });
  });

  return (
    <>
      <div
        {...getRootProps({ className: "dropzone-container" })}
        style={{
          cursor: "pointer",
          pointerEvents: "all",
        }}
      >
        <input {...getInputProps()} />
        <span style={{ cursor: "pointer", pointerEvents: "all" }}>
          {progress < 100 && tempFile && <strong>{tempFile.name}</strong>}
          {progress < 100 &&
            !tempFile &&
            "Suelte el archivo o haga click para subir"}
        </span>
        {tempFile && (
          <Preview
            name={tempFile.name}
            size={tempFile.size}
            progress={progress}
            onRemove={() => setTempFile(null)}
          />
        )}
      </div>
      {url && (
        <div className="justify-content-center">
          {value && (
            <Preview name={value.name} size={value.size} date={value.date} />
          ) ? (
            <Link to={url} target="_blank">
              <Preview name={value.name} size={value.size} date={value.date} />
            </Link>
          ) : null}
        </div>
      )}
    </>
  );
}
