import React from "react";
import { LaboratoriesProvider } from "./LaboratoriesContext";
import AppUI  from "./AppUI";

function Laboratories() {
  return(
    <LaboratoriesProvider>
      <AppUI/>
    </LaboratoriesProvider>
  )
}

export default Laboratories