import { Controller } from 'stimulus'
import Chart from 'chart.js'
import tinycolor from 'tinycolor2'
import uniq from 'lodash.uniq'
import get from 'lodash.get'
import indicatorLevelResolver from '../shared/indicatorLevelResolver'

export default class extends Controller {
  connect () {
    let { metrics, trendDirection, semaphores } = this.element.dataset

    metrics = JSON.parse(metrics)
    semaphores = JSON.parse(semaphores)

    const labels = metrics.map(({ date }) => date)
    const data = metrics.map(({ percentage }) => Number(Number(percentage).toFixed(2)))
    const average = (data.reduce((acc, curr) => (acc + curr), 0) / data.length).toFixed(2)
    const colors = metrics.map(({ color }) => color)
    const achievements = metrics.map(({ achievement }) => achievement)
    const indicatorLevel = indicatorLevelResolver(semaphores, average)

    this.ctx = this.element.getContext('2d')
    this.options = {
      type: 'line',
      data: {
        labels,
        datasets: [
          {
            label: 'Actual',
            pointBorderWidth: 5,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: false,
            borderWidth: 2,
            data,
            pointBackgroundColor: colors,
            pointBorderColor: colors,
            borderColor: colors
          }
        ]
      },
      options: {
        tooltips: {
          titleFontFamily: 'Source Sans Pro',
          titleFontSize: 16,
          bodyFontSize: 16,
          xPadding: 10,
          yPadding: 10,
          callbacks: {
            label: (tooltipItem, ___data) => {
              const achievement = achievements[tooltipItem.index]
              return ` ${achievement} ${tooltipItem.value}`
            }
          }
        },
        animation: {
          easing: 'easeInOutBack'
        },
        legend: {
          display: true,
          position: 'top'
        },
        scales: {
          yAxes: [{
            ticks: {
              fontColor: 'rgba(0,0,0,0.5)',
              fontStyle: 'bold',
              beginAtZero: true,
              maxTicksLimit: 5,
              padding: 20
            },
            gridLines: {
              drawTicks: false,
              display: false
            }
          }],
          xAxes: [{
            gridLines: {
              zeroLineColor: 'transparent'
            },
            ticks: {
              padding: 20,
              fontColor: 'rgba(0,0,0,0.5)',
              fontStyle: 'bold'
            }
          }]
        }
      },
      plugins: [
        {
          id: 'responsiveGradient',
          afterLayout: (chart, __options) => {
            const gradientStroke = this.ctx.createLinearGradient(
              this.ctx.canvas.width / 2,
              this.ctx.canvas.height,
              this.ctx.canvas.width / 2, 0
            )

            // const gradientFill = this.ctx.createLinearGradient(
            //   this.ctx.canvas.width / 2,
            //   this.ctx.canvas.height,
            //   this.ctx.canvas.width / 2, 0
            // )

            const sortedData = uniq([...data].sort((a, b) => a - b))

            const semaphoreIndex = data.indexOf(sortedData[0])

            gradientStroke.addColorStop(0, tinycolor(colors[semaphoreIndex]).setAlpha(0.5))
            // gradientFill.addColorStop(0, tinycolor(colors[semaphoreIndex]))

            sortedData.forEach((value) => {
              const colorIndex = data.indexOf(value)
              const color = colors[colorIndex]

              const stop = trendDirection === 'increase' ? Math.min(value, 100) / 100 : Math.max(value, 0) / 100

              if (stop >= 0.0 && stop <= 1.0) {
                gradientStroke.addColorStop(stop, tinycolor(color).setAlpha(0.5))
              // gradientFill.addColorStop(stop, tinycolor(color).setAlpha(0.5))
              } else {
                console.error(`Invalid stop ${stop} for ${trendDirection} ${value}`)
              }
            })

            chart.data.datasets[0].borderColor = gradientStroke
            // chart.data.datasets[0].backgroundColor = gradientFill
          }
        }
      ]
    }

    this.chart = new Chart(this.ctx, this.options)
  }

  changeType = (type) => {
    this.options.type = type
    //this.options.data.datasets[1].type = ['line', 'bar'].includes(type) ? 'line' : undefined

    this.chart.destroy()
    this.chart = new Chart(this.ctx, this.options)
  }
}
