import React, { useState, useEffect } from 'react'
import Chart from './Chart'
const CardChart = (props) => {
  const { text, value, color, modalControl, group, groupid, date, initialdate, finaldate } = props
  const [link, setLink] = useState(false)
  const setModal = () => {
    if (group) {
      modalControl(true, '/api/v2/metrics/group_by?group='+group+'&group_id='+groupid+'&date='+date+'&initialdate='+initialdate+'&finaldate='+finaldate, text)
    }
  }

  useEffect(() => {
    if ((group === undefined) && (groupid) ) { setLink(true) }
  }, [group])

  const LinkButton = ({indicatorid, text}) => {
    return ( <>
      <a href={`/indicators/${indicatorid}/measures`} target='_blank'>{text}</a>
    </>)
  }

  return (
    <li className='list-group-item list-group-item-action d-flex justify-content-between align-items-center cursor-pointer' onClick={()=>setModal()}>
      {link ? <LinkButton indicatorid={groupid} text={text}/> : <div className='w-50'>{text}</div> }
      <div className='text-center d-flex justify-content-between no-pointer align-items-center'>
        <Chart
          progress={value}
          size={60}
          fontSize={'12px'}
          strokeWidth={4}
          circleTwoStroke={color}
        />
      </div>
    </li>
  )
}

export default CardChart