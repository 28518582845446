import React, { useContext, useEffect, useState } from "react";
import Modal from "./components/Modal";
import EditEventModal from "./components/EditEvenModal";
import CalendarHeader from "./components/CalendarHeader";
import DeleteButton from "./components/DeleteButton";
import Sidebar from "./components/Sidebar";
import Time from "./components/Time";
import GlobalContext from "./context/GlobalContext";
import "bootstrap/dist/css/bootstrap.min.css";

function CalendarView({ id, csrf }) {
  const {
    showModal,
    setCurrentUserId,
    setToken,
    showEditModal,
    showDeleteModal,
  } = useContext(GlobalContext);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Cambia el valor 768 según tus necesidades
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setCurrentUserId(id);
    setToken(csrf);
  }, [id, setCurrentUserId]);

  return (
    <React.Fragment>
      <div className="h-100 d-flex flex-column">
        <div className="row">
          <CalendarHeader />
        </div>
        <div className="d-flex flex-grow-1 row">
          {!isMobile && <Sidebar />}
          <Time />
        </div>
      </div>
      {showModal && <Modal />}
      {showEditModal && <EditEventModal />}
      {showDeleteModal && <DeleteButton />}
    </React.Fragment>
  );
}

export default CalendarView;
