import React from "react";
import { Document, Page, View, Text, StyleSheet } from "@react-pdf/renderer";

const PdfReact = (props) => {
  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      padding: "1cm",
    },
    title: {
      fontSize: 18,
      marginBottom: "1cm",
    },
    table: {
      marginTop: "1cm",
      border: "1pt solid #000",
      paddingBottom: "20px",
      marginHorizontal: "10px",
    },
    tableHeader: {
      flexDirection: "row",
      borderBottom: "1pt solid #000",
      backgroundColor: "#f0f0f0",
      padding: "6pt",
    },
    tableRow: {
      flexDirection: "row",
      borderBottom: "1pt solid #000",
      padding: "6pt",
    },
    tableCell: {
      width: "50%",
      textAlign: "center",
    },
    section: {
      display: "flex",
      flexDirection: "column",
      gap: "40px",
    },
  });

  return (
    <Document>
      <Page size={"A4"}>
        <View style={styles.section}>
          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <Text style={styles.tableCell}>Tipo Reporte</Text>
              <Text style={styles.tableCell}>Usuario</Text>
              <Text style={styles.tableCell}>Jefe</Text>
            </View>

            <View style={styles.tableRow}>
              <Text style={styles.tableCell}>
                {props && props.trouble_type ? props.trouble_type : ""}
              </Text>
              <Text style={styles.tableCell}>
                {props.employee.user && props.employee.user.first_name
                  ? props.employee.user.first_name
                  : ""}{" "}
                {props.employee.user && props.employee.user.last_name
                  ? props.employee.user.last_name
                  : ""}
              </Text>
              <Text style={styles.tableCell}>
                {props.inmediate_boss && props.inmediate_boss.name
                  ? props.inmediate_boss.name
                  : ""}
              </Text>
            </View>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <Text style={styles.tableCell}>Fecha Evento</Text>
              <Text style={styles.tableCell}>Fecha Reporte</Text>
              <Text style={styles.tableCell}>Sede</Text>
            </View>

            <View style={styles.tableRow}>
              <Text style={styles.tableCell}>
                {props && props.date_event ? props.date_event : ""}
              </Text>
              <Text style={styles.tableCell}>
                {props && props.date_report ? props.date_report : ""}
              </Text>
              <Text style={styles.tableCell}>
                {props.branch_office && props.branch_office.name
                  ? props.branch_office.name
                  : ""}
              </Text>
            </View>
          </View>

          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <Text style={styles.tableCell}>Descripción de Acciones</Text>
              <Text style={styles.tableCell}>Descripción</Text>
            </View>

            <View style={styles.tableRow}>
              <Text style={styles.tableCell}>
                {props && props.description_actions
                  ? props.description_actions
                  : ""}
              </Text>
              <Text style={styles.tableCell}>
                {props && props.description ? props.description : ""}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfReact;
