import React, { useContext, useState, useEffect } from "react";
import {
  Link,
  useSearchParams,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import axios from "axios";

import {
  Button,
  Nav,
  Tooltip,
  OverlayTrigger,
  Badge,
  Card,
  Container,
} from "react-bootstrap";
import {
  faChartLine,
  faUser,
  faTrash,
  faFilePdf,
  faPenToSquare,
  faMagnifyingGlass,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "./Header";
import Table from "./Table";
import InputsFilter from "./InputsFilter";
import LoaderTable from "./Skeleton/LoaderTable";
import { AppContext } from "./Context/AppContext";
import { FLASH_TYPE_ERROR, FlashContext } from "./Context/FlashContext";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfReact from "./Pdf/PdfReact";
import ExcelReact from "./Excel/ExcelReact";

const Home = () => {
  const [troubles, setTroubles] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [infoPageTroubles, setInfoPageTroubles] = useState({});
  const { troubleType } = useParams();
  const [inputFilter, setInputFilter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { setLoading } = useContext(AppContext);
  const [searchParams] = useSearchParams();
  const { setFlash } = useContext(FlashContext);
  const navigate = useNavigate();

  const deleteTrouble = async (id) => {
    try {
      const url = `/api/v2/troubles/destroy/${id}`;
      const response = await axios.delete(url);
      if (response.status === 204) {
        setFlash({
          type: FLASH_TYPE_ERROR,
          message: "Elemento Eliminado",
        });
        fetchTroubles();
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      setFlash({
        type: FLASH_TYPE_ERROR,
        message: "Ocurrio un error en el servidor",
      });
      console.error("Error", error);
    }
  };

  const handleDownloadPdf = () => {
    if (!isDownloading) {
      setIsDownloading(true);

      setTimeout(() => {
        setIsDownloading(false);
      }, 1000);
    }
  };

  const location = useLocation();
  const fetchTroubles = async (page) => {
    setLoading(true);

    try {
      const url = `/api/v2/troubles`;
      const searchParams = new URLSearchParams(location.search);

      if (inputFilter) {
        searchParams.set("branch_office", inputFilter);
      }

      if (startDate) {
        searchParams.set("start_date", startDate);
      }

      if (endDate) {
        searchParams.set("end_date", endDate);
      }

      if (page) {
        searchParams.set("page", page || 1);
      }
      searchParams.set("trouble_type", troubleType);

      const response = await axios.get(`${url}?${searchParams.toString()}`);
      if (response.status === 200) {
        const data = await response.data;

        setTroubles(data.troubles);
        setInfoPageTroubles(data.total_pages);
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      setFlash({
        type: FLASH_TYPE_ERROR,
        message: "Ocurrio un error en el servidor",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTroubles(searchParams.get("page") || 1);
  }, [troubleType, searchParams]);

  const handleSearch = () => {
    fetchTroubles();
  };

  const handleFilterChange = (event) => {
    const filterValue = event.target.getAttribute("data-filter-type");
    navigate(`/troubles/${filterValue}`);
  };

  return (
    <>
      <Header
        title={
          "Registro e investigación de accidentes, incidentes y emergencias ambientales"
        }
        buttonBusiness={
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>Business Analytics</Tooltip>}
          >
            <Link to={"/troubles/business_analytics"}>
              <Button variant="warning">
                <FontAwesomeIcon icon={faChartLine} />
              </Button>
            </Link>
          </OverlayTrigger>
        }
        buttonNewElement={
          <Link to={`/troubles/${troubleType}/new`}>
            <Button>Nuevo</Button>
          </Link>
        }
      >
        <InputsFilter
          handleSearch={handleSearch}
          inputFilter={inputFilter}
          startDate={startDate}
          endDate={endDate}
          setInputFilter={setInputFilter}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </Header>
      <Card>
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey={troubleType}>
            <Nav.Item>
              <Nav.Link
                onClick={(e) => handleFilterChange(e)}
                data-filter-type="accident"
                eventKey="accident"
              >
                Accidentes
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                onClick={(e) => handleFilterChange(e)}
                data-filter-type="incident"
                eventKey="incident"
              >
                Incidentes
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                onClick={(e) => handleFilterChange(e)}
                data-filter-type="emergency"
                eventKey="emergency"
              >
                Emergencias
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
      </Card>
      <Table
        numPages={infoPageTroubles}
        items={troubles.length}
        heads={
          <tr>
            <th></th>
            <th className="text-center">Descripción</th>
            <th className="text-center">Sede</th>
            <th className="text-center">Reportó</th>
            <th className="text-center">Fecha Evento</th>
            <th className="text-center">Fecha Reporte</th>
          </tr>
        }
        loaderSize={
          <>
            {Array.from({ length: 3 }).map((_, index) => (
              <tr key={index}>
                <td style={{ minWidth: "370px" }}>
                  <LoaderTable />
                </td>
                <td style={{ maxWidth: "180px" }}>
                  <LoaderTable />
                </td>
                <td style={{ maxWidth: "117px" }}>
                  <LoaderTable />
                </td>
                <td
                  style={{
                    maxWidth: "85px",
                  }}
                >
                  <LoaderTable />
                </td>
                <td style={{ maxWidth: "130px" }}>
                  <LoaderTable />
                </td>
                <td style={{ maxWidth: "130px" }}>
                  <LoaderTable />
                </td>
              </tr>
            ))}
          </>
        }
        data={
          <>
            {troubles.map((item) => (
              <tr key={item.id}>
                <td style={{ width: "323px" }}>
                  <Container className="d-flex">
                    <Link to={`/troubles/${troubleType}/update/${item.id}`}>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Editar</Tooltip>}
                      >
                        <Button
                          variant="outline-success"
                          className="m-2"
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </Button>
                      </OverlayTrigger>
                    </Link>
                    <Link
                      to={`/troubles/${item.id}/investigations?troubleType=${troubleType}`}
                    >
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Investigar</Tooltip>}
                      >
                        <Button
                          variant="outline-primary"
                          className="m-2"
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </Button>
                      </OverlayTrigger>
                    </Link>
                    <Link to={`/troubles/${troubleType}/show/${item.id}`}>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Ver a detalle</Tooltip>}
                      >
                        <Button
                          variant="outline-primary"
                          className="m-2"
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </Button>
                      </OverlayTrigger>
                    </Link>
                    <ExcelReact id={item.id} />{" "}
                    <PDFDownloadLink
                      document={<PdfReact {...item} />}
                      fileName="ejemplo.pdf"
                    >
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Descargar Pdf</Tooltip>}
                      >
                        <Button
                          variant="outline-danger"
                          className="m-2"
                          onClick={handleDownloadPdf}
                          disabled={isDownloading}
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faFilePdf} />
                        </Button>
                      </OverlayTrigger>
                    </PDFDownloadLink>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Eliminar</Tooltip>}
                    >
                      <Button
                        variant="outline-danger"
                        className="m-2"
                        onClick={() => deleteTrouble(item.id)}
                        size="sm"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </OverlayTrigger>
                  </Container>
                </td>

                <td
                  style={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item && item.description}
                </td>
                <td style={{ textAlign: "center" }}>
                  {item.branch_office && item.branch_office.name}
                </td>
                <td>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        {item.employee.user && item.employee.user.first_name}{" "}
                        {item.employee.user && item.employee.user.last_name}
                      </Tooltip>
                    }
                  >
                    <div className="d-flex justify-content-center">
                      <FontAwesomeIcon icon={faUser} color="#007BFF" />
                    </div>
                  </OverlayTrigger>
                </td>

                <td style={{ textAlign: "center" }}>
                  {item && item.date_event}
                </td>
                <td style={{ textAlign: "center" }}>
                  {item && item.date_report}
                </td>
              </tr>
            ))}
          </>
        }
      />
    </>
  );
};

export default Home;
