import React from "react";

function DataTest({
  dataLaboratory,
  testExecution
}

){
  return(
    <React.Fragment>
      <div className="p-3 bg-light border rounded mb-3">
        <p><strong>Nombre:</strong> {dataLaboratory.type_laboratory_test.name}</p>
        <p><strong>Objetivo:</strong> {dataLaboratory.type_laboratory_test.objective}</p>
        <p><strong>Definiciones:</strong></p>
        <ul>
          {dataLaboratory.type_laboratory_test.definition_laboratories.map((definition, index) => (
            <li key={index}><strong>{index+1}- Descripción: </strong>{definition.description}</li>
          ))}
        </ul>
        <p><strong>Personal Cualificado Necesario:</strong> {dataLaboratory.type_laboratory_test.qualified_personnel_required}</p>
        <p><strong>Norma regional, nacional o internacional en la que se basa:</strong> {dataLaboratory.type_laboratory_test.standard}</p>
        <p><strong>Parámetros por determinar :</strong></p>
        <ul>
          {dataLaboratory.type_laboratory_test.determine_laboratory_params.map((definition, index) => (
            <li key={index}> <strong>{index+1}- Nombre: </strong>{definition.name}  <strong>Descripción: </strong>{definition.description}</li>
          ))}
        </ul>
        <p><strong>Criterios de aceptación:</strong> {dataLaboratory.type_laboratory_test.acceptance_criteria}</p>
      </div>
      <h5>Resultados prueba {dataLaboratory.type_laboratory_test.name}</h5>
      <table className="table table-bordered">
        <thead className="bg-danger text-white">
          <tr>
            <th>No. Unidades</th>
            <th>Parámetros de Prueba</th>
            <th>Criterio de Aceptación</th>
            <th>Incertidumbre</th>
            <th>Declaración de Conformidad</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {testExecution.number_unit}
            </td>
            <td>
              {testExecution.acceptance_criteria}
            </td>
            <td>
              {testExecution.uncertainty}
            </td>
            <td>
              {testExecution.result}
            </td>
            <td>
              {testExecution.declaration_conformity === true ? (
                <p>Aprobado</p>
              ) : (<p>No Aprobado</p>)}
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  )
}

export { DataTest }