import { Controller }  from 'stimulus'

export default class extends Controller {

  connect() {
    this.onChange()
  }

  onChange(e) {
    if (e) e.preventDefault();
    const url =  this.data.get('url')
    const elementId = this.data.get('target')
    const selected = this.data.get('selected')
    $.get(url+'?area_id='+this.element.value+'&selected_id='+selected).then((content) => {
      document.getElementById(elementId).innerHTML = content
    })
    var selectElement = document.getElementById('maintenance_plan_assignments_attributes_0_user_id');
    if (selectElement) {
      var filePath = 'maintenance_plans/admin_area?area_id='+this.element.value;
      fetch(filePath)
          .then(response => response.text())
          .then(data => {
            selectElement.value = data;

            // Si estás utilizando Select2, es necesario actualizar manualmente la interfaz de usuario
            if ($(selectElement).hasClass('select2-hidden-accessible')) {
              $(selectElement).trigger('change'); // Trigger 'change' para actualizar la UI de Select2
            }
          })
          .catch(error => {
            console.error('Error al cargar el archivo:', error);
          });
    }
  }
}
