import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['spinner', 'buttondraft', 'buttonsubmit', 'buttonreview', 'buttonapprove', 'buttonreturn']

  connect () {
    $(this.spinnerTarget).hide()
  }

  submit () {
    if (this.hasButtondraftTarget) { $(this.buttondraftTarget).hide() }
    if (this.hasButtonsubmitTarget) { $(this.buttonsubmitTarget).hide() }
    if (this.hasButtonreviewTarget) { $(this.buttonreviewTarget).hide() }
    if (this.hasButtonreturnTarget) { $(this.buttonreturnTarget).hide() }
    if (this.hasButtonapproveTarget) { $(this.buttonapproveTarget).hide() }
    $(this.spinnerTarget).show()
  }
}
