import React, { useContext } from "react";
import { InputGroup } from "../../InputGroup";
import { useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import ReCAPTCHA from 'react-google-recaptcha';

function New({portal, color}){
  const [errorResponse, setErrorResponse] = useState([])
  const [typeLaboratories, setTypeLaboratories] = useState([])
  const [date, setDate] = useState(new Date())
  const [nameCompanyName, setNameCompanyName] = useState('')
  const [indentification, setIndentification] = useState('')
  const [address, setAdress] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [applications, setApplications] = useState([])
  const [numberlots, setNumberLots] = useState('')
  const [unitslot, setUnitsLot] = useState('')
  const [descriptionApplication, setDescriptionApplication] = useState('')
  const [descriptionSampleCollection, setDescriptionSampleCollection] = useState('')
  const [contactPerson, setContactPerson] = useState('')
  const [disabledSubmit, setDisabledSubmit] = useState(false)
  const [recaptchaValue, setRecaptchaValue] = useState(null)
  useEffect(() =>{
    const getData = async () =>{
      try{
        const url = `/api/v2/laboratories/gettype_laboratory_test.json?portal_id=${portal.id}`;
        const response = await axios.get(url, {
          headers: {
            'Accept': 'application/json'
          }
        });
        if (response.status === 200){
          const data = await response.data
          setTypeLaboratories(data)
        }
      }catch (error){
          setError(true)
      }
    }
    getData()
    return() =>{
      setError(null)
    }
  }, [])

  const checkboxHandler = (e) => {
    let isSelected = e.target.checked
    let value = e.target.value

    if( isSelected ){
      setApplications([...applications, value])
    }else{
      setApplications((prevData) =>{
        return prevData.filter((id) =>{
          return id!==value
        })
      })
    }
  }

  const createLaboratory = async (newLaboratory) => {
    try {
      const response = await axios.post("/api/v2/laboratories", newLaboratory)
      window.location.href = `/main_laboratories_success?laboratory_id=${response.data.id}&portal=true&layout=pqrsf`;
      setErrorResponse([])
    } catch (error){
      console.error("Error al crear la prueba de laboratorio:", error.response.data.errors);
      setErrorResponse(error.response.data.errors)
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setDisabledSubmit(true);
    const newLaboratory = {
      date: date,
      name_company_name: nameCompanyName,
      indentification: indentification,
      address: address,
      phone: phone,
      email: email,
      number_lots: numberlots,
      units_lot: unitslot,
      portal_id: portal.id,
      description_application: descriptionApplication,
      description_sample_collection: descriptionSampleCollection,
      contact_person: contactPerson,
      request_tests_attributes: applications.map((test) => {
        return{
          type_laboratory_test_id: test,
          portal_id: portal.id,
          _destroy: false
        }
      }),
      recaptchaValue: recaptchaValue
    }
    setTimeout(() => {
      setDisabledSubmit(false);
    }, 4000);
    createLaboratory(newLaboratory)
  }

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value); // Guarda el valor del token generado por reCAPTCHA
  };

  return(
    <div className="card card border-info px-2 py-2">
      <form onSubmit={onSubmit}>
        <div className="card-header bg-white">
          <a
            className="btn btn-secondary.btn-sm floating-top-left rounded-circle"
            href="/main_laboratories?portal_id=6011faf0-0604-40c6-a347-c2aead8e50ce&layout=pqrsf&color=true"
          >
            <i className="fas fa-arrow-left"/>
          </a>
          <div className="d-flex justify-content-center">
            <div className="max-450px">
              <h3 className="text-wrap font-weight-bold text-dark">
                {portal.short_name}
              </h3>
            </div>
          </div>
          <div className="h4 text-center font-weight-bold text-dark ml-3">
            Laboratorio
          </div>
        </div>
        {errorResponse.length > 0 && (
          <div className="alert alert-danger" role="alert">
            <ul>
              {errorResponse.map((error, index) => (
                <li key={index} style={{ color: 'black' }}>
                  {error}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="card border-info shadow-sm mb-4 rounded">
          <div className={`card-header ${color? '' : 'bg-dark'}`} style={{backgroundColor: color}}>
            <h5 className="font-weight-bold text-white">
              INFORMACIÓN SOLICITANTE
            </h5>
          </div>
          <div className="card-body">
            <div className="form-row mt-2">
              <div className="col-md-4">
                <InputGroup iconClass="fas fa-map-pin" label="Dirección (*)">
                  <input
                    type="text"
                    className="form-control"
                    name="Dirección"
                    placeholder="Dirección"
                    onChange={(e) => setAdress(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="col-md-4">
                <InputGroup iconClass="fas fa-user" label="Nombre o Razón Social (*)">
                  <input
                    type="text"
                    className="form-control"
                    name="Nombre o Razón Social"
                    placeholder="Nombre o Razón Social"
                    onChange={(e) => setNameCompanyName(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="col-md-4">
                <InputGroup iconClass="fas fa-id-card" label="Cédula o RUC (*)">
                  <input
                    type="text"
                    className="form-control"
                    name="Cédula o RUC"
                    placeholder="Cédula o RUC"
                    onChange={(e) => setIndentification(e.target.value)}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="form-row mt-2">
              <div className="col-md-4">
                <InputGroup iconClass="fas fa-mobile-alt" label="Teléfono de Contacto (*)">
                  <PhoneInput
                    value={phone}
                    defaultCountry="CO"
                    onChange={(phone) => setPhone(phone)}
                    className="form-control"
                  />
                </InputGroup>
              </div>
              <div className="col-md-4">
                <InputGroup iconClass="fas fa-envelope" label="Correo Electrónico (*)">
                  <input
                    type="email"
                    className="form-control"
                    name="Correo Electrónico"
                    placeholder="Correo Electrónico"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="col-md-4">
                <InputGroup iconClass="fas fa-calendar" label="Fecha (*)">
                  <DatePicker
                    selected={date}
                    onChange={(date) => setDate(date)}
                    showMonthDropdown
                    className="form-control"
                  />
                </InputGroup>
              </div>
              <div className="col-md-4">
                <InputGroup iconClass="fas fa-id-card" label="Persona de Contacto (*)">
                  <input
                    type="text"
                    className="form-control"
                    name="Persona de conctacto"
                    placeholder="Persona de conctacto"
                    onChange={(e) => setContactPerson(e.target.value)}
                  />
                </InputGroup>
              </div>
            </div>
          </div>
        </div>
        <div className="card border-info shadow-sm mb-4 rounded">
          <div className={`card-header ${color? '' : 'bg-dark'}`} style={{backgroundColor: color}}>
            <h5 className="font-weight-bold text-white">
              SOLICITUD
            </h5>
          </div>
          <div className="card-body">
          <div className="row mb-3">
            <div className="col-sm-0">
                <h6 className="ml-3">Selecciona la pruebas: </h6>
              </div>
              <div className="col-sm-4">
                {typeLaboratories.length > 0 && typeLaboratories.map((type, index) => (
                  <div className="form-check form-check-inline custom-checkbox" key={index}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name={type.name}
                      value={type.id}
                      checked={ applications.includes(type.id)}
                      onChange={checkboxHandler}
                    />
                    <label className="form-check-label ml-2">
                      {type.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="form-row mt-2">
              <div className="col-md-4">
                <InputGroup iconClass="fas fa-building" label="Cantidad por Lotes (*)">
                  <input
                    type="number"
                    className="form-control"
                    name="Cantidad por Lotes"
                    placeholder="Cantidad por Lotes"
                    onChange={(e) => setNumberLots(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="col-md-4">
                <InputGroup iconClass="fas fa-building" label="Unidades por Lote (*)">
                  <input
                    type="number"
                    className="form-control"
                    name="Unidades por Lote"
                    placeholder="Unidades por Lote"
                    onChange={(e) => setUnitsLot(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="col-md-4">
                <InputGroup iconClass="fas fa-building" label="Descripción Solicitud (*)">
                  <input
                    type="text"
                    className="form-control"
                    name="Descripción Solicitud"
                    placeholder="Descripción Solicitud"
                    onChange={(e) => setDescriptionApplication(e.target.value)}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="form-row mt-2">
              <div className="col-md-4">
                <InputGroup iconClass="fas fa-building" label="Descripción Punto Toma de Muestra (*)">
                  <input
                    type="text"
                    className="form-control"
                    name="Descripción Solicitud"
                    placeholder="Descripción Punto Toma de Muestra"
                    onChange={(e) => setDescriptionSampleCollection(e.target.value)}
                  />
                </InputGroup>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 text-center">
          <div className="d-flex justify-content-center mb-2">
            <ReCAPTCHA
              sitekey="6Lcf7W4qAAAAAAbdjue-T58Br5sBSRCf-AbqnAqa"
              onChange={handleRecaptchaChange}
            />
          </div>
          <button
            className="btn btn-primary"
            disabled={disabledSubmit}
            style={{backgroundColor: color}}
          >
            Enviar Solicitud
          </button>
        </div>
      </form>
    </div>
  )
}

export {New}