import { Controller } from 'stimulus'
import Chart from 'chart.js'

export default class extends Controller {
  connect () {
    let { points } = this.element.dataset

    points = JSON.parse(points)

    const labels = points.map(({ chapter }) => chapter)
    const total = points.map(({ total }) => total)
    const prom = points.map(({ prom }) => prom)
    const data = points.map(({ value }) => value)
    const colors = points.map(({ color }) => color)
    const average = data.map((data, index) => (data * 100) / 4)

    this.ctx = this.element.getContext('2d')
    this.options = {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: 'Capitulos',
            total: total,
            prom: prom,
            pointBorderWidth: 5,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: true,
            backgroundColor: colors,
            borderWidth: 2,
            data,
            // Utiliza la función generateLabel para agregar el promedio a las etiquetas
            generateLabel: function (context) {
              const index = context.dataIndex;
              return `Capitulo ${labels[index]} (Promedio: )`;
            },
          },
        ]
      },
      options: {
        tooltips: {
          titleFontFamily: 'Source Sans Pro',
          titleFontSize: 16,
          bodyFontSize: 16,
          xPadding: 10,
          yPadding: 10,
          callbacks: {
            // Personaliza el tooltip para incluir la etiqueta generada
            label: function (tooltipItem, data) {
              const dataIndex = tooltipItem.index;
              const total = data.datasets[tooltipItem.datasetIndex].total[dataIndex];
              const prom = data.datasets[tooltipItem.datasetIndex].prom[dataIndex];
              const label = data.labels[dataIndex];
              const avg = average[dataIndex].toFixed(2) + "%";

              return `${label}: ${prom} / Puntos: ${total}`;
            },
          },
        },
        animation: {
          easing: 'easeInOutBack'
        },
        legend: {
          display: true,
          position: 'top'
        },
        scales: {
          yAxes: [{
            ticks: {
              fontColor: 'rgba(0,0,0,0.5)',
              fontStyle: 'bold',
              beginAtZero: true,
              maxTicksLimit: 5,
              max: 100,
              padding: 20
            },
            gridLines: {
              drawTicks: false,
              display: false
            }
          }],
          xAxes: [{
            gridLines: {
              zeroLineColor: 'transparent'
            },
            ticks: {
              padding: 20,
              fontColor: 'rgba(0,0,0,0.5)',
              fontStyle: 'bold'
            }
          }]
        }
      }
    }
    this.chart = new Chart(this.ctx, this.options)
  }
}
