import React, { useEffect, useState } from "react";
import Impact from "./Impact";
import { GetRisks } from "../Form/Requests";

const Aspect = ({
  aspect,
  indexAspect,
  deleteAspect,
  aspects,
  indexActivity,
  envImpact,
  envImpactAux,
  setEnvImpactAux,
  envAspectsAux,
  setEnvAspectsAux,
  envAspects,
  setEnvAspects,
  activitiesAux,
  setActivitiesAux,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const [impactsAux, setImpactsAux] = useState([]);
  const [impacts, setImpacts] = useState([]);
  const [impactsCounter, setImpactsCounter] = useState(-1);
  const [risks, setRisks] = useState([]);

  const handleChange = (e) => {
    const { value } = e.target;
    const updatedAspects = [...envAspectsAux];
    updatedAspects[indexAspect] = {
      ...updatedAspects[indexAspect],
      descriptionInput: value,
    };
    setEnvAspectsAux(updatedAspects);
  };

  const handleSelect = (idAspect, e) => {
    const { value } = e.target;
    setEnvAspectsAux((prevEnvAspectsAux) =>
      prevEnvAspectsAux.map((item) =>
        item.indexEnvAspect === idAspect
          ? { ...item, environmentalSelect: value }
          : item
      )
    );
  };

  useEffect(() => {
    const formattedAspects = envAspectsAux.reduce((acc, aspect, index) => {
      acc[index] = {
        id: aspect?.id || null,
        environmental_aspect_id: aspect.environmentalSelect,
        environmental_aspect_description: aspect.descriptionInput,
        env_impact_evaluations_attributes:
          aspect.env_impact_evaluations_attributes,
        _destroy: aspect?._destroy || false,
      };
      return acc;
    }, {});
    setEnvAspects(formattedAspects);
  }, [envAspectsAux]);

  useEffect(() => {
    const updatedImpact = { ...envImpactAux };
    const updatedActivitiesAux = [...activitiesAux];
    updatedImpact.env_impact_activities_attributes[
      indexActivity
    ].env_impact_aspects_attributes = envAspects;
    updatedActivitiesAux[indexActivity].env_impact_aspects_attributes =
      envAspects;
    setEnvImpactAux(updatedImpact);
    setActivitiesAux(updatedActivitiesAux);
  }, [envAspects]);

  useEffect(() => {
    GetRisks().then((data) => {
      setRisks(data);
    });
  }, []);


  useEffect(() => {
    if (
      !!envImpact.env_impact_activities_attributes[indexActivity]
        ?.env_impact_aspects_attributes[indexAspect]
    ) {
      const formattedImpacts = envImpact.env_impact_activities_attributes[
        indexActivity
      ].env_impact_aspects_attributes[
        indexAspect
      ].env_impact_evaluations_attributes.reduce((acc, evaluation, index) => {
        acc[index] = {
          id: evaluation?.id || null,
          indexImpact: index,
          description: evaluation.description,
          control: evaluation.control,
          character: evaluation.character,
          intensity: evaluation.intensity,
          impact_extension: evaluation.impact_extension,
          moment: evaluation.moment,
          persistence: evaluation.persistence,
          reversibility: evaluation.reversibility,
          synergy: evaluation.synergy,
          accumulation: evaluation.accumulation,
          effect: evaluation.effect,
          periodicity: evaluation.periodicity,
          recoverability: evaluation.recoverability,
          risk_id: evaluation.risk_id,
          risk_description: evaluation.risk_description,
          associated_opportunity_description:
            evaluation.associated_opportunity_description,
          observations: evaluation.observations,
          significant_impact: evaluation.significant_impact,
          significance: evaluation.significance,
          significance_level: evaluation.significance_level,
          _destroy: false,
          resources_attributes: {},
        };
        return acc;
      }, []);
      setImpactsAux(formattedImpacts);
      setImpactsCounter(
        envImpact.env_impact_activities_attributes[indexActivity]
          .env_impact_aspects_attributes[indexAspect]
          .env_impact_evaluations_attributes.length - 1
      );
    }
  }, [envImpact]);

  const addImpact = () => {
    const localCounter = impactsCounter + 1;
    const newImpact = {
      id: null,
      indexImpact: localCounter,
      description: "",
      control: "",
      character: "",
      intensity: null,
      impact_extension: null,
      moment: null,
      persistence: null,
      reversibility: null,
      synergy: null,
      accumulation: null,
      effect: null,
      periodicity: null,
      recoverability: null,
      risk_id: risks[0].id,
      risk_description: "",
      associated_opportunity_description: "",
      observations: "",
      significance: 0,
      significance_level: "",
      significant_impact: "",
      _destroy: false,
      resources_attributes: {},
    };
    setImpactsCounter(impactsCounter + 1);
    setImpactsAux([...impactsAux, newImpact]);
  };

  const deleteImpact = (e) => {
    const item = impactsAux.find((item) => item.indexImpact === e);
    if (!!item.id) {
      const indexToUpdate = impactsAux.findIndex(
        (item) => item.indexImpact === e
      );
      const newObj = { ...impactsAux[indexToUpdate], _destroy: true };
      setImpactsAux((prevState) => {
        const newState = [...prevState];
        newState[indexToUpdate] = newObj;
        return newState;
      });
    } else {
      const updatedImpacts = impactsAux.filter(
        (proc) => proc.indexImpact !== e
      );
      const reindexedImpacts = updatedImpacts.map((proc, index) => ({
        ...proc,
        indexImpact: index,
      }));
      setImpactsCounter(impactsCounter - 1);
      setImpactsAux(reindexedImpacts);
    }
  };

  if (collapsed) {
    return (
      <div className={`${aspect._destroy ? "d-none" : ""}`}>
        <div className="form-row px-3 mt-3">
          <div className="col-md-12">
            <div className="d-flex form-group mb-0">
              <div
                className="d-flex justify-content-between pr-2 input-group-text col-md-11 cursor-pointer"
                onClick={() => setCollapsed(false)}
              >
                <div className="">
                  <span>Aspecto ambiental asociado #{indexAspect + 1}</span>
                </div>
                <div>
                  <i className="fas fa-chevron-down"></i>
                </div>
              </div>
              <div className="col-md-1 d-flex justify-content-center cursor-pointer">
                <a
                  className="btn btn-outline-danger"
                  onClick={() => deleteAspect(indexAspect)}
                >
                  <i className="fas fa-times text-danger"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`form-row px-3 mt-3 ${aspect._destroy ? "d-none" : ""}`}>
        <div className="col-md-12">
          <div className="d-flex col-md-12 mb-3 p-0">
            <div
              className="d-flex justify-content-between pr-2 input-group-text col-md-11 cursor-pointer "
              onClick={() => setCollapsed(true)}
            >
              <div className="">
                <span>Aspecto ambiental asociado #{indexAspect + 1}</span>
              </div>
              <div>
                <i className="fas fa-chevron-up"></i>
              </div>
            </div>
            <div className="col-md-1 d-flex justify-content-center cursor-pointer">
              <a
                className="btn btn-outline-danger"
                onClick={() => deleteAspect(indexAspect)}
              >
                <i className="fas fa-times text-danger"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="card-body py-0 px-2">
          <div className="form-row">
            <div className="col-md-12">
              <div className="form-group mb-3">
                <span>Aspecto ambiental</span>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="fas fa-file-alt form-icon-small"></i>
                    </div>
                  </div>
                  <select
                    className="form-control custom-select"
                    onChange={(e) => handleSelect(indexAspect, e)}
                    value={aspect.environmentalSelect}
                  >
                    {aspects.map((aspect) => (
                      <option key={aspect.id} value={aspect.id}>
                        {aspect.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group mb-3">
                <span>Descripción del aspecto ambiental</span>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="fas fa-file-alt form-icon-small"></i>
                    </div>
                  </div>
                  <textarea
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                    value={aspect?.descriptionInput}
                  />
                </div>
              </div>
            </div>
          </div>
          {impactsAux.map((impact) => (
            <Impact
              key={impact.indexImpact}
              indexImpact={impact.indexImpact}
              deleteImpact={deleteImpact}
              impactsAux={impactsAux}
              setImpactsAux={setImpactsAux}
              impact={impact}
              risks={risks}
              impacts={impacts}
              setImpacts={setImpacts}
              envImpactAux={envImpactAux}
              setEnvImpactAux={setEnvImpactAux}
              indexActivity={indexActivity}
              indexAspect={indexAspect}
              envAspectsAux={envAspectsAux}
              setEnvAspectsAux={setEnvAspectsAux}
              envImpact={envImpact}
            />
          ))}
          <div className="card-body p-3">
            <button
              type="button"
              className="btn btn-info btn-sm mb-2"
              onClick={addImpact}
            >
              <i className="fas fa-plus mr-1"></i>
              Agregar
            </button>
            <span className="ml-2"> Agregar Impacto</span>
          </div>
        </div>
      </div>
    );
  }
};

export default Aspect;
