import React, { useState, useEffect } from 'react'
import CardChart from './CardChartYear'
const Modal = ({url, modalControl, group, modaltitle, date, initialdate, finaldate}) => {
  const [data, setData] = useState([])
  useEffect(() => {
    fetch(url).then((response)=>{
      return response.json()
    }).then((data) => {
      setData(data)
    })
  }, [url, date, initialdate, finaldate])
  return (
    <div className="modal fade show" tabIndex="-1" style={{paddingRight: '4px', display: 'block', backgroundColor: 'rgba(53, 53, 53, 0.5)'}} role="dialog">
      <div className="modal-dialog modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{modaltitle}</h5>
            <button type="btn btn-link" className="close" onClick={()=> modalControl(false)} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            { data.map((info)=>{
              return ( <CardChart key={info.id}
                                  text={info.text}
                                  value={info.value}
                                  color={info.color}
                                  modalControl={modalControl}
                                  group={group}
                                  groupid={info.id}
                                  date={date}
                                  initialdate={initialdate}
                                  finaldate={finaldate}/>)
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal