import { Controller } from 'stimulus'

export default class extends Controller {
  connect (e) {
    if (e) e.preventDefault()
    const url = this.data.get('url')
    const elementId = this.data.get('target')
    $.get(url).then((content) => {
      document.getElementById(elementId).innerHTML = content
    })
  }
}
