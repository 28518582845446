import React, {useState, useEffect} from "react"
import General from "./BscDevelopmentPlan/General"
import DateInput from './BscDevelopmentPlan/DateInput'
import TypeSelect from './BscDevelopmentPlan/TypeSelect'
import CardChart from './BscDevelopmentPlan/CardChart'
import Modal from './BscDevelopmentPlan/Modal'

const MiPgBsc = () => {
  const [general, setGeneral] = useState({ present: false, id: null, name: null, components: false })
  const [average, setAverage] = useState(0)
  const [lines, setLines] = useState([])
  const [date, setDate] = useState('')
  const [indicatortype, setIndicatorType] = useState('result')
  const [parent, setModalParent] = useState('')
  const [modal, ShowModal] = useState(false)
  const [modaltitle, setModalTitle] = useState('')
  const [query, setQuery] = useState('')
  var sumAverage = 0
  var availableLines = 0
  const dateCallBack = (date) =>{
    setDate(date)
  }

  const typeCallback = (type) => {
    setIndicatorType(type)
  }

  useEffect(() => {
    fetch('/api/v1/mipg_metrics/development_plan?date='+date+'&type_of='+indicatortype).then((response)=>{
      return response.json()
    }).then((data) => { 
      setGeneral(data)
    })
  }, [])

  useEffect(() => {
    fetch('/api/v1/mipg_metrics/strategic_lines?date='+date+'&type_of='+indicatortype).then((response)=>{
      return response.json()
    }).then((data)=>{ setLines(data) })
  }, [general])

  const modalCallback = (modal, parent, modaltitle, query) => {
    ShowModal(modal)
    setModalParent(parent)
    setModalTitle(modaltitle)
    setQuery(query)
  }

  const generalAverage = (available, value) => {
    if (general.present) {
      if (available) { 
        sumAverage += parseFloat(value)
        availableLines += 1
      }      
      if (availableLines > 0) {
        setAverage((sumAverage / availableLines).toFixed(2))
      }
    }
  }

  return (
    <>
      <div className='card-body'>
        <div className='d-flex justify-content-between'>
          <DateInput dateFilter={dateCallBack}/>
          <TypeSelect typeChange={typeCallback}></TypeSelect>
        </div>
        {general.present && <General name={general.name} average={average} counts={lines.length}/>}
        <div className='row mt-4'>
          { general.present && lines.map((line)=>{
            return (<CardChart key={line.id}
                               id={line.id}
                               name={line.name}
                               date={date}
                               lineValue={generalAverage}
                               indicatortype={indicatortype}
                               components={general.components}
                               modalControl={modalCallback}/>)
          })}
        </div>
        {modal && <Modal modalControl={modalCallback}
                         parent={parent}
                         query={query}
                         modaltitle={modaltitle}
                         indicatortype={indicatortype}
                         date={date}/>}
      </div>
    </>
  ); 
}

export default MiPgBsc