import React from 'react'
import CardChart from './CardChart'
const ListData = ({data, showAverage, modalControl, parentType}) => {
  return data.map((info) => {
    return (<React.Fragment key={info.id}>
      <CardChart key={info.id}
                 text={info.name}
                 value={showAverage ? info.value : info.weighted?.toFixed(0) }
                 color={info.color}
                 modalControl={modalControl}
                 parentType={parentType}
                 parentId={info.id}/>
    </React.Fragment>)
  })
}

export default ListData