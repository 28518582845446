import React, { useState, useEffect } from 'react'
import CardList from './CardList'
const Modal = ({modalControl, indicatortype, modaltitle, date, query, parent}) => {
  const [data, setData] = useState([])
  const [nextquery, setQuery] = useState('')
  var url = ''
  useEffect(() => {
    if (query == 'components') {
      setQuery('programs')
      url = '/api/v1/mipg_metrics/strategic_components?line_id='+parent+'&date='+date+'&type_of='+indicatortype
    } else if (query == 'programs') {
      setQuery('indicators')
      url = '/api/v1/mipg_metrics/programs?parent_id='+parent+'&date='+date+'&type_of='+indicatortype
    } else  if (query == 'indicators') {
      setQuery('none')
      url = '/api/v1/mipg_metrics/indicators?program_id='+parent+'&date='+date+'&type_of='+indicatortype
    }
    fetch(url).then((response)=>{
      return response.json()
    }).then((data) => {
      setData(data)
    })
  }, [query])
  return (
    <div className="modal fade show" tabIndex="-1" style={{paddingRight: '4px', display: 'block', backgroundColor: 'rgba(53, 53, 53, 0.5)'}} role="dialog">
      <div className="modal-dialog modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{modaltitle}</h5>
            <button type="btn btn-link" className="close" onClick={()=> modalControl(false)} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <ul className='list-group cursor-pointer'>
              { data.map((info)=>{
                return ( <CardList key={info.id}
                                   text={info.text}
                                   value={info.value}
                                   color={info.color}
                                   modalControl={modalControl}
                                   indicatortype={indicatortype}
                                   showchart={info.available}
                                   query={nextquery}
                                   parent={info.id}
                                   date={date}/>)
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal