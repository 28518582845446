import React, { useState, useContext, useEffect, useMemo } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";

function ModalForm({ temporalInformation, setTemporalInformation }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [typeOptions, setTypeOptions] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [selectedDateEnd, setSelectedDateEnd] = useState(null);
  const [periodicities, setPeriodicities] = useState([]);
  const [optionsPeriodicities, setOptionsPeriodicities] = useState([]);
  const [selectedPeriodicities, setSelectedPeriodicities] = useState();
  const [selectedDateRemember, setSelectedDateRemember] = useState(null);
  const [procedures, setProcedures] = useState([]);
  const [optionsProcedures, setOptionsProcedures] = useState([]);
  const [selectedProcedures, setSelectedProcedures] = useState();
  const [optionsManagementSystem, setOptionsManagementSystem] = useState([]);
  const [selectedManagementSystem, setSelectedManagementSystem] = useState();
  const [users, setUsers] = useState([]);
  const [optionsUsers, setOptionsUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedUsersRemember, setSelectedUserRemember] = useState([]);

  useEffect(() => {
    fetch("/api/v2/users/")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setUsers(data.results);
      }),
      fetch("/api/v2/periodicities/")
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setPeriodicities(data);
        }),
      fetch("/api/v2/procedures/")
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setProcedures(data);
        });
  }, []);

  useEffect(() => {
    const optionPeriodicities = periodicities.map((periodicities) => ({
      value: periodicities.id,
      label: periodicities.name,
    }));
    setOptionsPeriodicities(optionPeriodicities);
  }, [periodicities]);

  useEffect(() => {
    const optionUsers = users.map((user) => ({
      value: user.id,
      label: user.name,
    }));
    setOptionsUsers(optionUsers);
  }, [users]);

  useEffect(() => {
    const optionProcedure = procedures.map((procedures) => ({
      value: procedures.id,
      label: procedures.title,
    }));
    setOptionsProcedures(optionProcedure);
  }, [procedures]);

  useEffect(() => {
    const options = [
      { value: true, label: "Evento" },
      { value: false, label: "Tarea" },
    ];
    setTypeOptions(options);
  }, [temporalInformation.type]);

  useEffect(() => {
    const options = [
      { value: "quality", label: "Calidad", color: "#007BFF" },
      { value: "sst", label: "SST", color: "#FFEB3B" },
      { value: "environmental", label: "Ambiental", color: "#C8E6C9" },
      { value: "no_one", label: "Ninguno", color: "#E0E0E0" },
    ];
    setOptionsManagementSystem(options);
  }, [temporalInformation.associatedManagementSystem]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleTypeChange = (selectedOption) => {
    setTemporalInformation({
      ...temporalInformation,
      type: selectedOption.value,
    });
    setSelectedType(selectedOption);
  };

  const handleDateChange = (value) => {
    setTemporalInformation({
      ...temporalInformation,
      day: dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
    });
    setSelectedDate(value);
  };

  const handleOutsideClick = () => {
    setSelectedDate(selectedDate);
  };

  const handleDateEndChange = (value) => {
    setTemporalInformation({
      ...temporalInformation,
      dayEnd: dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
    });
    setSelectedDateEnd(value);
  };

  const handleEndOutsideClick = () => {
    setSelectedDateEnd(selectedDateEnd);
  };

  const handleDateRememberChange = (value) => {
    setTemporalInformation({
      ...temporalInformation,
      rememberDate: dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
    });
    setSelectedDateRemember(value);
  };

  const handleRememberOutsideClick = () => {
    setSelectedDateRemember(selectedDateEnd);
  };

  const handlePeriocity = (selectedOption) => {
    setTemporalInformation({
      ...temporalInformation,
      periocityTipe: selectedOption.value,
    });
    setSelectedPeriodicities(selectedOption);
  };

  const handlePlaceCheckboxChange = (e) => {
    setTemporalInformation({
      ...temporalInformation,
      placeOption: e.target.checked,
    });
  };

  const handleLinkCheckboxChange = (e) => {
    setTemporalInformation({
      ...temporalInformation,
      linkOption: e.target.checked,
    });
  };

  const handleArchivesCheckboxChange = (e) => {
    setTemporalInformation({
      ...temporalInformation,
      archivesOption: e.target.checked,
    });
  };

  const handleArchivesFileChange = (e) => {
    setTemporalInformation({
      ...temporalInformation,
      archives: e.target.files[0],
    });
  };

  const handleDescriptionCheckboxChange = (e) => {
    setTemporalInformation({
      ...temporalInformation,
      descriptionOption: e.target.checked,
    });
  };

  const handleDescriptionChange = (e) => {
    setTemporalInformation({
      ...temporalInformation,
      description: e.target.value,
    });
  };

  const handleRememberCheckboxChange = (e) => {
    setTemporalInformation({
      ...temporalInformation,
      remember: e.target.checked,
    });
  };

  const handleRememberUsersChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedUserRemember((prevSelectedUsers) => [
        ...prevSelectedUsers,
        selectedOption,
      ]);
      setTemporalInformation((prevTemporalInformation) => ({
        ...prevTemporalInformation,
        rememberUsers: [
          ...prevTemporalInformation.rememberUsers,
          selectedOption.value,
        ],
      }));
    } else {
      setSelectedUserRemember((prevSelectedUsers) =>
        prevSelectedUsers.filter((user) => user.value !== selectedOption.value)
      );
      setTemporalInformation((prevTemporalInformation) => ({
        ...prevTemporalInformation,
        rememberUsers: prevTemporalInformation.rememberUsers.filter(
          (user) => user !== selectedOption.value
        ),
      }));
    }
  };

  const handleOrganizerChange = (selectedOption) => {
    setTemporalInformation({
      ...temporalInformation,
      organizer: selectedOption.value,
    });
    setSelectedUser(selectedOption);
  };

  const handleProcedures = (selectedOption) => {
    setTemporalInformation({
      ...temporalInformation,
      procedures: selectedOption.value,
    });
    setSelectedProcedures(selectedOption);
  };

  const handleManagementSystem = (selectedOption) => {
    setTemporalInformation({
      ...temporalInformation,
      associatedManagementSystem: selectedOption.value,
    });
    setSelectedManagementSystem(selectedOption);
  };

  return (
    <div className="container-fluid">
      <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <label
                    className="form-check-label"
                    htmlFor="floatingInputGrid"
                    style={{ color: "#696969" }}
                  >
                    Titulo :
                  </label>
                  <input
                    placeholder="Titulo"
                    type="text"
                    className="form-control"
                    id="title"
                    value={temporalInformation.title}
                    onChange={(e) =>
                      setTemporalInformation({
                        ...temporalInformation,
                        title: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <label
                    className="form-check-label"
                    htmlFor="floatingInputGrid"
                    style={{ color: "#696969" }}
                  >
                    Tipo :
                  </label>
                  <Select
                    options={typeOptions}
                    name="[type_id]"
                    value={selectedType}
                    onChange={handleTypeChange}
                    placeholder={
                      selectedType ? selectedType.label : "Selecciona un tipo"
                    }
                    formatOptionLabel={({ label }) => <span>{label}</span>}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <label
                    className="form-check-label"
                    htmlFor="floatingInputGrid"
                    style={{ color: "#696969" }}
                  >
                    Hora inicio:
                  </label>
                  <DatePicker
                    selected={selectedDate}
                    withPortal
                    onChange={handleDateChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    onClickOutside={handleOutsideClick}
                    dateFormat="dd/MM/yyyy HH:mm"
                    className="form-control custom-datepicker"
                    placeholderText="Fecha y hora de inicio"
                    style={{ color: "#000" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <label
                    className="form-check-label"
                    htmlFor="floatingInputGrid"
                    style={{ color: "#696969" }}
                  >
                    Hora Final:
                  </label>
                  <DatePicker
                    selected={selectedDateEnd}
                    withPortal
                    onChange={handleDateEndChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    onClickOutside={handleEndOutsideClick}
                    dateFormat="dd/MM/yyyy HH:mm"
                    className="form-control custom-datepicker"
                    placeholderText="Fecha y hora Final"
                    style={{ color: "#000" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="form-floating">
                <label
                  className="form-check-label align-items-center"
                  htmlFor="floatingInputGrid"
                  style={{ color: "#696969" }}
                >
                  Periodicidad :
                </label>
                <Select
                  options={optionsPeriodicities}
                  name="[periodicities_id]"
                  value={selectedPeriodicities}
                  onChange={handlePeriocity}
                  placeholder={
                    selectedPeriodicities
                      ? selectedPeriodicities.label
                      : "periodicidad"
                  }
                  formatOptionLabel={({ label }) => <span>{label}</span>}
                />
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={temporalInformation.placeOption}
                      onChange={handlePlaceCheckboxChange}
                      id="placeCheckbox"
                    />
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={temporalInformation.placeOption}
                      onChange={handlePlaceCheckboxChange}
                      id="placeCheckbox"
                    />
                    <label className="form-check-label" htmlFor="placeCheckbox">
                      ¿Desea agregar Lugar?
                    </label>
                  </div>
                  <div className="col">
                    <input
                      placeholder="Ingrese lugar"
                      type="text"
                      className="form-control"
                      id="title"
                      value={temporalInformation.place}
                      onChange={(e) =>
                        setTemporalInformation({
                          ...temporalInformation,
                          place: e.target.value,
                        })
                      }
                      disabled={!temporalInformation.placeOption}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={temporalInformation.linkOption}
                      onChange={handleLinkCheckboxChange}
                      id="linkCheckbox"
                    />
                    <label className="form-check-label" htmlFor="placeCheckbox">
                      ¿Desea agregar Link?
                    </label>
                  </div>
                  <div className="col">
                    <input
                      placeholder="Ingrese el link"
                      type="text"
                      className="form-control"
                      id="link"
                      value={temporalInformation.link}
                      onChange={(e) =>
                        setTemporalInformation({
                          ...temporalInformation,
                          link: e.target.value,
                        })
                      }
                      disabled={!temporalInformation.linkOption}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={temporalInformation.descriptionOption}
                      onChange={handleDescriptionCheckboxChange}
                      id="descriptionCheckbox"
                    />
                    <label className="form-check-label" htmlFor="placeCheckbox">
                      ¿Desea agregar Descripcion?
                    </label>
                  </div>
                  <div className="col">
                    <input
                      placeholder="Ingrese la descripcion"
                      type="text"
                      className="form-control"
                      id="description"
                      value={temporalInformation.description}
                      onChange={handleDescriptionChange}
                      disabled={!temporalInformation.descriptionOption}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={temporalInformation.archivesOption}
                      onChange={handleArchivesCheckboxChange}
                      id="archivesCheckbox"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="archivesCheckbox"
                    >
                      ¿Archivo adjunto?
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control-file"
                      id="attachmentFile"
                      onChange={handleArchivesFileChange}
                      disabled={!temporalInformation.archivesOption}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={temporalInformation.remember}
                      onChange={handleRememberCheckboxChange}
                      id="rememberCheckbox"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="rememberCheckbox"
                    >
                      ¿Desea agregar recordatorio?
                    </label>
                  </div>
                  <div className="col">
                    <label
                      className="form-check-label"
                      htmlFor="rememberCheckbox"
                    >
                      Agrega los invitados
                    </label>
                    {selectedUsersRemember &&
                      selectedUsersRemember.map((user) => (
                        <label className="col" key={user.value}>
                          {user.label}
                        </label>
                      ))}
                    <Select
                      options={optionsUsers}
                      name="[users_id]"
                      value={selectedUsersRemember}
                      onChange={handleRememberUsersChange}
                      placeholder="Selecciona un usuario"
                      formatOptionLabel={({ label }) => <span>{label}</span>}
                      isDisabled={!temporalInformation.remember}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <label
                    className="form-check-label"
                    htmlFor="floatingInputGrid"
                    style={{ color: "#696969" }}
                  >
                    Hora Del recordatorio:
                  </label>
                  <DatePicker
                    selected={selectedDateRemember}
                    onChange={handleDateRememberChange}
                    withPortal
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    onClickOutside={handleRememberOutsideClick}
                    className="form-control custom-datepicker"
                    dateFormat="dd/MM/yyyy HH:mm"
                    placeholderText="Fecha y hora del recordatorio"
                    style={{ color: "#000" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <label
                    className="form-check-label"
                    htmlFor="floatingInputGrid"
                    style={{ color: "#696969" }}
                  >
                    Proceso asociado :
                  </label>
                  <Select
                    options={optionsProcedures}
                    name="[optionsProcedures_id]"
                    value={selectedProcedures}
                    onChange={handleProcedures}
                    placeholder={
                      selectedProcedures
                        ? selectedProcedures.label
                        : "Selecciona el sistema"
                    }
                    formatOptionLabel={({ label }) => <span>{label}</span>}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <label
                    className="form-check-label"
                    htmlFor="floatingInputGrid"
                    style={{ color: "#696969" }}
                  >
                    Sistema de Gestión Asociado :
                  </label>
                  <Select
                    options={optionsManagementSystem}
                    name="[value]"
                    value={selectedManagementSystem}
                    onChange={handleManagementSystem}
                    placeholder={
                      selectedManagementSystem
                        ? selectedManagementSystem.label
                        : "Selecciona un sistema"
                    }
                    formatOptionLabel={({ label }) => <span>{label}</span>}
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.data.color,
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 my-2">
            <div className="col-md">
              <div className="row align-items-center">
                <div className="col">
                  <label
                    className="form-check-label"
                    htmlFor="rememberCheckbox"
                  >
                    ¿Organizador?
                  </label>
                  <Select
                    options={optionsUsers}
                    name="[users_id]"
                    value={selectedUser}
                    onChange={handleOrganizerChange}
                    placeholder={
                      selectedUser
                        ? selectedUser.label
                        : "Selecciona un usuario"
                    }
                    formatOptionLabel={({ label }) => <span>{label}</span>}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ModalForm;
