import tinycolor from 'tinycolor2'
import * as d3 from 'd3'
import { loadLiquidFillGauge, liquidFillGaugeDefaultSettings } from '../shared/liquidGauge'

export default function (elementId, color = '#000000', value = 0) {
  const config = liquidFillGaugeDefaultSettings()

  config.circleThickness = 0.1
  config.circleFillGap = 0.1
  config.textVertPosition = 0.5
  config.textSize = 1
  config.waveCount = 2
  config.waveAnimateTime = 6000
  //config.circleColor = tinycolor(color).lighten().toHexString()
  config.circleColor = color
  config.textColor = '#000000'
  config.waveTextColor = '#0000000'
  config.waveColor = color

  d3.select(`#${elementId}`).selectAll('*').remove()

  loadLiquidFillGauge(elementId, value || 0, config)
}
