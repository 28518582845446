import React, { useState } from 'react'

const InputGroup = ({placeholder, value, label, icon, setter, customProps}) => {
  const [inputValue, setInputValue] = useState(value);
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setter(e.target.value)
  }

  return(
    <div className="form-group mb-3">{label}
      <div className="input-group">
        <div className="input-group-prepend">
          <div className="input-group-text">
            <i className={`${icon} form-icon-small`}></i>
          </div>
        </div>
        <input
          placeholder={placeholder}
          className="form-control"
          onChange={handleInputChange}
          type="text"
          value={inputValue}
          {...customProps}>
        </input>
      </div>
    </div>
  )
}

export default InputGroup