import React from 'react'

const LoadingTable = () => {
  return (
    <table className='Loading'>
      <tbody>
        <tr>
          <td className="td-1"><span>Loading</span></td>
          <td className="td-2"><span></span></td>
          <td className="td-3"><span></span></td>
          <td className="td-4"></td>
          <td className="td-5"><span></span></td>
        </tr>
        <tr>
          <td className="td-1"><span></span></td>
          <td className="td-2"><span></span></td>
          <td className="td-3"><span></span></td>
          <td className="td-4"></td>
          <td className="td-5"><span></span></td>
        </tr>
        <tr>
          <td className="td-1"><span></span></td>
          <td className="td-2"><span></span></td>
          <td className="td-3"><span></span></td>
          <td className="td-4"></td>
          <td className="td-5"><span></span></td>
        </tr>
        <tr>
          <td className="td-1"><span></span></td>
          <td className="td-2"><span></span></td>
          <td className="td-3"><span></span></td>
          <td className="td-4"></td>
          <td className="td-5"><span></span></td>
        </tr>
        <tr>
          <td className="td-1"><span></span></td>
          <td className="td-2"><span></span></td>
          <td className="td-3"><span></span></td>
          <td className="td-4"></td>
          <td className="td-5"><span></span></td>
        </tr>
      </tbody>
    </table>
  )
}

export default LoadingTable