
import React, { useEffect, useState } from 'react'
import InputGroup from '../forms/InputGroup'
import Header from '../Basics/Header'
import CheckBox from '../forms/CheckBox'

const AssociateObjectiveForm = (props) => {
  const [objectiveId, setObjectiveId] = useState(props.operative_objective.id)
  const [strategic_objective_id, setStrategicObjective] = useState(props.operative_objective.strategic_objective_id)
  const [companies, setCompanies] = useState([])
  const [company, setCompany] = useState()
  const [companyBscs, setCompanyBscs] = useState([])
  const [bscId, setBscId] = useState()
  const [strategicObjectives, setStrategicObjectives] = useState([])
  const [group_strategic_id, setGroupStrategic] = useState(props.operative_objective.group_strategic_id)
  const [percentage, setPercentage] = useState(props.operative_objective.percentage)

  const handleSubmit = (event) => {
    event.preventDefault()
    const url = objectiveId === null ? '/api/v1/operative_objectives' : `/api/v1/operative_objectives/${objectiveId}`
    const method = objectiveId === null ? 'POST' : 'PATCH'
    const datos = { strategic_objective_id, group_strategic_id, percentage }

    fetch(url, {
      method: method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(datos)
    }).then((response) => { return response.json() })
      .then(window.location.href = `/bsc_perspectives/${props.perspective_id}/strategic_objectives/${strategic_objective_id}/operative_objectives`)
      .catch(error => console.error(error))
  }

  useEffect(()=> {
    fetch(`/api/v1/portals`)
    .then((response) => { return response.json() })
    .then((data) => {
      setCompanies(data)
    })
    .catch(error => console.error(error))
  }, [])

  useEffect(()=> {
    if (company) {
      fetch(`/api/v1/bscs?portal_id=${company}`)
      .then((response) => { return response.json() })
      .then((data) => {
        setCompanyBscs(data)
      })
      .catch(error => console.error(error))
    }
  }, [company])

  useEffect(()=> {
    if (bscId) {
      fetch(`/api/v1/strategic_objectives?bsc_id=${bscId}`)
      .then((response) => { return response.json() })
      .then((data) => {
        setStrategicObjectives(data)
      })
      .catch(error => console.error(error))
    }
  }, [bscId])

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Header backUrl={`/bsc_perspectives/${props.perspective_id}/strategic_objectives/${strategic_objective_id}/operative_objectives`}/>
        <div className='card-body'>
          <div className='card shadow mb-3'>
            <div className='card-header bg-white'>
              <h6 className='card-title'>Conectar con empresa</h6>
            </div>
            <div className='card-body'>
              { group_strategic_id &&
                <div className="alert alert-info" role="alert">Objetivo estratégico seleccionado
                <button type="button" className="close" onClick={() => setGroupStrategic(null)}>
                  <span aria-hidden="true">&times;</span>
                </button>
                </div>
              }
              <div className='form-row'>
                <div className='col-12'>
                  <InputGroup
                    placeholder={'Porcentaje'}
                    value={percentage}
                    label={'Porcentaje'}
                    icon={'fas fa-percentage'}
                    setter={setPercentage}
                    customProps={{ type: "number" }}
                  />
                </div>
              </div>
              <div className='form-row'>
                <div className='col-4'>
                  <div className='list-group'>
                    <li className="list-group-item active">Empresa</li>
                    { !companies.length && <li className="list-group-item">No hay empresas</li> }
                    { (companies.length > 0) && companies.map((company) => {
                      return (
                        <React.Fragment key={company.id}>
                          <button type="button"
                                  onClick={() => setCompany(company.id)}
                                  className="list-group-item list-group-item-action">{company.name}</button>
                        </React.Fragment>)
                    })}
                  </div>
                </div>
                <div className='col-4'>
                  <div className='list-group'>
                    <li className="list-group-item active">BSC Estratégico</li>
                    { !(companyBscs.length > 0) && <li className="list-group-item">No hay BSC en esta empresa</li> }
                    { (companyBscs.length > 0) && companyBscs.map((companyBsc) => {
                      return (
                        <React.Fragment key={companyBsc.id}>
                          <button type="button"
                                  onClick={() => setBscId(companyBsc.id)}
                                  className="list-group-item list-group-item-action">{companyBsc.name}</button>
                        </React.Fragment>)
                    })}
                  </div>
                </div>
                <div className='col-4'>
                  <div className='list-group'>
                    <li className="list-group-item active">Objetivos Estratégicos</li>
                    { !(strategicObjectives.length > 0) && <li className="list-group-item">No hay Objetivos Estratégicos</li> }
                    { (strategicObjectives.length > 0) && strategicObjectives.map((sObj) => {
                      return (
                        <React.Fragment key={sObj.id}>
                          <button type="button"
                                  onClick={() => setGroupStrategic(sObj.id)}
                                  className={`list-group-item list-group-item-action ${group_strategic_id == sObj.id ? 'list-group-item-secondary' : ''}`}>{sObj.name}</button>
                        </React.Fragment>)
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default AssociateObjectiveForm