import React, { useContext, useEffect } from "react";
import { Toast, Container } from "react-bootstrap";
import { FLASH_TYPE_ERROR, FlashContext } from "../Context/FlashContext";
import "./toast.css";

const classMap = {
  notice: "toast-notice",
  success: "toast-success",
  error: "toast-error",
  draft: "toast-draft",
};

export const FlashAlerts = () => {
  const { message, type, ts, clearFlash } = useContext(FlashContext);

  if (!message) {
    return null;
  }

  return (
    <div className="toast-wrapper">
      <Container className="toast-container">
        <Toast
          key={ts}
          className={`toast fade ${classMap[type]}`}
          aria-atomic="true"
          aria-live="assertive"
          role="alert"
          data-controller="notifications"
          data-delay={5000}
          onClose={() => {
            clearFlash();
          }}
        >
          <Toast.Body className={`toast-body ${classMap[type]}`}>
            <button
              className="ml-2 mb-1 close"
              aria-label="Close"
              data-dismiss="toast"
              type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
            <pre className="ml-2 text-light w-100">{message}</pre>
          </Toast.Body>
        </Toast>
      </Container>
    </div>
  );
};

export const FlashErrors = () => {
  const { message, type } = useContext(FlashContext);
  if (!message) {
    return null;
  }

  if (type !== FLASH_TYPE_ERROR) {
    return null;
  }

  return <pre className="text-danger">{message}</pre>;
};
