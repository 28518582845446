import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['objective', 'submitButton']

  connect () {
    const div1 = document.querySelector('.actions-button')
    if(div1 && div1.children[0] && div1.children[1]){
      const button1 = div1.children[0]
      const button2 = div1.children[1]
      button1.setAttribute('class', 'disabled btn btn-success')
      button2.setAttribute('class', 'disabled btn btn-primary mx-3')
    }
    this.block_button()
  }

  verify(e) {
    e.preventDefault()
    const causeEffectsDiv = document.querySelector('div#cause_efects');
    let textareas;
    if (causeEffectsDiv) {
      // Si el div existe, excluimos los <textarea> dentro de él
      textareas = document.querySelectorAll('textarea:not(div#cause_efects textarea)');
    } else {
      // Si el div no existe, seleccionamos todos los <textarea>
      textareas = document.querySelectorAll('textarea');
    }
    const selectProduce= document.querySelector('div #produce_field')
    const approveProduce= document.querySelector('div #approve_field')
    const input = document.querySelector('input')
    const div1 = document.querySelector('.actions-button')
    const button1 = div1.children[0] 
    const button2 = div1.children[1] 
    let firstCon = textareas[0].value.trim() === ""
    let secCon = textareas[1].value.trim() === ""
    // let thirdCon = ""
    let fifthCon = input.value.trim() === ""
    let sixthCon = selectProduce.children.length > 1
    let seventhCon = approveProduce.children.length > 1

    // let cause_efects = true
    // if  (document.querySelector('div #cause_efects')){
    //   cause_efects = false
    //   if ((textareas[2].value.trim() !== "" && textareas[3].value.trim() !== "") || textareas[4].value.trim() !== "" || textareas[5].value.trim() !== ""){
    //     cause_efects = true
    //   }
    //   thirdCon = textareas[6].value.trim() === ""
    // }
    // else{
    //   thirdCon = textareas[2].value.trim() === ""
    // }
    if (!firstCon && !secCon && !fifthCon && sixthCon && seventhCon) {
      button1.setAttribute('class', 'btn btn-success')
      button2.setAttribute('class', 'btn btn-primary mx-3')
    } else {
      button1.setAttribute('class', 'disabled btn btn-success')
      button2.setAttribute('class', 'disabled btn btn-primary mx-3')
    }
  }

  block_button(){
    const div1 = document.querySelector('.actions-button')
    if (div1){
      const button1 = div1.children[0]
      const button2 = div1.children[1]
      if (button1){
        button1.addEventListener('click', function() {
          const time = 25;
          setTimeout(function() {
            button1.disabled = true;
          }, time);
        })
      }
      if (button2){
        button2.addEventListener('click', function() {
          const time = 25;
          setTimeout(function() {
            button2.disabled = true;
          }, time);
        })
      }
    }
    const buttonReturn = document.getElementById('return')
    if (buttonReturn){
      buttonReturn.addEventListener('click', function() {
        const time = 25;
        setTimeout(function() {
          buttonReturn.disabled = true;
        }, time);
      })
    }
    const buttonReview = document.getElementById('review')
    if (buttonReview){
      buttonReview.addEventListener('click', function() {
        const time = 25;
        setTimeout(function() {
          buttonReview.disabled = true;
        }, time);
      })
    }
    const buttonApprove = document.getElementById('approve')
    if (buttonApprove){
      buttonApprove.addEventListener('click', function() {
        const time = 25;
        setTimeout(function() {
          buttonApprove.disabled = true;
        }, time);
      })
    }
    const buttonDraft = document.getElementById('draft')
    if (buttonDraft){
      buttonDraft.addEventListener('click', function() {
        const time = 25;
        setTimeout(function() {
          buttonDraft.disabled = true;
        }, time);
      })
    }
    const buttonSubmit = document.getElementById('submitButton')
    if (buttonSubmit){
      buttonSubmit.addEventListener('click', function() {
        const time = 25;
        setTimeout(function() {
          buttonSubmit.disabled = true;
        }, time);
      })
    }
    const submit_button = document.getElementById('submit_button')
    if (submit_button){
      submit_button.addEventListener('click', function() {
        const time = 25;
        setTimeout(function() {
          submit_button.disabled = true;
        }, time);
      })
    }
  }
}
