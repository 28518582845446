import { Controller }  from 'stimulus'

export default class extends Controller {

  connect () {
    $("#approve_button").hide()
    $("#check_none").hide()
    var checkbox = $( ".document-check" ).length
    if (checkbox == 0) {
      $("#check_all").hide()
    }
  }

  onSelect () {
    var checked = $( ".document-check:checkbox:checked" ).length
    if (checked) { $("#approve_button").show() }
    else { $("#approve_button").hide() }
  }

  checkAll (){
    $( ".document-check:checkbox" ).prop('checked', true);
    $("#check_all").hide()
    $("#check_none").show()
    this.onSelect()
  }

  checkNone (){
    $( ".document-check:checkbox" ).prop('checked', false);
    $("#check_none").hide()
    $("#check_all").show()
    this.onSelect()
  }
}
