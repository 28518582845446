import React from 'react'
import CardChart from './CardChartYear'
const ListData = ({data, modalControl, group, date, initialdate, finaldate}) => {
  return data.map((info) => {
    return (<React.Fragment key={info.id}>
      <CardChart key={info.id} text={info.text} color={info.color} value={info.value} modalControl={modalControl} group={group} groupid={info.id} date={date} initialdate={initialdate} finaldate={finaldate}/>
    </React.Fragment>)
  })
}

export default ListData