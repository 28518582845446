import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    $('#newConvenience').hide()
    $('#saveConvenienceButton').hide()
    $('#newAdequacy').hide()
    $('#saveAdequacyButton').hide()
    $('#newEficacy').hide()
    $('#saveEficacyButton').hide()
    $('#newAlignment').hide()
    $('#saveAlignmentButton').hide()
    $('#newOther').hide()
    $('#saveOtherButton').hide()
    $('#newOpportunityAction').hide()
    $('#saveOpportunityActionButton').hide()
    $('#newNeedAction').hide()
    $('#saveNeedActionButton').hide()
  }

  showConvenienceForm () {
    $('#newConvenience').show()
    $('#saveConvenienceButton').show()
    $('#newConvenienceButton').hide()
  }

  showAdequacyForm () {
    $('#newAdequacy').show()
    $('#saveAdequacyButton').show()
    $('#newAdequacyButton').hide()
  }

  showEficacyForm () {
    $('#newEficacy').show()
    $('#saveEficacyButton').show()
    $('#newEficacyButton').hide()
  }

  showAlignmentForm () {
    $('#newAlignment').show()
    $('#saveAlignmentButton').show()
    $('#newAlignmentButton').hide()
  }

  showOtherForm () {
    $('#newOther').show()
    $('#saveOtherButton').show()
    $('#newOtherButton').hide()
  }

  showOpportunityActionForm () {
    $('#newOpportunityAction').show()
    $('#saveOpportunityActionButton').show()
    $('#newOpportunityActionButton').hide()
  }

  showNeedActionForm () {
    $('#newNeedAction').show()
    $('#saveNeedActionButton').show()
    $('#newNeedActionButton').hide()
  }
}
