import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  static targets = ['probability', 'impact', 'detectability', 'npr', 'risk', 'action', 'achievement1', 'achievement2', 'date']

  connect () {
    this.selectName = this.achievement1Target.name
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()
    var probability = document.getElementById("probability").value
    var impact = document.getElementById("impact").value
    var valuetwo = probability * impact
    $(this.nprTarget).prop('value', valuetwo)
    document.getElementById("npr").setAttribute("value", valuetwo)

    $(this.riskTarget).prop('value', valuetwo)

    if ((Number(valuetwo) > 0) && (Number(valuetwo) < 4 )) {
      $(this.riskTarget).prop('value', 'Bajo')
      $(this.actionTarget).prop('value', 'Aceptar')

      document.getElementById("npr").style.backgroundColor='#46E7FA';
      document.getElementById("risk_evaluation_risk_level").style.backgroundColor='#46E7FA';
      document.getElementById("risk_evaluation_recommended_action").style.backgroundColor='#46E7FA';
      document.getElementById("npr").style.color='#4F4F4F';
      document.getElementById("risk_evaluation_risk_level").style.color='#4F4F4F';
      document.getElementById("risk_evaluation_recommended_action").style.color='#4F4F4F';

    } else if ((Number(valuetwo) < 7) && (Number(valuetwo) >3)) {
      $(this.riskTarget).prop('value', 'Moderado')
      $(this.actionTarget).prop('value', 'Reducir')

      document.getElementById("npr").style.backgroundColor='#FAFA46';
      document.getElementById("risk_evaluation_risk_level").style.backgroundColor='#FAFA46';
      document.getElementById("risk_evaluation_recommended_action").style.backgroundColor='#FAFA46';
      document.getElementById("npr").style.color='#4F4F4F';
      document.getElementById("risk_evaluation_risk_level").style.color='#4F4F4F';
      document.getElementById("risk_evaluation_recommended_action").style.color='#4F4F4F';

    } else if ((Number(valuetwo) < 13) && (Number(valuetwo) >6)) {
      $(this.riskTarget).prop('value', 'Importante')
      $(this.actionTarget).prop('value', 'Compartir o Mitigar')

      document.getElementById("npr").style.backgroundColor='#FFC100';
      document.getElementById("risk_evaluation_risk_level").style.backgroundColor='#FFC100';
      document.getElementById("risk_evaluation_recommended_action").style.backgroundColor='#FFC100';
      document.getElementById("npr").style.color='#4F4F4F';
      document.getElementById("risk_evaluation_risk_level").style.color='#4F4F4F';
      document.getElementById("risk_evaluation_recommended_action").style.color='#4F4F4F';

    } else if ((Number(valuetwo) < 26) && (Number(valuetwo) >12)) {
      $(this.riskTarget).prop('value', 'Inaceptable')
      $(this.actionTarget).prop('value', 'Mitigar o Eliminar')

      document.getElementById("npr").style.backgroundColor='#B42100';
      document.getElementById("risk_evaluation_risk_level").style.backgroundColor='#B42100';
      document.getElementById("risk_evaluation_recommended_action").style.backgroundColor='#B42100';
      document.getElementById("npr").style.color='white';
      document.getElementById("risk_evaluation_risk_level").style.color='white';
      document.getElementById("risk_evaluation_recommended_action").style.color='white';
    }
    if (Number(value) < 100) {
      $(this.dateTarget).prop('disabled', true)
      $(this.achievement1Target).hide()
      this.achievement1Target.name = ''
      $(this.achievement2Target).show()
      this.achievement2Target.name = this.selectName
    } else {
      $(this.dateTarget).prop('disabled', false)
      $(this.dateTarget).val(null).trigger('change')
      $(this.achievement1Target).show()
      this.achievement1Target.name = this.selectName
      $(this.achievement2Target).hide()
      this.achievement2Target.name = ''
    }
  }
  showbuttons () {
    document.getElementById('cancel_button').className = 'btn btn-link';
    document.getElementById('submit_button').className = 'btn btn-primary';
    this.onChange()
  }
}
