import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useParams, Link, useLocation } from "react-router-dom";
import {
  Button,
  Table,
  Navbar,
  Badge,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faFile } from "@fortawesome/free-solid-svg-icons";
import Header from "./Header";
import LoaderPagination from "./Skeleton/LoaderPagination";
import { AppContext } from "./Context/AppContext";
import { FLASH_TYPE_ERROR, FlashContext } from "./Context/FlashContext";

const ShowTrouble = () => {
  const [data, setData] = useState([]);
  const params = useParams();
  const { loading, setLoading } = useContext(AppContext);
  const { setFlash } = useContext(FlashContext);

  useEffect(() => {
    ShowTroubles();
  }, []);

  const ShowTroubles = async () => {
    setLoading(true);
    try {
      const url = `/api/v2/troubles/${params.id}`;
      const response = await axios.get(url);
      const data = await response.data;
      if (response.status === 200) {
        setData(data);
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      setFlash({
        type: FLASH_TYPE_ERROR,
        message: "Ocurrio un error en el servidor",
      });
      console.error("Error", error);
    } finally {
      setLoading(false);
    }
  };

  const fileSummary = data.file_summary;

  return (
    <>
      <Header />
      <Navbar className="bg-light border border-secondary">
        <Link to={`/troubles/${params.troubleType}`}>
          <Button variant="light">
            <FontAwesomeIcon icon={faArrowLeft} color="#438dff" />
          </Button>
        </Link>
        <Navbar.Brand>Detalle del Reporte </Navbar.Brand>
      </Navbar>

      {loading ? (
        <LoaderPagination />
      ) : (
        <div className="table-responsive justify-content-center align-items-center p-4">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th scope="col" style={{ textAlign: "center" }}>
                  Tipo reporte
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                  User
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                  Sede
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                  Fecha Evento
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                  Fecha Reporte
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                  Descripción
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                  Descripción Acciones
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                  Jefe Inmediato
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(data).length > 0 && (
                <tr>
                  <td style={{ textAlign: "center" }}>
                    {data && data.trouble_type}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {data.employee.user && data.employee.user.first_name}{" "}
                    {data.employee.user && data.employee.user.last_name
                      ? data.employee.user.last_name
                      : ""}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {data.branch_office && data.branch_office.name}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {data && data.date_event}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {data && data.date_report}
                  </td>
                  <td
                    style={{
                      maxWidth: "100px",
                      wordWrap: "break-word",
                    }}
                  >
                    {data && data.description}
                  </td>
                  <td
                    style={{
                      maxWidth: "100px",
                      wordWrap: "break-word",
                    }}
                  >
                    {data && data.description_actions}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {data.inmediate_boss && data.inmediate_boss.name}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {data.file_summary && (
            <div
              style={{
                border: "1px solid black",
                display: "flex",
                flexDirection: "column",
                paddingLeft: "10px",
              }}
            >
              <h5> Anexo:</h5>
              <span>{data.file_summary.name}</span>
              {fileSummary &&
              /\.(jpg|png|jpeg|gif|svg)$/i.test(fileSummary.name) ? (
                <Link to={fileSummary.url} target="_blank">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Archivos adjuntos</Tooltip>}
                  >
                    <Button variant="outline-primary" className="m-2" size="sm">
                      <FontAwesomeIcon icon={faFile} />
                    </Button>
                  </OverlayTrigger>
                </Link>
              ) : (
                <Link
                  to={`/api/v2/troubles/controlled_copy.${params.id}`}
                  target="_blank"
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Archivos adjuntos</Tooltip>}
                  >
                    <Button variant="outline-primary" className="m-2" size="sm">
                      <FontAwesomeIcon icon={faFile} />
                    </Button>
                  </OverlayTrigger>
                </Link>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ShowTrouble;
