import React, { useState } from "react";
import { LaboratoriesContext } from "../LaboratoriesContext";
import { InputGroup } from "../../InputGroup";
import { AssignmentUsers } from "../AssignmentUsers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Sampling(){
  const {
    setOpenModalSampling,
    dataLaboratory,
    updateLaboratory
  } = React.useContext(LaboratoriesContext)
  const [sourceApplication, setSoruceApplication] = useState(dataLaboratory?.source_application || '')
  const [environmentalConditions, setEnvironmentalConditions] = useState(false)
  const [applications, setApplications] = useState(dataLaboratory?. request_tests || [])
  const [sampleType, setSampleType] = useState('')
  const [descriptionApplication, setDescriptionApplication] = useState(dataLaboratory?.description_application || '')
  const [descriptionSampleCollection, setDescriptionSampleCollection] = useState(dataLaboratory?.description_sample_collection || '')
  const [sampleTaken, setSampleTaken] = useState('')
  const [numberlots, setNumberLots] = useState(dataLaboratory?.number_lots || '')
  const [unitslot, setUnitsLot] = useState(dataLaboratory?.units_lot || '')
  const [resourcesRequired, setResourcesRequired] = useState(dataLaboratory?.resources_required || '')
  const [environmentalConditionsReception, setEnvironmentalConditionsReception] = useState(dataLaboratory?.environmental_conditions_reception || '')
  const [declarationConformity, setDeclarationConformity] = useState(dataLaboratory?.declaration_conformity ||
    `- Los resultados obtenidos cumplen con los requisitos de la norma NTE INEN 2187:2011
    - Regla de decisión aplicada: Basada en la norma NTE INEN 2187:2011 y directrices del cliente.
    -Los resultados obtenidos cumplen con los requisitos de la norma RTE INEN 109 (1R)`)
  const [sampleDate, setSampleDate] = useState(dataLaboratory?.sampleDate || '')
  const [receptionLabotory, setReceptionLabotory] = useState(dataLaboratory?.reception_laboratory || '')
  const [responsibleUser, setResponsibleUser] = React.useState(
    (dataLaboratory?.assignments?.map(assignment => {
      if (assignment.action === 'responsible') {
        return {
          ...assignment,
          value: assignment.user_name?.value,
          label: assignment.user_name?.label,
          typeSendData: 'edit',
        };
      }
      return null;
    }) || []).filter(item => item !== null)
  );
  const [readUser, setReadUser] = React.useState(
    (dataLaboratory?.assignments?.map(assignment => {
      if (assignment.action === 'read') {
        return {
          ...assignment,
          value: assignment.user_name?.value,
          label: assignment.user_name?.label,
          typeSendData: 'edit',
        };
      }
      return null;
    }) || []).filter(item => item !== null)
  );
  const [disabledSubmit, setDisabledSubmit] = useState(false)
  

  const sourceApplicationData = [
    { label: 'Sitio web', value: 'Sitio web' },
    { label: 'E-mail', value: 'E-mail' },
    { label: 'Teléfono', value: 'Teléfono' },
    { label: 'Presencial', value: 'Presencial' },
    { label: 'ISWO', value: 'ISWO' },
    { label: 'Otro', value: 'Otro' }
  ]

  const checkboxHandler = (e) => {
    const isSelected = e.target.checked;
    const value = e.target.value
    setApplications(
      applications.map((application) => 
        application.id === value 
          ? { ...application, _destroy: !isSelected }
          : application
      )
    );
  };

  const checkedTest = (id) => {
    const foundTest = applications.find(test => test.id === id);
    return foundTest ? !foundTest._destroy : false;
  };

  const onUpdate = (e) => {
    e.preventDefault();
    setDisabledSubmit(true);
    const dataupdateLaboratory = {
      source_application: sourceApplication,
      meets_environmental_conditions: environmentalConditions,
      request_tests_attributes: applications.map((test) => {
        return{
          id: test.id,
          type_laboratory_test_id: test,
          _destroy: test._destroy
        }
      }),
      sample_type: sampleType,
      description_application: descriptionApplication,
      sample_taken: sampleTaken,
      number_lots: numberlots,
      units_lot: unitslot,
      resources_required: resourcesRequired,
      state: 'test_execution',
      environmental_conditions_reception: environmentalConditionsReception,
      declaration_conformity: declarationConformity,
      reception_laboratory: receptionLabotory,
      sample_date: sampleDate,
      description_sample_collection: descriptionSampleCollection,
      assignments_attributes: [
        ...responsibleUser.map((user) => ({
          id: user.typeSendData == 'edit' ? user.id : '',
          action: user.action,
          user_id: user.user_id,
          assignable_type: user.assignable_type,
          _destroy: user._destroy
        })),
        ...readUser.map((user) => ({
          id: user.typeSendData == 'edit' ? user.id : '',
          action: user.action,
          user_id: user.user_id,
          assignable_type: user.assignable_type,
          _destroy: user._destroy
        }))
      ]
    }
    setTimeout(() => {
      setDisabledSubmit(false);
    }, 4000);
    updateLaboratory(dataLaboratory.id, dataupdateLaboratory)
  }
  
  const onCancel = () => {
    setOpenModalSampling(false);
  }

  return(
    <div className="modal fade show" tabIndex="-1" style={{ paddingRight: '4px', display: 'block', backgroundColor: 'rgba(3, 3, 3, 0.5)' }} role="document">
      <form onSubmit={onUpdate}>
        <div className="modal-dialog modal-dialog-scrollable" role="document" style={{ maxWidth: '700px' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">TOMA DE MUESTRA Y ASIGNACIÓN DE RESPONABLE</h5>
            </div>
            <div className="modal-body">
              <InputGroup iconClass="far fa-file-alt" label="Fuente de la Solicitud(*)">
                <select
                  className="custom-select"
                  value={sourceApplication}
                  onChange={(e) => setSoruceApplication(e.target.value)}
                >
                  <option value="" disabled={true}>Seleccione una opción</option>
                  {sourceApplicationData.map((application, index) => (
                    <option key={index} value={application.value}>
                      {application.label}
                    </option>
                  ))}
                </select>
              </InputGroup>
              <div className="form-check form-check-inline ml-1 mt-1">
                ¿Cumple condiciones ambientales?
                <div className="ml-3 mt-2">
                  <input
                    type="radio"
                    name="EnvironmentalConditions" // Mismo name para ambos botones
                    value={true}
                    checked={environmentalConditions === true}
                    onChange={() => setEnvironmentalConditions(true)} // Cambiar a true
                  />
                  <label>Si</label>
                </div>
                <div className="ml-3 mt-2">
                  <input
                    type="radio"
                    name="EnvironmentalConditions" // Mismo name para ambos botones
                    value={false}
                    checked={environmentalConditions === false}
                    onChange={() => setEnvironmentalConditions(false)} // Cambiar a false
                  />
                  <label>No</label>
                </div>
              </div>
              <InputGroup iconClass="far fa-file-alt" label="Condiciones Ambientales a la Recepción">
                <input
                  type="text"
                  className="form-control"
                  value={environmentalConditionsReception}
                  onChange={(e) => setEnvironmentalConditionsReception(e.target.value)}
                  autoFocus
                />
              </InputGroup>
              <h6 className="mt-3">Pruebas seleccionadas:</h6>
              <div className="col-sm-4 mb-4">
                {applications.length > 0 && applications.map((application, index) => (
                  <div className="form-check form-check-inline custom-checkbox" key={index}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name={application.name}
                      value={application.id}
                      checked={ checkedTest(application.id)}
                      onChange={checkboxHandler}
                    />
                    <label className="form-check-label ml-2">
                      {application.name}
                    </label>
                  </div>
                ))}
              </div>
              <InputGroup iconClass="far fa-file-alt" label="Tipo de Muestra (*)">
                <textarea
                  value={sampleType}
                  placeholder="Escribe el tipo de muestra"
                  onChange={(e) => setSampleType(e.target.value)}
                  className="form-control"
                />
              </InputGroup>
              <InputGroup iconClass="far fa-file-alt" label="Descripción Punto Toma de Muestra">
                <textarea
                  value={descriptionSampleCollection}
                  placeholder="Escribe el tipo de muestra"
                  onChange={(e) => setDescriptionSampleCollection(e.target.value)}
                  className="form-control"
                />
              </InputGroup>
              <InputGroup iconClass="far fa-file-alt" label="Muestra tomada por: (*)">
                <input
                  type="text"
                  className="form-control"
                  value={sampleTaken}
                  onChange={(e) => setSampleTaken(e.target.value)}
                  autoFocus
                />
              </InputGroup>
              <InputGroup iconClass="far fa-file-alt" label="Descripción Solicitud">
                <textarea
                  value={descriptionApplication}
                  placeholder="Escribe el tipo de muestra"
                  onChange={(e) => setDescriptionApplication(e.target.value)}
                  className="form-control"
                />
              </InputGroup>
              <InputGroup iconClass="fas fa-building" label="Cantidad por Lotes">
                <input
                  type="number"
                  className="form-control"
                  name="Cantidad por Lotes"
                  placeholder="Cantidad por Lotes"
                  value={numberlots}
                  onChange={(e) => setNumberLots(e.target.value)}
                  autoFocus
                />
              </InputGroup>
              <InputGroup iconClass="fas fa-building" label="Unidades por Lote (*)">
                <input
                  type="number"
                  className="form-control"
                  name="Unidades por Lote"
                  placeholder="Unidades por Lote"
                  value={unitslot}
                  onChange={(e) => setUnitsLot(e.target.value)}
                  autoFocus
                />
              </InputGroup>
              <InputGroup iconClass="far fa-file-alt" label="Recursos Necesarios para cumplir con el trabajo">
                <textarea
                  value={resourcesRequired}
                  placeholder="Escribe el tipo de muestra"
                  onChange={(e) => setResourcesRequired(e.target.value)}
                  className="form-control"
                />
              </InputGroup>
              <InputGroup iconClass="far fa-file-alt" label="Declaración de Conformidad.">
                <textarea
                  value={declarationConformity}
                  placeholder="Escribe la Declaración de Conformidad."
                  onChange={(e) => setDeclarationConformity(e.target.value)}
                  className="form-control"
                />
              </InputGroup>
              <InputGroup iconClass="fas fa-calendar" label="Fecha y Hora de Toma de Muestra">
                <DatePicker
                  selected={sampleDate}
                  onChange={(date) => setSampleDate(date)}
                  showTimeSelect
                  timeIntervals={15}
                  timeFormat="HH:mm"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  placeholderText="Seleccionar fecha y hora de toma de muestra"
                  className="form-control"
                />
              </InputGroup>
              <InputGroup iconClass="fas fa-calendar" label="Fecha y Hora de Recepción en el Laboratorio">
                <DatePicker
                  selected={receptionLabotory}
                  onChange={(date) => setReceptionLabotory(date)}
                  showTimeSelect
                  timeIntervals={15}
                  timeFormat="HH:mm"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  placeholderText="Seleccionar Fecha y Hora de Recepción en el Laboratorio:"
                  className="form-control"
                />
              </InputGroup>
              
              <h6>Responsable</h6>
              <AssignmentUsers
                key={'responsible'}
                adminUser={responsibleUser}
                setAdminUser={setResponsibleUser}
                type={'responsible'}
              />
              <h6>Lee</h6>
              <AssignmentUsers
                key={'read'}
                adminUser={readUser}
                setAdminUser={setReadUser}
                type={'read'}
              />
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-link'
                onClick={onCancel}
              >
                Cancelar
              </button>
              <button
                className='btn btn-primary mr-1'
                type="submit"
                disabled={disabledSubmit}
              >
                Envíar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export { Sampling }