import React, { useState, useContext } from "react";
import GlobalContext from "../context/GlobalContext";

export default function DeleteButton() {
  const { setShowDeleteModal, eventEditId, setShowEditModal, setFlaged } =
    useContext(GlobalContext);

  const handleDelete = () => {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    fetch(`/api/v2/events/${eventEditId}`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "X-CSRF-Token": csrfToken,
      },
    }).then((response) => {
      return response.json();
    });
    setShowDeleteModal(false);
    setShowEditModal(false);
    setTimeout(() => {
      setFlaged((prevFlaged) => !prevFlaged);
    }, 100);
  };

  return (
    <div
      className="modal fade show"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="0"
      style={{
        paddingRight: "4px",
        display: "block",
        backgroundColor: "rgba(53, 53, 53, 0.5)",
        zIndex: 15,
      }}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-scrollable modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setShowDeleteModal(false)}
            ></button>
          </div>
          <div className="modal-body d-flex justify-content-center">
            <h3>¿Deseas borrar este evento?</h3>
            <button
              className="btn btn-secondary ml-3 delete-button"
              onClick={(event) => handleDelete(event)}
            >
              Sí
            </button>
            <button
              className="btn btn-secondary ml-3 delete-button"
              onClick={() => setShowDeleteModal(false)}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
