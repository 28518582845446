import React, { useEffect, useState } from "react";
import Thead from "./Thead";
import Tbody from "./Tbody";

const Table = (props) => {
  const [infoMatrix, setInfoMatrix] = useState(props?.info_matrix);
  return (
    <>
      <div className="card-header d-flex justify-content-between bg-dark position-sticky sticky-top text-white">
        <h5 className="card-title font-weight-bold mb-0 ml-xs-4">
          <a className="btn btn-link" href="/environmental_matrices">
            <i className="fas fa-arrow-left" />
          </a>
          {infoMatrix.name}
        </h5>
      </div>
      <div className="card-body p-0">
        <table className="table table-bordered table-sm table-responsive vh-100">
          <Thead infoMatrix={infoMatrix}/>
          <Tbody infoMatrix={infoMatrix}/>
        </table>
      </div>
    </>
  );
};

export default Table;