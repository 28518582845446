import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button', 'buttonone', 'buttontwo', 'buttonthree', 'buttonNA', 'buttonAction, diamond, diamond2, diamond3']

  connect () {
    this.updateSubtotals();
    this.updateTotals();
    this.updateDiamonds();
  }

  buttonOne() {
    this.onChange(0)
    $(this.buttononeTarget).prop('className', 'btn btn-outline-success btn-sm mr-1')
  }

  buttonTwo() {
    this.onChange(0.5)
    $(this.buttontwoTarget).prop('className', 'btn btn-outline-warning btn-sm mr-1')
  }

  buttonThree() {
    this.onChange(1)
    $(this.buttonthreeTarget).prop('className', 'btn btn-outline-danger btn-sm mr-1')
  }

  buttonFour() {
    this.onChange('NA')
    $(this.buttonNATarget).prop('className', 'btn btn-outline-info btn-sm mr-1')
  }

  updateTotals() {
    const people = document.querySelectorAll('.peopleSubfactor')
    const valoresPeople = Array.from(people).map(element => parseFloat(element.textContent));
    const valoresFiltrados = valoresPeople.filter(valor => !isNaN(valor));
    let suma;
    if (valoresFiltrados.length > 0) {
      suma = valoresFiltrados.reduce((acc, valor) => acc + valor, 0);
    } else {
      suma = null; // O cualquier otro valor predeterminado que desees
    }
    document.getElementById('peopleFactor').innerHTML = !isNaN(suma) ? suma : '';
    document.getElementById('vulnerability_evaluation_people').value = !isNaN(suma) ? suma : '';
    const resources = document.querySelectorAll('.resourcesSubfactor')
    const valoresResources = Array.from(resources).map(element => parseFloat(element.textContent));
    const valoresFiltrados2 = valoresResources.filter(valor => !isNaN(valor));
    let suma2;
    if (valoresFiltrados2.length > 0) {
      suma2 = valoresFiltrados2.reduce((acc, valor) => acc + valor, 0);
    } else {
      suma2 = null; // O cualquier otro valor predeterminado que desees
    }
    document.getElementById('resourcesFactor').innerHTML = !isNaN(suma2) ? suma2 : '';
    document.getElementById('vulnerability_evaluation_resources').value = !isNaN(suma2) ? suma2 : '';
    const systems = document.querySelectorAll('.systemsSubfactor')
    const valoresSystems = Array.from(systems).map(element => parseFloat(element.textContent));
    const valoresFiltrados3 = valoresSystems.filter(valor => !isNaN(valor));
    let suma3;
    if (valoresFiltrados3.length > 0) {
      suma3 = valoresFiltrados3.reduce((acc, valor) => acc + valor, 0);
    } else {
      suma3 = null; // O cualquier otro valor predeterminado que desees
    }
    document.getElementById('systemsFactor').innerHTML = !isNaN(suma3) ? suma3 : '';
    document.getElementById('vulnerability_evaluation_systems').value= !isNaN(suma3) ? suma3 : '';
    this.updateDiamonds(suma, suma2, suma3);
  }

  updateSubtotals() {
    const organizational = document.querySelectorAll('.people1')
    const valores1 = Array.from(organizational).map(element => parseFloat(element.textContent));
    const valoresFiltrados1 = valores1.filter(valor => !isNaN(valor));
    const suma = valoresFiltrados1.reduce((acc, valor) => acc + valor, 0);
    const promedio = suma / valoresFiltrados1.length;
    document.getElementById('spanOrganizational').innerHTML = !isNaN(promedio) ? promedio : '';
    const training = document.querySelectorAll('.people2')
    const valores2 = Array.from(training).map(element => parseFloat(element.textContent));
    const valoresFiltrados2 = valores2.filter(valor => !isNaN(valor));
    const suma2 = valoresFiltrados2.reduce((acc, valor) => acc + valor, 0);
    const promedio2 = suma2 / valoresFiltrados2.length;
    document.getElementById('spanTraining').innerHTML = !isNaN(promedio2) ? promedio2 : '';
    const security = document.querySelectorAll('.people3')
    const valores3 = Array.from(security).map(element => parseFloat(element.textContent));
    const valoresFiltrados3 = valores3.filter(valor => !isNaN(valor));
    const suma3 = valoresFiltrados3.reduce((acc, valor) => acc + valor, 0);
    const promedio3 = suma3 / valoresFiltrados3.length;
    document.getElementById('spanSecurity').innerHTML = !isNaN(promedio3) ? promedio3 : '';
    const supplies = document.querySelectorAll('.resources1')
    const valores4 = Array.from(supplies).map(element => parseFloat(element.textContent));
    const valoresFiltrados4 = valores4.filter(valor => !isNaN(valor));
    const suma4 = valoresFiltrados4.reduce((acc, valor) => acc + valor, 0);
    const promedio4 = suma4 / valoresFiltrados4.length;
    document.getElementById('spanSupplies').innerHTML = !isNaN(promedio4) ? promedio4 : '';
    const buildings = document.querySelectorAll('.resources2')
    const valores5 = Array.from(buildings).map(element => parseFloat(element.textContent));
    const valoresFiltrados5 = valores5.filter(valor => !isNaN(valor));
    const suma5 = valoresFiltrados5.reduce((acc, valor) => acc + valor, 0);
    const promedio5 = suma5 / valoresFiltrados5.length;
    document.getElementById('spanBuildings').innerHTML = !isNaN(promedio5) ? promedio5 : '';
    const teams = document.querySelectorAll('.resources3')
    const valores6 = Array.from(teams).map(element => parseFloat(element.textContent));
    const valoresFiltrados6 = valores6.filter(valor => !isNaN(valor));
    const suma6 = valoresFiltrados6.reduce((acc, valor) => acc + valor, 0);
    const promedio6 = suma6 / valoresFiltrados6.length;
    document.getElementById('spanTeams').innerHTML = !isNaN(promedio6) ? promedio6 : '';
    const services = document.querySelectorAll('.systems1')
    const valores7 = Array.from(services).map(element => parseFloat(element.textContent));
    const valoresFiltrados7 = valores7.filter(valor => !isNaN(valor));
    const suma7 = valoresFiltrados7.reduce((acc, valor) => acc + valor, 0);
    const promedio7 = suma7 / valoresFiltrados7.length;
    document.getElementById('spanServices').innerHTML = !isNaN(promedio7) ? promedio7 : '';
    const alternative = document.querySelectorAll('.systems2')
    const valores8 = Array.from(alternative).map(element => parseFloat(element.textContent));
    const valoresFiltrados8 = valores8.filter(valor => !isNaN(valor));
    const suma8 = valoresFiltrados8.reduce((acc, valor) => acc + valor, 0);
    const promedio8 = suma8 / valoresFiltrados8.length;
    document.getElementById('spanAlternative').innerHTML = !isNaN(promedio8) ? promedio8 : '';
    const recovery = document.querySelectorAll('.systems3')
    const valores9 = Array.from(recovery).map(element => parseFloat(element.textContent));
    const valoresFiltrados9 = valores9.filter(valor => !isNaN(valor));
    const suma9 = valoresFiltrados9.reduce((acc, valor) => acc + valor, 0);
    const promedio9 = suma9 / valoresFiltrados9.length;
    document.getElementById('spanRecovery').innerHTML = !isNaN(promedio9) ? promedio9 : '';
  }

  updateDiamonds(factor1, factor2, factor3) {
    if (factor1 === null) {
      document.querySelector('.diamond1').style.backgroundColor = '#17A2B8';
    } else {
      switch (true) {
        case factor1 >= 0 && factor1 <= 1:
          document.querySelector('.diamond1').style.backgroundColor = '#008000';
          break;

        case factor1 > 1 && factor1 <= 2:
          document.querySelector('.diamond1').style.backgroundColor = '#FFFF00';
          break;

        case factor1 > 2:
          document.querySelector('.diamond1').style.backgroundColor = '#FF0000';
          break;
      }
    }
    if (factor2 === null) {
      document.querySelector('.diamond2').style.backgroundColor = '#17A2B8';
    } else {
      switch (true){
        case factor2 >= 0 && factor2 <= 1:
          document.querySelector('.diamond2').style.backgroundColor = '#008000';
          break;

        case factor2 > 1 && factor2 <= 2:
          document.querySelector('.diamond2').style.backgroundColor = '#FFFF00';
          break;

        case factor2 > 2:
          document.querySelector('.diamond2').style.backgroundColor = '#FF0000';
          break;
      }
    }
    if (factor3 === null) {
      document.querySelector('.diamond3').style.backgroundColor = '#17A2B8';
    } else {
      switch (true) {
        case factor3 >= 0 && factor3 <= 1:
          document.querySelector('.diamond3').style.backgroundColor = '#008000';
          break;

        case factor3 > 1 && factor3 <= 2:
          document.querySelector('.diamond3').style.backgroundColor = '#FFFF00';
          break;

        case factor3 > 2:
          document.querySelector('.diamond3').style.backgroundColor = '#FF0000';
          break;
      }
    }
  }

  onChange(value) {
    this.clearClass()
    const url = this.data.get('url')
    const level = this.data.get('level')
    $.post(url+'?value='+value).then((content) => {
      document.getElementById('spanreq'+ level).innerHTML = content
      this.updateSubtotals();
      this.updateTotals();
    })
  }

  clearClass() {
    $(this.buttononeTarget).prop('className', 'btn btn-success btn-sm mr-1')
    $(this.buttontwoTarget).prop('className', 'btn btn-warning btn-sm mr-1')
    $(this.buttonthreeTarget).prop('className', 'btn btn-danger btn-sm mr-1')
    $(this.buttonNATarget).prop('className', 'btn btn-info btn-sm mr-1')
  }
}
