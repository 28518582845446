import { Controller }  from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'check', 'clocks', 'totalClock']
  connect() {
    this.check()
  }

  onChange(e) {
    this.inputTarget.value = this.checkTarget.checked
    if (this.checkTarget.checked){
      $(this.clocksTarget).hide()
      $(this.totalClockTarget).show()
    }
    else{
      $(this.clocksTarget).show()
      $(this.totalClockTarget).hide()
    }
  }

  check(e) {
    if (this.inputTarget.value=='true'){
      this.checkTarget.checked = true
      $(this.clocksTarget).hide()
      $(this.totalClockTarget).show()
    }
    else{
      this.checkTarget.checked = false
      $(this.clocksTarget).show()
      $(this.totalClockTarget).hide()
    }
  }

}