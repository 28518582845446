import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import GlobalContext from "../../context/GlobalContext";
import EventDay from "./Event";

export default function PersonalEvents({ events, flag, setFlag }) {
  const {
    selectedDate,
    setShowModal,
    setShowEditModal,
    setEventEditId,
    flaged,
  } = useContext(GlobalContext);
  const [matrix, setMatrix] = useState([]);
  const [periodicities, setPeriodicities] = useState([]);
  const [periodicitiesLoaded, setPeriodicitiesLoaded] = useState(false);
  const numCols = 6; // Número de columnas
  const numRows = events.length;
  const options = [
    { value: "completed", label: "Hecho", color: "#97E7B9" },
    { value: "in_progress", label: "En Proceso", color: "#FBB446" },
    { value: "incomplete", label: "No Realizado", color: "#E91E63" },
    {
      value: "without_status",
      label: "Sin seguimiento",
      color: "rgb(250, 250, 70)",
    },
  ];
  function getColorFromFinding(finding) {
    const option = options.find((option) => option.value === finding);
    return option ? option.color : null;
  }

  useEffect(() => {
    fetch("/api/v2/periodicities/")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setPeriodicities(data);
        setPeriodicitiesLoaded(true);
      });
  }, [flag, numRows, events]);

  useEffect(() => {
    if (flag && periodicitiesLoaded) {
      // Ordenar los eventos por fecha de inicio
      const sortedEvents = [...events].sort((a, b) => {
        const startDateA = new Date(a.start_date);
        const startDateB = new Date(b.start_date);
        return startDateA - startDateB;
      });

      const newMatrix = Array.from({ length: numRows }, () =>
        Array(numCols).fill("")
      );

      sortedEvents.forEach((event, index) => {
        const organizerId = index + 1;
        const title = event.title;
        const startDate = dayjs(event.start_date).format("MM-DD HH:mm");
        const endDate = dayjs(event.end_date).format("MM-DD HH:mm");
        const periodicity =
          periodicities.find((p) => p.id === event.periodicity_id)?.name || "";
        const eventOn = true;
        const firstFollow = event.event_follows[event.event_follows.length - 1];
        const finding = firstFollow ? firstFollow.follow_status : null;
        const id = event.id;

        newMatrix[index] = [
          eventOn,
          organizerId,
          title,
          startDate,
          endDate,
          periodicity,
          id,
          finding,
        ];
      });

      setMatrix(newMatrix);
      setFlag(false);
    }
  }, [flag, numRows, events, flaged, periodicities]);

  function handleEvent(eventOn, id) {
    if (eventOn !== true) {
      setShowModal(true);
    } else {
      setShowEditModal(true);
      setEventEditId(id);
    }
  }

  return (
    <div className="d-flex flex-column container">
      {matrix.length === 0 ? (
        <div
          className="text-center my-4 border cursor-pointer"
          onClick={() => handleEvent(false)}
        >
          <p>No hay eventos ni Tareas. Crea un evento nuevo.</p>
        </div>
      ) : (
        <>
          <div className="row">
            <p className="col-1 h5 mt-1 text-center">No</p>
            <p className="col-5 h5 mt-1 text-center">Titulo</p>
            <p className="col-2 h5 mt-1 text-center">Fecha Inicio</p>
            <p className="col-2 h5 mt-1 text-center">Fecha Final</p>
            <p className="col-2 h5 mt-1 text-center">Periodicidad</p>
          </div>
          {events.map((event, index) => {
            if (matrix.length > 0 && matrix[index]) {
              return (
                <div
                  className="cursor-pointer"
                  key={event.id}
                  onClick={() =>
                    handleEvent(matrix[index][0], matrix[index][6])
                  }
                >
                  <EventDay
                    number={matrix[index][1]}
                    title={matrix[index][2]}
                    startDate={matrix[index][3]}
                    endDate={matrix[index][4]}
                    periodicity={matrix[index][5]}
                    color={getColorFromFinding(matrix[index][7])}
                  />
                </div>
              );
            }
            return null;
          })}
        </>
      )}
    </div>
  );
}
