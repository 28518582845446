import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    $('#newRelevanIssue').hide()
    $('#saveIssueButton').hide()
    $('#newClientSatisfaction').hide()
    $('#saveClientButton').hide()
    $('#saveSupplierButton').hide()
    $('#newExternalSuppliers').hide()
    $('#newReport').hide()
    $('#saveReportButton').hide()
    $('#newExternal').hide()
    $('#saveExternalButton').hide()
    $('#newExternalAction').hide()
    $('#saveExternalActionButton').hide()
    $('#newNonConformities').hide()
    $('#saveNonConformitiesButton').hide()
    $('#newAdquacyResources').hide()
    $('#saveAdquacyResourcesButton').hide()
    $('#OpportunitySaveButton').hide()
    $('#NeedActionSaveButton').hide()
  }

  showIssueForm () {
    $('#newRelevanIssue').show()
    $('#saveIssueButton').show()
    $('#newIssueButton').hide()
  }

  showClientForm () {
    $('#newClientSatisfaction').show()
    $('#saveClientButton').show()
    $('#newClientButton').hide()
  }

  showSupplierForm () {
    $('#newExternalSuppliers').show()
    $('#saveSupplierButton').show()
    $('#newSupplierButton').hide()
  }

  showReportForm () {
    $('#newReport').show()
    $('#saveReportButton').show()
    $('#newReportButton').hide()
  }

  showExternalForm () {
    $('#newExternal').show()
    $('#saveExternalButton').show()
    $('#newExternalButton').hide()
  }

  showExternalActionForm () {
    $('#newExternalAction').show()
    $('#saveExternalActionButton').show()
    $('#newExternalActionButton').hide()
  }

  showNonConformitiesForm () {
    $('#newNonConformities').show()
    $('#saveNonConformitiesButton').show()
    $('#newNonConformitiesButton').hide()
  }

  showAdquacyResourcesForm () {
    $('#newAdquacyResources').show()
    $('#saveAdquacyResourcesButton').show()
    $('#newAdquacyResourcesButton').hide()
  }
}
