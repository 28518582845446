import { useState, useEffect } from "react";
import axios from "axios";
import retry from "retry";
import * as Sentry from "@sentry/react";

const operationOpts = {
  retries: 10,
  factor: 3,
  minTimeout: 1 * 1000,
  maxTimeout: 60 * 1000,
  randomize: true,
};

export const useDocument = (documentId) => {
  const [url, setUrl] = useState();

  const endpoint = `/api/v2/documents/${documentId}/attached-file`;

  useEffect(() => {
    const getAttachedFile = async () => {
      const operation = retry.operation(operationOpts);

      operation.attempt(async (currentAttempt) => {
        try {
          const { data } = await axios.get(
            `${endpoint}?attempt=${currentAttempt}`
          );

          if (!data.attached) {
            operation.retry(
              new Error(`File ${documentId} is not generated yet`)
            );
            return;
          }

          setUrl(data.url);
        } catch (error) {
          if (operation.retry(error)) {
            Sentry.captureException(error);
            return;
          }
        }
      });
    };
    getAttachedFile();
  }, [documentId]);

  return [url];
};
