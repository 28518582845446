import React, { useEffect, useState } from 'react'
import Chart from './Chart'
const CardChart = (props) => {
  const { text, value, color, modalControl, parentType, parentId } = props

  const setModal = () => {
    if (parentType !== 'indicator') {
      modalControl(true, parentType, parentId, text)
    }
  }

  return (
    <li className='list-group-item list-group-item-action d-flex justify-content-between align-items-center cursor-pointer' onClick={()=>setModal()}>
      <div className='w-50'>{text}</div>
      <div className='text-center d-flex justify-content-between no-pointer align-items-center'>
        <Chart
          progress={value}
          size={60}
          fontSize={'12px'}
          strokeWidth={4}
          circleTwoStroke={color}
        />
      </div>
    </li>
  )
}

export default CardChart