import { Doughnut } from 'react-chartjs-2'
import { Controller }  from 'stimulus'


export default class extends Controller {

  static targets = ['answer', 'answerA', 'answerB', 'input', 'check']
  onChange ()
  {
    if (document.getElementById('buttonReturn') !== null){
      document.getElementById('buttonReturn').classList.add('disabled')
    }
    if(document.getElementById('buttonsubmit') !== null){
      document.getElementById('buttonsubmit').classList.add('disabled')
    }
  }
}
