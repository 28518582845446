import React, {useState} from "react";
import { LaboratoriesContext } from "../LaboratoriesContext";

function SendAndClose(){
  const {
    setOpenModalSendAndClose,
    dataLaboratory,
    updateLaboratory
  } = React.useContext(LaboratoriesContext)
  const [disabledSubmit, setDisabledSubmit] = useState(false)

  const onUpdateState = (e) => {
    e.preventDefault()
    setDisabledSubmit(true);
    const updateData = {
      state: "closed",
    }
    setTimeout(() => {
      setDisabledSubmit(false);
    }, 4000);
    updateLaboratory(dataLaboratory.id, updateData)
  }

  const onCancel = () => {
    setOpenModalSendAndClose(false);
  }
  return(
    <div className="modal fade show" tabIndex="-1" style={{ paddingRight: '4px', display: 'block', backgroundColor: 'rgba(3, 3, 3, 0.5)' }} role="document">
      <form onSubmit={onUpdateState}>
        <div className="modal-dialog modal-dialog-scrollable" role="document" style={{ maxWidth: '700px' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">ENVÍO DE INFORME Y CIERRE</h5>
            </div>
            <div className="modal-body">
              <p>Presione en envíar para hacer el respectivo envío del informe y el cierre de la solicitud de laboratoraio</p>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-link'
                onClick={onCancel}
              >
                Cancelar
              </button>
              <button
                className='btn btn-primary mr-1'
                type="submit"
                disabled={disabledSubmit}
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export { SendAndClose }