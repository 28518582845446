import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  static targets = ['truncatedText', 'completeText']

  showAll (e) {
    this.truncatedTextTarget.style.display = 'none'
    this.completeTextTarget.style.display = 'block'
  }

  showTruncated () {
    $(this.truncatedTextTarget).show()
    $(this.completeTextTarget).hide()
  }
}
