import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  faCalendarDays,
  faShareNodes,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Card, Nav, Button, ListGroup, Row, Col, Form } from "react-bootstrap";
import Header from "./Header";
import Table from "./Table";
import GraphicsMain from "./Graphics/GraphicsMain";
import GraphicsSecondary from "./Graphics/GraphicsSecondary";

const BusinessAnalytics = () => {
  const [startDateYear, setStartDateYear] = useState(new Date());
  const [startDateMonth, setStartDateMonth] = useState(new Date());
  const [summary, setSummary] = useState([]);
  const [taps, setTaps] = useState("Accidente");
  const [changeValue, setChangeValue] = useState(
    "3e16d32b-7baf-4047-a9de-7b654009fef0"
  );
  const [branch, setBranch] = useState([]);

  useEffect(() => {
    fetchSummary();
  }, []);
  const fetchSummary = async () => {
    try {
      const url = "/api/v2/trouble_summaries";
      const response = await axios.get(url);
      const data = response.data;
      setSummary(data);
    } catch (error) {
      throw Error(error);
    }
  };

  useEffect(() => {
    fetchBranchOffices();
  }, []);

  const fetchBranchOffices = async () => {
    try {
      const url = "/api/v2/branch_offices";
      const response = await axios.get(url);
      const data = response.data;
      setBranch(data);
    } catch (error) {}
  };

  const getTotal = (type) => {
    const types = type;
    const year = startDateYear.getFullYear();
    const month = startDateMonth.getMonth() + 1;
    const keySearch = `${types} ${year} ${month}`;

    if (summary && summary.count_by_year_and_type) {
      const valueSearch = summary.count_by_year_and_type[keySearch];
      if (valueSearch !== undefined) {
        return valueSearch;
      } else {
        return 0;
      }
    }
  };

  const getYear = (type) => {
    const types = type;
    const year = startDateYear.getFullYear();
    const keySearch = `${types} ${year}`;

    if (summary.count_by_year && summary.count_by_year[keySearch]) {
      return summary.count_by_year[keySearch];
    } else {
      return 0;
    }
  };

  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const month = months[startDateMonth.getMonth()];
  const year = startDateYear.getFullYear();

  const getValueGraphc = (branch_office) => {
    const branch = branch_office;
    const year = startDateYear.getFullYear();
    const values = {};

    if (summary && summary.branch_offices) {
      for (let month = 0; month <= 12; month++) {
        const keySearch = `${branch} ${year} ${month + 1}`;
        const value = summary.branch_offices[keySearch];

        values[months[month]] = value || 0;
      }
    } else {
      return 0;
    }
    return values;
  };

  const valuesMonthGraphc = getValueGraphc(changeValue);

  const listItems = months.map((month) => {
    const value = valuesMonthGraphc[month];

    return (
      <ListGroup.Item
        key={month}
        style={{ display: "grid ", justifyItems: "center" }}
      >
        <div>{month}</div>
        <div>{value}</div>
      </ListGroup.Item>
    );
  });

  const effective = "a74ecec6-b630-4676-a828-4f8c7cfc2556";
  const ineffective = "9107249d-2692-45f5-8e7f-d1d9591e904b";
  const inProgress = "5056daac-92cf-42d5-bc39-fa38932aeaba";

  const managed = () => {
    if (summary && summary.count_achievement) {
      const effectiveValue = summary.count_achievement[effective];
      const ineffectiveValue = summary.count_achievement[ineffective];
      const inProgress = summary.count_achievement[inProgress];

      if (effectiveValue !== undefined && ineffectiveValue !== undefined) {
        const value = effectiveValue + ineffectiveValue;
        return value;
      } else if (effectiveValue !== undefined) {
        return effectiveValue;
      } else if (ineffectiveValue !== undefined) {
        return ineffectiveValue;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  const toManage = () => {
    if (summary && summary.count_achievement) {
      const value = summary.count_achievement[inProgress];
      return value;
    } else {
      return 0;
    }
  };

  const managedValue = managed();
  const toManageValue = toManage();

  const calculatePercentage = (value) => {
    const valueTotal = summary.all_follows;
    const calculate = (value / valueTotal) * 100;
    return calculate.toFixed(2);
  };

  return (
    <>
      <Header
        title={<h2>Business Analytics</h2>}
        iconBack={
          <Link to="/troubles" className="pr-3">
            <Button variant="bg-dark">
              <FontAwesomeIcon icon={faArrowLeft} color="#438dff" />
            </Button>
          </Link>
        }
      />

      <Card>
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey={"Accidente"}>
            <Nav.Item>
              <Nav.Link
                data-filter-type="Accidente"
                eventKey="Accidente"
                onClick={() => setTaps("Accidente")}
              >
                Accidentes
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                data-filter-type="Incidente"
                eventKey="Incidente"
                onClick={() => setTaps("Incidente")}
              >
                Incidentes
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                data-filter-type="Emergencia"
                eventKey="Emergencia"
                onClick={() => setTaps("Emergencia")}
              >
                Emergencias
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                data-filter-type="Planes"
                eventKey="Planes"
                onClick={() => setTaps("plan")}
              >
                Planes de acción
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
      </Card>

      <Table
        businessAnalytics={
          taps !== "plan" && (
            <>
              <div
                className=" d-flex justify-content-around  "
                style={{ marginTop: "40px", marginBottom: "40px" }}
              >
                <div>
                  <h5>General</h5>
                  <Card
                    bg={"light"}
                    text={"dark"}
                    style={{ width: "500px", display: "grid" }}
                    className="mb-2 position-relative "
                  >
                    <Card.Header
                      className=" position-absolute "
                      style={{
                        background: "none",
                        border: 0,
                        gap: "10px",
                        justifySelf: "center",
                      }}
                    >
                      <Row>
                        <Col style={{ display: "flex", gap: "10px" }}>
                          <DatePicker
                            selected={startDateYear}
                            onChange={(date) => setStartDateYear(date)}
                            showYearPicker
                            dateFormat="yyyy"
                          />
                          <FontAwesomeIcon
                            icon={faCalendarDays}
                            style={{ paddingTop: "7px" }}
                          />
                        </Col>
                        <Col>
                          <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>Sedes</Form.Label>
                            <Form.Control
                              as="select"
                              defaultValue="Pasto"
                              onChange={(e) => setChangeValue(e.target.value)}
                            >
                              {branch.map((bch) => (
                                <option key={bch.id} value={bch.id}>
                                  {bch.name}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body
                      className=" d-flex flex-column  align-items-center justify-content-center"
                      style={{ paddingTop: "124px" }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                        <p>{getYear(taps)}</p>
                      </span>
                      <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                        Total {taps} año {year}
                      </span>
                    </Card.Body>
                  </Card>
                </div>
                <Card
                  bg={"light"}
                  text={"dark"}
                  style={{
                    width: "500px",
                    maxHeight: "calc(498px - 80px) ",
                    marginTop: "30px",
                  }}
                  className="mb-2 position-relative "
                >
                  <Card.Header
                    className=" position-absolute "
                    style={{
                      right: 0,
                      background: "none",
                      border: 0,
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <DatePicker
                      selected={startDateMonth}
                      onChange={(date) => setStartDateMonth(date)}
                      dateFormat="MM"
                      showMonthYearPicker
                      showFullMonthYearPicker
                    />
                    <FontAwesomeIcon
                      icon={faCalendarDays}
                      style={{ paddingTop: "7px" }}
                    />
                  </Card.Header>
                  <Card.Body
                    className=" d-flex flex-column  align-items-center justify-content-center"
                    style={{ paddingTop: "124px" }}
                  >
                    <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                      {getTotal(taps)}
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                      Total {taps} Mes {month}
                    </span>
                  </Card.Body>
                </Card>
              </div>
              <div
                style={{
                  display: "grid",
                  justifyItems: "center",
                  marginBottom: "10px",
                }}
              >
                <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                  Por Sedes y Mes Año {year}
                </span>
                <h6 style={{ fontWeight: "lighter", fontSize: "15px" }}>
                  Nariño
                </h6>
                <ListGroup horizontal={"xl"}>{listItems}</ListGroup>
                <div className=" d-flex w-75 justify-content-center  ">
                  <GraphicsMain list={valuesMonthGraphc} months={months} />
                </div>
              </div>
            </>
          )
        }
        businessAnalyticsPlans={
          taps === "plan" && (
            <>
              <div style={{ padding: "40px 20px" }}>
                <Row>
                  <Col>
                    <div style={{ marginTop: "40px" }}>
                      <Card
                        bg={"light"}
                        text={"dark"}
                        className="mb-2 position-relative "
                      >
                        <Card.Header
                          className=" position-absolute "
                          style={{ right: 0, background: "none", border: 0 }}
                        >
                          <FontAwesomeIcon icon={faShareNodes} />
                        </Card.Header>
                        <Card.Body
                          className=" d-flex flex-column  align-items-center justify-content-center"
                          style={{ marginTop: "60px" }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "27px" }}
                          >
                            {summary.all_plans}
                          </span>
                          <span
                            style={{ fontWeight: "lighter", fontSize: "17px" }}
                          >
                            Planes de mejora
                          </span>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className=" d-flex justify-content-between  ">
                      <Card
                        bg={"light"}
                        text={"dark"}
                        className="mb-2 position-relative "
                      >
                        <Card.Header
                          className=" position-absolute "
                          style={{ right: 0, background: "none", border: 0 }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "22px" }}
                            className="text-primary"
                          >
                            {calculatePercentage(toManageValue)} %
                          </span>
                        </Card.Header>
                        <Card.Body
                          className=" d-flex flex-column  align-items-center justify-content-center"
                          style={{ marginTop: "60px" }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "27px" }}
                          >
                            {toManageValue}
                          </span>
                          <span
                            style={{ fontWeight: "lighter", fontSize: "17px" }}
                          >
                            Acciones Abiertas
                          </span>
                        </Card.Body>
                      </Card>
                      <Card
                        bg={"light"}
                        text={"dark"}
                        className="mb-2 position-relative "
                      >
                        <Card.Header
                          className=" position-absolute "
                          style={{ right: 0, background: "none", border: 0 }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "22px" }}
                            className="text-primary"
                          >
                            {calculatePercentage(managedValue)} %
                          </span>
                        </Card.Header>
                        <Card.Body
                          className=" d-flex flex-column  align-items-center justify-content-center"
                          style={{ marginTop: "60px" }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "27px" }}
                          >
                            {managedValue}
                          </span>
                          <span
                            style={{ fontWeight: "lighter", fontSize: "17px" }}
                          >
                            Acciones Cerradas
                          </span>
                        </Card.Body>
                      </Card>
                      <Card
                        bg={"light"}
                        text={"dark"}
                        className="mb-2 position-relative "
                      >
                        <Card.Header
                          className=" position-absolute "
                          style={{ right: 0, background: "none", border: 0 }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "22px" }}
                            className="text-primary"
                          >
                            100 %
                          </span>
                        </Card.Header>
                        <Card.Body
                          className=" d-flex flex-column  align-items-center justify-content-center"
                          style={{ marginTop: "60px" }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "27px" }}
                          >
                            {summary.all_follows}
                          </span>
                          <span
                            style={{ fontWeight: "lighter", fontSize: "17px" }}
                          >
                            Acciones Totales
                          </span>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                  <Col
                    className=" d-flex w-50 justify-content-center "
                    style={{ marginTop: "40px" }}
                  >
                    <GraphicsSecondary
                      toManageValue={toManageValue}
                      managedValue={managedValue}
                    />
                  </Col>
                </Row>
              </div>
            </>
          )
        }
      />
    </>
  );
};

export default BusinessAnalytics;
