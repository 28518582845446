import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  static targets = ['deficiency']

  connect () {
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()
    var deficiency = document.getElementById('deficiency').value
    var exposition = document.getElementById('exposition').value
    var consequence = document.getElementById('consequence_level').value
    var value = deficiency * exposition
    var consequenceLevel = consequence * value
    document.getElementById('risk_level_text').innerHTML = consequenceLevel
    document.getElementById('risk_level').setAttribute('value', consequenceLevel)
    document.getElementById('probability_level_text').innerHTML = value
    document.getElementById('probability').setAttribute('value', value)
    if ((value >= 0) && (value <= 4)) {
      document.getElementById('probability_interpretation_text').innerHTML = 'Bajo'
      document.getElementById('probability_interpretation').setAttribute('value', 'Bajo')
    } else if ((Number(value) > 4) && (Number(value) <= 8)) {
      document.getElementById('probability_interpretation_text').innerHTML = 'Medio'
      document.getElementById('probability_interpretation').setAttribute('value', 'Medio')
    } else if ((Number(value) > 8) && (Number(value) <= 20)) {
      document.getElementById('probability_interpretation_text').innerHTML = 'Alto'
      document.getElementById('probability_interpretation').setAttribute('value', 'Alto')
    } else if ((Number(value) > 20) && (Number(value) <= 40)) {
      document.getElementById('probability_interpretation_text').innerHTML = 'Muy Alto'
      document.getElementById('probability_interpretation').setAttribute('value', 'Muy Alto')
    }

    if ((consequenceLevel >= 0) && (consequenceLevel <= 20)) {
      $('#risk_level_text').css('background-color', '#009788')
      $('#acceptability_text').css('background-color', '#009788')
      $('#risk_interpretation_text').css('background-color', '#009788')
      document.getElementById('risk_interpretation_text').innerHTML = 'IV: Mantener Medio Existente'
      document.getElementById('risk_interpretation').setAttribute('value', 'IV: Mantener Medio Existente')
      document.getElementById('acceptability_text').innerHTML = 'IV: ACEPTABLE'
      document.getElementById('acceptability').setAttribute('value', 4)
    } else if ((Number(consequenceLevel) > 20) && (Number(consequenceLevel) <= 120)) {
      $('#risk_level_text').css('background-color', '#05942B')
      $('#acceptability_text').css('background-color', '#05942B')
      $('#risk_interpretation_text').css('background-color', '#05942B')
      document.getElementById('risk_interpretation_text').innerHTML = 'III: Mejorar si es Posible'
      document.getElementById('risk_interpretation').setAttribute('value', 'III: Mejorar si es Posible')
      document.getElementById('acceptability_text').innerHTML = 'III: MEJORABLE'
      document.getElementById('acceptability').setAttribute('value', 3)
    } else if ((Number(consequenceLevel) > 120) && (Number(consequenceLevel) <= 500)) {
      $('#risk_level_text').css('background-color', '#E47923')
      $('#acceptability_text').css('background-color', '#E47923')
      $('#risk_interpretation_text').css('background-color', '#E47923')
      document.getElementById('risk_interpretation_text').innerHTML = 'II: Inmediato'
      document.getElementById('risk_interpretation').setAttribute('value', 'II: Inmediato')
      document.getElementById('acceptability_text').innerHTML = 'II: NO ACEPTABLE con control específico'
      document.getElementById('acceptability').setAttribute('value', 2)
    } else if ((Number(consequenceLevel) > 500) && (Number(consequenceLevel) <= 4000)) {
      $('#risk_level_text').css('background-color', '#EC1D63')
      $('#acceptability_text').css('background-color', '#EC1D63')
      $('#risk_interpretation_text').css('background-color', '#EC1D63')
      document.getElementById('risk_interpretation_text').innerHTML = 'I: Urgente'
      document.getElementById('risk_interpretation').setAttribute('value', 'I: Urgente')
      document.getElementById('acceptability_text').innerHTML = 'I: NO ACEPTABLE'
      document.getElementById('acceptability').setAttribute('value', 1)
    }
  }
}
