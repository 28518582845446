import React from "react";
import Select from 'react-select'

function AdminUsers({id, value, label, employees, SelectUserAssignment, removeAssigmentAdmin}){
  const options = employees.map(employee => {
    const [label, value] = employee;
    return { label, value };
  })
  return(
    <div className="form-row">
      <div className='input-group mb-3'>
        <Select
          options={options}
          name="[user_id]"
          value={value}
          placeholder={label}
          onChange={SelectUserAssignment(id)}
        />
        <div className='input-group-append'>
          <div className='input-group-text bg-white border-0'>
            <a type="button" 
              className="text-dark"
              onClick={() => removeAssigmentAdmin(id)}>
              <span className='fas fa-times cursor-pointer'></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export { AdminUsers }