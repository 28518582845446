import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { LoadingSpinner } from '../../LoadingSpinner';

function BusinessAnalytics(props) {
  const [laboratory, setLaboratory] = useState([]);
  const [typeRequest, setTypeRequest] = useState([])
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingType, setLoadingType] = useState(true);
  const canvasRef = useRef(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const url = `/api/v2/laboratories/bussiness_analytics?portal_id=${props.portal.id}`;
        const response = await axios.get(url);
        setLaboratory(response.data);
        setLoading(false)
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    getData();

    return () => {
      setError(null);
    };
  }, []);


  useEffect(() => {
    const getData = async () => {
      try {
        const url = `/api/v2/laboratories/percentaje_request?portal_id=${props.portal.id}`;
        const response = await axios.get(url);
        setTypeRequest(response.data);
        setLoadingType(false)
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    getData();

    return () => {
      setError(null);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="card-header card-sm d-flex justify-content-between bg-dark text-white position-sticky sticky-top">
        <h5>Business Analytics / Laboratorio</h5>
      </div>
      <div className="card-body p-1">
        <div className='d-flex justify-content-center mt-5'>
          <div className="col-12 col-md-6 text-center">
            <h5>Gráfica de solicitudes de Laboratorio</h5>
            {loading && <LoadingSpinner/>}
            {laboratory.length > 0 &&
              <canvas
                id="calibration-areas-chart"
                data-controller="summary-chart"
                data-points={JSON.stringify(laboratory)}
              />
            }
          </div>
        </div>
        <div className='d-flex justify-content-center mt-5'>
          <div className="col-12 col-md-6 text-center">
            <h5>Gráfica tipos de pruebas de laboratorio</h5>
            {loadingType && <LoadingSpinner/>}
            {typeRequest.length > 0 &&
              <canvas
                id="calibration-areas-chart"
                data-controller="summary-chart"
                data-points={JSON.stringify(typeRequest)}
              />
            }

            
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default BusinessAnalytics