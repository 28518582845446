import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    // const barra = document.getElementById('flotante');
    // barra.classList.add('oculto')
    $('.header-menu').click(function (e) {
      e.preventDefault()
      $('.general-section').slideUp(200)

      if ($(this).hasClass('active')) {
        $(this).removeClass('active')
        $('.header-menu').removeClass('active')
      } else {
        $('.header-menu').removeClass('active')
        $(this).addClass('active')
        $(`.${this.id}`).slideDown(200)
      }
    })

    $('.sidebar-dropdown > a').click(function (e) {
      e.preventDefault()
      $('.sidebar-submenu').slideUp(100)

      if ($(this).parent().hasClass('active')) {
        $('.sidebar-dropdown').removeClass('active')
        $(this).parent().removeClass('active')
      } else {
        $('.sidebar-dropdown').removeClass('active')
        $(this).next('.sidebar-submenu').slideDown(100)
        $(this).parent().addClass('active')
      }
    })

    $('#close-sidebar').click(function (e) {
      e.preventDefault()

      $('.page-wrapper').removeClass('toggled')
    })

    $('#show-sidebar').click(function (e) {
      e.preventDefault()

      $('.page-wrapper').addClass('toggled')
    })

    const defaultSection = $('[data-sidebar-section]')[0]
    const defaultMenu = $('[data-sidebar-menu]')[0]

    if (defaultSection && defaultSection.dataset) {
      const { sidebarSection } = defaultSection.dataset
      $(`#${sidebarSection}`).trigger('click')
    }

    if (defaultMenu && defaultMenu.dataset) {
      const { sidebarMenu } = defaultMenu.dataset
      $(`#${sidebarMenu} > a`).trigger('click')
    }

    if (typeof (Storage) !== 'undefined') {
      const bar = document.getElementById('flotante');
      const saved = localStorage.getItem('sidebar')
      if (saved === 'visible') {
        $('#pageWrapper').addClass('toggled')
        bar.classList.remove('oculto');
      }
      else
      {
        bar.classList.add('oculto');
      }
    }
  }

  hideSidebar () {
    const bar = document.getElementById('flotante');
    bar.classList.add('oculto');
    if (typeof (Storage) !== 'undefined') {
      localStorage.setItem('sidebar', 'hidden')
    }
  }

  showSidebar () {
    const bar = document.getElementById('flotante');
    bar.classList.remove('oculto');
    if (typeof (Storage) !== 'undefined') {
      localStorage.setItem('sidebar', 'visible')
    }
  }
}
