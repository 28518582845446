import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["grade"]

  connect () {
    this.row = document.getElementsByClassName("tbody-rows");
    this.sendButton = document.getElementsByClassName("submit-button");
    const trs = this.row[0].querySelectorAll("tr");
    const audited = this.row.length;
    this.skills = trs.length - 1;
    const totalSkills = this.skills * audited;
    this.array = new Array(totalSkills).fill(0);
    this.subtotals = new Array(audited).fill(0);
    this.rb = document.getElementsByClassName("radio-buttons-grade");
    for (let j = 0; j < this.rb.length; j++) {
      const opciones = this.rb[j].querySelectorAll("input[type='radio']");
      for (let i = 0; i < opciones.length; i++) {
        if (opciones[i].checked) {
          this.array[j]=parseInt(opciones[i].value)
        }
      }
    }
    let verifyArray = this.array.every(value => value !== 0);
    if (verifyArray && this.sendButton[0] !== undefined) this.sendButton[0].hidden = false
  }

  onSelect (e) {
    e.preventDefault();
    const words = e.target.id.split("_");
    const position = parseInt(words[5]);
    const value = parseInt(words[7]);
    this.array[position] = value;
    let subTotales = [];
    for (let i = 0; i < this.subtotals.length; i++) {
      let subtotal = this.array.slice(i * this.skills, (i + 1) * this.skills );
      let sumaSubtotal = subtotal.reduce((a,b) => a+b);
      let promedio = (sumaSubtotal / this.skills).toFixed(2);
      let promedioNumerico = parseFloat(promedio);
      subTotales.push(promedioNumerico);
    }
    let selectedRows = [];
    for (let i = 0; i < this.subtotals.length; i++) {
      let row = document.querySelector(`[name="auditor_evaluation[auditor_subtotals_attributes][${i}][subtotal_score]"]`);
      selectedRows.push(row);
    }
    for (let i = 0; i < this.subtotals.length; i++) {
      selectedRows[i].value = subTotales[i];
    }
    let verifyArray = this.array.every(value => value !== 0);
    if (verifyArray) this.sendButton[0].hidden = false
  }
}
