import { Controller }  from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  static targets = ['survey_theme']
  connect() {
    var prove = document.getElementById("prove");
    prove.style.background = '#6C757D'
    prove.style.color = "#f8f9fa"
    this.checkValues()
    this.onChange()
  }

  checkValues(e){
    var prove = document.getElementById("prove");
    var survey_theme_a = document.getElementById("survey_theme_a");
    var survey_theme_b = document.getElementById("survey_theme_b");
    var survey_theme_c = document.getElementById("survey_theme_c");
    var survey_theme_d = document.getElementById("survey_theme_d");
    var survey_theme_e = document.getElementById("survey_theme_e");
    var survey_theme_f = document.getElementById("survey_theme_f");
    var survey_theme_g = document.getElementById("survey_theme_g");
    var survey_theme_h = document.getElementById("survey_theme_h");

    if(survey_theme_a.getAttribute('data-value') == 'A'){
      prove.style.background = '#6C757D'
      prove.style.color = "#f8f9fa"
    }
    
    if(survey_theme_b.getAttribute('data-value')== 'B'){
      prove.style.background = '#f8f9fa'
      prove.style.color = "#6C757D"
    }
    if(survey_theme_c.getAttribute('data-value') == 'C'){
      prove.style.background = '#17A2B8'
      prove.style.color = "#f8f9fa"
    }
    if(survey_theme_d.getAttribute('data-value') == 'D'){
      prove.style.background =  '#81CA68'
      prove.style.color = "#f8f9fa"
    }  
    if(survey_theme_e.getAttribute('data-value') == 'E'){
      prove.style.background =  '#E91E63'
      prove.style.color = "#f8f9fa"
    }
    if(survey_theme_f.getAttribute('data-value') == 'F'){
      prove.style.background =  '#009688'
      prove.style.color = "#f8f9fa"
    }
    if(survey_theme_g.getAttribute('data-value') == 'G'){
      prove.style.background =  '#E47923'
      prove.style.color = "#f8f9fa"
    }
    if(survey_theme_h.getAttribute('data-value') == 'H'){
      prove.style.background =  '#1B47BA'
      prove.style.color = "#f8f9fa"
    }
    
  }

  onChange(e) {
    var survey_theme_a = document.getElementById("survey_theme_a");
    var survey_theme_b = document.getElementById("survey_theme_b");
    var survey_theme_c = document.getElementById("survey_theme_c");
    var survey_theme_d = document.getElementById("survey_theme_d");
    var survey_theme_e = document.getElementById("survey_theme_e");
    var survey_theme_f = document.getElementById("survey_theme_f");
    var survey_theme_g = document.getElementById("survey_theme_g");
    var survey_theme_h = document.getElementById("survey_theme_h");
    var prove = document.getElementById("prove");
    
    survey_theme_a.addEventListener("change", function(){
      prove.style.background = '#6C757D'
      prove.style.color = "#f8f9fa"
    })
    survey_theme_b.addEventListener("change", function(){
      prove.style.background = '#f8f9fa'
      prove.style.color = "#34373A"
    })
    survey_theme_c.addEventListener("change", function(){
      prove.style.background = '#6EC5D3'
      prove.style.color = "#f8f9fa"
    })
    survey_theme_d.addEventListener("change", function(){
      prove.style.background =  '#81CA68'
      prove.style.color = "#f8f9fa"
    })
    survey_theme_e.addEventListener("change", function(){
      prove.style.background =  '#E91E63'
      prove.style.color = "#f8f9fa"
    })
    survey_theme_f.addEventListener("change", function(){
      prove.style.background =  '#009688'
      prove.style.color = "#f8f9fa"
    })
    survey_theme_g.addEventListener("change", function(){
      prove.style.background =  '#E47923'
      prove.style.color = "#f8f9fa"
    })
    survey_theme_h.addEventListener("change", function(){
      prove.style.background =  '#1B47BA'
      prove.style.color = "#f8f9fa" 
    })
  }
  
}
