import { Controller } from 'stimulus'
import 'bootstrap/js/dist/modal'

export default class extends Controller {
  newsearch()
  {
    var items = document.getElementsByClassName('factor_container');
    var term = document.getElementById('keyword').value;
    Array.from(items).forEach(function(item){
      var display = false;
      var selector = item.childNodes[7].childNodes[0].childNodes[0].children('tr');
      var x = selector.selectedIndex;
      var y = selector[x].text;
      if (y.toLowerCase().indexOf(term) != -1 )
      {
        display = true;
      } // if y
      var factor =  item.childNodes[8].childNodes[0].childNodes[0].value
      if (factor.toLowerCase().indexOf(term) != -1 )
      {
        display = true;
      } // if factor
      var description =  item.childNodes[8].childNodes[2].childNodes[0].value
      if (description.toLowerCase().indexOf(term) != -1 )
      {
        display = true;
      } // if description
      if (display)
      {item.style.display = 'block';}
      else {item.style.display = 'none';}

    }) // array
  }

  pestal()
  {
    var term = document.getElementById('keyword').value;
    var cells = document.getElementsByTagName("td");
    for (var i=0;i<cells.length;i++)
    {
      if (cells[i].parentNode.textContent.toLowerCase().indexOf(term) != -1 )
      {
        cells[i].parentNode.style.display = 'table-row';
        
      } else {
        cells[i].parentNode.style.display = 'none';
      }
    }
  }

  concerned()
  { 
    var term = document.getElementById('keyword').value;
    term = term.toLowerCase();
    var cells = document.getElementsByTagName("td");
    for (var i=0;i<cells.length;i++)
    {
      if (cells[i].parentNode.textContent.toLowerCase().indexOf(term) != -1 )
      {
        cells[i].parentNode.style.display = 'table-row';
        //cells[i].style.background = "#B8DFF9";
      } else {
        cells[i].parentNode.style.display = 'none';
      }
    }
  }

  

  save()
  {
    alert("Guardado");
  }
}
