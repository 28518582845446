import React, { useState, useContext, useEffect } from "react";
import GlobalContext from "../context/GlobalContext";
import ModalFormEdit from "./ModalFormEdit";
import ModalFollow from "./ModalFollow";

export default function EditEvenModal() {
  const { setShowEditModal, setFlaged, eventEditId, currentUserId, setShowDeleteModal } =
    useContext(GlobalContext);
  const [typeModal, setTypeModal] = useState(0);
  const [eventInformation, setEventInformation] = useState([]);
  const [temporalInformation, setTemporalInformation] = useState({
    title: "",
    type: "",
    day: "",
    dayEnd: "",
    periocity: false,
    periocityTipe: "",
    placeOption: false,
    place: "",
    linkOption: false,
    link: "",
    descriptionOption: false,
    description: "",
    archivesOption: false,
    archives: null,
    remember: false,
    rememberUsers: "",
    rememberDate: new Date(),
    procedures: "",
    associatedManagementSystemOption: false,
    associatedManagementSystem: "",
    organizer: "Current user",
  });
  const [temporalInformationFollow, setTemporalInformationFollow] = useState({
    follow: "",
    finding: "",
    findingOption: false,
    descriptionOption: false,
    descriptionFollow: "",
    file: null,
    fileOption: false,
    currentUserCheckOption: false,
    employeeResponseId: currentUserId,
    eventId: "",
  });
  useEffect(() => {
    setEventInformation({
      event: {
        ...eventInformation.event,
        title: temporalInformation.title,
        event_type: temporalInformation.type,
        start_date: temporalInformation.day,
        end_date: temporalInformation.dayEnd,
        periodicity_id: temporalInformation.periocityTipe,
        place: temporalInformation.place,
        link: temporalInformation.link,
        description: temporalInformation.description,
        archive: temporalInformation.archives,
        reminder_date: temporalInformation.rememberDate,
        reminder_users: temporalInformation.rememberUsers,
        procedure_id: temporalInformation.procedures,
        management_id: temporalInformation.associatedManagementSystem,
        employee_id: temporalInformation.organizer,
      },
    });
  }, [temporalInformation]);
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  const handleSubmit = () => {
    if (typeModal === 0) {
      const formData = new FormData();
      formData.append(
        "event_follow[follow_status]",
        temporalInformationFollow.follow
      );
      formData.append(
        "event_follow[finding]",
        temporalInformationFollow.finding
      );
      formData.append(
        "event_follow[evidence_description]",
        temporalInformationFollow.descriptionFollow
      );
      if (!!temporalInformationFollow.file) {
        formData.append("event_follow[file]", temporalInformationFollow.file);
      }
      formData.append(
        "event_follow[employee_id]",
        temporalInformationFollow.employeeResponseId
      );

      fetch(`/api/v2/events/${eventEditId}/event_follows/`, {
        method: "POST",
        body: formData,
        headers: {
          "Content-Disposition": "form-data",
        },
        credentials: "include",
      }).then((response) => {
        return response.json();
      });
      setShowEditModal(false);
      setTimeout(() => {
        setFlaged((prevFlaged) => !prevFlaged);
      }, 100);
    } else {
      const formData = new FormData();
      formData.append("event[title]", eventInformation.event.title);
      formData.append("event[event_type]", eventInformation.event.event_type);
      formData.append("event[start_date]", eventInformation.event.start_date);
      formData.append("event[end_date]", eventInformation.event.end_date);
      formData.append(
        "event[periodicity_id]",
        eventInformation.event.periodicity_id
      );
      formData.append("event[place]", eventInformation.event.place);
      formData.append("event[link]", eventInformation.event.link);
      formData.append("event[file]", eventInformation.event.archive);
      formData.append("event[description]", eventInformation.event.description);
      formData.append(
        "event[reminder_date]",
        eventInformation.event.reminder_date
      );
      // eventInformation.event.reminder_users.map((user, idx) => {
      //   formData.append(
      //     `event[employees_attributes][${idx}][employee_id]`,
      //     user
      //   );
      // });
      formData.append(
        "event[procedure_id]",
        eventInformation.event.procedure_id
      );
      formData.append(
        "event[management_system]",
        eventInformation.event.management_id
      );
      formData.append("event[employee_id]", eventInformation.event.employee_id);

      fetch(`/api/v2/events/${eventEditId}`, {
        method: "PATCH",
        body: formData,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-CSRF-Token": csrfToken,
        },
      }).then((response) => {
        return response.json();
      });
      setShowEditModal(false);
    }
  };

  return (
    <div
      className="modal fade show"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      style={{
        paddingRight: "4px",
        display: "block",
        backgroundColor: "rgba(53, 53, 53, 0.5)",
        zIndex: 10,
      }}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title fs-5" id="staticBackdropLabel">
              {typeModal === 0 ? "Seguimiento" : "Edicion evento / tarea"}
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setShowEditModal(false)}
            ></button>
          </div>
          <div className="modal-body">
            {typeModal === 0 ? (
              <ModalFollow
                temporalInformationFollow={temporalInformationFollow}
                setTemporalInformationFollow={setTemporalInformationFollow}
              />
            ) : (
              <ModalFormEdit
                temporalInformation={temporalInformation}
                setTemporalInformation={setTemporalInformation}
              />
            )}
          </div>
          <div className="modal-footer">
            {typeModal === 0 ? (
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setTypeModal(1)}
              >
                Editar Evento
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setShowDeleteModal(true)}
              >
                Borrar Evento
              </button>
            )}
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setShowEditModal(false)}
            >
              Cerrar
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleSubmit()}
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
