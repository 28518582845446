import React, {useState, useEffect} from 'react'
import ListData from './ListData'

const List = (props) => {
  const [average, setAverage] = useState(0)
  const [weighted, setWeighted] = useState(0)
  const [showAverage, setShowAverage] = useState(true)
  const { cardTitle, data, modalControl, parentType } = props

  useEffect(()=>{
    let sum = 0
    let sumweighted = 0
    if (data.length) {
      for (var i=0; i < data.length; i++) {
        sum += data[i].value
        sumweighted += data[i].weighted
      }
      setAverage(`Promedio: ${(sum / data.length).toFixed(1)} %`)
      setWeighted(`Ponderado: ${sumweighted.toFixed(1)} %`)
    }

  },[])

  return (
    <div className='col-lg-4'>
      <div className='card'>
        <div className='card-header bg-white'>
          <h5 className='card-title text-center'>{cardTitle}</h5>
        </div>
        <nav>
          <div className='nav nav-justified nav-tabs' role='tablist'>
            <a className='h6 nav-item nav-link active' onClick={()=>setShowAverage(true)} href="#" data-toggle="tab" role="tab">{average}</a>
            <a className='h6 nav-item nav-link' onClick={()=>setShowAverage(false)} href="#" data-toggle="tab" role="tab">{weighted}</a>
          </div>
          <div className='w-100 tab-content'>
            <div className='tab-pane fade show active'>
              <ul className='list-group'>
                { data && <ListData data={data}
                                    showAverage={showAverage}
                                    modalControl={modalControl}
                                    parentType={parentType}/>}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default List