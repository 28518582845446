import { Controller }  from 'stimulus'

export default class extends Controller {
  static targets = ['achievement', 'observations']
  connect () {
    this.onChange();
  }

  onChange (e) {
    if (e) { e.preventDefault() }
    const position = $(this.achievementTarget).find(':selected').data('position')
    if (position == 3) {
      $(this.observationsTarget).show('slow')
    }
    else {
      $(this.observationsTarget).hide('slow')
    }
  }
}
