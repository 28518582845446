import React, { useState, useContext, useEffect } from "react";
import GlobalContext from "../context/GlobalContext";
import ModalForm from "./ModalForm";

function Modal() {
  const { eventInformation, setEventInformation, token } =
    useContext(GlobalContext);
  const { showModal, setShowModal } = useContext(GlobalContext);
  const { flaged, setFlaged } = useContext(GlobalContext);
  const [temporalInformation, setTemporalInformation] = useState({
    title: "",
    type: "",
    day: "",
    dayEnd: "",
    periocity: false,
    periocityTipe: "",
    placeOption: false,
    place: "",
    linkOption: false,
    link: "",
    descriptionOption: false,
    description: "",
    archivesOption: false,
    archives: null,
    remember: false,
    rememberUsers: "",
    rememberDate: new Date(),
    procedures: "",
    associatedManagementSystemOption: false,
    associatedManagementSystem: "",
    organizer: "Current user",
  });

  useEffect(() => {
    setEventInformation({
      event: {
        ...eventInformation.event,
        title: temporalInformation.title,
        event_type: temporalInformation.type,
        start_date: temporalInformation.day,
        end_date: temporalInformation.dayEnd,
        periodicity_id: temporalInformation.periocityTipe,
        place: temporalInformation.place,
        link: temporalInformation.link,
        description: temporalInformation.description,
        archive: temporalInformation.archives,
        reminder_date: temporalInformation.rememberDate,
        reminder_users: temporalInformation.rememberUsers,
        procedure_id: temporalInformation.procedures,
        management_id: temporalInformation.associatedManagementSystem,
        employee_id: temporalInformation.organizer,
      },
    });
  }, [temporalInformation]);

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("event[title]", eventInformation.event.title);
    formData.append("event[event_type]", eventInformation.event.event_type);
    formData.append("event[start_date]", eventInformation.event.start_date);
    formData.append("event[end_date]", eventInformation.event.end_date);
    formData.append(
      "event[periodicity_id]",
      eventInformation.event.periodicity_id
    );
    formData.append("event[place]", eventInformation.event.place);
    formData.append("event[link]", eventInformation.event.link);
    if (!!eventInformation.event.archive) {
      formData.append("event[file]", eventInformation.event.archive);
    }
    formData.append("event[description]", eventInformation.event.description);
    formData.append(
      "event[reminder_date]",
      eventInformation.event.reminder_date
    );
    if (!!eventInformation.event.reminder_users) {
      eventInformation.event.reminder_users.map((user, idx) => {
        formData.append(
          `event[employees_attributes][${idx}][employee_id]`,
          user
        );
      });
    }
    formData.append("event[procedure_id]", eventInformation.event.procedure_id);
    formData.append(
      "event[management_system]",
      eventInformation.event.management_id
    );
    formData.append("event[employee_id]", eventInformation.event.employee_id);

    fetch("/api/v2/events/", {
      method: "POST",
      body: formData,
      headers: {
        "Content-Disposition": "form-data",
      },
      credentials: "include",
    }).then((response) => {
      return response.json();
    });
    setShowModal(false);
  };

  useEffect(() => {
    setFlaged((prevFlaged) => !prevFlaged);
  }, [showModal]);

  return (
    <div
      className="modal fade show"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      style={{
        paddingRight: "4px",
        display: "block",
        backgroundColor: "rgba(53, 53, 53, 0.5)",
      }}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title fs-5" id="staticBackdropLabel">
              Creación de evento / tarea
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setShowModal(false)}
            ></button>
          </div>
          <div className="modal-body">
            <ModalForm
              temporalInformation={temporalInformation}
              setTemporalInformation={setTemporalInformation}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleSubmit()}
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
