import { Controller }  from 'stimulus'

export default class extends Controller {

  connect () {
    $("#senderButton").hide()
  }

  onSelect () {
    var checked = $( ".multi-check:checkbox:checked" ).length
    if (checked) { $("#senderButton").show() }
    else { $("#senderButton").hide() }
  }
}
