import { error } from "jquery";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['committee', 'procedure']
  connect() {
    if (this.hasCommitteeTarget){
      var start_date = this.data.get('startdate')
      var end_date = this.data.get('endate')
      var workflow = this.data.get('workflow')
      var type = this.data.get('type')
      var task = this.data.get('task')
      const filters = {
        start_date: start_date,
        end_date: end_date,
        workflow: workflow,
        type: type
      }
      const committeeElement = this.committeeTarget;
      committeeElement.textContent = 'Cargando...'
      const queryString = new URLSearchParams(filters).toString();
      fetch(`/acts/count_total?committee_id=${committeeElement.id}&task=${task}&${queryString}`)
          .then(response => response.json())
          .then(data => committeeElement.textContent = data.name)
          .catch(error => {
            console.log('Error: ' + error)
          })
    }
    if (this.hasProcedureTarget){
      var start_date = this.data.get('startdate')
      var end_date = this.data.get('endate')
      var workflow = this.data.get('workflow')
      var type = this.data.get('type')
      var task = this.data.get('task')
      const filters = {
        start_date: start_date,
        end_date: end_date,
        workflow: workflow,
        type: type
      }
      const procedureElement = this.procedureTarget;
      procedureElement.textContent =  'Cargando...'
      const queryString = new URLSearchParams(filters).toString();
      fetch(`/acts/count_total_procedure?procedure_id=${procedureElement.id}&task=${task}&${queryString}`)
          .then(response => response.json())
          .then(data => procedureElement.textContent = data.name)
          .catch(error => {
            console.log('Error: ' + error)
          })
    }
  }
}