import { Controller } from 'stimulus'
import Chart from 'chart.js'

export default class extends Controller {
  connect () {
    let { points } = this.element.dataset

    points = JSON.parse(points)

    const labels = points.map(({ chapter }) => chapter)
    const data = points.map(({ value }) => value)
    const colors = points.map(({ color }) => color)
    const total = points.map(({ total }) => total)
    this.ctx = this.element.getContext('2d')
  
    this.options = {
      type: 'doughnut',
      responsive: true,
      maintainAspectRatio: false,
      data: {
        
        labels,
        datasets: [
          {
            backgroundColor: colors,
            data
            
          },
        ]
      },
      options: {
        tooltips: {
          callbacks: {
            title: function(tooltipItem, data) {
              return data['labels'][tooltipItem[0]['index']];
            },
            label: function(tooltipItem, data) {
              return data['datasets'][0]['data'][tooltipItem['index']];
            },
           
          },
        }}
    }
    this.chart = new Chart(this.ctx, this.options)
  }
}
