import React from "react";

const SinergyModal = () => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th className="align-middle text-center">
            PARÁMETROS DE CALIFICACIÓN CUALITATIVA
          </th>
          <th className="align-middle text-center"> CALIFICACIÓN </th>
          <th className="align-middle text-center"> GRADO DE SIGNIFICANCIA </th>
          <th className="align-middle text-center"> DESCRIPCIÓN </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="align-middle text-justify" rowSpan="3">
            SINERGIA
          </td>
          <td className="align-middle text-center">SIN SINERGISMO</td>
          <td className="align-middle text-center">1</td>
          <td className="align-middle text-justify">
            Cuando una acción que actúa sobre un factor, no es sinérgico con
            otras acciones.
          </td>
        </tr>
        <tr>
          <td className="align-middle text-center">SINÉRGICO</td>
          <td className="align-middle text-center">2</td>
          <td className="align-middle text-justify">
            Se presenta un sinergismo moderado, que implica una manifestación
            mayor al causado por la acción.
          </td>
        </tr>
        <tr>
          <td className="align-middle text-center">MUY SINÉRGICO</td>
          <td className="align-middle text-center">4</td>
          <td className="align-middle text-justify">
            La acción es altamente sinérgica, que se manifiesta en un impacto
            mucho mayor sobre el factor intervenido
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default SinergyModal;
