import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['image', 'financial', 'legal', 'extension', 'impactupl'];

  connect() {
    const upl_log = this.element.dataset.user
    this.uplLogged = JSON.parse(upl_log);
    if (!!this.uplLogged) {
      this.updateAverage();
      this.addEventListeners();  
    }
  }

  addEventListeners() {
    this.imageTarget.addEventListener('change', this.onChange.bind(this));
    this.legalTarget.addEventListener('change', this.onChange.bind(this));
    this.financialTarget.addEventListener('change', this.onChange.bind(this));
    this.extensionTarget.addEventListener('change', this.onChange.bind(this));
  }

  onChange() {
    this.updateAverage();
  }

  updateAverage() {
    const legalValue = parseInt(this.legalTarget.value, 10);
    const imageValue = parseInt(this.imageTarget.value, 10);
    const financialValue = parseInt(this.financialTarget.value, 10);
    const extensionValue = parseInt(this.extensionTarget.value, 10);
    const average = (legalValue + imageValue + financialValue + extensionValue) / 4;
    const roundedAverage = Math.round(average);
    const impactuplValue = Math.max(1, Math.min(5, roundedAverage));

    this.dispatchImpactuplChange(impactuplValue);
  }

  dispatchImpactuplChange(value) {
    const event = new CustomEvent('impactupl:change', {
      detail: { value },
      bubbles: true
    });

    this.element.dispatchEvent(event);
  }

}