import React from 'react'

const Thead = ({infoMatrix}) => {
  return (
    <thead>
      <tr>
        <th className="text-center align-middle" scope="col">
          Sede/Obra
        </th>
        <td className="text-center align-middle" scope="col" colSpan={5}>
          {infoMatrix.branch_office_name}
        </td>
        <th className="text-center align-middle" scope="col">
          Fecha Elaboración
        </th>
        <td className="text-center align-middle" scope="col" colSpan={2}>
          {infoMatrix.elaboration_date}
        </td>
      </tr>
      <tr>
        <th
          className="text-center align-middle"
          scope="col"
          rowSpan={2}
          style={{ minWidth: "200px" }}
        >
          Procesos
        </th>
        <th
          className="text-center align-middle"
          scope="col"
          rowSpan={2}
          style={{ minWidth: "300px" }}
        >
          Actividades
        </th>
        <th className="text-center align-middle" scope="col" colSpan={3}>
          Situación
        </th>
        <th className="text-center align-middle" scope="col" colSpan={2}>
          Aspecto Ambiental Asociado
        </th>
        <th className="text-center align-middle" scope="col" colSpan={2}>
          Impacto Ambiental
        </th>
        <th className="text-center align-middle" scope="col" colSpan={13}>
          Evaluación del impacto
        </th>
        <th className="text-center align-middle" scope="col" colSpan={3}>
          Riesgos y Oportunidades
        </th>
        <th className="text-center align-middle" scope="col" colSpan={2}>
          Indicador de significancia de Impactos ambientales
        </th>
        <th className="text-center align-middle" scope="col" colSpan={7}>
          Plan de gestión de aspectos e impactos ambientales
        </th>
      </tr>
      <tr>
        <th className="text-center align-middle">N</th>
        <th className="text-center align-middle">A</th>
        <th className="text-center align-middle">E</th>
        <th
          className="text-center align-middle"
          scope="col"
          rowSpan={2}
          style={{ minWidth: "250px" }}
        >
          Aspecto Ambiental
        </th>
        <th
          className="text-center align-middle"
          scope="col"
          rowSpan={2}
          style={{ minWidth: "300px" }}
        >
          Descripción del Aspecto Ambiental
        </th>
        <th
          className="text-center align-middle"
          scope="col"
          rowSpan={2}
          style={{ minWidth: "150px" }}
        >
          Recurso Afectado
        </th>
        <th
          className="text-center align-middle"
          scope="col"
          rowSpan={2}
          style={{ minWidth: "300px" }}
        >
          Impacto generado
        </th>
        <th className="text-center align-middle">Naturaleza +/-</th>
        <th className="text-center align-middle">Intensidad</th>
        <th className="text-center align-middle">Extensión</th>
        <th className="text-center align-middle">Momento</th>
        <th className="text-center align-middle">Persistencia</th>
        <th className="text-center align-middle">Reversibilidad</th>
        <th className="text-center align-middle">Sinergia</th>
        <th className="text-center align-middle">Acumulación</th>
        <th className="text-center align-middle">Efecto</th>
        <th className="text-center align-middle">Periodicidad</th>
        <th className="text-center align-middle">Recuperabilidad</th>
        <th className="text-center align-middle">Valor Significancia</th>
        <th className="text-center align-middle">
          Nivel de Significancia
        </th>
        <th
          className="text-center align-middle"
          style={{ minWidth: "200px" }}
        >
          Riesgo asociado
        </th>
        <th
          className="text-center align-middle"
          style={{ minWidth: "200px" }}
        >
          Descripción del riesgo asociado
        </th>
        <th
          className="text-center align-middle"
          style={{ minWidth: "200px" }}
        >
          Descripción de la oportunidad asociada
        </th>
        <th className="text-center align-middle">
          Impacto Significativo
        </th>
        <th className="text-center align-middle">
          Impacto no Significativo
        </th>
        <th
          className="text-center align-middle"
          style={{ minWidth: "300px" }}
        >
          Acciones o control
        </th>
        <th
          className="text-center align-middle"
          style={{ minWidth: "100px" }}
        >
          Responsable
        </th>
        <th
          className="text-center align-middle"
          style={{ minWidth: "100px" }}
        >
          Fecha Inicio
        </th>
        <th
          className="text-center align-middle"
          style={{ minWidth: "100px" }}
        >
          Fecha Fin
        </th>
        <th
          className="text-center align-middle"
          style={{ minWidth: "250px" }}
        >
          Hallazgo
        </th>
        <th
          className="text-center align-middle"
          style={{ minWidth: "150px" }}
        >
          Porcentaje de avance
        </th>
        <th
          className="text-center align-middle"
          style={{ minWidth: "250px" }}
        >
          Evidencia
        </th>
      </tr>
    </thead>
  )
}

export default Thead