import { Controller } from 'stimulus'
import Chart from 'chart.js'

export default class extends Controller {
  connect () {
    let { points, variable } = this.element.dataset
    points = JSON.parse(points)
    var labels
    if (variable == 'objectives') {
      labels = points.map(({ objective }) => objective)
    } else {
      labels = points.map(({ activity }) => activity)
    }
    const data = points.map(({ percentage }) => percentage )
    const colors = points.map(({ color }) => color)
    this.ctx = this.element.getContext('2d')
    this.options = {
      type: 'pie',
      data: {
        labels,
        datasets: [
          {
            label: 'Cantidad',
            pointBorderWidth: 5,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: true,
            backgroundColor: colors,
            borderWidth: 2,
            data
          }
        ]
      },
      options: {
        tooltips: {
          titleFontFamily: 'Source Sans Pro',
          titleFontSize: 16,
          bodyFontSize: 16,
          xPadding: 10,
          yPadding: 10
        },
        animation: {
          easing: 'easeOutQuart',
          duration: 500,
          onComplete: function () {
            var ctx = this.chart.ctx
            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily)
            ctx.textAlign = 'center'
            ctx.textBaseline = 'bottom'

            this.chart.data.datasets.forEach(function (dataset) {
              for (var i = 0; i < dataset.data.length; i++) {
                var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                    total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                    mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius)/2,
                    start_angle = model.startAngle,
                    end_angle = model.endAngle,
                    mid_angle = start_angle + (end_angle - start_angle)/2;

                var x = mid_radius * Math.cos(mid_angle);
                var y = mid_radius * Math.sin(mid_angle);

                ctx.fillStyle = '#fff';
                if (i == 3){
                  ctx.fillStyle = '#444';
                }
                var percent = String(Math.round(dataset.data[i]/total*100)) + "%";

                if(dataset.data[i] != 0 && dataset._meta[0] != undefined && dataset._meta[0].data[i].hidden != true) {
                  ctx.fillText(dataset.data[i], model.x + x, model.y + y);
                  ctx.fillText(percent, model.x + x, model.y + y + 15);
                }
              }
            })
          },
        },
        legend: {
          display: true,
          position: 'top'
        }
      }
    }
    this.chart = new Chart(this.ctx, this.options)
  } //
} //

