import React from "react"

const ProfilePicture = ({url, initials, full_name}) => {
  return(<React.Fragment>
    {url && <div className="avatar bg-light text-dark border border-light rounded-circle avatar-small avatar-inline my-tooltip">
      <img src={url} className="img-fluid rounded-circle"></img>
      <span className="tooltiptext">{full_name}</span>
    </div>}
    {!url && <div className="avatar bg-light text-dark border border-light rounded-circle avatar-small avatar-inline my-tooltip">
      <div className='initials'>{initials}</div>
      <span className="tooltiptext">{full_name}</span>
    </div>}
  </React.Fragment>)
}

export default ProfilePicture


