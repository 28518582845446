import { Controller }  from 'stimulus'

export default class extends Controller {
  onChange() {
    if (! $('input.attendee:checkbox').not(":eq(0)").is(':checked')) {
      $('input.attendee:checkbox').prop('checked',true);
    } else {
      $('input.attendee:checkbox').prop('checked', false);
    }
  }
}