import React from "react";
import axios from "axios";

function ServiceAdminLaboratories(){
  const [adminLaboratory, setAdminboratory] = React.useState([])
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const getData = async () => {
      try {
        const url = `/api/v2/admin_laboratories`;
        const response = await axios.get(`${url}`);
        if (response.status === 200) {
          setAdminboratory(response.data)
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.log('error: ' + error)
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    getData();
    return () => {
      setError(null);
    };
  }, [])

  return {
    loading,
    error,
    adminLaboratory
  }
}

export { ServiceAdminLaboratories }