import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button', 'containerShow', 'containerForm']

  editIssue () {
    this.containerShowTarget.style.display = 'none'
    this.containerFormTarget.style.display = 'block'
    $('#saveIssueButton').attr('form', 'FormFor' + this.buttonTarget.value)
    $('#newIssueButton').hide()
    $('#saveIssueButton').show()
  }

  editClient () {
    this.containerShowTarget.style.display = 'none'
    this.containerFormTarget.style.display = 'block'
    $('#saveClientButton').attr('form', 'FormFor' + this.buttonTarget.value)
    $('#newClientButton').hide()
    $('#saveClientButton').show()
  }

  editSupplier () {
    this.containerShowTarget.style.display = 'none'
    this.containerFormTarget.style.display = 'block'
    $('#saveSupplierButton').attr('form', 'FormFor' + this.buttonTarget.value)
    $('#newSupplierButton').hide()
    $('#saveSupplierButton').show()
  }

  editReport () {
    this.containerShowTarget.style.display = 'none'
    this.containerFormTarget.style.display = 'block'
    $('#saveReportButton').attr('form', 'FormFor' + this.buttonTarget.value)
    $('#newReportButton').hide()
    $('#saveReportButton').show()
  }

  editExternal () {
    this.containerShowTarget.style.display = 'none'
    this.containerFormTarget.style.display = 'block'
    $('#saveExternalButton').attr('form', 'FormFor' + this.buttonTarget.value)
    $('#newExternalButton').hide()
    $('#saveExternalButton').show()
  }

  editExternalAction () {
    this.containerShowTarget.style.display = 'none'
    this.containerFormTarget.style.display = 'block'
    $('#saveExternalActionButton').attr('form', 'FormFor' + this.buttonTarget.value)
    $('#newExternalActionButton').hide()
    $('#saveExternalActionButton').show()
  }

  editNonConformities () {
    this.containerShowTarget.style.display = 'none'
    this.containerFormTarget.style.display = 'block'
    $('#saveNonConformitiesButton').attr('form', 'FormFor' + this.buttonTarget.value)
    $('#newNonConformitiesButton').hide()
    $('#saveNonConformitiesButton').show()
  }

  editAdquacyResources () {
    this.containerShowTarget.style.display = 'none'
    this.containerFormTarget.style.display = 'block'
    $('#saveAdquacyResourcesButton').attr('form', 'FormFor' + this.buttonTarget.value)
    $('#newAdquacyResourcesButton').hide()
    $('#saveAdquacyResourcesButton').show()
  }

  editOpportunityAction () {
    this.containerShowTarget.style.display = 'none'
    this.containerFormTarget.style.display = 'block'
    $('#OpportunitySaveButton').attr('form', 'FormFor' + this.buttonTarget.value)
    $('#OpportunitySaveButton').show()
  }

  editNeedAction () {
    this.containerShowTarget.style.display = 'none'
    this.containerFormTarget.style.display = 'block'
    $('#NeedActionSaveButton').attr('form', 'FormFor' + this.buttonTarget.value)
    $('#NeedActionSaveButton').show()
  }
}
