import React, { useState, useEffect } from "react";
import { GetProcedures } from "./Requests";
import ProcedureInput from "./ProcedureInput";

const Procedures = ({ envImpact, setEnvImpact }) => {
  const [checked, setChecked] = useState(false);
  const [procedures, setProcedures] = useState([]);
  const [multiProcedures, setMultiProcedures] = useState([]);
  const [multiProceduresAux, setMultiProceduresAux] = useState([]);
  const [counterProcedures, setCounterProcedures] = useState(0);

  const handleChange = () => {
    if (checked) {
      const updatedImpact = { ...envImpact };
      updatedImpact.environmental_impact.all_procedures = false;
      updatedImpact.environmental_impact.multi_procedures_attributes =
        multiProcedures;
      setEnvImpact(updatedImpact);
    } else {
      const updatedImpact = { ...envImpact };
      updatedImpact.environmental_impact.all_procedures = true;
      updatedImpact.environmental_impact.multi_procedures_attributes = {};
      setEnvImpact(updatedImpact);
    }
    setChecked(!checked);
  };

  const addInput = () => {
    const localCounter = counterProcedures + 1;
    const newProcedure = {
      indexProcedure: localCounter,
      procedure_id: procedures[0].id,
    };
    setCounterProcedures(counterProcedures + 1);
    setMultiProceduresAux([...multiProceduresAux, newProcedure]);
  };

  const deleteInput = (e) => {
    const updatedProcedures = multiProceduresAux.filter(
      (proc) => proc.indexProcedure !== e
    );
    const reindexedProcedures = updatedProcedures.map((proc, index) => ({
      ...proc,
      indexProcedure: index,
    }));
    setMultiProceduresAux(reindexedProcedures);
  };

  const handleSelect = (id, e) => {
    const { value } = e.target;
    setMultiProceduresAux((prevMultiProceduresAux) =>
      prevMultiProceduresAux.map((item) =>
        item.indexProcedure === id ? { ...item, procedure_id: value } : item
      )
    );
  };

  useEffect(() => {
    GetProcedures().then((data) => {
      setProcedures(data);
    });
  }, []);

  useEffect(() => {
    if (procedures.length > 0) {
      const newProcedure = {
        indexProcedure: counterProcedures,
        procedure_id: procedures[0].id,
      };
      setMultiProceduresAux([newProcedure]);
    }
  }, [procedures]);

  useEffect(() => {
    const formattedProcedures = multiProceduresAux.reduce(
      (acc, procedure, index) => {
        acc[index] = {
          id: null,
          procedure_id: procedure.procedure_id,
          processable_id: null,
          processable_type: "EnvironmentalImpact",
          _destroy: false,
        };
        return acc;
      },
      {}
    );
    setMultiProcedures(formattedProcedures);
  }, [multiProceduresAux]);

  useEffect(() => {
    const updatedImpact = { ...envImpact };
    updatedImpact.environmental_impact.multi_procedures_attributes =
      multiProcedures;
    setEnvImpact(updatedImpact);
  }, [multiProcedures]);

  return (
    <div className="card-body">
      <div className="form-row">
        <div className="col-md-12 justify-content-between">
          <h6>Procesos Asociados:</h6>
          <div className="col-md-12">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input ml-3"
                checked={checked}
                onChange={handleChange}
              />
              <label
                className="custom-control-label mr-3"
                onClick={() => handleChange()}
              >
                Todos Los procesos
              </label>
            </div>
          </div>
        </div>
        {!checked && (
          <div className="col-md-12">
            <div className="form-group mb-3">
              <span> Proceso </span>
              {multiProceduresAux.map((input) => (
                <ProcedureInput
                  key={input.indexProcedure}
                  procedures={procedures}
                  deleteInput={deleteInput}
                  idInput={input.indexProcedure}
                  idProcedure={input.procedure_id}
                  handleSelect={handleSelect}
                />
              ))}
              <button
                type="button"
                className="btn btn-info btn-sm mb-2"
                onClick={addInput}
              >
                <i className="fas fa-plus mr-1"></i>
                Agregar
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Procedures;
