import { Controller }  from 'stimulus'

export default class extends Controller {
  static targets=['check', 'dateField']
  connect() {
    this.onChange()
  }

  onChange() {
    if($(this.checkTarget).is(':checked')) {
      $(this.dateFieldTarget).hide('slow')
    } else {
      $(this.dateFieldTarget).show('slow')
    }
  }
}