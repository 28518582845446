import { Controller } from 'stimulus'
import measureChart from '../shared/measureChart'

export default class extends Controller {
  static targets = ['modal', 'modalContent', 'name', 'title']

  show (e) {
    e.preventDefault()
    const { id, group, name, title } = e.target.dataset
    
    if (id != null){
    $(this.modalContentTarget).html('')
    $('#loadingIndicator').attr('style', 'display: initial')
    $(this.nameTarget).html(name)
    $(this.titleTarget).html(title)
    $(this.modalTarget).modal('show')
      $.get(`/indicators/metrics/by-group?group=${group}&group_id=${id}`).then((response) => {
        $('#loadingIndicator').attr('style', 'display:none !important')

        $(this.modalContentTarget).html(response)
      })
    }
  }

  showProcedures (e) {
    e.preventDefault()

    const { type, name, title } = e.target.dataset
    $(this.modalContentTarget).html('')
    $('#loadingIndicator').attr('style', 'display: initial')
    $(this.nameTarget).html(name)
    $(this.titleTarget).html(title)
    $(this.modalTarget).modal('show')

    $.get(`/indicators/metrics/by-procedure?type=${type}`).then((response) => {
      $('#loadingIndicator').attr('style', 'display:none !important')

      $(this.modalContentTarget).html(response)
    })
  }
}
