import React, { useState, useEffect } from "react";
import Header from "./Header";
import Procedures from "./Form/Procedures";
import Activity from "./Form/Activity";
import { GetEnvironmentalAspects } from "./Form/Requests";

const Form = (props) => {
  const [matrixId] = useState(props.environmental_matrix.id);
  const [impactId] = useState(props.environmental_impact.id);
  const [activitiesAux, setActivitiesAux] = useState([
    {
      indexActivity: 0,
      activityInput: "",
      situationInput: null,
      aspects: {},
    },
  ]);
  const [activities, setActivities] = useState([]);
  const [activitiesCounter, setActivitiesCounter] = useState(0);
  const [envImpact, setEnvImpact] = useState({
    environmental_impact: {
      environmental_matrix_id: matrixId,
      multi_procedures_attributes: {},
      env_impact_activities_attributes: {},
      all_procedures: false,
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const url =
      impactId === null
        ? "/api/v1/environmental_impacts"
        : `/api/v1/environmental_impacts/${impactId}`;
    const method = impactId === null ? "POST" : "PATCH";
    fetch(url, {
      method: method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(envImpact),
    })
      .then((response) => {
        return response.json();
      })
      .then(() => {
        window.location.href = `/environmental_matrices/${matrixId}/environmental_impacts`
        // setTimeout(() => {
        //   window.location.reload()
        // }, 500);
      })
      .catch((error) => console.error(error));
  };

  const addActivity = () => {
    const localCounter = activitiesCounter + 1;
    const newActivity = {
      indexActivity: localCounter,
      activityInput: "",
      situationInput: null,
      aspects: {},
    };
    setActivitiesCounter(activitiesCounter + 1);
    setActivitiesAux([...activitiesAux, newActivity]);
  };

  const deleteActivity = (e) => {
    const updatedActivities = activitiesAux.filter(
      (activity) => activity.indexActivity !== e
    );
    const reindexedActivities = updatedActivities.map((activity, index) => ({
      ...activity,
      indexActivity: index,
    }));
    setActivitiesCounter(activitiesCounter - 1);
    setActivitiesAux(reindexedActivities);
  };

  return (
    <>
      <Header matrixId={matrixId} handleSubmit={handleSubmit} />
      <Procedures envImpact={envImpact} setEnvImpact={setEnvImpact} />
      <div className="card-body card shadow">
        {activitiesAux.map((activity) => (
          <Activity
            key={activity.indexActivity}
            indexActivity={activity.indexActivity}
            deleteActivity={deleteActivity}
            setActivitiesAux={setActivitiesAux}
            activitiesAux={activitiesAux}
            activity={activity}
            envImpact={envImpact}
            setEnvImpact={setEnvImpact}
            activities={activities}
            setActivities={setActivities}
          />
        ))}
        <div>
          <button
            type="button"
            className="btn btn-info btn-sm mb-2"
            onClick={addActivity}
          >
            <i className="fas fa-plus mr-1"></i>
            Agregar
          </button>
          <span className="ml-2"> Agregar Actividad</span>
        </div>
      </div>
    </>
  );
};

export default Form;
