import React, {useState, useEffect} from "react"
import General from "./MetricsPage/General"
import ProcedureTypeCard from "./MetricsPage/ProcedureTypeCardYear"
import IndicatorTypeList from './MetricsPage/IndicatorTypeListYear'
import List from './MetricsPage/ListYear'
import Modal from './MetricsPage/ModalYear'
import DateInput from './MetricsPage/DateInputYearTow'
const MetricsPage = () => {
  const [general, setGeneral] = useState({value: 0, types: 0})
  const [strategic, setStrategic] = useState({value: 0, color: '#34a8eb', procedures: 0})
  const [missional, setMissional] = useState({value: 0, color: '#34a8eb', procedures: 0})
  const [support, setSupport] = useState({value: 0, color: '#34a8eb', procedures: 0})
  const [assessment, setAssessment] = useState({value: 0, color: '#34a8eb', procedures: 0})
  const [generalcolor, setGeneralColor] = useState('#000000')
  const [modalurl, setModalUrl] = useState('')
  const [date, setDate] = useState('')
  const [initialdate, setIntialDate] = useState('')
  const [finaldate, setFinalDate] = useState('')

  const dateCallBack = (date) =>{
    setDate(date)
  }

  const initialDateCallBack = (initialdate) =>{
    setIntialDate(initialdate)
  }

  const finalDateCallBack = (finaldate) =>{
    setFinalDate(finaldate)
  }

  useEffect(() => {
    fetch('/api/v2/metrics/strategic?date='+date+'&initialdate='+initialdate+'&finaldate='+finaldate).then((response)=>{
      return response.json()
    }).then((data) => {
      setStrategic(data[0])
      })
  }, [setStrategic, date, initialdate, finaldate])

  useEffect(() => {
    fetch('/api/v2/metrics/missional?date='+date+'&initialdate='+initialdate+'&finaldate='+finaldate).then((response)=>{
      return response.json()
    }).then((data) => {
      setMissional(data[0])
    })
  }, [setMissional, date, initialdate, finaldate])

  useEffect(() => {
    fetch('/api/v2/metrics/support?date='+date+'&initialdate='+initialdate+'&finaldate='+finaldate).then((response)=>{
      return response.json()
    }).then((data) => {
      setSupport(data[0])
    })
  }, [setSupport, date, initialdate, finaldate])

  useEffect(() => {
    fetch('/api/v2/metrics/assessment?date='+date+'&initialdate='+initialdate+'&finaldate='+finaldate).then((response)=>{
      return response.json()
    }).then((data) => {
      setAssessment(data[0])
    })
  }, [setAssessment, date, initialdate, finaldate])

  useEffect(() => {
    const types = [strategic, missional, support, assessment]
    var sum = 0
    var counts = 0
    types.map((type)=>{
      if (type.procedures) {
        sum += Number(type.value)
        counts += 1
      }
    })
    if (counts) {
      setGeneral({ value: (sum / counts).toFixed(2), types: counts })
      fetch('/api/v2/metrics/achievement_level_color'+'?value='+(sum / counts)).then((response)=>{
        return response.json()
      }).then((data) => {
        setGeneralColor(data[0].color)
      })
    }
  }, [setGeneral, strategic, missional, support, assessment, setStrategic, setMissional, setSupport, setAssessment, setGeneralColor])

  const [modal, ShowModal] = useState(false)
  const [modaltitle, setModalTitle] = useState('')
  const [group, setGroup] = useState('')
  const [groupid, setGroupId] = useState('')
  const modalCallback = (modal, url, modaltitle, group) => {
    ShowModal(modal)
    setModalUrl(url)
    setModalTitle(modaltitle)
    setGroup(group)
  }

  return (
    <>
      <div className='card-body'>
        <DateInput dateFilter={dateCallBack}/>
        <General percentage={general.value} color={generalcolor} types={general.types}/>
        <div className='row mt-4'>
          {strategic.procedures && <ProcedureTypeCard title='Estratégico'
                                                      percentage={Number(strategic.value)}
                                                      color={strategic.color}
                                                      procedures={strategic.procedures}
                                                      type={'strategic'}
                                                      modalControl={modalCallback}
                                                      counts={general.types}
                                                      date={date}
                                                      initialdate={initialdate}
                                                      finaldate={finaldate}/>}
          {missional.procedures && <ProcedureTypeCard title='Misional'
                                                      percentage={Number(missional.value)}
                                                      color={missional.color}
                                                      procedures={missional.procedures}
                                                      type={'missionary'}
                                                      modalControl={modalCallback}
                                                      counts={general.types}
                                                      date={date}
                                                      initialdate={initialdate}
                                                      finaldate={finaldate}/>}
          {support.procedures && <ProcedureTypeCard title='Apoyo'
                                                    percentage={Number(support.value)}
                                                    color={support.color}
                                                    procedures={support.procedures}
                                                    type='support'
                                                    modalControl={modalCallback}
                                                    counts={general.types}
                                                    date={date}
                                                    initialdate={initialdate}
                                                    finaldate={finaldate}/>}
          {assessment.procedures && <ProcedureTypeCard title='Evaluación'
                                                      percentage={Number(assessment.value)}
                                                      color={assessment.color}
                                                      procedures={assessment.procedures}
                                                      type='assessment'
                                                      modalControl={modalCallback}
                                                      counts={general.types}
                                                      date={date}
                                                      initialdate={initialdate}
                                                      finaldate={finaldate}/>}
        </div>
        <div className='row mt-4'>
          <IndicatorTypeList modalControl={modalCallback} date={date} initialdate={initialdate} finaldate={finaldate}/>
          <List type={'objectives'} modalControl={modalCallback} date={date} initialdate={initialdate} finaldate={finaldate}/>
          <List type={'Perspectives'} modalControl={modalCallback} date={date} initialdate={initialdate} finaldate={finaldate}/>
          {modal && <Modal modalControl={modalCallback} url={modalurl} group={group} groupid={groupid} modaltitle={modaltitle} date={date} initialdate={initialdate} finaldate={finaldate}/>}
        </div>
      </div>
    </>
  );
}

export default MetricsPage