import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ['destroy', 'code', 'output']
  connect () {
    this.onChange()
  }

  onDelete (e) {
    e.preventDefault()
    this.destroyTarget.value = '1'
    $(this.element).fadeOut()
    $(this.codeTarget).prop('value', '0')
  }

  onChange (e) {
    if (e) e.preventDefault()
    var list = document.getElementsByClassName("activity")
    var max = 0;
    for (var i = 0; i < list.length; i++) {
      if (list[i].value > max)
      {
        max = list[i].value
      }
    }
    const { value } = this.codeTarget
    if (Number(value) > 0) {
      this.outputTarget.textContent = 'Actividad' + ' ' + Number(value).toString()
    }
    else
    {
      $(this.codeTarget).prop('value', Number(max)+1)
      this.outputTarget.textContent = 'Actividad' + ' ' + (Number(max)+1).toString()
    }
  }
}
