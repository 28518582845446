import React from "react";
import { EmptyRecords } from "../../EmptyRecords";
import axios from "axios";
import { LoadingSpinner } from "../../LoadingSpinner";
import { LabortoryContext } from "./LabortoryContext";
import { Table } from "./Table";
import { Modal } from "./Modal"
import { EditModal } from "./EditModal";

function AppUI() {
  const{
    loading,
    error,
    typeLaboratory,
    openModal,
    setOpenModal,
    openEditModal
  } = React.useContext(LabortoryContext)

  return(
    <div className="card border-0">
      <div className="card-header d-flex justify-content-between bg-dark">
        <h5 className="card-title text-white">
          Tipos de Pruebas de Laboratorio
        </h5>
        <div className="card-options">
          <button 
            className="btn btn-primary"
            onClick={
              () => {
                setOpenModal(state => !state)
              }
            }
          >
            Nuevo
          </button>
        </div>
      </div>
      <div className="card-body p-0">
        {loading && <LoadingSpinner/>}
        {error && <p>Se generó un error :o</p>}
        {!loading && typeLaboratory.length <= 0 && <EmptyRecords/> }
        {!loading && typeLaboratory.length > 0 &&  <Table/>}
      </div>
      {openModal && (
        <Modal/>
      )
      }
      {openEditModal && (
        <EditModal/>
      )}
    </div>
  )
}

export default AppUI;