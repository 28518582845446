import React, { useState, useEffect } from 'react';

const CountAchievementAction = ({ achievement_id, type }) => {
  const [count, setCount] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCount = async () => {
      try {
        const response = await fetch(`/api/v2/action_summaries/values_count?achievement_id=${achievement_id}&type=${type}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const text = await response.text();
        const data = text ? JSON.parse(text) : {};
        setCount(data.count_achievement || 0);
      } catch (error) {
        setError(error.message);
        console.error('Error fetching data:', error);
      }
    };
  
    fetchCount();
  }, [achievement_id, type]);

  return (
    <div>
      {error ? (
        <p>Error: {error}</p>
      ) : (
        <span id={`filter-${achievement_id}`} className="notification-circle badge badge-pill badge-warning notification filter-badge">
          {count !== null ? count : 'Loading...'}
        </span>
      )}
    </div>
  );
};

export default CountAchievementAction;
