import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['destroy', 'dropdown', 'objective']

  connect () {
    this.onChange()
  }

  onChange (e) {
    if (e) {
      e.preventDefault()
    }

    if (this.hasDropdownTarget && this.hasObjectiveTarget) {
      const objective = $(this.dropdownTarget).find(':selected').data('objective')
      this.objectiveTarget.value = objective
    }
  }

  onClickDelete (e) {
    e.preventDefault()
    this.destroyTarget.value = '1'
    $(this.element).fadeOut()
  }
}
