import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['equipmentSelect', 'infrastructureSelect']

  connect () {
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()
    
    if ($(this.equipmentSelectTarget).prop('checked')) {
      $('#equipmentFormDiv').show('slow')
    } else if ($(this.infrastructureSelectTarget).prop('checked')){
      $('#equipmentFormDiv').hide('slow')
    }
  }
}
