import { Controller } from 'stimulus'

export default class extends Controller {
  onChange (e) {
    e.preventDefault()
    const element = document.getElementById('stacked-chart')
    const controller = this.application.getControllerForElementAndIdentifier(element, 'stacked-chart')
    controller.changeType(this.element.value)
  }
}
