import React from "react";

const RecuperabilityModal = () => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th className="align-middle text-center">
            PARÁMETROS DE CALIFICACIÓN CUALITATIVA
          </th>
          <th className="align-middle text-center"> CALIFICACIÓN </th>
          <th className="align-middle text-center"> GRADO DE SIGNIFICANCIA </th>
          <th className="align-middle text-center"> DESCRIPCIÓN </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="align-middle text-justify" rowSpan="2">
            RECUPERABILIDAD - (RE)
          </td>
          <td className="align-middle text-justify">
            RECUPERACIÓN DE MANERA INMEDIATA
          </td>
          <td className="align-middle text-justify">1</td>
          <td className="align-middle text-justify">
            {" "}
            Una vez que se desarrolle la medida, el elemento retorna a sus
            condiciones iniciales
          </td>
        </tr>
        <tr>
          <td className="align-middle text-justify">
            RECUPERABLE EN MEDIO PLAZO
          </td>
          <td className="align-middle text-justify">2</td>
          <td className="align-middle text-justify">
            {" "}
            Si el elemento recupera su estado inicial en menos de 5 años
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default RecuperabilityModal;
