import React, { useState, useEffect } from 'react'
import LoadingTable from './Bscs/LoadingTable'
import TableHeaders from './Bscs/TableHeaders'
import PerspectiveRows from './Bscs/PerspectiveRows'

const BscMetricDetails = (props) => {
  const [colorRanges, setColorRanges] = useState([])
  const [perspectives, setPerspectives] = useState([])

  useEffect(() => {
    fetch(`/api/v1/bscs/${props.bsc.id}`).then((response)=>{
      return response.json()
    }).then((data) => {
      let dataCal = calAverages(data)
      setPerspectives(dataCal)
    })
  }, [])

  useEffect(() => {
    fetch('/api/v1/bsc_metrics/color_ranges').then((response)=>{
      return response.json()
    }).then((data) => {
      setColorRanges(data)
    })
  }, [])

  const calAverages = (data) => {
    const updatedData = data.map((perspective) => {
      const estrategics = perspective.strategic_objectives.map((strategic) => {
        const operatives = strategic.operative_objectives.map((operative) => {
          const indicators = operative.indicators.filter(indicator => indicator.percentage !== null )
          const sumIndicators = indicators.reduce((accumulator, indicator) => accumulator + indicator.percentage, 0)
          let averageIndicators = 0
          if (indicators.length > 0) {
            averageIndicators = sumIndicators / indicators.length
          }

          return {
            ...operative,
            percentage: averageIndicators
          }
        })
        const sumOperatives = operatives.reduce((accumulator, operative) => accumulator + operative.percentage, 0)
        let averageOperatives = 0
        if (operatives.length > 0) {
          averageOperatives = sumOperatives / operatives.length;
        }
        return {
          ...strategic,
          percentage: averageOperatives,
          operative_objectives: operatives
        }
      })
      const perspectiveSum = estrategics.reduce((accumulator, estrategic) => accumulator + estrategic.percentage, 0)
      let perspectiveAverage = 0
      if (estrategics.length > 0) {
        perspectiveAverage = perspectiveSum / estrategics.length
      }

      return {
        ...perspective,
        percentage: perspectiveAverage,
        strategic_objectives: estrategics
      }
    })
    return updatedData
  }

  const ColorRangeEvaluator = (resource) => {
    let color = '#FFFFFF'

    for (let i = 0; i < colorRanges.length; i++) {
      const colorRange = colorRanges[i];

      if (colorRange.range_type === 'between' && resource >= colorRange.starts && resource <= colorRange.ends) {
        color = colorRange.color;
      }
      if (colorRange.range_type === 'equal_to' && resource === colorRange.starts) {
        color = colorRange.color;
      }
      if (colorRange.range_type === 'greater_than' && resource > colorRange.starts) {
        color = colorRange.color;
      }
      if (colorRange.range_type === 'greater_than_or_equal_to' && resource >= colorRange.starts) {
        color = colorRange.color;
      }
      if (colorRange.range_type === 'less_than' && resource < colorRange.starts) {
        color = colorRange.color;
      }
      if (colorRange.range_type === 'less_than_or_equal_to' && resource <= colorRange.starts) {
        color = colorRange.color;
      }
    }
    return color;
  }

  const showStrategics = (element) => {
    let updatedData = perspectives.map((perspective) => {
      if (perspective.id === element.id) {
        return { ...perspective, show: !perspective.show }
      } else {
        return { ...perspective }
      }
    })
    setPerspectives(updatedData)
  }

  const showOperatives = (element) => {
    let updatedData = perspectives.map((perspective) => {
      let dupStrategics = perspective.strategic_objectives.map((strategic) => {
        if (strategic.id === element.id) {
          return { ...strategic, show: !strategic.show }
        } else {
          return { ...strategic }
        }
      })
      return { ...perspective, strategic_objectives: dupStrategics };
    })
    setPerspectives(updatedData)
  }

  const showIndicators = (element) => {
    let updatedData = perspectives.map((perspective) => {
      let dupStrategics = perspective.strategic_objectives.map((strategic) => {
        let dupOperatives = strategic.operative_objectives.map((operative) => {
          if (operative.id === element.id) {
            return { ...operative, show: !operative.show }
          } else {
            return { ...operative }
          }
        })
        return { ...perspective, ...strategic, operative_objectives: dupOperatives}
      })
      return { ...perspective, strategic_objectives: dupStrategics };
    })
    setPerspectives(updatedData)
  }

  return (
    <>
      <table className='table table-bordered table-striped'>
        <thead>
          { <TableHeaders/> }
        </thead>
        <tbody>
          { perspectives.length > 0 && perspectives.map((perspective) => {
              return (
                <React.Fragment key={perspective?.id}>
                  <tr>
                    <td>
                      <button className='btn btn-link' onClick={() => showStrategics(perspective)}>
                        <i className="fas fa-sitemap mr-3 text-warning"></i>
                        <span className='text-dark'>{perspective.name}</span>
                      </button>
                    </td>
                    <td className='text-center'><small>{`${props.bsc.start_date} - ${props.bsc.end_date}`}</small></td>
                    <td></td>
                    <td></td>
                    <td className='text-center'>{`${perspective.percentage?.toFixed(2)} %`}</td>
                    <td className='text-center align-middle'>
                      <div style={{ backgroundColor: ColorRangeEvaluator(perspective.percentage), width: '20px', height: '20px', borderRadius: '50%' }}></div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  { perspective.show && perspective.strategic_objectives.map((strategic) => {
                      return (
                        <React.Fragment key={strategic.id}>
                          <tr>
                            <td>
                              <button className='btn btn-link d-flex' onClick={() => showOperatives(strategic)}>
                                <i className="fas fa-bullseye mx-3 text-primary"></i>
                                <span className='text-dark text-justify'>{strategic.name}</span>
                              </button>
                            </td>
                            <td className='text-center'><small>{`${props.bsc.start_date} - ${props.bsc.end_date}`}</small></td>
                            <td></td>
                            <td></td>
                            <td className='text-center'>{`${strategic.percentage?.toFixed(2)} %`}</td>
                            <td className='text-center align-middle'>
                              <div style={{ backgroundColor: ColorRangeEvaluator(strategic.percentage), width: '20px', height: '20px', borderRadius: '50%' }}></div>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          { strategic.show && strategic.operative_objectives.map((operative) => {
                              return (
                                <React.Fragment key={operative.id}>
                                  <tr>
                                    <td className='px-3'>
                                      <button className='btn btn-link mx-3 d-flex' onClick={() => showIndicators(operative)}>
                                        <i className="fas fa-bullseye mx-3 text-info"></i>
                                        <span className='text-dark text-justify'>{operative.name}</span>
                                      </button>
                                    </td>
                                    <td className='text-center'><small>{`${props.bsc.start_date} - ${props.bsc.end_date}`}</small></td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-center'>{`${operative.percentage?.toFixed(2)} %`}</td>
                                    <td className='text-center align-middle'>
                                      <div style={{ backgroundColor: ColorRangeEvaluator(operative.percentage), width: '20px', height: '20px', borderRadius: '50%' }}></div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                  {
                                    operative.show && operative.indicators.map((indicator) => {
                                      return (
                                        <React.Fragment key={indicator.id}>
                                          <tr>
                                            <td>
                                              <div className='d-flex'>
                                                <i className="fas fa-chart-line pl-80 text-info"></i>
                                                <span className='text-dark mx-3 text-justify'>{indicator.name}</span>
                                              </div>
                                            </td>
                                            <td className='text-center'><small>{`${props.bsc.start_date} - ${props.bsc.end_date}`}</small></td>
                                            <td className='text-center'>{indicator.result}</td>
                                            <td className='text-center'>{indicator.goal}</td>
                                            <td className='text-center'>{indicator.percentage == undefined ? '' : `${indicator.percentage?.toFixed(2)} %`}</td>
                                            <td className='text-center align-middle'>
                                              <div style={{ backgroundColor: ColorRangeEvaluator(indicator.percentage), width: '20px', height: '20px', borderRadius: '50%' }}></div>
                                            </td>
                                            <td className='text-center'>{`${indicator.average?.toFixed(2)} %`}</td>
                                            <td className='text-center'>{`${indicator.accumulated?.toFixed(2)} %`}</td>
                                            <td className='text-center'>
                                              <div className='d-flex'>
                                                <a className='btn btn-sm btn-outline-primary mr-1' href={`/indicators/${indicator.id}/charts`} target="_blank">📊</a>
                                                <a className='btn btn-sm btn-outline-success' href={`/indicators/${indicator.id}/edit`} target="_blank">
                                                  <i className='fas fa-edit'></i>
                                                </a>
                                              </div>
                                            </td>
                                          </tr>
                                        </React.Fragment>
                                      )
                                    })
                                  }
                                </React.Fragment>
                              )
                            })
                          }
                        </React.Fragment>
                      )
                    }) // strategic_objectives.map
                  }
                </React.Fragment>
              ) // return
            }) // perspectives.map
          }
        </tbody>
      </table>
    </>
  )
}

export default BscMetricDetails