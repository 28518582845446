import React, { useState, useEffect} from "react";
import { LaboratoriesContext } from "../LaboratoriesContext";
import { InputGroup } from "../../InputGroup";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function EditReport(){
  const {
    setOpenModalEditReport,
    dataLaboratory,
    updateLaboratory
  } = React.useContext(LaboratoriesContext)
  const [address, setAdress] = useState(dataLaboratory?.address || '')
  const [nameCompanyName, setNameCompanyName] = useState(dataLaboratory?.name_company_name || '')
  const [indentification, setIndentification] = useState(dataLaboratory?.indentification || '')
  const [phone, setPhone] = useState(dataLaboratory?.phone || '')
  const [email, setEmail] = useState(dataLaboratory?.email || '')
  const [date, setDate] = useState(
    dataLaboratory?.date ? new Date(dataLaboratory.date) : new Date()
  );
  const [addReportModificationComment, setAddReportModificationComment] = useState(dataLaboratory?.add_report_modification_comment || '')
  const [contactPerson, setContactPerson] = useState(dataLaboratory?.contact_person || '')
  const [disabledSubmit, setDisabledSubmit] = useState(false)

  const onUpdate = (e) => {
    e.preventDefault()
    setDisabledSubmit(true)
    const dataupdateLaboratory = {
      address: address,
      name_company_name: nameCompanyName,
      indentification: indentification,
      phone: phone,
      email: email,
      date: date,
      add_report_modification_comment: addReportModificationComment,
      type: 'update_report',
      constact_person: contactPerson
    }
    setTimeout(() => {
      setDisabledSubmit(false);
    }, 4000);
    updateLaboratory(dataLaboratory.id, dataupdateLaboratory)
  }

  const onCancel = () => {
    setOpenModalEditReport(false);
  }

  return(
    <div className="modal fade show" tabIndex="-1" style={{ paddingRight: '4px', display: 'block', backgroundColor: 'rgba(3, 3, 3, 0.5)' }} role="document">
      <form onSubmit={onUpdate}>
        <div className="modal-dialog modal-dialog-scrollable" role="document" style={{ maxWidth: '700px' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edición de información de solicitante</h5>
            </div>
            <div className="modal-body">
              <InputGroup iconClass="fas fa-map-pin" label="Dirección (*)">
                <input
                  type="text"
                  className="form-control"
                  name="Dirección"
                  placeholder="Dirección"
                  value={address}
                  onChange={(e) => setAdress(e.target.value)}
                />
              </InputGroup>
              <InputGroup iconClass="fas fa-user" label="Nombre o Razón Social (*)">
                <input
                  type="text"
                  className="form-control"
                  name="Nombre o Razón Social"
                  placeholder="Nombre o Razón Social"
                  value={nameCompanyName}
                  onChange={(e) => setNameCompanyName(e.target.value)}
                />
              </InputGroup>
              <InputGroup iconClass="fas fa-id-card" label="Cédula o RUC (*)">
                <input
                  type="text"
                  className="form-control"
                  name="Cédula o RUC"
                  placeholder="Cédula o RUC"
                  value={indentification}
                  onChange={(e) => setIndentification(e.target.value)}
                />
              </InputGroup>
              <InputGroup iconClass="fas fa-mobile-alt" label="Teléfono de Contacto (*)">
                <PhoneInput
                  value={phone}
                  defaultCountry="CO"
                  onChange={(phone) => setPhone(phone)}
                  className="form-control"
                />
              </InputGroup>
              <InputGroup iconClass="fas fa-envelope" label="Correo Electrónico (*)">
                <input
                  type="email"
                  className="form-control"
                  name="Correo Electrónico"
                  placeholder="Correo Electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
              <InputGroup iconClass="fas fa-calendar" label="Fecha (*)">
                <DatePicker
                  selected={date}
                  onChange={(current_date) => setDate(current_date)}
                  showMonthDropdown
                  className="form-control"
                />
              </InputGroup>
              <InputGroup iconClass="fas fa-id-card" label="Persona de Contacto (*)">
                <input
                  type="text"
                  className="form-control"
                  name="Persona de conctacto"
                  placeholder="Persona de conctacto"
                  value={contactPerson}
                  onChange={(e) => setContactPerson(e.target.value)}
                />
              </InputGroup>
              <InputGroup iconClass="fas fa-window-restore" label="Razón por modificación del informe">
                <textarea
                  className="form-control"
                  value={addReportModificationComment}
                  onChange={(e) => setAddReportModificationComment(e.target.value)}
                />
              </InputGroup>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-link'
                onClick={onCancel}
              >
                Cancelar
              </button>
              <button
                className='btn btn-primary mr-1'
                type="submit"
                disabled={disabledSubmit}
              >
                Actualizar Informe
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export { EditReport }