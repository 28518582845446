import React, { useState } from 'react'
import CreateObjectiveForm from './CreateObjectiveForm'
import AssociateObjectiveForm from './AssociateObjectiveForm'

const OperativeObjectiveForm = (props) => {
  const [create, setCreate] = useState(null)

  const Header = () => {
    return (
      <>
        <div className="card border-0" data-sidebar-section="js-environmental-management" data-sidebar-menu="js-environmental-impacts-menu">
          <div className="card-header d-flex justify-content-between position-sticky sticky-top bg-dark">
            <div className="card-title mb-0 d-flex align-items-center">
              <a className="btn btn-link" href={`/bsc_perspectives/${props.perspective_id}/strategic_objectives/${props.operative_objective.strategic_objective_id}/operative_objectives`}>
                <i className="fas fa-arrow-left"></i>
              </a>
              <h5 className="mb-0 text-white">Objetivo Operativo</h5>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      { create === null && <Header/>}

      { create === null && <div className='card-body'>
        <div className='form-row'>
          <div className='col-6 h-80vh rounded'>
            <button className='btn btn-light btn-lg btn-block h-80vh' onClick={() => setCreate(true)}>
              <span className='font-weight-light d-block mb-3'>Crear Objetivo</span>
              <i className='text-white fas fa-cog rotate font-size-200'></i>
            </button>
          </div>
          <div className='col-6 h-80vh rounded'>
            <button className='btn btn-light btn-lg btn-block h-80vh' onClick={() => setCreate(false)}>
              <span className='font-weight-light d-block mb-3'>Asociar Objetivo</span>
              <i className='text-white fas fa-list-ul font-size-200'></i>
            </button>
          </div>
        </div>
      </div>}
      { create !== null && create && <CreateObjectiveForm operative_objective={props.operative_objective}
                                                          perspective_id={props.perspective_id}/> }
      { create !== null && !create && <AssociateObjectiveForm operative_objective={props.operative_objective}
                                                              perspective_id={props.perspective_id}/> }
    </>
  )
}

export default OperativeObjectiveForm