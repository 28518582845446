import React from "react";

const TrMissingActivity = ({ impact, actions }) => {
  function setRowspan(impact) {
    return impact.impact_actions.length;
  }

  if (!actions || actions.length === 0) {
    return (
      <tr key={`impact-${impact.id}`}>
        <td className="align-middle text-center">
          {impact.all_procedures ? (
            <span>Todos los procesos</span>
          ) : (
            <>
              {impact.procedures.map((procedure) => (
                <span className="d-block" key={procedure.id}>
                  {procedure.title}
                </span>
              ))}
            </>
          )}
        </td>

        {/* activity */}
        <td></td>
        <td></td>
        <td></td>
        <td></td>

        {/* aspect */}
        <td></td>
        <td></td>

        {/* evaluation */}
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>

        {/* action */}
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  } else {
    return (
      <>
        {actions.map((action, actionIndex) => (
          <tr key={`impact-${impact.id}-action-${action.id}`}>
            {actionIndex === 0 && (
              <td
                className="align-middle text-center"
                rowSpan={setRowspan(impact)}
              >
                {impact.all_procedures ? (
                  <span>Todos los procesos</span>
                ) : (
                  <>
                    {impact.procedures.map((procedure) => (
                      <span className="d-block" key={procedure.id}>
                        {procedure.title}
                      </span>
                    ))}
                  </>
                )}
              </td>
            )}

            {/* activity */}
            <td></td>
            <td></td>
            <td></td>
            <td></td>

            {/* aspect */}
            <td></td>
            <td></td>

            {/* evaluation */}
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>

            {/* action */}
            <td className="text-center align-middle">{action.action_title}</td>

            <td className="text-center align-middle">
              {action.action_responsible}
            </td>

            <td className="text-center align-middle">
              {action.action_start_date}
            </td>

            <td className="text-center align-middle">
              {action.action_end_date}
            </td>

            <td className="text-center align-middle">
              {action.action_evidence}
            </td>
          </tr>
        ))}
      </>
    );
  }
};

export default TrMissingActivity;
