import React from "react";

function CollapseTableType({laboratory}){
  return (
    <div className="card border-info mb-3">
      <div className="card-header">
        <h5 className="card-title mb-0 mt-1 font-weight-bold">
          INFORMACIÓN DE SOLICITUD
        </h5>
      </div>
      <div className="card-text mb-2 text-dark font-weight-normal p-md-2">
        <div className="row">
          <div className="col md-2">
            <div className="card border-success ml-2 shadow-sm">
              <div className="card-header text-center p-md-1 align-middle">
                <h6 className="font-weight-bold">
                  Pruebas seleccionadas
                </h6>
              </div>
              <div className="card-body text-center">
                {laboratory.request_tests.map((request, index) => (
                  <p key={index}>
                    {index+1}: {request.name}
                  </p>  
                ))}
              </div>
            </div>
          </div>
          <div className="col md-2">
            <div className="card border-success ml-2 shadow-sm">
              <div className="card-header text-center p-md-1 align-middle">
                <h6 className="font-weight-bold">
                  Descripción Solicitud
                </h6>
              </div>
              <div className="card-body text-center">
                {laboratory.description_application}
              </div>
            </div>
          </div>
          <div className="col md-2">
            <div className="card border-success ml-2 shadow-sm">
              <div className="card-header text-center p-md-1 align-middle">
                <h6 className="font-weight-bold">
                  Cantidad por Lotes
                </h6>
              </div>
              <div className="card-body text-center">
                {laboratory.number_lots}
              </div>
            </div>
          </div>
          <div className="col md-2">
            <div className="card border-success ml-2 shadow-sm">
              <div className="card-header text-center p-md-1 align-middle">
                <h6 className="font-weight-bold">
                  Unidades por Lote
                </h6>
              </div>
              <div className="card-body text-center">
                {laboratory.units_lot}
              </div>
            </div>
          </div>
          <div className="col md-2">
            <div className="card border-success ml-2 shadow-sm">
              <div className="card-header text-center p-md-1 align-middle">
                <h6 className="font-weight-bold">
                  Descripción Punto Toma de Muestra
                </h6>
              </div>
              <div className="card-body text-center">
                {laboratory.description_sample_collection}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { CollapseTableType }