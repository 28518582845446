import { Controller } from 'stimulus'

export default class extends Controller {
  // connect () {
  //   this.onChange()
  // }

  onChange (e) {
    if (e) e.preventDefault()
    const select = this.element;
    const level = (select.id === 'service') ? 2 : 3;
    const change = (select.id === 'service') ? "macroprocedure" : "procedure";

    var selectmacro = document.getElementById(change);
    var selectprocedure = document.getElementById('procedure');
    const url = "/risk_schemes/options?id="+select.value+"&type="+level; // Ruta configurada en las rutas de Rails

    fetch(url)
      .then(response => response.json())
      .then(data => {
        // Limpiar opciones actuales
        while (selectmacro.firstChild) {
          selectmacro.removeChild(selectmacro.firstChild);
        }

        const noSeleccionarOption = document.createElement("option");
        noSeleccionarOption.value = "";  // Puedes establecer el valor según tus necesidades
        noSeleccionarOption.text = "No Seleccionar";
        selectmacro.appendChild(noSeleccionarOption);

        // // Agregar nuevas opciones basadas en los datos obtenidos
        if ( level === 2 ){
          var idProcedure =  select.value === '' ? "" : data[0][0];
          this.chageprocedure(idProcedure, selectprocedure)
        }

        data.forEach(opcion => { 
          const option = document.createElement("option");
          option.value = opcion[0];
          option.text = opcion[1];
          selectmacro.appendChild(option);
        });
      })
      .catch(error => console.error("Error al cargar datos:", error));
  }

  chageprocedure (data, selectprocedure)
  {
    const url = "/risk_schemes/options?id="+data+"&type="+3; // Ruta configurada en las rutas de Rails

    fetch(url)
      .then(response => response.json())
      .then(data => {
        // Limpiar opciones actuales
        while (selectprocedure.firstChild) {
          selectprocedure.removeChild(selectprocedure.firstChild);
        }

        const noSeleccionarOption = document.createElement("option");
        noSeleccionarOption.value = "";  // Puedes establecer el valor según tus necesidades
        noSeleccionarOption.text = "No Seleccionar";
        selectprocedure.appendChild(noSeleccionarOption);


        data.forEach(opcion => { 
          const option = document.createElement("option");
          option.value = opcion[0];
          option.text = opcion[1];
          selectprocedure.appendChild(option);
        });
      })
      .catch(error => console.error("Error al cargar datos:", error));
  }

}
