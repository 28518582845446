import React, {useState, useEffect} from "react"
import ProfilePicture from "./Pictures/ProfilePicture"
import Spinner from './Pictures/Spinner'
import Dropdown from "./Pictures/Dropdown"
const Assignments = (props) => {
  const [main, setMain] = useState(null)
  /*const [showing, setShowing] = useState(false)*/
  const [showDropdown, setShow] = useState(false)
  const [assignments, setAssignments] = useState([])

  useEffect(() => {
    fetch('/api/v1/produces/main?user='+props.user).then((response)=>{
      return response.json()
    }).then((data) => {
      setMain(data[0])
      setAssignments(data)
      })
  }, [])

  const hoverCallback = (value) => {
    setShow(value)
    /*setShowing(value)*/
  }

  /*const hideAssignments = () => {
    setTimeout(function(){
      if(!showing){
        setShow(false)
      }
    }, 100);
  }*/

  return (
      <React.Fragment>
        <div className='row align-items-center justify-content-center' onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
          {!main && <Spinner/>}
          {main && <ProfilePicture url={main.url} hover={showDropdown} initials={main.initials} full_name={main.full_name}></ProfilePicture>}
          {assignments.length > 1 && <div className='assignment-counter'>+ {assignments.length - 1}</div>}
        </div>
        {showDropdown && assignments.length > 1 && <Dropdown assignments={assignments} hover={hoverCallback}/>}
      </React.Fragment>
  )
}

export default Assignments
