import React from "react";

const IntensityModal = () => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th className="align-middle text-center">
            PARÁMETROS DE CALIFICACIÓN CUALITATIVA
          </th>
          <th className="align-middle text-center"> CALIFICACIÓN </th>
          <th className="align-middle text-center"> GRADO DE SIGNIFICANCIA </th>
          <th className="align-middle text-center"> DESCRIPCIÓN </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="align-middle text-justify" rowSpan="5">
            INTENSIDAD - (IN) Califica la dimensión o tamaño del cambio
            ambiental producido por una actividad o proceso constructivo u
            operativo
          </td>
          <td className="align-middle text-center">BAJA</td>
          <td className="align-middle text-center">1</td>
          <td className="align-middle text-justify">
            Se presenta una alteración mínima del elemento evaluado
          </td>
        </tr>
        <tr>
          <td className="align-middle text-center">MEDIA</td>
          <td className="align-middle text-center">2</td>
          <td className="align-middle text-justify">
            Algunas de las características del elemento cambian completamente
          </td>
        </tr>
        <tr>
          <td className="align-middle text-center">ALTA</td>
          <td className="align-middle text-center">4</td>
          <td className="align-middle text-justify">
            El elemento cambia sus principales características, aunque aun puede
            recuperar
          </td>
        </tr>
        <tr>
          <td className="align-middle text-center">MUY ALTA</td>
          <td className="align-middle text-center">8</td>
          <td className="align-middle text-justify">
            El elemento sus características, con difícil recuperación
          </td>
        </tr>
        <tr>
          <td className="align-middle text-center">TOTAL</td>
          <td className="align-middle text-center">12</td>
          <td className="align-middle text-justify">
            Se presenta una destrucción total del elemento
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default IntensityModal;
