import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'day', 'week', 'month', 'year']

  onSubmit () {
    this.formTarget.submit()
  }

  dayButton () {
    $('.date-check:checkbox').prop('checked', false)
    this.dayTarget.checked = true
    this.onSubmit()
  }

  weekButton () {
    $('.date-check:checkbox').prop('checked', false)
    this.weekTarget.checked = true
    this.onSubmit()
  }

  monthButton () {
    $('.date-check:checkbox').prop('checked', false)
    this.monthTarget.checked = true
    this.onSubmit()
  }

  yearButton () {
    $('.date-check:checkbox').prop('checked', false)
    this.yearTarget.checked = true
    this.onSubmit()
  }
}
