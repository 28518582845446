import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['kaizen']

  connect () {
    this.verificar()
  }

  hideRead (e) {
    if (e) e.preventDefault()
    if ($(this.kaizenTarget).prop('checked')) { $('#select_opportunity').prop('disabled', false)} else { $('#select_opportunity').prop('disabled', true) }
  }
}
