import { Controller }  from 'stimulus'

export default class extends Controller {

  onChange() {
    
    const nameJQuery = $(this.element).attr('name');
    var checkboxes = $(`.group-${nameJQuery}`);
    if ($(this.element).prop('checked')) {
      checkboxes.prop('checked',true);
    } else {
      checkboxes.prop('checked', false);
    }
  }

  Charges(e) {

    const container = document.getElementById("form");
    const formId = container.getAttribute("name");
    const filePath2 = `evaluations/${formId}/form_user?`;
    if ($('#charge').prop('checked')) {
      fetch(filePath2+'charges=true')
        .then(response => response.text())
        .then(htmlContent => {
          container.innerHTML = htmlContent;
        })
        .catch(error => {
          console.error('Error al cargar el archivo:', error);
        });

    } else {
      fetch(filePath2+'charges=false')
        .then(response => response.text())
        .then(htmlContent => {
          container.innerHTML = htmlContent;
        })
        .catch(error => {
          console.error('Error al cargar el archivo:', error);
        });
    }

  }
  
}