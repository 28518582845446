import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['position', 'fillTask', 'selectTask', 'qualification']

  connect () {
    this.onChange()
  }

  onChange (e) {
    if (e) e.preventDefault()

    let { assessments } = this.element.dataset
    assessments = JSON.parse(assessments) // array
    const values = assessments.map(({ value }) => value)

    if ($(this.positionTarget).prop('checked')) {
      $('#positionField').show()
    } else {
      $('#positionField').hide()
    }
    this.hideAll()
    if ($(this.fillTaskTarget).prop('checked')) {
      $('#fillOutForm').show()
    } else if ($(this.selectTaskTarget).prop('checked')) {
      $('#selectForm').show()
    }
  }

  hideAll (e) {
    if (e) e.preventDefault()
    $('#selectForm').hide()
    $('#fillOutForm').hide()
  }

  setLevel (e) {
    if (e) e.preventDefault()
    let { assessments } = this.element.dataset
    assessments = JSON.parse(assessments)
    var value = this.qualificationTarget.value

    for(var i = 0; i < assessments.length; i++) {
      if(value > assessments[i].value) {
        document.getElementById(assessments[i].id).textContent = 'Mayor'
      } else if (value === assessments[i].value) {
        document.getElementById(assessments[i].id).textContent = 'Cumple'
      } else {
        document.getElementById(assessments[i].id).textContent = 'Menor'
      }
    }
  }
}
