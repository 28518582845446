import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button', 'containerShow', 'containerForm']

  editConvenience () {
    this.containerShowTarget.style.display = 'none'
    this.containerFormTarget.style.display = 'block'
    $('#saveConvenienceButton').attr('form', 'FormFor' + this.buttonTarget.value)
    $('#newConvenienceButton').hide()
    $('#saveConvenienceButton').show()
  }

  editAdequacy () {
    this.containerShowTarget.style.display = 'none'
    this.containerFormTarget.style.display = 'block'
    $('#saveAdequacyButton').attr('form', 'FormFor' + this.buttonTarget.value)
    $('#newAdequacyButton').hide()
    $('#saveAdequacyButton').show()
  }

  editEficacy () {
    this.containerShowTarget.style.display = 'none'
    this.containerFormTarget.style.display = 'block'
    $('#saveEficacyButton').attr('form', 'FormFor' + this.buttonTarget.value)
    $('#newEficacyButton').hide()
    $('#saveEficacyButton').show()
  }

  editAlignment () {
    this.containerShowTarget.style.display = 'none'
    this.containerFormTarget.style.display = 'block'
    $('#saveAlignmentButton').attr('form', 'FormFor' + this.buttonTarget.value)
    $('#newAlignmentButton').hide()
    $('#saveAlignmentButton').show()
  }

  editOther () {
    this.containerShowTarget.style.display = 'none'
    this.containerFormTarget.style.display = 'block'
    $('#saveOtherButton').attr('form', 'FormFor' + this.buttonTarget.value)
    $('#newOtherButton').hide()
    $('#saveOtherButton').show()
  }

  editOpportunityAction () {
    this.containerShowTarget.style.display = 'none'
    this.containerFormTarget.style.display = 'block'
    $('#saveOpportunityActionButton').attr('form', 'FormFor' + this.buttonTarget.value)
    $('#newOpportunityActionButton').hide()
    $('#saveOpportunityActionButton').show()
  }

  editNeedAction () {
    this.containerShowTarget.style.display = 'none'
    this.containerFormTarget.style.display = 'block'
    $('#saveNeedActionButton').attr('form', 'FormFor' + this.buttonTarget.value)
    $('#newNeedActionButton').hide()
    $('#saveNeedActionButton').show()
  }
}
