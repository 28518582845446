import React from "react";
import { serviceLaboratory } from "./serviceLaboratory";
import axios from "axios";

const LaboratoriesContext = React.createContext()

function LaboratoriesProvider({children}){
  const {
    loading,
    error,
    laboratory,
    setLaboratory
  } = serviceLaboratory()
  const [openModal, setOpenModal] = React.useState(false)
  const [errorResponse, setErrorResponse] = React.useState([])
  const [dataLaboratory, setDataLaboratory] = React.useState([])
  const [openModalApproved, setOpenModalApproved] = React.useState(false)
  const [openModalSampling, setOpenModalSampling] = React.useState(false)
  const [openModalTestExecution, setOpenModalTestExecution] = React.useState(false)
  const [openModalApprovalOfTest, setOpenModalApprovalOfTest] = React.useState(false)
  const [openModalSendAndClose, setOpenModalSendAndClose] = React.useState(false)
  const [openModalEditReport, setOpenModalEditReport] = React.useState(false)

  const createLaboratory = async (newLaboratory) => {
    try {
      const response = await axios.post("/api/v2/laboratories", newLaboratory)
      console.log("Laboratorio creado:", response.data);
      setErrorResponse([])
    } catch (error){
      setErrorResponse(error.response.data.error)
    }
  }

  const findLaboratory = async (idLaboratory, modal) =>{
    try{
      const response = await axios.get(`api/v2/laboratories/${idLaboratory}/edit`)
      switch(modal){
        case 'approve':
          setOpenModalApproved(true)
          setOpenModalSampling(false)
          setOpenModalTestExecution(false)
          setOpenModalApprovalOfTest(false)
          setOpenModalSendAndClose(false)
          setOpenModalEditReport(false)
          break;
        case 'samplingAssignment':
          setOpenModalSampling(true)
          setOpenModalApproved(false)
          setOpenModalTestExecution(false)
          setOpenModalApprovalOfTest(false)
          setOpenModalSendAndClose(false)
          setOpenModalEditReport(false)
          break;
        case 'testExecution':
          setOpenModalTestExecution(true)
          setOpenModalSampling(false)
          setOpenModalApproved(false)
          setOpenModalApprovalOfTest(false)
          setOpenModalSendAndClose(false)
          setOpenModalEditReport(false)
          break;
        case 'approvalOfTests':
          setOpenModalApprovalOfTest(true)
          setOpenModalTestExecution(false)
          setOpenModalSampling(false)
          setOpenModalApproved(false)
          setOpenModalSendAndClose(false)
          setOpenModalEditReport(false)
          break;
        case 'awaitingPresentationAndClosure':
          setOpenModalSendAndClose(true)
          setOpenModalApprovalOfTest(false)
          setOpenModalTestExecution(false)
          setOpenModalSampling(false)
          setOpenModalApproved(false)
          setOpenModalEditReport(false)
          break;
        case 'editReport':
          setOpenModalEditReport(true)
          setOpenModalSendAndClose(false)
          setOpenModalApprovalOfTest(false)
          setOpenModalTestExecution(false)
          setOpenModalSampling(false)
          setOpenModalApproved(false)
          break;
      }
      setDataLaboratory(response.data)
    } catch (error) {
      console.log(error)
      console.log('se generó un error')
    }
  }

  const updateLaboratory = async (id, dataLaboratory) =>{
    try{
      const response = await axios.patch(`api/v2/laboratories/${id}`, dataLaboratory)
      setErrorResponse([])
      window.location.href = "/laboratories";
    } catch (error){
      setErrorResponse(error.response.data.errors)
    }
  }

  const destroyLaboratory = async (id) => {
    try {
      const response = await axios.delete(`/api/v2/laboratories/${id}`);
      console.log('response ', response)
      if (response.status === 204) {
        window.location.href = "/laboratories";
      } else {
        console.error('Error al eliminar: respuesta no exitosa');
      }
    } catch (error) {
      console.error('Error al eliminar:', error);
    }
  }

  const filterData = async (typeFilter) =>{
    try{
      const url = `/api/v2/laboratories?type=${typeFilter}`;
      const response = await axios.get(`${url}`)
      if (response.status === 200){
        const data = await response.data
        setLaboratory(data)
      } else {
        throw new Error('Network response was not ok.')
      }
    } catch(error){
      console.error('Error al selecionar');
    }
  }

  return(
    <LaboratoriesContext.Provider
      value={{
        loading,
        error,
        laboratory,
        errorResponse,
        dataLaboratory,
        openModal,
        createLaboratory,
        findLaboratory,
        updateLaboratory,
        destroyLaboratory,
        openModalApproved,
        setOpenModalApproved,
        openModalSampling,
        setOpenModalSampling,
        openModalTestExecution,
        setOpenModalTestExecution,
        openModalApprovalOfTest,
        setOpenModalApprovalOfTest,
        openModalSendAndClose,
        setOpenModalSendAndClose,
        filterData,
        openModalEditReport,
        setOpenModalEditReport
      }}
    >
      {children}
    </LaboratoriesContext.Provider>
  )
}

export { LaboratoriesContext, LaboratoriesProvider }