import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'
import Rails from '@rails/ujs'

export default class extends Controller {


  saveForm()
  {
    const elem = document.getElementById(' mspiinformationForm') // or $('#myform')[0] with jQuery
    Rails.fire(elem, 'submit');
  }

  onAssigneSave (e) {
    this.saveForm()
  }

}
