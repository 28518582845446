import { Controller } from 'stimulus'
import 'select2'
import 'select2/dist/js/i18n/es'

export default class extends Controller {
  connect () {
    const placeholder = this.data.get('placeholder')
    const url = this.data.get('url')
    const width = this.data.get('width')
    const { error } = this.element.dataset
    let ajax
    let minimumInputLength
    let allowClear
    // let modalParent = document.querySelector('div .modal.show.fade');
    let parent = $(this.element).parent()

    if (url) {
      minimumInputLength = 1
      allowClear = true
      ajax = {
        url: url,
        dataType: 'json',
        data: this.handleAjaxParams
      }
    }
    if ((new RegExp('modal').test(this.element.id)))
    {
      $(this.element).select2({
        width,
        language: 'es',
        theme: `bootstrap4 ${error ? 'select2-error' : ''}`,
        minimumInputLength,
        placeholder,
        allowClear,
        ajax
      })
    }
    else if (new RegExp('modal').test(this.element.name))
    {
      $(this.element).select2({
        width,
        language: 'es',
        theme: `bootstrap4 ${error ? 'select2-error' : ''}`,
        minimumInputLength,
        placeholder,
        allowClear,
        ajax
      })
    }
    else{
      $(this.element).select2({
        width,
        language: 'es',
        theme: `bootstrap4 ${error ? 'select2-error' : ''}`,
        minimumInputLength,
        placeholder,
        allowClear,
        dropdownParent: parent,
        ajax
      })
    }

    

    $(this.element).on('select2:select select2:clear', () => {
      const event = new Event('change', { bubbles: true })
      this.element.dispatchEvent(event)
    })
  }

  handleAjaxParams = ({ term }) => {
    let params = JSON.parse(this.data.get('params'))

    if (params) {
      const keys = Object.keys(params)
      params = keys.reduce((acc, elementId) => {
        const paramName = params[elementId]
        const paramElement = document.getElementById(elementId)

        if (!paramElement) {
          return acc
        }

        return { ...acc, [paramName]: paramElement.value }
      }, {})
    }

    return {
      ...params,
      term
    }
  }
}
