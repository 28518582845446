import { Controller } from 'stimulus'
import 'bootstrap/js/dist/modal'

export default class extends Controller {
  static targets = ['content', 'title', 'buttonsubmit', 'buttoncancel']

  connect () {
    $(this.element).on('shown.bs.modal', () => {
      const autofocusedElements = document.querySelectorAll('input[autofocus]')
      if (autofocusedElements.length) {
        autofocusedElements[0].focus()
      }
    })
  }

  set content (content) {
    this.contentTarget.innerHTML = content
  }

  set title (title) {
    this.titleTarget.innerHTML = title
  }

  show () {
    $(this.element).modal('show')
  }

  submitted()
  {
    $(this.buttoncancelTarget).prop('className', 'btn btn-link disabled')
    $(this.buttonsubmitTarget).prop('className', 'btn btn-primary disabled')
    $('#actions_button').prop('className', 'btn btn-success disabled')
  }

  hidden()
  {
    $("#downloadModal").modal('hide'); //ocultamos el modal
    $('body').removeClass('modal-open'); //eliminamos la clase del body para poder hacer scroll
    $('.modal-backdrop').remove(); //eliminamos el backdrop del modal
  }

  closeModal()
  {
    var modal = $('#ModalPassword');
    modal.hide();
  }

  openModal(event) {
    const form = document.getElementById('obsoleteForm');
    const id = event.currentTarget.dataset.id;

    // Update form action using template literals and optional chaining for safety:
    const newUrl = `documents/${id}/expire`;
  
    // Actualiza la URL del formulario
    form.setAttribute('action', newUrl);
  }

  submitResponse()
  {
    const formID = $(this.buttonsubmitTarget).attr('form')
    $.ajax({
      url : $('#'+formID).attr('action'),
      dataType : 'json',
      data : $('#'+formID).serialize(),
      type: 'PATCH',
      success : function (response) {
        const elementId = response.id
        for(var i in response){
          if (i!='id'){
            if (i=='file'){
              let button = '<a disposition="attachment" class="btn btn-secondary btn-sm mr-1" target="_blank" href = "' + response[i] +'"><i class="far fa-file"></i></a>'
              $('#'+i+elementId).empty()
              $('#'+i+elementId).append(button)
            } else {
              $('#'+i+elementId).html(response[i])
            }
          }
        }
      }
    })
    $(this.element).modal('hide')
  }
}
