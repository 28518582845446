import React, { useEffect, useState } from 'react'
import Chart from "./Chart"
import ProgressLine from "./ProgressLine"
const General = ({ name, average, counts }) => {
  const [color, setColor] = useState('#fff')
  useEffect(() => {
    fetch('/api/v1/mipg_metrics/achievement_level_color'+'?value='+average).then((response)=>{
    return response.json()
    }).then((data) => {
      setColor(data[0].color)
    })
  }, [average])

  return (
    <>
      <h2 className='cart-title text-center'>{name}</h2>
      <div className='my-3'>
        {Boolean(average) && <Chart
          progress={average}
          size={80}
          strokeWidth={6}
          circleTwoStroke={color}
        />}
      </div>
      <ProgressLine percentage={average} color={color} text='Líneas Estratégicas: ' count={counts}/>
    </>
  )

}

export default General