import { Controller } from 'stimulus'

export default class extends Controller {
  open (e) {
    e.preventDefault()
    const url = this.data.get('url')
    const modalTitle = this.data.get('modal-title')
    const loading = this.data.get('loading')
   
    if (modalTitle) { this.modal.title = modalTitle }

    this.modal.show()

    if (url) {
      this.modal.content = loading

      $.get(url).then((content) => {
        this.modal.content = content
      })
    }
  }

  get modal () {
    const modalId = this.data.get('modal-id')
    const element = document.getElementById(modalId)

    return this.application.getControllerForElementAndIdentifier(element, 'modal')
  }
}
