import React from "react";

const TrMissingAction = ({
  impact,
  activity,
  activityIndex,
  aspect,
  aspectIndex,
  evaluation,
  evalIndex,
  actions,
}) => {
  function setRowspanImpactWithActions() {
    return (
      impact.impact_actions.length *
      impact.activities.reduce(
        (acc, activity) =>
          acc +
          activity.aspects.reduce(
            (aspectAcc, aspect) => aspectAcc + aspect.evaluations.length,
            0
          ),
        0
      )
    );
  }

  function setRowspanImpactWithoutActions() {
    return impact.activities.reduce(
      (acc, activity) =>
        acc +
        activity.aspects.reduce(
          (aspectAcc, aspect) => aspectAcc + aspect.evaluations.length,
          0
        ),
      0
    );
  }

  function setRowspanAspectWithActions() {
    return (
      impact.impact_actions.length *
      activity.aspects.reduce(
        (aspectAcc, aspect) => aspectAcc + aspect.evaluations.length,
        0
      )
    );
  }

  function setRowspanAspectWithoutActions() {
    return activity.aspects.reduce(
      (aspectAcc, aspect) => aspectAcc + aspect.evaluations.length,
      0
    );
  }

  function setRowspanEvalWithoutActions() {
    return aspect.evaluations.length;
  }

  function setRowspanEvalWithActions() {
    return impact.impact_actions.length * aspect.evaluations.length;
  }

  function setRowspanAction() {
    return impact.impact_actions.length;
  }

  const getBadgeClass = (value) => {
    if (value <= 25) {
      return "badge-success";
    } else if (value <= 50) {
      return "badge-warning";
    } else if (value <= 75) {
      return "badge-orange";
    } else {
      return "badge-danger";
    }
  };

  if (!actions || actions.length === 0) {
    return (
      <tr>
        {activityIndex === 0 && aspectIndex === 0 && evalIndex === 0 && (
          <td
            className="align-middle text-center"
            rowSpan={setRowspanImpactWithoutActions()}
          >
            {impact.all_procedures ? (
              <span>Todos los procesos</span>
            ) : (
              <>
                {impact.procedures.map((procedure) => (
                  <span className="d-block" key={procedure.id}>
                    {procedure.title}
                  </span>
                ))}
              </>
            )}
          </td>
        )}

        {aspectIndex === 0 && evalIndex === 0 && (
          <td
            className="text-center align-middle"
            rowSpan={setRowspanAspectWithoutActions()}
          >
            {activity.title}
          </td>
        )}

        {aspectIndex === 0 && evalIndex === 0 && (
          <td
            className="text-center align-middle font-weight-bold"
            rowSpan={setRowspanAspectWithoutActions()}
          >
            {activity.situation === 0 ? "x" : ""}
          </td>
        )}

        {aspectIndex === 0 && evalIndex === 0 && (
          <td
            className="text-center align-middle font-weight-bold"
            rowSpan={setRowspanAspectWithoutActions()}
          >
            {activity.situation === 1 ? "x" : ""}
          </td>
        )}

        {aspectIndex === 0 && evalIndex === 0 && (
          <td
            className="text-center align-middle font-weight-bold"
            rowSpan={setRowspanAspectWithoutActions()}
          >
            {activity.situation === 2 ? "x" : ""}
          </td>
        )}

        {evalIndex === 0 && (
          <td
            className="text-center align-middle"
            rowSpan={setRowspanEvalWithoutActions()}
          >
            {aspect.name}
          </td>
        )}

        {evalIndex === 0 && (
          <td
            className="text-center align-middle"
            rowSpan={setRowspanEvalWithoutActions()}
          >
            {aspect.description}
          </td>
        )}

        <td className="align-middle text-center">
          {evaluation.resources.map((resource) => (
            <span className="d-block" key={resource.id}>
              {resource.affected_resource}
            </span>
          ))}
        </td>

        <td className="align-middle text-center">
          {evaluation.generated_impact}
        </td>

        <td className="align-middle text-center">
          {evaluation.nature ? "+" : "-"}
        </td>

        <td className="align-middle text-center">{evaluation.intensity}</td>

        <td className="align-middle text-center">{evaluation.extension}</td>

        <td className="align-middle text-center">{evaluation.moment}</td>

        <td className="align-middle text-center">{evaluation.persistence}</td>

        <td className="text-center align-middle">{evaluation.reversibility}</td>

        <td className="text-center align-middle">{evaluation.synergy}</td>

        <td className="text-center align-middle">{evaluation.accumulation}</td>

        <td className="text-center align-middle">{evaluation.effect}</td>

        <td className="text-center align-middle">{evaluation.periodicity}</td>

        <td className="text-center align-middle">
          {evaluation.recoverability}
        </td>

        <td className="text-center align-middle">
          {evaluation.significance_value}
        </td>

        <td
          className={`text-center align-middle ml-2 ${getBadgeClass(
            evaluation.significance_value
          )}`}
        >
          {evaluation.significance_level}
        </td>

        <td className="text-center align-middle">
          {evaluation.associated_risk}
        </td>

        <td className="text-center align-middle">
          {evaluation.risk_description}
        </td>

        <td className="text-center align-middle">
          {evaluation.oportunity_description}
        </td>

        <td className="text-center align-middle font-weight-bold">
          {evaluation.significant_impact && "x"}
        </td>

        <td className="text-center align-middle font-weight-bold">
          {!evaluation.significant_impact && "x"}
        </td>

        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  } else {
    return (
      <>
        {actions.map((action, actionIndex) => {
          return (
            <tr
              key={`impact-${impact.id}-aspect-${aspectIndex}-evaluation-${evalIndex}-action-${actionIndex}`}
            >
              {activityIndex === 0 &&
                aspectIndex === 0 &&
                evalIndex === 0 &&
                actionIndex === 0 && (
                  <td
                    className="align-middle text-center"
                    rowSpan={setRowspanImpactWithActions(impact)}
                  >
                    {impact.all_procedures ? (
                      <span>Todos los procesos</span>
                    ) : (
                      <>
                        {impact.procedures.map((procedure) => (
                          <span className="d-block" key={procedure.id}>
                            {procedure.title}
                          </span>
                        ))}
                      </>
                    )}
                  </td>
                )}

              {aspectIndex === 0 && evalIndex === 0 && actionIndex === 0 && (
                <td
                  className="text-center align-middle"
                  rowSpan={setRowspanAspectWithActions(activity)}
                >
                  {activity.title}
                </td>
              )}

              {aspectIndex === 0 && evalIndex === 0 && actionIndex === 0 && (
                <td
                  className="text-center align-middle font-weight-bold"
                  rowSpan={setRowspanAspectWithActions(activity)}
                >
                  {activity.situation === 0 ? "x" : ""}
                </td>
              )}

              {aspectIndex === 0 && evalIndex === 0 && actionIndex === 0 && (
                <td
                  className="text-center align-middle font-weight-bold"
                  rowSpan={setRowspanAspectWithActions(activity)}
                >
                  {activity.situation === 1 ? "x" : ""}
                </td>
              )}

              {aspectIndex === 0 && evalIndex === 0 && actionIndex === 0 && (
                <td
                  className="text-center align-middle font-weight-bold"
                  rowSpan={setRowspanAspectWithActions(activity)}
                >
                  {activity.situation === 2 ? "x" : ""}
                </td>
              )}

              {evalIndex === 0 && actionIndex === 0 && (
                <td
                  className="text-center align-middle"
                  rowSpan={setRowspanEvalWithActions(aspect)}
                >
                  {aspect.name}
                </td>
              )}

              {evalIndex === 0 && actionIndex === 0 && (
                <td
                  className="text-center align-middle"
                  rowSpan={setRowspanEvalWithActions(aspect)}
                >
                  {aspect.description}
                </td>
              )}
              {actionIndex === 0 && (
                <>
                  <td
                    className="align-middle text-center"
                    rowSpan={setRowspanAction(evaluation)}
                  >
                    {evaluation.resources.map((resource) => (
                      <span className="d-block" key={resource.id}>
                        {resource.affected_resource}
                      </span>
                    ))}
                  </td>

                  <td
                    className="align-middle text-center"
                    rowSpan={setRowspanAction(evaluation)}
                  >
                    {evaluation.generated_impact}
                  </td>

                  <td
                    className="align-middle text-center"
                    rowSpan={setRowspanAction(evaluation)}
                  >
                    {evaluation.nature ? "+" : "-"}
                  </td>

                  <td
                    className="align-middle text-center"
                    rowSpan={setRowspanAction(evaluation)}
                  >
                    {evaluation.intensity}
                  </td>

                  <td
                    className="align-middle text-center"
                    rowSpan={setRowspanAction(evaluation)}
                  >
                    {evaluation.extension}
                  </td>

                  <td
                    className="align-middle text-center"
                    rowSpan={setRowspanAction(evaluation)}
                  >
                    {evaluation.moment}
                  </td>

                  <td
                    className="align-middle text-center"
                    rowSpan={setRowspanAction(evaluation)}
                  >
                    {evaluation.persistence}
                  </td>

                  <td
                    className="text-center align-middle"
                    rowSpan={setRowspanAction(evaluation)}
                  >
                    {evaluation.reversibility}
                  </td>

                  <td
                    className="text-center align-middle"
                    rowSpan={setRowspanAction(evaluation)}
                  >
                    {evaluation.synergy}
                  </td>

                  <td
                    className="text-center align-middle"
                    rowSpan={setRowspanAction(evaluation)}
                  >
                    {evaluation.accumulation}
                  </td>

                  <td
                    className="text-center align-middle"
                    rowSpan={setRowspanAction(evaluation)}
                  >
                    {evaluation.effect}
                  </td>

                  <td
                    className="text-center align-middle"
                    rowSpan={setRowspanAction(evaluation)}
                  >
                    {evaluation.periodicity}
                  </td>

                  <td
                    className="text-center align-middle"
                    rowSpan={setRowspanAction(evaluation)}
                  >
                    {evaluation.recoverability}
                  </td>

                  <td
                    className="text-center align-middle"
                    rowSpan={setRowspanAction(evaluation)}
                  >
                    {evaluation.significance_value}
                  </td>

                  <td
                    className={`text-center align-middle ml-2 ${getBadgeClass(
                      evaluation.significance_value
                    )}`}
                    rowSpan={setRowspanAction(evaluation)}
                  >
                    {evaluation.significance_level}
                  </td>

                  <td
                    className="text-center align-middle"
                    rowSpan={setRowspanAction(evaluation)}
                  >
                    {evaluation.associated_risk}
                  </td>

                  <td
                    className="text-center align-middle"
                    rowSpan={setRowspanAction(evaluation)}
                  >
                    {evaluation.risk_description}
                  </td>

                  <td
                    className="text-center align-middle"
                    rowSpan={setRowspanAction(evaluation)}
                  >
                    {evaluation.oportunity_description}
                  </td>

                  <td
                    className="text-center align-middle font-weight-bold"
                    rowSpan={setRowspanAction(evaluation)}
                  >
                    {evaluation.significant_impact && "x"}
                  </td>

                  <td
                    className="text-center align-middle font-weight-bold"
                    rowSpan={setRowspanAction(evaluation)}
                  >
                    {!evaluation.significant_impact && "x"}
                  </td>
                </>
              )}

              {activityIndex === 0 && aspectIndex === 0 && evalIndex === 0 && (
                <>
                  {/* action */}
                  <td className="text-center align-middle">
                    {action.action_title}
                  </td>
                  <td className="text-center align-middle">
                    {action.action_responsible}
                  </td>
                  <td className="text-center align-middle">
                    {action.action_start_date}
                  </td>
                  <td className="text-center align-middle">
                    {action.action_end_date}
                  </td>
                  <td className="text-center align-middle">
                    {action.action_funding}
                  </td>
                  <td className="text-center align-middle">
                    {action.action_percentage} %
                  </td>
                  <td className="text-center align-middle">
                    {action.action_evidence}
                  </td>
                </>
              )}
            </tr>
          );
        })}
      </>
    );
  }
};

export default TrMissingAction;
