import React from "react";
import {
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parseISO } from "date-fns";
import es from "date-fns/locale/es";

const InputsFilter = ({
  handleSearch,
  inputFilter,
  startDate,
  endDate,
  setInputFilter,
  setStartDate,
  setEndDate,
}) => {
  const handleInputFilterChange = (event) => {
    const filterValue = event.target.value;
    setInputFilter(filterValue);
  };

  const handleStartDateChange = (event) => {
    const filterValue = event.target.value;
    setStartDate(filterValue);
  };

  const handleEndDateChange = (event) => {
    const filterValue = event.target.value;
    setEndDate(filterValue);
  };

  const cleanInputs = () => {
    setInputFilter("");
    setStartDate("");
    setEndDate("");
  };

  return (
    <>
      <InputGroup size="sm" style={{ alignItems: "center" }}>
        <DatePicker
          selected={startDate ? parseISO(startDate) : null}
          onChange={(date) =>
            handleStartDateChange({
              target: {
                name: "startDate",
                value: date.toISOString(),
              },
            })
          }
          placeholderText="Fecha Inicio"
          className="bg-dark border-0 input-date text-light"
          dateFormat="yyyy/MM/dd"
          locale={es}
        />

        <DatePicker
          selected={endDate ? parseISO(endDate) : null}
          onChange={(date) =>
            handleEndDateChange({
              target: {
                name: "endDate",
                value: date.toISOString(),
              },
            })
          }
          className="bg-dark border-0 input-date text-light"
          placeholderText="Fecha Fin"
          dateFormat="yyyy/MM/dd"
          locale={es}
        />
        <Form.Control
          className="bg-dark text-white border-0 py-2"
          placeholder="Sede"
          onChange={(event) => handleInputFilterChange(event)}
          value={inputFilter}
        />

        <Button
          variant="outline-secondary"
          className="ml-2 border-0"
          onClick={handleSearch}
        >
          Buscar
        </Button>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Limpear filtros</Tooltip>}
        >
          <Button
            variant="outline-secondary"
            className="ml-2 border-0"
            onClick={cleanInputs}
          >
            <FontAwesomeIcon icon={faXmark} />
          </Button>
        </OverlayTrigger>
      </InputGroup>
    </>
  );
};

export default InputsFilter;
