import React from 'react'
import CardChart from './CardChart'
const Modal = ({ modalTitle, modalControl, data, parentId, parentType }) => {

  return (
    <div className="modal fade show" tabIndex="-1" style={{paddingRight: '4px', display: 'block', backgroundColor: 'rgba(53, 53, 53, 0.5)'}} role="dialog">
      <div className="modal-dialog modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{modalTitle}</h5>
            <button type="btn btn-link" className="close" onClick={()=> modalControl(false)} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            { data.map((info)=>{
              return ( <CardChart key={info.id}
                                  text={info.name}
                                  value={info.value}
                                  color={info.color}
                                  modalControl={modalControl}
                                  parentType={parentType}
                                  parentId={info.id}/>)
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal