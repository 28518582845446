import { Controller } from 'stimulus'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.es'

export default class extends Controller {
  connect () {
    const language = this.data.get('language')
    const editable = this.data.get('editable') || true

    if (editable === false || editable === 'false') {
      return
    }

    const options = {
      format: 'yyyy-mm-dd',
      todayHighlight: true,
      clear: true,
      language
    }

    $(this.element).datepicker(options)
  }
}
