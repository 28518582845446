import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['seeall']

  // connect () {
  //   this.hideRead()
  // }

  hideRead (e) {
    if (e) e.preventDefault()
    if ($(this.seeallTarget).prop('checked')) { $('#read_field').hide() } else { $('#read_field').show() }
  }
}
