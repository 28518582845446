import { Controller } from 'stimulus'

export default class extends Controller {
  static targets= ['input']
  onChange () {
    if ($('#checkAllEvaluations').prop('checked'))
    {
      $(".maintenance-evaluation-item").prop("checked", true);
    }
    else
    {
      $(".maintenance-evaluation-item").prop("checked", false);
    }
  }
}
