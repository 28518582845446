import React from 'react'

const LinkTitle = ({ title, type, modalControl, date, initialdate, finaldate }) => {
  return (
    <div className='card-title text-center'>
      <button className='btn btn-link' type='button' onClick={()=>modalControl(true,'/api/v2/metrics/type_procedures?type='+type+'&date='+date+'&initialdate='+initialdate+'&finaldate='+finaldate, title, 'procedure_id')}>
        <span className='h4 no-pointer'>{title}</span>
        <i className='ml-1 fas fa-chevron-right no-pointer'></i>
      </button>
    </div>
   )
}

export default LinkTitle