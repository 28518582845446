import React from "react";
import { LaboratoriesContext } from "../LaboratoriesContext";

function Buttons({state, laboratory_id}){
  const {
    findLaboratory,
    destroyLaboratory
  } = React.useContext(LaboratoriesContext)

  const approved = async (event) =>{
    event.preventDefault()
    findLaboratory(laboratory_id, 'approve')
  }

  const sampling = async (event) =>{
    event.preventDefault()
    findLaboratory(laboratory_id, 'samplingAssignment')
  }

  const testExecution = async (event) =>{
    event.preventDefault()
    findLaboratory(laboratory_id, 'testExecution')
  }

  const approvalOfTests = async (event) =>{
    event.preventDefault()
    findLaboratory(laboratory_id, 'approvalOfTests')
  }

  const AwaitingPresentationAndClosure = async (event) =>{
    event.preventDefault()
    findLaboratory(laboratory_id, 'awaitingPresentationAndClosure')
  }

  const EditReport = async (event) =>{
    event.preventDefault()
    findLaboratory(laboratory_id, 'editReport')
  }
  return(
    <div className="d-flex justify-content-between ml-2">
      {state == 'awaiting_approved' && 
        <button className="btn btn-info btn-sm mr-1"
          onClick={approved}
        >
          Aprobar
        </button>
      }
      {state == 'sampling_and_assignment' &&
        <button className="btn btn-info btn-sm mr-1"
        onClick={sampling}
        >
          Muestra y Asignación
        </button>
      }
      {state == 'test_execution' &&
        <button className="btn btn-info btn-sm mr-1"
        onClick={testExecution}
        >
          Ejecución de pruebas
        </button>
      }
      {state == 'awaiting_test_approval' &&
        <button className="btn btn-info btn-sm mr-1"
        onClick={approvalOfTests}
        >
          Aprobación de Pruebas
        </button>
      }
      {state == 'awaiting_presentation_and_closure' &&
        <button className="btn btn-info btn-sm mr-1"
        onClick={AwaitingPresentationAndClosure}
        >
          Enviar y Cerrar
        </button>
      }
      {state == 'closed' &&
        <button className="btn btn-info btn-sm mr-1"
        onClick={EditReport}
        >
          Actualizar informe
        </button>
      }
    </div>
  )
}

export { Buttons }