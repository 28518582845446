import React from 'react'
import ProfilePicture from './ProfilePicture'
const Dropdown = ({assignments, hover}) => {

  return (
    <div className="row" onMouseEnter={() => hover(true)} onMouseLeave={() => hover(false)}>
      <div className="my-dropdown-content">
        {assignments.slice(1).map((info)=>{
          return(<React.Fragment key={info.id}>
            <div className='row align-items-center justify-content-center mb-1'>
              {<ProfilePicture url={info.url} initials={info.initials} full_name={info.full_name}></ProfilePicture>}
            </div>
            <div className='dropdown-divider'></div>
          </React.Fragment>)
        })}
      </div>
    </div>
  )
}

export default Dropdown