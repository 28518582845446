import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['elementId']

  connect () {
    this.Documents()
  }

  Documents (e) {
    if (e) e.preventDefault()
    const badgeid = this.data.get('id')
    if (!document.getElementById(badgeid)) {
      const url = this.data.get('url')
      $.get(url).then((content) => {
        var badge = '<span id="' + badgeid + '" class="badge badge-pill badge-warning" style="display: none;"> ' + content + '</span>'
        $(this.elementIdTarget).append(badge)
        $('#' + badgeid).show('slow')
      })
    }
  }
}
