import React, { useState, useEffect } from 'react'
import { version } from 'react-dom';
import Select from 'react-select'

const Modal = ({
  portal,
  types,
  setModalSearch,
  publicFlag,
  periodicities,
  linesFlag,
  availableLines,
  setProgramTerm,
  programs,
  filterFlag,
  filterParams,
  setFilterFlag,
  setFilterParams,
  responsibles,
  indicatorType,
  categoryIndicator,
 }) => {

  const options = periodicities.map(periodicity => {
    const {id, name} = periodicity;
    return { value: id, label: name };
  })
  const defaultOptionPeriodicity = {
    label: 'Periodicidad',
    value: ''
  }

  const optionsusers = responsibles.map(employee => {
    const [label, value] = employee;
    return { label, value };
  })

  const defaultResponsibleMedition = {
    label: 'Responsable de medición',
    value: ''
  }
  const defaultResponsible = {
    label: 'Responsable de indicador',
    value: ''
  }

  const optionIndicatorTypes = indicatorType.map(type => {
    const [label, value] = type;
    return { label, value };
  })

  const typeIndicatorsOptions = [
    {
      value: 0,
      label: 'Producto'
    },
    {
      value: 1,
      label: 'Resultado'
    }
  ]
  const defaultOptionType = {
    label: 'Tipo de Indicador',
    value: ''
  }

  const OptionscategoryIndicator = categoryIndicator.map(category => {
    const [label, value] = category;
    return { label, value }
  })

  const defaultCategoryIndicator = {
    label: 'Categoría de indicador',
    value: ''
  }

  const lineOptions = availableLines.map(line => {
    const {id, name} = line;
    return { value: id, label: name };
  })
  const defaultOptionLine = {
    label: 'No Aplica',
    value: ''
  }

  const handleSelectLine = (e) => {
    setProgramTerm(e.value);
    lineParam(e);
  }

  const programOptions = programs.map(program => {
    const {id, name} = program;
    return { value: id, label: name };
  })
  const defaultOptionProgram = {
    label: 'No Aplica',
    value: ''
  }

  const lineParam = (e) => {
    const {value} = e
    setFilterParams({...filterParams, line: value})
  }

  const programParam = (e) => {
    const {value} = e
    setFilterParams({...filterParams, program: value})
  }

  const typeParam = (e) => {
    const {value} = e
    setFilterParams({...filterParams, type: value})
  }

  const periodicityParam = (e) => {
    const {value} = e
    setFilterParams({...filterParams, periodicity: value})
  }

  const meditionParam = (e) => {
    const {value} = e
    setFilterParams({...filterParams, metrics_responsible_id: value})
  }

  const responsibleParam = (e) => {
    const {value} = e
    setFilterParams({...filterParams, responsible_id: value})
  }

  const inputParam = (e) => {
    setFilterParams({...filterParams, input: e.target.value})
  }

  const nameParam = (e) => {
    setFilterParams({...filterParams, name: e.target.value})
  }

  const objectiveParam = (e) => {
    setFilterParams({...filterParams, objective: e.target.value})
  }

  const instruccionsParam = (e) => {
    setFilterParams({...filterParams, instruccions: e.target.value})
  }

  const sourceParam = (e) => {
    setFilterParams({...filterParams, source: e.target.value})
  }

  const formulaParam = (e) => {
    setFilterParams({...filterParams, formula: e.target.value})
  }

  const typeIndicator = (e) =>{
    const {value} = e
    setFilterParams({...filterParams, indicator_type_id: value})
  }

  const CategoryIndicator = (e) => {
    const {value} = e
    setFilterParams({...filterParams, category_indicator_id: value})
  }

  const handleSubmit = () => {
    setFilterFlag(true);
  }

  const handleCancel = () => {
    setModalSearch(false);
    setFilterParams({
      input: '',
      program: '',
      line: '',
      type: '',
      periodicity: '',
    })
  }

  return (
    <div className="modal fade show" tabIndex="-1" style={{paddingRight: '4px', display: 'block', backgroundColor: 'rgba(3, 3, 3, 0.5)'}} role="document">
      <div className="modal-dialog modal-dialog-scrollable" role="document" style={{maxWidth: '700px'}}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Búsqueda Avanzada</h5>
            <button type="btn btn-link" className="close" onClick={()=> setModalSearch(false)} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {publicFlag ?
          <>
            <div className="modal-body">
              <form className='d-inline mb-3'>
                <div className='form-group mb-3'>
                  <span>Nombre</span>
                  <div className='input-group'>
                    <div className='input-group-prepend'>
                      <div className='input-group-text'>
                        <i className='far fa-file-alt form-icon-small'></i>
                      </div>
                    </div>
                    <input
                      className='form-control'
                      placeholder='Nombre'
                      onChange={nameParam}
                    />
                  </div>
                </div>
                <div className='form-group mb-3'>
                  <span>Responsable de indicador</span>
                  <div className='input-group'>
                    <div className='input-group-prepend'>
                      <div className='input-group-text'>
                        <i className='fas fa-file-alt form-icon-small'></i>
                      </div>
                    </div>
                    <Select
                      className='w-100'
                      options={optionsusers}
                      defaultValue={defaultResponsible}
                      name="filters[responsible_id]"
                      onChange={responsibleParam}
                    />
                  </div>
                </div>
                <div className='form-group mb-3'>
                  <span>Responsable de medición</span>
                  <div className='input-group'>
                    <div className='input-group-prepend'>
                      <div className='input-group-text'>
                        <i className='fas fa-file-alt form-icon-small'></i>
                      </div>
                    </div>
                    <Select
                      className='w-100'
                      options={optionsusers}  
                      defaultValue={defaultResponsibleMedition}
                      name="filters[metrics_responsible_id]"
                      onChange={meditionParam}
                    />
                  </div>
                </div>
                <div className='form-group mb-3'>
                  <span>Línea Estratégica</span>
                  <div className='input-group'>
                    <div className='input-group-prepend'>
                      <div className='input-group-text'>
                        <i className='fas fa-file-alt form-icon-small'></i>
                      </div>
                    </div>
                    <Select
                      className='w-100'
                      options={lineOptions}
                      defaultValue={defaultOptionLine}
                      name="filters[strategic_line_id]"
                      onChange={handleSelectLine}
                    />
                  </div>
                </div>
                {linesFlag &&
                  <>
                    <div className='form-group mb-3'>
                      <span>Programa</span>
                      <div className='input-group'>
                        <div className='input-group-prepend'>
                          <div className='input-group-text'>
                            <i className='far fa-building form-icon-small'></i>
                          </div>
                        </div>
                        <Select
                          className='w-100'
                          options={programOptions}
                          defaultValue={defaultOptionProgram}
                          name="filters[program]"
                          customName='program'
                          onChange={programParam}
                        />
                      </div>
                    </div>
                  </>
                }
                {/* <div className='form-group mb-3'>
                  <span>Tipo de Indicador</span>
                  <div className='input-group'>
                    <div className='input-group-prepend'>
                      <div className='input-group-text'>
                        <i className='fas fa-file-alt form-icon-small'></i>
                      </div>
                    </div>
                    <Select
                      className='w-100'
                      options={optionIndicatorTypes}
                      defaultValue={defaultOptionType}
                      name="filters[type_of_indicator]"
                      onChange={typeParam}
                    />
                  </div>
                </div> */}
                <div className='form-group mb-3'>
                  <span>Tipo de Indicador</span>
                  <div className='input-group'>
                    <div className='input-group-prepend'>
                      <div className='input-group-text'>
                        <i className='fas fa-file-alt form-icon-small'></i>
                      </div>
                    </div>
                    <Select
                      className='w-100'
                      options={optionIndicatorTypes}
                      defaultValue={defaultOptionType}
                      name="filters[indicator_type_id]"
                      onChange={typeIndicator}
                    />
                  </div>
                </div>
              </form>
            </div>
          </> :
          <>
            <div className="modal-body">
              <form className='d-inline mb-3'>
                <div className='form-group mb-3'>
                  <span>Nombre</span>
                  <div className='input-group'>
                    <div className='input-group-prepend'>
                      <div className='input-group-text'>
                        <i className='far fa-file-alt form-icon-small'></i>
                      </div>
                    </div>
                    <input
                      className='form-control'
                      placeholder='Nombre'
                      onChange={nameParam}
                    />
                  </div>
                </div>
                <div className='form-group mb-3'>
                  <span>Responsable de indicador</span>
                  <div className='input-group'>
                    <div className='input-group-prepend'>
                      <div className='input-group-text'>
                        <i className='fas fa-file-alt form-icon-small'></i>
                      </div>
                    </div>
                    <Select
                      className='w-100'
                      options={optionsusers}
                      defaultValue={defaultResponsible}
                      name="filters[responsible_id]"
                      onChange={responsibleParam}
                    />
                  </div>
                </div>
                <div className='form-group mb-3'>
                  <span>Responsable de medición</span>
                  <div className='input-group'>
                    <div className='input-group-prepend'>
                      <div className='input-group-text'>
                        <i className='fas fa-file-alt form-icon-small'></i>
                      </div>
                    </div>
                    <Select
                      className='w-100'
                      options={optionsusers}  
                      defaultValue={defaultResponsibleMedition}
                      name="filters[metrics_responsible_id]"
                      onChange={meditionParam}
                    />
                  </div>
                </div>
                {/* <div className='form-group mb-3'>
                  <span>Tipo de Indicador</span>
                  <div className='input-group'>
                    <div className='input-group-prepend'>
                      <div className='input-group-text'>
                        <i className='fas fa-file-alt form-icon-small'></i>
                      </div>
                    </div>
                    <Select
                      className='w-100'
                      options={optionIndicatorTypes}
                      defaultValue={defaultOptionType}
                      name="filters[type_of_indicator]"
                      onChange={typeParam}
                    />
                  </div>
                </div> */}
                <div className='form-group mb-3'>
                  <span>Tipo de Indicador</span>
                  <div className='input-group'>
                    <div className='input-group-prepend'>
                      <div className='input-group-text'>
                        <i className='fas fa-file-alt form-icon-small'></i>
                      </div>
                    </div>
                    <Select
                      className='w-100'
                      options={optionIndicatorTypes}
                      defaultValue={defaultOptionType}
                      name="filters[indicator_type_id]"
                      onChange={typeIndicator}
                    />
                  </div>
                </div>
                {
                  portal.calderon ?
                  <div className='form-group mb-3'>
                    <span>Categoría de indicador</span>
                    <div className='input-group'>
                      <div className='input-group-prepend'>
                        <div className='input-group-text'>
                          <i className='fas fa-file-alt form-icon-small'></i>
                        </div>
                      </div>
                      <Select
                        className='w-100'
                        options={OptionscategoryIndicator}
                        defaultValue={defaultCategoryIndicator}
                        name="filters[category_indicator_id]"
                        onChange={CategoryIndicator}
                      />
                    </div>
                  </div>
                  :
                  ""
                }
              </form>
            </div>
          </> }

          <div className='modal-footer'>
            <button className='btn btn-link' onClick={handleCancel}>Cancelar</button>
            <button className='btn btn-dark mr-1' onClick={handleSubmit}>
              Buscar
              <i className='text-white fas fa-search mx-2 sm'></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
