import { Controller }  from 'stimulus'

export default class extends Controller {

  posOne(){
    var selectOne = $("#positionOne").val()
    var selecttwo = $("#positiontwo").val()
    var selectthree = $("#positionthree").val()
    if (selectOne == selecttwo) { $("#positiontwo").val("");}
    if (selectOne == selectthree) { $("#positionthree").val("");}
  }
  posTwo(){
    var selectOne = $("#positionOne").val()
    var selecttwo = $("#positiontwo").val()
    var selectthree = $("#positionthree").val()
    if (selecttwo == selectthree) { $("#positionthree").val("");}
    if (selecttwo == selectOne) { $("#positionOne").val("");}
  }
  posThree(){
    var selectOne = $("#positionOne").val()
    var selecttwo = $("#positiontwo").val()
    var selectthree = $("#positionthree").val()
    if (selectthree == selecttwo) { $("#positiontwo").val("");}
    if (selectthree == selectOne) { $("#positionOne").val("");}
  }
}
