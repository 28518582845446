import { Controller }  from 'stimulus'

export default class extends Controller {
  // static targets = ['bsc', 'bscperspective', 'bscstrategic', 'operative'] // Elementos objetivo

  cambiarBsc(event) {
    this.buscarperspectivess(event.target.value);
  }

  cambiarPerspectivaBsc(event) {
    this.buscarObjetivosEstrategicos(event.target.value);
  }

  cambiarObjetivoEstrategico(event) {
    this.buscarObjetivosOperativos(event.target.value);
  }

  async buscarperspectivess(idPerspectivaBsc = null) {
    const perspective = document.getElementById('bsc_perspective');
    if (!perspective) return; // Prevenir errores si falta el elemento objetivo

    const url = `/indicators/filter_bsc?id=${idPerspectivaBsc}&type=perspective`; // Suponiendo la estructura de tu endpoint

    try {
      const respuesta = await fetch(url);
      const data = await respuesta.json();

      perspective.innerHTML = this.renderizarOpciones(data); // Actualizar opciones
      const id = data[0] ? data[0].id : '0';
      this.buscarObjetivosEstrategicos(id);
    } catch (error) {
      console.error('Error al buscar objetivos estratégicos:', error);
      // Manejar errores apropiadamente, por ejemplo, mostrar un mensaje de error al usuario
    }
  }

  async buscarObjetivosEstrategicos(idstrategic = null) {
    const strategic = document.getElementById('strategic_objective');
    if (!strategic) return; // Prevenir errores si falta el elemento objetivo

    const url = `/indicators/filter_bsc?id=${idstrategic}&type=strategic_objective`; // Suponiendo la estructura de tu endpoint

    try {
      const respuesta = await fetch(url);
      const data = await respuesta.json();

      strategic.innerHTML = this.renderizarOpciones(data); // Actualizar opciones
      const id = data[0] ? data[0].id : '0';
      this.buscarObjetivosOperativos(id);
    } catch (error) {
      console.error('Error al buscar objetivos estratégicos:', error);
      // Manejar errores apropiadamente, por ejemplo, mostrar un mensaje de error al usuario
    }
  }

  async buscarObjetivosOperativos(idobjetivos = null) {
    const operative = document.getElementById('modal');
    if (!operative) return; // Prevenir errores si falta el elemento objetivo

    const url = `/indicators/filter_bsc?id=${idobjetivos}&type=operative_objective`; // Suponiendo la estructura de tu endpoint

    try {
      
      const respuesta = await fetch(url);
      const data = await respuesta.json();

      operative.innerHTML = this.renderizarOpciones(data); // Actualizar opciones
    } catch (error) {
      console.error('Error al buscar objetivos estratégicos:', error);
      // Manejar errores apropiadamente, por ejemplo, mostrar un mensaje de error al usuario
    }
  }
  renderizarOpciones(data) {
    return data.map(opcion => `<option value="${opcion.id}">${opcion.name}</option>`).join('');
  }
}