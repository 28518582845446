import React from "react";

import { Bar } from "react-chartjs-2";

export default function GraphicsMain({ list, months }) {
  const monthsValue = months.map((month) => {
    const value = list[month];
    return {
      month,
      value,
    };
  });

  const values = monthsValue.map((m) => m.value);

  const myData = {
    labels: months,
    datasets: [
      {
        label: "Total",
        data: values,
        tension: 0.5,
        fill: true,
        borderColor: "rgb(0, 150, 136)",
        backgroundColor: "rgba(0, 150, 136, 0.5)",
        pointRadius: 5,
        pointBorderColor: "rgba(0, 150, 136)",
        pointBackgroundColor: "rgba(0, 150, 136)",
      },
    ],
  };

  const options = {
    scales: {
      y: {
        min: 0,
      },
      x: {
        ticks: { color: "rgb(255, 99, 132)" },
      },
    },
  };
  return <Bar data={myData} options={options} />;
}
