import { Controller }  from 'stimulus'

export default class extends Controller {
  connect() {
    this.onChange()
  }

  onChange(e) {
    if (e) e.preventDefault();
    const url =  this.data.get('url')
    const aux = this.data.get('aux')    
    const target = this.data.get('target')
    $.get(url+'?select_id='+this.element.value+'&aux_var='+aux).then((content) => {
      document.getElementById(target).innerHTML = content
    })
  }
}
