import { Controller }  from 'stimulus'


export default class extends Controller {

  static targets = ['answer']
 

  connect() {
    this.onAnswer();
  }

  onAnswer ()
  { 
    const color = this.data.get('color')
    const color_ok = this.data.get('ok')
    var check = document.getElementById("boton");
    var texto = document.getElementById("texto");
  
    if (this.answerTarget.value != ''){
      check.style.display = ''
      texto.style.display = 'none'
    }
    else
    {
      check.style.display = 'none'
      texto.style.display = ''
    }
  }

}
